import { Injectable } from '@angular/core';
import { UsersService } from './users.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  constructor (
    private usersSrv: UsersService,
    private loginSrv: LoginService
  ) {}

  canActivate() {
    try {
      const hasPerm = this.usersSrv.actualUSerHasPerm(['APP_EXTERNA'], 'AND');
      if (hasPerm) {
        return true;
      } else {
        this.loginSrv.logout()
        return false;
      }  
    } catch( error) {
      this.loginSrv.logout()
      return false;
    }
    
  }
}
