import { Component, OnInit } from '@angular/core';
import { faSpinner, faTicketAlt, faSearch, faExclamationTriangle, faClipboardCheck, 
  faHourglassStart, faCalendarCheck, faClipboardList, faTimesCircle, faInbox } from '@fortawesome/free-solid-svg-icons';
import { animate, style, transition, trigger } from '@angular/animations';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/services/data.service';
import { Param, ParamOData, ReporteSLAData, Ticket, TipoTicket } from 'src/app/interfaces/interfaces';
import fileUtils from 'src/app/utils/fileUtils';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Constantes } from 'src/app/constants/constantes.const';
import { TicketService } from 'src/app/services/ticket.service';
import moment from 'moment';
import { WorkBook, WorkSheet, utils, writeFile } from 'xlsx';
import { UsersService } from 'src/app/services/users.service';
import { AuditService } from 'src/app/services/audit.service';
import { MongoDBService } from 'src/app/services/mongoDB.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-listado-reportes',
  templateUrl: './listado-reportes.component.html',
  styleUrls: ['./listado-reportes.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(200, style({ opacity: 0 }))
      ])
    ])
  ],
})
export class ListadoReportesComponent implements OnInit {
  //iconos
  faTicketAlt = faTicketAlt;
  faSpinner = faSpinner;
  faSearch = faSearch;
  faExclamationTriangle = faExclamationTriangle;
  faClipboardCheck = faClipboardCheck;
  faHourglassStart = faHourglassStart;
  faCalendarCheck = faCalendarCheck;
  faClipboardList = faClipboardList;
  faTimesCircle = faTimesCircle;
  faInbox=faInbox
  //datos
  tickets: Ticket[] = [];
  ticketsTypes: TipoTicket[] = [];
  selectedType!: string;
  //otros
  subs: Subscription[] = [];
  searchText!: string;
  statusGroupFilter?: 'all' | 'open' | 'closed';
  //paginado
  collectionSize = 0;
  collectionSizeFiltered = {count:0};
  start=0;
  end=5;
  page=1;
  pageSize=5;
  //cantidades
  ticketTotales = 0;
  ticketAbiertos = 0;
  ticketCerrados = 0;
  //booleans
  loading?: boolean;
  loadingMessage = new BehaviorSubject("");
  loadingTickets = false;
  tipoTicketSelected?: boolean;
  showEmptyAlert=false

  acciones: any = {
    show: true,
    label: 'Cambiar Reporte',
    options: [
      { codigo: 'SLAS', descripcion: 'Informe por SLAs' },
      { codigo: 'TIPO-TICKET', descripcion: 'Informe por Tipo Ticket' },
      { codigo: 'EVALUACION', descripcion: 'Informe por Evaluación', permisos: ['SEC-REPORTE-EVALUACION-GET'] }
    ]
  }

  reportType = 'SLAS'
  reportTitle = 'Informe por SLAs'
  reportSLAData: any = {}
  loadingSLAReport = false;
  ReporteSLADataArr: ReporteSLAData[] = [];
  tipoTicketsInReportSLA: string[] = []
  tipoTicketInReportSLASelected = '';

  constructor(
    private dataSrv: DataService,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private ticketsSrv: TicketService,
    private router: Router,
    private usersSrv: UsersService,
    private auditSrv: AuditService,
    private mongoSrv: MongoDBService,
    private toastSrv: ToastrService
  ) {
    this.subs.push(
      this.dataSrv.getPreLoadStatus().subscribe(cargado => {
        if (cargado) {
          this.getData();
        }
      })
    );
  }

  /** Evento inicial del componente */
  ngOnInit(): void {
    this.title.setTitle(`${this.activatedRoute.snapshot.data.titulo} - ${environment.app_name}`);
  }

  /** Evento al destruir el componente */
  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    })
  }

  /** Metodo para obtener los datos del servicio */
  async getData() {
    this.dataSrv.setCargando(true);
    await Promise.all([
      this.dataSrv.loadTicketsTypes()
    ]);
    this.dataSrv.setCargando(false);
    
    this.loading = true;
    this.loadingMessage.next("Cargando datos");
    this.subs.push(this.dataSrv.getTicketsTypes().subscribe(types => {
      this.ticketsTypes = types;
    }));
    // obtiene los tickets
    this.subs.push(this.dataSrv.getFilteringStatus().subscribe(filtering => {
      if (!filtering) {
        this.updatePagination();
      }
    }));
    this.loading = false;
  }

  /** Metodo para actualizar la vista de tiquets */
  async updateTickets() {
    this.loading = true;
    this.loadingMessage.next("Actualizando tickets");
    this.ticketTotales=0;
    this.ticketAbiertos=0;
    this.ticketCerrados=0;
    this.tipoTicketSelected=false;
    const idEntidad = this.usersSrv.getActualUser().entidad?.id.toString();
    const codEntidad = this.usersSrv.getActualUser().entidad?.codigoEntidad;
    
    const params: Param[] = [];
    params.push({key:'format',value:'json'});
    params.push({key:'top',value:2000});
    params.push({key:'select',value: Constantes.TICKETS_FIELDS});
    const filters: ParamOData[] = [];
    filters.push({ key: 'ProcessingTypeCode', value: this.selectedType})
    filters.push({ key: 'Z_ENTIDAD_KUT', value: [idEntidad, codEntidad], operation: 'in' })
    params.push({key:'filters',value: JSON.stringify(filters)});

    const nTickets : Ticket[] = [];
    const tks = await this.ticketsSrv.getTickets(params);
    this.auditSrv.logAction({modulo:'Reportes',accion:'Obtener reporte',detalle: `Tipo: ${this.selectedType}`});
    if (tks) {
      tks.forEach(element => {
        const ticket: Ticket = {
          OID:element.ObjectID,
          numero:element.ID,
          boleta: element.Z_NUMERODEBOLETA_KUT,
          descripcion: element.Name,
          tipo: this.dataSrv.getTicketType(element.ProcessingTypeCode, element.ServiceIssueCategoryID, element.IncidentServiceIssueCategoryID),
          prioridad: { id: element.ServicePriorityCode, descripcion: element.ServicePriorityCodeText },
          estado: { id: element.ServiceRequestUserLifeCycleStatusCode, descripcion: element.ServiceRequestUserLifeCycleStatusCodeText },
          fechaUltimoCambio: moment(element.LastChangeDateTime).toDate(),
          fechaDeCreacion: moment(element.CreationDateTime).toDate(),
          creadoPor: element.Z_CREADOPOR_KUT
        };
        if (Constantes.ESTADOS_CERRADOS_TICKET.includes(ticket.estado.id.toString())) {
          this.ticketCerrados++;
        } else {
          this.ticketAbiertos++;
        }
        nTickets.push(ticket);
        this.ticketTotales = nTickets.length;
      });
    }
    this.tickets = nTickets;
    this.tipoTicketSelected = true;
    this.loading = false;
  }

  /** Método para navegar a la página detalles de tickets
   * @ticketNumber Número de ticket del se desea ver los detalles
   */
  goToDetails(ticketNumber: number) {
    this.router.navigate(['tickets', ticketNumber, 'detalle']);
  }

  /** Método para actualizar el paginado de tickets */
  updatePagination() {
    this.collectionSize = this.collectionSizeFiltered.count;
    this.start = this.page * this.pageSize - this.pageSize;
    this.end = this.page * this.pageSize;
  }

  /** Metodo para exportar un html */
  export(format: "pdf" | "png",onlyChart?:boolean){
    const data = document.getElementById(onlyChart ? "pieChart" : "exportable"); 
    fileUtils.export(data,"Reporte",format);
    this.auditSrv.logAction({modulo:'Reportes',accion:'Exportar reporte',detalle: `Tipo: ${this.selectedType}, Grupo: ${this.statusGroupFilter}, Formato: ${format}`});
  }

  /** Método para exportar datos a un archivo Excel */
  exportExcel() {
    this.loading=true;
    this.loadingMessage.next("Exportando");
    const s = this.start;
    const e = this.end;
    this.start=0;
    this.end=this.collectionSize;
    setTimeout(() => {
      const element = document.getElementById('tabla');
      const ws: WorkSheet = utils.table_to_sheet(element,{raw:true}); //raw:true evita que excel formatee la celda
      // crea variables con tamaño de las columnas
      const wscols = [
        {wch:10}, //NRO TICKET
        {wch:15}, //NRO BOLETA
        {wch:35}, //ASUNTO
        {wch:18}, //TIPO TICKET
        {wch:16}, //CATEGORIA N1
        {wch:16}, //CATEGORIA N2
        {wch:16}, //PRIORIDAD
        {wch:18}, //ESTADO
        {wch:22}, //FECHA ULTIMA ACTUALIZACION
        {wch:18}, //FECHA CREACION
        {wch:30}  //CREADO POR
      ];
      // asigna variable a la tabla
      ws['!cols'] = wscols;

      // genera el libro y agrega la hoja
      const wb: WorkBook = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Sheet1');
      // guarda el archivo
      writeFile(wb, 'Listado de tickets.xlsx');
      this.start = s;
      this.end = e;
      this.loading = false;
      this.auditSrv.logAction({ modulo: 'Reportes', accion: 'Exportar reporte', detalle: `Tipo: ${this.selectedType}, Grupo: ${this.statusGroupFilter}, Formato: Excel` });
    }, 50)
  }

  selectReport(e) {
    this.reportType = e.option.codigo
    this.reportTitle = e.option.descripcion

  }

  obtenerReporteSLA() {
    if(['Z1', 'Z2'].includes(this.selectedType)){
      this.toastSrv.error(`No se puede obtener informe de SLA del tipo de ticket seleccionado`, '', { positionClass: 'toast-top-center', progressBar: false })
      return;
    }
    this.getInformeSLA()
  }
  async getInformeSLA() {
    let isByType = false;
    this.showEmptyAlert = false
    this.loading = true
    this.loadingMessage.next("Obteniendo informe");
    let filter = ''
    if (this.selectedType) {
      filter = `?tipoTicket=${this.selectedType}`;
      isByType = true;
    }
    this.reportSLAData = {}
    this.ReporteSLADataArr = []

    this.reportSLAData = await this.mongoSrv.getAll(`/tickets/informe${filter}`);
    if(isByType && !(`${this.selectedType}` in this.reportSLAData)){
      console.log(`No hay datos para el tipo de ticket ${this.selectedType}`)
      this.showEmptyAlert = true
    }
    
    for (const key in this.reportSLAData) {
      this.tipoTicketsInReportSLA.push(this.reportSLAData[key].ProcessingTypeCodeText)
      const item = this.reportSLAData[key]
      this.ReporteSLADataArr.push(
        {
          ...item,
          ProcessingTypeCodeText: item.ProcessingTypeCodeText != '--' ? item.ProcessingTypeCodeText : this.getNameByCode(key),
          ProcessingTypeCode: key
        })
    }
    this.loading = false

  }

  getRecibidos(item) {
    return item.cantidadTicketPendientes + item.cantidadTicketResueltos + item.cantidadTicketCancelados + item.cantidadTicketSinEstadosDefinidos;
  }

  getNameByCode(code) {
    const tipo = this.ticketsTypes.find(t => t.id == code);
    return tipo?.descripcion
  }
}

