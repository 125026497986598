<span class="main-title">{{title}}</span>
<div ngbDropdown class="d-inline-block" *ngIf="acciones.show">
    <button type="button" class="btn btn-link" id="dropdownBasic1" ngbDropdownToggle>
        {{acciones.label}} &nbsp;&nbsp;<fa-icon class="float-right" [icon]="faCaretDown"></fa-icon>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <ng-container  *ngFor="let option of acciones.options">
            <button ngbDropdownItem *ngIf="showOption(option)"
                (click)="emitSelected(option)"> {{ option.descripcion }}
            </button>
        </ng-container>
    </div>
</div>