import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {

  /** Título de la ventana de confirmación */
  @Input() confirmationTitle = "";
  /** Mensaje de confirmación */
  @Input() confirmationText = "";
  /** Texto en botón de confirmación */
  @Input() confirmButtonText = "Confirmar";
  /** Texto en botón de cancelar */
  @Input() cancelButtonText = "Cancelar";
  /** Texto en botón de cancelar */
  @Input() forDeletion = false;
  /** Resultado de la confirmación */
  @Output() result = new EventEmitter;

  confirm() {
    this.result.emit(true);
  }

  cancel() {
    this.result.emit(false);
  }

}
