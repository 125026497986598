<app-loadingSpinner [message]="mesaje" [show]="downloading"></app-loadingSpinner>

<div class="row px-3">
    <div class="col-12">
        <span class="page-route">Documentos Importantes</span>
    </div>

    <div class="col-12 py-3">
        <app-title></app-title>
    </div>


    <div class="col-lg-12 col-sm-12">
        <form [formGroup]="filterForm" (ngSubmit)="buscar()">
            
            <div class="d-flex mt-2">
                <div class="d-flex align-items-center">
                    <fa-icon class="ml-2 puntero pointer table-search " [icon]="faSearch"></fa-icon>
                    <input type="text" class="form-control main-content-input" placeholder="Buscar por nombre de archivo"
                        formControlName="dTitulo">
                </div>
                <div class="d-flex align-items-center mx-3">
                    <ng-select class="box-ng-select"  placeholder="Seleccione un Tipo Adjunto" 
                            [clearable]="false" [searchable]="false"
                            formControlName="tipoDocumento">
                        <ng-option value="">Seleccione un Tipo de Documento</ng-option>
                        <ng-option [value]="tipoDocumento._id"
                            *ngFor='let tipoDocumento of tiposAdjuntos'>{{tipoDocumento.description}}</ng-option>
                    </ng-select>
                </div>
                
                <div class="d-flex flex-row align-items-center">
                    <label class="label-entity mr-1 mt-2">Fecha Desde</label>  
                    <input class="main-content-input-ticket date-input" formControlName="createdDateIni"  type="date"  >
                </div>
                <div class="d-flex flex-row mx-3 align-items-center">
                    <label class="label-entity mr-1 mt-2">Fecha Hasta</label>   
                    <input class="main-content-input-ticket date-input"  formControlName="createdDateFin"  type="date"  >
                </div>
                
                <div class="d-flex align-items-center mx-3">
                    <button type="submit" class="btn btn-primary-portal btn-primary btn-sm mr-2">Filtrar</button>
                    <button class="btn btn-outline-secondary btn-sm mx-2" (click)="limpiar()">Limpiar</button>
                </div>
            </div>

            
        </form>
        
        

        <bpe-grid [configuration]="config" [find]="filter" (actions)="clickInfo( $event )">
        </bpe-grid>
    </div>


</div>