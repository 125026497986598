<app-loadingSpinner [message]="loadingMessage" [show]="cargando"></app-loadingSpinner>
<div class="row px-3">
    <div class="col-12">
        <span class="page-route"></span>
    </div>

    <div class="col-12 py-3">
        <app-title></app-title>
    </div>

    <div class="container-fluid">

        <div class="row justify-content-center" *ngIf="!cargando && productosAgrupados.length == 0">
            <div class="col-md-6">
                <div class="text-center">
                    <h1>Lo sentimos</h1>
                    <p class="lead">No se pudo obtener agrupación de productos.</p>
                </div>
            </div>
        </div>

        <div class="container-fluid text-center">
            <div class="row justify-content-center">
                <ng-container *ngFor="let grupo of productosAgrupados">
                    <div class="card text-center m-4" *ngIf="grupo.productos.length > 0" style="width: 22rem !important;">
                    
                    <div class="card-body" >
                        <h5 class="card-title card-group-title text-overflow">{{grupo.nombre}} </h5>
                        
                        <small *ngIf="grupo.productos.length == 0"> El grupo se encuentra vacío.</small>
                        <ul class="list-group text-left" *ngIf="grupo.productos.length > 0">
                            <li class="list-group-item card-item-list" *ngFor="let producto of grupo.productos" (click)="ver(producto)">
                                <strong>{{producto.nombre}}</strong>
                            </li>
                        </ul>
                    </div>

                    
                    </div>
                </ng-container>

            </div>

        </div>
    </div>
</div>