import moment from 'moment';
import { Constantes } from "../constants/constantes.const";

export default class DateUtils {
    
    static addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }

    /** Método para comprobar si el rango entre dos fecha es menor o igual al valor en la constante MAX_MONTHS_RANGE
     * @param startDate fecha inicio a comprobar en formato tradicional
     * @param finalDate fecha final a comprobar en formato tradicional
     * @return TRUE si el rango de entre las fechas en mayor al valor estipulado 
    */
    static isValidDateRange(startDate: string, finalDate: string) {
        if (startDate=='' || finalDate=='') {
            return false;
        } else {
            const since = moment(new Date(startDate));
            const until = moment(new Date(finalDate));
            if (until<since) {
                return false;
            } else {
                return true;
            }
        }
    }

    static isValidDate(dateString:string) {
        const d = new Date(dateString);
        return d.getTime() === d.getTime();
    }

    /** Método para transformar un dato de tipo Date a Epoch
     * @param date fecha a transformar
     * @param string si se requiere el resultado en formato string
     */
    static dateToEpoch(date: Date, string: boolean) {
        if (string) {
            return `/Date(${moment(date).unix()*1000})/`;
        } else {
            return moment(date).unix()*1000;
        }
        
    }

    /**
     * El método permite convertir un texto del tipo X a dd/MM/yyyy
     * @param data 
     * @returns 
     */
    static dateConvertToGrid(data:any){
        if(data) {
            const date = data.split('T')[0].split("-")
            return `${date[2]}/${date[1]}/${date[0]}`
        }
        return ''
    }
    
}
