import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
})
export class UsuariosComponent {

  rol:any;
  selection: any;
  
  constructor (
    private usersSrv: UsersService
  ) {
    this.rol = this.usersSrv.getActualUser().rol;
  }

}
