import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecoveryComponent } from './login/recovery/recovery.component';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthGuardLoginService } from './services/auth-guardlog.component';
import { CreatepasswordComponent } from './login/createpassword/createpassword.component';
import { ExpiredpasswordComponent } from './login/expiredpassword/expiredpassword.component';


const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'login'},
  {path: 'login',  canActivate:[AuthGuardLoginService],component: LoginComponent, data: { titulo: "Login"}},
  {path: 'login/recovery', component: RecoveryComponent, data: { titulo: "Recuperar contraseña"}},
  {path: 'login/createpassword', component: CreatepasswordComponent, data: { titulo: "Crear contraseña"}},
  {path: 'login/passwordexpired', component: ExpiredpasswordComponent, data: { titulo: "Contraseña expirada"}},
  {path: 'change-password', component: ChangePasswordComponent, data: { titulo: "Modificar contraseña"}}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
