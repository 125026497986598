import { Component, OnInit } from '@angular/core';
import { faSpinner, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Ejecucion } from 'src/app/interfaces/interfaces';
import { DownloadProgressService } from 'src/app/services/download-progress.service';

@Component({
  selector: 'bpe-download-progress',
  templateUrl: './download-progress.component.html',
  styleUrls: ['./download-progress.component.scss']
})
export class DownloadProgressComponent implements OnInit {
  
  message= ''; 
  title= '';
  isHidden = true;
  isCollapse = false;

  faSpinner=faSpinner;
  faAngleDown=faAngleDown
  faAngleUp=faAngleUp

  constructor (private downloadProgressSrv: DownloadProgressService) {  }
  ngOnInit() {
    this.downloadProgressSrv.getExecutions()
    .subscribe(async (execution: Ejecucion) => {
      this.isHidden = execution.isHidden
      this.title = execution.title ? execution.title : ''
      this.message = execution.mensaje ? execution.mensaje : '' 
    });
  }
  collapse(){
    this.isCollapse = !this.isCollapse;
  }
}
