import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Regex } from "src/app/constants/regex.const";

export const formBankAccount = new FormGroup({
  moneda: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
    Validators.pattern(Regex.ENTIDADES),
  ]),
  entidad: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
    Validators.pattern(Regex.ENTIDADES),
  ]),
  tipoCuenta: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  numeroCuenta: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
    Validators.pattern(Regex.TELEFONO),
  ]),
  sucursal: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  titular: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  entidadid: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  tipoDocumento: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  numeroDocumento: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
});
