<app-loadingSpinner [message]="loadingMessage" [show]="loading"></app-loadingSpinner>
<div class="row px-3 new-entity ">
    <div class="col-12">
        <a class="page-route pointer" (click)="getBack()">Usuarios&nbsp;</a>
        <span class="page-route">/&nbsp;</span>
        <span class="page-route">Editar Usuario</span>
    </div>
    <div class="col-12 py-3">
        <app-title></app-title>
    </div>
    <div class="col-lg-12 col-sm-12">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1">
                <a ngbNavLink>Información del Usuarios</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-8">
                            <form [formGroup]="formEditUser" (ngSubmit)="editUser()" class="col-12">
                                <div class="col-12 pt-4">
                                    <label class="label-entity">Usuario</label>
                                </div>
                                <div class="col-12 ">
                                    <input class="main-content-input input-agregar" formControlName="username"
                                        type="text" placeholder="Nombre de Usuario">
                                    <app-field-error [field]="formEditUser.controls['username']"></app-field-error>
                                </div>
                                <div class="col-12 pt-4">
                                    <label class="label-entity">Nombre</label>
                                </div>
                                <div class="col-12">
                                    <input class="main-content-input input-agregar" formControlName="name" type="text"
                                        placeholder="Nombre">
                                    <app-field-error [field]="formEditUser.controls['name']"></app-field-error>
                                </div>
                                <div class="col-12 pt-4">
                                    <label class="label-entity">Apellido</label>
                                </div>

                                <div class="col-12 ">
                                    <input class="main-content-input input-agregar" formControlName="surname"
                                        type="text" placeholder="Apellido">
                                    <app-field-error [field]="formEditUser.controls['surname']"></app-field-error>
                                </div>
                                <div class="col-12 py-3">
                                    <button type="submit" class="btn btn-primary-portal  btn-primary btn-sm">
                                        <span class="text-btn-main">Editar</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="col-4 ml-auto h-50 box-descripcion-ticket d-flex flex-column mt-4" *ngIf="!loading">
                            <div class="row my-2" *ngIf="userToEdit?.usuarioCreacion">
                                <div class="col-5">
                                    <label class="label-entity font-weight-bold">Usuario de creaci&oacute;n:</label>
                                </div>
                                <div class="col-7">
                                    <span class="value-data">{{ userToEdit?.usuarioCreacion }}</span>
                                </div>
                            </div>
                            <div class="row my-2" *ngIf="userToEdit?.fechaCreacion && userToEdit?.fechaCreacion!=''">
                                <div class="col-5">
                                    <label class="label-entity font-weight-bold">Fecha de creaci&oacute;n:</label>
                                </div>
                                <div class="col-7">
                                    <span class="value-data">{{ userToEdit?.fechaCreacion }}</span>
                                </div>
                            </div>
                            <div class="row my-2" *ngIf=" userToEdit?.usuarioActualizacion ">
                                <div class="col-5">
                                    <label class="label-entity font-weight-bold">Usuario de modificaci&oacute;n:</label>
                                </div>
                                <div class="col-7">
                                    <span class="value-data">{{ userToEdit?.usuarioActualizacion }}</span>
                                </div>
                            </div>
                            <div class="row my-2" *ngIf="userToEdit?.fechaActualizacion && userToEdit?.fechaActualizacion!=''">
                                <div class="col-5">
                                    <label class="label-entity font-weight-bold">Fecha de modificaci&oacute;n:</label>
                                </div>
                                <div class="col-7">
                                    <span class="value-data">{{ userToEdit?.fechaActualizacion }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink>Roles del Usuario</a>
                <ng-template ngbNavContent>
                    <div>
                        <form [formGroup]="formAssignRol">
                            <div class="col-12 pt-4">
                                <label class="label-entity">Rol</label>
                            </div>
                            <div class="col-12 ">
                                <ng-select [multiple]="true" placeholder="Rol" [clearable]="false" [searchable]="false"
                                    formControlName="rol">
                                    <ng-option value="">Selecciones los roles</ng-option>
                                    
                                    <ng-option [value]="rol._id"
                                        *ngFor='let rol of rolesPosibles'
                                        [disabled]='!rol.visible_entidades'>{{rol.codigo}}</ng-option>

                                </ng-select>
                                <app-field-error [field]="formAssignRol.controls['rol']"></app-field-error>
                            </div>
                        </form>
                    </div>
                    <div class="col-12 pt-4" *ngIf="!formAssignRol.pristine">
                        <div class="alert alert-warning" role="alert" style="padding: 5px !important">
                            <fa-icon [icon]="faCircleExclamation"></fa-icon>&nbsp;&nbsp; Para confirmar los cambios de
                            roles debe presionar el botón 'Guardar roles'
                        </div>
                    </div>
                    <div class="col-12 pt-4">
                        <h5 class="mt-2">Detalle de roles</h5>
                        <p-table [value]="rolesSelected" [tableStyle]="{'min-width': '50rem'}">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Código</th>
                                    <th>Descripción</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-r>
                                <tr>
                                    <td>{{r.codigo}}</td>
                                    <td>{{r.descripcion}}</td>

                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                    <div class="col-12 pt-4">
                        <h5 class="mt-2">Permisos asignados</h5>
                        <ul class="row-ul">
                            <li *ngFor="let p of privilegiosAsignados"><small> {{p.descripcion}} </small></li>
                        </ul>
                    </div>
                    
                    <div class="d-flex justify-content-end py-3">
                        <button class="btn btn-primary-portal btn-primary btn-sm  mx-2" (click)="guardarRoles()" [disabled]="loading">
                            <span class="text-btn-main">Guardar roles</span>
                        </button>
                    </div>
                </ng-template>
            </li>

            <li [ngbNavItem]="3">
                <a ngbNavLink>Perfiles del Usuarios</a>
                <ng-template ngbNavContent>
                    <div>
                        <form [formGroup]="formAssignPerfil">
                            <div class="col-12 pt-4">
                                <label class="label-entity">Perfil</label>
                            </div>
                            <div class="col-12 ">
                                <ng-select [multiple]="true" placeholder="Perfiles" [clearable]="false" [searchable]="false"
                                    formControlName="perfiles">
                                    <ng-option value="">Selecciones los perfiles</ng-option>
                                    <ng-option [value]="perfil._id"
                                        *ngFor='let perfil of perfilesPosibles'>{{perfil.nombre}}</ng-option>

                                </ng-select>
                                <app-field-error [field]="formAssignPerfil.controls['perfiles']"></app-field-error>
                            </div>

                        </form>
                    </div>
                    <div class="col-12 pt-4" *ngIf="!formAssignPerfil.pristine">
                        <div class="alert alert-warning" role="alert" style="padding: 5px !important">
                            <fa-icon [icon]="faCircleExclamation"></fa-icon>&nbsp;&nbsp; Para confirmar los cambios de
                            perfiles debe presionar el botón 'Guardar perfiles'
                        </div>
                    </div>
                    <div class="col-12 pt-4">
                        <h5 class="mt-2">Detalle de perfiles</h5>
                        <p-table [value]="perfilesSelected" [tableStyle]="{'min-width': '50rem'}">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-r>
                                <tr>
                                    <td>{{r.codigo}}</td>
                                    <td>{{r.nombre}}</td>

                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                    <div class="d-flex justify-content-end py-3">

                        <button class="btn btn-primary-portal btn-primary btn-sm mx-2" (click)="guardarPerfiles()" [disabled]="loading">
                            <span class="text-btn-main">{{ loading ? 'Procesando' : 'Guardar perfiles'}}</span>
                            <fa-icon *ngIf="loading" [icon]="faSpinner" animation="spin"></fa-icon>
                        </button>
                    </div>

                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>
