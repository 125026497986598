import { Component, Input, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Mensajes } from 'src/app/constants/mensajes.const';
import { OcService } from 'src/app/services/oc.service';
import moment from 'moment';

@Component({
  selector: 'app-tabla-productos-asociados-detalle',
  templateUrl: './tabla-productos-asociados-detalle.component.html',
  styleUrls: ['./tabla-productos-asociados-detalle.component.scss']
})
export class TablaProductosAsociadosDetalleComponent implements OnInit {

  //inputs y outputs
  @Input() editable;
  @Input() ocObjectId;

  //icons
  faSpinner=faSpinner;

  //arrays
  productListLocal: any[] = [];


  //paginacion
  page=1;
  collectionSize=0;
  pageSize=5;

  //booleans
  loading = false;


  constructor (
    private ocService: OcService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getProducts();
  }

  /** Método para obtener los productos asociados a la oc actual */
  async getProducts() {
    this.loading = true;
    const products = await this.ocService.getOportunityProducts(this.ocObjectId);
    if (products) {
      products.forEach(product => {
        if (product.Z_FECHAHASTADESCUENTO_KUT) {
          product.Z_FECHAHASTADESCUENTO_KUT=moment(product.Z_FECHAHASTADESCUENTO_KUT).toDate();
        }
      });
      this.productListLocal = products;
      this.collectionSize = this.productListLocal.length;
    } else {
      this.toastr.error(Mensajes.ERROR_AL_CARGAR_PRODUCTOS,'',{positionClass:'toast-top-center', progressBar:false});
    }
    this.loading = false;
  }

  formatNumber(number:string){
    return number.split('.')[0];
  }
}
