import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { faSearch, faRedo, faDownload, faPlus, faSpinner, faTrash, faCheckCircle, faCancel, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { StorageService } from 'src/app/services/storage.service';
import { WorkBook, WorkSheet, utils, writeFile } from 'xlsx';
import moment from 'moment';
import { Basico, CategoriaTicket, GrupoTicket, IncidenciaTicket, Param, ParamOData, Ticket, TicketFilterParams, TicketFilterShowParams, TipoTicket, Usuario } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/services/users.service';
import { AuditService } from 'src/app/services/audit.service';
import { Subscription } from 'rxjs';
import { MongoDBService } from 'src/app/services/mongoDB.service';
import DateUtils from 'src/app/utils/dateUtils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { TicketService } from 'src/app/services/ticket.service';
import { ComentariosService } from 'src/app/services/comentarios.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Constantes } from 'src/app/constants/constantes.const';
import { Mensajes } from 'src/app/constants/mensajes.const';
import { LocationStrategy } from '@angular/common';


@Component({
  selector: 'app-listado-tickets',
  templateUrl: './listado-tickets.component.html'
})
export class ListadoTicketsComponent implements OnDestroy {

  @ViewChild('tabla') table!: ElementRef;
  //icons
  faSpinner = faSpinner;
  faRedo = faRedo;
  faSearch = faSearch;
  faDownload = faDownload;
  faPlus = faPlus;
  faTrash = faTrash;
  faCheckCircle = faCheckCircle;
  faCancel = faCancel;
  faExternalLinkAlt = faExternalLinkAlt;
  //filtros
  filterNumber = '';
  filterDocumentNumber = '';
  filterType: string[] = [];
  filterCategory: string[] = [];
  filterIncident: string[] = [];
  filterPriority: string[] = [];
  filterState: string[] = [];
  filterUser: string[] = [];
  filterCreateSince: string = moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD');
  filterCreateUntil: string = moment(new Date()).format('YYYY-MM-DD');
  includeClossed = false;
  searchText = '';
  loadingMessage = new BehaviorSubject("Obteniendo detalles de ticket");
  //datos
  subs: Subscription[] = [];
  tickets: Ticket[] = [];
  ticketsTypes: TipoTicket[] = [];
  ticketsTypesCategories: CategoriaTicket[] = [];
  ticketsTypesCategoriesIncidents: IncidenciaTicket[] = [];
  ticketsPriorities: Basico[] = [];
  ticketsStatuses: Basico[] = [];
  ticketsStatusesAll: Basico[] = [];
  users: Usuario[] = [];

  loading = false;
  exporting = false;
  filterApplied = false;
  showFilters = false;
  hasPermissionForATMVeredicts = false;
  hasPermissionForContacts = false;
  hasPermissionForAnulations = false;
  dateChanging = false;
  //Paginado
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  skip = 0;
  navTickets: GrupoTicket[] = []

  groupToTickets!: GrupoTicket;
  tiposTickets: TipoTicket[] = []
  queryOdData: any = {};
  queries: any[] = [];

  selectedIndex = 0;

  columns = [
    { header: '' },
    { header: 'Nro. ticket' },
    { header: 'Nro. boleta' },
    { header: 'Asunto' },
    { header: 'Tipo ticket' },
    { header: 'Categoria nivel 1' },
    { header: 'Categoria nivel 2' },
    { header: 'Prioridad' },
    { header: 'Estado' },
    { header: 'Fecha' },
    { header: 'Creado por' }
  ]

  filtros: TicketFilterParams = {
    ProcessingTypeCode: [],
    ServiceIssueCategoryID: [],
    IncidentServiceIssueCategoryID: [],
    ServicePriorityCode: [],
    ServiceRequestUserLifeCycleStatusCode: [],
    Z_CREADOPOR_KUT: "",
    Z_NUMERODEBOLETA_KUT: "",
    ID: "",
    includeClosed: false,
    Name:""
  }

  showFiltros: TicketFilterShowParams = {
    Z_NUMERODEBOLETA_KUT: true,
    ProcessingTypeCode: false,
    ServiceIssueCategoryID: false,
    IncidentServiceIssueCategoryID: false,
    ServicePriorityCode: false,
    ServiceRequestUserLifeCycleStatusCode: false,
    Z_CREADOPOR_KUT: false,
    Name: false
  }

  aceptarAnulacion = false;
  dataAceptarAnulacion: any = {}

  aprobarAnulacion = "YF";
  rechazarAnulacion = "YE";
  aprobarAnulacion2 = "Z7";
  rechazarAnulacion2 = "Z4";
  Estados: any = {};
  participantesTicketList: any[] = [];
  recipientsEmail: any = [];
  editarMedioDePago = false;
  mostrarModalAnulacion = false;
  mostrarBotones = false;
  paymentMethods : Basico[] = [];
  ticketsTypesInTabs: TipoTicket[] = [];

  tabs: { codigo: string, title: string, ID?:any }[] = [
    { codigo: 'LIST', title: 'Listado de tickets' }    
  ];

  constructor(
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private storageSrv: StorageService,
    private usersSrv: UsersService,
    private dataSrv: DataService,
    private toastSrv: ToastrService,
    private auditSrv: AuditService,
    private mongoSrv: MongoDBService,
    private modalSrv: NgbModal,
    private toastr: ToastrService,
    private ticketsSrv: TicketService,
    private comentsSrv: ComentariosService,
    private notificationSrv: NotificationService,
    private location: LocationStrategy,
  ) {
    this.title.setTitle(`${this.activatedRoute.snapshot.data.titulo} - ${environment.app_name}`);
    this.subs = []
    // obtiene el estado de carga de tickets
    this.subs.push(
      this.dataSrv.getPreLoadStatus().subscribe(cargado => {
        if (cargado) {
          this.loadData()
        }
      })
    );
    
    history.pushState(null, '', window.location.href);
    this.location.onPopState(() => {
        history.pushState(null, '', window.location.href);
        this.selectTab(0);
    });
  }
  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe()
    }

    this.subs = []
  }

  async onInit() {
    await this.focusTab();
    // Obtiene filtros guardados
    await this.getFilters();
    // obtiene datos de pestanaña de tickets actual
    await this.getData();
  }

  async loadData() {
    setTimeout(async () => {
      this.dataSrv.setCargando(true);
      await Promise.all([
        this.dataSrv.loadTicketsTypes(),
        this.dataSrv.loadTicketsStatuses(),
        this.dataSrv.loadTicketsPriorities(),
        this.dataSrv.loadTicketsClassifications(),
        this.dataSrv.loadGruposTickets(),
        this.dataSrv.loadQueriesOdata(),
        this.dataSrv.loadPaymentMethods(),
      ]);
      this.dataSrv.setCargando(false);
      // obtiene el estado de carga de tickets

      this.subs.push(this.dataSrv.getLoadingStatus().subscribe(loading => {
        this.loading = loading
      }));
      // obtiene los tipos de tickets
      this.subs.push(this.dataSrv.getTicketsTypes().subscribe(types => {
        this.ticketsTypes = types;
      }));
      // obtiene las categiruas de tickets
      this.subs.push(this.dataSrv.getTicketsTypesCategories().subscribe(categories => {
        this.ticketsTypesCategories = categories;
      }));
      // obtiene los incidencias de tickets
      this.subs.push(this.dataSrv.getTicketsTypesCategoriesIncidents().subscribe(incidents => {
        this.ticketsTypesCategoriesIncidents = incidents;
      }));
      // obtiene las prioridades de tickets
      this.subs.push(this.dataSrv.getTicketsPriorities().subscribe(data => {
        this.ticketsPriorities = data;
      }));
      // obtiene los estados de tickets
      this.subs.push(this.dataSrv.getTicketsStatuses().subscribe(statuses => {
        this.ticketsStatusesAll = statuses;
      }));

      this.subs.push(this.dataSrv.getPaymentMethods().subscribe(methods => {
        this.paymentMethods = methods;
      }));

      this.subs.push(this.dataSrv.getTicketsTypes().subscribe(ticket => {
        this.tiposTickets = ticket;
      }));

      await this.subs.push(this.dataSrv.getQueriesOdata().subscribe(consultas => {
        this.queries = consultas;
      }));

      await this.subs.push(this.dataSrv.getGruposTickets().subscribe(grupos => {
        this.navTickets = grupos;
        this.onInit()
      }));

      // obtienes los usuarios
      this.usersSrv.getUsers().then(users => {
        if (users) {
          this.users = users;
        }
      })
    })
  }

  async focusTab() {
    const nGrupos: GrupoTicket[] = [];
    this.loading = true;
    /* this.loadingMessage.next("Obteniendo grupos de tickets");
    const data = await this.mongoSrv.getAll(`/grupos-tickets?orderBy=orden&orderDir=asc&size=-1`);
    if (data) {
      nGrupos = data.datos
    }
    this.navTickets = nGrupos; */
    this.ticketsStatuses = []
    const tab = this.storageSrv.get('current-tab', true);
    let hasPerm = false
    if (tab) {
      const nav = JSON.parse(tab)
      if (this.usersSrv.actualUSerHasPerm([nav.permisos])) {
        this.groupToTickets = nav
        this.getQueryOData();
        hasPerm = true
      }
    }
    if (!hasPerm) {
      for (const nav of this.navTickets) {
        if (this.usersSrv.actualUSerHasPerm([nav.permisos])) {
          this.groupToTickets = nav
          this.getQueryOData();
          break
        }
      }
    }

  }

  getParams() {
    const params: Param[] = [];
    if (this.groupToTickets && this.groupToTickets.cpi) {
      params.push({ key: 'zEmail', value: this.usersSrv.getActualUser().email })
      params.push({ key: 'zRolCode', value: "213" })
      params.push({ key: 'zTipoTicket', value: "ZVE" })
      params.push({ key: 'codigo', value: this.groupToTickets.codigo })
      const f1 = this.filterCreateSince != '';
      const f2 = this.filterCreateUntil != '';
      if (f1 || f2) {
        params.push({ key: 'zDesde', value: `${this.filterCreateSince}`})
        if (f2) {
          params.push({ key: 'zHasta', value: `${this.filterCreateUntil}`})
        }
      }
    } else {
      params.push({ key: 'top', value: this.pageSize.toString() })
      params.push({ key: 'skip', value: this.skip.toString() })
      if (this.groupToTickets) {
        params.push({ key: 'codigo', value: this.groupToTickets.codigo })
      }
      params.push({ key: 'includeClosed', value: this.filtros.includeClosed.toString() })

      const valueFilter = this.generateFilterToRequest()

      if (valueFilter) {
        params.push({ key: 'filters', value: JSON.stringify(valueFilter) })
      }
    }
    return params
  }

  async getData() {
    if (this.groupToTickets) {
      this.loading = true;
      this.loadingMessage.next("Obteniendo tickets");
      this.tickets = [];
      this.collectionSize = 0
      const params = this.getParams()

      this.auditSrv.logAction({
        modulo: "Tickets",
        accion: "Obtener tickets",
        detalle: `Tipo: ${this.groupToTickets.titulo}, Filtros: ${JSON.stringify(params)}`
      })

      const data = await this.mongoSrv.get(`/grupos-tickets/tickets/`, params);

      if (data) {
        if (this.groupToTickets.cpi) {
          const nTickets: Ticket[] = [];
          if (data && data.length === undefined) {
            const tk = data
            const ticket: Ticket = {
              OID: tk.zObjectID,
              numero: tk.zID,
              boleta: tk.Z_NUMERODEBOLETA_KUT,
              descripcion: tk.zName,
              tipo: this.dataSrv.getTicketType(tk.zProcessingTypeCode, tk.zServiceIssueCategoryID, tk.zIncidentServiceIssueCategoryID),
              prioridad: { id: tk.zServicePriorityCode, descripcion: tk.zServicePriorityCodeText },
              estado: { id: tk.zServiceRequestUserLifeCycleStatusCode, descripcion: tk.zServiceRequestUserLifeCycleStatusCodeText },
              fechaUltimoCambio: moment(tk.zLastChangeDateTime).toDate(),
              fechaDeCreacion: moment(tk.zCreationDateTime).toDate(),
              creadoPor: tk.z_CREADOPOR ? tk.z_CREADOPOR.toLowerCase() : tk.z_CREADOPOR,
            };
            nTickets.push(ticket);
            this.tickets = nTickets;
            this.collectionSize = 1
            this.loading = false;
          } else if (data.length > 0) {
            data.forEach(element => {
              const ticket: Ticket = {
                OID: element.zObjectID,
                numero: element.zID,
                boleta: element.Z_NUMERODEBOLETA_KUT,
                descripcion: element.zName,
                tipo: this.dataSrv.getTicketType(element.zProcessingTypeCode, element.zServiceIssueCategoryID, element.zIncidentServiceIssueCategoryID),
                prioridad: { id: element.zServicePriorityCode, descripcion: element.zServicePriorityCodeText },
                estado: { id: element.zServiceRequestUserLifeCycleStatusCode, descripcion: element.zServiceRequestUserLifeCycleStatusCodeText },
                fechaUltimoCambio: moment(element.zLastChangeDateTime).toDate(),
                fechaDeCreacion: moment(element.zCreationDateTime).toDate(),
                creadoPor: element.z_CREADOPOR ? element.z_CREADOPOR.toLowerCase() : element.z_CREADOPOR,
              };
              nTickets.push(ticket);
            });

            this.tickets = nTickets;
            this.collectionSize = nTickets.length

            this.loading = false;
          } else {
            this.collectionSize = 0
            this.loading = false;
          }


        } else {

          const ticketsList = data.d.results;
          const nTickets: Ticket[] = [];

          ticketsList.forEach(element => {

            const ticket: Ticket = {
              OID: element.ObjectID,
              numero: element.ID,
              boleta: element.Z_NUMERODEBOLETA_KUT,
              descripcion: element.Name,
              tipo: this.dataSrv.getTicketType(element.ProcessingTypeCode, element.ServiceIssueCategoryID, element.IncidentServiceIssueCategoryID),
              prioridad: { id: element.ServicePriorityCode, descripcion: element.ServicePriorityCodeText },
              estado: { id: element.ServiceRequestUserLifeCycleStatusCode, descripcion: element.ServiceRequestUserLifeCycleStatusCodeText },
              fechaUltimoCambio: moment(element.LastChangeDateTime).toDate(),
              fechaDeCreacion: moment(element.CreationDateTime).toDate(),
              creadoPor: element.Z_CREADOPOR_KUT ? element.Z_CREADOPOR_KUT.toLowerCase() : element.Z_CREADOPOR_KUT,
              pago: { 
                id: element.zCabMediodePago_KUT ? element.zCabMediodePago_KUT : '', 
                descripcion: element.zCabMediodePago_KUTText ? element.zCabMediodePago_KUTText : '', 
                numerodereferencia: element.zCabNumerodereferencia_KUT ? element.zCabNumerodereferencia_KUT : '', 
                fechaTransferencia: element.Z_FECHATRANSFERENCIA_KUT? moment.utc(element.Z_FECHATRANSFERENCIA_KUT).format("YYYY-MM-DD") : '' },
            };
            
            nTickets.push(ticket);
          });

          this.tickets = nTickets;
          this.collectionSize = data.d.__count
          if (this.collectionSize == 1 && (this.filtros.Z_NUMERODEBOLETA_KUT || this.filtros.ID)) {

            this.selectNavToShow(ticketsList[0].ProcessingTypeCode)

          }
          this.loading = false;
        }
      } else {
        this.loading = false;
        this.toastSrv.error(data.error, '', { positionClass: 'toast-top-center', progressBar: false })
      }
    }
  }

  /** Método para mostrar ticket de un tipo
  * @param aMostrar tipo de tiquet a buscar
  */
  async showTickets(aMostrar) {
    this.ticketsStatuses = []
    this.ticketsTypesInTabs = this.ticketsTypes
    this.saveTab(aMostrar)
    this.groupToTickets = aMostrar
    await this.getQueryOData();
    this.page = 1;
    this.getData()
  }

  saveTab(tab) {
    this.storageSrv.save('current-tab', JSON.stringify(tab), true);
  }

  selectNavToShow(type) {
    let queryFound: any = this.queries[0];
    for (const query of this.queries) {
      for (const filtro of query.filtros) {
        if (filtro.property == 'ProcessingTypeCode' && filtro.defaultOpertator == 'eq'
          && filtro.defaultValue && filtro.defaultValue == type) {
          queryFound = query;
          break
        }

        if (filtro.property == 'ProcessingTypeCode' && filtro.defaultOpertator == 'in'
          && filtro.defaultValue && filtro.defaultValue.split(',').includes(type)) {
        queryFound = query;
        break
      }
      }
    }

    if (queryFound) {
      for (const grupo of this.navTickets) {
        if (grupo.codigoQuery == queryFound.codigo) {
          if (this.usersSrv.actualUSerHasPerm([grupo.permisos])) {
            this.groupToTickets = grupo
            break
          } else {
            this.tickets = []
          }
        }
      }
    }
  }
  updatePagination() {
    this.skip = this.pageSize * (this.page - 1);
    this.getData()
  }

  async getQueries() {

    this.queries = await this.mongoSrv.getAll(`/grupos-tickets/queries`);
  }
  async getQueryOData() {
    if (this.groupToTickets && !this.groupToTickets.cpi) {
      for (const q of this.queries) {
        if (q.codigo == this.groupToTickets.codigoQuery) {
          this.queryOdData = q
          break;
        }
      }

      this.checkFiltersToShow()
    } else {
      this.showFiltros = {
        Z_NUMERODEBOLETA_KUT: false,
        ProcessingTypeCode: false,
        ServiceIssueCategoryID: false,
        IncidentServiceIssueCategoryID: false,
        ServicePriorityCode: true,
        ServiceRequestUserLifeCycleStatusCode: true,
        Z_CREADOPOR_KUT: true,
        Name: true
      }
      this.ticketsStatuses = this.ticketsStatusesAll
    }
  }

  /** Metodo para chequear que filtros serán mostrados en cada pestaña, según la información de this.queryOdata */
  async checkFiltersToShow() {

    if (this.groupToTickets && !this.groupToTickets.cpi) {
      const filtrosEstadosSeleccionados = [...this.filtros.ServiceRequestUserLifeCycleStatusCode]
      
      this.filtros.ProcessingTypeCode = []
      this.filtros.ServiceIssueCategoryID = []
      this.filtros.IncidentServiceIssueCategoryID = []
      this.filtros.ServiceRequestUserLifeCycleStatusCode = []
      
      
      this.showFiltros = {
        Z_NUMERODEBOLETA_KUT: true,
        ProcessingTypeCode: false,
        ServiceIssueCategoryID: false,
        IncidentServiceIssueCategoryID: false,
        ServicePriorityCode: false,
        ServiceRequestUserLifeCycleStatusCode: false,
        Z_CREADOPOR_KUT: false,
        Name: false
      }
      if (this.queryOdData && this.queryOdData.filtros) {
        for (const filtro of this.queryOdData.filtros) {
          this.showFiltros[filtro.property] = filtro.showInForm
          if (filtro.property == 'ProcessingTypeCode' && filtro.defaultOpertator == 'eq' && filtro.defaultValue) {
            this.filtros.ProcessingTypeCode = [filtro.defaultValue]
            const tipoEncontrado = await this.ticketsTypes.find(tipo => tipo.id === filtro.defaultValue);
            const previouslySelectedSatutes: string[] = []
            this.ticketsStatuses = []
            if (tipoEncontrado) {
              if (tipoEncontrado.estadosPosibles) {
                this.ticketsStatuses.push(...tipoEncontrado.estadosPosibles);
                if (filtrosEstadosSeleccionados.length > 0)
                  await this.ticketsStatuses.forEach(status => {
                    if (filtrosEstadosSeleccionados.includes(status.id.toString())) {
                      previouslySelectedSatutes.push(status.id.toString())
                    }
                  })
                this.filtros.ServiceRequestUserLifeCycleStatusCode = previouslySelectedSatutes
              }
            }
          }
        
        if(filtro.property == 'ProcessingTypeCode' && filtro.defaultOpertator == 'in' && filtro.defaultValue) {
          const tipos = filtro.defaultValue.split(',')
          const tiposEncontrados = await this.ticketsTypes.filter((tipo) => tipos.includes( tipo.id ));
          this.ticketsTypesInTabs = tiposEncontrados
          const previouslySelectedSatutes: string[] = []
          this.ticketsStatuses = []
          if (tiposEncontrados && tiposEncontrados.length > 0) {
            for(const tipoEncontrado of tiposEncontrados) {
              if(tipoEncontrado.estadosPosibles){
                const estadosToAdd: Basico[]=[]
                await this.ticketsStatuses.forEach(status => {
                  
                  if(tipoEncontrado.estadosPosibles){
                    for(const estado of tipoEncontrado.estadosPosibles) {
                      if(estado.id == status.id) {
                        estadosToAdd.push(status)
                      }
                    }
                  }
                })
                
                this.ticketsStatuses.push(...estadosToAdd);
                
                if(filtrosEstadosSeleccionados.length > 0)
                  await this.ticketsStatuses.forEach(status => {
                    if(filtrosEstadosSeleccionados.includes(status.id.toString())) {
                      previouslySelectedSatutes.push(status.id.toString())
                    }
                  })
                  this.filtros.ServiceRequestUserLifeCycleStatusCode = previouslySelectedSatutes
              }
            }
          }
        }
        if(!filtro.showInForm && filtro.property == 'ServiceIssueCategoryID' && filtro.defaultOpertator == 'eq' && filtro.defaultValue) {
          this.filtros.ServiceIssueCategoryID = [filtro.defaultValue]
        }

          if (!filtro.showInForm && filtro.property == 'IncidentServiceIssueCategoryID' && filtro.defaultOpertator == 'eq' && filtro.defaultValue) {
            this.filtros.IncidentServiceIssueCategoryID = [filtro.defaultValue]
          }
        }
      }

      this.saveFilters()
    }

  }

  /** Método para filtrar las categorias teniendo en cuenta los tipo de tickets seleccionados en el filtro */
  filterCategories() {
    if (this.filterType.length == 0) {
      this.filterCategory = [];
      this.filterIncident = [];
    }
  }

  /** Método para filtrar los incidentes teniendo en cuenta las categorias seleccionadas en el filtro */
  filterIncidents() {
    if (this.filterCategory.length == 0) {
      this.filterIncident = [];
    }
  }

  /** Método para filtrar los estados teniendo en cuenta los tipo de tickets seleccionados en el filtro */
  filterStatuses() {
    if (this.filtros.ProcessingTypeCode && this.filtros.ProcessingTypeCode.length > 0) {
      this.ticketsStatuses = [];
      this.filtros.ProcessingTypeCode.forEach(seleccionado => {
        const tipoEncontrado = this.ticketsTypes.find(tipo => tipo.id === seleccionado);

        if (tipoEncontrado) {
          if (tipoEncontrado.estadosPosibles) {
            this.ticketsStatuses.push(...tipoEncontrado.estadosPosibles);
          }
        }
      });
    } else {
      this.ticketsStatuses = [];
      this.filtros.ServiceRequestUserLifeCycleStatusCode = []
    }
  }

  /** Método para mostrar/ocultar los filtros */
  toogleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  async clearFilters() {
    this.loading = true;
    this.loadingMessage.next("Limpiando filtros");
    this.ticketsStatuses = []

    this.deleteFilters(true);

    this.page = 1;
    this.skip = this.pageSize * (this.page - 1);

    await this.getQueryOData();
    await this.getData()
  }

  /** Método para aplicar los filtros seleccionados */
  generateFilterToRequest() {
    const idEntidad = this.usersSrv.getActualUser().entidad?.id.toString();
    const codEntidad = this.usersSrv.getActualUser().entidad?.codigoEntidad;

    const filters: ParamOData[] = [];
    for (const key in this.filtros) {
      if (this.filtros[key] instanceof Array && this.filtros[key].length > 0) {
        filters.push({ key: key, value: this.filtros[key], operation: 'in' })
      } else if (this.filtros[key] != '') {
        filters.push({ key: key, value: this.filtros[key] })
      }
    }
    filters.push({ key: 'Z_ENTIDAD_KUT', value: [idEntidad, codEntidad], operation: 'in' })
    filters.push({ key: 'Z_CabEntidadDueadelATM_KUT', value: [idEntidad, codEntidad], operation: 'in' })

    const f1 = this.filterCreateSince != '';
    const f2 = this.filterCreateUntil != '';
    if (f1 || f2) {
      filters.push({ key: 'CreationDateTime', value: `${new Date(this.filterCreateSince).toISOString()}`, operation: 'ge' });
      if (f2) {
        let h = new Date(this.filterCreateUntil);
        h = DateUtils.addDays(h, 1);
        filters.push({ key: 'CreationDateTime', value: `${h.toISOString()}`, operation: 'le' });
      }
    }

    return filters
  }


  getFilters() {
    const filtersSaved = this.storageSrv.get('filtros-tickets', true);
    const filterCreateSinceSaved = this.storageSrv.get('filterCreateSince', true);
    const filterCreateUntilSaved = this.storageSrv.get('filterCreateUntil', true)
    if (filtersSaved) {
      const fObject = JSON.parse(filtersSaved);
      this.filtros = fObject;
      if(!this.isFilerEmpty()){
        this.showFilters = true
      }
    }
    if (filterCreateSinceSaved) {
      const fs = JSON.parse(filterCreateSinceSaved);
      if (fs) {
        this.filterCreateSince = fs
      }
    }

    if (filterCreateUntilSaved) {
      const fs = JSON.parse(filterCreateUntilSaved);
      if (fs) {
        this.filterCreateUntil = fs
      }
    }
  }

  saveFilters() {
    this.storageSrv.save('filtros-tickets', JSON.stringify(this.filtros), true)
    this.storageSrv.save('filterCreateSince', JSON.stringify(this.filterCreateSince), true)
    this.storageSrv.save('filterCreateUntil', JSON.stringify(this.filterCreateUntil), true)
  }

  deleteFilters(deleteDates: boolean) {
    this.filtros = {
      ProcessingTypeCode: [],
      ServiceIssueCategoryID: [],
      IncidentServiceIssueCategoryID: [],
      ServicePriorityCode: [],
      ServiceRequestUserLifeCycleStatusCode: [],
      Z_CREADOPOR_KUT: "",
      Z_NUMERODEBOLETA_KUT: "",
      ID: "",
      includeClosed: false,
      Name: ""
    }

    this.storageSrv.remove('filtros-tickets', true)

    if (deleteDates) {
      this.filterCreateSince = moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD');
      this.filterCreateUntil = moment(new Date()).format('YYYY-MM-DD');

      this.storageSrv.remove('filterCreateSince', true)
      this.storageSrv.remove('filterCreateUntil', true)
    }
  }


  filtrar() {
    this.saveFilters()
    this.page = 1;
    this.skip = this.pageSize * (this.page - 1);
    this.getData()
  }

  /** Método para navegar a la página de creación de tickets */
  newTicket() {
    this.router.navigate(['tickets', 'crearticket']);
  }

  /** Método para navegar a la página detalles de tickets
   * @ticketNumber Número de ticket del se desea ver los detalles
   */
  goToDetails(ticketNumber: number) {
    this.addDetailTab(ticketNumber)
    //this.router.navigate(['tickets', ticketNumber, 'detalle']);
  }

  /** Método para exportar datos a un archivo Excel */
  async exportExcel() {
    let isVerdictData = false;
    this.loading = true;
    this.loadingMessage.next("Preparando exportacion de  tickets");
    const params = this.getParams()
    const data = await this.mongoSrv.get(`/grupos-tickets/tickets/reporte`, params);
    const toExport: any = [];

    if (data) {
      if (this.groupToTickets.cpi) {
        if (data && data.length === undefined) {
          const tk = data
          const tipo = this.dataSrv.getTicketType(tk.zProcessingTypeCode, tk.zServiceIssueCategoryID, tk.zIncidentServiceIssueCategoryID)
          if (
            tk &&
            'zAnalisisVeredicto_KUTText' in tk &&
            'Z_DENOMINACIONCOMERCIAL_KUT' in tk &&
            'zImporteReclamado_KUT' in tk &&
            'Z_CabEntidadDueadelATM_KUTText' in tk
          ) {
            isVerdictData = true;
          }
          const ticket = {
            "NRO TICKET": tk.zID,
            "NRO BOLETA": tk.Z_NUMERODEBOLETA_KUT,
            "ASUNTO": tk.zName,
            "TIPO TICKET": tipo?.descripcion,
            "CATEGORIA N1": tipo?.categoria?.descripcion,
            "CATEGORIA N2": tipo?.categoria?.incidencia?.descripcion,
            "PRIORIDAD": tk.zServicePriorityCodeText,
            "ESTADO": tk.zServiceRequestUserLifeCycleStatusCodeText,
            "FECHA ULTIMA ACTUALIZACION": moment(tk.zLastChangeDateTime).toDate(),
            "FECHA CREACION": moment(tk.zCreationDateTime).toDate(),
            "CREADO POR": tk.z_CREADOPOR ? tk.z_CREADOPOR.toLowerCase() : tk.z_CREADOPOR
          };
          if(isVerdictData){
            ticket['ANÁLISIS VEREDICTO'] = tk.zAnalisisVeredicto_KUTText;
            if(!isNaN(tk.zImporteReclamado_KUT)){
              ticket['IMPORTE RECLAMADO'] = Number(tk.zImporteReclamado_KUT);
            } else {
              ticket['IMPORTE RECLAMADO'] = 0;
            }
            ticket['CLIENTE'] = tk.Z_DENOMINACIONCOMERCIAL_KUT;
            ticket['ENTIDAD DUEÑA ATM'] = tk.Z_CabEntidadDueadelATM_KUTText;
          }
          toExport.push(ticket);

        } else if (data.length > 0) {
          data.forEach(tk => {
            const tipo = this.dataSrv.getTicketType(tk.zProcessingTypeCode, tk.zServiceIssueCategoryID, tk.zIncidentServiceIssueCategoryID)

            if (
              tk &&
              'zAnalisisVeredicto_KUTText' in tk &&
              'Z_DENOMINACIONCOMERCIAL_KUT' in tk &&
              'zImporteReclamado_KUT' in tk &&
              'Z_CabEntidadDueadelATM_KUTText' in tk
            ) {
              isVerdictData = true;
            }

            const ticket = {
              "NRO TICKET": tk.zID,
              "NRO BOLETA": tk.Z_NUMERODEBOLETA_KUT,
              "ASUNTO": tk.zName,
              "TIPO TICKET": tipo?.descripcion,
              "CATEGORIA N1": tipo?.categoria?.descripcion,
              "CATEGORIA N2": tipo?.categoria?.incidencia?.descripcion,
              "PRIORIDAD": tk.zServicePriorityCodeText,
              "ESTADO": tk.zServiceRequestUserLifeCycleStatusCodeText,
              "FECHA ULTIMA ACTUALIZACION": moment(tk.zLastChangeDateTime).toDate(),
              "FECHA CREACION": moment(tk.zCreationDateTime).toDate(),
              "CREADO POR": tk.z_CREADOPOR ? tk.z_CREADOPOR.toLowerCase() : tk.z_CREADOPOR
            };
            if(isVerdictData){
              ticket['ANÁLISIS VEREDICTO'] = tk.zAnalisisVeredicto_KUTText;
              if(!isNaN(tk.zImporteReclamado_KUT)){
                ticket['IMPORTE RECLAMADO'] = Number(tk.zImporteReclamado_KUT);
              } else {
                ticket['IMPORTE RECLAMADO'] = 0;
              }
              ticket['CLIENTE'] = tk.Z_DENOMINACIONCOMERCIAL_KUT;
              ticket['ENTIDAD DUEÑA ATM'] = tk.Z_CabEntidadDueadelATM_KUTText;
            }
            toExport.push(ticket);
          });

        }

      } else {
        
        const ticketsList = data;

        ticketsList.forEach(tk => {
          if (
            tk &&
            'zAnalisisVeredicto_KUTText' in tk &&
            'Z_DENOMINACIONCOMERCIAL_KUT' in tk &&
            'zImporteReclamado_KUT' in tk &&
            'Z_CabEntidadDueadelATM_KUTText' in tk
          ) {
            isVerdictData = true;
          }
          const tipo = this.dataSrv.getTicketType(tk.ProcessingTypeCode, tk.ServiceIssueCategoryID, tk.IncidentServiceIssueCategoryID)
          const ticket = {
            "NRO TICKET": tk.ID,
            "NRO BOLETA": tk.Z_NUMERODEBOLETA_KUT,
            "ASUNTO": tk.Name,
            "TIPO TICKET": tipo?.descripcion,
            "CATEGORIA N1": tipo?.categoria?.descripcion,
            "CATEGORIA N2": tipo?.categoria?.incidencia?.descripcion,
            "PRIORIDAD": tk.ServicePriorityCodeText,
            "ESTADO": tk.ServiceRequestUserLifeCycleStatusCodeText,
            "FECHA ULTIMA ACTUALIZACION": moment(tk.LastChangeDateTime).toDate(),
            "FECHA CREACION": moment(tk.CreationDateTime).toDate(),
            "CREADO POR": tk.Z_CREADOPOR_KUT ? tk.Z_CREADOPOR_KUT.toLowerCase() : tk.Z_CREADOPOR_KUT
          };
          
          if(isVerdictData){
            ticket['ANÁLISIS VEREDICTO'] = tk.zAnalisisVeredicto_KUTText;
            if(!isNaN(tk.zImporteReclamado_KUT)){
              ticket['IMPORTE RECLAMADO'] = Number(tk.zImporteReclamado_KUT);
            } else {
              ticket['IMPORTE RECLAMADO'] = 0;
            }
            ticket['CLIENTE'] = tk.Z_DENOMINACIONCOMERCIAL_KUT;
            ticket['ENTIDAD DUEÑA ATM'] = tk.Z_CabEntidadDueadelATM_KUTText;
          }
          toExport.push(ticket);
        });

      }
      setTimeout(() => {
        // instancia el libro 
        const wb: WorkBook = utils.book_new();
        // instancia una hoja vacía
        const ws: WorkSheet = utils.json_to_sheet(toExport);
        // instancia variable con tamaño de las columnas
        const style = { numFmt: '#.##0' };
        const range = { s: { c: 12, r: 1}, e: { c: 12, r: (toExport.length - 1) } };
        
        const wscols = [
          { wch: 10 }, //NRO TICKET
          { wch: 15 }, //NRO BOLETA
          { wch: 35 }, //ASUNTO
          { wch: 18 }, //TIPO TICKET
          { wch: 16 }, //CATEGORIA N1
          { wch: 16 }, //CATEGORIA N2
          { wch: 16 }, //PRIORIDAD
          { wch: 18 }, //ESTADO
          { wch: 22 }, //FECHA ULTIMA ACTUALIZACION
          { wch: 18 }, //FECHA CREACION
          { wch: 30 }  //CREADO POR
        ];
        if(isVerdictData){
          for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
              const cell = utils.encode_cell({ r: R, c: C });
              if (!ws[cell]) continue;
              ws[cell].z = "#,##0";
            }
          }

          wscols.push({ wch: 30 }) //ANÁLISIS VEREDICTO
          wscols.push({ wch: 30 }) //IMPORTE RECLAMADO
          wscols.push({ wch: 30 }) //CLIENTE
          wscols.push({ wch: 30 }) //ENTIDAD DUEÑA ATM
        }
        // asigna variable a la tabla
        ws['!cols'] = wscols;
        // agrega hoja al libro
        utils.book_append_sheet(wb, ws, 'Hoja1');
        if(isVerdictData){
          // Setea estilo numerico
          
        }
        // guarda el archivo
        writeFile(wb, 'Listado de tickets.xlsx');

        this.auditSrv.logAction({ modulo: 'Tickets', accion: 'Exportar tickets', detalle: `Tipo: ${this.groupToTickets.titulo}, Filtros: ${JSON.stringify(params)}` })
        this.loading = false;
      }, 50)
    }


  }

  async open(content: any, size: "md" | "lg" | "xl") {
    this.modalSrv.open(content, { size: size, centered: true, backdropClass: 'modal-backdrop' });
  }

  async anulacion(codigoAnulacion: any) {
    let aprobar = false;
    if ([this.aprobarAnulacion, this.aprobarAnulacion2].includes(codigoAnulacion)) {
      aprobar = true;
    }

    let checked = false;
    if (aprobar) {
      if (this.editarMedioDePago && this.dataAceptarAnulacion.pago.id=="") {
        this.toastr.warning("Medio de pago es requerido",'',{positionClass:'toast-top-center', progressBar:false});  
      } else if (this.editarMedioDePago && Constantes.MEDIOS_DE_PAGO_CON_REFERENCIA.includes(this.dataAceptarAnulacion.pago.id) && this.dataAceptarAnulacion.pago.numerodereferencia=="") {
        this.toastr.warning("Número de referencia es requerido",'',{positionClass:'toast-top-center', progressBar:false});  
      } else {
        checked = true;
      }
      checked = true
    } else {
      checked = true;
    }

    if (checked) {

      const req = { ServiceRequestUserLifeCycleStatusCode: codigoAnulacion };
      this.modalSrv.dismissAll();
      this.loading = true;
      this.loadingMessage.next("Procesando ticket");
      const statusChanged = await this.changeStatus(codigoAnulacion, false, false);
      if (statusChanged) {
        this.loading = false;
        this.getData();
        const message = ["YF", "Z7"].includes(codigoAnulacion)
          ? Mensajes.ANULACION_APROBADA
          : Mensajes.ANULACION_RECHAZADA;
        this.toastr.success(message, "", {
          positionClass: "toast-top-center",
          progressBar: true,
        });
      } else {
        this.loading = false;
        this.toastr.error(Mensajes.ERROR_AL_PROCESAR_ANULACION, "", {
          positionClass: "toast-top-center",
          progressBar: true,
        });
      }
    }

  }

  /** Método para cambiar el estado del ticket actual
   * @param newStatusCode código del nuevo estado a asignar
   * @param updateAfterChangue usar TRUE para actualizar el ticket una vez que haya cambiado el estado
   * @param showToast usar TRUE para mostrar el toast de cambio de estado
   * @returns TRUE cuando el cambio se realiza correctamente, sino FALSE
   */
  async changeStatus(
    newStatusCode: any,
    updateAfterChangue: boolean,
    showToast: boolean
  ) {
    const oldStatusCode = this.dataAceptarAnulacion.estado.id
    const statusesFromTicket = this.getEstadosTicketsByTipo(this.dataAceptarAnulacion.tipo.id);
    
    const newStatus = await statusesFromTicket.find((status: any) => {
      return status.id == newStatusCode;
    });


    if (newStatusCode == oldStatusCode) {
      this.filtrar()
      return true;
    } else {
      if (showToast) {
        this.loading = true;
        this.loadingMessage.next("Cambiando estado de ticket");
      }
      const oldStatusText = this.dataAceptarAnulacion.estado.descripcion;
      if (this.aceptarAnulacion) {
        this.loadingMessage.next("Guardando datos");
        await this.guardarDatos();
      }


      if (showToast) {
        this.loading = true;
        this.loadingMessage.next("Cambiando estado de ticket");
      }

      const payload = { "ServiceRequestUserLifeCycleStatusCode": newStatusCode };
      const res = await this.ticketsSrv.updateTicket(this.dataAceptarAnulacion.OID, payload);
      if (res.updated) {
        this.auditSrv.logAction({ modulo: 'Tickets', accion: 'Cambiar estado', detalle: `ID: ${this.dataAceptarAnulacion.OID}, Estado anterior: ${oldStatusCode}, Estado asignado: ${newStatusCode}` })
        const nComentary = {
          ServiceRequestID: this.dataAceptarAnulacion.numero,
          TypeCode: "10011",
          FormattedText: `${this.usersSrv.getActualUser().nombreCompleto}:\nUsuario: ${this.usersSrv.getActualUser().email}\nEstado anterior: ${oldStatusText}\nEstado actual: ${newStatus?.descripcion}\n`,
          ParentObjectID: this.dataAceptarAnulacion.OID
        };
        const res = await this.comentsSrv.nuevoComentarioTK(nComentary);
        const mail = {
          "recipients": this.recipientsEmail,
          "previousStatus": oldStatusText,
          "newStatus": newStatus?.descripcion,
          "user": this.usersSrv.getActualUser().email,
          "userName": this.usersSrv.getActualUser().nombreCompleto,
          "ticketId": this.dataAceptarAnulacion.numero,
          "ticketType": this.dataAceptarAnulacion.tipo.descripcion,
          "idTicketType": this.dataAceptarAnulacion.tipo.id,
          "subject": this.dataAceptarAnulacion.descripcion,
          "entity": this.usersSrv.getActualUser().entidad?.codigoEntidad
        }
        this.notificationSrv.sendMail(mail, 'status_change');
        if (updateAfterChangue) {
          this.filtrar()
        }
        this.loading = false;
        return true;
      } else {
        if (showToast) {
          this.toastr.error(Mensajes.ERROR_ACTUALIZAR_ESTADO, '', { positionClass: 'toast-top-center', progressBar: false });
        }
        this.loading = false;
        return false;
      }
    }
  }

  addCurrentUserToRecipients() {
    this.recipientsEmail.push(this.usersSrv.getActualUser().email);
  }

  /** Método para obtener los participantes del ticket */
  async getParticipantes() {
    this.recipientsEmail = [];
    this.addCurrentUserToRecipients();
    const participants = await this.ticketsSrv.getParticipants(this.dataAceptarAnulacion.ObjectID);
    //Limpio el array en cada get, sino pushea sobre lo que ya esta agregado
    this.participantesTicketList = [];

    if (participants) {
      this.auditSrv.logAction({ modulo: 'Tickets', accion: 'Obtener participantes', detalle: `ID: ${this.dataAceptarAnulacion.ObjectID}` })
      if (participants.length > 0) {
        //Itero para ver el valor del campo Z_CONTACTO_PORTAL_ENT_KUT
        participants.forEach(async (participante: any) => {
          if (['Z5', '213', '214', '40'].includes(participante.RoleCode)) {
            const z = this.participantesTicketList.filter((element) => element.Email == participante.Email);
            if (z.length == 0) {
              if (participante.RoleCode != '40') {
                this.participantesTicketList.push(participante);
              }
              //se agrega la lista de participantes a la lista de mails
              this.recipientsEmail.push(participante.Email);
            }
          }

        });
      }
    }
  }

  ticketTieneDatosPago(ticket: Ticket): boolean {
    return ticket.pago?.id != undefined && ticket.pago?.id != '' && 
           ticket.pago.fechaTransferencia != undefined && ticket.pago.fechaTransferencia != '' && 
           ticket.pago.numerodereferencia != undefined && ticket.pago.numerodereferencia != '';
  }
  
  showModalAceptarAnulacion(content: any, size: "md" | "lg" | "xl", aceptarAnular: boolean, ticket: any) {

    this.dataAceptarAnulacion = {}
    
    this.aceptarAnulacion = aceptarAnular;
    this.dataAceptarAnulacion = ticket;
    
    this.mostrarModalAnulacion = ['ZD', 'Y6'].includes(this.dataAceptarAnulacion.estado.id);

    this.mostrarBotones = (this.dataAceptarAnulacion.tipo.id == 'Z4' && this.dataAceptarAnulacion.estado.id) == 'YD' ||
      (this.dataAceptarAnulacion.tipo.id == 'ZAE' && this.mostrarModalAnulacion);
    
    if(this.ticketTieneDatosPago(ticket)){
      this.editarMedioDePago = false;
    } else {
      this.editarMedioDePago = this.dataAceptarAnulacion.tipo.id == 'Z4' || 
      (this.dataAceptarAnulacion.tipo.id == 'ZAE' && Constantes.ROL_ENTIDADES_REQUIEREN_MEDIO_DE_PAGO.includes(this.dataAceptarAnulacion.tipo.categoria?.incidencia?.descripcion.toString()))
    
    }

    this.modalSrv.open(content, { size: size, centered: true, backdropClass: 'modal-backdrop' });
  }

  /** Método para guardar los datos de método de pago y número de referencia
   * 
  */
  async guardarDatos() {
    let componentesFecha;
    let fechaTransferencia;
    const body = {
      zCabMediodePago_KUT :this.dataAceptarAnulacion.pago.id,
      zCabNumerodereferencia_KUT :  this.dataAceptarAnulacion.pago.numerodereferencia,
    }

    if(this.dataAceptarAnulacion.pago.fechaTransferencia) {
      componentesFecha = this.dataAceptarAnulacion.pago.fechaTransferencia.split("-");
      fechaTransferencia = new Date(
        parseInt(componentesFecha[0]),
        parseInt(componentesFecha[1]) - 1,
        parseInt(componentesFecha[2])
      );
      body['Z_FECHATRANSFERENCIA_KUT'] = `/Date(${fechaTransferencia.getTime()})/`
    }

    await this.ticketsSrv.updateTicket(this.dataAceptarAnulacion.OID, body)
  }

  getConstantes() {
    return Constantes;
  }


  enabledAnulacion(ticket) {
    const mostrarModalAnulacionZAE = ['ZD', 'Y6'].includes(ticket.estado.id);
    const mostrarModalAnulacionZ4 = ['YD'].includes(ticket.estado.id);
    // return ticket.tipo && (ticket.tipo.id == 'ZAE' || ticket.tipo.id == 'Z4') && !['1','Y3'].includes(ticket.estado.id);
    return ticket.tipo && ((ticket.tipo.id == 'ZAE' && mostrarModalAnulacionZAE) || (ticket.tipo.id == 'Z4' && mostrarModalAnulacionZ4))
  }

  showBotonesAnulacion(ticket) {
    return ticket.tipo && (ticket.tipo.id == 'ZAE' || ticket.tipo.id == 'Z4')
  }

  getEstadosTicketsByTipo(tipo: string) {
    const estados = this.tiposTickets.find(tipoTicket => tipoTicket.id.toString() == tipo)?.estadosPosibles;
    if (estados) {
      return estados
    }
    return []
  }


  onDateChange() {
    setTimeout(async () => {
      if (!this.dateChanging) {
        this.dateChanging = true;
        await this.filtrar();
        this.dateChanging = false;
      }
    }, 1000)
  }

  isFilerEmpty(){
    if(this.filtros.Z_NUMERODEBOLETA_KUT != '' || this.filtros.ID != '' || this.filtros.Name != ''){
      return false
    }
    if(this.filtros.ProcessingTypeCode.length > 0 || 
      this.filtros.ServiceIssueCategoryID.length > 0 || 
      this.filtros.IncidentServiceIssueCategoryID.length > 0 || 
      this.filtros.ServicePriorityCode.length > 0 || 
      this.filtros.ServiceRequestUserLifeCycleStatusCode.length > 0 ){
      return false
    }
    if(this.filtros.includeClosed){
      return false;
    }

    return true;
  }

  selectTab(index: number) {
    this.selectedIndex = index;
  }

  closeTab(index: number) {
    this.tabs.splice(index, 1);
    if (this.selectedIndex >= this.tabs.length) {
      this.selectedIndex = this.tabs.length - 1;
    }
  }

  addDetailTab(id) {
    this.tabs.push({codigo:`ID${id}`,  title: `Ticket:  ${id}`, ID: id });
    this.selectTab(this.tabs.length - 1)
  }



}
