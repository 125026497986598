import { formatNumber } from "@angular/common";

export default class NumberUtils {
    
    /** Método para cambiar  el formato de un numero
     * @param value número a transformar
     * @param to locale al cual transformar
     * @param removePoints TRUE para quitar puntos y cambiar el punto decimal por coma
     */
    static changeLocale(value:number,to:string,removePoints?)  {
        let nValue : any = formatNumber(value,to);
        if (removePoints) {
            nValue = this.changeSeparators(value);
        }
        return nValue;
    }

    /** Método para quitar los puntos y reemplazar la coma por un punto
     * @param value número a modificar
     */
    static changeSeparators(value: any) {
        const nValue = value.replaceAll(".","").replaceAll(",",".")
        return nValue;
    }

}
