import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Regex } from "src/app/constants/regex.const";

export const formBusquedaRuc = new FormGroup({
  ruc: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
    Validators.pattern(Regex.RUC),
  ]),
});
