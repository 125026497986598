import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { faBuilding, faChartBar,faTicket,faBriefcase, faSignOutAlt, faUsers,faClipboardList, faFolderOpen, faCubesStacked} from '@fortawesome/free-solid-svg-icons';
import { LoginService } from '../../services/login.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  selection: any;
  ruta: any;
  rol:any;

  //icons
  faBuilding =faBuilding;
  faUser =faUsers;
  faTicket=faTicket;
  faChartBar=faChartBar;
  faSignOutAlt = faSignOutAlt;
  faClipboardList=faClipboardList;
  faBriefcase=faBriefcase;

  userInitials: any ;

  menuItems = [
    {
      key:'tickets',
      titulo:'Tickets',
      routerlink:['tickets','tickets'],
      icon:faTicket,
      permisos:['SEC_TICKETS_NAV']
    },
    {
      key:'reportes',
      titulo:'Reportes',
      routerlink:['reportes','reportlist'],
      icon:faChartBar,
      permisos:['SEC_REPORTES_NAV']
    },
    {
      key:'usuarios',
      titulo:'Usuarios',
      routerlink:['usuarios','userlist'],
      icon:faUsers,
      permisos:['SEC_USUARIOS_NAV']
    },
    {
      key:'auditoria',
      titulo:'Auditoria',
      routerlink:['auditoria','listado'],
      icon:faClipboardList,
      permisos:['SEC_AUDITORIA_NAV']
    },
    {
      key:'oportunidadcomercial',
      titulo:'Oportunidad Comercial',
      routerlink:['oportunidadcomercial','listado'],
      icon:faBriefcase,
      permisos:['SEC_OPORTUNIDAD_COMERCIAL_NAV']
    },
    {
      key:'documentos-importantes',
      titulo:'Documentos',
      routerlink:['documentos-importantes','listado'],
      icon:faFolderOpen,
      permisos:['SEC_DOCUMENTOS_IMPORTANTES_NAV']
    },
    {
      key:'catalogo-productos',
      titulo:'Productos',
      routerlink:['catalogo-productos','listado'],
      icon:faCubesStacked,
      permisos:['SEC_CATALOGODIGITAL_VISUALIZACION']
    },
  ]
  
  
  constructor(
    private router: Router,
    private loginSrv: LoginService,
    private usersSrv: UsersService
  ) { 
    router.events.forEach(val=>{
      if( val instanceof NavigationEnd ){
        this.ruta=val.url;
        this.selectionSet(); 
      }
    })
  }

  ngOnInit(): void {
    this.rol = this.usersSrv.getActualUser().rol;
    this.userInitials = this.usersSrv.getActualUser().nombreCompleto!.slice(0,1)
  }

  selectionSet(){
    this.selection = this.ruta.includes('usuarios') ? 'usuarios':this.selection;
    this.selection = this.ruta.includes('reportes') ? 'reportes': this.selection;
    this.selection = this.ruta.includes('tickets') ? 'tickets': this.selection;
    this.selection = this.ruta.includes('auditoria') ? 'auditoria':this.selection;
    this.selection = this.ruta.includes('oportunidadcomercial') ? 'oportunidadcomercial':this.selection;
    this.selection = this.ruta.includes('documentos') ? 'documentos-importantes':this.selection;
    this.selection = this.ruta.includes('catalogo-productos') ? 'catalogo-productos':this.selection;
  }

  /** Método para comprobar si un item requiere permisos para visualizar el menu 
    * @param item array de permisos que se van a comprobar
    * @returns Verdadero cuando el usuario tiene los permisos requeridos, sino Falso
    */
  checkRol(item:any){
    if(item.permisos && item.permisos.length > 0) {
      return this.usersSrv.actualUSerHasPerm(item.permisos, item.criterio? item.criterio : 'AND');
    }
    return true;
  }

  goTo(link:any){
    this.router.navigate(link)
    /* if(this.rol!='user'){} */
  }

  signOut(){
    this.loginSrv.logout();
  }

}
