import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {
  @HostBinding('class.fileover') fileOver :boolean | undefined;

  @Output() fileDropped = new EventEmitter();
  @Output() dragOver = new EventEmitter();

  @HostListener('dragover', ['$event']) onDragOver(evt:any){
    evt.preventDefault();
    evt.stopPropagation();
    this.dragOver.emit(true);
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt:any){
    evt.preventDefault();
    evt.stopPropagation();
    this.dragOver.emit(false);

  }
  
  @HostListener('drop', ['$event']) ondrop(evt:any){
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if(files.length > 0){
      this.fileDropped.emit(files);
      this.dragOver.emit(false);
      
    }
  }

}
