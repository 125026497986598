import { NgModule,LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecoveryComponent } from './login/recovery/recovery.component';
import { PagesModule } from './pages/pages.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { CustomToastComponent } from './shared/custom-toast/custom-toast.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha-2';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CreatepasswordComponent } from './login/createpassword/createpassword.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ExpiredpasswordComponent } from './login/expiredpassword/expiredpassword.component';
import { TableModule } from 'primeng/table';
import { SocketService } from './services/socket.service';
import { registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs);
import { ChartModule } from 'primeng/chart';

const globalSettings: RecaptchaSettings = { siteKey: environment.captcha_site_key_v2 };

@NgModule({ declarations: [
        RecoveryComponent,
        AppComponent,
        LoginComponent,
        ChangePasswordComponent,
        CreatepasswordComponent,
        ExpiredpasswordComponent
    ],
    exports: [
        RouterModule
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        NgbModule,
        PagesModule,
        ChartModule,
        FontAwesomeModule,
        FormsModule,
        ToastrModule.forRoot({
            toastComponent: CustomToastComponent,
            preventDuplicates: true,
            maxOpened: 1,
            autoDismiss: true,
            positionClass: 'toast-top-center'
        }),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        NgxSkeletonLoaderModule,
        TableModule,
        RecaptchaV3Module,
        RecaptchaModule], providers: [
        SocketService,
        {
            provide: LOCALE_ID,
            useValue: 'es-PY'
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.captcha_site_key
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: globalSettings
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
