import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs';
import { Param } from '../interfaces/interfaces';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    private logSrv: LogService
  ) {}

/**----------------------ENCABEZADOS--------------------------**/
/**------------------------------------------------------------*/

  /** Método para obtener headers para utilizar con servicios de CPI
   * @param customHeaders encabezados adicionales a agregar a la petición
   */
  getHeadersCPI(customHeaders?: Param[]): HttpHeaders {
    throw new Error('Method unsupported');
  }

  /** Método para obtener headers para utilizar con servicios de la API
   * @param customHeaders encabezados adicionales a agregar a la petición
   */
  getHeadersAPI(customHeaders?: Param[]): HttpHeaders {
    const headers: any = {
      'Authorization':`Bearer ${this.storageService.get('token')!}`
    };
    if (customHeaders) {
      customHeaders.forEach(h => {
        headers[h.key] = h.value;
      })
    }
    return new HttpHeaders(headers);
  }

  /** Método para obtener headers para utilizar con servicios de C4C
   * @param customHeaders encabezados adicionales a agregar a la petición
   */
  getHeadersC4C(customHeaders?: Param[]): HttpHeaders {
    throw new Error('Method unsupported');
  }

  /** Método para obtener headers para utilizar con servicios de CRM
   * @param customHeaders encabezados adicionales a agregar a la petición
   */
  getHeadersCRM(customHeaders?: Param[]): HttpHeaders {
    throw new Error('Method unsupported');
  }

/**----------------------GET--------------------------**/
/**---------------------------------------------------- */

  /** Método GET a los servicios disponibles
   * @param service servicio al cual apuntar
   * @param endpoint endpoint al cual apuntar
   * @param customHeaders encabezados a sumar a los pre-establecidos
   * @param parameters parametros a agregar a la url
   * @param fullResponse TRUE para obtener la respuesta http completa, sino devuelve solo el cuerpo
   * @returns <ul>
   * <li> Si la petición fue correcta y fullResponse es verdadero: devuelve HttpResponse </li>
   * <li> Si la petición fue correcta y fullResponse es false: devuelve el cuerpo de la respuesta </li>
   * <li> Si la petición tuvo algún error: devuelve HttpErrorResponse </li>
   * </ul>
  */
  getRequest(service: 'C4C'|'CPI'|'API', endpoint: string, customHeaders: Param[]|null, parameters: Param[]|null, fullResponse?: boolean) {
    let url: string;
    let headers : HttpHeaders;
    switch (service) {
      case 'C4C':
        throw new Error('Method unsupported');
      case 'CPI':
        throw new Error('Method unsupported');
      default:
        url = `${environment.apiUrl}${endpoint}`;
        headers = customHeaders!=null ? this.getHeadersAPI(customHeaders) : this.getHeadersAPI();
        break;
    }
    let params = new HttpParams();
    if (parameters) {
      parameters.forEach(param => {
        params = params.append(param.key, param.value);
      });
    }
    return this.http.get<any>(url, { headers: headers, observe: 'response',params: params}).pipe(
      map((response: HttpResponse<any>) => {
        return fullResponse ? response : response.body;
      }),
      catchError((error: HttpErrorResponse) => {
        if (service!='API') {
          this.logSrv.sendErrorNotification(
            'get request', 
            error.status, error.statusText, error.message,''
          );
        }
        // Devuelve error   
        return [error];      
      })
    );
  }

/**----------------------POST--------------------------**/
/**---------------------------------------------------- */

  /** Método POST a los servicios disponibles
   * @param service servicio al cual apuntar
   * @param endpoint endpoint al cual apuntar
   * @param payload datos a enviar en el body
   * @param customHeaders encabezados a sumar a los pre-establecidos
   * @returns <ul>
   * <li> Si la petición fue correcta: devuelve HttpResponse </li>
   * <li> Si la petición tuvo algún error: devuelve HttpErrorResponse </li>
   * </ul>
  */
  postRequest(service:'C4C'|'CPI'|'API'|'CRM',endpoint: string, payload: any, customHeaders?: Param[]|null) {
    let url: string;
    let headers: HttpHeaders;
    switch (service) {
      case 'C4C':
        throw new Error('Method unsupported');
      case 'CPI':
        throw new Error('Method unsupported');
      case 'CRM':
        throw new Error('Method unsupported');
      default:
        url = `${environment.apiUrl}${endpoint}`;
        headers = (customHeaders && customHeaders!=null) ? this.getHeadersAPI(customHeaders) : this.getHeadersAPI(); 
        break;
    }
    return this.http.post<any>(url, payload, { headers: headers,  observe: 'response'}).pipe(
      map((response: HttpResponse<any>) => {
        return response;
      }),
      // Se captura los errores
      catchError((error: HttpErrorResponse) => {
        if (service!='API') {
          this.logSrv.sendErrorNotification(
            'post request', 
            error.status, error.statusText, error.message,
            JSON.stringify(payload, null, 2)
          );
        }
        // Devuelve error   
        return [error];  
      })
    );
  }

  /** Método POST a los servicios disponibles
   * @param service servicio al cual apuntar
   * @param endpoint endpoint al cual apuntar
   * @param payload datos a enviar en el body
   * @param customHeaders encabezados a sumar a los pre-establecidos
   * @returns <ul>
   * <li> Si la petición fue correcta: devuelve HttpResponse </li>
   * <li> Si la petición tuvo algún error: devuelve HttpErrorResponse </li>
   * </ul>
  */
  postRequestTextResponse(service:'C4C'|'CPI'|'API'|'CRM',endpoint: string, payload: any, customHeaders?: {key:string,value:string}[]|null) {
    let url: string;
    let headers: HttpHeaders;
    switch (service) {
      case 'C4C':
        throw new Error('Method unsupported');
      case 'CPI':
        throw new Error('Method unsupported');
      case 'CRM':
        throw new Error('Method unsupported');
      default:
        url = `${environment.apiUrl}${endpoint}`;
        headers = (customHeaders && customHeaders!=null) ? this.getHeadersAPI(customHeaders) : this.getHeadersAPI(); 
        break;
    }
    return this.http.post(url, payload, { headers: headers,  observe: 'response', responseType: 'text'}).pipe(
      map((response: HttpResponse<any>) => {
        return response;
      }),
      // Se captura los errores
      catchError((error: HttpErrorResponse) => {
        if (service!='API') {
          this.logSrv.sendErrorNotification(
            'post request text response', 
            error.status, error.statusText, error.message,
            JSON.stringify(payload, null, 2)
          );
        }
        // Devuelve error   
        return [error];  
      })
    );
  }

  /** Para para enviar una petición POST al endpoint de inicio de sesión
   * El método utiliza el encabezado exclusivo para el endpoint
   * @param endpoint ubicación del servicio en la API
   * @param payload datos a enviar
   */
  postRequestLogin(endpoint: string,payload: any) {
    const url = `${ environment.apiUrl }${ endpoint }`;
    const headers = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${environment.apiLoginBasic}`
    });
    return this.http.post<any>(url, payload, { headers: headers,  observe: 'response'}).pipe(
      map((response: HttpResponse<any>) => {
        return response;
      }),
      // Se captura los errores
      catchError((error: HttpErrorResponse) => {
        console.log(error)
        // Devuelve error   
        return [error];  
      })
    );
  }

  /** Para para enviar una petición POST al endpoint de inicio de sesión
   * El método utiliza el encabezado exclusivo para el endpoint
   * @param endpoint ubicación del servicio en la API
   * @param payload datos a enviar
   */
  postRequestRefreshToken(endpoint: string,payload: any, userId) {
    const url = `${ environment.apiUrl }${ endpoint }`;
    const headers = new HttpHeaders({
      'x-user' : userId,
    });
    return this.http.post<any>(url, payload, { headers: headers,  observe: 'response'})
    .pipe(
      map((response: HttpResponse<any>) => {
        return response;
      }),
      // Se captura los errores
      catchError((error: HttpErrorResponse) => {
        console.log(error)
        // Devuelve error   
        return [error];  
      })
    );
  }

  /** Método para enviar una petición POST al endpoint de recuperación de contraseña 
   * El método utiliza el encabezado exclusivo para el endpoint
   * @param endpoint al cual apuntar, el mimso debe ser uno de los proveidos en la endpoint '/user-validation'
   * @param payload datos a enviar
  */
  postRequestRecovery(endpoint: string, payload: any) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${environment.apiRecoveryBearer}`
    });
    const url = `${ environment.apiUrl }/user-validation/${ endpoint }`;
    return this.http.post<any>(url, payload, { headers: headers,  observe: 'response'}).pipe(
      map((response: HttpResponse<any>) => {
        return response;
      }),
      // Se captura los errores
      catchError((error: HttpErrorResponse) => {
        this.logSrv.sendErrorNotification(
          'post request', 
          error.status, error.statusText, error.message,
          JSON.stringify(payload, null, 2)
        );
        // Devuelve error   
        return [error];  
      })
    );
  }

  /**----------------------PUT--------------------------**/
  /**---------------------------------------------------- */

  /** Método PUT a los servicios disponibles
   * @param service servicio al cual apuntar
   * @param endpoint endpoint al cual apuntar
   * @param payload datos a enviar en el body
   * @param customHeaders encabezados a sumar a los pre-establecidos
   * @returns <ul>
   * <li> Si la petición fue correcta: devuelve HttpResponse </li>
   * <li> Si la petición tuvo algún error: devuelve HttpErrorResponse </li>
   * </ul>
  */
  putRequest(service:'CPI'|'API',endpoint: string, payload: any, customHeaders?: Param[]|null) {
    let url: string;
    let headers: HttpHeaders;
    if (service=='CPI') {
      throw new Error('Method unsupported');
    } else {
      url = `${ environment.apiUrl }${endpoint}`;
      headers = (customHeaders && customHeaders!=null) ? this.getHeadersAPI(customHeaders) : this.getHeadersAPI(); 
    } 
    return this.http.put<any>(url, payload, { headers: headers,  observe: 'response'}).pipe(
      map((response: HttpResponse<any>) => {
        return response;
      }),
      // Se captura los errores
      catchError((error: HttpErrorResponse) => {
        if (service!='API') {
          this.logSrv.sendErrorNotification(
            'put request', 
            error.status, error.statusText, error.message,
            JSON.stringify(payload, null, 2)
          );
        }
        // Devuelve error   
        return [error];  
      })
    );
  }  

  /**----------------------DELETE--------------------------**/
  /**---------------------------------------------------- */

  /** Método DELETE a los servicios disponibles
   * @param service servicio al cual apuntar
   * @param endpoint endpoint al cual apuntar
   * @param customHeaders encabezados a sumar a los pre-establecidos
   * @param parameters parametros a agregar a la url
   * @param fullResponse TRUE para obtener la respuesta http completa, sino devuelve solo el cuerpo
   * @returns <ul>
   * <li> Si la petición fue correcta: devuelve HttpResponse </li>
   * <li> Si la petición tuvo algún error: devuelve HttpErrorResponse </li>
   * </ul>
  */
  deleteRequest(service: 'API', endpoint: string, customHeaders: Param[]|null, parameters: Param[]|null, fullResponse?: boolean) {
    const url = `${ environment.apiUrl }${endpoint}`;
    const headers: HttpHeaders = (customHeaders && customHeaders!=null) ? this.getHeadersAPI(customHeaders) : this.getHeadersAPI();
    let params = new HttpParams();
    if (parameters) {
      parameters.forEach(param => {
        params = params.append(param.key, param.value);
      });
    }
    return this.http.delete<any>(url, { headers: headers, observe: 'response',params: params}).pipe(
      map((response: HttpResponse<any>) => {
        return fullResponse ? response : response.body;
      }),
      catchError((error: HttpErrorResponse) => {
        if (service!='API') {
          this.logSrv.sendErrorNotification(
            'get request', 
            error.status, error.statusText, error.message,''
          );
        }
        // Devuelve error   
        return [error];      
      })
    );
  }  

}
