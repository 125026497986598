<div class="wrapper">
    <app-loadingSpinner [message]="loadingMessage" [show]="loading"></app-loadingSpinner>
    <app-sidebar></app-sidebar>
    <div class="main">
        <app-header></app-header>
        <div class="contenido">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<bpe-download-progress></bpe-download-progress>