import { Component, OnInit } from '@angular/core';
import { faBell, faSquareCheck, faSquare } from '@fortawesome/free-regular-svg-icons'
import { MongoDBService } from '../../services/mongoDB.service';
import { tiempoTranscurrido } from 'src/app/utils/appUtils';
import { SERVICIOS_REST } from 'src/app/constants/servicios-rest.const';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdjuntosService } from '../../services/adjuntos.service';
import { Subscription } from 'rxjs';
import { SocketService } from '../../services/socket.service';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'bpe-notificacions-panel',
  templateUrl: './notificacions-panel.component.html'
})
export class NotificacionsPanelComponent implements OnInit {
  faBell=faBell;
  faSquare=faSquare;
  faSquareCheck=faSquareCheck;
  faInbox=faInbox

  visible = false;
  loading = false;

  notifications: any[] = [];
  modalInfo = '';

  downloading = false;
  countNotifications = 0;

  subscriptionNotificaciones: Subscription | undefined;

  constructor(
    private service: MongoDBService,
    private adjuntosService: AdjuntosService,
    private modalService: NgbModal,
    private socketSrevice: SocketService
    ) { }

  ngOnInit(): void {
    this.subscriptionNotificaciones = this.socketSrevice._subject.subscribe(
      (value) => {
        this.obtenerNotificaciones()
      }
    );
    this.obtenerNotificaciones()
  }

  async download(e){
    this.downloading = true;
    const dataDocumeto = await this.service.getAll(`/documentosAdjuntos/${e.notificacion.documento}`)
    await this.adjuntosService.getDocument(dataDocumeto.adjuntoDomus.D_PK,dataDocumeto.adjuntoDomus.templateId, dataDocumeto, 'Panel de notificaciones')
    await this.service.update(`/notification/detalle`, e._id,{ descargado:true })
    this.downloading = false;
  }

  obtenerNotificaciones(){
    const params: any[]=[];
    params.push( {key:'size', value: '10'} )
    params.push( {key:'orderBy', value: 'fechaCreacion'} )
    params.push( {key:'orderDir', value: 'desc'} )
    
    this.countNotifications = 0;
    this.service.get(`${SERVICIOS_REST.REST_NOTIFICACION}v2`,params).then((response:any)=>{
        this.notifications= response.datos;
        for(const n of this.notifications) {
          if(!n.leido){
            this.countNotifications++;
          }
        }
        this.loading = false
      }).catch((error: any)=>{
        this.loading = false
      })  
  }

  getDaysOAgo(fecha){
    return tiempoTranscurrido(fecha)
  }

  async open(content, data) {
    await this.service.update(`/notification/detalle`, data._id,{ leido:true })
    this.countNotifications--
    this.modalInfo = data.notificacion.mensaje;
    data.leido = true
    this.modalService.open(content, {
      size: "lg",
      centered: true
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
