import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { catchError, switchMap, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LoginService } from '../login.service';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../storage.service';
import { Mensajes } from 'src/app/constants/mensajes.const';
import { Constantes } from 'src/app/constants/constantes.const';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshToken: BehaviorSubject<string> = new BehaviorSubject('');
  private cancelSubject: Subject<void> = new Subject<void>();

  constructor(
    private storageSrv: StorageService,
    private loginSrv: LoginService,
    private toastr: ToastrService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const error = { ...err.error }
        if(error.code == 401 && (error.data && error.data.code && [1, 2, 3, 4, 5].includes(error.data.code))) {
          return throwError( () => err )  
        } else if (error.code == 401) {
          return this.handle401Error(request, next);
        }
        return throwError( () => err )
      }),
      retry({
        count:1, 
        delay: (error: any, retryCount: number) => {
          if(error instanceof HttpErrorResponse && (error.status !== 401 && error.status !== 408)) {
            for(const path of Constantes.PATH_REINTENTABLES) {
              if(error.url?.includes(path)) {
                return timer(retryCount * 3000);
              }
            }
          }
          return throwError( () => error );
        }
      })
    )
  }

  showError() {

    this.toastr.error(Mensajes.SESION_EXPIRADA, '', {
      positionClass: 'toast-top-center', progressBar: false
    });

  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const refreshToken = this.storageSrv.get('refreshToken')
    if (refreshToken) {
      this.refreshToken.next(refreshToken);
      return this.loginSrv.requestRefreshToken(refreshToken).pipe(
        switchMap((res: any) => {
          
          this.loginSrv.saveToken(res);
          const newToken = this.storageSrv.get('token');
          
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${newToken}`
            }
          });
          this.refreshToken.next('');
          return next.handle(request);
        }),
        catchError((error) => {
          const started = this.storageSrv.get("started");
          if (started == "true") {
            this.loginSrv.logout();
            this.showError();
          }
          return throwError(() => error);
        })
      );
      
    } else {
      const started = this.storageSrv.get("started");
      if (started == "true") {
        this.loginSrv.logout();
        this.showError();
      }
      return throwError(() => `error al renovar token`);
    }
  }
}