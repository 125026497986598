import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { formNewTicket } from "./crear-ticket.form";
import {
  faPlus,
  faSpinner,
  faUpload,
  faFileAlt,
  faTimes,
  faExternalLinkAlt,
  faCheck,
  faTrash,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { ActivatedRoute, Router } from "@angular/router";
import { TicketService } from "src/app/services/ticket.service";
import { ToastrService } from "ngx-toastr";
import { formContact } from "./contacto-principal.form";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OcService } from "src/app/services/oc.service";
import { environment } from "src/environments/environment";
import { Title } from "@angular/platform-browser";
import {
  ATM,
  Barrio,
  Basico,
  CategoriaTicket,
  Ciudad,
  Departamento,
  Documento,
  Entidad,
  IncidenciaTicket,
  Pais,
  Sucursal,
  TipoTicket,
  Contacto,
  Param,
  ParametroGeneral,
} from "src/app/interfaces/interfaces";
import moment from 'moment';
import FileUtils from "src/app/utils/fileUtils";
import { AdjuntosService } from 'src/app/services/adjuntos.service';
import { Mensajes } from 'src/app/constants/mensajes.const';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { Constantes } from 'src/app/constants/constantes.const';
import { ContactService } from 'src/app/services/contact.service';
import { UsersService } from 'src/app/services/users.service';
import { AuditService } from 'src/app/services/audit.service';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { MongoDBService } from 'src/app/services/mongoDB.service';
import { replaceTags } from "src/app/utils/app-utils";
import { ComentariosService } from "src/app/services/comentarios.service";
import { EncryptionService } from "src/app/services/encryption.service";

@Component({
  selector: 'app-crear-ticket',
  templateUrl: './crear-ticket.component.html',
  styleUrls: ['./crear-ticket.component.scss']
})
export class CrearTicketComponent implements OnInit {

  //iconos
  faPlus = faPlus;
  faSpinner = faSpinner;
  faUpload = faUpload;
  faFileAlt = faFileAlt;
  faTimes = faTimes;
  faExternalLinkAlt = faExternalLinkAlt;
  faCheck = faCheck;
  faTrash = faTrash;
  faExclamationTriangle=faExclamationTriangle;
  //formularios
  formNewTicket: FormGroup = formNewTicket;
  contactForm: FormGroup = formContact;
  //vista
  showDropZone = false;
  eliminandoValidators = false;
  showCategoryIncident = true;
  isTkVeredicto = false;
  loading = false;
  loadingMessage = new BehaviorSubject('');
  contactoRequerido = false;
  //otros
  subscriptions: Subscription[] = [];
  formats: any = ['background', 'bold', 'color', 'font', 'code', 'italic', 'link', 'size', 'strike', 'script',
    'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'code-block', 'formula'];
  //adjuntos
  dropedFile: any;
  files: any[] = [];
  selectedFiles: any;
  documentos: Documento[] = [];
  crearAdjunto = false;
  //datos
  countriesFrom: Pais[] = [];
  statesFrom: Departamento[] = [];
  citiesFrom: Ciudad[] = [];
  neighborhoodsFrom: Barrio[] = [];
  countriesTo: Pais[] = [];
  statesTo: Departamento[] = [];
  citiesTo: Ciudad[] = [];
  neighborhoodsTo: Barrio[] = [];
  ticketsClassifications: ParametroGeneral[] = [];
  ticketsTypes: TipoTicket[] = [];
  ticketsTypesCategories: CategoriaTicket[] = [];
  ticketsTypesCategoriesIncidents: IncidenciaTicket[] = [];
  typeTicketsAllowed: string[] = [];
  ticketsPriorities: Basico[] = [];
  ticketsStatuses: Basico[] = [];
  branches: Sucursal[] = [];
  entities: Entidad[] = [];
  atms: ATM[] = [];
  atmModels: Basico[] = [];
  atmProviders: Basico[] = [];
  genders: Basico[] = [];
  //paginado
  page = 1;
  collectionSize = 0;
  pageSize = 10;
  collectionSizeContacto = 0;
  //validadores
  validatorAltaAtm: any[] = ['departamentoDestino', 'ciudadDestino', 'barrioDestino',
    'calleDestino', 'numeroCalleDestino', 'latitud', 'longitud',
    'marca', 'modelo', 'suministro', 'fechaHoraInstalacion'];
  validatorBajaAtm: any[] = ['fechaHoraBaja', 'terminal'];
  validatorReubicacionAtm: any[] = ['departamentoDestino', 'ciudadDestino', 'barrioDestino',
    'calleDestino', 'numeroCalleDestino', 'latitud', 'longitud', 'marca', 'modelo', 'suministro',
    'fechaHoraInstalacion', 'departamentoOrigen', 'ciudadOrigen', 'barrioOrigen', 'calleOrigen', 'numeroCalleOrigen'];
  validatorAsistencia: any[] = ['terminal'];
  validatorInforme: any[] = [];
  allValidators: any[] = ['departamentoDestino', 'ciudadDestino', 'barrioDestino', 'calleDestino',
    'numeroCalleDestino', 'latitud', 'longitud', 'marca', 'modelo', 'suministro', 'fechaHoraInstalacion',
    'departamentoOrigen', 'ciudadOrigen', 'barrioOrigen', 'calleOrigen', 'numeroCalleOrigen',
    'fechaHoraBaja', 'horaBaja', 'terminal']

  BuyerPartyID;
  emailContacto: any;
  emailContactoPermanente: any;
  contactoPrincipalList: Contacto[] = [];
  contactoSelected: Contacto | undefined;
  categoryIncidents: any[] = [];
  sucursalesList: any[] = [];
  Constantes = Constantes;
  usuarioActual = this.usersSrv.getActualUser();
  ProcessorPartyID = '';
  editorConfig = {
    height: 200,
    menubar: false,
    plugins: [
      'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
      'searchreplace', 'fullscreen',
      'insertdatetime', 'media', 'table', 'help', 'wordcount'
    ],
    toolbar:
      'undo redo | formatselect | bold italic | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help',
    elementpath: false,
    language_url: '/assets/es.js',
    language: 'es',
    placeholder: 'Agregue una descripción',
    branding: false,
    paste_as_text: true
  };

  @ViewChild("contactosAsociados", { static: true }) contactosAsociadosModal: any;
  @ViewChild("nuevoContacto", { static: true }) nuevoContactoModal: any;
  @ViewChild(EditorComponent) editorComponent?: EditorComponent;
  @ViewChild("confirmarcomentario", { static: true }) confirmarcomentario: any;

  constructor(
    private ocService: OcService,
    private toastr: ToastrService,
    private router: Router,
    private ticketService: TicketService,
    private modalService: NgbModal,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private adjuntosService: AdjuntosService,
    private dataSrv: DataService,
    private contactSrv: ContactService,
    private usersSrv: UsersService,
    private auditSrv: AuditService,
    private mongoSrv: MongoDBService,
    private encryptService: EncryptionService,
    private comentarioService: ComentariosService
  ) {
    // obtiene el estado de carga de tickets
    this.subscriptions.push(
      this.dataSrv.getPreLoadStatus().subscribe(cargado => {
        if (cargado) {
          this.getData();
          this.resetForms();
        }
      })
    );
  }

  /** Evento al iniciar el componente */
  ngOnInit(): void {
    this.title.setTitle(`${this.activatedRoute.snapshot.data.titulo} - ${environment.app_name}`);
    this.asociacionContacto();
    this.getTicketPermitidos();
    const subscribeTipo = this.formNewTicket.get('tipo')?.valueChanges.subscribe((tipoSelected) => {
      if (tipoSelected) {
        this.getDescriptionTemplate(this.formNewTicket.get('tipo')?.value, '', '');
      }
    })
    const subscribeCategoryServices = this.formNewTicket.get('categoryServices')?.valueChanges.subscribe((serviceSelected) => {
      if (serviceSelected) {
        this.getDescriptionTemplate(this.formNewTicket.get('tipo')?.value, serviceSelected, '');
      }
    })

    const suscribeCategoryIncidents = this.formNewTicket.get('categoryIncidents')?.valueChanges.subscribe((incidentSelected) => {
      if (incidentSelected) {
        this.getDescriptionTemplate(this.formNewTicket.get('tipo')?.value, this.formNewTicket.get('categoryServices')?.value, incidentSelected);
      }
    })

    if (subscribeTipo) {
      this.subscriptions.push(subscribeTipo)
    }

    if (subscribeCategoryServices) {
      this.subscriptions.push(subscribeCategoryServices)
    }

    if (suscribeCategoryIncidents) {
      this.subscriptions.push(suscribeCategoryIncidents)
    }
  }

  /** Evento al salir del componente */
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

  /** Método para resetear los formularios del componente */
  resetForms() {
    this.formNewTicket.reset();
    this.formNewTicket.patchValue({ 'prioridadTicket': "3" });
    this.formNewTicket.patchValue({ 'tipo': '' });
    this.formNewTicket.patchValue({ 'prioridad': '' })
    this.formNewTicket.patchValue({ 'categoryServices': '' });
    this.formNewTicket.patchValue({ 'categoryIncidents': '' })
    this.contactForm.reset();
  }

  /** Método para obtener datos del servicio */
  async getData() {

    this.dataSrv.setCargando(true);
    await Promise.all([
      this.dataSrv.loadTicketsTypes(),
      this.dataSrv.loadTicketsStatuses(),
      this.dataSrv.loadTicketsPriorities(),
      this.dataSrv.loadTicketsClassifications(),
      this.dataSrv.loadGruposTickets(),
      this.dataSrv.loadQueriesOdata(),
      this.dataSrv.loadLocations(),
      this.dataSrv.loadBranches(),
      this.dataSrv.loadEntities(),
      this.dataSrv.loadATM(),
      this.dataSrv.loadATMProviders(),
      this.dataSrv.loadGenders()
    ]);
    this.dataSrv.setCargando(false);
    // obtiene los paises
    this.subscriptions.push(this.dataSrv.getCountries().subscribe(countries => {
      this.countriesFrom = countries;
      this.countriesTo = countries;
    }));
    //obtiene los departamentos
    this.subscriptions.push(this.dataSrv.getStates().subscribe(states => {
      this.statesFrom = states;
      this.statesTo = states;
    }));
    // obtiene los tipos de tickets
    this.subscriptions.push(this.dataSrv.getTicketsTypes().subscribe(types => {
      this.ticketsTypes = types;
      if (this.typeTicketsAllowed.length > 0) {
        this.ticketsTypes = this.ticketsTypes.filter(type => {
          return this.typeTicketsAllowed.includes(type.id.toString())
        })
      }
    }));
    // obtiene las categorias de tickets
    this.subscriptions.push(this.dataSrv.getTicketsTypesCategories().subscribe(categories => {
      this.ticketsTypesCategories = categories;
    }));
    // obtiene los incidentes de tickets
    this.subscriptions.push(this.dataSrv.getTicketsTypesCategoriesIncidents().subscribe(incidents => {
      this.ticketsTypesCategoriesIncidents = incidents;
    }));
    // obtiene las prioridades de tickets
    this.subscriptions.push(this.dataSrv.getTicketsPriorities().subscribe(priorities => {
      this.ticketsPriorities = priorities;
    }));
    // obtiene las prioridades de tickets
    this.subscriptions.push(this.dataSrv.getBranches().subscribe(branches => {
      this.branches = branches;
    }));
    // obtiene las prioridades de tickets
    this.subscriptions.push(this.dataSrv.getEntities().subscribe(entities => {
      this.entities = entities.filter(entity => { return !Constantes.ENTIDADESSINATM.includes(entity.codigoEntidad) });
    }));
    // obtiene las atms
    this.subscriptions.push(this.dataSrv.getATMS().subscribe(atms => {
      this.atms = atms;
    }));
    // obtiene las atms
    this.subscriptions.push(this.dataSrv.getATMSProviders().subscribe(providers => {
      this.atmProviders = providers;
    }));
    // obtiene los géneros
    this.subscriptions.push(this.dataSrv.getGenders().subscribe(genders => {
      this.genders = genders;
    }));

    // obtiene la clasificación de tickets
    this.subscriptions.push(this.dataSrv.getTicketsClassifications().subscribe(types => {
      this.ticketsClassifications = types;
    }));
  }

  /** Se obtiene los codigos de los tipos de tickets  permitidos a cada usuario*/
  async getTicketPermitidos() {
    const tipos = await this.mongoSrv.getAll(`/tipo-tickets/permitidos`)
    const params: Param[] = [];
    params.push({ key: "size", value: "-1" });
    params.push({
      key: "filtros",
      value: JSON.stringify({ codigo: "tickets.types.no.permitidos.portal.entidades" }),
    });
    const tiposNoManejados = await this.mongoSrv.get(`/parametroGenerales/`, params);
    let tiposNoPermitidos: string[] = [];
    if (tiposNoManejados && tiposNoManejados.datos.length > 0) {
      tiposNoPermitidos = tiposNoManejados.datos[0].valor.split(",");
    }
    this.typeTicketsAllowed = tipos;
    if (tipos) {
      if (this.ticketsTypes.length > 0) {
        this.ticketsTypes = this.ticketsTypes.filter(type => {
          return this.typeTicketsAllowed.includes(type.id.toString()) && !tiposNoPermitidos.includes(type.id.toString())
        })
      }
    }
  }

  /** Método para asignar el contacto del usuario actual al ticket */
  async asociacionContacto() {
    this.loading = true;
    this.loadingMessage.next("Seleccionando contacto");
    const contact = await this.contactSrv.getContactByEmail(this.usuarioActual.email);
    if (contact && !(contact instanceof Array)) {
      this.contactoSelected = contact;
      this.formNewTicket.get('contactoPrincipal')?.patchValue(contact.nombre);
    }
    this.loading = false;
  }

  /** Método para abrir un modal
   * @param content modal a mostrar
   * @param size Tamaño del modal
   */
  open(content: any, size: "md" | "lg" | "xl") {
    this.modalService.open(content, { size: size, centered: true, backdropClass: 'modal-backdrop' });
    if (content._declarationTContainer.localNames[0] != 'nuevoAdjunto') {
      this.emailContacto = this.usuarioActual.email;
      this.contactForm.reset();
      this.contactForm.get('email')?.patchValue(this.emailContactoPermanente);
    } else {
      document.getElementById('fileSelection')?.click();
    }
  }

  /** Método para crea un nuevo ticket */
  async crearTicket() {
    let sendReq = {};
    const textDescription = replaceTags(this.formNewTicket.value.description)

    const comentarioSensible = await this.evaluteDescription(textDescription);
        let confirmado = true;
        if(comentarioSensible){
          confirmado = false
          const cancelarModalRef = await this.modalService.open(this.confirmarcomentario, { size: 'md', centered: true });
          confirmado = await cancelarModalRef.result.then(async (result) => {
            return true;
          }, (reason) => {
            return false;
          });
    } 

    if(confirmado) {
      if (this.formNewTicket.invalid) {
        this.validateAllFields(this.formNewTicket);
        this.toastr.error(Mensajes.INPUTS_VACIOS, '', { positionClass: 'toast-top-center', progressBar: false })
        return
      }
      if (this.contactoSelected && this.usuarioActual.entidad) {
        await this.checkContactAsociation(this.usuarioActual.entidad.C4C_ID, this.contactoSelected.idContacto, this.contactoSelected.email);
      }
  
  
      await this.getProcessorEmployee();
  
      this.loading = true;
      this.loadingMessage.next("Creando ticket, aguarde por favor")
      if (formNewTicket.value.tipo == 'ZSAT') {
        const reqSoporteAtm = {
          "ProcessingTypeCode": this.formNewTicket.value.tipo,
          "Name": this.formNewTicket.value.asunto,
          "BuyerPartyID": this.BuyerPartyID,
          "BuyerMainContactPartyID": this.contactoSelected!.idContacto,
          "ServiceIssueCategoryID": this.formNewTicket.value.categoryServices,
          "IncidentServiceIssueCategoryID": this.formNewTicket.value.categoryIncidents,
          "Z_ORIGEN_KUT": "12",
          "Z_ENTIDAD_KUT": this.usuarioActual.entidad?.codigoEntidad,
          "ServicePriorityCode": this.formNewTicket.value.prioridadTicket,
          "Z_NUMERODEBOLETA_KUT": this.formNewTicket.value.numeroDeBoleta,
          "Z_DESCRIPCION_KUT": " ",
          "Z_CREADOPOR_KUT": this.usuarioActual.email,
          "zDest_idDepartKUT_KUT": this.formNewTicket.value.departamentoDestino,
          "zDest_idCiudad_SDK": this.formNewTicket.value.ciudadDestino,
          "zDest_idBarrio_SDK": this.formNewTicket.value.barrioDestino,
          "zDest_Direccion_SDK": `${this.formNewTicket.value.calleDestino} ${this.formNewTicket.value.numeroCalleDestino}`,
          "zOrig_idBarrio_SDK": this.formNewTicket.value.barrioOrigen,
          "zOrig_Direccion_SDK": `${this.formNewTicket.value.calleOrigen} ${this.formNewTicket.value.numeroCalleOrigen}`,
          "zOrig_idDepartKUT_KUT": this.formNewTicket.value.departamentoOrigen,
          "zOrig_idCiudad_SDK": this.formNewTicket.value.ciudadOrigen,
          "z_CabTickMarca_KUT": this.formNewTicket.value.marca,
          "z_CabTIckModelo_KUT": this.formNewTicket.value.modelo,
          "z_CabTickSuministro_KUT": this.formNewTicket.value.suministro,
          "z_CanTickFechaHoraInstalacinATM_KUT": new Date(this.formNewTicket.value.fechaHoraInstalacion).toISOString(),
          "z_CabTickLongitud_KUT": this.formNewTicket.value.longitud,
          "z_CabTickLatitud_KUT": this.formNewTicket.value.latitud,
          "z_CabTickFechaBaja_KUT": new Date(this.formNewTicket.value.fechaHoraBaja).toISOString(),
          "Z_CabTickNroTermluno_KUT": this.formNewTicket.value.terminal,
          "Z_MOTIVOTICKET_KUT": this.formNewTicket.value.clasificacion,
          "ProcessorPartyID": this.ProcessorPartyID
        }
        sendReq = reqSoporteAtm;
      } else {
        const req = {
          "ProcessingTypeCode": this.formNewTicket.value.tipo,
          "Name": this.formNewTicket.value.asunto,
          "BuyerPartyID": this.BuyerPartyID,
          "BuyerMainContactPartyID": this.contactoSelected!.idContacto,
          "ServiceIssueCategoryID": this.formNewTicket.value.categoryServices,
          "IncidentServiceIssueCategoryID": this.formNewTicket.value.categoryIncidents,
          "Z_ORIGEN_KUT": "12",
          "Z_ENTIDAD_KUT": this.usuarioActual.entidad?.codigoEntidad,
          "ServicePriorityCode": this.formNewTicket.value.prioridadTicket,
          "Z_NUMERODEBOLETA_KUT": this.formNewTicket.value.numeroBoleta?.toString(),
          "Z_DESCRIPCION_KUT": " ",
          "Z_CREADOPOR_KUT": this.usuarioActual.email,
          "zImporteReclamado_KUT": this.formNewTicket.value?.importeReclamado?.toString(),
          "Z_CabEntidadDueadelATM_KUT": this.formNewTicket.value?.atmEntity,
          "Z_MOTIVOTICKET_KUT": this.formNewTicket.value.clasificacion,
          "ProcessorPartyID": this.ProcessorPartyID
        }
        if (this.formNewTicket.value.nroTarjeta!=null) {
        const tarjeta = this.protectCardNumber(this.formNewTicket.value.nroTarjeta);
        req['Z_Tarjeta_Enmascarada_KUT'] = tarjeta.protected
        req['Z_Tarjeta_Encriptada_KUT'] = tarjeta.encripted
      }
      sendReq = req;
      }
      const nTicket = await this.ticketService.createTicket(sendReq);
  
      if (nTicket) {
        if (nTicket.error) {
          this.toastr.error(nTicket.message, '', { positionClass: 'toast-top-center', progressBar: true });
        } else {
          this.auditSrv.logAction({ modulo: 'Tickets', accion: 'Crear ticket', detalle: `ID: ${nTicket.ID}` });
          if (this.formNewTicket.value.description != null) {
            const nDescripcion = replaceTags(this.formNewTicket.value.description)
            const description = { "FormattedText": nDescripcion };
            const descriptionObject = await this.ticketService.getTicketDescriptionObject(nTicket.ObjectID);
            await this.ticketService.createTicketDescription(descriptionObject.ObjectID, description);
          }
          if (this.documentos.length > 0) {
            await this.uploadFiles(nTicket.ID);
          }
          this.toastr.success(Mensajes.TICKET_CREADO, '', { positionClass: 'toast-top-center', progressBar: true });
          this.router.navigate(['tickets', nTicket.ID, 'detalle']);
        }
      } else {
        this.toastr.error(Mensajes.ERROR_TICKET, '', { positionClass: 'toast-top-center', progressBar: true });
      }
    }

    this.loading = false;
  }

  /** Método para seleccionar un contacto
   * @param contacto contacto seleccionado
   */
  selectContacto(contacto: Contacto) {
    this.contactoSelected = contacto;
    this.formNewTicket.get('contactoPrincipal')?.patchValue(contacto.nombre);
  }

  /** Método para limpiar el contacto principal */
  limpiarContacto() {
    this.contactoSelected = undefined;
    this.formNewTicket.get('contactoPrincipal')?.reset();
  }

  /** Método para obtener las ciudades asociadas a un departamento
   * @param stateID id del departamento del cual se desea obtener las ciudades
   * @param type From para origen y To para destino
   */
  async getCities(stateID: number, type: "from" | "to") {
    this.loading = true;
    this.loadingMessage.next("Obteniendo ciudades");
    if (type == 'from') {
      this.citiesFrom = await this.dataSrv.getCities(stateID);
      this.formNewTicket.get('ciudadOrigen')?.reset();
      this.formNewTicket.get('barrioOrigen')?.reset();
      this.formNewTicket.get('zonaOrigen')?.reset();
    } else {
      this.citiesTo = await this.dataSrv.getCities(stateID);
      this.formNewTicket.get('ciudadDestino')?.reset();
      this.formNewTicket.get('barrioDestino')?.reset();
      this.formNewTicket.get('zonaDestino')?.reset();
    }
    this.loading = false;
  }

  /** Método para obtener los barrios asociados a una ciudad
   * @param cityID id de la ciudad de la cual se desea buscar los barrios
   * @param type From para origen y To para destino
   */
  async getNeighborhoods(cityID: string, type: "from" | "to") {
    this.loading = true;
    this.loadingMessage.next("Obteniendo Barrios");
    if (type == 'from') {
      this.neighborhoodsFrom = await this.dataSrv.getNeighborhoods(cityID);
      this.formNewTicket.get('barrioOrigen')?.reset();
    } else {
      this.neighborhoodsTo = await this.dataSrv.getNeighborhoods(cityID);
      this.formNewTicket.get('barrioDestino')?.reset();
    }
    this.loading = false;
  }

  /** Método para seleccionar un archivo
   * @param evt evento de seleccion
   */
  async selectFile(evt: any) {
    const files = Array.from<File>(evt.target.files);
    this.loading = true;
    this.loadingMessage.next("Verificando validez del documento, esto puede demorar unos segundos");
    const { valid, isSensitve }= await this.adjuntosService.validfiles(files, true);
    this.loading = false;
    if(isSensitve){
      this.toastr.warning('Este archivo contiene datos sensibles y será marcado como tal', '', { positionClass: 'toast-top-center', progressBar: false });
    }
    if (valid) {
      files.forEach(file => {
        const fileName = FileUtils.fixName(file.name);
        if (fileName.shorted) {
          this.toastr.info('Nombre actualizado a 40 carácteres', '', { positionClass: 'toast-top-center', progressBar: false });
        }
        FileUtils.fileToBase64(file).then(data => {
          const documento: Documento = {
            tituloOriginal: file.name,
            titulo: fileName.full,
            tituloSolo: fileName.short,
            extension: fileName.extention,
            tamaño: file.size,
            fechaCreacion: new Date(moment.now()),
            datos: data,
            contieneInformacionSensible: isSensitve,
            file: file
          };
          this.documentos.push(documento);
        });
      });
    }
  }

  /** Método para obtener tamaño del documento 
   * @param documento documento del cual obtener su tamaño
  */
  getFileSize(documento: any): string {
    return FileUtils.getFileSize(documento);
  }

  /** Método para eliminar documento de la lista de archivos a adjuntar 
   * @param documento documento a eliminar de la lista
  */
  deleteFile(documento: any): void {
    this.documentos = this.documentos.filter(doc => {
      return doc != documento;
    });
  }

  /** Método para subir todos los adjuntos y asociar al ticket
   * @param idTicket id del ticket al cual asociar los adjuntos
   */
  async uploadFiles(idTicket: string) {
    this.loading = true;
    this.loadingMessage.next("Subiendo archivos")
    let allUPloaded = true;
    for (const documento of this.documentos) {
      if(documento.contieneInformacionSensible){ 
        documento.subido = await this.adjuntosService.uploadSecureFile(
          documento, 
          idTicket
        );
      } else {
        documento.subido = await this.adjuntosService.uploadFile(
          documento,
          idTicket,
          this.BuyerPartyID
        );
      }
      this.auditSrv.logAction({
        modulo: "Tickets",
        accion: "Subir adjunto",
        detalle: `Ticket: ${idTicket}, Adjunto: ${documento.titulo}`,
      });
      if (!documento.subido) {
        allUPloaded = false;
      }
    }
    if (!allUPloaded) {
      this.toastr.error(Mensajes.NUEVO_ADJUNTO_ERROR, '', { positionClass: 'toast-top-center', progressBar: false });
    }
    this.loading = false;
  }

  /** Método para ajustar la vista del formulario cuando se cambia el tipo de ticket 
   * @param event evento de cambio
  */
  onChange(event: any) {
    this.isTkVeredicto = false;
    this.formNewTicket.get('sucursal')?.clearValidators();
    this.formNewTicket.get('sucursal')?.updateValueAndValidity();
    this.formNewTicket.get('importeReclamado')?.removeValidators(Validators.required);
    this.formNewTicket.get('importeReclamado')?.updateValueAndValidity();
    this.formNewTicket.get('numeroBoleta')?.removeValidators(Validators.required);
    this.formNewTicket.get('numeroBoleta')?.updateValueAndValidity();
    this.formNewTicket.get('atmEntity')?.removeValidators(Validators.required);
    this.formNewTicket.get('atmEntity')?.updateValueAndValidity();
    this.formNewTicket.get('asunto')?.addValidators(Validators.required)
    this.formNewTicket.get('asunto')?.updateValueAndValidity();
    this.formNewTicket.get('categoryServices')?.patchValue('')
    this.formNewTicket.get('categoryIncidents')?.patchValue('')


    this.BuyerPartyID = (formNewTicket.value.tipo == 'Z6' || formNewTicket.value.tipo == 'Z9') ?
      this.formNewTicket.value.sucursal : this.usuarioActual.entidad?.C4C_ID
    if (event == "") {
      this.formNewTicket.get('asunto')?.reset();
      this.formNewTicket.get('prioridadTicket')?.setValue("3");
    }
    if (formNewTicket.value.tipo == 'Z9') {
      this.formNewTicket.get('sucursal')?.addValidators([Validators.required, Validators.minLength(1), Validators.maxLength(240)]);
      this.formNewTicket.get('sucursal')?.updateValueAndValidity();
      this.formNewTicket.get('contactoPrincipal')?.removeValidators(Validators.required)
      this.formNewTicket.get('contactoPrincipal')?.updateValueAndValidity();
      this.limpiarContacto();
      this.BuyerPartyID = this.formNewTicket.value.sucursal;
    } else {
      this.asociacionContacto();
      this.formNewTicket.get('contactoPrincipal')?.addValidators(Validators.required)
      this.formNewTicket.get('contactoPrincipal')?.updateValueAndValidity();
      switch (formNewTicket.value.tipo) {
        case 'Z6':
          this.formNewTicket.get('sucursal')?.addValidators([Validators.required, Validators.minLength(1), Validators.maxLength(240)]);
          this.formNewTicket.get('sucursal')?.updateValueAndValidity();
          break;
        case 'ZVE':
          this.isTkVeredicto = true;
          this.formNewTicket.get('importeReclamado')?.addValidators(Validators.required)
          this.formNewTicket.get('importeReclamado')?.updateValueAndValidity();
          this.formNewTicket.get('numeroBoleta')?.addValidators(Validators.required)
          this.formNewTicket.get('numeroBoleta')?.updateValueAndValidity();
          this.formNewTicket.get('atmEntity')?.addValidators(Validators.required)
          this.formNewTicket.get('atmEntity')?.updateValueAndValidity();
          this.formNewTicket.get('asunto')?.removeValidators(Validators.required)
          this.formNewTicket.get('asunto')?.updateValueAndValidity();
          this.formNewTicket.get('categoryServices')?.patchValue('CA_900');
          this.formNewTicket.get('categoryIncidents')?.patchValue('');
          this.formNewTicket.get('categoryIncidents')?.removeValidators(Validators.required)
          this.formNewTicket.get('categoryIncidents')?.updateValueAndValidity();
          this.showCategoryIncident = false;
          break;
        default:
          break;
      }
    }
  }

  onChangeSucursal(evt) {
    this.BuyerPartyID = evt;
  }

  /** Método para ajustar la vista del formulario cuando se cambia la categoría de ticket */
  onChangeCategory() {
    this.eliminateAllValidators();
    this.showCategoryIncident = !Constantes.CATEGORIAS_SIN_INCIDENTES.includes(this.formNewTicket.value.categoryServices);
    this.formNewTicket.get('categoryIncidents')?.patchValue('');
    if (!this.eliminandoValidators) {
      switch (this.formNewTicket.value.categoryServices) {
        case 'CA513':
          this.formNewTicket.get("paisDestino")?.patchValue("PY");
          this.validatorAltaAtm.forEach(campo => {
            this.formNewTicket.get(campo)?.addValidators(Validators.required)
            this.formNewTicket.get(campo)?.updateValueAndValidity();
            this.formNewTicket.get(campo)?.reset()
          });
          break;
        case 'CA514':
          this.validatorBajaAtm.forEach(campo => {
            this.formNewTicket.get(campo)?.addValidators(Validators.required)
            this.formNewTicket.get(campo)?.updateValueAndValidity();
            this.formNewTicket.get(campo)?.reset()
          });
          break;
        case 'CA515':
          this.formNewTicket.get("paisOrigen")?.patchValue("PY");
          this.formNewTicket.get("paisDestino")?.patchValue("PY");
          this.validatorReubicacionAtm.forEach(campo => {
            this.formNewTicket.get(campo)?.addValidators(Validators.required)
            this.formNewTicket.get(campo)?.updateValueAndValidity();
            this.formNewTicket.get(campo)?.reset()
          });
          break;
        case 'CA516':
          this.validatorAsistencia.forEach(campo => {
            this.formNewTicket.get(campo)?.addValidators(Validators.required)
            this.formNewTicket.get(campo)?.updateValueAndValidity();
            this.formNewTicket.get(campo)?.reset()
          });
          break;
        case 'CA517':
          this.validatorInforme.forEach(campo => {
            this.formNewTicket.get(campo)?.addValidators(Validators.required)
            this.formNewTicket.get(campo)?.updateValueAndValidity();
            this.formNewTicket.get(campo)?.reset()
          });
          break;
        default:
          break;
      }
    }
    if (Constantes.CATEGORIAS_SIN_INCIDENTES.includes(this.formNewTicket.value.categoryServices) || this.formNewTicket.value.tipo == 'ZVE') {
      this.showCategoryIncident = false;
      this.formNewTicket.get('categoryIncidents')?.removeValidators(Validators.required);
      this.formNewTicket.get('categoryIncidents')?.updateValueAndValidity();
    } else {
      this.showCategoryIncident = true;
      this.formNewTicket.get('categoryIncidents')?.addValidators(Validators.required);
      this.formNewTicket.get('categoryIncidents')?.updateValueAndValidity();
    }
  }

  /** Método para ajustar los validadores de campo */
  eliminateAllValidators() {
    this.eliminandoValidators = true;
    this.allValidators.forEach(campo => {
      this.formNewTicket.get(campo)?.removeValidators(Validators.required);
      this.formNewTicket.get(campo)?.updateValueAndValidity();
    });
    this.eliminandoValidators = false;
  }

  /** Método para comprobar si un email tiene contactos asociados */
  async contactEmailValidate() {
    this.emailContacto = this.emailContacto.trim();
    this.contactForm.get('email')?.setValue(this.contactForm.get('email')?.value.trim());
    if (!this.contactForm.get('email')?.invalid) {
      this.emailContactoPermanente = this.emailContacto;
      this.loading = true;
      this.loadingMessage.next("Obteniendo contactos");
      // obtiene todos los contactos asociados al email
      this.contactoPrincipalList = [];
      const contacts = await this.contactSrv.getContactByEmail(this.emailContacto, true);
      if (contacts && contacts instanceof Array && contacts.length > 0) {
        this.contactoPrincipalList = contacts;
        this.collectionSizeContacto = this.contactoPrincipalList.length;
        this.open(this.contactosAsociadosModal, "xl");
      } else {
        this.toastr.warning(Mensajes.CONTACTO_PRINCIPAL_SIN_EMAIL, '', { positionClass: 'toast-top-center', progressBar: true })
        this.open(this.nuevoContactoModal, "xl");
      }
      this.loading = false;
    } else {
      this.toastr.error(Mensajes.ERROR_AL_VALIDAR_MAIL, '', { positionClass: 'toast-top-center', progressBar: false });
    }
  }

  /** Método para validar campos de un formulario
   * @param formGroup formulario a validar
   */
  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  /** Método para crear un nuevo contacto */
  async crearContacto() {
    if (this.contactForm.invalid) {
      this.validateAllFields(this.contactForm);
      this.toastr.error(Mensajes.DATOS_INCOMPLETOS, '', { positionClass: 'toast-top-center', progressBar: false });
      return
    } else {
      this.loading = true;
      this.loadingMessage.next("Creando contacto");
      const req = {
        "FirstName": this.contactForm.value.nombre,
        "LastName": this.contactForm.value.apellido,
        "GenderCode": this.contactForm.value.genero,
        "Phone": this.contactForm.value.telefono,
        "Mobile": this.contactForm.value.telefonoMovil,
        "Email": this.contactForm.value.email,
        "AccountID": this.usuarioActual.entidad?.C4C_ID
      }
      // llama al servicio para crear el contacto, espera el resultado
      const res = await this.ocService.post(`/http/contactoportal`, req);
      if (res.created) {
        const nContact = res.body! as Contacto 
        this.auditSrv.logAction({ modulo: 'Tickets', accion: 'Crear contacto', detalle: `ID: ${nContact.idContacto}, Nombre: ${nContact.nombre}` });
        this.selectContacto(nContact);
        this.loading = false;
        this.modalService.dismissAll();
        this.toastr.success(Mensajes.ALTA_CONTACTO, '', { positionClass: 'toast-top-center', progressBar: true });
        this.loading = false;
        return true;
      } else {
        this.loading = false;
        this.toastr.error(Mensajes.ERROR_AL_CREAR_CONTACTO, '', { positionClass: 'toast-top-center', progressBar: false });
        return false;
      }
    }
  }

  /** Método para filtrar los modelos de atm teniendo en cuenta la marca seleccionada en el formulario */
  changeModelo() {
    this.formNewTicket.get('modelo')?.reset();
    const atm = this.atms.find(element => element.id == formNewTicket.value.marca);
    if (atm) {
      this.atmModels = atm.modelos;
    }
  }

  /** Método para prevenir la escritura de carácteres especiales en el campo numérico 
   * @param event evento de teclado (keyinput, keydown, keyup)
  */
  preventInvalidChars(event: any) {
    const invalidChars = ["-", "+", "e"];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  /** Método mostrar la zona de carga mediante drag & drop 
   * @param event evento de drag
  */
  onDragOver(event: any) {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    if (!this.showDropZone) {
      this.showDropZone = true;
    }
  }

  /** Método para capturar cuando se sueltan archivos a la zona de carga
   * @param event evento de drop
  */
  onFileDropped(event: any) {
    this.showDropZone = false;
    this.selectFile(event);
    this.selectedFiles = undefined;
  }

  /** Método para mostrar un mensaje de error
   * @param mensaje: mensaje a mostrar
   */
  showError(mensaje: string) {
    this.toastr.error(mensaje, '', {
      positionClass: 'toast-top-center',
      tapToDismiss: true,
      timeOut: 900000,
      extendedTimeOut: 900000
    });
  }

  onPaste(event) {
    const items = event.clipboardData.items;
    if (!items) return;

    //access data directly
    let is_image = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        //image
        const blob = items[i].getAsFile();
        const evt = {
          target: {
            files: [blob]
          }
        }
        this.selectFile(evt);
        is_image = true;
      }
    }
    if (is_image == true) {
      event.preventDefault();
    }
  }

  /**
   * Método para comprobar si el contacto ya está asociado al cliente, si no lo está lo asocia
   * @param accountID id de la cuenta
   * @param contactID id del contacto
   * @param contactEmail email del contacto
   */
  async checkContactAsociation(accountID: string, contactID: string, contactEmail: string) {
    this.loading = true;
    this.loadingMessage.next("Comprobando asociación");
    const exists = await this.contactSrv.checkContact(accountID, contactID);
    if (!exists.error && !exists.asociacion) {
      this.loadingMessage.next("Asociación contacto a entidad");
      await this.contactSrv.associateContact(accountID, contactID, contactEmail);
    }
    this.loading = false;
  }

  /**
   * Metodo para obtener plantilla de tickets
   * @param tipo 
   * @param servicio 
   * @param incidencia 
   */
  async getDescriptionTemplate(tipo, servicio, incidencia) {

    try {
      if (tipo != '') {
        const plantilla: any = await this.dataSrv.getTemplateTicketByType(tipo, servicio, incidencia);

        if (plantilla) {
          const strPlantilla = plantilla.template;
          const ndescripcion = replaceTags(strPlantilla);
          this.formNewTicket.get('description')?.patchValue(ndescripcion);
        } else {
          this.formNewTicket.get('description')?.patchValue('');
        }
      }

    } catch (error: any) {
      this.toastr.error(`No pudo obtener plantilla del tipo del ticket`, '', { positionClass: 'toast-top-center', progressBar: false });
    }
  }

  check(text){
    replaceTags(text)
  }

  getTipoTicketId(id): string {
    for (const t of this.ticketsTypes) {
      if (t.id == id) {
        return t.OID ? t.OID : '';
      }
    }
    return ''
  }
  async getProcessorEmployee() {
    const params: Param[] = [];

    params.push({ key: 'tipoTicket', value: this.getTipoTicketId(this.formNewTicket.get('tipo')?.value) })
    params.push({ key: 'servicio', value: this.formNewTicket.get('categoryServices')?.value ? this.getServiciosIdBycode(this.formNewTicket.get('categoryServices')?.value) : '' })
    params.push({ key: 'incidencia', value: this.formNewTicket.get('categoryIncidents')?.value ? this.getIncidenciaIdByCode(this.formNewTicket.get('categoryIncidents')?.value) : '' })


    const detalleEmpleado = await this.mongoSrv.get(`/detalle-empleadoc4c/processor-party`, params);

    if (detalleEmpleado) {
      this.ProcessorPartyID = detalleEmpleado.empleado.idEmpleado
    }
  }

  /**
  * Metodo que obtinene los codigos de los servicios, utilizando su ID 
  * @param servicios 
  * @returns 
  */
  getServiciosIdBycode(servicioCode: string): string {
    for (const s of this.ticketsTypesCategories) {
      if (s.id == servicioCode) {
        if (s.OID) return s.OID;
      }
    }
    return '';
  }


  /**
   * Metodo que obtiene el codigo de la incidencia, utilizando el ids
   * @param incidenteId 
   * @returns 
   */
  getIncidenciaIdByCode(incidenteId: string): string {
    for (const s of this.ticketsTypesCategoriesIncidents) {
      if (s.id == incidenteId) {
        if (s.OID) return s.OID;
      }
    }
    return '';
  }

  /** Método para proteger los datos de un número de tarjeta
   * @param cardNumber número de tarjeta a proteger
   * @returns objeto con el cardNumber encriptado y el cardNumber protegido.
   */
  protectCardNumber(cardNumber: string) {
    const cardNumberEncripted = this.encryptService.encrypt(cardNumber);
    // Reemplaza los numeros del cardNumber por * dejando solo los ultimos 4 visibles
    const cardNumberProtected = cardNumber.slice(0, 6) + cardNumber.slice(6, -4).replace(/\d/g, '*') + cardNumber.slice(-4);
    return {encripted: cardNumberEncripted, protected: cardNumberProtected}
  }
  
  async evaluteDescription(text){
    const data = {
      text, 
    }
    const result = await this.comentarioService.checkComentario(data);
    return result.body
  }
}

