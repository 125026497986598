import { Component, Input, OnInit } from '@angular/core';
import { faSpinner, faStar } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MongoDBService } from 'src/app/services/mongoDB.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'bpe-form-encuesta',
  templateUrl: './form-encuesta.component.html'
})
export class FormEncuestaComponent implements OnInit {

  @Input('idEvaluacion') idEvaluacion;
  evaluacion: any;
  faStar = faStar
  faSpinner = faSpinner;
  creating = false;
  
  constructor(private service: MongoDBService,
    private usersSrv: UsersService,
    public activeModal: NgbActiveModal) { }


  ngOnInit(): void {
    this.obtenerDatos()

  }

  cancelar() {
    
    this.evaluacion.estado = 'ENCUESTA NO RESPONDIDA';
    this.evaluacion.usuario = this.usersSrv.getActualUser()?.id
    this.service.update(`/evaluaciones/`, this.idEvaluacion, this.evaluacion)
    .then((data: any) => {
      this.activeModal.close('Se hizo click en cancelar');
    })
  
  }

  finalizar() {
    
    this.evaluacion.estado = 'COMPLETADA';
    this.evaluacion.usuario = this.usersSrv.getActualUser()?.id
    this.service.update(`/evaluaciones/`, this.idEvaluacion, this.evaluacion)
    .then((data: any) => {
      this.activeModal.close(data);
    })
  }
  
  obtenerDatos() {
    this.service.getAll(`/evaluaciones/${this.idEvaluacion}`)
      .then((data: any) => {
        this.evaluacion = data;
      })
  }

  validarFormulario(){
    let valido = true;
    for(const r of this.evaluacion.preguntas){
        if(r.respuesta > 0 && r.respuesta < 3 && r.comentario == '') {
          valido = false;
        }
    }
    return valido;
  }
}
