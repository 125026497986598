import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class PermGuardService  {

  constructor (
    private router: Router,
    private usersSrv: UsersService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.usersSrv.getActualUser();
    let permisosRequeridos:any[]=[]
    if(route.data.roles) {
      permisosRequeridos = route.data.roles
    }
    if(permisosRequeridos.length==0){
      return true;
    }
    if(user.roles) {
      for(const rol of user.roles) {
        for(const privilegio of rol.privileges) {
          if(permisosRequeridos.includes(privilegio.codigo)){
            return true;
          }
        }
      }
    }
    this.router.navigate(['/','home'])
    return false;
  }

}
