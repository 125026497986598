import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-bienvenida',
  templateUrl: './bienvenida.component.html'
})
export class BienvenidaComponent implements OnInit {
  
  userName = '';

  constructor(private usersSrv: UsersService) { }
  
  ngOnInit(): void {
    this.userName = this.usersSrv.getActualUser().nombreCompleto
  }

}
