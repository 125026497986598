import { Injectable } from '@angular/core';
import { io, Socket } from "socket.io-client";
import { Observable, Subject } from 'rxjs';
import { Notificacion } from '../interfaces/interfaces';
import { environment } from '../../environments/environment';
import { UsersService } from './users.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    _subject = new Subject<Notificacion>();
    private socket: Socket;

    constructor(
        private dataService: UsersService,
        private storageService: StorageService
    ) {
        console.log(`Creación de socket: ${Math.random()}`)
        this.socket = io(this.getServer(), { autoConnect: false, transports: ['websocket'] });

        this.init()
    }

    /**
     * Realiza la conexión al socket, agregando la autenticación de usuario, sessionID e identificar del user.
     */
    connect() {
        const actualUser = this.dataService.getActualUser();
        const token = this.storageService.get('token')
        const sessionID = localStorage.getItem("sessionID");

        this.socket.auth = {
            sessionID,
            usuario: actualUser.email,
            accessToken: token,
            xuser: actualUser.id
        }

        this.socket.connect();
    }

    /**
     * Inicializa los eventos a escuchar del socket
     */
    init() {


        this.socket.on("session", ({ sessionID, userID }) => {
            this.socket.auth = { sessionID };
            localStorage.setItem("sessionID", sessionID);
        });

        this.socket.on("connect_error", (err) => {
            console.log(err)
        });

        this.socket.on('notify', ({ mensaje, titulo }) => {
            this._subject.next({
                id: this.randomID(),
                mensaje,
                titulo,
                fechaCreacion: '',
                documento: ''
            });
        })
    }

    getObservableNotificaciones(): Observable<Notificacion> {
        return this._subject.asObservable();
    }

    randomID() {
        return Math.floor(Math.random() * 10000) + 1
    }

    /**
     * obtiene base del servidor de la API.
     * @returns URL base del servidor
     */
    getServer() {
        const url = environment.apiUrl
        return url.substring(0, url.length-4)
    }

}