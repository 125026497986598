export const DataTicket= {
    listado:{
        titulo:'Tickets',
        roles: ['SEC_TICKETS_GET']
    },
    crear: {
        titulo:'Nuevo ticket',
        roles: ['SEC_TICKETS_OPERACION']
    },
    detalle: {
        titulo:'Ticket',
        roles: ['SEC_TICKETS_GET']
    }
}
