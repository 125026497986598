export const environment = {
  production: false,
  app_name: "Portal de Entidades",
  env_name: "QA",
  PartyID: '8000000062',
  TERRITORY: '131',
  // API PORTAL DE ENTIDADES
  apiUrl: import.meta.env.NG_APP_API_URL,
  apiLoginBasic: import.meta.env.NG_APP_API_BASIC_LOGIN,
  apiRecoveryBearer: import.meta.env.NG_APP_API_RECOVERY_BEARER,
  apiLogBearer:import.meta.env.NG_APP_API_LOG_BEARER,

  // CAPTCHA
  captcha_site_key: import.meta.env.NG_APP_API_CAPTCHA_SITE_KEY,
  captcha_site_key_v2: import.meta.env.NG_APP_API_CAPTCHA_SITE_KEY_V2
};
