import { FormControl, FormGroup, Validators } from "@angular/forms";

export const formLogin = new FormGroup({
  username: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  password: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
});
