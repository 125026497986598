<!-- SPINNER FULL SCREEN -->
<app-loadingSpinner [message]="loadingMessages" [show]="loading"></app-loadingSpinner>

<div class="row px-3" *ngIf="!loading">

    <div class="col-12">
        <span class="page-route">Oportunidad comercial/Detalles</span>

    </div>

    <div class="col-12 py-3">
        <app-title></app-title>
    </div>

</div>

<form *ngIf="!loading">

    <div class="d-flex flex-row flex-wrap" style="margin-left: -1rem;">

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col-12 pt-3">
                <label class="label-entity">ID</label>
            </div>
            <div class="col-12">
                <input readonly class="cursor-default ticket-resumen-input input-agregar" type="text"
                    value="{{this.idOc}}">
            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6  justify-content-center">
            <div class="col-12 pt-3">
                <label class="label-entity">Tipo de Documento</label>
            </div>
            <div class="col-12">
                <input readonly class="cursor-default ticket-resumen-input input-agregar" type="text"
                    value="{{oc.ProcessingTypeCodeText}}">
            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col-12 pt-3">
                <label class="label-entity">Ciclo de ventas</label>
            </div>
            <div class="col-12">
                <input readonly class="cursor-default ticket-resumen-input input-agregar" type="text"
                    value="{{oc.SalesCycleCodeText}}">
            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col pt-3">
                <label class="label-entity">Cliente</label>
            </div>
            <div class="col">
                <div class="d-flex flex-row ticket-resumen-input">
                    <input class="cursor-default ticket-resumen-input input-agregar" readonly type="text"
                        value="{{cliente.denominacion}}">
                </div>

            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">

            <div class="col pt-3">
                <label class="label-entity">Contacto principal</label>
            </div>
            <div class="col">
                <div class="d-flex flex-row ticket-resumen-input">
                    <input class="cursor-default ticket-resumen-input input-agregar" readonly type="text"
                        value="{{contacto.Name}}">
                </div>

            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">

            <div class="col pt-3">
                <label class="label-entity">Razon Social</label>
            </div>
            <div class="col">
                <div class="d-flex flex-row ticket-resumen-input">
                    <input readonly class="cursor-default ticket-resumen-input input-agregar " type=""
                        value="{{cliente.razonSocial}}">
                </div>

            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col-12 pt-3">
                <label class="label-entity">RUC</label>
            </div>
            <div class="col-12">
                <input readonly class="cursor-default ticket-resumen-input input-agregar" type="text"
                    value="{{cliente.ruc}}">
            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col pt-3">
                <label class="label-entity">Nombre</label>
            </div>
            <div class="col">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    placeholder="Nombre" value="{{oc.Name}}">
            </div>
        </div>



        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">

            <div class="col pt-3">
                <label class="label-entity">Categoria bancard </label>
            </div>
            <div class="col">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    value="{{oc.Z_CLASIFICACIONABC_KUTText}}">
            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col-12 pt-3">
                <label class="label-entity">T. Debito Nacional </label>
            </div>
            <div class="col-12">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    value="{{formatNumbers(oc.Z_TDEBITONACContent_KUT)}}">

            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col pt-3">
                <label class="label-entity">Unidad T. Debito Nacional</label>
            </div>
            <div class="col">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    value="{{oc.Z_TDEBITONACUnitCode_KUT}}">
            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col-12 pt-3">
                <label class="label-entity">T. Debito Inter</label>
            </div>
            <div class="col-12">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    value="{{formatNumbers(oc.Z_TDEBITOINTContent_KUT)}}">

            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">

            <div class="col pt-3">
                <label class="label-entity">Unidad T. Debito Inter</label>
            </div>
            <div class="col">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    value="{{oc.Z_TDEBITOINTUnitCode_KUT}}">
            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col-12 pt-3">
                <label class="label-entity">T. Credito Internacional</label>
            </div>
            <div class="col-12">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    value="{{formatNumbers(oc.Z_TCREDITOINTContent_KUT)}}">
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">

            <div class="col pt-3">
                <label class="label-entity">Unidad T. Credito Internacional</label>
            </div>
            <div class="col">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    value="{{oc.Z_TCREDITOINTUnitCode_KUT}}">


            </div>
        </div>


        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">

            <div class="col-12 pt-3">
                <label class="label-entity">T. Credito Nac.</label>
            </div>
            <div class="col-12">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    value="{{formatNumbers(oc.Z_TARJETA_CNContent_KUT)}}">

            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col pt-3">
                <label class="label-entity">Unidad T. Credito Nacional</label>
            </div>
            <div class="col">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    value="{{oc.Z_TARJETA_CNUnitCode_KUT}}">


            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center" *ngIf="cliente && cliente.tipoCliente">
            <div class="col pt-3">
                <label class="label-entity">Tipo Cliente</label>
            </div>
            <div class="col">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    value="{{cliente.tipoCliente.descripcion}}">
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col pt-3">
                <label class="label-entity">Tipo Empresa</label>
            </div>
            <div class="col">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    value="{{cliente.tipoEmpresa.descripcion}}">
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col pt-3">
                <label class="label-entity">Estado</label>
            </div>
            <div class="col">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    value="{{oc.ExternalUserStatusCode}} - {{oc.ExternalUserStatusCodeText}}">
            </div>
        </div>

    </div>
    <div class="d-flex flex-row justify-content-center pt-3 mt-3">
        <span class="main-title"> Tickets relacionados</span>
    </div>
    <div class="col-lg-12 col-sm-12" style="font-size: small;">
        <div  class="table-responsive">
            <table class="table table-style table-font-size">
                <tr class="py-2">
                    <th scope="col">ID</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Descripción</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Categoria de Servicio</th>
                    <th scope="col">Categoria de Incidente</th>

                </tr>
                <tbody *ngFor="let ticket of oc.ticketsAsociados">
                    <tr  class="py-2 mt-2" >
                        <td>{{ ticket.ticketId }}</td>
                        <td>{{ ticket.estado }}</td>
                        <td>{{ ticket.descripcion }}</td>
                        <td>{{ ticket.tipo }}</td>
                        <td>{{ ticket.servicio }}</td>
                        <td>{{ ticket.incidencia }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <app-tabla-productos-asociados-detalle [ocObjectId]="ocObjectId"
        [editable]="editable"></app-tabla-productos-asociados-detalle>

    <app-tabla-cuentas-bancarias-detalle 
        [ocObjectId]="ocObjectId"
        [editable]="editable" 
        [requiereConfirmacion]="oc.Z_RequiereConfirmacion_KUT"
        [cuentaConfirmada]="oc.Z_CuentaConfirmada_KUT">
    </app-tabla-cuentas-bancarias-detalle>

    <div class="mx-3">
        <div class="d-flex flex-row justify-content-center my-0">
            <span class="main-title">Adjuntos</span>
        </div>
        <app-adjuntos [idOc]="idOc" [idEvento]="idOc" [idCliente]="idCliente" [canUpload]="canUploadAttached"
            [canDelete]="canDeleteAttached"></app-adjuntos>
    </div>

    <app-actividades-ventas-oc [llamadasRealizadas]="datosLLamadas" [loading]="loading"></app-actividades-ventas-oc>

    <div class="mx-3">
        <div class="d-flex flex-row justify-content-center my-0">
            <span class="main-title">Comentarios</span>
        </div>
        <app-comentarios [oc]="oc" [type]="'OC'"></app-comentarios>
    </div>



</form>