import { Routes } from "@angular/router";
import { PermGuardService } from "../../services/perm-guard.service";
import { DataAuditoria } from "./auditoria.routes.data";
import { ListadoAuditComponent } from "./listado-auditoria/listado-auditoria.component";


export const Auditoria_Routes: Routes = [
    {path: 'listado', component: ListadoAuditComponent,data: DataAuditoria.listado, canActivate:[PermGuardService]},
    {path: '', pathMatch: 'full', redirectTo:'listado'},
    {path: '**', pathMatch: 'full', redirectTo: 'listado'}
  ]
  ;
   
