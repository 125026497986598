import { Component, Input } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { TareasOC } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-actividades-ventas-oc',
  templateUrl: './actividades-ventas-oc.component.html'
})
export class ActividadesVentasOcComponent {

  @Input("llamadasRealizadas") llamadasRealizadas: TareasOC[]=[];
  @Input("loading") loading;

  //paginado
  page=1;
  pageSize=5;
  collectionSize=0;
  faSpinner=faSpinner;

  getDate(date: string){
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  }

}
