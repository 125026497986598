import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Regex } from "src/app/constants/regex.const";

export const formNewUser  = new FormGroup({
    name: new FormControl('',[Validators.required, 
                                Validators.minLength(1), 
                                Validators.maxLength(240), 
                                Validators.pattern(Regex.ENTIDADES)]), 
    surname: new FormControl('',[Validators.required, 
                                Validators.minLength(1), 
                                Validators.maxLength(240), 
                                Validators.pattern(Regex.ENTIDADES)]), 
    email: new FormControl('',[Validators.required,
                                Validators.minLength(1),
                                Validators.maxLength(240),
                                Validators.pattern(Regex.EMAIL)]),
    rol: new FormControl("",[Validators.required,
                                Validators.minLength(1),
                                Validators.maxLength(240),]),
    perfil: new FormControl("",[Validators.minLength(1),
                                Validators.maxLength(240),])


                    
})
