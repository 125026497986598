<!-- SPINNER FULL SCREEN -->
<div *ngIf="loading || exporting"
    class="d-flex flex-column align-items-center justify-content-center w-100 h-100 spinner-background"
    style="border:none; background-color:rgba(0, 0, 0, 0.7);top:50%;left:50%;transform: translate(-50%,-50%);position: fixed; z-index: 9999999;">
    <fa-icon [icon]="faSpinner" class="fa-2x mt-5 d-flex justify-content-center " style="color: #fff;"
        animation="spin"></fa-icon>
    <span class="label-entity" style="color:#fff">{{exporting?'Exportando':'Buscando oportunidades'}}</span>
</div>
<div class="row px-3">
    <div #navegacion class="col-12">
        <span class="page-route">Oportunidad comercial</span>
    </div>
    <div #titulo class="col-12 py-3">
        <app-title></app-title>
    </div>

    <div class="row">

        <div class="col-12 ml-3">
            <button (click)="toogleShowFilters()" class="btn btn-primary-portal  btn-primary btn-sm">
                {{showFilters ? 'Ocultar filtros' : 'Mostrar filtros avanzados'}}
            </button>
            
        </div>
    </div>


    <div class="col-12  ml-3"  *ngIf="showFilters">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-col-xl-5 g-4 mt-3 mr-4">
            

            <div class="col px-1 py-2">
                <div class="d-flex align-items-center">
                    <input class="main-content-input-ticket filtro-ticket" style="text-indent:15px" 
                    placeholder="Razón Social" [(ngModel)]="searchProspectPartyName">
                </div> 
            </div>
            <div class="col px-1 py-2">
                <div class="d-flex align-items-center">
                    <input class="main-content-input-ticket filtro-ticket" style="text-indent:15px" 
                    placeholder="RUC" [(ngModel)]="searchRUC">
                </div> 
            </div>
            <div class="col px-1 py-2">
                <div class="d-flex align-items-center">
                    <input class="main-content-input-ticket filtro-ticket" style="text-indent:15px" 
                    placeholder="Ciclo de Ventas" [(ngModel)]="searchProcessingTypeCodeText">
                </div> 
            </div>

            <div class="col px-1 py-2">
                <div class="d-flex align-items-center">
                    <input class="main-content-input-ticket filtro-ticket" style="text-indent:15px" 
                    placeholder="Nombre de contacto" [(ngModel)]="searchPrimaryContactPartyName">
                </div> 
            </div>

            
            <div class="col px-1 py-2">
                <div class="d-flex align-items-center">
                    <input class="main-content-input-ticket filtro-ticket" style="text-indent:15px" 
                    placeholder="Rubro" [(ngModel)]="searchRubroKutText">
                </div> 
            </div>


            <div class="col px-1 py-2">
                <div class="d-flex align-items-center">
                    <input class="main-content-input-ticket filtro-ticket" style="text-indent:15px" 
                    placeholder="Ciudad" [(ngModel)]="searchSalesOrganisationName">
                </div> 
            </div>

            <div class="col px-1 py-2">
                <div class="d-flex align-items-center">
                    <input class="main-content-input-ticket filtro-ticket" style="text-indent:15px" 
                    placeholder="Filtrar por creador" [(ngModel)]="searchCreadorPorText">
                </div> 
            </div>
            
        </div>
    </div>
    

    <div #filtros class="col-12 mt-3">
        
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2 d-flex">
                <input class="main-content-input-ticket filtro-ticket sin-icono" placeholder="ID"
                    [(ngModel)]="filterID" (keyup.enter)="buscar()">
                <button *ngIf="filterApplied" (click)="limpiar()" class="btn btn-primary-portal btn-primary btn-sm ml-2">
                    <fa-icon [icon]="faTrash"></fa-icon>
                </button>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                <ng-select class="select-m filtro-ticket" placeholder="Estado" [multiple]="true"
                    [(ngModel)]="filterStatus" [clearable]="false" [searchable]="true">
                    <ng-option value="{{estado.id}}" *ngFor="let estado of statuses | sort: 'asc' : 'descripcion'">
                        <span>{{estado.descripcion}}</span>
                    </ng-option>
                </ng-select>
            </div>



            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                <div class="d-flex align-items-center">
                    <label class="label-entity mb-0">Creado desde</label>
                    <input class="main-content-input-ticket date-input filtro-ticket" [(ngModel)]="filterCreateSince"
                        type="date">
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                <div class="d-flex align-items-center">
                    <label class="label-entity mb-0">Creado hasta</label>
                    <input class="main-content-input-ticket date-input filtro-ticket" [(ngModel)]="filterCreateUntil"
                        type="date">
                </div>
            </div>
            
            <div class="d-flex mb-2 ml-auto mr-3">
                <div class="d-flex align-items-end">
                    <button class="btn btn-primary-portal btn-primary btn-sm mr-2" (click)="buscar()">Filtrar</button>
                    <button class="btn btn-outline-secondary btn-sm" (click)="limpiar()">Limpiar</button>
                </div>
            </div>



        </div>
        
    </div>



    <div class="col-12 d-flex">
        <div class="d-flex align-items-end ml-auto">

            <div class="d-flex mr-3 mt-2 align-items-center">
                <div class="d-flex align-items-center">
                    
                    <button class="btn btn-primary-portal  btn-primary btn-sm" type="button" (click)="launchExportacion()">
                        <fa-icon [icon]="faFileExcel" class="mr-2"></fa-icon>Exportar
                    </button>
                </div>
            </div>

            <div class="d-flex mt-2 align-items-center">
                <div class="d-flex align-items-center">
                    
                    <button class="btn btn-primary-portal  btn-primary btn-sm" type="button" (click)="nuevaOc()">
                        <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>Nueva OC
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div class="col-12 py-4">



        <p-table #tabla [value]="oportunidades" dataKey="ID" [loading]="cargando">
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th>Detalle</th>
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-oc let-expanded="expanded">
                <tr>

                    <td>
                        <fa-icon *ngIf="oc.ticketsAsociados && oc.ticketsAsociados.length > 0"
                            pTooltip="Ver datos de Tickets asociados" [icon]="expanded ? faAngleUp : faAngleRight "
                            [pRowToggler]="oc" class="puntero"></fa-icon>
                    </td>

                    <td class="table-cell-ticket ml-3">
                        <fa-icon [icon]="faExternalLinkAlt" (click)="detallesOc(oc.ID)" class="puntero"></fa-icon>
                    </td>

                    <td *ngFor="let col of columns">
                        <ng-container *ngIf="!col.date">{{col.property ? oc[col.field][col.property] :
                            oc[col.field]}}</ng-container>
                        <ng-container *ngIf="col.date">{{col.property ? oc[col.field][col.property] : oc[col.field] |
                            date:col.date}}</ng-container>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td class="span-empty" [attr.colspan]="columns.length + 2">
                        <div class="d-flex justify-content-center">No existen datos.</div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-oc>
                <tr>
                    <td [attr.colspan]="columns.length + 2" style="background-color: #e7e7e7 !important;">
                        <div class="p-3">
                            <p-table [value]="oc.ticketsAsociados" dataKey="ticketId">
                                <ng-template pTemplate="caption"> Datos de tickets asociados a la Oportunidad Comercial
                                </ng-template>
                                <ng-template pTemplate="header">
                <tr>
                    <th>ID</th>
                    <th>Estado</th>
                    <th>Descripción</th>
                    <th>Tipo</th>
                    <th>Categoria de Servicio</th>
                    <th>Categoria de Incidente</th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-instalacion>
                <tr>
                    <td>{{ instalacion.ticketId }}</td>
                    <td>{{ instalacion.estado }}</td>
                    <td>{{ instalacion.descripcion }}</td>
                    <td>{{ instalacion.tipo }}</td>
                    <td>{{ instalacion.servicio }}</td>
                    <td>{{ instalacion.incidencia }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">No cuenta con tickets asociados</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    </td>
    </tr>
    </ng-template>

    </p-table>
    <div #paginado class="d-flex justify-content-end py-3" *ngIf="!loading">
        <label class="label-entity pt-2 pr-3">Cantidad de OC's: <b>{{collectionSize}}</b></label>
        <ngb-pagination *ngIf="collectionSize > 0" [(page)]="page" [maxSize]="3" [pageSize]="pageSize"
            [collectionSize]="collectionSize" [boundaryLinks]="true" (pageChange)="updatePagination()">
        </ngb-pagination>
    </div>
</div>
</div>