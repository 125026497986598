import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Regex } from "src/app/constants/regex.const";

export const formNuevoParticipante  = new FormGroup({
    nombre: new FormControl('',[Validators.required,
                                Validators.minLength(1),
                                Validators.maxLength(240),
                                Validators.pattern(Regex.NOMBRE_APELLIDO)]),
    apellido: new FormControl('',[Validators.required,
                                Validators.minLength(1),
                                Validators.maxLength(240),
                                Validators.pattern(Regex.NOMBRE_APELLIDO)]),   
    telefono: new FormControl('',[
                                Validators.minLength(1),
                                Validators.maxLength(240),]),
    email: new FormControl('',[
                                Validators.minLength(1),
                                Validators.maxLength(240),
                                Validators.pattern(Regex.EMAIL)]),

        

                    
})