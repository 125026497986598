import { Component, Input, OnInit, Output } from '@angular/core';
import { ComentariosService } from 'src/app/services/comentarios.service';
import { faSpinner} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-estados',
  templateUrl: './estados.component.html',
  styleUrls: ['./estados.component.scss']
})
export class EstadosComponent implements OnInit {

  @Input() ticket;
  @Output() collectionSize = new BehaviorSubject(0);
  loadingEstados=true;
  estados: Array<any> = [];
  faSpinner=faSpinner;

  //paginacion tabla participantes existentes
  page = 1;
  pageSize = 5;

  constructor(
    private comentarioService: ComentariosService
  ) { }

  ngOnInit(): void {
    this.getStatusChanges();
  }

  /** Método para obtener los cambios de estado del ticket actual */
  async getStatusChanges() {
    const comments = await this.comentarioService.getComentariosTK(this.ticket.ObjectID,true);
    comments.forEach(comment => {
      const str = comment.FormattedText
      const commentObj = {
        estadoAnterior: str.substring((str.indexOf('Estado anterior:')+16), (str.indexOf('Estado actual:')-1) ),
        estadoActual:str.substring((str.indexOf('Estado actual:')+14), ((str.length)-1) ),
        fecha:moment(comment.CreatedOn).locale('es').format(' L , HH:mm'),
        usuario: str.substring(0,(str.indexOf('\nUsuario:')-1))
      }
      this.estados.push(commentObj)
      this.collectionSize.next(this.estados.length ? this.estados.length : 0);
    })
    this.loadingEstados=false;
  }

}
