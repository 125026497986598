export const DataCatalogoProductos = {
    listado:{
        titulo:'Catálogo de productos',
        roles: ['SEC_CATALOGODIGITAL_VISUALIZACION']
    },
    detalle:{
        titulo:'Detalle de Productos',
        roles: ['SEC_CATALOGODIGITAL_VISUALIZACION']
    }
}
