<app-loadingSpinner [message]="mesaje" [show]="downloading"></app-loadingSpinner>

<div class="row px-3">
    <div class="col-12">
        <span class="page-route">Notificaciones</span>
    </div>

    <div class="col-12 py-3">
        <app-title></app-title>
    </div>


    <div class="col-lg-12 col-sm-12">
        <form [formGroup]="filterForm" (ngSubmit)="buscar()">
            
            <div class="d-flex mt-2">
                <div class="d-flex flex-row align-items-center">
                    <label class="label-entity mr-1 mt-2">Fecha Desde</label>  
                    <input class="main-content-input-ticket date-input" formControlName="createdDateIni"  type="date"  >
                </div>
                <div class="d-flex flex-row mx-3 align-items-center">
                    <label class="label-entity mr-1 mt-2">Fecha Hasta</label>   
                    <input class="main-content-input-ticket date-input"  formControlName="createdDateFin"  type="date"  >
                </div>
                
                <div class="d-flex align-items-center mx-3">
                    <button type="submit" class="btn btn-primary-portal btn-primary btn-sm mr-2">Filtrar</button>
                    <button class="btn btn-outline-secondary btn-sm mx-2" (click)="limpiar()">Limpiar</button>
                </div>
            </div>

        </form>
        
        

        <bpe-grid [configuration]="config" [find]="filter" (actions)="clickInfo( $event )">
        </bpe-grid>
    </div>


</div>

<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Detalle de notificación</h4>
        <button data-dismiss="modal" type="button" class="btn ml-auto" (click)="closeModal()">X</button>
    </div>
    <div class="modal-body" ngbAutofocus>
        <div [innerHTML]="modalInfo"></div>
    </div>

</ng-template>