import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { FormGroup } from "@angular/forms";
import { formulario } from "./recovery.form";
import { Mensajes } from "src/app/constants/mensajes.const";
import { RecoveryService } from "src/app/services/recovery.service";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-recovery",
  templateUrl: "./recovery.component.html",
  styleUrls: ["./recovery.component.scss"],
})
export class RecoveryComponent {
  formRecovery: FormGroup;
  enviandoRecovery = false;
  faSpinner = faSpinner;

  constructor(
    private recoveryService: RecoveryService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.formRecovery = formulario;
    this.formRecovery.reset();
  }

  home() {
    this.router.navigate(["login"]);
  }

  /** Método para enviar el correo de reseteo de contraseña */
  async sendRecovery() {
    this.enviandoRecovery = true;
    if (this.formRecovery.invalid) {
      this.toastr.error(Mensajes.EMAIL_MAL_INGRESADO, "", {
        positionClass: "toast-top-center",
        progressBar: false,
      });
      this.enviandoRecovery = false;
      return;
    }
    const req = {
      email: this.formRecovery.value.email,
    };
    const res = await this.recoveryService.recovery(req);
    if (res) {
      this.toastr.success(Mensajes.RECOVERY_MESSAGE, "", {
        positionClass: "toast-top-center",
        progressBar: true,
      });
      this.home();
    } else {
      this.toastr.error(Mensajes.RECOVERY_ERROR, "", {
        positionClass: "toast-top-center",
        progressBar: true,
      });
    }
    this.enviandoRecovery = false;
  }
}
