    
<div class="d-flex flex-row justify-content-center pt-3 mt-3">
    <span class="main-title">Llamadas telefónicas</span>
</div>

 
  <!-- Tabla de llamadas -->
<div class="col-lg-12 col-sm-12" style="font-size: small;">

    <div  class="table-responsive">
        <table id="excel-table" class="table table-style table-font-size">
            <tr class="py-2">
                <th scope="col"></th>
                <th scope="col">Asunto</th>
                <th scope="col">Estado</th>
                <th scope="col">Subestado</th>
                <th scope="col">Fecha de inicio</th>
                <th scope="col">Fecha de finalizacion</th>
                <th scope="col">Prioridad</th>
                <th scope="col">Cliente</th>
                <th scope="col">Contacto</th>
                <th scope="col">Nota</th>
            </tr>

            <tbody style="border:none;"*ngIf="llamadasRealizadas.length!=0">
                <tr class="py-2 mt-2" *ngFor="let llamada of llamadasRealizadas">
                    <td></td>
                    <td> {{llamada.TituloTarea}}</td>
                    <td> {{llamada.EstadoTarea}}</td>
                    <td> {{llamada.SubEstadoTarea}}</td>               
                    <td> {{llamada.FechIniTarea}} </td>              
                    <td> {{llamada.FechFinTarea}} </td>
                    <td> {{llamada.PrioridadTarea}} </td>  
                    <td> {{llamada.ClienteTarea}} </td>   
                    <td> {{llamada.ContactoTarea}} </td>
                    <td> {{llamada.NotasTareas ? llamada.NotasTareas.Notas : '-'}} </td>
                </tr>
            </tbody>
            <tbody align="center" *ngIf="loading">
                <td colspan="5">
                    <span><fa-icon [icon]="faSpinner" animation="spin"></fa-icon></span>
                </td>
            </tbody>
            <tbody align="center" *ngIf="llamadasRealizadas.length==0 && !loading">
                <td colspan="5"> 
                    <span class="span-empty">No hay llamadas asociadas</span> 
                </td>
            </tbody>
            
        </table>
        <ngb-pagination *ngIf="llamadasRealizadas.length!=0"  [(page)]="page" [maxSize]="3" [pageSize]="pageSize" [collectionSize]="llamadasRealizadas.length" [boundaryLinks]="true"> </ngb-pagination>

    </div>
</div>
 <!-- Tabla de llamadas -->