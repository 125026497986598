




<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item pointer" *ngFor="let tab of tabs; let i = index">
        <a class="nav-link" [class.active]="i === selectedIndex" (click)="selectTab(i)">{{ tab.title }}
            <span *ngIf="tab.codigo != 'LIST'" aria-hidden="true" (click)="closeTab(i)">&times;</span>
        </a>
    </li>
</ul>
<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade" *ngFor="let tab of tabs; let i = index" [class.show]="i === selectedIndex" [class.active]="i === selectedIndex">
        <ng-container *ngIf="tab.codigo == 'LIST'">
        <!-- SPINNER FULL SCREEN -->
            <app-loadingSpinner [message]="loadingMessage" [show]="loading"></app-loadingSpinner>
            <div class="row px-3">
                <div class="col-12">
                    <span class="page-route">Tickets</span>
                </div>
                <div class="col-12 py-3 d-flex flex-row">
                    <app-title></app-title>
                    <!-- Navegacion de tickets-->
                    <ul #navegacion class="nav nav-tabs ml-3 w-100">
                        <ng-container *ngFor="let nav of navTickets">
                            <li class="nav-item" *hasPermission="[nav.permisos]">
                                <label [attr.id]="nav.codigo"
                                    [ngClass]="groupToTickets && groupToTickets.codigo == nav.codigo ? 'nav-link active' : 'nav-link navegacionTickets pointer'"
                                    (click)="showTickets(nav)">
                                    {{ nav.titulo }}
                                </label>
                            </li>
                        </ng-container>
                    </ul>
                </div>
                <div #cuerpo class="w-100">

                    <div class="row ml-3">

                        <button type="button" class="btn btn-primary-portal btn-primary btn-sm mr-2" (click)="toogleShowFilters()">
                            {{showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}}
                        </button>
                        <button *ngIf="filterApplied" (click)="clearFilters()" class="btn btn-primary-portal  btn-primary btn-sm"
                            data-toggle="tooltip" data-placement="top" title="Restaurar filtros iniciales">
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                    </div>

                    <div #filtrosForm class="row ml-4 mb-3">
                        <div *ngIf="showFilters"
                            class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-col-xl-5 g-4 mt-3 mr-4">
                            <div class="col px-1 py-2">
                                <div class="d-flex align-items-center">
                                    <input class="main-content-input-ticket filtro-ticket" style="text-indent:15px"
                                        placeholder="Nro. de Ticket" [(ngModel)]="filtros.ID" name="ID" (keyup.enter)="filtrar()">
                                </div>
                            </div>
                            <div class="col px-1 py-2" *ngIf="showFiltros.Z_NUMERODEBOLETA_KUT">
                                <div class="d-flex align-items-center">
                                    <input class="main-content-input-ticket filtro-ticket" style="text-indent:15px"
                                        placeholder="Nro. de Boleta" [(ngModel)]="filtros.Z_NUMERODEBOLETA_KUT"
                                        name="Z_NUMERODEBOLETA_KUT" (keyup.enter)="filtrar()">
                                </div>
                            </div>
                            <div class="col px-1 py-2" *ngIf="showFiltros.Name">
                                <div class="d-flex align-items-center">
                                    <input class="main-content-input-ticket filtro-ticket" style="text-indent:15px"
                                        placeholder="Asunto" [(ngModel)]="filtros.Name" name="Name" (keyup.enter)="filtrar()">
                                </div>
                            </div>
                            <div class="col px-1 py-2" *ngIf="showFiltros.ProcessingTypeCode">
                                <div class="d-flex align-items-center">
                                    <ng-select class="select-m filtro-ticket" placeholder="Tipo ticket" [multiple]="true"
                                        [(ngModel)]="filtros.ProcessingTypeCode" name="ProcessingTypeCode" [clearable]="false"
                                        [searchable]="true" (ngModelChange)="filterCategories();filterStatuses();">
                                        <ng-option value="{{tipo.id}}"
                                            *ngFor="let tipo of ticketsTypesInTabs | sort: 'asc' : 'descripcion'">
                                            <span>{{tipo.descripcion}}</span>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col px-1 py-2" *ngIf="showFiltros.ServiceIssueCategoryID">
                                <div class="d-flex align-items-center">
                                    <ng-select class="select-m filtro-ticket" placeholder="Categoria 1" [multiple]="true"
                                        [(ngModel)]="filtros.ServiceIssueCategoryID" name="ServiceIssueCategoryID"
                                        [clearable]="false" [searchable]="true" [disabled]="filtros.ProcessingTypeCode.length==0"
                                        (ngModelChange)="filterIncidents();">
                                        <ng-option value="{{categoria.id}}"
                                            *ngFor="let categoria of ticketsTypesCategories | CategoryFilter:filtros.ProcessingTypeCode | sort: 'asc' : 'descripcion'">
                                            <span>{{categoria.descripcion}}</span>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col px-1 py-2" *ngIf="showFiltros.IncidentServiceIssueCategoryID">
                                <div class="d-flex align-items-center">
                                    <ng-select class="select-m filtro-ticket" placeholder="Categoria 2" [multiple]="true"
                                        [(ngModel)]="filtros.IncidentServiceIssueCategoryID" name="IncidentServiceIssueCategoryID"
                                        [clearable]="false" [searchable]="true"
                                        [disabled]="filtros.ServiceIssueCategoryID.length==0">
                                        <ng-option value="{{incidente.id}}"
                                            *ngFor="let incidente of ticketsTypesCategoriesIncidents | IncidentFilter:filtros.ServiceIssueCategoryID | sort: 'asc' : 'descripcion'">
                                            <span>{{incidente.descripcion}}</span>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col px-1 py-2" *ngIf="showFiltros.ServicePriorityCode">
                                <div class="d-flex flex-column align-items-center ">
                                    <ng-select class="select-m filtro-ticket" placeholder="Prioridad" [multiple]="true"
                                        [(ngModel)]="filtros.ServicePriorityCode" name="ServicePriorityCode" [clearable]="false"
                                        [searchable]="true">
                                        <ng-option value="{{prioridad.id}}"
                                            *ngFor="let prioridad of ticketsPriorities | sort: 'asc' : 'descripcion'">
                                            <span>{{prioridad.descripcion}}</span>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col px-1 py-2" *ngIf="showFiltros.ServiceRequestUserLifeCycleStatusCode">
                                <div class="d-flex align-items-center">
                                    <ng-select class="select-m filtro-ticket" placeholder="Estado" [multiple]="true"
                                        [(ngModel)]="filtros.ServiceRequestUserLifeCycleStatusCode"
                                        name="ServiceRequestUserLifeCycleStatusCode" [clearable]="false" [searchable]="true"
                                        [disabled]="ticketsStatuses.length==0">
                                        <ng-option value="{{estado.id}}"
                                            *ngFor="let estado of ticketsStatuses | IncludeClossed: filtros.includeClosed | sort: 'asc' : 'descripcion'">
                                            <span>{{estado.descripcion}}</span>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col px-1 py-2" *ngIf="showFiltros.Z_CREADOPOR_KUT">
                                <div class="d-flex align-items-center">
                                    <ng-select class="select-m filtro-ticket" placeholder="Solicitado por" [multiple]="true"
                                        [(ngModel)]="filtros.Z_CREADOPOR_KUT" name="Z_CREADOPOR_KUT" [clearable]="false"
                                        [searchable]="true">
                                        <ng-option value="{{usuario.email}}" *ngFor="let usuario of users | sort: 'asc' : 'email'">
                                            <span>{{usuario.email}}</span>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col px-1 py-2">
                                <div class="d-flex align-items-center main-content-input-ticket filtro-ticket">
                                    <label class="switch ml-auto my-auto">
                                        <input type="checkbox" [(ngModel)]="filtros.includeClosed" name="includeClosed">
                                        <span class="slider round"></span>
                                    </label>
                                    <label class="mr-auto my-auto" style="text-indent: 7px; color: var(--blue);">Incluir
                                        cerrados</label>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div class="col-lg-12">
                        <div class="row align-items-end">
                            <div class="col-md-3">
                                <label for="fechaDesde">Creado desde</label>
                                <input id="fechaDesde" class="main-content-input-ticket date-input filtro-ticket"
                                    [(ngModel)]="filterCreateSince" name="filterCreateSince" id="fechaDesde" type="date">
                            </div>

                            <div class="col-md-3">
                                <label for="fechaHasta">Creado hasta</label>
                                <input class="main-content-input-ticket date-input filtro-ticket" [(ngModel)]="filterCreateUntil"
                                    type="date" name="filterCreateUntil" id="fechaHasta">
                            </div>

                            <div class="col-lg-3 col-md-12 col-sm-12">
                                <button type="button" class="btn btn-primary-portal btn-primary btn-sm mr-2" (click)="filtrar()">
                                    <fa-icon [icon]="faSearch" class="mr-1"></fa-icon>Filtrar
                                </button>
                                <button class="btn btn-outline-secondary btn-sm btn-sm" (click)="clearFilters()">Limpiar</button>
                            </div>

                            <div class="col-lg-3 col-md-12 col-sm-12">
                                <div class="d-flex justify-content-end">
                                    <button type="button" class="btn btn-primary-portal btn-primary btn-sm mr-2"
                                        (click)="exportExcel()">
                                        <fa-icon [icon]="faDownload" class="mr-1"></fa-icon>Exportar
                                    </button>
                                    <button type="button" class="btn btn-primary-portal  btn-primary btn-sm" (click)="newTicket()">
                                        <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Nuevo ticket
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div class="col-lg-12 py-4">
                        <p-table #tabla2 [value]="tickets" [loading]="loading" [resizableColumns]="true" columnResizeMode="expand">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col.header}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-ticket>
                                <tr>
                                    <td>
                                        <div class="d-inline-block">
                                            <fa-icon [icon]="faExternalLinkAlt" (click)="goToDetails(ticket.numero)" class="puntero"
                                                [pTooltip]="'Ver Detalle del ticket'" tooltipPosition="top"></fa-icon>

                                            <fa-icon [icon]="faCheckCircle" class="ml-2 puntero icon-normal"
                                                [pTooltip]="'Aceptar Anulación'" tooltipPosition="top"
                                                [ngClass]="enabledAnulacion(ticket) ? '' : 'fa-disabled'"
                                                (click)="showModalAceptarAnulacion(anular,'md', true, ticket)"
                                                *ngIf="showBotonesAnulacion(ticket)"></fa-icon>

                                            <fa-icon [icon]="faCancel" class="ml-2 puntero icon-inmediata"
                                                [pTooltip]="'Rechazar Anulación'" tooltipPosition="top"
                                                [ngClass]="enabledAnulacion(ticket) ? '' : 'fa-disabled'"
                                                (click)="showModalAceptarAnulacion(anular,'md', false, ticket)"
                                                *ngIf="showBotonesAnulacion(ticket)"></fa-icon>

                                        </div>
                                    </td>
                                    <td class="table-cell-ticket ml-3">
                                        {{ ticket.numero }}
                                    </td>
                                    <td class="table-cell-ticket">
                                        {{ ticket.boleta }}
                                    </td>
                                    <td title="{{ticket.descripcion}}" class="table-cell-ticket table-cell-ticket-lg">
                                        {{ ticket.descripcion }}
                                    </td>
                                    <td class="table-cell-ticket">
                                        {{ ticket.tipo ? ticket.tipo?.descripcion : '' }}
                                    </td>
                                    <td class="table-cell-ticket">
                                        {{ ticket.tipo && ticket.tipo.categoria ? ticket.tipo?.categoria?.descripcion : '' }}
                                    </td>
                                    <td class="table-cell-ticket">
                                        {{ ticket.tipo && ticket.tipo.categoria && ticket.tipo.categoria.incidencia ?
                                        ticket.tipo?.categoria?.incidencia?.descripcion : '' }}
                                    </td>
                                    <td class="table-cell-ticket">
                                        <span class="value-data"
                                            [ngClass]="ticket.prioridad.id == '1' ? 'label-inmediata' : 
                                                                            ticket.prioridad.id == '2' ? 'label-urgente' : 
                                                                            ticket.prioridad.id == '3' ? 'label-normal' : 'label-baja'">
                                            {{ ticket.prioridad.descripcion }}
                                        </span>
                                    </td>
                                    <td class="table-cell-ticket">
                                        {{ ticket.estado.descripcion }}
                                    </td>
                                    <td class="table-cell-ticket">
                                        <div class="d-flex flex-column">
                                            <div class="d-flex">
                                                <div class="mr-auto">
                                                    <b>Creado:</b>
                                                </div>
                                                <div class="ml-auto">
                                                    {{ ticket.fechaDeCreacion | date:'dd/MM/YYYY HH:mm'}}
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="mr-auto">
                                                    <b>Modif.:</b>
                                                </div>
                                                <div class="ml-auto">
                                                    {{ ticket.fechaUltimoCambio | date:'dd/MM/YYYY HH:mm'}}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="table-cell-ticket">
                                        {{ ticket.creadoPor }}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td class="span-empty" [attr.colspan]="columns.length + 1">
                                        <div class="d-flex justify-content-center">No existen datos.</div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div #paginado class="d-flex justify-content-end py-3" *ngIf="!loading">
                            <label class="label-entity pt-2 pr-3">Cantidad de tickets: <b>{{collectionSize}}</b></label>
                            <ngb-pagination *ngIf="collectionSize > 0" [(page)]="page" [maxSize]="3" [pageSize]="pageSize"
                                [collectionSize]="collectionSize" [boundaryLinks]="true" (pageChange)="updatePagination()">
                            </ngb-pagination>
                        </div>
                    </div>

                </div>
            </div>

            <ng-template #anular let-modal>
                <div class="modal-body modal-background">
                    <div class="row row-cols-lg-1 py-2  justify-content-center">
                        <div class="col-lg-12 text-center text-modal">
                            <h5>¿Desea {{aceptarAnulacion ? 'aceptar' : 'rechazar'}} realmente la anulaci&oacute;n?</h5>
                        </div>
                    </div>

                    <div class="row mt-2 pt-3"
                        *ngIf=" dataAceptarAnulacion.tipo.id == 'Z4' || dataAceptarAnulacion.tipo.id == 'ZAE' ">
                        <div class="col-lg-12 col-md-12 col-sm-12 mt-3 mb-0">
                            <label class="label-entity" style="font-size: 1.2rem!important"><i>Datos de Pago</i></label>
                        </div>
                        <!-- Medio de Pago -->
                        <div class="col">
                            <div class="d-flex flex-column">
                                <label class="label-entity">Medio de pago</label>
                                <ng-select [disabled]="!mostrarBotones || !editarMedioDePago" placeholder="Medio de pago"
                                    [(ngModel)]="dataAceptarAnulacion.pago.id" [clearable]="false" [searchable]="false">
                                    <ng-option value="{{pm.id}}" *ngFor="let pm of paymentMethods">
                                        <span>{{pm.descripcion}}</span>
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <!-- Número  de referencia -->
                        <div class="col"
                            *ngIf="getConstantes().MEDIOS_DE_PAGO_CON_REFERENCIA.includes(dataAceptarAnulacion.pago.id)">
                            <div class="d-flex flex-column">
                                <label class="label-entity">Fecha de transferencia</label>

                                <input [disabled]="!mostrarBotones || !editarMedioDePago" id="fechaTransferencia"
                                    class="ticket-resumen-input date-input filtro-ticket"
                                    [(ngModel)]="dataAceptarAnulacion.pago.fechaTransferencia" name="fechaTransferencia"
                                    type="date">
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2 pt-3"
                        *ngIf=" dataAceptarAnulacion.tipo.id == 'Z4' || dataAceptarAnulacion.tipo.id == 'ZAE' ">
                        <div class="col"
                            *ngIf="getConstantes().MEDIOS_DE_PAGO_CON_REFERENCIA.includes(dataAceptarAnulacion.pago.id)">
                            <div class="d-flex flex-column">
                                <label class="label-entity">Nro. de referencia</label>
                                <input [disabled]="!mostrarBotones || !editarMedioDePago" class="ticket-resumen-input input-agregar"
                                    [(ngModel)]="dataAceptarAnulacion.pago.numerodereferencia" type="text"
                                    placeholder="Nro. de referencia">
                            </div>
                        </div>
                    </div>
                    <div class="row my-3 justify-content-center">
                        <button class=" col-auto btn-anulacion-aprobar mx-2" (click)="dataAceptarAnulacion.tipo.id == 'Z4' ? aceptarAnulacion ?  anulacion(aprobarAnulacion) : anulacion(rechazarAnulacion) :
                            aceptarAnulacion ? anulacion(aprobarAnulacion2) : anulacion(rechazarAnulacion2)">
                            Confirmar
                        </button>
                        <button class="col-auto btn-anulacion-cancelar" (click)="modal.dismiss()">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="tab.codigo != 'LIST'">
            <app-detalle-ticket [ticketNumber]="tab.ID"></app-detalle-ticket>
        </ng-container>

    </div>

</div>