import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AdjuntosService } from 'src/app/services/adjuntos.service';
import FileUtils from "src/app/utils/fileUtils"
import { faDownload, faPlus,faSpinner, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Mensajes } from 'src/app/constants/mensajes.const';
import { Constantes } from 'src/app/constants/constantes.const';
import { DataService } from 'src/app/services/data.service';
import { MongoDBService } from 'src/app/services/mongoDB.service';
import { UsersService } from 'src/app/services/users.service';
import { Documento } from 'src/app/interfaces/interfaces';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-nuevoadjunto',
  templateUrl: './nuevo-adjunto.component.html',
  styleUrls: ['./nuevo-adjunto.component.scss']
})
export class NuevoAdjuntoComponent implements OnInit {

  @Input() idCliente: any;
  @Input() idEvento: any;
  @Input() idOc: any;
  @Input() evento: any;
  @Output() refresh =  new EventEmitter();

  faDownload=faDownload;
  faPlus=faPlus;
  faSpinner=faSpinner;
  faExclamationTriangle=faExclamationTriangle;

  archivo: any;
  selectedFile: Documento|undefined;
  tipoAdjuntos:any[]=[]
  atributos:any[]=[]

  uploading = false;
  loading = false;
  loadingMessage = new BehaviorSubject('');
  
  
  tempId:any;
  tipo:any;
  preselectedValue: any;
  tipoDoc:any;
  atributo:any;
  valorAtributo:any;
  tipoDocValue:any


  TipoDoc: any[] = [
    {
      name: "SET",
      id:1,
      Ticket: false,
      OC: true,
      Client:true,
      atributes:[{
        cod: 'RUC',
        desc:'RUC'
      }]
    },
    {
      name: 'OFAC',
      id:2,
      Ticket: false,
      OC: true,
      Client:true,
      atributes:[{
        cod: 'RUC',
        desc:'RUC'
      }, 
      {
        cod:'FECALTAOFAC',
        desc:'FECHA DE ALTA OFAC'
      }]


    },
    {
      name: "MATCH",
      id:3,
      Ticket: false,
      OC: true,
      Client:true,
      atributes:[{
        cod: 'RUC',
        desc:'RUC'
      }]


    },
    {
      name: 'Cedula de Identidad',
      id:4,
      Ticket: true,
      OC: true,
      Client:true,
      atributes:[{
        cod: 'NRODOCUMENTO',
        desc:'NRO. DOCUMENTO'
      }, 
      {
        cod: 'NOMBRECEDULA',
        desc:'NOMBRE DE CLIENTE'
      },
      {
        cod: 'FECEMICED',
        desc:'FECHA DE EMISIÓN'
      }]
    },
    {
      name: "Contrato",
      id:6,
      Ticket: true,
      OC: true,
      Client:true,
      atributes:[{
        cod:'FECALTCONT',
        desc:'FECHA DE ALTA CONTRATO'
      }]

    },
    {
      name: 'Informconf',
      id:7,
      Ticket: false,
      OC: true,
      Client:true,
      atributes:[{
        cod: 'RUC',
        desc:'RUC'
      }]


    },
    {
      name: "Patente Comercial",
      id:103,
      Ticket: false,
      OC: true,
      Client:true,
      atributes:[{
        cod:'FECDOCPTTE',
        desc:'FECHA DEL DOCUMENTO'
      }]

    },
    {
      name: "Declaración Iva",
      id:114,
      Ticket: true,
      OC: false,
      Client:true,
      atributes:[{
        cod:'FECDOCIVA',
        desc:'FECHA DEL DOCUMENTO'
      }]

    }
  ]
  id: any;
  CodCli: any;
  CodSuc: any;
  user: any;

  constructor(
    private toastr: ToastrService,
    private mongoSrv : MongoDBService, 
    private usersSrv: UsersService,
    private modalService: NgbModal,
    private adjuntosService:AdjuntosService,
    private router: Router,
    private dataSrv: DataService
  ) {
    this.tempId = this.router.url.includes('oportunidadcomercial') ? '1152789': '997172' 
    this.tipo = this.router.url.includes('oportunidadcomercial') ? 'OC' : 'TK' 
   }

  ngOnInit(): void {
    this.getTiposAdjuntos()
    this.atributo='';
    this.getClientData();
    if (this.evento && !this.preselectedValue) {
      this.preselectedValue=this.evento.target.files[0];
      this.selectFile(this.evento);
    } else {
      document.getElementById('fileInput')!.click();
    }
  }

  /** Método para obtener datos del cliente */
  async getClientData() {
    const client = await this.dataSrv.getClientByID(this.idCliente);
    if (client) {
      this.CodCli = client.codCliente
      this.CodSuc = client.codSucursal
    }
  }

  onChange(event:any){
    this.tipoDoc=this.TipoDoc.find(tipo=>tipo.id == event);
    this.tipoDocValue=event
    this.valorAtributo=''
    this.atributo=''
  }


  /** Método para obtener los tipos de adjuntos */
  async getTiposAdjuntos() {
    let forOC = true;
    if (this.tipo=='TK') {
      this.tipoDocValue='99';
      forOC = false;
    }
    this.adjuntosService.getTipoAdjuntos(forOC).then(types => {
      this.tipoAdjuntos= types;
    })
  }

  convertDate(date){
    if(!date) return '';
    const ano: string = date.substring(0, 4);
    const mes: string = date.substring(5, 7);
    const dia: string = date.substring(8, 10);
    const fecha = dia + '/' + mes+ '/' + ano ;
    return fecha
  }



  agregarAtributo(){
 
    if(this.atributo=='NOMBRECEDULA' && !/^[a-z A-Z ]+$/.test(this.valorAtributo)){
      this.toastr.error(Mensajes.NOMBRE_CEDULA,'',{positionClass: 'toast-top-center', progressBar: false})
      return
    }
    if(this.atributo=='NRODOCUMENTO' && !/^[0-9]+$/.test(this.valorAtributo)){
      this.toastr.error(Mensajes.NRO_DOCUMENTO,'',{positionClass: 'toast-top-center', progressBar: false})
      return
    }
    if(this.atributos.findIndex(element=>element.cod==this.atributo)!=-1){
      this.atributos[this.atributos.findIndex(element=>element.cod==this.atributo)]={cod:this.atributo, value: this.valorAtributo }
      return
    }
    this.atributos.push({cod:this.atributo, value: this.valorAtributo })
  }

  //Agregar adjunto
  async agregarDocumento() {
    if (this.selectedFile && this.hasValidInputs()) {
      this.uploading=true;
      let payload1: any = {
        CodCliente: this.CodCli,
        CodSucursal: this.CodSuc,
        IncidenteId: this.idEvento,
        OCId: this.idEvento,
        PK: `C4C${this.idEvento}${Math.floor(1+Math.random()*900000)}`,
        TIPOADJUNTO: this.tipoDocValue,
        file: {
          fileB64: this.selectedFile.datos,
          title: this.selectedFile.tituloSolo,
          extension: this.selectedFile.extension
        },
        templateId: this.tempId, //TICKET
        user: this.usersSrv.getActualUser()!.email,
        volumenId: 5
      }
      payload1 = this.addAttributes(payload1);
      
      let res;
      if(this.selectedFile.contieneInformacionSensible) {
        res = await this.adjuntosService.uploadSecureFile(this.selectedFile, this.idEvento);
      } else {
        res = await this.adjuntosService.uploadDocument(payload1);
      }

      if (res || res.uploaded) {
        this.toastr.success(Mensajes.NUEVO_ADJUNTO,'',{positionClass: 'toast-top-center', progressBar: true})
        this.refresh.emit(true);
        const payload2 = {
          'idOc': this.idOc,
          'checkAdjunto':false,
          'enabled': false
        }
        this.mongoSrv.update('/checkAdjunto',this.idOc, payload2);
        this.modalService.dismissAll();
        this.uploading=false;
      } else {
        this.uploading=false;
        this.toastr.error(Mensajes.NUEVO_ADJUNTO_ERROR,'',{positionClass:'toast-top-center', progressBar:false});
      }
    } else {
      this.uploading=false;
      this.toastr.error(Mensajes.INPUTS_VACIOS,'',{positionClass: 'toast-top-center', progressBar: false})
      return
    }
  } 

  hasValidInputs() {
    return this.selectedFile != undefined && 
    this.selectedFile.tituloSolo != undefined && 
    this.selectedFile.tituloSolo.length>0 && 
    this.tipoDocValue!='';
  }

  getObjectId(id){
    if(id!=undefined && id!=''){
      return this.tipoAdjuntos.find(element=>element.value==id)._id;
  
    }
  }

  addAttributes(payload: any) {
    this.atributos.forEach(attribute => {
      if (['FECALTCONT','FECALTAOFAC','FECDOCIVA','FECDOCPTTE','FECEMICED'].includes(attribute.cod)) {
        payload[attribute.cod] = this.convertDate(attribute.value);
      } else {
        payload[attribute.cod] = attribute.value;
      }
    })
    return payload;
  }

  /** Método para seleccionar un archivo
   * @param evt evento de seleccion
   */
  async selectFile(evt: any) {
    const files = Array.from<File>(evt.target.files);
    let resultado;
    this.loading = true;
    this.loadingMessage.next("Verificando validez del documento, esto puede demorar unos segundos");
    if(this.tipo == 'TK'){ 
      resultado = await this.adjuntosService.validfiles(files, true)
    } else{
      resultado = await this.adjuntosService.validfiles(files)
    }
    this.loading = false;
    if ( resultado.valid ) {
      if(resultado.isSensitve){
        this.toastr.warning('Este archivo contiene datos sensibles y será marcado como tal', '', { positionClass: 'toast-top-center', progressBar: false });
      }
      const file = files[0];
      const fileName = FileUtils.fixName(file.name);
      if (fileName.shorted) {
        this.toastr.info('Nombre actualizado a 40 carácteres','',{positionClass: 'toast-top-center', progressBar: false});
      }
      FileUtils.fileToBase64(file).then(data => {
        const documento: Documento = {
          tituloOriginal:file.name,
          titulo:fileName.full,
          tituloSolo: fileName.short,
          extension:fileName.extention,
          tamaño: file.size,
          fechaCreacion: new Date(moment.now()),
          datos: data,
          file: file,
          contieneInformacionSensible: resultado.isSensitve
        };
        this.selectedFile = documento;
      });
    } 
  }

  onChangeAtributo(){
    this.valorAtributo = "";
  }

  checkLength(event: Event) {
    if (this.selectedFile?.tituloSolo?.length==Constantes.CARACTERES_NOMBRE_ADJUNTO) {
      event.preventDefault();
    }
  }

  getNameSizeLeft() {
    return Constantes.CARACTERES_NOMBRE_ADJUNTO-(this.selectedFile?.tituloSolo ? this.selectedFile?.tituloSolo.length : 0);
  }
} 
