import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Regex } from "src/app/constants/regex.const";

export const formAltaContacto = new FormGroup({
  nombre: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
    Validators.pattern(Regex.ENTIDADES),
  ]),
  apellido: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
    Validators.pattern(Regex.ENTIDADES),
  ]),
  nacimiento: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  telefono: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  telefonoMovil: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  email: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
    Validators.pattern(Regex.EMAIL),
  ]),
  sexo: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  cedula: new FormControl("", [
    Validators.pattern(Regex.SOLONUMERO),
    Validators.required
  ]),
  esPEP: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  cargo: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
});
