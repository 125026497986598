
<div class="row my-2">
  <div class="col-12 d-flex align-items-center mt-2">
    <label class="label-entity font-weight-bold">Historial de estados</label>
    <div class="ml-auto">
      <ngb-pagination *ngIf="collectionSize.getValue() > 0 && !loadingEstados" [(page)]="page" [maxSize]="3" [pageSize]="pageSize" [collectionSize]="collectionSize.getValue()"
        [boundaryLinks]="true"> </ngb-pagination>
    </div>
  </div>
  <div class="col-12 box-descripcion-ticket p-0">
    <div class="table-responsive">
      <table class="table table-style table-font-size table-align">
        <thead>
          <tr class="align-items-center">
            <th scope="col">Estado Anterior</th>
            <th scope="col">Estado Actual</th>
            <th scope="col">Fecha cambio</th>
            <th scope="col">Usuario</th>
          </tr>
        </thead>
        <tbody align="center" *ngIf="estados.length == 0 && !loadingEstados">
          <td colspan="3" class="span-empty">Sin datos</td>
        </tbody>
        <tbody align="center" *ngIf="loadingEstados">
          <td colspan="3">
            <fa-icon [icon]="faSpinner" animation="spin"></fa-icon>
          </td>
        </tbody>

        <tbody *ngIf="estados.length != 0 && !loadingEstados">
          <tr *ngFor="
          let estado of estados | slice: (page-1) * pageSize : page * pageSize

        ">
            <td>{{ estado.estadoAnterior }}</td>
            <td>{{ estado.estadoActual }}</td>
            <td>{{ estado.fecha }}</td>
            <td>{{ estado.usuario }}</td>

          </tr>

        </tbody>

      </table>

    </div>

  </div>
</div>