import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  faDownload,
  faSearch,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription } from "rxjs";
import { AdjuntosService } from "../../../services/adjuntos.service";
import DateUtils from "../../../utils/dateUtils";
import { MongoDBService } from "src/app/services/mongoDB.service";
import { TipoAdjunto } from "src/app/interfaces/interfaces";
import { AuditService } from "src/app/services/audit.service";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-listado-documentos-importantes",
  templateUrl: "./listado-documentos-importantes.component.html",
})
export class ListadoDocumentosImportantesComponent implements OnInit {
  filter = {};
  filterForm: FormGroup;
  downloading = false;
  faSearch = faSearch;
  faSpinner = faSpinner;
  mesaje = new BehaviorSubject("Descargando archivo");

  subs: Subscription[] = [];
  tipoDocumetoList: any[] = [];
  tiposAdjuntos: TipoAdjunto[] = []

  config = {
    url: "/documentosAdjuntos/visualizacion/",
    columnas: [
      { field: "adjuntoDomus.D_Titulo", header: "Nombre" },
      { field: "modulo", header: "Modulo" },
      { field: "tipoDocumento.description", header: "Tipo Adjunto" },
      {
        field: "createdDate",
        header: "Fecha de creación",
        renderer: (data) => {
          return DateUtils.dateConvertToGrid(data.createdDate);
        },
      },
      {
        field: "",
        header: "Acciones",
        actions: true,
        options: [
          {
            event: "download",
            tooltip: "Descargar documento",
            icon: faDownload,
          },
        ],
      },
    ],
  };

  constructor(
    private fb: FormBuilder,
    private adjuntosService: AdjuntosService,
    private dataSrv: DataService
  ) {
    this.filterForm = this.fb.group({
      dTitulo: [""],
      tipoDocumento: [""],
      createdDateIni: [""],
      createdDateFin: [""],
    });
  }

  ngOnInit(): void {
   this.getData();
  }

  async getData() {
    this.dataSrv.setCargando(true);
    await Promise.all([
      this.dataSrv.loadTiposAdjuntos(),
    ]);
    this.dataSrv.setCargando(false);

    this.subs.push(this.dataSrv.getTiposAdjuntos().subscribe(tipos => {
      this.tiposAdjuntos = tipos.filter((tipo) => tipo.documents)
    }));
  }
  
  async clickInfo(e) {
    this.downloading = true;
    const templateiD = e.data.adjuntoDomus.templateId;
    const dpk = e.data.adjuntoDomus.D_PK;
    switch (e.accion) {
      case "download":
        this.downloading = true;
        await this.adjuntosService.getDocument(dpk, templateiD, e.data.adjuntoDomus)
        this.downloading = false;
        break;
      default:
        break; 
    }
  }

  limpiar() {
    this.filterForm.reset();
  }

  buscar() {
    const dataFilter = { ...this.filterForm.value };
    if (dataFilter.createdDateFin) {
      dataFilter.createdDate = `${dataFilter.createdDateIni};${dataFilter.createdDateFin}`;
    } else {
      dataFilter.createdDate = dataFilter.createdDateIni;
    }
    delete dataFilter.createdDateIni;
    delete dataFilter.createdDateFin;

    this.filter = { ...dataFilter };
  }
}
