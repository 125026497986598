<p-overlayPanel #op [appendTo]="'bell'">

    <div class="container notification-container notification-panel">
        <div class="row">
            <div class="col-lg-12">
                <div class="box shadow-sm rounded bg-white mb-3">
                    <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Notificaciones</h6>
                    </div>

                    <div class="box-body p-0" *ngIf="notifications.length <= 0">

                        <div class="d-flex flex-column align-items-center">
                            <div>
                                <fa-icon [icon]="faInbox" size="2x" class="pointer"
                                    style="color: #8b8b8b !important;"></fa-icon>
                            </div>
                            <div>
                                Estás al día, no tienes notificaciones nuevas
                            </div>
                        </div>


                    </div>
                    <div class="box-body p-0" *ngIf="notifications.length > 0">
                        
                        <div class="p-3 d-flex align-items-center border-bottom" *ngFor="let det of notifications"
                            [ngClass]="det.leido ? '' : 'unread-notify'">
                            <div class="font-weight-bold mr-3">
                                <div class="text-truncate">{{det.notificacion.titulo}}</div>
                                <button type="button" class="btn btn-outline-success btn-sm"
                                    (click)="open(content, det)">Ver Mensaje</button>
                                &nbsp;
                                <button type="button" class="btn btn-outline-success btn-sm" (click)="download(det)"
                                    *ngIf="det.notificacion.documento">Descargar</button>
                            </div>
                            <span class="ml-auto mb-auto text-right ">

                                <small class="text-right text-muted pt-1">
                                    {{ getDaysOAgo(det.notificacion.fechaCreacion)}}
                                </small>
                            </span>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <button 
        [routerLink]="['/','notificaciones']" 
        (click)="op.toggle($event)" 
        class="btn btn-outline-primary btn-sm btn-block">
        Ver todas las notificaciones
    </button>

</p-overlayPanel>
<div class="icon-container">
    <fa-icon #bell (click)="op.toggle($event)" [icon]="faBell" style="color: #fff !important;" size="2x"
        class="pointer">
    </fa-icon>
    <span class="badge-notification" *ngIf="countNotifications > 0"> {{ countNotifications }}</span>
</div>

<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Detalle de notificación</h4>
        <button data-dismiss="modal" type="button" class="btn ml-auto" (click)="closeModal()">X</button>
    </div>
    <div class="modal-body" ngbAutofocus>
        <div [innerHTML]="modalInfo"></div>
    </div>

</ng-template>