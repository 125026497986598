import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Regex } from "src/app/constants/regex.const";

export const formContact  = new FormGroup({
    nombre: new FormControl('',[Validators.required,
                                Validators.minLength(1),
                                Validators.maxLength(240),
                                Validators.pattern(Regex.ENTIDADES)]),
    apellido: new FormControl('',[Validators.required,
                                Validators.minLength(1),
                                Validators.maxLength(240),
                                Validators.pattern(Regex.ENTIDADES)]),   
    telefono: new FormControl('',[Validators.required,
                                Validators.minLength(1),
                                Validators.maxLength(240),]),
    telefonoMovil: new FormControl('',[
                                Validators.minLength(1),
                                Validators.maxLength(240),]),
    email: new FormControl('',[Validators.required,
                                Validators.minLength(1),
                                Validators.maxLength(240),
                                Validators.pattern(Regex.EMAIL)]),
    genero: new FormControl("",[Validators.required,
                                Validators.minLength(1),
                                Validators.maxLength(240),]),

        

                    
})