import moment from 'moment'; 
import { deburr } from 'lodash';
import { formatNumber } from '@angular/common';

/**
 * El método permite borrar los datos de un objeto que son vacios o nulos
 * @param data 
 * @returns 
 */
export function deleteEmptyData(data:any){
    for(const key in data) {
        if(data[key] == '' || data[key] == null) {
            delete data[key];
        }
    }
    return data;
}

/**
 * El método permite convertir un texto del tipo X a dd/MM/yyyy
 * @param data 
 * @returns 
 */
export function dateConvertToGrid(data:any){
    if(data) {
        return moment(data).format("DD/MM/YYYY HH:mm:ss");
    }
    return ''
}

/** Método para quitar caracteres especiales de un string
 * @param text string a transformar
 * @returns texto transformado sin caracteres especiales
 */
export function simplifyText(text: string) {
    // Primero, convertir los caracteres latinos en la forma básica de la letra
  const deburred = deburr(text);
  // Luego, eliminar cualquier carácter que no sea una letra o un número
  return deburred.replace(/[^a-zA-Z0-9 ]/g, '');
}

/**
 * El método obtener las diferencia de tiempo en formato hace 10 minutos, hace 2 horas ...
 * @param data 
 * @returns 
 */
export function tiempoTranscurrido(fechaCreacion) {
  const unDia = 24 * 60 * 60 * 1000; // milisegundos en un día
  const hoy = new Date();
  const fecha1 = new Date(fechaCreacion);
  const diffDias = Math.round(Math.abs((hoy.getTime() - fecha1.getTime()) / unDia));
  const diffSemanas = Math.floor(diffDias / 7);
  const diffMeses = Math.floor(diffDias / 30);
  const diffAnios = Math.floor(diffMeses / 12);

  if (diffAnios > 0) {
    return `Hace ${diffAnios} ${diffAnios > 1 ? 'años' : 'año'}`;
  } else if (diffMeses > 0) {
    return `Hace ${diffMeses} ${diffMeses > 1 ? 'meses' : 'mes'}`;
  } else if (diffSemanas > 0) {
    return `Hace ${diffSemanas} ${diffSemanas > 1 ? 'semanas' : 'semana'}`;
  } else if (diffDias > 0) {
    return `Hace ${diffDias} ${diffDias > 1 ? 'días' : 'día'}`;
  } else {
    return 'Hace menos de un día';
  }
}

export function transformToNumber(numberString: string, localeFrom: string, localeTo: string, digitFormat?: string) {
  // comprueba si numberString es tiene caracteres numericos con separadores de miles y decimales
  if (numberString && (numberString.match(/\d+[,.]\d+/g) || numberString.match(/\d+[.]\d+/g) || numberString.match(/\d+/g))) {
    const enLocale = localeFrom === 'en-EN';
    const value = enLocale ? Number(numberString) : Number(numberString.replaceAll(".","").replaceAll(",","."));
    return digitFormat ? formatNumber(value,localeTo,digitFormat) : formatNumber(value,localeTo);
  } else {
    return 0;
  } 
}
