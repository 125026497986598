export const Regex = {
    CONTRASENA: '(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}',
    CUIT: '^(20|23|24|27|30|33|34)[0-9]{8}?[0-9]',
    NOMBRE_APELLIDO: '^([0-9A-Za-zÁÉÍÓÚñáéíóúÑ\' ]+)$',
    FECHA_NACIMIENTO:'^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/[0-9]{4}$',
    ALIAS: '^([A-Za-zÁÉÍÓÚñáéíóúÑ0-9\' ]+)$',
    RAZON_SOCIAL: '^([A-Za-zÁÉÍÓÚñáéíóúÑ0-9\' ]+)$',
    PAIS: '^([A-Za-zÁÉÍÓÚñáéíóúÑ\' ]+)$',
    PROVINCIA: '^([A-Za-zÁÉÍÓÚñáéíóúÑ\' ]+)$',
    CALLE: '^([0-9A-Za-zÁÉÍÓÚñáéíóúÑ\' ]+)$',
    LOCALIDAD: '^([0-9A-Za-zÁÉÍÓÚñáéíóúÑ\' ]+)$',
    BARRIO: '^([0-9A-Za-zÁÉÍÓÚñáéíóúÑ\' ]+)$',
    NRO_DPTO: '[0-9A-Za-z]+',
    DPTO: '^[0-9A-Za-zÁÉÍÓÚñáéíóúÑ]+$',
    PISO: '^[0-9]+$',
    CP: '[[0-9]{2}]+[.]',
    COORDENADA: '((?:[+-]?[0-9]*[.,][0-9]+)|(?:[+-]?[0-9]+))',
    ENTIDADES: '^[a-z A-ZñÑ. ]+',
    TELEFONO: '^[0-9]*$',
    EMAIL: '[a-zA-Z0-9._%+-]+[a-zA-Z0-9_%+-]@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}',
    IMPORTE: '^[0-9]+[.]?[0-9]+$',
    RUC: '[0-9]{0,10}?[-]?[0-9]{1,10}?',
    TERMINAL: '^[0-9]{9,10}$',
    SOLONUMERO: '^[0-9]+$',
    TARJETA: '^(?:2[0-9]{12}(?:[0-9]{3})?|4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35{3}){11})|6[0-9]{12}(?:[0-9]{3})|6[0-9]{12}(?:[0-9]{4})|5[0-9]{12}(?:[0-9]{3})|8[0-9]{12}(?:[0-9]{3})$'
};
