import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faSpinner} from '@fortawesome/free-solid-svg-icons'
import { ToastrService } from 'ngx-toastr';
import { formNewUser } from './crear-usuario.form';
import { FormControl, FormGroup } from '@angular/forms';
import { UsersService } from 'src/app/services/users.service';
import { Mensajes } from 'src/app/constants/mensajes.const';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { MongoDBService } from '../../../services/mongoDB.service';
import { AuditService } from 'src/app/services/audit.service';

@Component({
  selector: 'app-crearusuario',
  templateUrl: './crear-usuario.component.html',
  styleUrls: ['./crear-usuario.component.scss']
})
export class CrearUsuarioComponent implements OnInit {
  entidadesList : Array<any>=[];
  formNewUser: FormGroup;
  faSpinner = faSpinner;

  entityValidator:any;
  title = '';
  creatingUser = false;
  rolesPosibles: any[] = []
  perfilesPosibles: any[] = []

  constructor (
    private activatedRoute: ActivatedRoute,
    private usersSrv: UsersService,
    private toastr: ToastrService,
    private router: Router,
    private titleSrv: Title,
    private mongoSrv: MongoDBService,
    private auditSrv: AuditService
  ) { 
    this.entityValidator = this.usersSrv.getActualUser().entidad?.id;
    this.formNewUser=formNewUser;
    this.formNewUser.reset()
    this.formNewUser.patchValue({'entidad': null})
  }

  ngOnInit(): void {
    this.titleSrv.setTitle(`${this.activatedRoute.snapshot.data.titulo} - ${environment.app_name}`);
    this.getRoles();
    this.getPerfiles();
  } 


  /** Método para crea un nuevo usuario utilizando datos del formulario formNewUser */
  async createUser(){
    this.creatingUser= true;
    if (this.formNewUser.invalid) {
      this.toastr.error(Mensajes.ERROR_DATOS_DE_USUARIO,'',{positionClass:'toast-top-center', progressBar:false});
      this.validateAllFields(this.formNewUser);
      this.creatingUser = false;
    } else {
      const req ={
        username: this.formNewUser.value.email,
        name: this.formNewUser.value.name,
        surname: this.formNewUser.value.surname,
        email: this.formNewUser.value.email,
        roles: [this.getRolSelected(this.formNewUser.value.rol)],
        rol: 'user',
        entity: this.entityValidator,
        created_by: this.usersSrv.getActualUser().id,
        created_date: new Date().toISOString(),
      }
      if(this.formNewUser.value.perfil && this.formNewUser.value.perfil.length >0){
        req['perfiles']= [this.formNewUser.value.perfil];
      }
      const res = await this.usersSrv.createUser(req);
      if (res.created==true) {
        this.auditSrv.logAction({modulo:'Usuarios',accion: 'Crear usuario', detalle: `Email: ${req.username}`})
        this.toastr.success(Mensajes.USUARIO_CREADO,'',{positionClass:'toast-top-center', progressBar:true});
        this.router.navigate(['usuarios']);
      } else if (res.status && res.status == 409) {
        this.toastr.error('Ya existe un usuario asociado al correo ingresado','',{positionClass:'toast-top-center', progressBar:false});
      } else {
        this.toastr.error(Mensajes.ERROR_AL_CREAR_USUARIO,'',{positionClass:'toast-top-center', progressBar:false});
      }
      this.creatingUser= false;
    }
  }

  /**
   * Método que obtiene los roles disponibles, el `size` es 99999 para obtener todos los roles
   * y obtener todos los roles en un sola página
   */
  async getRoles() {
    const res = await this.mongoSrv.getAll(`/roles`);
    if (res) {
      this.auditSrv.logAction({modulo:'Usuarios',accion: 'Obtener roles'})
      this.rolesPosibles = res.datos.filter((rol: {codigo:any, visible_entidades:boolean})=>{
        return rol.visible_entidades
      });
    } else {
      this.toastr.error("No se puso obtener los roles", '', { positionClass: 'toast-top-center', progressBar: false })
    }
  }

  /**
   * Método que obtiene los perfiles disponibles, el `size` es 99999 para obtener todos los roles
   * y obtener todos los perfiles en un sola página
   */
  async getPerfiles() {
    const res = await this.mongoSrv.getAll(`/perfil`);
    if (res) {
      this.auditSrv.logAction({modulo:'Usuarios',accion: 'Obtener perfiles'})
      this.perfilesPosibles = res.datos;
    } else {
      this.toastr.error("No se puso obtener los perfiles", '', { positionClass: 'toast-top-center', progressBar: false })
    }
  }

  /**
   * Método que retorna un objeto para asignar roles al usuario
   * @param rolAsignado - rol asignado
   * @returns un objecto que tiene arrays de roles a ser asigandos `assing` y desasignados `revoke`
   */
  getRolSelected(rolAsignado: any) {
    for (const rol of this.rolesPosibles) {
      if (rolAsignado.includes(rol._id)) {
        return [rol._id];
      } 
    }
    return [];
  }

  usuarios(){
    this.router.navigate(['usuarios','userlist'])
  }

  validateAllFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
        const control = formGroup.get(field);            
        if (control instanceof FormControl) {             
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {        
            this.validateAllFields(control);  
        }
    });
  }

}
