import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import {
  faSpinner,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { formulario } from "src/app/login/expiredpassword/expiredpassword.form";
import { ToastrService } from "ngx-toastr";
import { Mensajes } from "src/app/constants/mensajes.const";
import { StorageService } from "src/app/services/storage.service";
import { LoginService } from "src/app/services/login.service";
import { RecoveryService } from "src/app/services/recovery.service";

@Component({
  selector: "app-expiredpassword",
  templateUrl: "./expiredpassword.component.html",
  styleUrls: ["./expiredpassword.component.scss"],
})
export class ExpiredpasswordComponent implements OnInit {
  formPassword: FormGroup;
  faSpinner = faSpinner;
  faEyePasswordActual = faEye;
  faEyePasswordNueva = faEye;
  faEyePasswordNuevaRep = faEye;

  faEyeSlashPasswordActual = faEyeSlash;
  faEyeSlashPasswordNueva = faEyeSlash;
  faEyeSlashPasswordNuevaRep = faEyeSlash;

  showPassActual = false;
  showPassNuevaRep = false;
  showPassNueva = false;

  passActual;
  user;

  constructor(
    private loginSrv: LoginService,
    private recoverySrv: RecoveryService,
    private storageSrv: StorageService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.formPassword = formulario;
    this.formPassword.reset();
    this.toastr.success(Mensajes.PASSWORD_EXPIRED, "", {
      positionClass: "toast-top-center",
      progressBar: true,
    });
  }

  ngOnInit(): void {
    this.passActual = this.storageSrv.get("pwac", true, true)!;
    this.storageSrv.remove("pwac", true);
  }

  home() {
    this.router.navigate(["login"]);
  }

  /** Método para cambiar la contraseña */
  async changePassword() {
    if (this.formPassword.invalid) {
      this.toastr.error(Mensajes.RESTRICCIONES_CONTRASEÑA, "", {
        positionClass: "toast-top-center",
        progressBar: false,
      });
      return;
    } else if (
      this.formPassword.value.newPass != this.formPassword.value.confirmPass
    ) {
      this.toastr.error(Mensajes.CONTRASEÑAS_DIFERENTES, "", {
        positionClass: "toast-top-center",
        progressBar: false,
      });
      return;
    } else if (this.formPassword.value.password != this.passActual) {
      this.toastr.error(Mensajes.CONTRASEÑA_INCORRECTA, "", {
        positionClass: "toast-top-center",
        progressBar: false,
      });
      return;
    } else if (this.formPassword.value.email.invalid) {
      this.toastr.error(Mensajes.EMAIL_MAL_INGRESADO, "", {
        positionClass: "toas-top-center",
        progressBar: false,
      });
      return;
    } else {
      const payload = {
        newPassword: this.formPassword.value.newPass,
        currentPassword: this.formPassword.value.password,
        email: this.formPassword.value.email,
      };
      const res = await this.recoverySrv.setFirstPassword(payload);
      if (res) {
        this.toastr.success(Mensajes.CONTRASEÑA_GENERADA_CORRECTAMENTE, "", {
          positionClass: "toast-top-center",
          progressBar: true,
        });
        this.loginSrv.logout();
      } else {
        this.toastr.error(Mensajes.ERROR_AL_CAMBIAR_CONTRASEÑA, "", {
          positionClass: "toast-top-center",
          progressBar: false,
        });
      }
    }
  }

  showActualPassword() {
    this.showPassActual = !this.showPassActual;
  }
  showNewPassword() {
    this.showPassNueva = !this.showPassNueva;
  }
  showConfirmPassword() {
    this.showPassNuevaRep = !this.showPassNuevaRep;
  }
}
