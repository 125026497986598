<div *ngIf="field.touched">
    <div class="custom-control-inline invalid-feedback" *ngIf="field.errors?.required">
        Requerido
    </div>
    <div class="custom-control-inline invalid-feedback" *ngIf="field.errors?.minlength">
        Mínimo necesario: {{ field.errors.minlength.requiredLength }} (actual {{ field.errors.minlength.actualLength }})
    </div>
    <div class="custom-control-inline invalid-feedback" *ngIf="field.errors?.maxlength">
        Máximo permitido: {{ field.errors.maxlength.requiredLength }} (actual {{ field.errors.maxlength.actualLength }})
    </div>
    <div class="custom-control-inline invalid-feedback" *ngIf="field.errors?.pattern">
        <span *ngIf="type!='password'">Formato inválido</span>
        <span *ngIf="type=='password'">Minimo 8 caracteres, una minuscula y <br> una mayuscula.</span>
    </div>
    <div class="custom-control-inline invalid-feedback" *ngIf="field.errors?.max">
        Valor máximo permitido: {{ field.errors.max.max }}
    </div>
    <div class="custom-control-inline invalid-feedback" *ngIf="field.errors?.min">
        Valor mínimo permitido: {{ field.errors.min.min }}
    </div>
    <div class="custom-control-inline invalid-feedback" *ngIf="field.errors?.message">
        {{ field.errors.message }}
    </div>
    <div class="custom-control-inline invalid-feedback" *ngIf="field.errors?.noMenorAHoy">
        Complete con una fecha menor o igual a hoy
    </div>
</div>