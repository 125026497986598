<div class="container mt-4">
    <h1>Encuesta de Satisfacción</h1>

    <div class="form-group" *ngFor="let r of evaluacion?.preguntas">
        <label for="recomendacion">{{ r.pregunta }}</label>
        <br />
        <p-rating [(ngModel)]="r.respuesta" [disabled]="evaluacion.estado == 'COMPLETADA'">
            <ng-template pTemplate="onicon">
                <fa-icon [icon]="faStar" style="color: #609bf9 !important;"></fa-icon>
            </ng-template>
            <ng-template pTemplate="officon">
                <fa-icon [icon]="faStar"></fa-icon>
            </ng-template>
        </p-rating>
        <div class="form-group" *ngIf="r.respuesta>0 && r.respuesta<3">
            <label for="opinion" *ngIf="!(evaluacion.estado == 'COMPLETADA')">Comentario:</label>
            <textarea class="form-control" [(ngModel)]="r.comentario" *ngIf="!(evaluacion.estado == 'COMPLETADA')"
                rows="3" placeholder="Ingrese el comentario del por qué de su respuesta.">
            </textarea>
            <p class="text-danger" *ngIf="evaluacion.estado == 'COMPLETADA'"> {{ r.comentario }}</p>
        </div>
    </div>

    <div class="d-flex justify-content-center py-3" *ngIf="evaluacion && !(evaluacion.estado == 'COMPLETADA')">
        <button class="btn-outline-portal" 
            *ngIf="!(evaluacion.estado == 'ENCUESTA NO RESPONDIDA')"
            (click)="cancelar();">Cancelar</button>

        <button (click)="finalizar()" class="btn btn-primary-portal btn-primary btn-sm mx-2" [disabled]="!validarFormulario()">
            <span class="text-btn-main">{{ creating ? 'Procesando' : 'Finalizar'}}</span>
            <fa-icon *ngIf="creating" [icon]="faSpinner" animation="spin"></fa-icon>
        </button>
    </div>
</div>