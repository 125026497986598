  <div class="download-manager card" 
    [ngClass]="{'active': !isHidden, 'slide-out': isHidden, 'collapse': isCollapse}"
    *ngIf="!isHidden"
    style="width: 24rem;">
    <div class="card-header" (click)="collapse()" style="cursor: pointer;">
      <strong> {{ title }}</strong> 
      <fa-icon *ngIf="isCollapse" [icon]="faAngleUp" class="float-right"></fa-icon>
      <fa-icon *ngIf="!isCollapse" [icon]="faAngleDown" class="float-right"></fa-icon>
    </div>
    <div class="card-body" *ngIf="!isCollapse">
        {{ message }}  <fa-icon [icon]="faSpinner" animation="spin"></fa-icon>
        <p>
          <small class="form-text text-muted">Esto puede llevar algunos minutos, pero puedes continuar utilizando la aplicación</small>
        </p>
    </div>
  </div>