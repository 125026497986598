import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { AdjuntosService } from '../../../services/adjuntos.service';
import { MongoDBService } from '../../../services/mongoDB.service';
import DateUtils from '../../../utils/dateUtils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-listado-notificaciones',
  templateUrl: './listado-notificaciones.component.html'
})
export class ListadoNotificacionesComponent {

  @ViewChild('content') content:any;

  filter = {}
  filterForm: FormGroup;
  downloading = false;
  mesaje= new BehaviorSubject('Descargando Archivo');
  modalInfo = '';

  tipoDocumetoList: any[]=[];

  config = {
    url: '/notification/v2/',
    columnas: [
      { field: 'notificacion.titulo', header: 'Notificación' },
      {
        field: 'fechaCreacion', header: 'Fecha de creación',
        renderer: (data) => {
          return DateUtils.dateConvertToGrid(data.fechaCreacion)
        }
      },
      {field:'', header:'Acciones', actions: true, 
        options:[
          {event:'open', tooltip:'Leer mensaje', icon: faEye},
          {event:'download', tooltip:'Descargar documento', icon: faDownload, show: 
            (data) =>{
              return data.notificacion.documento ? true : false;
            }
          }
        ] 
      }
    ]
  }

  constructor(
    private fb: FormBuilder,
    private service: MongoDBService,
    private adjuntosService: AdjuntosService,
    private modalService: NgbModal) {
      this.filterForm = this.fb.group({
        createdDateIni: [''],
        createdDateFin: ['']
      })
     }

  clickInfo(e) {
    
    switch(e.accion) {
      case 'open':
        this.open(this.content, e.data)
        break; 
      case 'download':
        this.downloadFile(e.data);
        break;      
      default:
        break; 
    }

  }

  limpiar() {
    this.filterForm.reset()
  }

  buscar() {
    const dataFilter = { ...this.filterForm.value };
    if (dataFilter.createdDateFin) {
      dataFilter.fechaCreacion = `${dataFilter.createdDateIni};${dataFilter.createdDateFin}`
    } else {
      dataFilter.fechaCreacion = dataFilter.createdDateIni
    }
    delete dataFilter.createdDateIni;
    delete dataFilter.createdDateFin;

    this.filter = { ...dataFilter }
  }

  async downloadFile(data) {
    this.downloading = true;
    const dataDocumeto = await this.service.getAll(`/documentosAdjuntos/${data.notificacion.documento}`)
    await this.adjuntosService.getDocument(dataDocumeto.adjuntoDomus.D_PK,dataDocumeto.adjuntoDomus.templateId, dataDocumeto, 'Listado de notificaciones')
    await this.service.update(`/notification/detalle`, data._id,{ descargado:true })
    this.downloading = false;
  }
  
  open(content, data) {
    this.service.update(`/notification/detalle`, data._id,{ leido:true })
    this.modalInfo = data.notificacion.mensaje;

    this.modalService.open(content, {
      size: "lg",
      centered: true
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
