import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Regex } from "src/app/constants/regex.const";

export const formEditUser  = new FormGroup({
    username: new FormControl('',[Validators.required,
                                Validators.minLength(1),
                                Validators.maxLength(240),
                                Validators.pattern(Regex.EMAIL)]),
    email: new FormControl('',[Validators.required,
                                Validators.minLength(1),
                                Validators.maxLength(240),
                                Validators.pattern(Regex.EMAIL)]),
    rol: new FormControl('',[Validators.required,
                                Validators.minLength(1),
                                Validators.maxLength(240),]),
    name: new FormControl('',[Validators.required, 
                                Validators.minLength(1), 
                                Validators.maxLength(240), 
                                Validators.pattern(Regex.ENTIDADES)]), 
    surname: new FormControl('',[Validators.required, 
                                Validators.minLength(1), 
                                Validators.maxLength(240), 
                                Validators.pattern(Regex.ENTIDADES)]),

})
