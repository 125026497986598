<div class="row px-3">
    <div class="col-12 py-3">
        <app-title></app-title>
    </div>
    <div class="container-fluid">
        
        <div class="card ">
            <div class="card-body">
                <bpe-grid *ngIf="!loading" class="w-100" [configuration]="tableConfig" [find]="{}">
                </bpe-grid>
            </div>
        </div>
    </div>
    

</div>

