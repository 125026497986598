<div class="col-12 pt-4">
    <div class="d-flex align-items-center">
        <label class="label-entity"><b>Productos Asociados</b> {{forOnboarding && productListAsociated.length>0 ? '(para Onboarding)' : ''}}</label>
        <div class="d-flex align-items-center pointer ml-auto" *hasPermission="['SEC_PARAMETRICOS_GET']">
            
            <button (click)="open(agregarPOS)" *hasPermission="['SEC_ALTAAGIL_OPERACION','SEC_ONBOARDING_OPERACION']"
                class="btn btn-primary-portal  btn-primary btn-sm mr-3" type="button">
                <span class="text-btn-main" style="font-size: small;"><fa-icon [icon]="faPlus"></fa-icon> Asociar POS</span>
            </button>

            <button (click)="open(agregarProductos)" 
                class="btn btn-primary-portal  btn-primary btn-sm" type="button">
                <span class="text-btn-main" style="font-size: small;"><fa-icon [icon]="faPlus"></fa-icon> Asociar productos</span>
            </button>
        </div>
    </div>
</div>

<!-- Tabla de productos -->
<div class="col-12 d-flex flex-row flex-wrap py-3">
    <table class="table-style table">
        <tr class="table-line py-2">
            <th scope="col"></th>
            <th scope="col">Producto</th>
            <th scope="col">Precio estándar</th>
            <th scope="col">Precio Elegido</th>
            <th scope="col">Cantidad</th>
        </tr>
        <tr class="py-2 mt-2" *ngFor="let producto of productListAsociated | slice: (page-1) * pageSize : page * pageSize" style="border:none;">
            <td>
                <fa-icon class="pointer" [icon]="faTimes" (click)="removeProduct(producto)"></fa-icon>
            </td>
            <td>{{producto.descripcion}}</td>
            <td>{{producto.costos![0].precio}}</td>
            <td class="col-5">
                <ng-select id="costSelect" aria-disabled="true" placeholder="" class="w-100"
                    [items]="producto.costos!" [clearable]="false" [searchable]="false" 
                    bindLabel= "descripcion"
                    [(ngModel)]="producto.costo"
                    (change)="sendData()">
                    <ng-template ng-label-tmp let-item="item">
                        {{item.aCobrar ? item.aCobrar : item.precio}} - {{item.descripcion}}
                    </ng-template>
                </ng-select>
            </td>
            <td>
                <input class="text-center input-cantidad needs-validation" 
                fromControlName="cantidad" type="number" placeholder="Cantidad" 
                [(ngModel)]="producto.cantidad" (keypress)="sendData()">
            </td>
        </tr>
        <tr *ngIf="productListAsociated.length==0" align="center">
            <td colspan="8"> 
                <span class="span-empty">No hay productos asociados</span> 
            </td>
        </tr>
    </table>
    <ngb-pagination *ngIf="productListAsociated.length>0"  
        [(page)]="page" 
        [maxSize]="3" 
        [pageSize]="pageSize" 
        [collectionSize]="productListAsociated.length" 
        [boundaryLinks]="true">
    </ngb-pagination>
</div>

<ng-container *ngIf="equposPOSListAsociated.length!=0">
    <div class="col-12 pt-4">
        <div class="d-flex align-items-center">
            <label class="label-entity"><b>POS</b></label>
        </div>
    </div>

    <!-- Tabla de POS -->
    <div class="col-12 d-flex flex-row flex-wrap py-3">
        <table class="table-style table">
            <tr class="table-line py-2">
                <th scope="col"></th>
                <th scope="col">Serie</th>
                <!-- <th scope="col">Modelo</th> -->
                <th scope="col">Perfil de comercio</th>
                <th scope="col">Perfil de descarga</th>
            </tr>
            <tr class="py-2 mt-2" *ngFor="let producto of equposPOSListAsociated | slice: (page-1) * pageSize : page * pageSize" style="border:none;">
                <td>
                    <fa-icon class="pointer" [icon]="faTimes" (click)="removePOS(producto)"></fa-icon>
                </td>
                <td>{{producto.serie}}</td>
                <!-- <td>{{producto.nombreEquipo}}</td> -->
                <td class="col-5">
                    <ng-select id="PerfilesComerciosSelect" aria-disabled="true" placeholder="Seleccione perfil comercio"
                        [items]="producto.perfilescomerciosSeleccionables!" [clearable]="false" [searchable]="false" 
                        bindLabel= "descripcion"
                        [(ngModel)]="producto.perfilComercio"
                        (change)="sendData()">
                        <ng-template ng-label-tmp let-item="item">
                            <span >{{item.OID }} - {{item.descripcion}}</span>
                        </ng-template>
                    </ng-select>
                </td>

                <td class="col-5">
                    <ng-select id="PerfilesDescargasSelect" aria-disabled="true" placeholder="seleccione perfil descarga"
                        [items]="producto.perfilesdescargasSeleccionables!" [clearable]="false" [searchable]="false" 
                        bindLabel= "descripcion"
                        [(ngModel)]="producto.perfilDescarga"
                        (change)="sendData()">
                        <ng-template ng-label-tmp let-item="item">
                            {{item.OID }} - {{item.descripcion}}
                        </ng-template>
                    </ng-select>
                </td>
                
            </tr>

            <tr *ngIf="equposPOSListAsociated.length==0" align="center">
                <td colspan="2"> 
                    <span class="span-empty">No hay POS asociados</span> 
                </td>
            </tr>
        </table>
        <ngb-pagination *ngIf="productListAsociated.length>0"  
            [(page)]="page" 
            [maxSize]="3" 
            [pageSize]="pageSize" 
            [collectionSize]="productListAsociated.length" 
            [boundaryLinks]="true">
        </ngb-pagination>
    </div>

</ng-container>
<ng-template #agregarProductos let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal">Listado de productos</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background pb-0">
        <input class="table-contact-modal filtro-ticket main-content-input-ticket" style="text-indent:10px" [(ngModel)]="searchText" type="text"  placeholder="Búsqueda rápida">
    </div>
    <div class="modal-body modal-background">
        <div class="table-responsive table-contact-modal">
            <table class="table-style table">
                <tr>
                    <th scope="col"></th>
                    <th scope="col">ID</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Onboarding</th>
                </tr>
                <tr class="py-2 mt-2" *ngFor="let producto of productListFiltered | 
                    ProductSearch: searchText : modalCollectionSizeFiltered |
                    slice:modalStart:modalEnd">
                    <td>
                        <input type="checkbox" [checked]="isSelected(producto) || isAsociated(producto)" [disabled]="isAsociated(producto)" (change)="selectProduct(producto, $event)">
                    </td>
                    <td>{{producto.id}}</td>
                    <td>{{producto.descripcion}}</td>
                    <td>{{producto.onboarding ? 'Sí' : 'No'}}</td>
                </tr>
                <tr align="center" *ngIf="loadingProducts">
                    <td colspan="9"> 
                        <fa-icon [icon]="faSpinner" animation="spin"></fa-icon>
                    </td>
                </tr>
            </table>
        </div>
        <div class="py-2 d-flex flex-row">
            <div class="d-flex flex-column">
                <ngb-pagination
                    [(page)]="modalPage" [maxSize]="3" 
                    [pageSize]="modalPageSize" [collectionSize]="modalCollectionSize" 
                    [boundaryLinks]="true" (pageChange)="updatePagination()">
                </ngb-pagination>
            </div>
            <!-- Botones -->
            <div class="d-flex flex-column ml-auto">
                <div class="d-flex align-items-center pointer ml-auto">
                    <div class="d-flex align-items-center pointer mr-3">    
                        <button class="btn btn-primary-portal  btn-primary btn-sm" type="button" (click)="asociateProducts();">Agregar</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #agregarPOS let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal">Listado de POS</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background pb-0">
        <input class="table-contact-modal filtro-ticket main-content-input-ticket" style="text-indent:10px" 
            [(ngModel)]="searchSerie" type="text"  placeholder="Búsqueda por número de serie"
            (keyup.enter)="getEquiposPOS()">
        
    </div>
    <div class="modal-body modal-background">
        <div class="table-responsive table-contact-modal">
            <table class="table-style table">
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Deposito</th>
                    <th scope="col">Serie</th>
                    <th scope="col">Modelo</th>
                    <th scope="col">Producto</th>
                </tr>
                <ng-container *ngIf="!loadingPOS ">
                    <tr class="py-2 mt-2" *ngFor="let equipo of equposPOSList | slice: (pageModalPOS-1) * pageSizeModalPOS : pageModalPOS * pageSizeModalPOS">
                        <td>
                            <input type="checkbox" 
                                [checked]="isPOSSelected(equipo)"
                                [disabled]="isPOSAsociated(equipo)"
                                (change)="selectEquiposPOS(equipo, $event)">
                        </td>
                        <td>{{equipo.deposito}}</td>
                        <td>{{equipo.serie}}</td>
                        <td>{{equipo.modelo}}</td>
                        <td>{{equipo.codigoProducto}}</td>
                    </tr>
                </ng-container>
                <tr align="center" *ngIf="loadingPOS">
                    <td colspan="5"> 
                        <fa-icon [icon]="faSpinner" animation="spin"></fa-icon>
                    </td>
                </tr>
                <tr align="center" *ngIf="!loadingPOS && equposPOSList.length == 0">
                    <td colspan="5"> 
                        <small class="text-muted">No hay POS disponibles</small>
                    </td>
                </tr>
            </table>
        </div>
        <div class="py-2 d-flex flex-row">
            <div class="d-flex flex-column">
                <ngb-pagination
                    [(page)]="pageModalPOS" [maxSize]="3" 
                    [pageSize]="pageSizeModalPOS" [collectionSize]="collectionSizeModalPOS" 
                    [boundaryLinks]="true">
                </ngb-pagination>
            </div>
            <!-- Botones -->
            <div class="d-flex flex-column ml-auto">
                <div class="d-flex align-items-center pointer ml-auto">
                    <div class="d-flex align-items-center pointer mr-3">    
                        <button class="btn btn-secondary-portal  btn-secondary btn-sm mr-3" 
                            *ngIf="filterBySerie"
                            type="button" 
                            (click)="clearSearchSerie();">Limpiar busqueda</button>
                        <button class="btn btn-primary-portal  btn-primary btn-sm" type="button" (click)="asociatePOS();">Agregar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
