import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges,ViewContainerRef} from '@angular/core';
import { faSearch, faPlus, faSpinner, faEdit, faTrashCan, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons'
import { ToastrService } from 'ngx-toastr';
import { MongoDBService } from '../../services/mongoDB.service';
import { Param } from '../../interfaces/interfaces';
import { deleteEmptyData } from '../../utils/app-utils';

@Component({
  selector: 'bpe-grid',
  templateUrl: './bpe-grid.component.html'
})
export class BpeGridComponent implements OnChanges {

  /**
   * Propiedades de la tabla que serán utilizadas para su construcción, es un json con el siguiente formato
   * ```js
   * config = {
   * url:'/ayuda',
   * columnas : [
   *    {field:'pregunta', header:'Pregunta'},
   *    {field:'comentario', header:'Respuesta', innerHTML:true},
   *    {field:'', header:'Acciones', actions: true, 
   *        options:[
   *           {event:'edit', tooltip:'Editar rol', icon: faEdit}
   *          ]
   *    }
   * ]
   * }
   * ```
   */
  @Input('configuration') configuration: any = {}
  
  /**
   * Objeto que respresenta los datos para el filtrado de datos.
   */
  @Input('find') find: any = {}

  @Output('actions') actions: EventEmitter<any>

  loading = false;
  filtrado = false;
  
  page=1;
  pageSize=10;
  maxSize=10;
  collectionSize=0;
  dataList:any[]=[]

  faSpinner = faSpinner;
  faSearch=faSearch;
  faPlus=faPlus;
  faEdit=faEdit;
  faTrashCan=faTrashCan;
  faTriangleExclamation=faTriangleExclamation;
  
  hideComponent = false

  constructor(
    private mongoSrv: MongoDBService, 
    private toastr: ToastrService,
    public viewContainerRef: ViewContainerRef
  ) { 
    this.actions = new EventEmitter();
  }
  /**
   * Esta función se ejecuta cada vez que existe cambios en el filtrado de datos
   * @param changes - Cambios que ocurren en el componente
   */
  ngOnChanges(changes: SimpleChanges) {
    if(changes.find) {
      this.page=1;
      this.obtenerDatos();
    }
  }
  
  /**
   * Función para buscar los datos del listado.
   * Según las configuraciones que pasa el desarrollador se utiliza la URL para obtener los datos.
   * Según la interacción del usuario se agregan los datos de cantidad de registros y pagina del listado. 
   * Por ultimo también se agregan datos de filtrado, cuando ocurre cambio en el objeto
   */
  async obtenerDatos(){
    
    const params: Param[]=[];
    params.push( {key:'size', value: this.pageSize.toString()} )
    params.push( {key:'page', value: this.page.toString()} )
    
    const valueFilter = JSON.stringify(deleteEmptyData(this.find))
    
    if (valueFilter) {
      params.push( {key:'filtros', value: valueFilter} )
    }

    if(this.configuration.sort) {
      params.push( {key:'orderBy', value: this.configuration.sort.orderBy} )
      params.push( {key:'orderDir', value: this.configuration.sort.orderDir} )
    }
    
    this.loading = true
    const data = await this.mongoSrv.get(this.configuration.url,params);
    if (data) {
      this.dataList= data.datos;
      this.collectionSize = data.total;
      if(this.configuration.hideIfEmpty) {
        if(this.dataList.length == 0){
          this.hideComponent=true;
        }
      }
    } else {
      this.toastr.error(data.error,'',{positionClass:'toast-top-center', progressBar:false})
    }
    this.loading = false
  }


  /**
   * Se encarga de emitir un evento al Componente padre.
   * Ejemplo: 
   * ```HTML
   * <bpe-grid 
   * ...
   * (actions)="clickInfo( $event )">
   * 
   * </bpe-grid>
   * ```
   * En este caso, por cada evento emitido, se va a ejecutar la función clickInfo(), el dev se encarga de manejar el 
   * evento en esta función
   * 
   * @param accion - Representa la acción que debe ser emitida.
   * @param data - Representa los datos que se van a procesar con el evento emitido.
   * 
   */
  emitAction(accion: any, data: any){
    this.actions.emit( {accion, data})
  }

  /**
   * Se encarga de procesar el texto que se va a mostrar en la grilla.
   * Sirve para poder procesar un objeto dentro de la lista, supongamos que el listado de users 
   * que tiene una entidad asociada, el field es `entidad.nombre`, entonces se pueda visualizar la propiedad nombre de la entidad
   * 
   * @param row - Representa el objeto completo de la fila
   * @param field - Representa el path para acceder a la información que queremos mostrar
   * 
   */
  getData(row, columnConfig) {
    if(columnConfig.renderer){
      return columnConfig.renderer(row)
    } else {
      const keys = columnConfig.field.split('.');
      let dataToProcess=row
      for(const key of keys){
        if(key in dataToProcess) {
          dataToProcess = dataToProcess[key]
        } else {
          return '';
        }
      }
      return dataToProcess
    }
    
  }
}
