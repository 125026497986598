import { Component, ElementRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { faPlus, faFileExcel, faSearch, faSpinner, faExternalLinkAlt, faTrash, faRefresh, faAngleRight, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { WorkBook, WorkSheet, utils, writeFile } from 'xlsx';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { DataService } from 'src/app/services/data.service';
import { Basico, OC, ODataQuery, Param } from 'src/app/interfaces/interfaces';
import { Subscription } from 'rxjs';
import { OCFilterPipe, OCSearchPipe } from 'src/app/pipes/search.pipe';
import { UsersService } from 'src/app/services/users.service';
import { AuditService } from 'src/app/services/audit.service';
import { LogService } from 'src/app/services/log.service';
import { Constantes } from 'src/app/constants/constantes.const';
import { MongoDBService } from 'src/app/services/mongoDB.service';
import { DownloadProgressService } from 'src/app/services/download-progress.service';

@Component({
  selector: 'app-listado-oc',
  templateUrl: './listado-oc.component.html',
  styleUrls: ['./listado-oc.component.scss'],
  providers: [OCFilterPipe, OCSearchPipe]
})
export class ListadoOCComponent {
  @ViewChild('tabla') table!: ElementRef;
  //filters
  filterID = '';
  filterStatus: string[] = [];
  filterCreateSince: string = moment(new Date()).subtract(6, 'month').format('YYYY-MM-DD');
  filterCreateUntil: string = moment(new Date()).format('YYYY-MM-DD');
  searchText = '';
  searchProspectPartyName = '';
  searchRUC = '';
  searchProcessingTypeCodeText = '';
  searchPrimaryContactPartyName = '';
  searchRubroKutText = '';
  searchSalesOrganisationName = '';
  searchCreadorPorText = '';
  //icons
  faSearch = faSearch;
  faFileExcel = faFileExcel;
  faPlus = faPlus;
  faSpinner = faSpinner;
  faExternalLinkAlt = faExternalLinkAlt;
  faTrash = faTrash;
  faRefresh = faRefresh;
  faAngleRight = faAngleRight;
  faAngleUp = faAngleUp;

  statuses: Basico[] = [];
  subscriptions: Subscription[] = [];
  //vistas
  loading = false;
  exporting = false;
  filterApplied = false;
  dateChanging = false;
  //Paginado
  page = 1;
  pageSize = 5;
  collectionSize = 0;
  skip = 0
  filteredCount = { count: 0 };
  oportunidades: any[] = [];

  cargando = false
  showFilters = false

  columns: any[] = [
    { header: "ID", field: 'ID' },
    { header: "Razón Social", field: 'razonSocial' },
    { header: "Ciclo de ventas", field: 'ciclodeVentas', property: 'descripcion' },
    { header: "Persona de contacto", field: 'contacto' },
    { header: "Productos", field: 'productos' },
    { header: "Plan USD", field: 'planUSD' },
    { header: "Estado OC", field: 'estado', property: 'descripcion' },
    { header: "Promoción", field: 'promocion' },
    { header: "Rubro", field: 'rubro' },
    { header: "Ciudad", field: 'ciudad' },
    { header: "Fecha de creación", field: 'fechaCreacion', date: 'dd/MM/YYYY HH:mm:ss' },
    { header: "Fecha y hora de aprobación", field: 'fechaAprobacion', date: 'dd/MM/YYYY HH:mm:ss' },
    { header: "Fecha cierre", field: 'fechaCierre', date: 'dd/MM/YYYY' },
    { header: "Usuario de creación", field: 'creadoPor' },
  ]

  constructor(
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private storageSrv: StorageService,
    private dataSrv: DataService,
    private toastSrv: ToastrService,
    private filterPipe: OCFilterPipe,
    private searchPipe: OCSearchPipe,
    private usersSrv: UsersService,
    private auditSrv: AuditService,
    private logSrv: LogService,
    private mongoSrv: MongoDBService,
    private downloagPrgSrv: DownloadProgressService
  ) {
    this.title.setTitle(`${this.activatedRoute.snapshot.data.titulo} - ${environment.app_name}`);
    this.subscriptions.push(
      this.dataSrv.getPreLoadStatus().subscribe(cargado => {
        if (cargado) {
          this.getData();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

  async getData() {

    this.dataSrv.setCargando(true);
    await Promise.all([
      this.dataSrv.loadOpportunitiesStatuses(),
      this.dataSrv.loadConfigConfirmacionCuentas()
    ]);
    this.dataSrv.setCargando(false);

    // obtiene el estado de carga
    this.subscriptions.push(this.dataSrv.getLoadingStatus().subscribe(loading => {
      this.loading = loading
    }));

    // obtiene los estados de oportunidad comercial
    this.subscriptions.push(this.dataSrv.getOpportunitiesStatuses().subscribe(statuses => {
      this.statuses = statuses;
    }));

    // obtiene el estado de filtrado
    this.subscriptions.push(this.dataSrv.getFilteringStatus().subscribe(filtering => {
      if (!filtering) {
        this.updatePagination();
      }
    }));
  }

  async exportExcelV2(): Promise<void> {
    const nOpportunities: any[] = [];
    const params: Param[] = [];
    params.push({ key: 'format', value: 'json' });
    params.push({ key: 'top', value: '2000' });
    params.push({ key: 'expand', value: 'OpportunityItem' });
    params.push({ key: 'select', value: 'ObjectID,ID,ProspectPartyName,ProcessingTypeCode,ProcessingTypeCodeText,PrimaryContactPartyName,OpportunityItem,ExpectedRevenueAmountCurrencyCode,ExternalUserStatusCode,ExternalUserStatusCodeText,ProbabilityPercent,SalesOrganisationName,CreationDateTime,Z_RUBRO_KUTText,Z_FECHAHORA_APROB_KUT,Z_OCCREADOPOR_KUT,ProcessStatusValidSinceDate' });
    const filters = this.buildFilters()

    params.push({ key: 'filters', value: JSON.stringify(filters) });

    const opportunitiesToExport: any = await this.mongoSrv.get(`/oc`, params)
    if (opportunitiesToExport.d.results.length > 0) {

      for (const oc of opportunitiesToExport.d.results) {
        // instancia variable local
        let productos = "";
        // recorre la lista de productos asociados a la oc
        oc.OpportunityItem.forEach(producto => {
          // extra el nombre y agrega a la variable
          productos = productos + producto.ProductIDDescription + ';';
        });
        if (!this.dataSrv.getOpportunityStatus(oc.ExternalUserStatusCode)) {
          this.dataSrv.addOpportunityStatus(oc.ExternalUserStatusCode, oc.ExternalUserStatusCodeText);
        }

        let datosInstalacion = '';
        let index = 0;
        if(oc.ticketsAsociados) {
          for (const dato of oc.ticketsAsociados) {

            datosInstalacion = `${datosInstalacion} ${dato.ticketId}, ${dato.estado}, ${dato.descripcion}`
            index++;
            if (index < oc.ticketsAsociados.length) {
              datosInstalacion += `; `;
            }
          }
        }
        

        const nOC = {
          "ID": oc.ID,
          "Razón Social": oc.ProspectPartyName,
          "Ciclo de Ventas": { id: oc.ProcessingTypeCode, descripcion: oc.ProcessingTypeCodeText },
          "Contacto": oc.PrimaryContactPartyName,
          "Productos": productos,
          "Plan USD": oc.ExpectedRevenueAmountCurrencyCode,
          "Estado": this.dataSrv.getOpportunityStatus(oc.ExternalUserStatusCode)!.descripcion,
          "Promocion": oc.ProbabilityPercent,
          "Rubro": oc.Z_RUBRO_KUTText,
          "Ciudad": oc.SalesOrganisationName,
          "Fecha Creación": moment(oc.CreationDateTime).toDate(),
          "Fecha Aprobación": oc.Z_FECHAHORA_APROB_KUT ? moment(oc.Z_FECHAHORA_APROB_KUT).toDate() : oc.Z_FECHAHORA_APROB_KUT,
          "Fecha Cierre": Constantes.ESTADOS_CERRADOS_OC.includes(oc.ExternalUserStatusCode) ? oc.ProcessStatusValidSinceDate ? moment(oc.ProcessStatusValidSinceDate).add(4, 'h').toDate() : oc.ProcessStatusValidSinceDate : undefined,
          "Usuario": oc.Z_OCCREADOPOR_KUT,
          "Datos de tickets asociados": datosInstalacion
        };
        nOpportunities.push(nOC)
      }

      setTimeout(() => {
        try {
          // instancia el libro 
          const wb: WorkBook = utils.book_new();
          const ws: WorkSheet = utils.json_to_sheet(nOpportunities);
          // // crea variables con tamaño de las columnas
          const wscols = [
            { wch: 10 }, //ID
            { wch: 30 }, //Razón Social
            { wch: 16 }, //Ciclo de Ventas
            { wch: 16 }, //Contacto
            { wch: 16 }, //Productos
            { wch: 10 }, //Plan USD
            { wch: 10 }, //Estado
            { wch: 10 }, //Promocion
            { wch: 10 },  //Rubro
            { wch: 15 },  //Ciudad
            { wch: 10 },  //Fecha Creación
            { wch: 10 },  //Fecha Aprobación
            { wch: 10 },  //Fecha Cierre
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
          ];
          // asigna variable a la tabla
          ws['!cols'] = wscols;
          // genera el libro y agrega la hoja
          utils.book_append_sheet(wb, ws, 'Hoja1');
          // guarda el archivo
          writeFile(wb, 'Listado OC.xlsx');
          // Registrar acción
          const filters: any = {};
          if (this.filterID) {
            filters.ID = this.filterID;
          }
          if (this.filterCreateSince) {
            filters.Desde = this.filterCreateSince;
          }
          if (this.filterCreateUntil) {
            filters.Hasta = this.filterCreateUntil;
          }
          if (this.filterStatus.length > 0) {
            filters.Estados = this.filterStatus.toString();
          }
          if (this.searchText != '') {
            filters.Texto = this.searchText;
          }
          this.auditSrv.logAction({ modulo: 'OC', accion: 'Exportar datos de OC', detalle: JSON.stringify(filters) });
          this.exporting=false;
        } catch (e:any) {
          this.logSrv.sendErrorNotification(e.toString(),'','','');
        }

      }, 50)
    }
  }

  async launchExportacion() {
    this.downloagPrgSrv.addExecutions({
      mensaje: 'Estamos creando el archivo',
      title: 'Prepando exportación',
      isHidden: false,
    })
    await this.exportExcelV2();
    this.downloagPrgSrv.addExecutions({ isHidden: true })
  }

  nuevaOc() {
    this.router.navigate(['oportunidadcomercial', 'crearoc']);
  }

  detallesOc(id: any) {
    this.router.navigate(['oportunidadcomercial', id, 'detalle'])
  }

  formatNumbers(number: string) {
    return number.split('.')[0];
  }

  toogleShowFilters() {
    this.showFilters = !this.showFilters;
  }
  /** Método para comprobar si algún filtro ha sido aplicado */
  checkFilters() {
    this.saveFilters();
    this.filterApplied = this.filterID.length > 0 || this.filterStatus.length > 0;
    this.updatePagination();
  }

  /** Método para cargar los filtros*/
  loadFilters() {
    const filters = JSON.parse(this.storageSrv.get('ocFilters')!);
    if (filters) {
      this.filterID = filters.filterID;
      this.filterStatus = filters.filterStatus;
      this.filterCreateSince = filters.filterCreateSince;
      this.filterCreateUntil = filters.filterCreateUntil;
    }
  }

  /** Método para guardar los filtros aplicados en la página */
  saveFilters() {
    const filtersPage = {
      filterID: this.filterID,
      filterStatus: this.filterStatus,
      filterCreateSince: this.filterCreateSince,
      filterCreateUntil: this.filterCreateUntil
    }
    this.storageSrv.save('ocFilters', JSON.stringify(filtersPage));
  }

  /** Método para actualizar el paginado de tickets */
  updatePagination() {
    this.cargando = true
    this.skip = this.pageSize * (this.page - 1);
    this.getAllOportunities()
  }

  buildFilters() {
    const queryFilters: ODataQuery[] = [];

    queryFilters.push({
      key: `Z_ENTIDAD_PORTAL_KUT`,
      value: this.usersSrv.getActualUser().entidad?.nombre
    })

    if (this.filterID && this.filterID != '') {
      queryFilters.push({ key: 'ID', value: this.filterID })
      this.saveFilters()

      return queryFilters
    }

    if (this.filterStatus && this.filterStatus.length > 0) {
      queryFilters.push({ key: 'ExternalUserStatusCode', value: this.filterStatus, operation: 'in' })
    }

    if (this.filterCreateSince && this.filterCreateSince != '') {
      const desde = momentTZ(new Date(this.filterCreateSince)).tz('America/Asuncion').format('YYYY-MM-DDTHH:mm:ss')
      queryFilters.push({ key: 'CreationDateTime', value: `${desde}`, operation: 'ge' })
    }

    if (this.filterCreateUntil && this.filterCreateUntil != '') {
      const fecha = new Date(this.filterCreateUntil);
      fecha.setDate(fecha.getDate() + 1)
      fecha.setHours(23)
      fecha.setMinutes(59)
      fecha.setSeconds(59)
      fecha.setMilliseconds(999)
      const hasta = momentTZ(fecha).tz('America/Asuncion').format('YYYY-MM-DDTHH:mm:ss')
      queryFilters.push({ key: 'CreationDateTime', value: `${hasta}`, operation: 'le' })
    }
    if (this.searchProspectPartyName && this.searchProspectPartyName != '') {
      queryFilters.push({ key: 'ProspectPartyName', value: this.searchProspectPartyName, operation: 'like'  })
    }
    if (this.searchRUC && this.searchRUC != '') {
      queryFilters.push({ key: 'Z_RUC_KUT', value: this.searchRUC, operation: 'like'  })
    }
    if (this.searchProcessingTypeCodeText && this.searchProcessingTypeCodeText != '') {
      queryFilters.push({ key: 'ProcessingTypeCodeText', value: this.searchProcessingTypeCodeText, operation: 'like'  })
    }
    if (this.searchPrimaryContactPartyName && this.searchPrimaryContactPartyName != '') {
      queryFilters.push({ key: 'PrimaryContactPartyName', value: this.searchPrimaryContactPartyName, operation: 'like' })
    }
    if (this.searchRubroKutText && this.searchRubroKutText != '') {
      queryFilters.push({ key: 'Z_RUBRO_KUTText', value: this.searchRubroKutText, operation: 'like'  })
    }
    if (this.searchSalesOrganisationName && this.searchSalesOrganisationName != '') {
      queryFilters.push({ key: 'SalesOrganisationName', value: this.searchSalesOrganisationName, operation: 'like'  })
    }
    if (this.searchCreadorPorText && this.searchCreadorPorText != '') {
      queryFilters.push({ key: 'Z_OCCREADOPOR_KUT', value: this.searchCreadorPorText, operation: 'like'  })
    }

    this.saveFilters()

    return queryFilters
  }

  buscar() {
    this.cargando = true
    this.page = 1
    this.skip = 0;
    this.getAllOportunities();
  }

  limpiar() {
    this.cargando = true
    this.page = 1
    this.skip = 0; 
    this.filterCreateSince = moment(new Date()).subtract(6, 'month').format('YYYY-MM-DD');
    this.filterCreateUntil = moment(new Date()).format('YYYY-MM-DD');
    this.filterID = '';
    this.filterStatus = [];
    this.searchProspectPartyName = '';
    this.searchProcessingTypeCodeText = '';
    this.searchPrimaryContactPartyName = '';
    this.searchRubroKutText = '';
    this.searchSalesOrganisationName = '';
    this.searchCreadorPorText = '';
    this.storageSrv.remove('filters');
    this.getAllOportunities();
  }
  async getAllOportunities() {
    const queries: Param[] = [];
    queries.push({ key: 'select', value: 'ObjectID,ID,ProspectPartyName,ProcessingTypeCode,ProcessingTypeCodeText,PrimaryContactPartyName,OpportunityItem,ExpectedRevenueAmountCurrencyCode,ExternalUserStatusCode,ExternalUserStatusCodeText,ProbabilityPercent,SalesOrganisationName,CreationDateTime,Z_RUBRO_KUTText,Z_FECHAHORA_APROB_KUT,Z_OCCREADOPOR_KUT,ProcessStatusValidSinceDate' });
    queries.push({ key: 'top', value: this.pageSize.toString() });
    queries.push({ key: 'skip', value: this.skip.toString() });
    queries.push({ key: 'expand', value: 'OpportunityItem' });

    const filters = this.buildFilters()

    queries.push({ key: 'filters', value: JSON.stringify(filters) });
    queries.push({key: 'order', value: JSON.stringify({ orderby: 'CreationDateTime', orderdir: 'desc' })})

    this.auditSrv.logAction({
      modulo: "OC",
      accion: "Obtener oportunidades comerciales",
      detalle: `Consulta: ${JSON.stringify(queries)}`
    })

    const nOpportunities = await this.mongoSrv.get('/oc', queries);
    this.collectionSize = nOpportunities.d.__count;
    this.oportunidades = [];
    for (const oc of nOpportunities.d.results) {
      // instancia variable local
      let productos = "";
      // recorre la lista de productos asociados a la oc
      oc.OpportunityItem.forEach(producto => {
        // extra el nombre y agrega a la variable
        productos = productos + producto.ProductIDDescription + ';';
      });
      if (!this.dataSrv.getOpportunityStatus(oc.ExternalUserStatusCode)) {
        this.dataSrv.addOpportunityStatus(oc.ExternalUserStatusCode, oc.ExternalUserStatusCodeText);
      }
      const nOC: OC = {
        OID: oc.ObjectID,
        ID: oc.ID,
        razonSocial: oc.ProspectPartyName,
        ciclodeVentas: { id: oc.ProcessingTypeCode, descripcion: oc.ProcessingTypeCodeText },
        contacto: oc.PrimaryContactPartyName,
        productos: productos,
        planUSD: oc.ExpectedRevenueAmountCurrencyCode,
        estado: this.dataSrv.getOpportunityStatus(oc.ExternalUserStatusCode)!,
        promocion: oc.ProbabilityPercent,
        rubro: oc.Z_RUBRO_KUTText,
        ciudad: oc.SalesOrganisationName,
        fechaCreacion: moment(oc.CreationDateTime).toDate(),
        fechaAprobacion: oc.Z_FECHAHORA_APROB_KUT ? moment(oc.Z_FECHAHORA_APROB_KUT).toDate() : oc.Z_FECHAHORA_APROB_KUT,
        fechaCierre: Constantes.ESTADOS_CERRADOS_OC.includes(oc.ExternalUserStatusCode) ? oc.ProcessStatusValidSinceDate ? moment(oc.ProcessStatusValidSinceDate).add(4, 'h').toDate() : oc.ProcessStatusValidSinceDate : undefined,
        creadoPor: oc.Z_OCCREADOPOR_KUT,
        ticketsAsociados: oc.ticketsAsociados ? oc.ticketsAsociados : []
      };
      this.oportunidades.push(nOC)
    }

    this.cargando = false
  }


}
