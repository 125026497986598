import { Component, OnInit,Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AdjuntosService } from 'src/app/services/adjuntos.service';
import moment from 'moment';
import { faDownload, faPlus, faSpinner, faSearch,faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import FileUtils from 'src/app/utils/fileUtils';
import { Documento } from 'src/app/interfaces/interfaces';
import { BehaviorSubject } from 'rxjs';
import { Mensajes } from 'src/app/constants/mensajes.const';
import { UsersService } from 'src/app/services/users.service';


@Component({
  selector: 'app-adjuntos',
  templateUrl: './adjuntos.component.html',
  styleUrls: ['./adjuntos.component.scss']
})
export class AdjuntosComponent implements OnInit {
  @ViewChild('nuevoAdjunto') nuevoAdjunto;
  faDownload=faDownload;
  faSpinner=faSpinner;
  faPlus=faPlus;
  faSearch=faSearch;
  faTrash=faTrash;
  @Input() idCliente;
  @Input() idEvento;
  @Input() idOc;
  @Input() ticket;
  @Input() canUpload = false;
  @Input() canDelete = false;
  @Input() tipoAdjunto = 'TK';

  nombreArchivo:any; //filtro
  tempId:any;
  tipo:any;

  showDropZone = false;
  loading = false;
  loadingData = false;
  loadingMessage = new BehaviorSubject("");
  filtrado = false;
  crearAdjunto = true;

  page = 1;
  pageSize = 4;
  collectionSize;


  documentos:Documento[]=[]
  DOCUMENTOS:Documento[]=[]
  documentosFiltrados:Documento[]=[]
  evt;
  file;

  fileToDelete;

  constructor(
    private modalSrv: NgbModal,
    private adjuntosSrv:AdjuntosService,
    private router: Router, 
    private toastr:ToastrService,
    private toastSrv: ToastrService,
    private usersSrv: UsersService
  ) {
    this.tempId = this.router.url.includes('oportunidadcomercial') ? '1152789': '997172' 
    this.tipo = this.router.url.includes('oportunidadcomercial') ? 'OC' : 'TK' 
   }

  ngOnInit(): void {
    this.getAdjuntos()
  }

  async getAdjuntos(){
    this.loadingData=true;
    const adjuntos = await this.adjuntosSrv.getAdjuntos(this.idEvento, this.tipo);
    this.documentos=adjuntos;
   
    this.DOCUMENTOS=this.documentos;
    this.collectionSize=this.documentos.length;
    this.loadingData=false;
  }

  /** Método para eliminar el archivo seleccionado */
  async deleteDocument() {
    this.loading=true;
    this.loadingMessage.next("Eliminando adjunto");
    let deleted;
    if(this.fileToDelete.contieneInformacionSensible) {
      deleted = await this.adjuntosSrv.deleteSecureDocument(this.fileToDelete.documentoSeguro)
    } else {
      const req= { 
        "templateId": this.tempId,
        "PK": this.fileToDelete.pk,
        "UsuarioEliminacion": this.usersSrv.getActualUser().email
      }
      deleted = await this.adjuntosSrv.deleteDocument(req);
    }
    
    if (deleted) {
      this.getAdjuntos().then(() => {
        this.loading=false;
      })
      this.toastr.success('Adjunto eliminado','',{positionClass:'toast-top-center',progressBar:false});
    } else {
      this.toastr.error('Se produjo un error al eliminar','',{positionClass:'toast-top-center',progressBar:false});
      this.loading=false;
    }
  }

  formatDate(date:any){
      return moment(date).format('DD/MM/YYYY HH:mm').toString()
  }

  open(content:any,tamaño:'lg'|'md' | 'lg' | 'xl'){
      this.modalSrv.open(content, {size:tamaño, backdropClass: 'modal-backdrop'})
  }
  home(){
    this.router.navigate(['tickets','tickets'])
  }
    
  /** Método para obtener datos de un documento de domus
   * @param pk identificador del archivo a obtener datos
   */
  async getDocument(documento:any): Promise<void> {
    const pk = documento.pk
    this.loading=true;
    this.loadingMessage.next("Obteniendo archivo");
    
    if(documento.contieneInformacionSensible) {
      const res = await this.adjuntosSrv.getSensitiveContent(documento.documentoSeguro);
      this.loading=false;
    } else {
      const req= {
        "templateId": this.tempId,
        "PK":pk,
      }
      const res = await this.adjuntosSrv.getContent(req);
      if (res.retrieved) {
        this.dowloadFile(res.body);
      } else {
        this.toastSrv.error(Mensajes.ADJUNTOS_ERROR_OBTENERDATOS,'',{positionClass:'toast-top-center', progressBar:false});
      }
      this.loading=false;
    }
  }

  /** Método para descargar un archivo de domus
   * @param data datos del archivo a descargar
   */
  dowloadFile(jsonData){
    this.loading=true;
    this.loadingMessage.next("Descargando archivo");
    let titulo = this.getValue(jsonData,'Titulo');
    titulo = `${titulo}.${jsonData.contents.extension.toLowerCase()}`
    let type = jsonData.contents.extension == 'pdf' || jsonData.contents.extension == 'docx' || jsonData.contents.extension == 'doc' || jsonData.contents.extension == 'xlsx' || jsonData.contents.extension == 'xls' ? 'application' : 'image';
    type = jsonData.contents.extension == 'txt' ? 'text' : type;
    let extension= `${jsonData.contents.extension}`;
    extension = jsonData.contents.extension == 'xlsx' ? 'vnd.ms-excel': extension;
    extension = jsonData.contents.extension == 'docx' ? 'vnd.openxmlformats-officedocument.wordprocessingml.document': extension;
    extension = jsonData.contents.extension == 'txt' ? 'plain' : extension;
    extension = jsonData.contents.extension == 'doc' ? 'msword' : extension;
    extension = jsonData.contents.extension == 'xls' ? 'vnd.ms-excel' : extension;

    const imageBlob = this.dataURItoBlob(jsonData.contents.content);
    const imageFile = new File([imageBlob],titulo, { type:  `${type}/${extension}`  });
    const url= window.URL.createObjectURL(imageFile);
    const anchor = document.createElement("a");
    anchor.download = titulo;
    anchor.href = url;
    anchor.click();
    this.loading=false;
  }

  /** Método para obtener el tamaño de un archivo
   * @param document documento a inspeccionar
   * @return tamaño del archivo en kb
   */
  getFileSize(document) {
    return FileUtils.getFileSize(document);
  }

dataURItoBlob(dataURI) {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array]);    
  return blob;
}

getValue(documento: any, name: any){

  return documento.fields?.find(element=>element.name==name)==undefined || documento.fields?.find(element=>element.name==name).value==undefined  ? '' : documento.fields?.find(element=>element.name==name)?.value;
 }


 

 refreshDocuments(){
  this.documentos = this.DOCUMENTOS
   .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

  }


  refreshDocumentsFiltrados(){
  this.documentos = this.documentosFiltrados
  .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

  }


  onChange() {
    if(!this.nombreArchivo){
      this.filtrado=false;
      this.page=1;
      this.refreshDocuments();
      this.collectionSize = this.DOCUMENTOS.length;
      return
    }
    this.filtrado=true;
    this.page=1;
    this.documentosFiltrados = this.DOCUMENTOS.filter(documento => {
      return documento.titulo.includes(this.nombreArchivo)
    });
    this.collectionSize=this.documentosFiltrados.length;
    this.refreshDocumentsFiltrados();
  
  }

  onDragOver(event) {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    if (!this.showDropZone) {
      this.showDropZone=true;
    }
  }

  onFileDropped(event,modal) {
    this.evt=event;
    this.modalSrv.open(modal, {size:'lg'});
    this.showDropZone=false;
  }

  onPaste(event) {
    const items = event.clipboardData.items;
    if (!items) return;
    
    //access data directly
    let is_image = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        //image
        const blob = items[i].getAsFile();
        const evt = {
          target: {
            files: [blob]
          }
        }
        this.evt=evt;
        this.modalSrv.open(this.nuevoAdjunto, {size:'lg'});
        is_image = true;
      }
    }
    if(is_image == true){
      event.preventDefault();
    }
  }

}
