import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cliente, Contacto, Param } from '../interfaces/interfaces';
import { MongoDBService } from './mongoDB.service';

@Injectable({
  providedIn: 'root'
})
export class OcService {

  constructor(
    private mongoDBSrv: MongoDBService
  ) {}

  /** Método para obtener detalles de una oc
   * @param idOc identificador de la oportunidad comercial
   * @returns objeto con datos de la oc, si no se encuentra devuelve undefined
   */
  async getOportunityDetails(idOc:any) {
    const data = await this.mongoDBSrv.get(`/oc/${idOc}/`,[]);
    if(data && data.d && data.d.results) {
      return data.d.results[0];
    } 
    return undefined;
  }

  /** Método para obtener los productos asociados a una OC
   * @param ocObjectId identificador de la oportunidad comercial
   * @returns lista de productos asociados a la oc, si se produce un error devuelve undefined
   */
  async getOportunityProducts(ocObjectId:any) {
    const data = await this.mongoDBSrv.get(`/oc/productos/${ocObjectId}/`,[]);
    if(data && data.length > 0) {
      return data;
    } 
    return undefined;
  }
  
  /** Obtener cuentas bancarias asociadas a una OC
   * @param ocObjectId identificador de la oportunidad comercial
   * @returns lista de cuentas bancarias asociadas a la oc, si se produce un error devuelve undefined
   */
  async getBankAccounts(ocObjectId:any) {
    const data = await this.mongoDBSrv.get(`/oc/cuenta-bancaria/${ocObjectId}/`,[]);
    if(data) {
      return data;
    } 
    return undefined;
  }

  /** Método para generar un request POST mediante el servicio requestService
   * @param source endpoint al cual apuntar
   * @param data cuerpo a enviar
   */
  async post(source : any,data : any, textResponse?: boolean) {
    try {
      let textReponseFilter = ''
      if(textResponse) {
        textReponseFilter = `textResponse=${textResponse}`;
      } 
      const res = await this.mongoDBSrv.create(`/oc/save/data?source=${source}&${textReponseFilter}`,data);
      
      if (res.posted) {
        const data = this.processData(res.body.data, source);
        return {created:res.posted,body:data}
      } else {
        return {created:false}
      }
    } catch (error: any) {
      return {created:false}
    }
  }

  /** Método para generar un request POST mediante el servicio requestService
   * @param source endpoint al cual apuntar
   * @param data cuerpo a enviar
   */
  async actualizarCliente(oid: string, data : any) {
    try {
     
      const res = await this.mongoDBSrv.update(`/oc/cliente`,oid,data);
      if (res.updated) {
        const client = res.body.data;
        const nClient : Cliente = {
          OID: client.ObjectID,
          id: client.ExternalID,
          idCliente: client.AccountID,
          tipoCliente: { 
            id: client.Z_TIPODECLIENTE_KUT, 
            descripcion: client.Z_TIPODECLIENTE_KUTText 
          },
          tipoEmpresa: { 
            id: client.LegalFormCode, 
            descripcion: client.LegalFormCodeText 
          },
          ruc: client.Z_RUC_KUT,
          denominacion: client.Z_DENOMINACIONCOMERCIAL_KUT,
          razonSocial: client.Name,
          rubro: { 
            id: client.Z_RUBRO_KUT, 
            descripcion: client.Z_RUBRO_KUTText 
          },
          rol: { 
            id: client.RoleCode, 
            descripcion: client.RoleCodeText 
          },
          estadoProcesadora: client.Z_ESTADOPROCESADOR_KUTText,
          estadoSwitch: client.Z_ESTADOSWITCH_KUTText,
          direccion: {
            pais: client.CountryCode,
            departamento: client.StateCode,
            calle: client.Street,
            numeroCalle: client.HouseNumber,
            codigoPostal: client.StreetPostalCode
          },
          email: client.Email,
          telefono: client.Phone,
          celular: client.Mobile,
          web: client.WebSite,
          formaJuridica: client.Z_FORMAJURIDICA_KUT,
          promedioPorVenta: client.Z_TRNPROMEDIO_KUT,
          promedioPorMes: client.Z_MONTOFACTContent_KUT,
          mcc: client.Z_MCC_KUT,
          casaMatriz: client.Z_CASAMATRIZ_KUT
        }
        return {created:res.updated,body:nClient}
      } else {
        return {created:false}
      }
    } catch (error: any) {
      return {created:false}
    }
  }

  /** Método para crear una oc mediante onboarding
   * @param data datos de la oc a crear
   */
  async createOC(data: any) {
    try {
      const res = await this.mongoDBSrv.create(`/oc/`,data);
      if (res.posted) {
        return {created:res.posted,body:res.body}
      } else {
        return {created:false}
      }
    } catch (error: any) {
      return {created:false}
    }
  }

  /** Método para asociar una sucursal con un cliente padre
   * @param data json con id del cliente e id de la sucursal
   */
  async asociateBranchWithParent(data: any) {
    try {
      const res = await this.mongoDBSrv.create(`/oc/asociar-sucursal`,data);
      if (res instanceof HttpResponse) {
        return {created:[200,201].includes(res.status),body:res.body}
      } else {
        return {created:false}
      }
    } catch (error: any) {
      return {created:false}
    }
  }

  async checkOcByIDs(ids: string[]) {
    // instancia parametros de filtros
    const params: Param[]=[];
    params.push( {key:'ids', value: ids.join(',')} )

    const data = await this.mongoDBSrv.get(`/oc/check-oc/`,params);
    if(data && data.length > 0) {
      return true;
    } 
    return false
  }


  /** Método para obtener detalles de una oc
   * @param idOc identificador de la oportunidad comercial
   * @returns objeto con datos de la oc, si no se encuentra devuelve undefined
   */
  async getOportunityPhoneCalls(idOc:any) {
    const data = await this.mongoDBSrv.get(`/oc/${idOc}/llamadas`,[]);
    if(data) {
      return data;
    } 
    return undefined;
  }

  processData(data: any, source: string) {
    if(source.includes('cliente')){
      const client = data;

      const nClient : Cliente = {
        OID: client.ObjectID,
        id: client.ExternalID,
        idCliente: client.AccountID,
        tipoCliente: { 
          id: client.Z_TIPODECLIENTE_KUT, 
          descripcion: client.Z_TIPODECLIENTE_KUTText 
        },
        tipoEmpresa: { 
          id: client.LegalFormCode, 
          descripcion: client.LegalFormCodeText 
        },
        ruc: client.Z_RUC_KUT,
        denominacion: client.Z_DENOMINACIONCOMERCIAL_KUT,
        razonSocial: client.Name,
        rubro: { 
          id: client.Z_RUBRO_KUT, 
          descripcion: client.Z_RUBRO_KUTText 
        },
        rol: { 
          id: client.RoleCode, 
          descripcion: client.RoleCodeText 
        },
        estadoProcesadora: client.Z_ESTADOPROCESADOR_KUTText,
        estadoSwitch: client.Z_ESTADOSWITCH_KUTText,
        direccion: {
          pais: client.CountryCode,
          departamento: client.StateCode,
          calle: client.Street,
          numeroCalle: client.HouseNumber,
          codigoPostal: client.StreetPostalCode
        },
        email: client.Email,
        telefono: client.Phone,
        celular: client.Mobile,
        web: client.WebSite,
        formaJuridica: client.Z_FORMAJURIDICA_KUT,
        promedioPorVenta: client.Z_TRNPROMEDIO_KUT,
        promedioPorMes: client.Z_MONTOFACTContent_KUT,
        mcc: client.Z_MCC_KUT,
        casaMatriz: client.Z_CASAMATRIZ_KUT
      }
      return nClient;
    } else {
      const contact = data;
      const nContact : Contacto = {
        idContacto: contact.ContactID,
        nombre: contact.Name,
        cliente: contact.AccountFormattedName,
        email: contact.Email,
        telefono: contact.Phone,
        celular: contact.Mobile,
        estado: contact.StatusCode
      }
      return nContact;
    }
  }

  /** Método para acutalizar el estado de la OC
   * @param objectID oid de la OC
   * @param datos a actualizar
   * @return obtjeto con los siguientes datos
   * updated: TRUE si  el cambio se realizo correctamente
   * status estado de la peticion http
   * body cuerpo de la respuesta solo en caso de que sea correcto
   */
  async updateOC(objectID: any, data: {ExternalUserStatusCode: string}) {
    const res = await this.mongoDBSrv.update(`/oc`,objectID,data);
    return res
  }
}
