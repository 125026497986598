import { Component, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { faPlus, faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { DataService } from 'src/app/services/data.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Basico, EquiposPOS, Param, Producto } from 'src/app/interfaces/interfaces';
import { UsersService } from 'src/app/services/users.service';
import { MongoDBService } from "src/app/services/mongoDB.service";

@Component({
  selector: "app-tabla-productos-asociados",
  templateUrl: "./tabla-productos-asociados.component.html",
  styleUrls: ["./tabla-productos-asociados.component.scss"],
})
export class TablaProductosAsociadosComponent implements OnInit {
  
  @Output() productList : BehaviorSubject<Producto[]> = new BehaviorSubject<Producto[]>([]);
  @Output() equipoList : BehaviorSubject<EquiposPOS[]> = new BehaviorSubject<EquiposPOS[]>([]);
  @Output() productsForOnboarding = new BehaviorSubject<boolean>(false);

  //icons
  faPlus=faPlus;
  faTimes=faTimes;
  faSpinner=faSpinner;

  //listas
  productListOriginal:Producto []=[];
  productListFiltered:Producto []=[];
  productListAsociated:Producto []=[];
  productListSelected:Producto []=[];
  susbcriptions: Subscription[] = [];
  equposPOSList: EquiposPOS[] = []
  equposPOSSelected: EquiposPOS[] = []
  equposPOSListAsociated: EquiposPOS[] = []
  perfilesComercios: Basico[] = [];
  perfilesDescargas: Basico[] = [];
  //paginacion
  page=1;
  collectionSize=0;
  pageSize=6;

  //Paginado modal
  modalPage = 1;
  modalPageSize = 6;
  modalCollectionSize = 0;
  modalCollectionSizeFiltered = {count:0};
  modalStart=0;
  modalEnd=10;

  pageModalPOS = 1;
  pageSizeModalPOS = 6;
  collectionSizeModalPOS = 0;

  //vista
  loadingProducts = false;
  loadingPOS = false;
  forOnboarding = false;
  searchText='';
  searchSerie = '';
  filterBySerie = false;
  hasPermissionForOnboarding = false;
  codigoProductoAltaAgil = 'P51';
  canShowAltaAgil = false;

  constructor(
    private modalSrv:NgbModal,
    private dataSrv: DataService,
    private userSrv: UsersService,
    private mongoDBSrv: MongoDBService
  ) {
    this.dataSrv.loadProducts();
    this.dataSrv.loadPerfilesComerios();
    this.dataSrv.loadPerfilesDescargas();
    this.getEquiposPOS();
  }

  async ngOnInit() {
    // obtiene los tickets
    this.dataSrv.getFilteringStatus().subscribe(filtering => {
      if (!filtering) {
        this.updatePagination();
      } 
    });
    this.susbcriptions.push(this.dataSrv.getProducts().subscribe(products => {
      this.productListOriginal=products;
      this.modalCollectionSize=this.productListOriginal.length;
      this.modalCollectionSizeFiltered={count:this.productListOriginal.length};
    }));

    this.susbcriptions.push(this.dataSrv.getPerfilesComercios().subscribe(perfiles => {
      this.perfilesComercios = perfiles;
    }));

    this.susbcriptions.push(this.dataSrv.getPerfilesDescargas().subscribe(perfiles => {
      this.perfilesDescargas = perfiles;
    }));

    this.hasPermissionForOnboarding=this.userSrv.actualUSerHasPerm(['SEC_ONBOARDING_OPERACION']);
    await this.getProductoAltaAgil();
  }

  ngOnDestroy() {
    this.susbcriptions.forEach(sub => {sub.unsubscribe()});
  }

  open(contenido:any){
    this.modalSrv.open(contenido, {size:'lg',centered: true});
    this.productListFiltered = this.productListOriginal;
    if (this.forOnboarding) {
      this.productListFiltered = this.productListFiltered.filter(product => {
        return  product.onboarding==true
      });
    }
    this.modalCollectionSize=this.productListFiltered.length;
    this.modalCollectionSizeFiltered={count:this.productListFiltered.length};
    this.updatePagination();
  }

  /** Método para cerrar el modal
   * @param event evento de mouse
   */
  close(event:any){
    if(event){
      this.modalSrv.dismissAll();
    }
  }

  /**
   * Metodo para obtener producto de alta agil
   */
  async getProductoAltaAgil(){
    const params: Param[] = [];
    params.push({ key: "size", value: "-1" });
    params.push({
      key: "filtros",
      value: JSON.stringify({ codigo: "producto.alta.agil" }),
    });
    const param = await this.mongoDBSrv.get(`/parametroGenerales/`, params);
    if(param) {
      this.codigoProductoAltaAgil = param.datos[0].valor;
    }
  }
  /** Método para actualizar el paginado de productos modal */
  updatePagination() {
    this.modalCollectionSize=this.modalCollectionSizeFiltered.count;
    this.modalStart = this.modalPage * this.modalPageSize - this.modalPageSize;
    this.modalEnd = this.modalPage * this.modalPageSize;
  }

  /** Método para comprobar si un producto está en la lista de asociados
   * @param product producto a comprobar
   * @returns TRUE cuando está asociado, sino false
   */
  isAsociated(product:Producto) {
    return this.productListAsociated.includes(product);
  }

  /** Método para comprobar si un producto está en l alista de seleccionados
   * @param product Producto a comprobar
   * @return TRUE cuando el producto est{a en la lista, sino FALSE}
   */
  isSelected(product:Producto) {
    return this.productListSelected.includes(product);
  }

  /** Método para seleccionar o deseleccionar un producto
   * @param product Producto a modificar
   * @param event Evento de seleccion en checkbox
   */
  selectProduct(product:Producto,event:any) {
    if (event.target.checked) {
      this.productListSelected.push(product);
    } else {
      this.productListSelected=this.productListSelected.filter(nProduct => {return nProduct!=product});
    }
  }

  /** Método para agrear productos a la lista de asociados */
  asociateProducts() {
    this.productListSelected.forEach(product => {
      product.costo= {},
      product.cantidad=''
      this.productListAsociated.push(product);
    });
    this.productListSelected=[];
    this.checkForAltaAgil()
    this.sendData();
    this.modalSrv.dismissAll();
  }

  /** Método para quitar un producto de la lista de asociados
   * @param product Producto a remover de la lista
   */
  removeProduct(product:Producto) {    
    this.productListAsociated=this.productListAsociated.filter(p => {return product!=p});
    this.equposPOSListAsociated=this.equposPOSListAsociated.filter(p => {return product.id!=p.codigoProducto});
    this.checkForAltaAgil();
    this.sendData();
  }

  /** Método para enviar datos a la ventana padre */
  sendData() {
    this.productsForOnboarding.next(this.allOnboarding());
    this.productList.next(this.productListAsociated);
    this.equipoList.next(this.equposPOSListAsociated);
  }
 
  /** Método para comprobar si todos los productos asociados son de onboarding */
  allOnboarding() {
    let onboarding = false;
    if (this.productListAsociated.length>0) {
      onboarding = this.productListAsociated.filter(p => p.onboarding==false).length>0 ? false : true;
    } 
    return onboarding;
  }

  /**
   * Metodo para obtener los POS.
   */
  async getEquiposPOS(){
    this.loadingPOS = true;
    if(this.searchSerie) { 
      this.filterBySerie = true;
      this.equposPOSList = await this.mongoDBSrv.get(`/equipos-pos`,[{key:'serie',value:this.searchSerie}]);
      this.equposPOSList = this.equposPOSList.filter(equipo => equipo.estado == 'Disponible')
    } else  {
      this.filterBySerie = false;
      this.equposPOSList = await this.mongoDBSrv.get(`/equipos-pos`,[]);
      this.equposPOSList = this.equposPOSList.filter(equipo => equipo.estado == 'Disponible')
    }
    this.collectionSizeModalPOS = this.equposPOSList.length;
    this.loadingPOS = false
  }

  async clearSearchSerie(){
    this.searchSerie = '';
    this.getEquiposPOS();
  }
  
  selectEquiposPOS(equipo:EquiposPOS, event:any){
    
    if (event.target.checked) {
      this.equposPOSSelected.push({...equipo});
    } else {
      this.equposPOSSelected = this.equposPOSSelected.filter(e => e.serie != equipo.serie);
    }
  }

  /** Método para comprobar si un POS ya está en la lista de asociados
   * @param pos POS a comprobar
   * @returns TRUE cuando está asociado, sino false
   */
  isPOSAsociated(pos:EquiposPOS) {
    let isSelected = false;
    this.equposPOSListAsociated.forEach(e => {
      if(e.serie == pos.serie){
        isSelected = true;
      }
    });
    return isSelected;
  }

  isPOSSelected(equipo:EquiposPOS){
    let isSelected = false;
    this.equposPOSSelected.forEach(e => {
      if(e.serie == equipo.serie){
        isSelected = true;
      }
    });
    return isSelected;
  }

  /** Método para agregar POS a la lista de asociados */
  asociatePOS() {
    // asociamos POS
    this.equposPOSSelected.forEach(equipo => {
      const productFound = this.productListOriginal.find(p => p.id == equipo.codigoProducto);
      if(productFound){
        this.productListSelected.push(productFound);
      }
      this.equposPOSListAsociated
        .push({
            ...equipo, 
            perfilescomerciosSeleccionables: this.getPerfilesComerios(equipo.codigoProducto),
            perfilesdescargasSeleccionables: this.getPerfilesDescarga(equipo.codigoProducto)
        });
    });
    
    // Agrupando productos asociados
    this.productListSelected.forEach(product => {
      const productoAsociado = this.productListAsociated.find(p => p.id == product.id);
      if(!productoAsociado){
        product.costo= product.costos![0],
        product.cantidad='1'
        this.productListAsociated.push(product);
      } else {
        productoAsociado.cantidad = (parseInt(productoAsociado.cantidad ? productoAsociado.cantidad : '0') + 1).toString();
      }
    })
    
    this.productListSelected=[];
    this.sendData();
    this.equposPOSSelected=[];
    this.modalSrv.dismissAll();
  }

  removePOS(equipo:EquiposPOS) {    
    this.equposPOSListAsociated=this.equposPOSListAsociated.filter(p => {return equipo.serie!=p.serie});
    const productoAsociado = this.productListAsociated.find(p => p.id == equipo.codigoProducto);
    if(productoAsociado){
      productoAsociado.cantidad = (parseInt(productoAsociado.cantidad ? productoAsociado.cantidad : '0') - 1).toString();
      if(productoAsociado.cantidad == '0'){
        this.removeProduct(productoAsociado);
      }
    }
    this.checkForAltaAgil()
    this.sendData();
  }


  /**
   * Metodo que se encarga de verificar si el usuario tiene permisos y condiciones para realizar alta agil
   */
  checkForAltaAgil(){
    // Chequea si el usuario tiene permisos para realizar alta agil
    const hasPermission = this.userSrv.actualUSerHasPerm(['SEC_ALTAAGIL_OPERACION']);
    if(hasPermission){
      // Cheque si se cumple la condiciones para realizar alta agil
      const productoAsociado = this.productListAsociated.find(p => p.id == this.codigoProductoAltaAgil);
      if(productoAsociado) {
        this.canShowAltaAgil = true
      } else {
        this.canShowAltaAgil = false
      }
    } else {
      this.canShowAltaAgil = false
    }
  }

  getPerfilesComerios(codigoProducto){
    const producto = this.productListOriginal.filter(product => product.id == codigoProducto)[0]
    if(producto && producto.perfilescomercio) {
      const codigosPerfiles = producto.perfilescomercio;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.perfilesComercios.filter(perfil => codigosPerfiles.includes(perfil.OID!))
    }
    return []
  }

  getPerfilesDescarga(codigoProducto){
    const producto = this.productListOriginal.filter(product => product.id == codigoProducto)[0]
    if(producto && producto.perfilesdescarga) {
      const codigosPerfiles = producto.perfilesdescarga;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.perfilesDescargas.filter(perfil => codigosPerfiles.includes(perfil.OID!))
    }
    return []
  }
}
