import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMaskCardNumber]'  // Usar esto en el template para aplicar la directiva
})
export class MaskCardNumberDirective {
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('focus') onFocus() {
    // Asignar el valor original o un string vacío si es undefined
    this.el.value = this.el.dataset.originalValue ?? '';
  }

  @HostListener('blur') onBlur() {
    const value = this.el.value;
    // Guardar el valor original
    this.el.dataset.originalValue = value;
    // Enmascarar el valor excepto los últimos 4 dígitos
    this.el.value = value.slice(0, 6) + value.slice(6, -4).replace(/\d/g, '*') + value.slice(-4);
  }
}
