import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestService } from './request.service';
import { LogService } from './log.service';
import { MongoDBService } from './mongoDB.service';
import { Param } from '../interfaces/interfaces';
import { EncryptionService } from './encryption.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class ComentariosService {

  constructor(
    private requestSrv: RequestService,
    private logSrv: LogService,
    private mongoDBSrv: MongoDBService,
    private encryptSrv: EncryptionService,
    private usersSrv: UsersService
  ) { }

  /** Método para obtener los comentarios de un ticket
   * @param objectid Identificador del ticket
   * @param statuses TRUE para filtrar solamente los cambios de estado, sino se filtrar los restantes
   * @return lista de comentarios, vacío si se produce algún error
   */
  async getComentariosTK(objectid: any,statuses: boolean) {
    const params: Param[]=[];
    params.push( {key:'tipo', value: 'TK'} )
    params.push( {key:'statuses', value: `${statuses}`} )
    return await this.mongoDBSrv.get(`/comment/c4c/${objectid}`,params);
    
  }

  /** Método para enviar un nuevo comentario de ticket
   * @param comentario datos del comentario a enviar
   * @returns obtjeto con valores commented (true o false si es creado o no, el estado y el cuerpo si es correcto)
   */
  async nuevoComentarioTK(comentario: any) {
    const res = await this.mongoDBSrv.create(`/comment/v2?tipo=TK`,comentario);
    if (res.posted) {
      return {commented:true,status:200,body:res.body}
    } else {
      return {commented:false,status:400}
    }
  }

  /** Método para obtener comentarios de una oportunidad comercial
   * @param objectid Identificador de la oc
   * @returns lista de comentarios, vacío si se produce algún error
   */
  async getComentariosOC(objectid: any) {
    const params: Param[]=[];
    params.push( {key:'id', value: objectid} )
    params.push( {key:'tipo', value: 'OC'} )
    return await this.mongoDBSrv.get(`/comment/c4c/${objectid}`,params);
  }

  /** Método para enviar un nuevo comentario de pc
   * @param comentario datos del comentario a enviar
   * @returns obtjeto con valores commented (true o false si es creado o no, el estado y el cuerpo si es correcto)
   */
  async nuevoComentarioOC(comentario: any) {
    const res = await this.mongoDBSrv.create(`/comment/v2?tipo=OC`,comentario);
    if (res.posted) {
      return {commented:true,status:200,body:res.body}
    } else {
      return {commented:false,status:400}
    }
  }

  async checkComentario(data) {
    let encryptedPayload = ""
    const customHeaders: Param[] = [];
    customHeaders.push({key:'x-user',value:this.usersSrv.getActualUser().id.toString()});
    encryptedPayload = this.encryptSrv.encryptPayloadData(JSON.stringify(data));
    const $source = this.requestSrv.postRequest('API',`/documento-seguro/check-text` , {encrypted: encryptedPayload}, customHeaders);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      return {posted: [201, 200].includes(res.status), body: res.body.data}
    } else {
      return {posted: false, body: undefined}
    }
  }

}
