import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { faSpinner, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ComentariosService } from 'src/app/services/comentarios.service';
import { Mensajes } from 'src/app/constants/mensajes.const';
import { NotificationService } from 'src/app/services/notification.service';
import { UsersService } from 'src/app/services/users.service';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { BehaviorSubject } from 'rxjs';
import { replaceTags } from 'src/app/utils/app-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-comentarios',
  templateUrl: './comentarios.component.html',
  styleUrls: ['./comentarios.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({opacity: 0}),
        animate(200, style({opacity: 1}))
      ]),
      transition('* => void', [
        animate(200, style({opacity: 0}))
      ])
    ])
  ]
})
export class ComentariosComponent implements OnInit {

  editorConfig;

  comments: any[] = [];
  COMMENTS: any[] = [];

  moment = moment;

  newComment;

  crearComentarios = false;

  @Input() allowNewComment = true;
  @Input() ticket;
  @Input() oc;
  @Input() type = "";
  @Input() recipientsEmail: string[] = [];
  @Output() NewStatus = new EventEmitter();
  @Output() collectionSize = new BehaviorSubject(0);
  @ViewChild(EditorComponent) editorComponent?: EditorComponent;
  @ViewChild('confirmarcomentario') confirmarcomentarioModel;



  loadingComments = true;
  sendingComments?: boolean;

  //icons
  faSpinner = faSpinner;
  faExclamationTriangle = faExclamationTriangle;

  page=1;
  pageSize=3;

  constructor(
    private comentarioService: ComentariosService,
    private toastr:ToastrService,
    private notifcationService: NotificationService,
    private usersSrv: UsersService,
    private modalSrv: NgbModal,
  ) {}

  ngOnInit(): void {
    this.loadingComments = true;

    if(this.type == 'OC') {
      this.getCommentsOC();
      this.editorConfig = {
        height: 100,
        selector: 'div.tinymce',
        toolbar: false,
        menubar: false,
        statusbar: false,
        language_url: '/assets/es.js',
        language: 'es',
        placeholder: 'Inserte su comentario aquí.',
        branding: false,
        paste_as_text: true,
        forced_root_block: 'div',
        remove_linebreaks : true,
      };
    } else {
      this.getCommentsTK();
      this.editorConfig = {
        height: 200,
        menubar: false,
        plugins: [
          'advlist', 'autolink', 'lists' , 'link', 'image', 'charmap', 'preview', 'anchor',
          'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'help', 'wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help',
        elementpath: false,
        language_url: '/assets/es.js',
        language: 'es',
        placeholder: 'Inserte su comentario aquí.',
        branding: false,
        paste_as_text: true,
        forced_root_block: 'div',
        remove_linebreaks : true,
      }
    }

    if(this.type == 'TK') {
      if(this.ticket.ServiceRequestUserLifeCycleStatusCodeText == 'Resuelto'){ this.crearComentarios = true; }

      if(this.ticket.ServiceRequestUserLifeCycleStatusCodeText == 'Cancelado'){ this.crearComentarios = true; }
    }
    
  }

  /** Método para obtener los comentarios del ticket actual */
  async getCommentsTK(){
    this.comments = await this.comentarioService.getComentariosTK(this.ticket.ObjectID,false);
    this.COMMENTS = this.comments
    this.loadingComments = false;
    this.collectionSize.next(this.comments.length)
    this.refreshComments()
    this.formatCommentsNames(this.comments)
  }

  /** Método para obtener comentarios de la oc actual */
  async getCommentsOC() {
    const regexNota = /solicitud de confirmación de cuenta.*?fecha de petición:.*?solicitado por:/is;

    this.comments = await this.comentarioService.getComentariosOC(this.oc.ObjectID);
    this.COMMENTS = this.comments.filter(comment => !regexNota.test(comment.Text.toLowerCase()));
    this.loadingComments = false;
    this.refreshComments()
    const comentarios = await this.comentarioService.getComentariosOC(this.oc.ObjectID);
    if (comentarios) {
      this.comments = comentarios.filter(comment => !regexNota.test(comment.Text.toLowerCase()));
      this.collectionSize.next(this.comments.length)
    }
  }

  async enviarComentarioTK(){
      this.sendingComments = true;
      if(this.newComment != undefined){
        const Ncomentario = replaceTags(this.newComment)
        const comentarioSensible = await this.evaluteNewComment(Ncomentario);
        let confirmado = true;
        if(comentarioSensible){
          confirmado = false
          const cancelarModalRef = await this.modalSrv.open(this.confirmarcomentarioModel, { size: 'md', centered: true });
          confirmado = await cancelarModalRef.result.then(async (result) => {
            return true;
          }, (reason) => {
            return false;
          });
        } 

        if(confirmado) {
          const comentario = {
            ServiceRequestID: this.ticket.ID,
            TypeCode: "10011",
            FormattedText: '<p>' + this.usersSrv.getActualUser().nombreCompleto +': </p>' + Ncomentario,
            ParentObjectID: this.ticket.ObjectID
          };
  
          const res = await this.comentarioService.nuevoComentarioTK(comentario);
          if (res.commented) {
            if((['Z5','ZRCE'].includes(this.ticket.ProcessingTypeCode))
            && (['YB','YA','Z8','YN','YO','YP'].includes(this.ticket.ServiceRequestUserLifeCycleStatusCode))){
              this.NewStatus.emit('YC');
            }
            if((this.ticket.ProcessingTypeCode == 'Z9' || this.ticket.ProcessingTypeCode == 'Z6')
            && (this.ticket.ServiceRequestUserLifeCycleStatusCode == 'Y6' || this.ticket.ServiceRequestUserLifeCycleStatusCode == '2' || this.ticket.ServiceRequestUserLifeCycleStatusCode == 'Z8')){
              this.NewStatus.emit('Z2');
            }
            if((this.ticket.ProcessingTypeCode == 'Z8')
            && (this.ticket.ServiceRequestUserLifeCycleStatusCode == 'Z8' || this.ticket.ServiceRequestUserLifeCycleStatusCode == '2' || this.ticket.ServiceRequestUserLifeCycleStatusCode == 'Z0' || this.ticket.ServiceRequestUserLifeCycleStatusCode == 'ZD' || this.ticket.ServiceRequestUserLifeCycleStatusCode == 'YB' || this.ticket.ServiceRequestUserLifeCycleStatusCode == 'YC')){
              this.NewStatus.emit('Z2');
            }
            if((this.ticket.ProcessingTypeCode == 'ZDA' || this.ticket.ProcessingTypeCode == 'ZEMB')
            && (this.ticket.ServiceRequestUserLifeCycleStatusCode == 'Z8' || this.ticket.ServiceRequestUserLifeCycleStatusCode == 'YA' || this.ticket.ServiceRequestUserLifeCycleStatusCode == 'ZD' || this.ticket.ServiceRequestUserLifeCycleStatusCode == 'YB')){
              this.NewStatus.emit('YC');
            }
  
            const body = {
              "recipients" : this.recipientsEmail,
              "subject": this.ticket.Name ? this.ticket.Name : "Nuevo comentario",
              "comment": Ncomentario,
              "user": this.usersSrv.getActualUser().email,
              "userName":  this.usersSrv.getActualUser().nombreCompleto,
              "ticketId": this.ticket.ID,
              "ticketType": this.ticket.ProcessingTypeCodeText,
              "idTicketType": this.ticket.ProcessingTypeCode,
              "entity": ['ZVE'].includes(this.ticket.ProcessingTypeCode) ? 
                [this.usersSrv.getActualUser().entidad?.codigoEntidad, this.ticket.Z_CabEntidadDueadelATM_KUT ]: 
                this.usersSrv.getActualUser().entidad?.codigoEntidad 
           }
           this.notifcationService.sendMail(body, 'comment');
  
            this.getCommentsTK();
            this.sendingComments = false;
            this.newComment = '';
  
          } else {
            this.toastr.error(Mensajes.ERROR_AL_ENVIAR_COMENTARIO,'',{positionClass:'toast-top-center', progressBar:false});
          }       
        }
        this.sendingComments = false;
      }
      else{
        this.toastr.error(Mensajes.ERROR_COMENTARIO_VACIO,'',{positionClass:'toast-top-center', progressBar:false});
        this.sendingComments = false;
      }
  }

  /** Método para enviar un nuevo comentario para la OC actual */
  async enviarComentarioOC() {
      this.sendingComments = true;
      if(this.newComment != undefined){
        const Ncomentario = replaceTags(this.newComment)
        
        const comentario = {
          OpportunityID: this.oc.ID,
          TypeCode: "10001",
          Text: this.usersSrv.getActualUser().nombreCompleto +': ' + Ncomentario,
          ParentObjectID: this.oc.ObjectID
        };
        const sendComment = await this.comentarioService.nuevoComentarioOC(comentario);
        if (sendComment.commented) {
          this.getCommentsOC();
          this.newComment = '';
        } else {
          this.toastr.error(Mensajes.ERROR_AL_ENVIAR_COMENTARIO,'',{positionClass:'toast-top-center', progressBar:false});
        }
        this.sendingComments = false;
      } else{
        this.toastr.error(Mensajes.ERROR_COMENTARIO_VACIO,'',{positionClass:'toast-top-center', progressBar:false});
        this.sendingComments = false;
      }
  }


  refreshComments(){
    this.comments = this.COMMENTS
     .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  formatCommentsNames(comments){
    let fullName;
    let nameArray;

    comments.forEach(comment => {
      if((comment.CreatedBy.split(" ").length - 1) > 2){
        nameArray = comment.CreatedBy.split(" ")
        fullName = `${nameArray[0]} ${nameArray[2]}`
      }
      else{
        fullName = comment.CreatedBy;
      }
      comment.CreatedBy = fullName;
    });
  }

  async evaluteNewComment(text){
    const data = {
      text, 
    }
    const result = await this.comentarioService.checkComentario(data);
    return result.body
  }
}
