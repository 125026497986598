import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardLoginService  {

  constructor(
    private router: Router, 
    private storageSrv: StorageService
  ) {}

  canActivate() {
    if (this.storageSrv.get("accessToken") === null ){
      return true;
    } else {
      this.router.navigate(['tickets']);
      return false;
    }
  }
}
