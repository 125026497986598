import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OcService } from 'src/app/services/oc.service';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Mensajes } from 'src/app/constants/mensajes.const';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { TimerComponent } from 'src/app/shared/timer/timer.component';
import { Constantes } from 'src/app/constants/constantes.const';
import { ContactService } from 'src/app/services/contact.service';
import { DataService } from 'src/app/services/data.service';
import { AuditService } from 'src/app/services/audit.service';
import { MongoDBService } from 'src/app/services/mongoDB.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TareasOC } from 'src/app/interfaces/interfaces';
@Component({
  selector: 'app-detalles-oc',
  templateUrl: './detalles-oc.component.html',
  styleUrls: ['./detalles-oc.component.scss']
})
export class DetallesOcComponent implements OnInit {

  @ViewChild(TimerComponent, {static:false})
  private timerComponent!:TimerComponent;
  
  
  loading = false;
  loadingMessages = new BehaviorSubject("Estamos cargando los datos de la OC, por favor aguarde");

  idOc;
  oc;
  idContacto;
  idCliente;
  ocObjectId;
  contacto: any = {};
  cliente: any = {};
  cuentasBancariasList:any []=[];
  prodcutosList:any []=[];
  datosInstalacion: { estado?: string, descripcion?: string} = {}
  datosLLamadas: TareasOC[] = []


  //paginado
  page=1;
  pageSize=5;
  collectionSize=0;

  //icons
  faSpinner=faSpinner;
  faTimes=faTimes;

  //booleans
  editable = false;
  canUploadAttached = false;
  canDeleteAttached = false;

  requiereConfirmacion?: boolean;
  subscriptions: Subscription[] = [];
  
  constructor(
    private route :ActivatedRoute,
    private ocService : OcService,
    private toastr:ToastrService,
    private title: Title,
    private contactSrv: ContactService,
    private dataSrv: DataService,
    private auditSrv: AuditService,
    private mongoSrv: MongoDBService,
  ) {}

  ngOnInit(): void {   
    this.title.setTitle(`${this.route.snapshot.data.titulo} - ${environment.app_name}`);
    this.idOc = +this.route.snapshot.paramMap.get('id')!;
    this.getOc();
  }
  
  /** Método para obtener datos de la oc */
  async getOc(){
    this.loading = true;
    this.loadingMessages.next("Cargando datos de la OC")
    this.auditSrv.logAction({modulo:'OC',accion:'Ver detalles de OC',detalle:`OC: ${this.idOc}`})
    const oc = await this.ocService.getOportunityDetails(this.idOc);
    await this.getPhoneCalls();
    if (oc) {
      this.oc = oc;
      this.ocObjectId = this.oc.ObjectID;      
      this.idContacto = this.oc.PrimaryContactPartyID;
      await this.getContact(this.idContacto);
      this.idCliente =this.oc.ProspectPartyID;
      await this.getClient(this.idCliente);
      this.canUploadAttached=!Constantes.ESTADOS_CERRADOS_OC.includes(this.oc.ExternalUserStatusCode);
      this.loading=false;
    } else {
      this.loading=false;
      this.toastr.error(`${Mensajes.ERROR_AL_CARGAR_DATOS} ${this.idOc}`,'',{positionClass:'toast-top-center', progressBar:false});
    }
  }

  /** Método para obtener datos de un contacto
   * @param contactID identificador del contacto
   */
  async getContact(contactID) {
    const contact = await this.contactSrv.getContactByID(contactID);
    if (contact) {
      this.contacto = contact
    } else {
      this.toastr.error(`${Mensajes.ERROR_AL_CARGAR_CONTACTO} ${this.idOc}`,'',{positionClass:'toast-top-center', progressBar:false});
    }
  }

  /** Método para obtener datos de un cliente
   * @param clientID identificador del cliente
   */
  async getClient(clientID: string) {
    const client = await this.dataSrv.getClientByID(clientID);
    if (client) {
      this.cliente = client;    
    } else {
      this.toastr.error(`${Mensajes.ERROR_AL_CARGAR_CLIENTE}${this.idOc}`,'',{positionClass:'toast-top-center', progressBar:false});
    }  
  }


  /** Método para obtener datos de la instalacion de la OC */
  async getDatosInstalacion(){
    this.loadingMessages.next("Buscando datos de instalación")
    this.auditSrv.logAction({modulo:'OC',accion:'Ver detalles de Instalación de OC',detalle:`OC: ${this.idOc}`})
    const data = await this.mongoSrv.getAll(`/tickets/instalacion/${this.idOc}`)
    if (data) {
      let descripcion = '';
      if(data.descripcion.length > 0) {
        descripcion = data.descripcion[0].Text
      }
      this.datosInstalacion = {
        estado: data.estado,
        descripcion
      };
    }
  }

  /** Método para obtener llamadas de la OC */
  async getPhoneCalls(){
    
    const data = await this.ocService.getOportunityPhoneCalls(this.idOc);
    if(data && data['Tareas']){
      if(data['Tareas'] instanceof Array){
        //this.datosLLamadas = data['Tareas'].filter((tarea:TareasOC) => tarea.CategTarea === '0002')
        this.datosLLamadas = data['Tareas'];
      } else {
        if(data['Tareas']['CategTarea'] === '0002'){
          this.datosLLamadas = [data['Tareas']];
        }
      }
      
    }
  }

  formatNumbers(number:string){
    return number.split('.')[0];
  }
  
}
