import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Regex } from "src/app/constants/regex.const";

export const formAltaCliente = new FormGroup({
  razonSocial: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(30),
  ]),
  ruc: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
    Validators.pattern(Regex.RUC),
  ]),
  tipoEmpresa: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  rol: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  casaMatriz: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  denominacionComercial: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  promedioTickets: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  montoPromedio: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  rubro: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  mcc: new FormControl({ value: "", disabled: true }, [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  tipoCliente: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  pais: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  departamento: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  calle: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  numeroCalle: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  barrio: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  ciudad: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  zona: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  codigoPostal: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  mail: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
    Validators.pattern(Regex.EMAIL),
  ]),
  web: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  telefono: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  telefonoMovil: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  latitud: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  longitud: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
});
