/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { formNewOc } from "./crear-oc-forms/crear-oc.form";
import {
  faExternalLinkAlt,
  faSpinner,
  faCheck,
  faTimes, faQuestionCircle, faPlus, faTrash, faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OcService } from "src/app/services/oc.service";
import { Mensajes } from "src/app/constants/mensajes.const";
import { formBusquedaRuc } from "./crear-oc-forms/busqueda-ruc.form";
import { formAltaCliente } from "./crear-oc-forms/alta-cliente.form";
import { formContact } from "./crear-oc-forms/contacto-principal.form";
import { formAltaContacto } from "./crear-oc-forms/alta-contacto.form";
import { formBankAccount } from './crear-oc-forms/new-bank-account.form';
import { environment } from "src/environments/environment";
import { Title } from "@angular/platform-browser";
import { TimerComponent } from "src/app/shared/timer/timer.component";
import { BehaviorSubject, Subscription } from "rxjs";
import { DataService } from "src/app/services/data.service";
import {
  Barrio,
  Basico,
  Ciudad,
  Cliente,
  Contacto, CuentaBancaria, CuentaFacturacion, DatosCuenta, Departamento,
  Documento, EquiposPOS, MCC, Pais,
  Param,
  Producto, TipoEmpresa, Zona,
} from "src/app/interfaces/interfaces";
import DateUtils from "src/app/utils/dateUtils";
import NumberUtils from "src/app/utils/numberUtils";
import { ContactService } from "src/app/services/contact.service";
import { Map, tileLayer, marker } from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { formatNumber } from '@angular/common';
import moment from 'moment';
import FileUtils from 'src/app/utils/fileUtils';
import { AdjuntosService } from 'src/app/services/adjuntos.service';
import { Constantes } from 'src/app/constants/constantes.const';
import { MongoDBService } from 'src/app/services/mongoDB.service';
import { UsersService } from "src/app/services/users.service";
import { AuditService } from 'src/app/services/audit.service';
import { CrmService } from 'src/app/services/crm.service';
import leaflet from 'leaflet';
import { LogService } from 'src/app/services/log.service';
import { dateConvertToGrid, simplifyText, transformToNumber } from 'src/app/utils/appUtils';
import { ComentariosService } from "src/app/services/comentarios.service";

@Component({
  selector: "app-crear-oc",
  templateUrl: "./crear-oc.component.html",
  styleUrls: ["./crear-oc.component.scss"],
})
export class CrearOcComponent implements OnInit {
  // componentes html
  @ViewChild(TimerComponent, { static: false }) timerComponent!: TimerComponent;
  @ViewChild("contactosAsociados", { static: true }) contactosAsociadosModal;
  @ViewChild("formularioClienteModal", { static: true }) nuevoClienteModal;
  @ViewChild("formularioContactoModal", { static: true }) nuevoContactoModal;
  @ViewChild('cliente', { static: true }) clientModal;
  @ViewChild('confirmNOOnboardingModal', { static: true }) confirmNOOnboardingModal;
  @ViewChild('confirmNOAltaAgilModal', { static: true }) confirmNOAltaAgilModal;
  @ViewChild('newBankAccountModal', { static: true }) newBankAccountModal;
  //varios
  loadingMessage: BehaviorSubject<string> = new BehaviorSubject('');
  subscriptions: Subscription[] = [];
  //formularios
  formNewOc: FormGroup;
  newClientForm: FormGroup;
  rucForm: FormGroup;
  contactForm: FormGroup;
  newContactForm: FormGroup;
  newBankAccountForm: FormGroup;
  //datos
  selectedClient: Cliente | undefined;
  selectedClientToEdit: Cliente | undefined;
  selectedClientSUC: Cliente | undefined;
  selectedContact: Contacto | undefined;
  selectedPEPContact: Contacto | undefined;
  selectedMainEmployeeResponsible = '';
  clientToCreate;
  clientDirectionToCreate;
  parentID: string | undefined;
  contactToCreate;
  contactPEPToCreate;
  rucPermanente;
  emailContactoPermanente;
  ocId;
  caracteresRestantesAddress = 40;
  caracteresRestantesRazonSocial = 40;
  caracteresRestantesDenominacionComercial = 40;
  //listas
  clients: Cliente[] = [];
  contactoPrincipalList: Contacto[] = [];
  productList: Producto[] = [];
  productosAltaAgil: Producto[] = [];
  equiposPOSList: EquiposPOS[] = [];
  businessTypes: TipoEmpresa[] = [];
  businessLines: Basico[] = [];
  countries: Pais[] = [];
  states: Departamento[] = [];
  cities: Ciudad[] = [];
  neighborhoods: Barrio[] = [];
  zones: Zona[] = [];
  clientTypes: Basico[] = [];
  mccsList: MCC[] = [];
  mccs: MCC[] = [];
  facturationAccounts: CuentaFacturacion[] = [];
  nAccountType: 'TD' | 'TC' | 'SPI' = 'TD';
  accountTypes: Basico[] = [];
  clientDocumentTypes: Basico[] = [];
  bankAccountTD: CuentaBancaria | undefined;
  bankAccountTC: CuentaBancaria | undefined;
  bankAccountSPI: CuentaBancaria | undefined;
  //iconos
  faSpinner = faSpinner;
  faExternalLinkAlt = faExternalLinkAlt;
  faCheck = faCheck;
  faTimes = faTimes;
  faQuestionCircle = faQuestionCircle;
  faExclamationTriangle = faExclamationTriangle;
  faPlus = faPlus;
  faTrash = faTrash;
  //booleans
  loading = false;
  validaton = false;
  disableSucursalButton = false;
  disableClientTypeSelection = true;
  creatingPEPContact = false;
  estadoConfirmacionOnboarding = false;
  productsForOnboarding = false;
  hasPermissionForOnboarding = false;
  hasPermissionForAltaAgil = false;
  isPersona = false;
  isUnipersonal = false;
  isNewClient = false;
  tieneProductosSPI = false;
  editClient = false;
  //modal paginado
  page = 1;
  collectionSize = 0;
  pageSize = 5;
  // lista de requerimientos onboarding
  onboardingCheckList: { check: string, description: string, value: boolean, warning: boolean }[] = [
    { check: 'newClient', description: 'Cliente nuevo', value: false, warning: false },
    { check: 'businessType', description: 'Tipo de empresa (Unipersonal)', value: false, warning: false },
    { check: 'accountValidated', description: 'Las cuentas deben estar validadas y es de tipo Cta. Cte. o Caja de ahorro', value: false, warning: false},
    { check: 'productsValidated', description: 'Los productos seleccionados para onboarding', value: false, warning: false }
  ];
  onboardingCheckListChecked = 0;
  // lista de requerimientos onboarding
  altaAgilCheckList: { check: string, description: string, value: boolean, warning: boolean }[] = [
    { check: 'productsValidated', description: 'POS seleccionado/s puede/n ser activado/s automáticamente', value: false, warning: false },
    { check: 'requestedFilesValidated', description: 'Alta ágil requiere el contrato para poder activar el POS', value: false, warning: false },
    { check: 'onboardingValidated', description: 'Alta ágil requiere que se cumplan todos los requisitos de Onboarding', value: false, warning: false }
  ];
  altaAgilCheckListChecked = 0;
  //mark;
  coordenadaActual = new BehaviorSubject<any>(undefined);
  mark: L.Marker | undefined;
  bordes: any;
  mapSubcription: Subscription | undefined;
  modificandoMapa = false;
  proximidad = '';
  //Adjuntos
  file_ci_f: any;
  file_ci_d: any;
  file_ruc: any;
  file_contrato: any;
  requestedFiles: Documento[] = [];
  otherFiles: Documento[] = [];
  filesToUpload: Documento[] = [];
  tipoAdjuntos: any;
  selectedFiles: any;
  selectedFile: any;
  selectedFileName: any;
  selectedFileType: any;
  tieneContrato = true;

  accountOwner;
  accountDocumentType;
  accountDocumentNumber;

  contract_url = "";

  confirmacionCuentasConfig: any;
  requiereConfirmacion?: boolean;
  cuentaConfirmada = false;
  datosCuenta?: DatosCuenta
  addNota = false;
  notaConsultaConfirmacion = '';

  constructor(
    private modalSrv: NgbModal,
    private toastr: ToastrService,
    private router: Router,
    private ocservice: OcService,
    private mongoSrv: MongoDBService,
    private title: Title,
    private contactSrv: ContactService,
    private activatedRoute: ActivatedRoute,
    private dataSrv: DataService,
    private usersSrv: UsersService,
    private auditSrv: AuditService,
    private adjuntosSrv: AdjuntosService,
    private crmSrv: CrmService,
    private logSrv: LogService,
    private comentarioService: ComentariosService
  ) {
    this.subscriptions.push(
      this.dataSrv.getPreLoadStatus().subscribe(cargado => {
        if (cargado) {
          this.getData();
        }
      })
    );
    this.formNewOc = formNewOc;
    this.newClientForm = formAltaCliente;
    this.rucForm = formBusquedaRuc;
    this.contactForm = formContact;
    this.newContactForm = formAltaContacto;
    this.newBankAccountForm = formBankAccount;
    this.formNewOc.reset();
    this.newClientForm.reset();
    this.rucForm.reset();
    this.contactForm.reset();
    this.newContactForm.reset();
    this.dataSrv.loadAccountTypes();
  }

  /** Evento que se ejecuta al iniciar el componente */
  ngOnInit(): void {
    // asigna título a la página
    this.title.setTitle(
      `${this.activatedRoute.snapshot.data.titulo} - ${environment.app_name}`
    );
    // asigna valores prederminados
    this.formNewOc.get('categoria')?.patchValue('8');
    this.formNewOc.get('pep')?.patchValue('false');
    this.formNewOc.get('unidadTDebitoNacional')?.patchValue('%');
    this.formNewOc.get('unidadTCreditoNacional')?.patchValue('%');
    this.formNewOc.get('unidadTDebitoInternacional')?.patchValue('%');
    this.formNewOc.get('unidadTCreditoInternacional')?.patchValue('%');
    this.formNewOc.get('codigoPostal')?.patchValue('');
    this.formNewOc.get('estado')?.patchValue('Z01');
    // obtiene datos del servicio
    this.hasPermissionForOnboarding = this.usersSrv.actualUSerHasPerm(['SEC_ONBOARDING_OPERACION']);
    this.hasPermissionForAltaAgil = this.usersSrv.actualUSerHasPerm(['SEC_ALTAAGIL_OPERACION']);
    this.getResponsibleEmployee()
    this.getProductosWithAltaAgil()
  }

  /** Evento que se ejecuta despues de iniciar los componentes */
  ngAfterViewInit(): void {
    // abre la ventana de busqueda de cliente
    this.open(this.clientModal, "xl");
  }

  /** Evento que se ejecuta al cerrar la ventana */
  ngOnDestroy(): void {
    // Cancela las suscripciones
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  /** Método para obtener datos del servicio */
  async getData() {
    setTimeout(async () => {
      this.dataSrv.setCargando(true);
      await Promise.all([
        this.dataSrv.loadOpportunitiesStatuses(),
        this.dataSrv.loadBusinessLines(),
        this.dataSrv.loadBusinessTypes(),
        this.dataSrv.loadClientTypes(),
        this.dataSrv.loadFacturationAccountsTypes(),
        this.dataSrv.loadClientDocumentTypes(),
        this.dataSrv.loadAccountTypes(),
        this.dataSrv.loadMCCs(),
        this.dataSrv.loadLocations(),
        this.dataSrv.loadConfigConfirmacionCuentas()
      ]);
      this.dataSrv.setCargando(false);
    })


    this.subscriptions.push(this.dataSrv.getBusinessTypes().subscribe(types => {
      this.businessTypes = types;
    }));
    this.subscriptions.push(this.dataSrv.getBusinessLines().subscribe(lines => {
      this.businessLines = lines;
    }));
    this.subscriptions.push(this.dataSrv.getCountries().subscribe(countries => {
      this.countries = countries;
    }));
    this.subscriptions.push(this.dataSrv.getStates().subscribe(states => {
      this.states = states;
    }));
    this.subscriptions.push(this.dataSrv.getClientTypes().subscribe(states => {
      this.clientTypes = states;
    }));
    this.subscriptions.push(this.dataSrv.getFacturationAccountTypes().subscribe(fat => {
      this.facturationAccounts = fat;
    }));
    this.subscriptions.push(this.dataSrv.getAccountTypes().subscribe(types => {
      this.accountTypes = types;
    }));
    this.subscriptions.push(this.dataSrv.getClientDocumentTypes().subscribe(types => {
      this.clientDocumentTypes = types;
    }));
    this.subscriptions.push(this.dataSrv.getMCCs().subscribe(mccs => {
      this.mccsList = mccs;
    }));
    this.subscriptions.push(this.dataSrv.getRequiereConfirmacion().subscribe(requiere => {
      this.requiereConfirmacion = requiere;
      if (this.requiereConfirmacion) {
        const accountValidationCheck = this.onboardingCheckList.find(check => {
          return check.check == 'accountValidated';
        })
        if (accountValidationCheck) {
          accountValidationCheck.description = 'Las cuentas deben estar confirmadas y deben ser del tipo Cta. Cte. o Caja de ahorro (El chequeo se realiza durante la creación)';
          accountValidationCheck.warning = true;
        }
      }
    }));
    const params: Param[] = [];
    params.push({
      key: "filtros",
      value: JSON.stringify({ codigo: "oc_contract_url" }),
    });
    this.mongoSrv.get('/parametroGenerales/', params).then(res => {
      this.contract_url = res.datos[0].valor
    });
  }

  /** Método para obtener las ciudades asociadas a un departamento
   * @param stateID id del departamento del cual se desea obtener las ciudades
   */
  async getCities(stateID: number): Promise<void> {
    this.loading = true;
    this.loadingMessage.next("Obteniendo ciudades")
    this.newClientForm.get('ciudad')?.setValue('');
    this.newClientForm.get('barrio')?.setValue('');
    this.newClientForm.get('zona')?.setValue('');
    this.cities = await this.dataSrv.getCities(stateID);
    this.loading = false;
  }

  /** Método para obtener los barrios asociados a una ciudad
   * @param cityID id de la ciudad de la cual se desea buscar los barrios
   */
  async getNeighborhoods(cityID: string): Promise<void> {
    this.loading = true;
    this.loadingMessage.next("Obteniendo barrios")
    this.newClientForm.get('barrio')?.setValue('');
    this.newClientForm.get('zona')?.setValue('');
    this.neighborhoods = await this.dataSrv.getNeighborhoods(cityID);
    this.loading = false;
  }

  /** Método para obtener las zonas asociadas a una ciudad
   * @param cityID id de la ciudad de la cual se desea obtener las zonas
   */
  async getZones(cityID: string): Promise<void> {
    this.loading = true;
    this.newClientForm.get('barrio')?.setValue('');
    this.newClientForm.get('zona')?.setValue('');
    this.zones = await this.dataSrv.getZones(cityID);
    this.loading = false;
  }

  /** Navega al listado de oportunidades comerciales */
  listadoOcs(): void {
    this.router.navigate(['oportunidadcomercial', 'listado']);
  }

  /** Método para crear una nueva OC */
  async validateOC(): Promise<void> {
    // valida que los campos requeridos hayan sido completados
    const validData = await this.validateData();
    if (validData) {
      await this.ejecutarConfirmacionCuentas();
      if (this.hasPermissionForOnboarding) {
        if (this.checkOnboarding()) {
          if (this.hasPermissionForAltaAgil && this.equiposPOSList.length>0) {
            if (this.checkAltaAgil()) {
              const validEquipment = await this.validatePosEquipment();
              if (validEquipment) {
                // CREA OC CON ONBOARDING Y ALTA AGIL
                this.createOCwithOnboarding(true);
              }
            } else {
              // CREA OC NORMAL TRAS CONFIRMACIÓN
              this.open(this.confirmNOAltaAgilModal, 'lg');
            }
          } else {
            // CREA OC CON ONBOARDING
            this.createOCwithOnboarding();
          }
        } else {
          if(this.requiereConfirmacion && !this.cuentaConfirmada){
            // CREA OC NORMAL
            this.createOC();
          } else {
            // CREA OC NORMAL TRAS CONFIRMACIÓN
            this.open(this.confirmNOOnboardingModal, 'lg');
          }
        }
      } else {
        this.createOC();
      }
    }
  }

  /** */
  async ejecutarConfirmacionCuentas() {
    // Comprueba si la cuenta TD tiene tipo de documento y numero de documento si se requiere confirmación
    let hasBankAccountsComplete = true;

    if (this.requiereConfirmacion) {
      // Comprueba si la cuenta TD ha sido confirmada
      if (this.bankAccountTD != undefined) {
        hasBankAccountsComplete = (this.bankAccountTD && this.bankAccountTD.tipoDocumento != undefined)
          && (this.bankAccountTD && this.bankAccountTD.numeroDocumento != undefined);
      } else {
        hasBankAccountsComplete = false
      }
      if (hasBankAccountsComplete) {
        await this.validateAccountConfirmation();
      }
    }
  }

  /** Método para crea una oportunidad comercial normal */
  async createOC(): Promise<void> {
    this.loading = true;
    const origen = "22_E";
    if (
      (!this.clientToCreate || await this.createClient(origen, this.clientToCreate, this.clientDirectionToCreate)) &&
      (!this.contactToCreate || await this.createContact(origen, false)) &&
      (!this.contactPEPToCreate || await this.createContact(origen, true))
    ) {
      this.loadingMessage.next("Creando oportunidad comercial");
      // obtiene los productos
      const productos = await this.prepareProducts();

      // obtiene las cuentas de facturacion
      const facturationAccount = await this.prepareFacturationAccounts(productos);
      // prepara datos a enviar
      const req: any = {
        "ProspectPartyID": this.selectedClient!.idCliente,
        "Z_CANAL_KUT": origen,
        "Z_FDVENTAS_KUT": this.usersSrv.getActualUser().entidad?.fuerzaDeVenta,
        "Name": this.formNewOc.value.nombre,
        "OpportunityItem": { "results": productos },
        "ExternalUserStatusCode": "",
        "OpportunityProspectPartyContactParty": {
          "results": [
            {
              "OpportunityPartyContactPartyID": this.selectedContact!.idContacto,
              "Z_CONTACTOOC__KUT": "1",
              "Z_OP_TipoCliente_KUT": "101"
            }
          ]
        },
        "Opportunity_nodoCuentasBancarias_SDK": facturationAccount,
        "ProcessingTypeCode": this.formNewOc.value.tipoOC,
        "SalesCycleCode": this.formNewOc.value.tipoOC,
        "Z_ENTIDAD_KUT": this.usersSrv.getActualUser().entidad?.codigoEntidad,
        "Z_ENTIDAD_PORTAL_KUT": this.usersSrv.getActualUser().entidad?.nombre,
        "Z_OCCREADOPOR_KUT": this.usersSrv.getActualUser().email,
        "Z_CLASIFICACIONABC_KUT": this.formNewOc.value.categoria,
        "SalesCyclePhaseCode": "1",
        "Z_TCREDITOINTContent_KUT": this.formNewOc.value.tCreditoInternacional,
        "Z_TARJETA_CNContent_KUT": this.formNewOc.value.tCreditoNacional,
        "Z_TDEBITOINTContent_KUT": this.formNewOc.value.tDebitoInternacional,
        "Z_TDEBITONACContent_KUT": this.formNewOc.value.tDebitoNacional,
        "Z_TCREDITOINTUnitCode_KUT": this.formNewOc.value.unidadTCreditoInternacional,
        "Z_TARJETA_CNUnitCode_KUT": this.formNewOc.value.unidadTCreditoNacional,
        "Z_TDEBITOINTUnitCode_KUT": this.formNewOc.value.unidadTDebitoInternacional,
        "Z_TDEBITONACUnitCode_KUT": this.formNewOc.value.unidadTDebitoNacional,
        "MainEmployeeResponsiblePartyID": this.selectedMainEmployeeResponsible
      }
      if (this.tieneContrato && this.isUnipersonal) {
        req.Z_Contrato_KUT = '01'
      } else {
        req.Z_Contrato_KUT = '02'
      }
      req.Z_RequiereConfirmacion_KUT  = this.requiereConfirmacion;
      req.Z_CuentaConfirmada_KUT = this.cuentaConfirmada
      const res = await this.ocservice.createOC(req);
      if (res.created) {
        // extrae el cuerpo de la respuesta
        const data = res.body!;
        // extrae el id de la oc creada
        this.ocId = data.data.body.d.results.ID;
        this.auditSrv.logAction({ modulo: 'OC', accion: 'Crear OC', detalle: this.ocId });
        //prapara documentos a cargar
        this.prepareFiles();
        let filesUploaded = false;
        let checked = false;
        if (this.filesToUpload.length > 0) {
          filesUploaded = await this.uploadFiles(this.ocId, this.selectedClient!.idCliente);
          checked = true;
        } else {
          filesUploaded = true;
          this.loadingMessage.next("Comprobando adjuntos");
          // prepara datos para comprobar adjuntos
          const checkAdjunto = {
            'ocId': this.ocId,
            'checkAdjunto': true,
            'enabled': true
          }
          // llama al servicio y espera el resultado
          const r = await this.mongoSrv.create('/checkAdjunto', checkAdjunto);
          checked = r.posted;
        }

        if (filesUploaded && checked) {
          this.toastr.success(Mensajes.ALTA_OC, '', { positionClass: 'toast-top-center', progressBar: true });
        } else {
          this.toastr.error(
            `${Mensajes.ERROR_AL_RECUPERAR_ADJUNTOS} ${this.ocId}`,
            "",
            { positionClass: "toast-top-center", progressBar: false }
          );
        }

        this.loadingMessage.next("Agregando nota.");
        if (this.addNota) {
          const Ncomentario = this.notaConsultaConfirmacion//replaceTags(this.notaConsultaConfirmacion)
          const comentario = {
            OpportunityID: data.data.body.d.results.ID,
            TypeCode: "10001",
            Text: Ncomentario,
            ParentObjectID: data.data.body.d.results.ObjectID
          };

          await this.comentarioService.nuevoComentarioOC(comentario);

        }
        
        this.loading = false;
        this.router.navigate(['oportunidadcomercial', this.ocId, 'detalle']);
      } else {
        this.loading = false;
        this.toastr.error(Mensajes.ERROR_AL_CREAR_OC, '', { positionClass: 'toast-top-center', progressBar: false });
      }
    } else {
      this.loading = false;
    }
  }

  /** Método para crear una nueva sucursal a partir de datos de un cliente
   * @param origen codigo de origen de la sucursal
   * @param client datos del cliente del cual se desea crear la sucursal
   * @returns TRUE si la sucursal se creo correctamente, sino FALSE
   */
  createSuc(origen: string, client: Cliente) {
    const nSuc = {
      "Name": client.razonSocial, //RAZON SOCIAL
      "LegalFormCode": client.tipoEmpresa.id, //TIPO EMPRESA
      "CountryCode": client.direccion.pais, //PAIS
      "StateCode": client.direccion.departamento, //DEPARTAMENTO
      "AddressLine1": client.direccion.calle, //CALLE
      "HouseNumber": client.direccion.numeroCalle, //NUMEROCALLE
      "Street": client.direccion.calle, //CALLE
      "StreetPostalCode": client.direccion.codigoPostal, //CODIGO POSTAL
      "District": client.direccion.zona, //ZONA
      "City": client.direccion.ciudad, //CIUDAD
      "LanguageCode": "ES", //IDIOMA
      "Email": client.email, //CORREO ELECTRÓNICO
      "Phone": client.telefono, //TELEFONO
      "Mobile": client.celular, //CELULAR
      "WebSite": client.web, //SITIO WEB
      'RoleCode': 'Z02', //ROL SUCURSAL POTENCIAL
      "CustomerABCClassificationCode": "8", //CLASIFICACION
      "LifeCycleStatusCode": "2", //ESTADO
      "Z_RUC_KUT": this.rucPermanente, //RUC
      "Z_CLASIFICACIONABC_KUT": "8", //CLASIFICACION
      "Z_FORMAJURIDICA_KUT": client.formaJuridica, //TIPO EMPRESA
      'Z_DENOMINACIONCOMERCIAL_KUT': client.denominacion, //DENOMICACION COMERCIAL
      "Z_TRNPROMEDIO_KUT": NumberUtils.changeSeparators(client.promedioPorVenta), //PROMEDIO POR VENTA
      "Z_MONTOFACTContent_KUT": NumberUtils.changeSeparators(client.promedioPorMes), //PROMEDIO POR MES
      "Z_MONTOFACTcurrencyCode_KUT": "PYG", //MONEDA PROMEDIO
      "Z_RUBRO_KUT": client.rubro.id,   //RUBRO  
      "Z_MCC_KUT": client.mcc, //MCC
      "Z_CANAL_KUT": origen, //CANAL
      "Z_TIPODECLIENTE_KUT": client.tipoCliente.id, //TIPO CLIENTE
      "Z_CASAMATRIZ_KUT": 'N', //MATRIZ NO
      "Z_ESTADOPROCESADOR_KUT": "I",
      "Z_ESTADOSWITCH_KUT": "I"
    };
    const nDirection = {
      "zAI_idBarrio_SDK": client.direccion.barrio,
      "zAI_idZona_SDK": client.direccion.zona,
      "zAI_idCiudad_SDK": client.direccion.ciudad
    }
    return this.createClient(origen, nSuc, nDirection, true)
  }

  async asociateBranchWithParent(parentID: string, branchID: string) {
    this.loadingMessage.next("Asociando sucursal con cliente principal");
    const data = {
      root: [{
        "AccountID": branchID,
        "ParentAccountID": parentID
      }]
    }
    const res = await this.ocservice.post(`/http/updateparentaccount`, data, true);
    return res.created;
  }

  /** Método para crear una oportunidad comercial con Onboarding digital */
  async createOCwithOnboarding(withAltaAgil?: boolean): Promise<void> {
    this.loading = true;
    const origen = "17_D"
    // valida los requisitos necesarios
    if (
      (!this.clientToCreate || await this.createClient(origen, this.clientToCreate, this.clientDirectionToCreate)) &&
      (!this.contactToCreate || await this.createContact(origen, false)) &&
      (!this.contactPEPToCreate || await this.createContact(origen, true)) &&
      await this.validateContactAssociation()
    ) {
      // prepara adjuntos a cargar
      this.prepareFiles();
      // obtiene la lista de productos con datos para onboarding
      const products = await this.prepareProducts(withAltaAgil);
      // verifica si existe el producto VPOS entre la lista de productos seleecionados
      const hasVPOS = products.find(product => { return product.ProductID == 'P7' });
      // Si no existe, o si ha sido seleccionado solo un producto envia la oc
      if (!hasVPOS || (products.length == 1 && this.selectedClient?.casaMatriz == 'N')) {
        const res1 = await this.sendOnboarding(this.selectedClient!.idCliente, products);
        
        if (res1.created) {
          // extrae el cuerpo de la respuesta
          const body = res1.body!;
          const data = body.data.body;

          // extrae el id de la oc creada
          this.ocId = data.d.results.ID;
          let filesUploaded = false;
          let checked = false;
          if (this.filesToUpload.length > 0) {
            filesUploaded = await this.uploadFiles(this.ocId, this.selectedClient!.idCliente);
            checked = true;
          } else {
            filesUploaded = true;
            this.loadingMessage.next("Comprobando adjuntos");
            // prepara datos para comprobar adjuntos
            const checkAdjunto = {
              'ocId': this.ocId,
              'checkAdjunto': true,
              'enabled': true
            }
            // llama al servicio y espera el resultado
            const r = await this.mongoSrv.create('/checkAdjunto', checkAdjunto);
            checked = r.posted;
          }

          if (filesUploaded && checked) {
            this.toastr.success(Mensajes.ONBOARDING_SUCCESS, '', { positionClass: 'toast-top-center', progressBar: false });
            if (this.hasPermissionForAltaAgil && this.checkAltaAgil()) {
              await this.ocservice.updateOC(data.d.results.ObjectID, { ExternalUserStatusCode: 'Z10' })
            }
          } else {
            if (this.hasPermissionForAltaAgil && this.checkAltaAgil()) {
              this.toastr.warning(Mensajes.OC_AA_ERROR_AL_CARGR_ARCHIVOS, '', { positionClass: 'toast-top-center', progressBar: false });
            }
          }
          if (this.addNota) {
            const Ncomentario = this.notaConsultaConfirmacion
            const comentario = {
              OpportunityID: data.d.results.ID,
              TypeCode: "10001",
              Text: Ncomentario,
              ParentObjectID: data.d.results.ObjectID
            };

            await this.comentarioService.nuevoComentarioOC(comentario);

          }
          setTimeout(() => {
            this.loading = false;
            this.router.navigate(['oportunidadcomercial', this.ocId, 'detalle']);
          }, 1000)
        } else {
          this.toastr.error(Mensajes.ONBOARDING_ERROR, '', { positionClass: 'toast-top-center', progressBar: false });
          this.loading = false;
        }
      } else if (hasVPOS) {
        let oc1ID = "";
        let oc2ID = "";
        // extrae productos que no sean VPOS
        const productsWithoutVPOS = products.filter(product => { return product.ProductID != 'P7' });
        let oc1Created = false;
        if (productsWithoutVPOS) {
          // envia OC sin VPOS
          const res2 = await this.sendOnboarding(this.selectedClient!.idCliente, productsWithoutVPOS);
          if (res2.created) {
            // extrae el cuerpo de la respuesta
            const body = res2.body!;
            const data = body.data.body;
            // extrae el id de la oc creada
            oc1ID = data.d.results.ID;
            let filesUploaded = false;
            let checked = false;
            if (this.filesToUpload.length > 0) {
              filesUploaded = await this.uploadFiles(oc1ID, this.selectedClient!.idCliente);
              checked = true;
            } else {
              filesUploaded = true;
              this.loadingMessage.next("Comprobando adjuntos");
              // prepara datos para comprobar adjuntos
              const checkAdjunto = {
                'ocId': oc1ID,
                'checkAdjunto': true,
                'enabled': true
              }
              // llama al servicio y espera el resultado
              const r = await this.mongoSrv.create('api/checkAdjunto', checkAdjunto);
              checked = r.posted;
            }
            oc1Created = filesUploaded && checked
          }
        }
        if (oc1Created) {
          this.loadingMessage.next("Procesando");
          const t = this.clientToCreate ? 5000 : 0;
          setTimeout(async () => {
            // crea sucursal nueva y comprueba si se creo correctamente
            const sucCreated = await this.createSuc(origen, this.selectedClient!);
            if (sucCreated) {
              // envía oc con datos para VPOS
              const res3 = await this.sendOnboarding(this.selectedClientSUC!.idCliente, [hasVPOS], {newBranch: true});
              // confirma que se hayan creado ambas oc
              if (res3.created) {
                // extrae el cuerpo de la respuesta
                const body = res3.body!;
                const data = body.data.body;
                // extrae el id de la oc creada
                oc2ID = data.d.results.ID;
                let filesUploaded = false;
                let checked = false;
                if (this.filesToUpload.length > 0) {
                  filesUploaded = await this.uploadFiles(oc2ID, this.selectedClient!.idCliente);
                  checked = true;
                } else {
                  filesUploaded = true;
                  this.loadingMessage.next("Comprobando adjuntos");
                  // prepara datos para comprobar adjuntos
                  const checkAdjunto = {
                    'ocId': oc2ID,
                    'checkAdjunto': true,
                    'enabled': true
                  }
                  // llama al servicio y espera el resultado
                  const r = await this.mongoSrv.create('api/checkAdjunto', checkAdjunto);
                  checked = r.posted;
                }
                // si se realiza correctamente navega al detalle de la oc, sino, muestra mensaje de error
                if (filesUploaded && checked) {
                  this.loading = false;
                  this.toastr.success(Mensajes.ONBOARDING_SUCCESS, '', { positionClass: 'toast-top-center', progressBar: false });
                }
                setTimeout(() => {
                  const oc1 = `oportunidadcomercial/${oc1ID}/detalle`;
                  window.open(oc1, "_blank");
                  const oc2 = `oportunidadcomercial/${oc2ID}/detalle`;
                  window.open(oc2, "_blank");
                }, 1000)
              } else {
                this.toastr.error(Mensajes.ONBOARDING_ERROR, '', { positionClass: 'toast-top-center', progressBar: false });
                this.loading = false;
              }
            }
          }, t)
        } else {
          this.toastr.error(Mensajes.ONBOARDING_ERROR, '', { positionClass: 'toast-top-center', progressBar: false });
          this.loading = false;
        }
      }
    } else {
      this.loading = false;
      this.toastr.error(Mensajes.ONBOARDING_ERROR, '', { positionClass: 'toast-top-center', progressBar: false });
    }
  }

  /** Método para crear una oc de onboarding
   * @param clientID id del cliente
   * @param products array con productos a asociar
   * @param flow opcional, flujos a tener en cuenta para la creación
    * @option nSuc si la oc es para una nueva sucursal
    * @option altaAgil si la oc es para alta agil
   * @returns TRUE cuando la oc se crea correctamente sino FALSE
   */
  async sendOnboarding(clientID: string, products: any[], flow?: {newBranch?: boolean, altaAgil?: boolean} ) {
    this.loadingMessage.next(`Creando oportunidad comercial ${flow?.newBranch ? 'sucursal' : ''}`);

    const facturationAccount = await this.prepareFacturationAccounts(products);
    const req: any = {
      "ProspectPartyID": clientID,
      "Z_CANAL_KUT": "17_D",
      "Z_FDVENTAS_KUT": this.usersSrv.getActualUser().entidad?.fuerzaDeVenta,
      "Z_EMPRESA_KUT": "1",
      "Name": this.formNewOc.value.nombre,
      "OpportunityItem": { "results": products },
      "ExternalUserStatusCode": flow?.altaAgil ? 'Z01' : 'Z10',
      "OpportunitySalesTeamParty": {
        "results": [
          {
            "PartyID": environment.PartyID,
            "RoleCategoryCode": "71",
            "RoleCode": "71"
          }
        ]
      },
      "OpportunityProspectPartyContactParty": {
        "results": [
          {
            "OpportunityPartyContactPartyID": this.selectedContact!.idContacto,
            "Z_CONTACTOOC__KUT": "1",
            "Z_OP_TipoCliente_KUT": "101"
          }
        ]
      },
      "Opportunity_nodoCuentasBancarias_SDK": facturationAccount,
      "ProcessingTypeCode": flow?.newBranch ? 'Z02' : this.formNewOc.value.tipoOC,
      "SalesCycleCode": flow?.newBranch ? 'Z02' : this.formNewOc.value.tipoOC,
      "SalesTerritoryID": environment.TERRITORY,
      "PriorityCode": "3",
      "LifeCycleStatusCode": "1",
      "GroupCode": "",
      "DistributionChannelCode": "00",
      "DivisionCode": "00",
      "SalesUnitPartyID": "SO_1010",
      "SalesOrganisationID": "SO_1010",
      "Z_ENTIDAD_KUT": this.usersSrv.getActualUser().entidad?.codigoEntidad,
      "Z_ENTIDAD_PORTAL_KUT": this.usersSrv.getActualUser().entidad?.nombre,
      "Z_OCCREADOPOR_KUT": this.usersSrv.getActualUser().email,
      "MainEmployeeResponsiblePartyID": this.selectedMainEmployeeResponsible,
      "Z_Deposito_KUT": await this.getDeposito(),
      "Z_EquipoPreactivadoOC_KUT": this.hasPermissionForAltaAgil && this.checkAltaAgil(),
      "Z_AltaAgilPortalEntidades_KUT": this.hasPermissionForAltaAgil && this.checkAltaAgil()
    }
    if (this.tieneContrato) {
      req.Z_Contrato_KUT = '01'
    } else {
      req.Z_Contrato_KUT = '02'
    } 
    req.Z_RequiereConfirmacion_KUT  = this.requiereConfirmacion;
    req.Z_CuentaConfirmada_KUT = this.cuentaConfirmada
    
    const res = await this.ocservice.createOC(req);
    return res;
  }

  /** Método para validar los campos de oportunidad comercial
   * @returns TRUE cuando todos los campos estan compconstados correstamente, sino FALSE
   */
  async validateData() {
    // comprueba formulario OC
    const formValid = !this.formNewOc.invalid;
    // comprueba si existe al menos un producto seleccionado
    const hasProducts = this.productList.length > 0;
    // comprueba si existe al menos una cuenta bancaria seleccionada
    const hasBankAccounts = this.bankAccountTD != undefined;
    // comprueba si los productos tienen los datos correctos
    const productsValid = this.validateProducts();
    // compruebas adjuntos obligatorios de unipersonal
    const attachmentValid = this.validateAttachments();
    // compruebas adjuntos obligatorios de unipersonal
    const spiValid = !this.tieneProductosSPI || (this.tieneProductosSPI && (this.bankAccountSPI ? true : false))

    // Muestra mensaje si alguna de las validaciones falla
    if (!formValid) {
      const campos = this.validateAllFields(this.formNewOc).join();
      this.toastr.error(Mensajes.DATOS_INCOMPLETOS + campos, '', { positionClass: 'toast-top-center', progressBar: false });
    } else if (!hasProducts) {
      this.toastr.error(Mensajes.OC_SIN_PRODUCTOS, '', { positionClass: 'toast-top-center', progressBar: false });
    } else if (!productsValid) {
      this.toastr.error(Mensajes.OC_PRODUCTOS_SIN_COMPLETAR, '', { positionClass: 'toast-top-center', progressBar: false });
    } else if (!hasBankAccounts) {
      this.toastr.error(Mensajes.OC_SIN_CUENTAS, '', { positionClass: 'toast-top-center', progressBar: false });
    } else if (!attachmentValid) {
      this.toastr.error(Mensajes.ADJUNTOS_INCOMPLETOS, '', { positionClass: 'toast-top-center', progressBar: false });
    } else if (!spiValid) {
      this.toastr.error("Debe agregar una cuenta real para SPI", '', { positionClass: 'toast-top-center', progressBar: false });
    }
    // devuelve resultado
    return formValid && hasProducts && hasBankAccounts && productsValid && attachmentValid && spiValid
  }

  /** metodo para confirmar la cuenta TD agregada, tambien actualiza los de Cuentas SPI*/
  async validateAccountConfirmation() {
    if (this.bankAccountTD) {
      this.loadingMessage.next('Confirmando cuenta')
      this.loading = true;
      const accountData = await this.crmSrv.accountConfirmation(this.bankAccountTD.tipo.id.toString(),
        this.bankAccountTD.numero, this.bankAccountTD.tipoDocumento ? this.bankAccountTD.tipoDocumento : '',
        this.bankAccountTD.numeroDocumento ? this.bankAccountTD.numeroDocumento : '', this.bankAccountTD.titular);
      this.addNota = true;
      this.notaConsultaConfirmacion = ``

      if (accountData && accountData.body && accountData.body.data && accountData.body.data.DatosCuenta) {
        this.datosCuenta = accountData.body.data.DatosCuenta;
        if (this.datosCuenta && this.datosCuenta.CodRespuesta == '00' && this.datosCuenta.Mensaje == 'OK') {

          this.notaConsultaConfirmacion = `SOLICITUD DE CONFIRMACIÓN DE CUENTA
Fecha de petición: ${dateConvertToGrid(new Date())}
Solicitado por: ${this.usersSrv.getActualUser().email}
Propietario: ${this.usersSrv.getActualUser().nombre}
Nro. de Cuenta Bancaria: ${this.bankAccountTD.numero}
Entidad: ${this.usersSrv.getActualUser().entidad.nombre}

RESPUESTA DE LA ENTIDAD:
Titular de la Cta.: ${this.datosCuenta.NombreTitular}
Nro. de Cuenta Confirmada: ${this.datosCuenta.NroCuentaBancard}
Nro. de Cuenta Confirmada SPI: ${this.datosCuenta.NroCuentaSPI}
Mensaje: ${this.datosCuenta.Mensaje}`

          // Como la entidad cuenta con confirmación de cuenta, el checklist de onboarding (AccountValidated) 
          // se completa una vez que se confirma la cuenta ya que la validación de cuentas se omite.
          this.onboardingCheckList.find(check => {
            return check.check == 'accountValidated';
          })!.value = true;

          if (this.bankAccountTD) {
            this.bankAccountTD.titular = this.datosCuenta.NombreTitular;
            this.bankAccountTD.validado = true;
            this.bankAccountTD.numero = this.datosCuenta.NroCuentaBancard;
            this.bankAccountTD.esTitular = true        
          }

          if (this.bankAccountSPI) {
            this.bankAccountSPI.numero = this.datosCuenta.NroCuentaSPI;
            this.bankAccountSPI.titular = this.datosCuenta.NombreTitular
            this.bankAccountSPI.esTitular = true
          }

          // guarda datos adicionales
          this.accountOwner = this.datosCuenta.NombreTitular;
          this.accountDocumentType = this.bankAccountTD.tipoDocumento;
          this.accountDocumentNumber = this.bankAccountTD.numeroDocumento;

          this.cuentaConfirmada = true;
        } else {
          this.notaConsultaConfirmacion = `SOLICITUD DE CONFIRMACIÓN DE CUENTA
Fecha de petición: ${dateConvertToGrid(new Date())}
Solicitado por: ${this.usersSrv.getActualUser().email}
Propietario: ${this.usersSrv.getActualUser().nombre}
Nro. de Cuenta Bancaria: ${this.bankAccountTD.numero}
Entidad: ${this.usersSrv.getActualUser().entidad.nombre}

RESPUESTA DE LA ENTIDAD:
Mensaje: ${this.datosCuenta!.Mensaje}`
          this.cuentaConfirmada = false;
        }
      } else {
        this.notaConsultaConfirmacion = `SOLICITUD DE CONFIRMACIÓN DE CUENTA
Fecha de petición: ${dateConvertToGrid(new Date())}
Solicitado por: ${this.usersSrv.getActualUser().email}
Propietario: ${this.usersSrv.getActualUser().nombre}
Nro. de Cuenta Bancaria: ${this.bankAccountTD.numero}
Entidad: ${this.usersSrv.getActualUser().entidad.nombre}

RESPUESTA DE LA ENTIDAD:
Mensaje: ${this.datosCuenta!.Mensaje}`
        this.cuentaConfirmada = false;
      }
      this.loading = false;
    }
  }

  async validatePosEquipment() {
    this.loadingMessage.next("Verificando equipos seleccionados");
    this.loading = true;
    let invalid = false;
    let depositoUnique = true;


    const seriesNoDisponibles: string[] = [];
    if (this.equiposPOSList.length > 0) {
      const deposito = this.equiposPOSList[0].deposito;
      this.equiposPOSList.forEach(equipo => {
        if (equipo.deposito != deposito) {
          depositoUnique = false;
        }
      });

      if (!depositoUnique) {
        this.toastr.error(`Los equipos deben pertenecer a un único depósito`, '', { positionClass: 'toast-top-center', progressBar: false });
        this.loading = false;
        return false
      }

      const series: string = this.equiposPOSList.map(equipo => equipo.serie).join(",")
      const params: Param[] = [{ key: 'series', value: series }]
      const res = await this.mongoSrv.get(`/equipos-pos/disponibilidad`, params);
      if (res) {
        res.forEach((equipo: any) => {
          if (equipo.estado != 'Disponible') {
            invalid = true;
            seriesNoDisponibles.push(equipo.serie)
          }
        })
      }
    }
    if (invalid) {
      let mensaje = ''
      if (seriesNoDisponibles.length > 1) {
        mensaje = `Los equipos POS con series ${seriesNoDisponibles.join(", ")} no están disponibles`
      } else {
        mensaje = `El equipo POS con serie ${seriesNoDisponibles[0]} no está disponible`
      }
      this.toastr.error(mensaje, '', { positionClass: 'toast-top-center', progressBar: false });
      this.loading = false;
      return false
    } else {
      this.loading = false;
      return true;
    }
  }

  /** Método para comprobar que los adjuntos obligatorios hayan sido cargados */
  validateAttachments() {
    if (
      this.isUnipersonal &&
      (!this.file_ci_f || !this.file_ci_d || !this.file_ruc || (this.isUnipersonal && this.tieneContrato && !this.file_contrato))) {
      return false;
    } else {
      return true;
    }
  }

  /** Método para validar la los productos */
  validateProducts(): boolean {
    let cantidadProductosValidados = 0;
    this.productList.forEach(producto => {
      if (producto.costo.id >= 0 && producto.cantidad != "") {
        cantidadProductosValidados++;
      }
    })
    return cantidadProductosValidados == this.productList.length;
  }

  /** Método para signar productos de onboarding */
  setProductsForOnboarding(forOnboarding) {
    this.productsForOnboarding = forOnboarding;
  }

  /** Método para abrir un modal
   * @param content modal a abrir
   * @param size tamaño de la ventana modal
   * @param clientType tipo de cliente a mostrar (sólo para creacion de cliente)
   */
  async open(content: any, size: "md" | "lg" | "xl", clientType?: "nuevoClienteComercio" | "nuevoClientePersona" | "nuevaSucursal" | "actualizarClientePotencial" | "actualizarSucursalPotencial") {
    switch (content._declarationTContainer.localNames[0]) {
      case 'formularioClienteModal':
        this.newClientForm.reset();
        this.newClientForm.get('pais')?.patchValue('PY');
        this.newClientForm.get('ruc')?.patchValue(this.rucPermanente);
        if (clientType && ['actualizarClientePotencial', 'actualizarSucursalPotencial'].includes(clientType) && this.selectedClientToEdit) {
          const esSuc = clientType == 'actualizarSucursalPotencial';
          let matriz = !esSuc ? this.selectedClientToEdit : this.clients.find(client => { return client.casaMatriz == 'S' })!;
          if (!matriz) {
            this.toastr.warning("No existe sucursal matriz del cliente seleccionado")
            matriz = this.clients[0];
          }
          this.newClientForm.get('razonSocial')?.setValue(this.selectedClientToEdit.razonSocial);
          this.newClientForm.get('denominacionComercial')?.setValue(this.selectedClientToEdit.denominacion);
          this.newClientForm.get('rol')?.patchValue(esSuc ? 'Z02' : 'BUP002');
          this.newClientForm.get('casaMatriz')?.patchValue(esSuc ? 'N' : 'S');
          const tCliente = this.selectedClientToEdit.tipoCliente ? this.selectedClientToEdit.tipoCliente.id! : matriz.tipoCliente.id;
          this.newClientForm.get('tipoCliente')?.setValue(tCliente == 'ZFAC' ? 'ZCOM' : tCliente);
          if (tCliente == 'ZPER') {
            this.newClientForm.get('tipoEmpresa')?.patchValue('1');
          }
          this.newClientForm.get('rubro')?.setValue(this.selectedClientToEdit.rubro.id);
          await this.updateMCCs(Number(this.selectedClientToEdit.rubro.id));
          this.newClientForm.get('mcc')?.setValue(this.selectedClientToEdit.mcc);
          this.newClientForm.get('pais')?.setValue(this.selectedClientToEdit.direccion.pais);
          this.newClientForm.get('departamento')?.setValue(this.selectedClientToEdit.direccion.departamento);
          await this.getCities(Number(this.selectedClientToEdit.direccion.departamento));
          this.newClientForm.get('calle')?.setValue(this.selectedClientToEdit.direccion.calle);
          this.newClientForm.get('numeroCalle')?.setValue(this.selectedClientToEdit.direccion.numeroCalle);
          this.newClientForm.get('codigoPostal')?.setValue(this.selectedClientToEdit.direccion.codigoPostal);
          this.newClientForm.get('ciudad')?.setValue(this.selectedClientToEdit.direccion.ciudad);
          await this.getNeighborhoods(this.selectedClientToEdit.direccion.ciudad!);
          await this.getZones(this.selectedClientToEdit.direccion.ciudad!);
          this.newClientForm.get('barrio')?.setValue(this.selectedClientToEdit.direccion.barrio);
          this.newClientForm.get('zona')?.setValue(this.selectedClientToEdit.direccion.zona);
          this.newClientForm.get('telefono')?.setValue(this.selectedClientToEdit.telefono);
          this.newClientForm.get('telefonoMovil')?.setValue(this.selectedClientToEdit.celular);
          this.newClientForm.get('mail')?.setValue(this.selectedClientToEdit.email);
          this.newClientForm.get('promedioTickets')?.setValue(transformToNumber(this.selectedClientToEdit.promedioPorVenta, 'en-EN', 'es-PY', '1.0-0'));
          this.newClientForm.get('montoPromedio')?.setValue(transformToNumber(this.selectedClientToEdit.promedioPorMes, 'en-EN', 'es-PY', '1.0-0'));
          this.parentID = matriz.idCliente;
        } else if (clientType && clientType == 'nuevaSucursal') {
          let matriz = this.clients.find(client => { return client.tipoCliente.id == 'CRM000' })!;
          if (!matriz) {
            matriz = this.clients[0];
          }
          this.newClientForm.get('razonSocial')?.setValue(matriz.razonSocial);
          this.newClientForm.get('rol')?.patchValue('Z02');
          this.newClientForm.get('casaMatriz')?.patchValue('N');
          switch(matriz.tipoCliente.id) {
            case 'ZCOM':
              this.newClientForm.get('tipoCliente')?.setValue('ZCOM');
              break;
            case 'ZPER':
              this.newClientForm.get('tipoCliente')?.setValue('ZPER');
              this.newClientForm.get('tipoEmpresa')?.patchValue('1');
              break;
            default:
              this.disableClientTypeSelection = false;
              break;
          }
          this.parentID = matriz.idCliente;
        } else {
          this.newClientForm.get('rol')?.patchValue('BUP002');
          this.newClientForm.get('casaMatriz')?.patchValue('S');
          if (clientType == 'nuevoClienteComercio') {
            this.newClientForm.get('tipoCliente')?.patchValue('ZCOM');
          } else {
            this.newClientForm.get('tipoCliente')?.patchValue('ZPER');
            this.newClientForm.get('tipoEmpresa')?.patchValue('1');
          }
        }
        break;
      case 'formularioContactoModal':
        this.newContactForm.reset();
        if (this.creatingPEPContact) {
          this.newContactForm.get('esPEP')?.patchValue('S');
          this.newContactForm.get('esPEP')?.disable();
          this.newContactForm.controls.cargo.addValidators(Validators.required);
        } else {
          const defaultEmail = this.contactForm.get('email')?.value.toLowerCase();
          this.newContactForm.get('email')?.patchValue(defaultEmail);
          this.newContactForm.get('esPEP')?.patchValue('N');
          this.newContactForm.get('esPEP')?.enable();
          this.newContactForm.controls.cargo.removeValidators(Validators.required);
        }
        this.newContactForm.controls.cedula.updateValueAndValidity();
        this.newContactForm.controls.cargo.updateValueAndValidity();
        break;
      default:
        break;
    }
    this.modalSrv.open(content, { size: size, centered: true, backdropClass: 'modal-backdrop' });
    if (content._declarationTContainer.localNames[0] == 'mapa') {
      this.openMap();
    }
  }

  /** Método para comprobar si un RUC o CI tiene clientes asociados
   * @param clientesAsociados modal de clientes asociados
   */
  async searchClients(clientesAsociados: any, sinclientesAsociados: any) {
    this.isNewClient = false;
    if (!this.rucForm.get("ruc")?.invalid) {
      const ruc = this.rucForm.value.ruc;
      this.rucForm.get("ruc")?.reset();
      let nruc = "";
      if (ruc.includes("-") && !this.checkDigit(ruc)) {
        this.toastr.error(Mensajes.RUC_INVALIDO, "", {
          positionClass: "toast-top-center",
          progressBar: false,
        });
        return;
      } else if (!ruc.includes("-")) {
        nruc = this.updateRUC(ruc);
      } else {
        nruc = ruc;
      }
      this.rucPermanente = nruc;
      this.loading = true;
      this.loadingMessage.next("Buscando clientes");
      this.clients = [];
      await this.dataSrv.getClientsByRuc(nruc).then(async clients => {
        this.auditSrv.logAction({ modulo: 'OC', accion: 'Buscar cliente por ruc', detalle: nruc });
        this.clients = clients;
        this.disableSucursalButton = this.clients.find(client => { return ['BUP002', 'Z02'].includes(client.rol.id.toString()) }) != undefined;
        this.collectionSize = this.clients.length;
        if (this.collectionSize > 0) {
          const ids: string[] = [];
          this.clients.forEach((client) => {
            ids.push(client.idCliente);
          });
          const res = await this.ocservice.checkOcByIDs(ids);
          if (res) {
            const name = clients[0].razonSocial;
            this.toastr.warning("El cliente " + name + " ya tiene una OC en proceso", "", {
              positionClass: "toast-top-center",
              progressBar: false,
            });
            this.clients = [];
            this.collectionSize = 0;
            this.loading = false;
            return;
          } else {
            this.open(clientesAsociados, "xl");
          }
        } else {
          this.isNewClient = true;
          this.open(sinclientesAsociados, "lg");
        }
      });

      this.loading = false;
    }
  }

  /** Método para comprobar el dígito verificador
   * @param ruc ruc a comprobar
   * @return TRUE si el ruc es correcto sino FALSE
   */
  checkDigit(ruc: any) {
    if (ruc.includes("-")) {
      const _dni: any[] = [];
      const dni = ruc.split("-")[0];
      const digitoVerificador = parseInt(ruc.split("-")[1]);
      let multiplicador = 2;
      let sumador = 0;
      let indice = 0;
      let resto = 0;
      for (let i = 0; i < dni.length; i++) {
        _dni.push(dni[i]);
      }
      _dni.reverse().forEach((digito) => {
        indice++;
        if (indice >= 11) {
          multiplicador = 2;
          indice = 0;
        }
        sumador += digito * multiplicador;
        multiplicador += 1;
      });
      resto = sumador % 11;
      if (resto > 1 && 11 - resto == digitoVerificador) {
        return true;
      } else if (resto <= 1 && digitoVerificador == 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /** Método para agregar numero verificador a un ci
   * @param ci ci a agregar digito verificador
   * @return TRUE si el ruc es correcto sino FALSE
   */
  updateRUC(ci: any) {
    if (!ci.includes("-")) {
      let _ci: any[] = [];
      let multiplicador = 2;
      let sumador = 0;
      let indice = 0;
      let resto = 0;
      _ci = Array.from(ci);
      _ci.reverse().forEach((digito) => {
        indice++;
        if (indice >= 11) {
          multiplicador = 2;
          indice = 0;
        }
        sumador += digito * multiplicador;
        multiplicador += 1;
      });
      resto = sumador % 11;
      const digitoVerificador = resto > 1 ? 11 - resto : 0;
      const nci = `${ci}-${digitoVerificador}`;
      return nci;
    } else {
      return ci;
    }
  }

  /** Método para seleccional un nuevo cliente
   * @param cliente Cliente seleccionado
   */
  selectClient(cliente: Cliente) {
    this.editClient = false;
    if (cliente.rol.id == 'Z02') {
      this.editClient = true;
      this.selectedClientToEdit = cliente;
      this.open(this.nuevoClienteModal, "xl", "actualizarSucursalPotencial")
      this.formNewOc.get('tipoOC')?.patchValue('Z01');
    } else if (cliente.rol.id == 'BUP002') {
      this.editClient = true;
      this.selectedClientToEdit = cliente;
      this.open(this.nuevoClienteModal, "xl", "actualizarClientePotencial")
      this.formNewOc.get('tipoOC')?.patchValue('Z01');
    } else if (['CRM000', 'Z01', 'Z02'].includes(cliente.rol.id.toString())) {
      this.selectedClient = cliente;
      this.checkClientTypeInfo();
      this.formNewOc.get('cliente')?.patchValue(cliente.denominacion);
      this.formNewOc.get('ruc')?.patchValue(cliente.ruc);
      this.formNewOc.get('razonSocial')?.patchValue(cliente.denominacion);
      this.formNewOc.get('tipoEmpresa')?.patchValue(cliente.tipoEmpresa.descripcion);
      this.checkOnboarding({allowWarnings: true});
      this.formNewOc.get('tipoOC')?.patchValue('Z02');
    }
  }

  /** Método para guardar datos de un cliente nuevo a crear */
  confirmNewCliente() {
    // comprueba si los datos del formulario están compconstos y correctos
    if (this.newClientForm.invalid) {
      this.loading = false;
      this.validateAllFields(this.newClientForm);
      this.toastr.error(Mensajes.DATOS_INCOMPLETOS, this.newClientForm.getRawValue(), { positionClass: 'toast-top-center', progressBar: false });
      return
    } else {
      // obtiene datos de tipo de empresa
      const bt = this.businessTypes.find(bt => { return bt.id == this.newClientForm.value.tipoEmpresa });
      // prepara datos de cliente a crear
      this.clientToCreate = {
        "Name": simplifyText(this.newClientForm.value.razonSocial).toUpperCase(), //RAZON SOCIAL
        "LegalFormCode": bt!.C4C_ID, //TIPO EMPRESA
        "CountryCode": this.newClientForm.value.pais, //PAIS
        "StateCode": this.newClientForm.value.departamento, //DEPARTAMENTO
        "AddressLine1": simplifyText(this.newClientForm.value.calle), //CALLE
        "HouseNumber": this.newClientForm.value.numeroCalle, //NUMEROCALLE
        "Street": simplifyText(this.newClientForm.value.calle), //CALLE
        "StreetPostalCode": this.newClientForm.value.codigoPostal, //CODIGO POSTAL
        "District": this.newClientForm.value.zona, //ZONA
        "City": this.newClientForm.value.ciudad, //CIUDAD
        "LanguageCode": "ES", //IDIOMA
        "Email": this.newClientForm.value.mail.toLowerCase(), //CORREO ELECTRÓNICO
        "Phone": this.newClientForm.value.telefono, //TELEFONO
        "Mobile": this.newClientForm.value.telefonoMovil, //CELULAR
        "WebSite": this.newClientForm.value.web, //SITIO WEB
        'RoleCode': this.newClientForm.value.rol, //ROL
        "CustomerABCClassificationCode": "8", //CLASIFICACION
        "LifeCycleStatusCode": "2", //ESTADO
        "Z_RUC_KUT": this.rucPermanente, //RUC
        "Z_CLASIFICACIONABC_KUT": "8", //CLASIFICACION
        "Z_FORMAJURIDICA_KUT": bt!.id.toString(), // TIPOEMPRESA - FORMA JURIDICA
        'Z_DENOMINACIONCOMERCIAL_KUT': this.newClientForm.value.denominacionComercial.toUpperCase(), //DENOMICACION COMERCIAL
        "Z_TRNPROMEDIO_KUT": this.newClientForm.value.promedioTickets.replaceAll(".", ""), //PROMEDIO POR VENTA
        "Z_MONTOFACTContent_KUT": this.newClientForm.value.montoPromedio.replaceAll(".", ""), //PROMEDIO POR MES
        "Z_MONTOFACTcurrencyCode_KUT": "PYG", //MONEDA PROMEDIO
        "Z_RUBRO_KUT": this.newClientForm.value.rubro,   //RUBRO  
        "Z_MCC_KUT": this.newClientForm.value.mcc, //MCC
        "Z_CANAL_KUT": "17_D", //CANAL
        "Z_TIPODECLIENTE_KUT": this.newClientForm.value.tipoCliente, //TIPO CLIENTE
        "Z_CASAMATRIZ_KUT": this.newClientForm.value.casaMatriz, //MATRIZ
        "Z_ESTADOPROCESADOR_KUT": "I",
        "Z_ESTADOSWITCH_KUT": "I"
      }
      // prepara datos de dirección del cliente a crear
      this.clientDirectionToCreate = {
        "zAI_idBarrio_SDK": this.newClientForm.value.barrio,
        "zAI_idZona_SDK": this.newClientForm.value.zona,
        "zAI_idCiudad_SDK": this.newClientForm.value.ciudad,
        "Latitude": this.newClientForm.value.latitud.toString(),
        "Longitude": this.newClientForm.value.longitud.toString()
      }
      this.checkClientTypeInfo();
      //actualiza el formulario de OC
      this.formNewOc.get('cliente')?.patchValue(this.newClientForm.value.denominacionComercial.toUpperCase());
      this.formNewOc.get('ruc')?.patchValue(this.rucPermanente);
      this.formNewOc.get('razonSocial')?.patchValue(this.newClientForm.value.razonSocial.toUpperCase());
      this.formNewOc.get('tipoEmpresa')?.patchValue(bt?.descripcion);
      if (this.newClientForm.value.rol == 'BUP002') {
        this.formNewOc.get('tipoOC')?.patchValue('Z01');
      } else if (['CRM000', 'Z01', 'Z02'].includes(this.newClientForm.value.rol)) {
        this.formNewOc.get('tipoOC')?.patchValue('Z02');
      }
      // comprueba datos de onboarding
      this.checkOnboarding({allowWarnings: true});
      // reinicia formulario cliente
      this.newClientForm.reset();
      // cierra los modales
      this.modalSrv.dismissAll();
    }
  }

  /** Método para crear un nuevo cliente 
   * @param origen Origen de la creación del cliente
   * @param client Datos del cliente a crear
   * @param direction Dirección del cliente a crear
   * @param isSUC TRUE si es una sucursal, FALSE si es un cliente normal
   * @returns TRUE si el cliente fue creado exitoamente, sino FALSE
  */
  async createClient(origen: string, client: any, direction: any, isSUC?: boolean): Promise<boolean> {
    try {
      this.loadingMessage.next(`${this.editClient ? 'Editando' : 'Creando'} cliente ${isSUC ? 'sucursal' : ''}`);
      // ajusta el origen
      client['Z_CANAL_KUT'] = origen;
      // obtiene oid de cliente a modificar si existiere
      const oid = this.selectedClientToEdit ? this.selectedClientToEdit.OID! : '';
      // llama al servicio con los datos del cliente a crear, se espera el resultado
      const res = this.editClient ? await this.ocservice.actualizarCliente(oid, client) : await this.ocservice.post(`/http/clienteportal`, client);

      if (res.created) {

        const nClient = res.body as Cliente;
        this.auditSrv.logAction({ modulo: 'OC', accion: this.editClient ? 'Modificar Cliente' : 'Crear cliente', detalle: `ID: ${client.AccountID}` });
        // instancia cliente con interfaz local

        this.loadingMessage.next('Actualizando dirección');
        // llama al servicio para obtener dirección del cliente, epsera el resultado

        const data = await this.dataSrv.getClientAddress(nClient.OID!);
        if (data) {

          // extrae OID del resultado
          const addressOID = data[0].ObjectID;
          // prepara datos de dirección a corregir
          const addressReq = {
            "d": direction
          }
          // llama al servicio para actualizar la dirección

          const patched = await this.dataSrv.updateClientAddress(addressOID, addressReq);
          if (patched.updated) {

            this.auditSrv.logAction({ modulo: 'OC', accion: 'Actualizar dirección de cliente', detalle: `ID: ${nClient.idCliente}, dirección: ${direction}` });
            nClient.direccion.zona = direction.zAI_idZona_SDK;
            nClient.direccion.ciudad = direction.zAI_idCiudad_SDK;
            nClient.direccion.barrio = direction.zAI_idBarrio_SDK;
            // actualiza el dato del cliente o sucursal seleccionado

            if (isSUC) {
              const associated = await this.asociateBranchWithParent(this.parentID!, nClient.idCliente);
              if (associated) {
                this.auditSrv.logAction({ modulo: 'OC', accion: 'Asociar a cliente padre', detalle: `cliente: ${nClient.idCliente}, padre: ${this.parentID}` });
                this.selectedClientSUC = nClient;
              }
            } else {
              this.selectedClient = nClient;
              this.parentID = nClient.idCliente;
            }
            return true;
          } else {

            this.toastr.error(Mensajes.ERROR_AL_ACTUALIZAR_DIRECCION_CLIENTE, '', { positionClass: 'toast-top-center', progressBar: false });
            this.loading = false;
            return false;
          }
        } else {

          this.toastr.error('Error al obtener datos de dirección', '', { positionClass: 'toast-top-center', progressBar: false });
          this.loading = false;
          return false;
        }
      } else {

        this.toastr.error(this.editClient ? Mensajes.ERROR_AL_MODIFICAR_CLIENTE : Mensajes.ERROR_AL_CREAR_CLIENTE, '', { positionClass: 'toast-top-center', progressBar: false });
        this.loading = false;
        return false;
      }
    } catch (error: any) {
      this.logSrv.sendErrorNotification(
        error.toString(), '', '', ''
      );
      this.toastr.error(Mensajes.ERROR_AL_CREAR_CLIENTE, '', { positionClass: 'toast-top-center', progressBar: false });
      this.loading = false;
      return false;
    }
  }

  /** Método para crear un nuevo contacto 
   * @param origen string, origen del contacto a crear
   * @param isPEP booleano, si el contanto a crear es PEP usar TRUE
  */
  async createContact(origen: string, isPEP: boolean): Promise<boolean> {
    this.loadingMessage.next(isPEP ? 'Creando contacto PEP' : 'Creando contacto');
    // obtiene dato del contacto a crear
    const toCreate = isPEP ? this.contactPEPToCreate : this.contactToCreate;
    // ajusta el origen
    toCreate['Z_CANAL_KUT'] = origen;
    // agrega dato del cliente
    toCreate['AccountID'] = this.selectedClient?.idCliente;
    // llama al servicio para crear el contacto, espera el resultado
    const res = await this.ocservice.post(`/http/contactoportal`, toCreate);
    if (res.created) {
      // obtiene contacto creado del body
      const nContact: Contacto = res.body! as Contacto;
      this.auditSrv.logAction({ modulo: 'OC', accion: 'Crear contacto', detalle: `ID: ${nContact.idContacto}, PEP: ${isPEP ? 'Sí' : 'No'}` });
      // actualiza contacto local
      if (isPEP) {
        this.selectPEPContact(nContact);
      } else {
        this.selectContact(nContact);
      }
      this.creatingPEPContact = false;
      return true;
    } else {
      this.loading = false;
      this.toastr.error(Mensajes.ERROR_AL_CREAR_CONTACTO, '', { positionClass: 'toast-top-center', progressBar: false });
      return false;
    }
  }

  /** Método para guardar datos de contacto a crear
   * @param isPEP boolean usar true si el contacto a agregar es PEP
   */
  async confirmNewContact(isPEP: boolean) {
    // comprueba el formulario de contacto
    if (this.newContactForm.invalid) {
      this.loading = false;
      this.validateAllFields(this.newContactForm);
      this.toastr.error(Mensajes.DATOS_INCOMPLETOS, '', { positionClass: 'toast-top-center', progressBar: false });
      return
    } else {
      // prepara nombre compconsto de contacto
      const fullName = `${this.newContactForm.value.nombre} ${this.newContactForm.value.apellido}`;
      // prepara datos de contacto a crear
      const nContact = {
        "FirstName": this.newContactForm.value.nombre.toUpperCase(),
        "LastName": this.newContactForm.value.apellido.toUpperCase(),
        "GenderCode": this.newContactForm.value.sexo,
        "Phone": this.newContactForm.value.telefono,
        "Mobile": this.newContactForm.value.telefonoMovil,
        "LanguageCode": "ES",
        "BirthDate": DateUtils.dateToEpoch(new Date(this.newContactForm.value.nacimiento), true),
        "Email": this.newContactForm.value.email.toLowerCase(),
        "Z_CEDULA_KUT": this.newContactForm.value.cedula
      }
      // comprueba si el contacto principal es PEP
      if (this.newContactForm.value.esPEP == 'S' || isPEP) {
        // Agrega campo requerido
        nContact['Z_ESPEP_KUT'] = 'S';
        nContact['Z_CARGOPEP_KUT'] = this.newContactForm.value.cargo;
      }
      // comprueba si el contacto a crear es PEP
      if (isPEP) {
        // guarda datos de contacto PEP a crear
        this.contactPEPToCreate = nContact;
        // actualiza campo del formulario OC
        this.formNewOc.get('contactoPEP')?.patchValue(fullName.toUpperCase());
      } else {

        // guarda datos de contacto a crear
        this.contactToCreate = nContact;
        // actualiza campo del formulario OC
        this.formNewOc.get('contactoPrincipal')?.patchValue(fullName.toUpperCase());
      }
      this.modalSrv.dismissAll();
      this.creatingPEPContact = false;
    }
  }

  /** Método para comprobar el email de contacto
   * <p>
   *  Se comprueba si ya existen contactos con el correo si existe muestra el formulario de contactos asociados
   *  si no existe muestra el formulario de nuevo contacto
   * </p>
   */
  async searchContacts() {
    // comprueba si el email  del formulario de contacto es correcto
    if (!this.contactForm.get('email')?.invalid) {
      // cierra los modales
      this.modalSrv.dismissAll();
      // guarda el email
      this.emailContactoPermanente = this.contactForm.value.email;
      this.loading = true;
      this.loadingMessage.next("Buscando contactos");
      // obtiene los contactos con el email
      const contacts = await this.contactSrv.getContactByEmail(this.contactForm.value.email, true);
      if (contacts && contacts instanceof Array) {
        this.auditSrv.logAction({ modulo: 'OC', accion: 'Obtener contacto por email', detalle: this.contactForm.value.email });
        if (contacts.length > 0) {
          this.contactoPrincipalList = contacts;
          this.open(this.contactosAsociadosModal, "xl");
        } else {
          this.toastr.warning(Mensajes.CONTACTO_PRINCIPAL_SIN_EMAIL, "", {
            positionClass: "toast-top-center",
            progressBar: true,
          });
          this.open(this.nuevoContactoModal, "xl");
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.contactoPrincipalList = [];
        this.toastr.error(Mensajes.ERROR_AL_VALIDAR_MAIL, "", {
          positionClass: "toast-top-center",
          progressBar: false,
        });
      }
    } else {
      this.contactForm.get('email')?.markAsTouched({ onlySelf: true });
    }
  }

  /** Método para seleccionar un contacto
   * @param contact contacto seleccionado
   */
  selectContact(contact: Contacto): void {
    this.formNewOc.get('contactoPrincipal')?.patchValue(contact.nombre);
    this.selectedContact = contact;
  }

  /** Método para seleccionar un contacto
   * @param contacto contacto seleccionado
   */
  selectPEPContact(contact: Contacto): void {
    this.formNewOc.get('contactoPEP')?.patchValue(contact.nombre);
    this.selectedPEPContact = contact;
  }

  /** Método para validar la asociación contacto/cliente
   * @returns TRUE cuando la validación se realiza correctamente, sino FALSE
   */
  async validateContactAssociation(): Promise<boolean> {
    this.loadingMessage.next('Comprobando asociación');
    // instancia variable
    let checked = false;
    // comprueba si ya existe asociación entre contacto y cliente
    const res = await this.contactSrv.checkContact(this.selectedClient!.idCliente, this.selectedContact!.idContacto);
    // comprueba si no hubo error en el servicio
    if (!res.error) {
      // comprueba si no existe asociación
      if (!res.asociacion) {
        // obtiene datos de cliente, contacto e email,
        const clientID = this.selectedClient!.idCliente;
        const contactID = this.selectedContact!.idContacto;
        const email = this.selectedContact!.email;
        this.loadingMessage.next('Creando asociación');
        // crea asociación y guarda resultado en variable
        checked = await this.contactSrv.associateContact(clientID, contactID, email, '1');
      } else if (res.asociacion && res.asociacion.rol != '1') {
        // si existe asociación pero el rol es diferente a "1-superadmin" se actualiza la asociación
        this.loadingMessage.next('Corrigiendo asociación');
        // actualiza y guarda el resultado en variable
        checked = await this.contactSrv.asignateRol(res.asociacion.OID, '1');
      } else {
        checked = true;
      }
    } else {
      checked = false;
      this.toastr.warning(Mensajes.ERROR_AL_COMPROBAR_ASOCIACION, '', { positionClass: 'toast-top-center', progressBar: true });
    }
    // comprueba si se realizo el paso anterior correctamente y si existe un contacto PEP seleccionado
    if (checked && this.selectedPEPContact) {
      this.loadingMessage.next('Comprobando asociación PEP');
      // comprueba si ya existe asociación entre contacto PEP y cliente
      const res2 = await this.contactSrv.checkContact(this.selectedClient!.idCliente, this.selectedPEPContact!.idContacto);
      // comprueba si no ubo error
      if (!res2.error) {
        // comprueba si no existe asociación
        if (!res2.asociacion) {
          // obtiene datos de cliente, contacto PEP e email
          const clientID = this.selectedClient!.idCliente;
          const contactID = this.selectedPEPContact!.idContacto;
          const email = this.selectedPEPContact!.email;
          this.loadingMessage.next('Creando asociación');
          // llama al servicio para asocial y guara resultado en variable
          checked = await this.contactSrv.associateContact(clientID, contactID, email);
        } else if (res2.asociacion && res2.asociacion.rol == '1') {
          // si existe asociación y el rol es igual "1-superadmin", se elimina el rol
          this.loadingMessage.next('Corrigiendo asociación');
          // actualiza el rol mediante servicio y guarda resultado en variable
          checked = await this.contactSrv.asignateRol(res2.asociacion.OID, '');
        } else {
          checked = true;
        }
      } else {
        checked = false;
        this.toastr.warning(Mensajes.ERROR_AL_COMPROBAR_ASOCIACION, '', { positionClass: 'toast-top-center', progressBar: true });
      }
    }
    // devuel variable
    return checked;
  }

  /** Método para asignar la lista de productos
   * @params products productos a asignar
   */
  async setProductList(products: Producto[]) {
    this.productList = products;
    this.checkOnboarding({allowWarnings: true});
    this.checkProductosSPI();
    this.checkAltaAgil();
  }

  async setEquiposPOS(equipos: EquiposPOS[]) {
    this.equiposPOSList = equipos;
  }
  /** Método para limpiar el formulario de cliente seleccionado */
  limpiarCliente(): void {
    this.selectedClient = undefined;
    this.selectedClientToEdit = undefined;
    this.selectedContact = undefined;
    this.formNewOc.get('cliente')?.setValue(undefined);
    this.formNewOc.get('ruc')?.setValue(undefined);
    this.formNewOc.get('razonSocial')?.setValue(undefined);
    this.formNewOc.get('contactoPrincipal')?.setValue(undefined)
    this.formNewOc.get('tipoOC')?.setValue(undefined);
    this.contactToCreate = undefined;
    this.contactPEPToCreate = undefined;
    this.clientToCreate = undefined;
    this.checkOnboarding({allowWarnings: true});
  }

  /** Método para limpiar el formulario de contacto seleccionado */
  limpiarContacto(): void {
    this.selectedContact = undefined;
    this.formNewOc.get('contactoPrincipal')?.setValue(undefined);
  }

  /** Método para limpiar el formulario de cliente pep */
  clearPEPContact(): void {
    this.selectedPEPContact = undefined;
    this.formNewOc.get('contactoPEP')?.setValue(undefined);
    this.formNewOc.get('pep')?.setValue('false');
  }

  /** Método para actualizar la cantidad de carácteres restantes del campo
   * @param fieldName Nombre del campo a comprobar
  */
  checkCaracteres(fieldName: string): void {
    if (this.newClientForm.get(fieldName)?.value) {
      const caracteresMax = fieldName == 'razonSocial' ? Constantes.CARACTERES_RAZON_SOCIAL : 40;
      const lengthAddress = this.newClientForm.get(fieldName)?.value.length;
      const rest = lengthAddress <= caracteresMax ? caracteresMax - lengthAddress : 0;
      switch (fieldName) {
        case "calle":
          this.caracteresRestantesAddress = rest;
          break;
        case "denominacionComercial":
          this.caracteresRestantesDenominacionComercial = rest;
          break;
        case "razonSocial":
          this.caracteresRestantesRazonSocial = rest;
          break;
      }
    }
  }

  /** Método para marcar campos completados incorrectamente */
  validateAllFields(formGroup: FormGroup) {
    const failedControls: string[] = [];
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        if (control.errors) {
          failedControls.push(field)
        }
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
    return failedControls
  }

  /** Método para actualizar los datos de MCC segun el rubro de cliente
   * @param businessLineID id de rubro del cual obtener las mcc
   */
  updateMCCs(businessLineID: number): void {
    this.newClientForm.get('mcc')?.enable();
    this.mccs = this.mccsList.filter(mcc => mcc.codRubro == businessLineID);
  }

  /** Método para ajustar el formulario segun sea o no PEP seleccionado
   * @param isPEP 'S' para requerir datos de contacto PEP sino, 'N'
   */
  isPEPChangued(isPEP: any): void {
    if (isPEP == 'S') {
      this.newContactForm.controls.cargo.addValidators(Validators.required);
    } else {
      this.newContactForm.controls.cargo.removeValidators(Validators.required);
    }
    this.newContactForm.controls.cargo.updateValueAndValidity();
  }

  /** Método para ajustar el la formulario segun tenga o no contacto PEP
   * @param isPEP TRUE para requerir datos de contacto PEP sino, FALSE
   */
  hasPEPContact(hasPEPContact: boolean): void {
    if (hasPEPContact) {
      this.formNewOc.controls.contactoPEP.addValidators(Validators.required);
    } else {
      this.formNewOc.controls.contactoPEP.removeValidators(Validators.required);
    }
    this.formNewOc.controls.contactoPEP.updateValueAndValidity();
  }

  /** Método para preparar las cuentas bancarias de la OC a crear
   * @param forOnboarding especificar si la lista es para onboarding o no
   * @returns lista de cuentas
   */
  async prepareFacturationAccounts(products: any[]): Promise<any> {
    const results: any[] = [];
    // obtiene cuentas de facturación segun el producto 
    const accounts = this.facturationAccounts.filter(fa => {
      // ignora tipo facturación tc si es tipo PER
      if (this.isPersona && fa.id == "4") {
        return false;
      } else {
        let includes = false;
        products.forEach(p => {
          if (fa.productos.includes(p.ProductID)) {
            includes = true;
          }
        });
        return includes;
      }
    });
    // recorre las cuentas de facturación
    accounts.forEach(a => {
      const cuentaSPI = this.bankAccountSPI ? this.bankAccountSPI : this.bankAccountTD;
      const cuentaTC = this.bankAccountTC ? this.bankAccountTC : this.bankAccountTD;
      const cuentaTD = this.bankAccountTD;
      switch (a.id) {
        case "6": {
          let esTitual:any = cuentaSPI!.esTitular;
          if(typeof cuentaSPI!.esTitular === 'boolean') {
            esTitual = cuentaSPI!.esTitular ? 'S' : 'N';
          } 
          // instancia datos
          const nAccount = {
            "CodTipCue_SDK": cuentaSPI!.tipo.id,
            "NroCtaBancaria_SDK": cuentaSPI!.numero,
            "CtaReal_SDK": cuentaSPI!.numero,
            "CodEnt_SDK": cuentaSPI!.entidad.codigoEntidad,
            "TitularCta_SDK": cuentaSPI!.titular,
            "CodSuc_SDK": "",
            "CodMoneda_SDK": "600",
            "CuentaVigente_SDK": a.vigente,
            "tipoCF_SDK": a.codigo,
            "TipDocTitular_SDK": cuentaSPI!.tipoDocumento,
            "DocTitular_SDK": cuentaSPI!.numeroDocumento,
            "CtaEsDelTitular_SDK": esTitual
          }
          // Si la entidad tiene Requiere confirmación de cuentas, agregamos los datos. 
          if(this.requiereConfirmacion) {
            nAccount["RequiereConfir_SDK"]  = this.requiereConfirmacion ? 'S' : 'N';
            nAccount["CuentaConfir_SDK"] = this.cuentaConfirmada ? 'S' : 'N'; 
          }
          // agrega a la lista  de resultado
          results.push(nAccount)
          break;
        }
        case "4": {
          // instancia datos
          const nAccount = {
            "CodTipCue_SDK": cuentaTC!.tipo.id,
            "NroCtaBancaria_SDK": cuentaTC!.numero,
            "CodEnt_SDK": cuentaTC!.entidad.codigoEntidad,
            "TitularCta_SDK": cuentaTC!.titular,
            "CodSuc_SDK": "",
            "CodMoneda_SDK": "600",
            "CuentaVigente_SDK": a.vigente,
            "tipoCF_SDK": a.codigo,
          }
          // agrega a la lista  de resultado
          results.push(nAccount)
          break;
        }
        default: {
          let esTitual:any = cuentaTD!.esTitular;
          if(typeof cuentaTD!.esTitular === 'boolean') {
            esTitual = cuentaTD!.esTitular ? 'S' : 'N';
          } 
          const nAccount = {
            "CodTipCue_SDK": cuentaTD!.tipo.id,
            "NroCtaBancaria_SDK": cuentaTD!.numero,
            "CodEnt_SDK": cuentaTD!.entidad.codigoEntidad,
            "TitularCta_SDK": cuentaTD!.titular,
            "CodSuc_SDK": "",
            "CodMoneda_SDK": "600",
            "CuentaVigente_SDK": a.vigente,
            "tipoCF_SDK": a.codigo,
            "TipDocTitular_SDK": cuentaTD!.tipoDocumento,
            "DocTitular_SDK": cuentaTD!.numeroDocumento,
            "CtaEsDelTitular_SDK": esTitual
          }
          // Si la entidad tiene Requiere confirmación de cuentas, agregamos los datos. 
          if(this.requiereConfirmacion) {
            nAccount["RequiereConfir_SDK"]  = this.requiereConfirmacion ? 'S' : 'N';
            nAccount["CuentaConfir_SDK"] = this.cuentaConfirmada ? 'S' : 'N'; 
          }
          // agrega a la lista  de resultado
          results.push(nAccount)
          break;
        }
      }
    })
    // devuelve lista de resultado
    return results;
  }

  /** Método para preparar los productos de la OC a crear
   * @params forAltaAgil (optional) productos incluiran parámetros de alta ágil
   * @returns lista de productos
   */
  async prepareProducts(forAltaAgil?: boolean): Promise<any[]> {
    const results: any[] = [];

    // recorre la lista de productos
    this.productList.forEach(product => {
      let addProduct = 0;
      const productoAltaAgil = this.isProductoAltaAgil(product.id)

      if (product.cantidad) {
        while (addProduct < Number(product.cantidad)) {
          // instancia producto a agregar
          const nProduct = {
            "ProductID": product.id,
            "QuantityUnitCode": product.unidad.id,
            "Z_Costo_KUT": product.costo!.id.toString(),
            "Quantity": productoAltaAgil && forAltaAgil ? "1" : product.cantidad!.toString()
          }
          nProduct["Z_MONEDAPOSICION_KUT"] = product.moneda!.descripcion;
          if (product.costo.descuento) {
            nProduct["Z_TIENEDESCUENTO_KUT"] = true;
            nProduct["Z_IMPORTEDESCUENTOContent_KUT"] = product.costo.descuento;
            nProduct["Z_IMPORTEDESCUENTOcurrencyCode_KUT"] = product.moneda!.descripcion;
            nProduct["Z_MONEDADESDECUENTO_KUT"] = product.moneda!.descripcion;
            const desde = moment(new Date());
            const meses = product.costo.periodoMeses;
            const hasta = moment(new Date()).add(meses, 'months');
            nProduct["Z_FECHAVIGENCIADESCUENTO_KUT"] = DateUtils.dateToEpoch(desde.toDate(), true);
            nProduct["Z_FECHAHASTADESCUENTO_KUT"] = DateUtils.dateToEpoch(hasta.toDate(), true);
          }
          nProduct["Z_Dispositivo_KUT"] = product.dispositivo;
          nProduct["DescriptionLanguageCode"] = "ES";
          if (product.tarifario) {
            nProduct["Z_Tarifario_KUT"] = product.tarifario;
          }

          if (productoAltaAgil && forAltaAgil) {
            const datosPos: EquiposPOS = this.preparePosData(product.id.toString(), addProduct)
            nProduct["ProductID"] = datosPos.codigoProductoAA!;
            nProduct["PerfildedescargaOC_KUT"] = datosPos.perfilDescarga?.OID;
            nProduct["PerfildeComercioOC_KUT"] = datosPos.perfilComercio?.OID;
            nProduct["Z_SERIE_KUT"] = datosPos.serie
            nProduct["Z_MODELOPOSOC_KUT"] = datosPos.modelo
            nProduct["Z_SERVICIO_KUT"] = product.servicio
            nProduct["QuantityUnitCode"] = "EA"
          }

          // agrega a la lista de resultado
          results.push(nProduct);
          if (productoAltaAgil && forAltaAgil) {
            addProduct++;
          } else {
            addProduct = Number(product.cantidad);
          }
        }
      }
    });
    // devuelve lista de resultado
    return results;
  }

  /** Método para comprobar datos requeridos para onboarding digital 
   * @return TRUE cuando los datos requeridos están compconstos sino, false
  */
  checkOnboarding({allowWarnings=false}={}): boolean {
    // Comprueba que el cliente seleccionado sea nuevo
    const newClientChecked = this.isNewClient;
    this.onboardingCheckList.find(check => {
      return check.check == 'newClient';
    })!.value = newClientChecked ? newClientChecked : false;

    // Comprueba que haya un cliente seleccionado y sea de tipo Unipersonal
    const businessTypeChecked = this.isUnipersonal;
    this.onboardingCheckList.find(check => {
      return check.check == 'businessType';
    })!.value = businessTypeChecked ? businessTypeChecked : false;

    // Comprueba si existen cuentas que no esten validadas
    const accountValidationCheck = this.onboardingCheckList.find(check => {
      return check.check == 'accountValidated';
    })
    if (accountValidationCheck) {
      accountValidationCheck.value = allowWarnings ? accountValidationCheck.warning : this.bankAccountTD ? this.bankAccountTD.validado : false;
    }

    // Comprueba si existen productos que no sean validos para onboarding
    const hasInvalidProduct = this.productList.find(product => { return product.onboarding == false });
    this.onboardingCheckList.find(check => {
      return check.check == 'productsValidated';
    })!.value = this.productList.length > 0 ? (hasInvalidProduct ? false : this.productsForOnboarding ? true : false) : false;

    // Obtiene la cantidad de chequeados y asigna a la variable local
    const checkedCount = this.onboardingCheckList.filter(check => { return check.value == true }).length;
    this.onboardingCheckListChecked = checkedCount;
    return this.onboardingCheckList.length == checkedCount;
  }

  /** Método para comprobar datos requeridos para alta agil
   * @return TRUE cuando los datos requeridos están completos sino, false
  */
  checkAltaAgil(): boolean {
    const hasPOSForAltaAgilArray = this.productList.filter(pr => {
      return this.isProductoAltaAgil(pr.id)
    })
    const hasPOSForAltaAgil = hasPOSForAltaAgilArray.length > 0;
    // Controla que existan productos y equipos POS asociados para habilitar si es por alta agil.
    this.altaAgilCheckList.find(check => {
      return check.check == 'productsValidated';
    })!.value = (this.productList.length > 0 && this.equiposPOSList.length > 0) ? hasPOSForAltaAgil : false;

    const hasRequestedFiles = this.requestedFiles.filter(rf => { return rf.tipo?.id == "6" }).length > 0
    this.altaAgilCheckList.find(check => {
      return check.check == 'requestedFilesValidated';
    })!.value = hasRequestedFiles;

    this.altaAgilCheckList.find(check => {
      return check.check == 'onboardingValidated';
    })!.value = this.onboardingCheckListChecked == this.onboardingCheckList.length;

    // Obtiene la cantidad de chequeados y asigna a la variable local
    const checkedCount = this.altaAgilCheckList.filter(check => { return check.value == true }).length;
    this.altaAgilCheckListChecked = checkedCount;
    return this.altaAgilCheckList.length == checkedCount;
  }

  /** Método para comprobar si el cliente a crear o seleccionado es unipersonal **/
  checkClientTypeInfo(): void {
    this.isPersona = this.selectedClient ? this.selectedClient.tipoCliente.id == 'ZPER' : this.clientToCreate.Z_TIPODECLIENTE_KUT == 'ZPER';
    this.isUnipersonal = (this.selectedClient && this.selectedClient.tipoEmpresa.id == 'Z1') || (this.clientToCreate && this.clientToCreate.LegalFormCode == 'Z1');
  }

  /** Método para confirmar un rechazar creación de OC sin onboarding
   * @param confirmed usar TRUE para crear OC sin onboarding
   */
  confirmNOOnboarding(confirmed: boolean): void {
    if (confirmed) {
      this.createOC();
    }
    this.modalSrv.dismissAll();
  }

  /** Método para confirmar un rechazar creación de OC sin alta agil
   * @param confirmed usar TRUE para crear OC normal
   */
  confirmNOAltaAgil(confirmed: boolean): void {
    if (confirmed) {
      this.createOC();
    }
    this.modalSrv.dismissAll();
  }

  /** Método para cargar el mapa */
  async openMap() {
    this.proximidad = '';
    // instancia el mapa
    const map = new Map('mapa', { scrollWheelZoom: true });
    map.on('dblclick', (e: L.LeafletMouseEvent) => {
      const ubi = e.latlng;
      const location = { y: ubi.lat.toFixed(7), x: ubi.lng.toFixed(7) }
      // Actualizar la posición del marcador
      this.updateLocation(map, location)
      //this.mark.setLatLng(event.latlng);
    });
    // agrega la base
    tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    // comprueba si se asignó alguna coordenada
    if (this.newClientForm.value.latitud) {
      const location = { y: this.newClientForm.value.latitud, x: this.newClientForm.value.longitud };
      this.updateLocation(map, location);
    } else {
      // Comprueba si se cargaron datos de calle y ciudad
      if (this.newClientForm.value.calle && this.newClientForm.value.ciudad) {
        this.loading = true;
        this.loadingMessage.next('Buscando calle');
        // obtiene calle
        const calle = this.newClientForm.value.calle;
        // obtiene número
        const num = this.newClientForm.value.numeroCalle;
        const numero = calle && num ? ` ${num}` : '';
        // obtiene datos del pais
        const pais = `, ${this.countries.find(c => c.id == this.newClientForm.value.pais)?.descripcion}`
        // obtiene datos del departamento
        const state = this.states.find(d => d.id == this.newClientForm.value.departamento);
        const departamento = state ? `, ${state.descripcion}` : '';
        // obtiene datos del barrio
        const neig = this.neighborhoods.find(n => n.id == this.newClientForm.value.barrio);
        const barrio = neig ? `, ${neig.descripcion}` : '';
        // obtiene datos de la ciudad
        const ciudad = `, ${this.cities.find(c => c.id == this.newClientForm.value.ciudad)?.descripcion}`;
        // crea dirección con el nombre de la calle, ciudad , departamento y pais
        const address = `${calle}${numero}${barrio}${ciudad}${departamento}${pais}`;
        // busca la dirección en el proveedor y se espera el resultado
        let c = await this.findLocationByAddress(address);
        // comprueba si se obtuvo algun resultado
        if (c.length > 0) {
          // obtiene la primera coordenada y actualiza en el mapa
          const location = c[0];
          this.updateLocation(map, location);
          this.proximidad = 'Calle';
        } else {
          // busca nuevamente pero solo con el nombre de la ciudad
          c = await this.findLocationByAddress(`${calle}${numero}${ciudad}${departamento}${pais}`);
          // comprueba si se obtuvo resultados
          if (c.length > 0) {
            // obtiene la primera coordenada y actualiza en el mapa
            const location = c[0];
            this.updateLocation(map, location);
            this.proximidad = 'Calle';
          } else {
            // busca nuevamente pero solo con el nombre de la ciudad
            c = await this.findLocationByAddress(`${calle}${ciudad}${departamento}${pais}`);
            // comprueba si se obtuvo resultados
            if (c.length > 0) {
              // obtiene la primera coordenada y actualiza en el mapa
              const location = c[0];
              this.updateLocation(map, location);
              this.proximidad = 'Calle';
            } else {
              this.loadingMessage.next('Buscando barrio');
              // busca nuevamente pero solo con el nombre de la ciudad
              c = await this.findLocationByAddress(`${barrio.replace(",", "")}${ciudad}${departamento}${pais}`);
              // comprueba si se obtuvo resultados
              if (c.length > 0) {
                // obtiene la primera coordenada y actualiza en el mapa
                const location = c[0];
                this.updateLocation(map, location);
                this.proximidad = 'Barrio';
              } else {
                this.loadingMessage.next('Buscando ciudad');
                // busca nuevamente pero solo con el nombre de la ciudad
                c = await this.findLocationByAddress(`${ciudad}${departamento}${pais}`);
                // comprueba si se obtuvo resultados
                if (c.length > 0) {
                  // obtiene la primera coordenada y actualiza en el mapa
                  const location = c[0];
                  this.updateLocation(map, location);
                  this.proximidad = 'Ciudad';
                }
              }
            }
          }
        }
        this.loading = false;
      } else {
        this.coordenadaActual.next(undefined);
      }
      // Comprueba si se obtuvo alguna coordenada
      if (!this.coordenadaActual.value) {
        this.loading = true;
        this.loadingMessage.next('Obteniendo ubicación');
        // obtiene la ubiación actual del usuario
        map.locate({ setView: true, enableHighAccuracy: true });
        map.on('locationfound', async (e) => {
          const ubi = e.latlng;
          const location = { y: ubi.lat.toFixed(7), x: ubi.lng.toFixed(7) }
          this.updateLocation(map, location);
          this.proximidad = 'Usuario';
          this.loading = false;
        });
        map.on('locationerror', async () => {
          this.loadingMessage.next('Obteniendo país');
          const location = await this.findLocationByAddress('Paraguay');
          this.updateLocation(map, location[0]);
          this.proximidad = 'País';
          this.loading = false;
        })
      }
    }
  }

  /** Método para obtener una coordenadas de una ubicación
   * @param address ubicación a buscar
   * @returns lista con coordenadas obtenidas
   */
  async findLocationByAddress(address: any) {
    // instancia el proveedor nominatim
    const provider = new OpenStreetMapProvider();
    // obtiene coordenada para el país
    const c = await provider.search({ query: address });
    return c;
  }

  /** Método para actualizar la ubicacion de un mapa 
   * @param map mapa a actualizar
   * @param location ubicación a asignar
   */
  updateLocation(map: any, location: any) {
    if (location) {
      // obtiene la denominación del cliente si existiese
      const deno = this.clientToCreate ? this.clientToCreate.Name.toString : this.selectedClient ? this.selectedClient.denominacion : 'Ubicación del cliente';
      this.mark?.remove();
      // crea un marcador con la coordenada nueva y le asigna la denominacion
      this.mark = marker([location.y, location.x], { draggable: true, autoPan: true }).bindPopup(deno);
      // crea metodo para saber cuando se mueve el cursor
      this.mark.on('move', (e: any) => {
        // obtiene la nueva ubicación del marcador
        const ubicacion = e.latlng;
        // actualiza la coordenada actual
        this.coordenadaActual.next({ latitud: ubicacion.lat.toFixed(7), longitud: ubicacion.lng.toFixed(7) });
      });
      // agrega el marcador al mapa
      this.mark.addTo(map);
      // actualiza la coordenada actual
      this.coordenadaActual.next({ latitud: location.y, longitud: location.x });
      // centra el mapa a la nueva ubicación
      map.fitBounds(leaflet.latLngBounds([this.mark.getLatLng()]));
    }
  }

  /** Método para confirmar la ubicación seleccionada en el mapa */
  confirmLocation() {
    // obtiene la coordenada actual
    const coordenada = this.coordenadaActual.getValue();
    // asigna al formulario
    this.newClientForm.get('latitud')?.patchValue(coordenada.latitud);
    this.newClientForm.get('longitud')?.patchValue(coordenada.longitud);
    // finaliza subscripción
    this.mapSubcription?.unsubscribe();
  }

  /** Método para transformar un texto numérico a formato local
   * @param evt Evento KEYUP
   * @param control Control de formulario al cual asignar el nuevo valor
   * @param digitFormat Dígitos a mostrar en siguiente formato {minEnteros}.{minFraccion}-{maxFaccion}
   * @param enLocale por defecto false, cuando TRUE evita reemplazar los separadores de miles antes de transformar
   */
  transformNumber(evt: any, digitFormat?: string, enLocale?: boolean, moveCursor?: boolean) {
    const caretPos = evt.target.selectionStart;
    const lValue = evt.target.value;
    const value = enLocale ? Number(lValue) : Number(lValue.replaceAll(".", "").replaceAll(",", "."));
    const locale = enLocale ? 'en-EN' : 'es-PY';
    const nValue = digitFormat ? formatNumber(value, locale, digitFormat) : formatNumber(value, locale);
    if (nValue != '∞') {
      evt.target.value = nValue;
    }
    if (moveCursor) {
      evt.target.selectionStart = caretPos
    }
  }

  /** Método para comprobar si la tecla presionar es un numero
   * @param evt Evento KEYPRESS
   */
  checkNumber(evt: any) {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode < 48 || charCode > 57) {
      evt.preventDefault();
      return false;
    }
    return true;
  }

  /** Método para seleccionar un archivo
   * @param evt evento de seleccion
   */
  async selectFile(evt: any) {
    const files = Array.from<File>(evt.target.files);
    const { valid } = await this.adjuntosSrv.validfiles(files)
    if (valid) {
      files.forEach(file => {
        const fileName = FileUtils.fixName(file.name);
        if (fileName.shorted) {
          this.toastr.info('Nombre actualizado a 40 carácteres', '', { positionClass: 'toast-top-center', progressBar: false });
        }
        FileUtils.fileToBase64(file).then(data => {
          const documento: Documento = {
            tituloOriginal: file.name,
            titulo: fileName.full,
            tituloSolo: fileName.short,
            extension: fileName.extention,
            tamaño: file.size,
            fechaCreacion: new Date(moment.now()),
            datos: data
          };
          this.otherFiles.push(documento);
        });
      });
    }
  }

  /** Método para obtener tamaño del documento 
   * @param documento documento del cual obtener su tamaño
  */
  getFileSize(documento: any): string {
    return FileUtils.getFileSize(documento);
  }

  /** Método para quitar documento de la lista de archivos a adjuntar 
   * @param documento documento a eliminar de la lista
  */
  removeFile(documento: any): void {
    this.otherFiles = this.otherFiles.filter(doc => {
      return doc != documento;
    });
  }

  /** Método para preparar la lista de ajuntos a cargar y asociar */
  prepareFiles() {
    this.filesToUpload = this.requestedFiles;
    this.otherFiles.forEach(file => {
      this.filesToUpload.push(file);
    })
  }

  /** Método para subir todos los adjuntos y asociar a una oc
   * @param idOC id de la oc cual asociar los adjuntos
   * @param idCliente id del cliente asociado a la oc
   */
  async uploadFiles(idOC: string, idCliente: string) {
    this.loadingMessage.next("Subiendo adjuntos");
    let allUPloaded = true;
    const total = this.filesToUpload.length;
    let actual = 1;
    for (const documento of this.filesToUpload) {
      this.loadingMessage.next(`Subiendo adjunto ${actual} de ${total}`);
      documento.subido = await this.adjuntosSrv.uploadOCFile(documento, idOC, idCliente);
      if (!documento.subido) {
        allUPloaded = false;
      } else {
        this.auditSrv.logAction({ modulo: 'OC', accion: 'Subir adjunto OC', detalle: `OC: ${this.ocId} documento: ${documento.titulo}` });
      }
      actual++;
    }
    if (!allUPloaded) {
      this.toastr.warning(Mensajes.NUEVO_ADJUNTO_ERROR, '', { positionClass: 'toast-top-center', progressBar: false });
    }
    return allUPloaded
  }

  async fileSelected(event: any, destino?: string) {
    const files = Array.from<File>(event.target.files);
    const { valid } = await this.adjuntosSrv.validfiles(files)
    if (valid) {
      if (destino) {
        const file = files[0];
        const fileName = FileUtils.fixName(file.name);
        if (fileName.shorted) {
          this.toastr.info('Nombre actualizado a 40 carácteres', '', { positionClass: 'toast-top-center', progressBar: false });
        }
        FileUtils.fileToBase64(file).then(data => {
          const fileType: Basico = { id: '', descripcion: '' };
          switch (destino) {
            case 'ci_frente':
              fileType.id = '4';
              fileType.OID = '618979e16c810f10211183fd';
              break;
            case 'ci_dorso':
              fileType.id = '135';
              fileType.OID = '63a9f43f932b7e00778b62cd';
              break;
            case 'ruc':
              fileType.id = '1';
              fileType.OID = '618979e16c810f10211183f7';
              break;
            case 'contrato':
              fileType.id = '6';
              fileType.OID = '618979e16c810f10211183ff';
              break;
            default:
              break;
          }
          const documento: Documento = {
            tituloOriginal: file.name,
            titulo: fileName.full,
            tituloSolo: fileName.short,
            extension: fileName.extention,
            tamaño: file.size,
            fechaCreacion: new Date(moment.now()),
            datos: data,
            tipo: fileType
          };
          this.requestedFiles.push(documento);
          this.checkAltaAgil();
        });
      } else {
        files.forEach(file => {
          const fileName = FileUtils.fixName(file.name);
          if (fileName.shorted) {
            this.toastr.info('Nombre actualizado a 40 carácteres', '', { positionClass: 'toast-top-center', progressBar: false });
          }
          FileUtils.fileToBase64(file).then(data => {
            const documento: Documento = {
              tituloOriginal: file.name,
              titulo: fileName.full,
              tituloSolo: fileName.short,
              extension: fileName.extention,
              tamaño: file.size,
              fechaCreacion: new Date(moment.now()),
              datos: data
            };
            this.otherFiles.push(documento);
          });
        });
      }
    } else {
      switch (destino) {
        case 'ci_frente':
          this.file_ci_f = undefined;
          break;
        case 'ci_dorso':
          this.file_ci_d = undefined;
          break;
        case 'ruc':
          this.file_ruc = undefined;
          break;
        case 'contrato':
          this.file_contrato = undefined;
          break;
        default:
          break;
      }
    }
  }

  /** Método para acortar del nombre del archivo 
   * @param fileName nombre del archivo a comprobar
   */
  checkFileName(fileName: string) {
    let nFileName = '';
    if (fileName) {
      nFileName = fileName.length > 28 ? `...${fileName.substring(fileName.length - 28)}` : fileName
    }
    return nFileName;
  }

  /** Método para gestionar la seleccion de un archivo
   * @param event Evento de selección
   */
  async handleFileSelect(event) {
    const files = Array.from<File>(event.target.files);
    const { valid } = await this.adjuntosSrv.validfiles(files)
    if (valid) {
      const selectedFile = files[0];
      this.selectedFile = selectedFile;
      const fileName = FileUtils.fixName(selectedFile.name);
      if (fileName.shorted) {
        this.toastr.info('Nombre actualizado a 40 carácteres', '', { positionClass: 'toast-top-center', progressBar: false });
      }
      this.selectedFileName = fileName.full;
    }
  }

  /** Método para comprobar caracteres especiales en el nombre del adjunto
   * @param event evento 
   */
  checkLength(event: Event) {
    if (this.selectedFileName.length == Constantes.CARACTERES_NOMBRE_ADJUNTO) {
      event.preventDefault();
    }
  }

  /** Método para agregar un adjunto a la lista
   * @param file archivo a agregar
   * @param fileName nombre del archivo
   * @param fileType tipo de adjunto
   */
  addFile(file, fileName, fileType) {
    if (!file || !fileName || !fileType) {
      this.toastr.warning('Compconste todos los campos', '', { positionClass: 'toast-top-center', progressBar: false });
    } else {
      const nFileName = FileUtils.fixName(fileName);
      FileUtils.fileToBase64(file).then(data => {
        const documento: Documento = {
          tituloOriginal: fileName,
          titulo: nFileName.full,
          tituloSolo: nFileName.short,
          extension: nFileName.extention,
          tamaño: file.size,
          fechaCreacion: new Date(moment.now()),
          datos: data,
          tipo: { id: fileType.value, descripcion: fileType.description, OID: fileType._id }
        };
        this.otherFiles.push(documento);
      });
      this.selectedFile = undefined;
      this.selectedFileName = undefined;
      this.selectedFileType = undefined;
      this.modalSrv.dismissAll();
    }
  }

  /** Método para obtener todos los tipos de adjuntos */
  async getFileTypes() {
    const data = await this.adjuntosSrv.getTipoAdjuntos(true)
    if (data) {
      this.tipoAdjuntos = data;
    }
  }

  openNewAccount(nType: 'TD' | 'TC' | 'SPI') {
    this.nAccountType = nType;
    this.newBankAccountForm.reset();
    const user = this.usersSrv.getActualUser();
    this.newBankAccountForm.get('entidad')?.patchValue(user.entidad.nombre);
    this.newBankAccountForm.get('entidadid')?.patchValue(user.entidad.id);
    if (nType === 'SPI' || nType === 'TC') {
      this.newBankAccountForm.get('titular')?.patchValue(this.accountOwner)
    }
    if (nType === 'SPI') {
      this.newBankAccountForm.get('titular')?.patchValue(this.accountOwner)
      this.newBankAccountForm.get('tipoDocumento')?.patchValue(this.accountDocumentType)
      this.newBankAccountForm.get('numeroDocumento')?.patchValue(this.accountDocumentNumber)
      this.newBankAccountForm.get('tipoDocumento')?.addValidators(Validators.required);
      this.newBankAccountForm.get('numeroDocumento')?.addValidators(Validators.required);
      this.newBankAccountForm.updateValueAndValidity();
    } else {
      this.newBankAccountForm.get('tipoDocumento')?.removeValidators(Validators.required);
      this.newBankAccountForm.get('numeroDocumento')?.removeValidators(Validators.required);
      this.newBankAccountForm.updateValueAndValidity();
    }
    if(nType=='TD' && this.requiereConfirmacion) {
      this.updateVadilationForm();
    }
    this.open(this.newBankAccountModal, "lg")
  }

  async associateAccount() {
    if (this.newBankAccountForm.invalid) {
      this.validateAllFields(this.newBankAccountForm);
      this.toastr.error(Mensajes.DATOS_INCOMPLETOS, '', { positionClass: 'toast-top-center', progressBar: false });
      return
    } else {
      const entity = this.usersSrv.getActualUser().entidad;
      const accountNumber = this.newBankAccountForm.value.numeroCuenta;
      let accountType;
      let accountValid;
      let titular;
      let accountIsTitular;
      if (this.nAccountType === 'TD' && !this.requiereConfirmacion) {
        this.loading = true;
        this.loadingMessage.next('Verificando validación de cuenta');
        let tieneExcepcion = "N";
        const originValidation = await this.crmSrv.checkOriginException(Number(entity.codigoEntidad.replace('E', '')).toString());
        if (originValidation && originValidation.DatosEntidad_OUT && originValidation.DatosEntidad_OUT.TieneExcepcion) {
          tieneExcepcion = originValidation.DatosEntidad_OUT.TieneExcepcion;
        }
        if (tieneExcepcion == "N") {
          this.loadingMessage.next('Validando cuenta');
          const accountValidation = await this.crmSrv.checkAccount(Number(entity.codigoEntidad.replace('E', '')).toString(), accountNumber);
          if (accountValidation && accountValidation.Datos.CuentasDebito.length > 0) {
            const account = accountValidation.Datos.CuentasDebito[0];
            accountType = this.accountTypes.find(accountType => accountType.OID == account.GS01CtaTpo.toString());
            titular = account.GS26NomPro;
            accountValid = true;
            accountIsTitular = 'S';
            // guarda datos adicionales
            this.accountOwner = titular;
            this.accountDocumentType = account.GE36TDId;
            this.accountDocumentNumber = account.GE36NroDoc;
          } else {
            accountType = this.accountTypes.find(accountType => accountType.id == this.newBankAccountForm.value.tipoCuenta);
            titular = this.newBankAccountForm.value.titular;
            accountValid = false;
            accountIsTitular = 'S';
          }
        } else {
          accountType = this.accountTypes.find(accountType => accountType.id == this.newBankAccountForm.value.tipoCuenta);
          titular = this.newBankAccountForm.value.titular;
          accountValid = true;
          accountIsTitular = 'S';
        }
      } else {
        accountType = this.accountTypes.find(accountType => accountType.id == this.newBankAccountForm.value.tipoCuenta);
        titular = this.newBankAccountForm.value.titular;
        accountValid = false;
        accountIsTitular = 'S';
      }
      const nAccount: CuentaBancaria = {
        'tipo': accountType,
        'numero': accountNumber,
        'entidad': entity,
        'titular': titular,
        'tipoDocumento': this.newBankAccountForm.value.tipoDocumento,
        'numeroDocumento': this.newBankAccountForm.value.numeroDocumento,
        'esTitular': accountIsTitular,
        'validado': accountValid
      }
      switch (this.nAccountType) {
        case 'TD':
          this.bankAccountTD = nAccount;
          if(this.requiereConfirmacion){
            this.accountOwner = this.bankAccountTD.titular
            this.accountDocumentType = this.bankAccountTD.tipoDocumento
            this.accountDocumentNumber = this.bankAccountTD.numeroDocumento
          }
          break;
        case 'TC':
          this.bankAccountTC = nAccount;
          break;
        case 'SPI':
          this.bankAccountSPI = nAccount;
          break;
        default:
          break;
      }
      this.loading = false;
      this.checkOnboarding({allowWarnings: true});
      this.modalSrv.dismissAll();
    }
  }


  async getResponsibleEmployee() {
    let idEmpleado = ''
    const detallesEmpleado = await this.mongoSrv.getAll(`/detalle-empleadoc4c/responsible-party`);
    for (const detalle of detallesEmpleado) {
      idEmpleado = detalle.empleado.idEmpleado
    }

    if (idEmpleado != '') {
      this.selectedMainEmployeeResponsible = idEmpleado
    }
  }

  checkProductosSPI() {
    let tieneProductoSPI = false;
    const productosSPI = this.facturationAccounts.find(facturationAccounts => facturationAccounts.codigo == 'PAGTRASPI')?.productos;
    this.productList.forEach(producto => {
      if (productosSPI?.includes(producto.id.toString())) {
        tieneProductoSPI = true;
      }
    })
    this.tieneProductosSPI = tieneProductoSPI;
  }

  /**
 * Determina si un producto se es de alta agil.
 *
 * @param {string} codigoProducto El código del producto.
 * @returns {boolean} `true` si el producto es de alta agil, `false` en caso contrario.
 */
  isProductoAltaAgil(codigoProducto) {
    const productoEncontrado = this.productosAltaAgil.find((producto) => {
      return producto.id === codigoProducto;
    });
    if (productoEncontrado) {
      return true;
    }
    return false
  }

  /**
   * Metodo para agregar los datos de POS a la OC
   * @param {string} codigoProducto Código del producto
   * @param {number} index Indice del equipo
   * @returns {any} datos de equipos POS
   */
  preparePosData(codigoProducto: string, index: number) {
    const equipos = this.equiposPOSList.filter(equipo => equipo.codigoProducto == codigoProducto);
    return equipos[index];
  }

  /**
   * Método para obtener los datos los productos permitidos para alta agil
   */
  async getProductosWithAltaAgil() {
    this.productosAltaAgil = await this.mongoSrv.getAll(`/productos-pe/alta-agil`);
  }

  async getDeposito() {
    if (this.equiposPOSList.length > 0) {
      if (this.equiposPOSList[0].deposito) {
        return this.equiposPOSList[0].deposito
      } else {
        return ''
      }
    }
    return ''
  }


  updateVadilationForm(){
    this.newBankAccountForm.controls.tipoDocumento.addValidators(Validators.required);
    this.newBankAccountForm.controls.numeroDocumento.addValidators(Validators.required);
    this.newBankAccountForm.controls.tipoDocumento.updateValueAndValidity();
    this.newBankAccountForm.controls.tipoDocumento.updateValueAndValidity();
  }
}
