import { Injectable } from '@angular/core';
import { MongoDBService } from './mongoDB.service';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  constructor(
    private mongoDBSrv: MongoDBService
  ) {}

  /** Método para comprobar un número de cuenta
   * @param entity código de la entidad
   * @param account número de cuenta a comprobar
   * @return TRUE si la cuenta es válida, sino FALSE
   */
  async checkAccount(entity: string,account: string) {
    const rest = await this.mongoDBSrv.getAll(`/crm/checkAccount?entity=${entity}&account=${account}`);
    if (rest) {
      return rest;
    } else {
      return undefined;
    }
  }

  /** Método para obtener detalles de transacción por número de boleta
   * @param documentNumber número de boleta a comprobar
   */
  async getTransactionDetails(documentNumber: string) {
    const rest = await this.mongoDBSrv.getAll(`/crm/transactionDetails?documentNumber=${documentNumber}`);
    if (rest) {
      return rest;
    } else {
      return undefined;
    }
  }

  /** Método para comprobar un número de cuenta
   * @param entity código de la entidad
   * @param account número de cuenta a comprobar
   * @return TRUE si la cuenta es válida, sino FALSE
   */
  async checkOriginException(entity: string) {
    const rest = await this.mongoDBSrv.getAll(`/crm/check-origin?entity=${entity}`);
    if (rest) {
      return rest;
    } else {
      return undefined;
    }
  }

  /** Método para confirmar un número de cuenta
   * @param account número de cuenta a comprobar
   * @param nroDocumento número de documento asociado a la cuenta
   * @param nombreTitural nombre del titular de la cuenta
   * @return TRUE si la cuenta es válida, sino FALSE
   */
  async accountConfirmation(accountType: string, account: string, tipoDocumento: string, 
        nroDocumento: string, nombreTitular: string) {
    const data = {
      accountType,
      account,
      nroDocumento,
      nombreTitular,
      tipoDocumento
    }
    
    const rest = await this.mongoDBSrv.create(`/crm/account-confirmation`, data);
    if (rest) {
      return rest;
    } else {
      return undefined;
    }
  }

}
