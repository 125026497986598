import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { MongoDBService } from 'src/app/services/mongoDB.service';

function deleteEmptyData(data:any){
  for(const key in data) {
      if(data[key] == '' || data[key] == null) {
        delete data[key];
      }
  }
  return data;
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html'
})
export class HelpComponent implements OnInit {

  ayudaList: any[]=[]
  loading=false;

  filterForm: FormGroup;
  faTimes=faTimes;
  faSearch=faSearch;
  
  constructor(
    private mongoSrv: MongoDBService,
    private fb: FormBuilder
  ) {
    this.filterForm = this.fb.group({
      pregunta: ['']
    })
  }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  async obtenerDatos(){
    const params: any[]=[];
    params.push( {key:'size', value: '-1'} ) // Si se envia -1, se trae el total del listado
    
    const valueFilter = JSON.stringify(deleteEmptyData(this.filterForm.value))
    if (valueFilter) {
      params.push( {key:'filtros', value: valueFilter} )
    }

    const data = await this.mongoSrv.get('/ayuda/entidad',params);
    if (data) {
      this.ayudaList= data.datos;
    }
    this.loading = false;
  }

  limpiar(){
    this.filterForm.reset()
    this.obtenerDatos()
  }

}
