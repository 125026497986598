import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import momentTZ from 'moment-timezone';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() seleccion: any;

  faQuestionCircle=faQuestionCircle;
  faHistory=faHistory;
  env;
  showEnv = false;
  user: any;

  constructor(
    private router: Router, 
    private usersSrv: UsersService
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.env = environment.env_name;
    if (['QA','DESARROLLO'].includes(this.env)) {
      this.showEnv=true;
    }
  }

  home(){
    this.router.navigate(['tickets','tickets'])
  }

  getUser(){
    this.user = this.usersSrv.getActualUser();
    this.user.lastLogin= momentTZ(this.user.lastLogin).tz('America/Asuncion').format('DD/MM/YYYY HH:mm:ss')
  }
}
