import { Pipe, PipeTransform } from '@angular/core';

export type SortOrder = 'asc' | 'desc';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(items: any[], sortOrder?: SortOrder, sortKey?: string) {
    sortOrder = sortOrder && (sortOrder.toLowerCase() as any);

    if (!items || (sortOrder !== 'asc' && sortOrder !== 'desc')) return items;

    let numberArray: any[] = [];
    let stringArray: any[] = [];
    const dateArray = [];

    if (!sortKey) {
      numberArray = items.filter(item => typeof item === 'number').sort();
      stringArray = items.filter(item => typeof item === 'string').sort();
    } else {
      numberArray = items.filter(item => typeof item[sortKey] === 'number').sort((a, b) => a[sortKey] - b[sortKey]);
      stringArray = items
        .filter(item => typeof item[sortKey] === 'string')
        .sort((a, b) => {
          if (a[sortKey] < b[sortKey]) return -1;
          else if (a[sortKey] > b[sortKey]) return 1;
          else return 0;
        });
    }
    const sorted = numberArray.concat(stringArray).concat(dateArray);
    return sortOrder === 'asc' ? sorted : sorted.reverse();
  }

}
