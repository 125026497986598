import { Routes } from "@angular/router";
import { PermGuardService } from "../../services/perm-guard.service";
import { DataCatalogoProductos } from "./catalogo-productos.routes.data";
import { ListadoCatalogoProductosComponent } from "./listado-catalogo-productos/listado-catalogo-productos.component";
import { FormularioCatalogoProductosComponent } from "./formulario-catalogo-productos/formulario-catalogo-productos.component";


export const CatalogoProductos_Routes: Routes = [
    {path: 'listado', component: ListadoCatalogoProductosComponent, data: DataCatalogoProductos.listado, canActivate:[PermGuardService]},
    {path: ':id/detalle', component: FormularioCatalogoProductosComponent, data: DataCatalogoProductos.detalle, canActivate:[PermGuardService]},
    {path: '', pathMatch: 'full', redirectTo:'listado'},
    {path: '**', pathMatch: 'full', redirectTo: 'listado'}
  ]
  ;
   
