import { NgModule } from '@angular/core';
import { 
  TicketsFilterPipe,
  TicketsSearchPipe,
  IncludeClossedPipe,
  TypeFilterPipe,
  CategoryFilterPipe,
  IncidentFilterPipe,
  OCFilterPipe,
  OCSearchPipe ,
  StateFilterPipe,
  ProductSearchPipe,
  ProductFilterPipe,
  ReportSLASearchPipe} from './search.pipe';
import { SortPipe } from './sort.pipe';

@NgModule({
  imports: [],
  declarations: [ 
    TicketsFilterPipe,
    TicketsSearchPipe,
    SortPipe,
    IncludeClossedPipe,
    TypeFilterPipe,
    CategoryFilterPipe,
    IncidentFilterPipe,
    OCFilterPipe,
    OCSearchPipe,
    StateFilterPipe,
    ProductSearchPipe,
    ProductFilterPipe,
    ReportSLASearchPipe
  ],
  exports: [
    TicketsFilterPipe,
    TicketsSearchPipe,
    SortPipe,
    IncludeClossedPipe,
    TypeFilterPipe,
    CategoryFilterPipe,
    IncidentFilterPipe,
    OCFilterPipe,
    OCSearchPipe,
    StateFilterPipe,
    ProductSearchPipe,
    ProductFilterPipe,
    ReportSLASearchPipe
  ]
})
export class ApplicationPipesModule {}
