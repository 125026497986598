import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Param, ParamOData, QueryParam } from '../interfaces/interfaces';
import { RequestService } from './request.service';
import { UsersService } from './users.service';
import { LogService } from './log.service';
import { MongoDBService } from './mongoDB.service';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor (
    private requestSrv: RequestService,
    private usersSrv: UsersService,
    private logSrv: LogService,
    private mongoSrv: MongoDBService
  ) {}

  /** Método para crear un ticket
   * @param data datos del ticket
   * @returns objeto con datos del ticket creado, si se produce algún error devuelve undefined
   */
  async createTicket(data: any) {
    
    const res = await this.mongoSrv.create('/tickets/v2',data);
    if (res.posted) {
      return res.body.data.body.d.results;
    } 
  }

  /** Método para obtener tickets del servicio ServiceRequestCollection
   * @param params componenete a agregar a la busqueda
   */
  async getTickets(params: Param[]) {
    const res = await this.mongoSrv.get(`/tickets/v2`, params);
    if (res.status==200) {
      return res.body.d.results;
    } else {
      return [];
    }
  }

  /** Método para obtener detalles de un ticket 
   * @param id número de ticket
   * @return objeto con datos del tickets, si no se encuentra devuelve undefined
  */
  async getTicketDetailsV2(id: number) {
    const params: Param[] = [];
    const filters: ParamOData[] = [];
    
    filters.push({ key: 'ID', value: id.toString()})

    params.push({ key: 'filters', value: JSON.stringify(filters) })
    params.push({ key: 'expand', value: "ServiceRequestTextCollection,ServiceRequestParty,ServiceRequestBusinessTransactionDocumentReference" })

    const data = await this.mongoSrv.get('/tickets/v2', params);
    if(data) {
      if(data.d.__count == "1"){
        return data.d.results[0];
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  /** Método para obtener el número de boleta asociado a un ticket
   * @param id número de boleta
   * @return el número de boleta, si no se encuentra el ticket devuelve undefined
   */
  async getNumeroBoleta(id: number) {
    const res = await this.mongoSrv.get(`/tickets/numero-boleta/${id}`, []);
   
    if (res && res.d && res.d.results) {
      return res.d.results
    } else {
      return undefined;
    }
  }

  /** Método para obtener la descripción de un ticket
   * @param OID Object ID identificador del ticket
   * @return la descripción del ticket, si no encuentra el ticket devuelve undefined
   */
  async getTicketDescriptionObject(OID: any) {
    const data = await this.mongoSrv.getAll(`/tickets/${OID}/descripcion`);
    if(data) {
      return data
    } else {
      return undefined;
    }
  }

  /** Método para obtener la descripción de un ticket
   * @param OID Object ID identificador del ticket
   * @return la descripción del ticket, si no encuentra el ticket devuelve undefined
   */
  async getTicketDescriptionV2(ticket: any,reOpen?: boolean) {
    if(ticket.ServiceRequestTextCollection && ticket.ServiceRequestTextCollection.length &&
      ticket.ServiceRequestTextCollection.length > 0) {
     if(!reOpen) {
      return ticket.ServiceRequestTextCollection.find((text: any) => text.TypeCode == '10004');  
     } else {
      return ticket.ServiceRequestTextCollection
     } 
    }
    return undefined;
    
  }

  /** Método para agregar descripcion a un ticket
   * @param ticketOID OID del ticket al cual asignar descripcion
   * @param description objeto con la descripcion a asignar
   */
  async createTicketDescription(ticketOID: string, description: {"FormattedText": string}) {
    const res = await this.mongoSrv.create(`/tickets/descripcion/${ticketOID}`,description);
    
    if (res.posted) {
      return true;
    } else {
      return false;
    }  
  }

  /** Método para acutalizar datos de un ticket
   * @param objectID oid del ticket
   * @param datos a actualizar
   * @return obtjeto con los siguientes datos
   * updated: TRRRUE si  el cambio se realizo correctamente
   * status estado de la peticion http
   * body cuerpo de la respuesta solo en caso de que sea correcto
   */
  async updateTicket(objectID: any, data: any) {
    const res = await this.mongoSrv.update(`/tickets/v2`,objectID,data);
    return res
  }

  /** Método para obtener el SLA de un ticket 
   * @param ticketId número de ticket
   * @returns objeto con datos de SLA del ticket, si se produce algun error devuelve undefined
  */
  async getTicketSLA(ticketId: any) {
    const res = await this.mongoSrv.get(`/tickets/sla/${ticketId}`, []);
    
    if (res && res.d && res.d.results) {
      return res.d.results[0];
    } else {
      return undefined;
    }
  }

  /** Método para generar SLA de un ticket
   * @param data datos a enviar
   * @returns TRUE cuando se carga correctamente, sino FALSE
   */
  async postTicketSLA(data: {"IdTicket": string}) : Promise<boolean>{
    const res = await this.mongoSrv.create(`/tickets/sla/v2`,data);
    if (res.posted) {
      return true;
    } else {
      return false;
    }   
  }

  /** Método para asociar un particiante a un ticket
   * @param ticketId numero del ticket al cual asociar
   * @param datos del participante
   * @return objeto con datos de asociación, si se produce algún error devuelve undefined
   */
  async associatePartner(ticketId: string, data: {RoleCategoryCode: string,RoleCode: string,PartyID: string}) {
    const res = await this.mongoSrv.create(`/tickets/asociar-partner/v2/${ticketId}`,data);
    if (res.posted) {
      return true;
    } else {
      return false;
    }
  }

  /** Método para crear un nuevo participante
   * @patam data datos del nuevo particpante
   * @returns datos del nuevo particpante creado, si se produce algun error devuelve undefined
   */
  async createPartner(data: {FirstName: string,LastName: string, Phone: string,Email: string, Z_CONTACTO_PORTAL_ENT_KUT: string}) : Promise<any | undefined> {
    const res = await this.mongoSrv.create(`/tickets/partner/v2/`,data);
    if (res.posted) {
      return res.body.data.d.results;
    } else {
      return undefined;
    }
  }

  /** Método para eliminar un participante
   * @param partnerID OID del participante
   * @returns TRUE cuando se realiza correctamente, sino FALSE
   */
  async deleteParticipante(PartnerID: any) {
    return await this.mongoSrv.delete(`/tickets/partner/v2/${PartnerID}`, []);
  }

  /** Método para obtener los participantes de un ticket 
   * @param ticketOID OID del ticket
   * @returns lista de participantes, si no encuentra el ticket o se produce un error devuelve undefined
  */
  async getParticipants(ticketOID: any) {
   
    const data = await this.mongoSrv.get(`/tickets/${ticketOID}/participantes`,[]);
    if(data && data.length > 0) {
      return data;
    } 
    return undefined;
  }

  /** Método para obtener los contactos asociados a un participante
   * @param partyId identificador del participante
   * @returns datos del contacto, si se produce algun error devuelve undefined
   */
  async getParticipantContact(partyId: string) {
    const params: Param[] = [];
    params.push({ key: 'PartyID', value: partyId })
    const data = await this.mongoSrv.get(`/tickets/partner-contact`,[]);
    if(data) {
      return data;
    } 
    return undefined;
  }

  /** Método para obtener participantes asociados a un correo electrónico
   * @param email correo electrónico del participante a buscar
   * @returns lista de contactos asociados al correo electrónico, si se produce un error devuelve undefined
   */
  async getParticipanteByEmail(email: string) {
    const params: Param[] = [];
    params.push({ key: 'Email', value: email })
    const data = await this.mongoSrv.get(`/tickets/partner-contact`,params);
    if(data) {
      return data;
    } 
    return undefined;
  }

  /** Método para asociar dos tickets
   * @param parentObjectID OID del ticket padre
   * @param ticketId número del ticket hijo
   * @returns TRUE cuando se realiza correctamente, sino FALSE
   */
  async asociarTicket(parentObjectID: string, ticketId: string) {
    const res = await this.mongoSrv.create(`/tickets/asociar`,{parentObjectID, ticketId} )
    if (res.posted) {
      return true;
    } else {
      return false;
    }
  }

  /** Método para obtener ticket asociado a un ticket
   * @param objectID identificador del tiquet
   * @returns objeto con datos del ticket, si se produce algún error devuelve undefined
   */
  async getTicketAsociado(objectID: string) {
    return this.getTicketHijo(objectID)
  }

  /** Método para obtener tickets hijos de un ticket
   * @param objectID identificador del ticker padre
   * @returns objeto con datos del ticket hijo, si se produce algún error devuelve undefined
   */
  async getTicketHijo(objectID: any) {
    const res = await this.mongoSrv.get(`/tickets/hijos/${objectID}`, []);
    
    if (res instanceof HttpResponse) {
      return res.body.d.results;
    } else {
      return undefined;
    }
  }

}


