import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { lastValueFrom } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import packageInfo from '../../../package.json';
 
@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  reloadCount=0;
  appName = packageInfo.name;
  
  constructor(
    private requestSrv: RequestService,
    private recaptchaV3Srv: ReCaptchaV3Service
  ) {}

  async createToken(action: string) {
    const $source = this.recaptchaV3Srv.execute(action);
    const token = await lastValueFrom($source);
    return token as string;
  }
  

  /**
   * Método que se encarga de verificar un token en la API
   * @param token Token captcha
   * @returns 
   */
  async verify(token: string) {
    const $source = this.requestSrv.postRequest('API',`/verify/${token}/${this.appName}`,null)
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      if (res.body.success) {
        if (res.body.score>0.5) {
          return 0;
        } else {
          return 1;
        }
      } else {
        if (res.body['error-codes'].includes("timeout-or-duplicate")) {
          if (this.reloadCount<3) {
            return 2;
          }
        }
        return 3;
      }
    } else {
      return 4
    }
  }
}