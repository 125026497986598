<!-- Sidebar -->
<div class="d-flex flex-column flex-shrink-0 sidebar-header">
    <a class="d-block d-flex justify-content-center p-2 link-dark text-decoration-none user-initials2">
        <div class="d-flex justify-content-center align-items-center iniciales-container-sidebar my-1">
            <span class="sidebar-initials">{{userInitials.toUpperCase()}}</span>
        </div>                
    </a>

    <!-- Lista de elementos menu lateral -->
    <div class="sidebar-scroll">
        <ul class="nav nav-pills nav-flush flex-column mb-auto text-center" >
            <li *ngFor="let item of menuItems"
                [ngClass]="{'py-3 sidebar-header-item-container mx-1 my-1 pointer':checkRol(item)}">
                <div [routerLink]="item.routerlink" class="d-flex flex-column text-center align-items-center" 
                        *ngIf="checkRol(item)" 
                        [ngClass]="{'selection-background':selection===item.key}">
                        <fa-icon [icon]="item.icon" class="fa-2x"></fa-icon>
                    <span>{{ item.titulo }}</span>
                </div>
            </li>
        </ul>
    </div>
    <!-- Ultimo Elemento -->
    <div class="d-flex flex-column mt-auto text-center align-items-center py-2 sidebar-header-item-container mx-1 my-1 pointer" (click)="signOut()">
        <fa-icon [icon]="faSignOutAlt" class="fa-2x puntero"></fa-icon>
        <span>Cerrar Sesion</span>
    </div>
</div>
