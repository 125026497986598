import { Component, OnInit } from '@angular/core';
import { faSearch, faFileExcel, faFilter } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Entidad, Usuario } from 'src/app/interfaces/interfaces';
import { AuditService } from 'src/app/services/audit.service';
import { LogService } from 'src/app/services/log.service';
import { MongoDBService } from 'src/app/services/mongoDB.service';
import { UsersService } from 'src/app/services/users.service';
import { dateConvertToGrid } from 'src/app/utils/app-utils';
import { ToastrService } from 'ngx-toastr';
import { WorkBook, WorkSheet, utils, writeFile } from 'xlsx';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listado-auditoria',
  templateUrl: './listado-auditoria.component.html',
  styleUrls: ['./listado-auditoria.component.scss']
})
export class ListadoAuditComponent implements OnInit {

  faSearch = faSearch;
  faFileExcel = faFileExcel;
  faFilter = faFilter;

  entities: Entidad[] = [];
  users: Usuario[] = [];
  filteredUsers: Usuario[] = [];
  selectedEntity: Entidad = this.usersSrv.getActualUser().entidad!;
  selectedUser: any;
  dateSince: string = moment(new Date()).format('YYYY-MM-DD');
  dateUntil: string = moment(new Date()).format('YYYY-MM-DD');

  tableConfig = {
    url: '/auditoria/v2/',
    columnas: [
      {
        field: 'createdDate', header: 'Fecha/Hora',
        renderer: (data) => {
          return dateConvertToGrid(data.createdDate)
        }
      },
      { field: 'usuario', header: 'Usuario' },
      { field: 'entidad', header: 'Entidad' },
      { field: 'modulo', header: 'Módulo' },
      { field: 'accion', header: 'Acción' },
      { field: 'detalle', header: 'Detalle',
        renderer: (data) => {
          if (data.detalle=='{}') {
            return ''
          } else {
            return data.detalle;
          }
        }
      }
    ],
    sort: {
      orderBy: "createdDate",
      orderDir: "desc"
    }
  };
  tableFilter: any;
  loading = true;
  loadingMessage = new BehaviorSubject("");

  constructor (
    private usersSrv: UsersService,
    private auditSrv: AuditService,
    private mongoSrv: MongoDBService,
    private logSrv: LogService,
    private toastr:ToastrService,
    private route: ActivatedRoute
  ) {
  }

  async ngOnInit() {
    this.loading = true;
    this.loadingMessage.next("Obteniendo datos");
    await this.route.params.subscribe(params => {
      if (params['username']) {
        this.selectedUser = params['username'];
      }
    });
    this.filter();
    this.loading =false;
    this.getUsers();
  }

  async getUsers() {
    const nUsers = await this.usersSrv.getUsers();
    if (nUsers) {
      this.users = nUsers;
      this.filteredUsers = nUsers;
    }
  }

  filter() {
    const nFilter : any = {};
    nFilter.entidadId = this.selectedEntity.id;
    if (this.selectedUser) {
      nFilter.usuario= this.selectedUser;
    }
    let fDate;
    if (this.dateSince && this.dateUntil) {
      fDate = `${this.dateSince};${this.dateUntil}`;
    } else if (this.dateSince || this.dateUntil) {
      
      fDate = this.dateSince ? this.dateSince : 
      `2000-01-01;${this.dateUntil}`;
    }
    if (fDate) {
      nFilter.createdDate = fDate;
    }
    this.tableFilter = nFilter;
    this.auditSrv.logAction({
       modulo: 'Auditoría',
       accion: 'Obtener datos de auditoría'
    })
  }

  async exportExcel(){
    this.loading=true;
    this.loadingMessage.next("Preparando datos para exportar");
    const params = [{key:'filtros', value: JSON.stringify(this.tableFilter)}]
    const data = await this.mongoSrv.get('/auditoria/v2/',params)
    const toExport: any = [];
    if (data.datos.length>0) {
      data.datos.forEach(f => {
        const row = {
          "Fecha/Hora": moment(f.createdDate).format("DD/MM/YYYY HH:mm:ss"),
          "Usuario": f.usuario,
          "Entidad": f.entidad,
          "Módulo": f.modulo,
          "Acción": f.accion,
          "Detalle": f.detalle
        }
        toExport.push(row);
      })
      setTimeout(() => {   
        this.loadingMessage.next("Exportando");
        try {
          // instancia el libro 
          const wb: WorkBook = utils.book_new();   
          const ws: WorkSheet = utils.json_to_sheet(toExport); 
          // // crea variables con tamaño de las columnas
          const wscols = [
            {wch:20}, //Fecha
            {wch:30}, //Usuario
            {wch:10}, //Acción
            {wch:30}, //Modulo
            {wch:10}, //Acción
            {wch:10}, //Detalle
          ];
          // asigna variable a la tabla
          ws['!cols'] = wscols;
          // genera el libro y agrega la hoja
          utils.book_append_sheet(wb, ws, 'Hoja1');
          // guarda el archivo
          writeFile(wb, 'Auditoria.xlsx');
          // Registrar acción
          const filters : any = {};
          filters.entidad = this.selectedEntity.nombre;
          if (this.selectedUser) {
            filters.usuario= this.selectedUser;
          }
          if (this.dateSince) {
            filters.desde = this.dateSince;
          } 
          if (this.dateUntil) {
            filters.hasta = this.dateUntil;
          }
          this.auditSrv.logAction({modulo:'Auditoría',accion:'Exportar datos de auditoría',detalle: JSON.stringify(filters)});
        } catch (e:any) {
          this.logSrv.sendErrorNotification(e.toString(),'','','');
        }
        this.loading=false;
      },50)
    } else {
      this.loading=false;
      this.toastr.warning('No existen datos para exportar','',{positionClass:'toast-top-center', progressBar:false});
    }
  }

  filterUsers(entityID) {
    this.selectedUser = undefined;
    this.filteredUsers = this.users.filter(user => user.entidad!.id==entityID);
  }

}
