  import { Component } from '@angular/core';
  import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
  import { faCheck, faTimes, faExclamationTriangle, faInfo } from '@fortawesome/free-solid-svg-icons';
  
  @Component({
    selector: '[custom-toast-component]',
    styleUrls: [`./custom-toast.component.scss`],
    templateUrl: `./custom-toast.component.html`,
    animations: [
    ],
    preserveWhitespaces: false,
  })
  export class CustomToastComponent extends Toast {

    //iconos
    faTimes=faTimes;
    faCheck=faCheck;
    faExclamationTriangle=faExclamationTriangle;
    faInfo=faInfo;

    // constructor is only necessary when not using AoT
    constructor(
      protected toastrService: ToastrService,
      public toastPackage: ToastPackage,
    ) {
      super(toastrService, toastPackage);
    }
  
    action(event: Event) {
      event.stopPropagation();
      this.toastPackage.triggerAction();
      return false;
    }
  }