
<div class="row px-3 ">

    <div class="col-12">
        <span class="page-route">Usuarios</span>
    </div>

    <div class="col-12 py-3">
        <app-title></app-title>
    </div>

    <div class="col-md-12 col-lg-12">
        

        <div class="row">

            <div class="col-md-3">

                <div class="form-group">
                    <div class="d-flex align-items-center">
                        <input type="text" class="main-content-input-ticket filtro-ticket"
                            placeholder="Buscar por nombre" [(ngModel)]="filtroNombre"
                            (keyup.enter)="filtrar()">
                    </div>
                </div>

            </div>

            <div class="col-md-3">

                <div class="form-group">
                    <div class="d-flex align-items-center">
                        <input type="text" class="main-content-input-ticket filtro-ticket"
                            placeholder="Buscar por apellido" [(ngModel)]="filtroApellido"
                            (keyup.enter)="filtrar()">
                    </div>
                </div>

            </div>

            <div class="col-md-3">

                <div class="form-group">
                    <div class="d-flex align-items-center">
                        <input type="text" class="main-content-input-ticket filtro-ticket"
                            placeholder="Buscar por nombre de usuario" [(ngModel)]="filtroUsuario"
                            (keyup.enter)="filtrar()">
                    </div>
                </div>

            </div>

            <div class="col-md-3">

                <ng-select class="ng-selec-portal" [(ngModel)]="filtroRol" [clearable]="false" [searchable]="true"
                    [multiple]="true" placeholder="Buscar por roles">
                    <ng-option value="">Todos</ng-option>
                    <ng-option *ngFor="let rol of roles" [value]="rol.id">{{rol.descripcion}}</ng-option>
                </ng-select>

            </div>
            <div class="col-md-3">
                <ng-select class="ng-selec-portal" [(ngModel)]="filtroActivos" [clearable]="false"
                    [searchable]="false" placeholder="Usuario activo o inactivo">
                    <ng-option value="">Todos
                    </ng-option>
                    <ng-option value="true">Activos
                    </ng-option>
                    <ng-option value="false">Inactivos
                    </ng-option>
                </ng-select>
            </div>
        </div>
        

        <div class="row mt-2">
            <div class="col-md-3">
                <div class="d-flex align-items-center">
                    <button type="submit" class="btn btn-primary-portal btn-primary btn-sm mr-2" (click)="filtrar()">Filtrar</button>
                    <button class="btn btn-outline-secondary btn-sm mx-2" (click)="limpiar()">Limpiar</button>
                </div>
            </div>
        </div>
    
    <div class="d-flex">
        <div class="ml-auto mt-2">

            <div class="d-flex align-items-center pointer" (click)="crearUsuario()"
                *hasPermission="['SEC_USUARIOS_OPERACION']">
                <button class="btn btn-primary-portal btn-primary btn-sm mr-2" type="button">
                    <span class="text-btn-main">  <fa-icon [icon]="faPlus" class="mr-1"></fa-icon> Nuevo Usuario</span>
                </button>
            </div>
        </div>

    </div>
</div>
<p-contextMenu #cm [model]="items"></p-contextMenu>
<div class="col-12 py-4">
    <p-table [value]="usuariosList" [loading]="loading">
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="6">
                    <div class="d-flex justify-content-center py-2">
                        <span *ngIf="!loading" class="span-empty">No existen usuarios.</span>
                        <span *ngIf="!loading" class="span-empty">No se han encontrado usuarios.</span>
                        <fa-icon *ngIf="loading" [icon]="faSpinner" animation="spin"></fa-icon>
                    </div>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th>Usuario</th>
                <th>Email</th>
                <th>Rol</th>
                <th>Entidad</th>
                <th>Estado</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-usuario>
            <tr>
                <td>
                    <div class="d-flex flex-row align-items-center">
                        <div class="d-flex flex-column">
                            <div class=" iniciales-container">
                                <span
                                    class="iniciales  justify-content-center align-items-center d-flex">{{usuario.nombre?.slice(0,1).toUpperCase()}}</span>
                            </div>
                        </div>
                        <div class="d-flex flex-column pb-1 px-1">
                            {{usuario.nombre ? usuario.nombre :'SIN DATO'}}
                        </div>
                    </div>
                </td>
                <td>{{ usuario.email }}</td>
                <td>
                    <ng-container *ngIf="usuario.roles">
                        <span class="badge badge-success mr-1" *ngFor="let rol of usuario.roles">{{rol ? rol.codigo : ''}}</span>
                    </ng-container>
                </td>
                <td>
                    <div class="d-flex flex-row align-items-center">
                        <div class="d-flex flex-column">
                            <div class=" iniciales-container">
                                <span
                                    class="iniciales  justify-content-center align-items-center d-flex">{{usuario.entidad.nombre.slice(0,1).toUpperCase()}}</span>
                            </div>
                        </div>
                        <div class="d-flex flex-column pb-1 px-1">
                            {{usuario.entidad.nombre}}
                        </div>
                    </div>
                </td>
                <td>{{usuario.activo ? 'Activo' : 'Inactivo'}}</td>
                <td>
                    <button class="entity-option-btn" (click)="showContextMenu($event,cm, usuario)"> ... </button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="d-flex justify-content-end py-3">
        <ngb-pagination *ngIf="usuariosList.length != 0" [(page)]="page" [maxSize]="3"
            [pageSize]="pageSize" [collectionSize]="collectionSize" [boundaryLinks]="true"
            (pageChange)="getUsuarios()"> </ngb-pagination>
    </div>
</div>

<!---------------- MODALES----------------->
<ng-template #inactivar let-modal>
    <div class="modal-body modal-background">
        <div class="row row-cols-lg-1 py-2  justify-content-center">
            <div class="col-lg-8 text-center text-modal">
                <span>¿Desea eliminar</span>&nbsp;
                <span>realmente el Usuario?</span>
            </div>
        </div>
        <div class="row row-cols-lg-1 py-3 justify-content-center">
            <div class="col-lg-8 text-center text-modal">
                <div class=" d-flex row text-center justify-content-center">
                    <span class="span-modal">{{selectedUser!.email}}</span>
                </div>
                <div class="d-flex row text-center justify-content-center">
                    <span class="span-modal id-modal"></span>
                </div>

            </div>
        </div>
        <div class="row row-cols-4 row-cols-lg-4 mx-0  py-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <button type="button" (click)="modal.dismiss()" (click)="changeStatus(selectedUser!,false)"
                        class="btn btn-primary-portal btn-primary btn-sm btn-modal">
                        <span>Inactivar</span>
                    </button>
                </div>
            </div>

        </div>
        <div class="row row-cols-4 row-cols-lg-4 mx-0  pb-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex  justify-content-center">
                    <button type="button" (click)="modal.dismiss()" class="btn-cancel-modal">
                        <span>Cancelar</span>
                    </button>
                </div>
            </div>

        </div>

    </div>
</ng-template>
<ng-template #activar let-modal>
    <div class="modal-body modal-background">
        <div class="row row-cols-lg-1 py-2  justify-content-center">
            <div class="col-lg-8 text-center text-modal">
                <span>¿Desea activar</span>&nbsp;
                <span>realmente el Usuario?</span>
            </div>
        </div>
        <div class="row row-cols-lg-1 py-3 justify-content-center">
            <div class="col-lg-8 text-center text-modal">
                <div class=" d-flex row text-center justify-content-center">
                    <span class="span-modal">{{selectedUser!.email}}</span>
                </div>
                <div class="d-flex row text-center justify-content-center">
                    <span class="span-modal id-modal"></span>
                </div>
            </div>
        </div>
        <div class="row row-cols-4 row-cols-lg-4 mx-0  py-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <button type="button" (click)="modal.dismiss()" (click)="changeStatus(selectedUser!,true)"
                        class="btn btn-primary-portal btn-primary btn-sm btn-modal">
                        <span>Activar</span>
                    </button>
                </div>
            </div>

        </div>
        <div class="row row-cols-4 row-cols-lg-4 mx-0  pb-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex  justify-content-center">
                    <button type="button" (click)="modal.dismiss()" class="btn-cancel-modal">
                        <span>Cancelar</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #resetearPassword let-modal>
    <div class="modal-body modal-background">
        <div class="row row-cols-lg-1 py-2  justify-content-center">
            <div class="col-lg-8 text-center text-modal">
                <span>¿Desea restaurar</span>&nbsp;
                <span>la contraseña del usuario?</span>
            </div>
        </div>
        <div class="row row-cols-lg-1 py-3 justify-content-center">
            <div class="col-lg-8 text-center text-modal">
                <div class=" d-flex row text-center justify-content-center">
                    <span class="span-modal">{{selectedUser?.email}}</span>
                </div>
                <div class="d-flex row text-center justify-content-center">
                    <span class="span-modal id-modal"></span>
                </div>
            </div>
        </div>
        <div class="row row-cols-4 row-cols-lg-4 mx-0  py-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <button type="button" (click)="sendRecovery(selectedUser!.email); modal.dismiss()"
                        class="btn btn-primary-portal btn-primary btn-sm btn-modal">
                        <span>Enviar</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row row-cols-4 row-cols-lg-4 mx-0  pb-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex  justify-content-center">
                    <button type="button" (click)="modal.dismiss()" class="btn-cancel-modal">
                        <span>Cancelar</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

    
</div>
