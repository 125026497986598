import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Page_Routing } from './pages.routes'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { PagesComponent } from './pages.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { UserListComponent } from './usuarios/listado-usuarios/listado-usuarios.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { CrearUsuarioComponent } from './usuarios/crear-usuario/crear-usuario.component';
import { EditarUsuariosComponent } from './usuarios/editar-usuarios/editar-usuarios.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../services/auth/auth-interceptor.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { TicketsComponent } from './tickets/tickets.component';
import { ReportesComponent } from './reportes/reportes.component';
import { ListadoReportesComponent } from './reportes/listado-reportes/listado-reportes.component';
import { AuditoriaComponent } from './auditoria/auditoria.component';
import { ListadoAuditComponent } from './auditoria/listado-auditoria/listado-auditoria.component';
import { CrearTicketComponent } from './tickets/crear-ticket/crear-ticket.component';
import { PieChartComponent } from './reportes/pie-chart/pie-chart.component';
import { OportunidadComercialComponent } from './oportunidad-comercial/oportunidad-comercial.component';
import { ListadoOCComponent } from './oportunidad-comercial/listado-oc/listado-oc.component';
import { DndDirective } from './tickets/crear-ticket/dnd.directive';
import { CrearOcComponent } from './oportunidad-comercial/crear-oc/crear-oc.component';
import { DetalleTicketComponent } from './tickets/detalle-ticket/detalle-ticket.component';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TablaProductosAsociadosComponent } from './oportunidad-comercial/crear-oc/tabla-productos-asociados/tabla-productos-asociados.component';
import { DetallesOcComponent } from './oportunidad-comercial/detalles-oc/detalles-oc.component';
import { TablaCuentasBancariasDetalleComponent } from './oportunidad-comercial/detalles-oc/tabla-cuentas-bancarias-detalle/tabla-cuentas-bancarias-detalle.component';
import { TablaProductosAsociadosDetalleComponent } from './oportunidad-comercial/detalles-oc/tabla-productos-asociados-detalle/tabla-productos-asociados-detalle.component';
import { ApplicationPipesModule } from '../pipes/application-pipes.module';
import { TableModule } from 'primeng/table';
import { ContextMenuModule } from 'primeng/contextmenu';
import { HasPermissionDirective } from '../directives/has-permission.directive';
import { HelpComponent } from './help/help.component';
import { AccordionModule } from 'primeng/accordion';
import { DocumentosImportantesComponent } from './documentos-importantes/documentos-importantes.component';
import { ListadoDocumentosImportantesComponent } from './documentos-importantes/listado-documentos-importantes/listado-documentos-importantes.component';
import { CatalogoProductosComponent } from './catalogo-productos/catalogo-productos.component';
import { ListadoCatalogoProductosComponent } from './catalogo-productos/listado-catalogo-productos/listado-catalogo-productos.component';
import { FormularioCatalogoProductosComponent } from './catalogo-productos/formulario-catalogo-productos/formulario-catalogo-productos.component';
import { ChartModule } from 'primeng/chart';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { ListadoNotificacionesComponent } from './notificaciones/listado-notificaciones/listado-notificaciones.component';
import { GraficosEvaluacionComponent } from './reportes/graficos-evaluacion/graficos-evaluacion.component';
import { ListadoTicketsComponent } from './tickets/listado-tickets/listado-tickets.component';
import { ResumenOcComponent } from './oportunidad-comercial/detalles-oc/resumen-oc/resumen-oc.component';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { BienvenidaComponent } from './bienvenida/bienvenida.component';
import { HistorialLoginComponent } from './historial-login/historial-login.component';
import { ActividadesVentasOcComponent } from './oportunidad-comercial/detalles-oc/actividades-ventas-oc/actividades-ventas-oc.component';
import { MaskCardNumberDirective } from '../directives/mask-card-number.directive';

@NgModule({
  declarations: [
    PagesComponent,
    UsuariosComponent,
    UserListComponent,
    CrearUsuarioComponent,
    EditarUsuariosComponent,
    TicketsComponent,
    ReportesComponent,
    ListadoReportesComponent,
    AuditoriaComponent,
    ListadoAuditComponent,
    CrearTicketComponent,
    PieChartComponent,
    OportunidadComercialComponent,
    ListadoOCComponent,
    DndDirective,
    CrearOcComponent,
    DetalleTicketComponent,
    SafeHtmlPipe,
    TablaProductosAsociadosComponent,
    DetallesOcComponent,
    TablaCuentasBancariasDetalleComponent,
    TablaProductosAsociadosDetalleComponent,
    HasPermissionDirective,
    HelpComponent,
    DocumentosImportantesComponent,
    ListadoDocumentosImportantesComponent,
    CatalogoProductosComponent,
    ListadoCatalogoProductosComponent,
    FormularioCatalogoProductosComponent,
    NotificacionesComponent,
    ListadoNotificacionesComponent,
    GraficosEvaluacionComponent,
    ListadoTicketsComponent,
    ResumenOcComponent,
    HistorialLoginComponent,
    ActividadesVentasOcComponent,
    BienvenidaComponent,
    MaskCardNumberDirective
  ],
  imports: [
    BrowserModule,
    NgbModule,
    Page_Routing,
    FormsModule,
    SharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxSkeletonLoaderModule,
    ApplicationPipesModule,    
    TableModule,
    ContextMenuModule,
    AccordionModule,
    ChartModule,
    EditorModule,
    ButtonModule,
    TooltipModule
  ],
  exports:[
    RouterModule,
    HasPermissionDirective
  ],
  providers:[
    {
      provide:HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  bootstrap: [],
})
export class PagesModule { }