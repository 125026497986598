import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { TituloComponent } from './titulo/titulo.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../services/auth/auth-interceptor.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { FieldErrorComponent } from './field-error/field-error.component';
import { ComentariosComponent } from './comentarios/comentarios.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SafeCommentsPipe } from '../pipes/safe-comments.pipe';
import { AdjuntosComponent } from './adjuntos/adjuntos.component';
import { NuevoAdjuntoComponent } from './adjuntos/nuevo-adjunto/nuevo-adjunto.component';
import { TimerComponent } from './timer/timer.component';
import { EstadosComponent } from './estados/estados.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LoadingSpinnerComponent } from './loadingSpinner/loadingSpinner.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';// import { BanTableComponent } from './ban-table/ban-table.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ContextMenuModule } from 'primeng/contextmenu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NotificacionsPanelComponent } from './notificacions-panel/notificacions-panel.component';
import { TooltipModule } from 'primeng/tooltip';
import { BpeGridComponent } from './bpe-grid/bpe-grid.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { FormEncuestaComponent } from './form-encuesta/form-encuesta.component';
import {RatingModule} from 'primeng/rating';
import {ScrollerModule} from 'primeng/scroller';
import { PreContentComponent } from './pre-content/pre-content.component';
import { SkeletonModule } from 'primeng/skeleton';
import { DownloadProgressComponent } from './download-progress/download-progress.component';

@NgModule({
  declarations: [
    HeaderComponent,
    TituloComponent,
    CustomToastComponent,
    FieldErrorComponent,
    ComentariosComponent,
    SafeCommentsPipe,
    AdjuntosComponent,
    NuevoAdjuntoComponent,
    TimerComponent,
    EstadosComponent,
    SidebarComponent,
    LoadingSpinnerComponent,
    ConfirmationComponent,
    NotificacionsPanelComponent,
    BpeGridComponent,
    FormEncuestaComponent,
    BpeGridComponent,
    PreContentComponent,
    DownloadProgressComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgxSkeletonLoaderModule,
    TableModule,
    ButtonModule,
    ContextMenuModule,
    RatingModule,
    TooltipModule,
    OverlayPanelModule,
    ScrollerModule,
    SkeletonModule,
    EditorModule
  ],
  exports: [
    HeaderComponent,
    TituloComponent,
    CustomToastComponent,
    FieldErrorComponent,
    ComentariosComponent,
    AdjuntosComponent,
    NuevoAdjuntoComponent,
    TimerComponent,
    EstadosComponent,
    LoadingSpinnerComponent,
    ConfirmationComponent,
    EstadosComponent,
    SidebarComponent,
    BpeGridComponent,
    FormEncuestaComponent,
    NotificacionsPanelComponent,
    PreContentComponent,
    DownloadProgressComponent
  ],
  providers:[
    NgbActiveModal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class SharedModule { }
