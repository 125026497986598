import { Routes } from "@angular/router";
import { CrearTicketComponent } from "./crear-ticket/crear-ticket.component";
import { DetalleTicketComponent } from "./detalle-ticket/detalle-ticket.component";
import { DataTicket } from "./tickets.routes.data";
import { PermGuardService } from "../../services/perm-guard.service";
import { ListadoTicketsComponent } from "./listado-tickets/listado-tickets.component";

export const Tickets_Routes: Routes = [
    {path: 'list', component: ListadoTicketsComponent,data: DataTicket.listado, canActivate:[PermGuardService]},
    {path: 'crearticket', component: CrearTicketComponent,data: DataTicket.crear, canActivate:[PermGuardService]},
    {path: ':id/detalle', component: DetalleTicketComponent,data: DataTicket.detalle, canActivate:[PermGuardService]},
    {path: '**', pathMatch: 'full', redirectTo: 'list'}
  ]
  ;
   
