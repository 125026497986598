import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Documento, Param } from '../interfaces/interfaces';
import { ToastrService } from 'ngx-toastr';
import { Constantes } from '../constants/constantes.const';
import { Mensajes } from '../constants/mensajes.const';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { DataService } from './data.service';
import { MongoDBService } from './mongoDB.service';
import { UsersService } from './users.service';
import { HttpResponse } from '@angular/common/http';
import FileUtils from '../utils/fileUtils';


@Injectable({
  providedIn: 'root'
})
export class AdjuntosService {

  loading = false;
  loadingData = false;
  loadingMessage = new BehaviorSubject("");
  constructor(
    private requestSrv: RequestService,
    private toastr: ToastrService,
    private usersSrv: UsersService,
    private dataSrv: DataService,
    private mongoSrv: MongoDBService
  ) { }

  /** Método para obtener todos los adjuntos asociados a un ticket u oportunidad comercial
   * @param id Número de Ticket u OC
   * @param tipo Ticket: 'TK', Oportunidad Comercial: 'OC'
   * @return lista de adjuntos
   */
  async getAdjuntos(id: any, tipo: 'TK' | 'OC') {
    const nDocuments: Documento[] = [];
    const endpoint = `/adjuntoDomus/${id}/${tipo}`;
    const documents = await this.mongoSrv.getAll(endpoint);
    if (documents) {
      documents.forEach(documento => {
        const nDocumento: Documento = {
          pk: documento.D_PK,
          titulo: documento.D_Titulo,
          fechaCreacion: documento.D_FechaCreacion,
          usuario: documento.D_UsuarioCreacion,
          borrable: documento.D_Entidad==this.usersSrv.getActualUser().entidad?.id,
          contieneInformacionSensible: documento.contieneInformacionSensible,
          documentoSeguro: documento.documentoSeguro,
        }
        nDocuments.push(nDocumento);
      })
    }
    return nDocuments;
  }

  /** Método para alzar un documento a domus
   * @param data datos del documento
   * @return TRUE si el archivo subio correctamente, sino FALSE
   */
  async uploadDocument(data: any) {
    const res = await this.mongoSrv.create('/adjunto',data, true)
    if (res && res.posted) {
      return { uploaded: res.body.code == 201}
    } else {
      return { uploaded: false}
    }
  }

  /** Método para obtener el c ontenido de un archivo
   * @param datas JSON con la pk y el templateID
   */
  async getContent(datas: { templateId: string, PK: string }) {
    const params : Param[] = [
      {
        key: 'templateId',
        value: datas.templateId
      },
      {
        key: 'PK',
        value: datas.PK
      }
    ]
    const res = await this.mongoSrv.get('/adjunto',params, true)
    if (res) {
      return res;
    } else {
      return {retrieved: false}
    }
    
  }

  /** Método para obtener el c ontenido de un archivo
   * @param datas JSON con la pk y el templateID
   */
  async getSensitiveContent(secureDocumentId: string) {
    const params : Param[] = [
      {
        key: 'id',
        value: secureDocumentId
      }
    ]
    const res = await this.mongoSrv.get('/documento-seguro',params)
    
    if(res){
      this.downloadSecureDocument(res.filename,res.file);
    } else{
      this.toastr.warning("No es posible obtener el documento");
    }
    
  }

  /**
   * Metodo para descargar un documento seguro, convierte el base64 a un archivo y lo descarga
   * @param filename 
   * @param fileBase64 
   */
  downloadSecureDocument(filename: string, fileBase64: string) {
    
    const blob = this.dataURItoBlob(fileBase64)
    const file = new File([blob],filename, { type:  FileUtils.getMimeType(filename) });
    const url= window.URL.createObjectURL(file);
    const anchor = document.createElement("a");
    anchor.download = filename;
    anchor.href = url;
    anchor.click();

    // Liberar el objeto URL creado
    window.URL.revokeObjectURL(url);
  }

  /** Método para eliminar un documento de domus
   * @param datas JSON con la pk, el templateID y el usuario que elimina
   */
  async deleteDocument(datas: {"templateId": string,"PK": string, "UsuarioEliminacion": string}) {
    const params : Param[] = [
      {
        key: 'templateId',
        value: datas.templateId
      },
      {
        key: 'PK',
        value: datas.PK
      },
      {
        key: 'UsuarioEliminacion',
        value: datas.UsuarioEliminacion
      }
    ]
    return await this.mongoSrv.delete('/adjunto',params)
  }


  /** Método para eliminar un documento de goanywhere

   * @param datas JSON con la pk, el templateID y el usuario que elimina
   */
  async deleteSecureDocument(id: string) {
    const params : Param[] = [
      {
        key: 'id',
        value: id
      }
    ]
    return await this.mongoSrv.delete('/documento-seguro',params)
  }

  /** Método para obtener los tipos de adjuntos 
   * @param forOC TRUE para filtrar tipos de adjunto para OC, sino se mostrarán tipos de adjuntos para Ticket
  */
  async getTipoAdjuntos(forOC: boolean) {
    const endpoint = `/tipoAdjunto`;
    let types = await this.mongoSrv.getAll(endpoint);
    if (types) {
      if (!forOC) {
        types = types.filter(element => element.value == '99');
      }
    } else {
      this.toastr.error("No se pudo obtener los tipos de adjuntos");
    }
    return types;
  }


  /**
   * Método para comprobar si los documentos son válidos
   * @input files array de documentos a comprobar
   * @return TRUE si todos los documentos pasan las validaciones, sino false
   */
  async validfiles(files: File[], sensitiveDataValidation?: boolean) {
    let valid = true;
    let isSensitve = false;
    if (files) {
      for (const file of files) {
        if(sensitiveDataValidation && this.isInExtensionsSensible(file.type)) {
          const formData = new FormData();
          formData.append('archivo', file);
  
          const $source = this.requestSrv.postRequest('API', `/documento-seguro/check`, formData);
          const res = await lastValueFrom($source);
          if (res instanceof HttpResponse) {
            if ([200].includes(res.status)) {
              isSensitve = res.body.data;
            }
          }
        }

        const fileExtention = file.name.slice(file.name.lastIndexOf('.') + 1);
        if (fileExtention && !Constantes.VALID_EXTENTIONS.includes(fileExtention.toLowerCase())) {
          valid = false;
          this.toastr.error(Mensajes.EXTENSIONES + Constantes.VALID_EXTENTIONS, '', { positionClass: 'toast-top-center', progressBar: false })
        } else if (file.size && file.size > Constantes.MAX_FILE_SIZE) {
          valid = false;
          this.toastr.error(Mensajes.DOCUMENTO_SIZE, '', { positionClass: 'toast-top-center', progressBar: false })
        }
      }
    }
    return {valid, isSensitve};
  }


  /** Método para subir un adjunto de ticket 
   * @param document Documento a subir
   * @param idTicket número identificatorio del ticket
   * @param idCliente id del Cliente
   * @return Promesa de booleano conteniendo true si se alzo correctamente o false
   */
  async uploadFile(document: Documento,idTicket: string,idCliente: string) {    
    const client = await this.dataSrv.getClientByID(idCliente);
    if (client) {
      const req=
      {
        CodCliente: client.codCliente,
        CodSucursal: client.codSucursal,
        IncidenteId: idTicket,
        OCId: idTicket,
        PK: `C4C${idTicket}${Math.floor(1+Math.random()*900000)}`,
        TIPOADJUNTO: '99', //OTROS ADJUNTOS
        file: {
          fileB64: document.datos,
          title: document.tituloSolo,
          extension: document.extension
        },
        templateId: '997172', //TICKET
        user: this.usersSrv.getActualUser()!.email,
        volumenId: 5
      }
      const res = await this.uploadDocument(req);
      if (!res.uploaded) {
        this.toastr.error(Mensajes.NUEVO_ADJUNTO_ERROR, '', { positionClass: 'toast-top-center', progressBar: false });
      }
      return res.uploaded
    } else {
      this.toastr.error('No se pudo obtener datos del cliente', '', { positionClass: 'toast-top-center', progressBar: false });
      return false;
    }
  }

  /** Método para subir un adjunto de OC 
   * @param document Documento a subir
   * @param idOC número identificatorio del oc al cual asociar el adjunto
   * @param idCliente código del cliente asociado a la oc
   * @return Promesa de booleano conteniendo true si se alzo correctamente o false
   */
  async uploadOCFile(document: Documento,idOC: string,idCliente: string) {    
    const client = await this.dataSrv.getClientByID(idCliente);
    if (client) {
      const req=
      {
        CodCliente: client.codCliente,
        CodSucursal: client.codSucursal,
        IncidenteId: idOC,
        OCId: idOC,
        PK:'C4C'+idOC+(Math.floor(1+Math.random()*900000)),
        TIPOADJUNTO: document.tipo?.id,
        file: {
            fileB64: document.datos,
            title: document.tituloSolo,
            extension: document.extension
        },
        templateId: 1152789, //OC
        user:this.usersSrv.getActualUser().email,
        volumenId: 5
      }
      const res = await this.uploadDocument(req);
      if (!res.uploaded) {
        this.toastr.error(Mensajes.NUEVO_ADJUNTO_ERROR,'',{positionClass:'toast-top-center', progressBar:false});
      } 
      return res.uploaded
    } else {
      this.toastr.error('No se pudo obtener datos del cliente','',{positionClass:'toast-top-center', progressBar:false});
      return false;
    }
  }

  async getDocument(pk: any, templateId: any, data: any, modulo?:any) {
    const req = {
      "NRODOCUMENTO": "",
      "NROCONTRATO": "",
      "FECALTCONT": "",
      "NOMBRECEDULA": "",
      "RUC": "",
      "FECDOCPTTE": "",
      "FECEMICED": "",
      "CodCliente": "",
      "CodSucursal": "",
      "IncidenteId": "",
      "OCId": "",
      "templateId": templateId,
      "Fecha": "",
      "NROCEDULA": "",
      "FECALTAOFAC": "",
      "FECDOCIVA": "",
      "PK": pk,

    }

    this.mongoSrv.logAction({
      aplicacion: `Portal de Entidades`,
      modulo: modulo ? modulo : 'Portal de Entidades',
      accion: 'Descarga de archivo domus',
      detalle: JSON.stringify({ ...req, fileName: data.dTitulo }),
      recursoId: data._id,
      coleccion: 'documentosadjuntos'
    })

    const dataDocument = await this.getContent(req);
    if (dataDocument.retrieved) {
      await this.dowloadFile(dataDocument.body)
    } else {
      this.toastr.error(Mensajes.ADJUNTOS_ERROR_OBTENERDATOS, "", {
        positionClass: "toast-top-center",
        progressBar: false,
      });
    }
  }


  /** Método para descargar un archivo de domus
   * @param data datos del archivo a descargar
   */
  dowloadFile(jsonData){
    let titulo = this.getValue(jsonData,'Titulo');
    titulo = `${titulo}.${jsonData.contents.extension.toLowerCase()}`
    let type = jsonData.contents.extension == 'pdf' || jsonData.contents.extension == 'docx' || jsonData.contents.extension == 'doc' || jsonData.contents.extension == 'xlsx' || jsonData.contents.extension == 'xls' ? 'application' : 'image';
    type = jsonData.contents.extension == 'txt' ? 'text' : type;
    let extension= `${jsonData.contents.extension}`;
    extension = jsonData.contents.extension == 'xlsx' ? 'vnd.ms-excel': extension;
    extension = jsonData.contents.extension == 'docx' ? 'vnd.openxmlformats-officedocument.wordprocessingml.document': extension;
    extension = jsonData.contents.extension == 'txt' ? 'plain' : extension;
    extension = jsonData.contents.extension == 'doc' ? 'msword' : extension;
    extension = jsonData.contents.extension == 'xls' ? 'vnd.ms-excel' : extension;

    const imageBlob = this.dataURItoBlob(jsonData.contents.content);
    const imageFile = new File([imageBlob],titulo, { type:  `${type}/${extension}`  });
    const url= window.URL.createObjectURL(imageFile);
    const anchor = document.createElement("a");
    anchor.download = titulo;
    anchor.href = url;
    anchor.click();
  }

  getValue(documento: any, name: any) {
    return documento.fields?.find(element => element.name == name) == undefined || documento.fields?.find(element => element.name == name).value == undefined ? '' : documento.fields?.find(element => element.name == name)?.value;
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array]);    
    return blob;
  }

  /** Método para subir un adjunto de OC 
   * @param document Documento a subir
   * @param idOC número identificatorio del oc al cual asociar el adjunto
   * @param idCliente código del cliente asociado a la oc
   * @return Promesa de booleano conteniendo true si se alzo correctamente o false
   */
  async uploadSecureFile(document: Documento,idOC: string) {    
    const formData = new FormData();
    
    const tipoadjunto = document.tipo?.id ? document.tipo?.id.toString() : '99';
    if(document.file){
      formData.append('archivo', document.file);
      formData.append('type','TK')
      formData.append('Z_ID',idOC)
      formData.append('Z_TipoAdjunto',tipoadjunto)
      formData.append('D_PK','C4C'+idOC+(Math.floor(1+Math.random()*900000)))
      
      const $source = this.requestSrv.postRequest('API', `/documento-seguro`, formData);
      const res = await lastValueFrom($source);
      if (res instanceof HttpResponse) {
        if ([200,201].includes(res.status)) {          
          return true
        } else {
          this.toastr.error(Mensajes.NUEVO_ADJUNTO_ERROR,'',{positionClass:'toast-top-center', progressBar:false});
          return false
        }
      } 
    }
    return false
  }

  isInExtensionsSensible(mimeType: string){
    if(mimeType && mimeType !='') {
      if(mimeType.includes('pdf')) {
          return true
      } else if(mimeType.includes('image')) {
        return true
      } else if(mimeType.includes('word')) {
        return true
      } else if(mimeType.includes('sheet') || mimeType.includes('excel') || mimeType.includes('csv')) {
        return true
      }
      return false
    }
    return false;
  }
}
