import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Ejecucion } from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class DownloadProgressService {

  private executions = new BehaviorSubject<Ejecucion>({isHidden: true});

  addExecutions(execution: Ejecucion) {
    this.executions.next(execution)
  }

  getExecutions(): Observable<Ejecucion> {
    return this.executions.asObservable();
  }

}
