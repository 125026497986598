import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { faSearch, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { Mensajes } from '../../../constants/mensajes.const';
import { Usuario, Basico, Param, FiltroUsuario } from '../../../interfaces/interfaces';
import { MongoDBService } from '../../../services/mongoDB.service';
import { RecoveryService } from '../../../services/recovery.service';
import { StorageService } from '../../../services/storage.service';
import { UsersService } from '../../../services/users.service';
import { environment } from 'src/environments/environment';
import { AuditService } from 'src/app/services/audit.service';
import { BehaviorSubject } from 'rxjs';
import { deleteEmptyData } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-listado',
  templateUrl: './listado-usuarios.component.html',
  styleUrls: ['./listado-usuarios.component.scss']
})
export class UserListComponent implements OnInit {



  title = '';

  @ViewChild('inactivar') inactivar: any;
  @ViewChild('activar') activar: any;
  @ViewChild('resetearPassword') resetearPassword: any;


  // filtros
  filtroNombre = "";
  filtroApellido = "";
  filtroUsuario = "";
  filtroRol: string[] = [""];
  filtroActivos = "";
  filtros;

  usuariosList: Usuario[] = [];
  USUARIOSLIST: Usuario[] = [];
  usuariosListFiltro: Usuario[] = [];
  entidades: string[] = [];
  selectedUser: Usuario | undefined;
  roles: Basico[] = [];

  //icons
  faSearch = faSearch;
  faPlus = faPlus;
  faSpinner = faSpinner;

  items: MenuItem[] = [];

  //Paginado
  page = 1;
  pageSize = 10;
  collectionSize = 0;

  //booleans
  loading = false;
  filtrando = false;
  loadingMessage = new BehaviorSubject("");


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private recoverySrv: RecoveryService,
    private storageSrv: StorageService,
    private toastr: ToastrService,
    private userSrv: UsersService,
    private modalService: NgbModal,
    private mongoSrv: MongoDBService,
    private auditSrv: AuditService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(`${this.activatedRoute.snapshot.data.titulo} - ${environment.app_name}`);
    this.getRoles();
    this.filtrar();
  }

  /** Método para obtener los usuarios */
  async getUsuarios() {
    this.loading = true;
    this.loadingMessage.next("Cargando usuarios");
    const params: Param[] = [];
    params.push({ key: "size", value: this.pageSize.toString() });
    params.push({ key: "page", value: this.page.toString() });
    const valueFilter = JSON.stringify(deleteEmptyData(this.filtros));
    if (valueFilter) {
      params.push({ key: "filtros", value: valueFilter });
    }

    const res = await this.userSrv.getUsersV2(params, true);
    if (res) {
      this.auditSrv.logAction({
        modulo: "Usuarios",
        accion: "Obtener usuarios",
        detalle: "Buscar: " + this.filtroUsuario + "; Roles: " + this.filtroRol.join(", ") + "; Entidad: " + this.userSrv.getActualUser().entidad.id + "; Estado: " + this.filtroActivos
      });
      this.collectionSize = res.total;
      this.usuariosList = res.users;
    } else {
      this.toastr.error(`Hubo un error al traer los datos necesarios.`, "", {
        positionClass: "toast-top-center",
        progressBar: false,
      });
    }
    this.loading = false;
  }


  /** Método para obtener los roles */
  async getRoles() {
    const roles = await this.mongoSrv.getAll(`/roles`);
    if (roles) {
      this.auditSrv.logAction({
        modulo: "Usuarios",
        accion: "Obtener roles"
      })
      roles.datos.forEach(rol => {
        if (rol.visible_entidades) {
          this.roles.push({ id: rol.codigo, descripcion: rol.codigo })
        }
      });
    } else {
      this.toastr.error("No se puso obtener los roles", '', { positionClass: 'toast-top-center', progressBar: false })
    }
  }

  /** Método para abrir la ventana de edición de datos
   * @param user usuario a modificar
   */
  editUser(user: Usuario) {
    this.storageSrv.save('userToEdit', JSON.stringify(user), true, true);
    this.router.navigate(['usuarios', 'editarusuario']);
  }

  crearUsuario() {
    this.router.navigate(['usuarios', 'nuevousuario'])
  }

  /** Método para abrir el modal de reseteo de contraseña 
   * @param user Usuario del cual se desea resetar contraseña
  */
  resetPassword(user: Usuario) {
    this.selectedUser = user;
    this.modalService.open(this.resetearPassword, { centered: true, backdropClass: 'modal-backdrop' });
  }

  /** Método para enviar mensaje de reseteo de contraseña */
  async sendRecovery(email: string) {
    const req = {
      email: email,
    }
    const res = await this.recoverySrv.recovery(req);
    if (res) {
      this.auditSrv.logAction({
        modulo: "Usuarios",
        accion: "Resetear contraseña de usuario",
        detalle: "Email: " + email
      })
      this.toastr.success(Mensajes.RECOVERY_SENT + email, '', { positionClass: 'toast-top-center', progressBar: true });
    } else {
      this.toastr.error(Mensajes.RECOVERY_ERROR, '', { positionClass: 'toast-top-center', progressBar: false });
    }
  }
  
  async changeStatusModal(user: Usuario, enable: boolean) {
    if (user.entidad!.activo) {
      this.selectedUser = user;
      const modal = enable ? this.activar : this.inactivar;
      this.modalService.open(modal, { centered: true, backdropClass: 'modal-backdrop' });
    } else {
      this.toastr.error(Mensajes.ERROR_ENTIDAD_INACTIVA, '', { positionClass: 'toast-top-center' });
    }
  }

  async changeStatus(user: Usuario, enable: boolean) {
    const successMsg = enable ? Mensajes.USUARIO_ACTIVADO : Mensajes.USUARIO_INACTIVADO;
    const errorMsg = enable ? Mensajes.ERROR_AL_ACTIVAR_USUARIO : Mensajes.ERROR_AL_INACTIVAR_USUARIO
    const payload = {
      'enabled': enable
    }
    const updated = await this.userSrv.updateUser(user.id, payload);
    if (updated) {
      this.auditSrv.logAction({
        modulo: "Usuarios",
        accion: "Cambiar estado de usuario",
        detalle: "Usuario: " + user.email + ", Estado: " + enable
      })
      this.toastr.success(successMsg, '', { positionClass: 'toast-top-center', progressBar: true })
      this.getUsuarios();
    } else {
      this.toastr.error(errorMsg, '', { positionClass: 'toast-top-center', progressBar: false })
    }
  }
  
  verLogs(email: string) {
    this.router.navigate(['auditoria', 'listado', { username: email }]);
  }

  showContextMenu(event: MouseEvent, contextMenu: ContextMenu, usuario: Usuario): void {
    event.stopPropagation();
    event.preventDefault();

    this.items = [
      { label: 'Ver logs', command: () => this.verLogs(usuario.email) },
      { label: 'Modificar', visible: usuario.activo, command: () => this.editUser(usuario) },
      { label: 'Restaurar contraseña', command: () => this.resetPassword(usuario) },
      { label: '<a class="text-danger">Inactivar</a>', visible: usuario.activo, command: () => this.changeStatusModal(usuario, false), escape: false },
      { label: 'Activar', visible: !usuario.activo, command: () => this.changeStatusModal(usuario, true) },
    ];

    contextMenu.show(event);

  }

  async limpiar() {
    this.filtrando = true;
    // reseteo de filtros
    this.filtroUsuario = "";
    this.filtroRol = [""];
    this.filtroActivos = ""
    this.page = 1;
    this.pageSize = 10;
    this.filtros = {};
    this.filtroApellido = ""
    this.filtroNombre = ""

    await this.filtrar();
    this.filtrando = false;
  }

  async filtrar() {
    this.verificarFiltros()
    this.filtrando = true;
    const filterData: FiltroUsuario = {
      entity: this.userSrv.getActualUser().entidad.id,
      enabled: this.filtroActivos,
      email: this.filtroUsuario,
      name: this.filtroNombre,
      surname: this.filtroApellido,
      roles: this.filtroRol.includes("") ? undefined : this.filtroRol,
    };
    this.page = 1;
    this.pageSize = 10;
    this.filtros = filterData;
    await this.getUsuarios();
    this.filtrando = false;
  }

  verificarFiltros() {
    if (this.filtroRol.length > 0) {
      this.filtroRol = this.filtroRol.filter(filtro => filtro != "")
    } else {
      this.filtroRol = [""]
    }
  }
}
