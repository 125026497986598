import { Component, Input, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-loadingSpinner',
  templateUrl: './loadingSpinner.component.html',
  styleUrls: ['./loadingSpinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {

  @Input() message: BehaviorSubject<string> = new BehaviorSubject('');
  @Input() show;

  faSpinner=faSpinner;
  msg  = '';

  ngOnInit() {
    this.message.subscribe(m => {
      this.msg=m;
    });
  }

}
