import { Component, Input, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  @Input() messages: string []=[];
  @Input() loadingOc;
  message = "";

  //Timer
  interval;
  faSpinner=faSpinner;

  ngOnInit(): void {
    this.startTimer();
  }

  startTimer() {
    let indice=0;
    this.interval = setInterval(() => {
      this.message =this.messages[indice];
      indice+=1;
      if(indice>= this.messages.length){
        indice=0;
      }
      if(!this.loadingOc){
        this.pauseTimer();
      }
    },500)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

}
