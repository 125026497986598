export const Mensajes = {


    //ENTIDADES
    ENTIDAD_CREADA: 'Entidad creada correctamente.',
    ENTIDAD_EDITADA: 'Entidad editada correctamente.',
    ENTIDAD_ELIMINADA: 'Entidad eliminada correctamente.',
    ENTIDAD_ACTIVADA: 'Entidad activada correctamente.',
    ENTIDAD_INACTIVADA: 'Entidad inactivada correctamente.',

    //ERRORES
    ENTIDAD_EXISTENTE: 'Ya existe una entidad con el nombre indicado.',
    ERROR_CREAR_ENTIDAD: 'Hubo un error al crear la entidad',
    ERROR_EDITAR_ENTIDAD: 'Hubo un error al editar la entidad',
    ERROR_AL_INICIAR_SESION: 'Hubo un problema al iniciar sesion.',
    ERROR_ELIMINAR_ENTIDAD: 'Hubo un problema al eliminar la entidad.',
    ERROR_AL_ACTIVAR: 'Hubo un problema al activar la entidad.',

    //ERRORES USUARIOS
    ERROR_AL_CREAR_USUARIO: 'Hubo un problema al crear el usuario.',
    ERROR_AL_EDITAR_USUARIO: 'Hubo un problema al editar el usuario.',
    ERROR_AL_INACTIVAR_USUARIO: 'Hubo un problema al inactivar el usuario.',
    ERROR_AL_ACTIVAR_USUARIO: 'Hubo un problema al activar el usuario.',
    ERROR_ENTIDAD_INACTIVA: 'La entidad está inactiva.',

    //LOGIN
    CREDENCIALES_INCORRECTAS: 'Las credenciales ingresadas son incorrectas, su cuenta será bloqueada a los 5 intentos fallidos.',
    USUARIO_INACTIVO: 'El usuario se encuentra inactivado.',
    USUARIO_SIN_PERMISOS:'El usuario ingresado no tiene los permisos necesarios para acceder.',
    CONTRASEÑA_INCORRECTA:'La contraseña actual ingresada es incorrecta.',
    CONTRASEÑAS_DIFERENTES:'La confirmacion de contraseña no coincide con la contraseña nueva.',
    PASSWORD_EXPIRED: 'Su contraseña expiró porfavor ingrese una nueva.',
    CONTRASEÑA_GENERADA_CORRECTAMENTE: 'Contraseña generada correctamente.',
    ERROR_AL_GENERAR_CONTRASEÑA: 'Hubo un problema al generar la contraseña',
    ERROR_AL_CAMBIAR_CONTRASEÑA: 'Hubo un error al cambiar su contraseña',

    //USUARIOS
    USUARIO_CREADO: 'Usuario creado correctamente.',
    USUARIO_EDITADO: 'Usuario editado correctamente.',
    USUARIO_INACTIVADO: 'Usuario inactivado correctamente.',
    USUARIO_ACTIVADO: 'Usuario activado correctamente',
    LOGIN_DESBLOQUEADO: 'Usuario desbloqueado correctamente',
    ERROR_AL_DESBLOQUEAR_USUARIO: 'Hubo un problema al desbloquear el usuario.',
    USUARIO_BLOQUEADO: 'El usuario está bloqueado. El tiempo restante es de',
    SESION_EXPIRADA: 'Su sesion ha expirado.',
    ERROR_DATOS_DE_USUARIO: 'Complete todos los campos de manera apropiada.',

    //EMAIL-RECOVERY
    RECOVERY_MESSAGE: 'Si la dirección de correo existe, recibiras un mail con las instrucciones para el cambio de contraseña.',
    RECOVERY_SENT:'Se envio correctamente el correo a ',
    RECOVERY_ERROR: 'Hubo un problema al enviar el correo.',
    CONTRASEÑA_CAMBIADA_CON_EXITO: 'La contraseña fue actualizada correctamente.',
    CONTRASEÑAS_IGUALES: 'Las contraseñas deben ser iguales.',
    TOKEN_EXPIRADO: 'El link de recupero de contraseña ha expirado.',
    TOKEN_INVALIDO: 'El token de autorización es inválido',
    TOKEN_INACTIVO: 'El token de autorización ya fue utilizado',
    RESTRICCIONES_CONTRASEÑA: 'La contraseña debe tener al menos 8 caracteres, un numero, una minuscula y una mayuscula.',
    ERROR_CAMBIAR_CONTRASEÑA: 'Hubo un problema al cambiar la contraseña',

    //ERRORES INPUTS
    EMAIL_MAL_INGRESADO: 'El email ingresado es invalido.',
    ENTIDAD_CARACTERES: 'Solo se permiten caracteres alfabeticos.',
    LIMITE_CARACTERES: 'El limite de caracteres en los comentarios es de',

    //TICKETS
    TICKET_CREADO: 'Ticket creado con exito.',
    ERROR_TICKET: 'Error al crear el ticket.',
    ARCHIVOS_REPETIDOS: 'Los archivos repetidos no fueron cargados.',
    ERROR_TIPO_TICKETS:'Error al buscar los tipo de tickets.',
    TICKET_CREADO_BREVE: 'Su ticket sera creado en breve, porfavor aguarde un momento.',
    ERROR_COMENTARIO_VACIO: 'No se pueden enviar comentarios vacios.',
    ERROR_ACTUALIZAR_ESTADO: 'Error al actualizar estado.',
    ANULACION_APROBADA:'Anulacion aprobada correctamente.',
    ANULACION_RECHAZADA:'Anulacion rechazada correctamente.',
    ERROR_AL_PROCESAR_ANULACION:'Error al procesar anulación',
    INVALID_DATE_RANGE:'Rango de fecha inválido',

    //ALTA OC
    RUC_SIN_CLIENTE: 'El RUC seleccionado no cuenta con clientes asociados, porfavor cree uno.',
    RUC_INVALIDO: 'El RUC ingresado es invalido.',
    CONTACTO_PRINCIPAL_SIN_EMAIL: 'El mail ingresado no pertenece a ningun contacto, por favor cree uno.',
        
    ALTA_OC: 'Oportunidad comercial creada con exito.',
    ALTA_CLIENTE: 'Cliente creado con exito.',
    ALTA_CONTACTO: 'Contacto creado con exito.',
    ERROR_AL_CREAR_CLIENTE:'Hubo un problema al crear el cliente.',
    ERROR_AL_MODIFICAR_CLIENTE: 'Hubo un problema al crear el cliente.',
    ERROR_AL_ACTUALIZAR_DIRECCION_CLIENTE:'Hubo un problema al cargar dirección del cliente',
    ERROR_AL_CREAR_CONTACTO:'Hubo un problema al crear el contacto.',
    ERROR_AL_VALIDAR_MAIL:'Hubo un problema al validar el correo electronico',
    ERROR_AL_VALIDAR_RUC:'Hubo un problema al validar el ruc',
    ERROR_AL_COMPROBAR_ASOCIACION:'Hubo un proble al validar la asociación',
    DATOS_INCOMPLETOS:'Datos necesarios faltantes: ',
    ADJUNTOS_INCOMPLETOS: 'Por favor cargue los adjuntos obligatorios',
    CONTACTO_PEP_ENCONTRADO:'Ya existe un contacto con este número de cédula, será actualizado',
    OC_SIN_PRODUCTOS:'Agregue al menos un producto.',
    OC_SIN_CUENTAS:'Agregue al menos una cuenta.',
    OC_PRODUCTOS_SIN_COMPLETAR:'Por favor complete los datos de producto.',
    ERROR_AL_CREAR_OC:'Hubo un problema al crear la oc',
    ERROR_AL_RECUPERAR_CIUDADES: 'Error al recuperar las ciudades.',
    ERROR_AL_RECUPERAR_BARRIOS:'Error al recuperar los barrios',
    ERROR_AL_RECUPERAR_ZONAS:'Error al recuperar las zonas',
    ERROR_AL_RECUPERAR_ADJUNTOS: 'No se pudieron recuperar los adjuntos de la OC ',
    ERROR_AL_RECUPERAR_PRODCUTOS: 'Error al cargar productos.',
    ERROR_AL_CARGAR_ADJUNTOS: 'Hubo un error al cargar los adjuntos de la OC ',
    ERROR_AL_CARGAR_DATOS: 'Hubo un error al cargar los datos de la OC ',
    ERROR_AL_CARGAR_CONTACTO: 'Hubo un error al cargar el contacto de la OC ',
    ERROR_AL_CARGAR_CLIENTE: 'Hubo un error al cargar el cliente de la OC ',
    ERROR_AL_CARGAR_CUENTA_BANCARIA: 'Hubo un error al cargar las cuentas bancarias.',
    ERROR_AL_CARGAR_PRODUCTOS: 'Hubo un error al cargar los productos.',
    ONBOARDING_SUCCESS:'Pedido de generación realizado, en unos minutos será notificado sobre el proceso',
    ONBOARDING_ERROR:'Se produjo un error al intentar generar la OC',
    OC_AA_ERROR_AL_CARGR_ARCHIVOS: `Hubo un error al cargar los archivos de la OC, por lo que el estado de la OC no se actualizó a "Pendiente de integración"`,

    //COMENTARIOS
    ERROR_AL_ENVIAR_COMENTARIO: 'Se produjo un error al enviar el comentario',

    //DOMUS
    NUEVO_ADJUNTO:'Adjunto creado correctamente.',
    NUEVO_ADJUNTO_ERROR: 'Hubo un problema al subir adjuntos.',

    LISTADO_ERROR:'Hubo un problema al traer los adjuntos.',
    ASOCIAR_ERROR:'Hubo un problema al asociar los documentos.',
    ASOCIAR_ADJUNTO:'Adjuntos asociados correctamente.',
    DESCARGAR_ADJUNTO:'No se pudo descargar el documento.',
    INPUTS_VACIOS:'Por favor llene todos los campos requeridos.',
    EXTENSIONES:'Solo se aceptan archivos con extension: ',
    DOCUMENTO_SIZE:'El tamaño del documento seleccionado debe ser menor a 10MB.',
    DOCUMENTO_NAME:'El nombre del archivo no puede quedar vacío',
    DOCUMENTO_INVALID:'El documento es inválido',
    NOMBRE_CEDULA:'El nombre de cedula solo puede contener caracteres alfabeticos.',
    NRO_DOCUMENTO:'El numero de documento solo puede contener caracteres numericos.',
    ADJUNTOS_ERROR_OBTENERDATOS:'No se pudo obtener datos del archivo',


    //PARTICIPANTES TICKETS
    ERROR_AL_CREAR_PARTICIPANTE: 'Hubo un problema al crear el participante.',
    ERROR_AL_ASOCIAR_PARTICIPANTE: 'Ocurrio un error al asignar el participante.',
    ERROR_AL_ELIMINAR_PARTICIPANTE: 'Ocurrio un problema al eliminar el participante.',
    PARTICIPANTE_INEXISTENTE: 'El usuario seleccionado no es un participante, por favor creelo.',
    PARTICIPANTE_ASOCIADO: 'El participante ya se encuentra asociado.',
    PARTICIPANTE_ASOCIADO_SUCCESS: 'Participante asociado con exito!',
    PARTICIPANTE_ELIMINADO: 'Participante eliminado con exito!',
    ERROR_AL_RECUPERAR_USUARIOS: 'Ocurrio un problema al recueprar los usuarios de la entidad.',

    //array tags
    tags: ["<h1>","</h1>","<h2>","</h2>","<h3>","</h3>","<h4>","</h4>","<h5>","</h5>","<h6>","</h6>",]
}