import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private http: HttpClient,
    private storageSrv: StorageService
  ) {}

  /** Método para enviar mensaje de error por mail
   * se envia el mensaje a las direcciones mensaionadas en el archivo de constantes
   * @param user cuenta del usuario
   * @param enviroment ambiente
   * @param error Error
   * @param errorCode código de error
   * @param errorCodeText texto del código de error
   * @param errorMessage Mensaje a enviar
   */
  sendErrorNotification(
      error: string, 
      errorCode: string|number, 
      errorCodeText: string, 
      errorMessage: string,
      payload?: string
    ) {

    let usuario;
    const user = this.storageSrv.get('user', true, true)
    if (user) {
      usuario = JSON.parse(user).email;
    }

    let message = `
    <style>
      code {
        display: inline-block;
        word-wrap: break-word;
        white-space: pre-wrap;
        overflow: auto;
        max-width: 100%;
      }
    </style>
        <h1>${errorCodeText}</h1>
        <ul>
          <li><b>Usuario:</b> ${usuario}</li>
          <li><b>App:</b> ${environment.app_name}</li>
          <li><b>Ambiente:</b> ${environment.env_name}</li>
          <li><b>Error:</b> <code>${error}</code></li>
          <li><b>Código:</b> ${errorCode}</li>
          <li><b>Mensaje:</b> ${errorMessage}</li>`
        if (payload) {
          payload = this.sanitizePayload(payload)
          message+=`<li><b>Payload:</b> <code>${payload}</code></li>`
        }
        message+=`<ul>`;
    const mail = {
      "content": message,
      "subject": 'Error - Portal de Entidades'
    }
    const h = new HttpHeaders({
      'Authorization': `Bearer ${environment.apiLogBearer}`
     });
    
    const url = `${ environment.apiUrl }/error_log`;
    this.http.post<any>(url, mail, { headers: h,  observe: 'response'}).subscribe()
  }


  sanitizePayload(payload) {
    for(const key of Object.keys(payload)){
        if(typeof payload[key] == 'object'){
            this.sanitizePayload(payload[key])
        } 
        if (Array.isArray(payload[key])) {
            payload[key].forEach(this.sanitizePayload);
        }
        if(['authorization','password', 'file', 'fileb64'].includes(key.toLocaleLowerCase())){
            payload[key] = '*********';
        }
    }
    return JSON.stringify(payload);
}

}
