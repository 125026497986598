import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { UsersService } from '../services/users.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
})
export class PagesComponent implements OnInit {
  
  rol;
  userInitials;
  // carga
  loading = false;
  loadingMessage = new BehaviorSubject("");
  
  constructor (
    private dataSrv: DataService,
    private usersSrv: UsersService
  ) {
    this.dataSrv.estaCargando().subscribe((estaCargando) => {
      this.loading = estaCargando;
      if (estaCargando) {
        this.loadingMessage.next("Cargando datos");
      }
    });
  }
   

  async ngOnInit() {
    const user = this.usersSrv.getActualUser();
    this.rol = user.rol;
    this.userInitials = user.nombre!.slice(0,1);
    await this.dataSrv.loadAllData();
  }
  
}
