import { Routes } from "@angular/router";
import { PermGuardService } from "../../services/perm-guard.service";
import { DataDocumentosImportantes } from "./documentos-importantes.routes.data";
import { ListadoDocumentosImportantesComponent } from "./listado-documentos-importantes/listado-documentos-importantes.component";


export const DocumentosImportantes_Routes: Routes = [
    {path: 'listado', component: ListadoDocumentosImportantesComponent,
    data: DataDocumentosImportantes.listado, canActivate:[PermGuardService]},
    {path: '', pathMatch: 'full', redirectTo:'listado'},
    {path: '**', pathMatch: 'full', redirectTo: 'listado'}
  ]
  ;
   
