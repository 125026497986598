import { Component } from '@angular/core';

@Component({
  selector: 'app-documentos-importantes',
  templateUrl: './documentos-importantes.component.html'
})
export class DocumentosImportantesComponent {


}
