import { Routes } from "@angular/router";
import { RouterModule } from "@angular/router";



//Rutas
import { Usuario_Routes } from "./usuarios/usuarios.routes";
import { Tickets_Routes } from "./tickets/tickets.routes";
import {Reportes_Routes} from "./reportes/reportes.routes";


//Componentes
import { PagesComponent } from "./pages.component";
import { UsuariosComponent } from "./usuarios/usuarios.component";
import { AuthGuardService } from "../services/auth-guard.service";
import { TicketsComponent } from "./tickets/tickets.component";
import { ReportesComponent } from "./reportes/reportes.component";
import { Auditoria_Routes } from "./auditoria/auditoria.routes";
import { AuditoriaComponent } from "./auditoria/auditoria.component";
import { OportunidadComercialComponent } from "./oportunidad-comercial/oportunidad-comercial.component";
import { Oportunidad_Routes } from "./oportunidad-comercial/oportinudad.routes";
import { HelpComponent } from "./help/help.component";
import { DocumentosImportantesComponent } from "./documentos-importantes/documentos-importantes.component";
import { DocumentosImportantes_Routes } from "./documentos-importantes/documentos-importantes.routes";
import { CatalogoProductosComponent } from "./catalogo-productos/catalogo-productos.component";
import { CatalogoProductos_Routes } from "./catalogo-productos/catalogo-productos.routes";
import { NotificacionesComponent } from "./notificaciones/notificaciones.component";
import { Notificaciones_Routes } from "./notificaciones/notificaciones.routes";
import { BienvenidaComponent } from "./bienvenida/bienvenida.component";
import { HistorialLoginComponent } from "./historial-login/historial-login.component";


const Page_Routes: Routes = [
    {path: '', component:PagesComponent, canActivate: [AuthGuardService], children:[
          {path: '', pathMatch: 'full', redirectTo: 'usuarios'},
          {path: 'usuarios', component: UsuariosComponent, children: Usuario_Routes},
          {path: 'tickets', component: TicketsComponent, children: Tickets_Routes},
          {path: 'reportes', component: ReportesComponent, children: Reportes_Routes},
          {path: 'auditoria', component: AuditoriaComponent, children: Auditoria_Routes},
          {path: 'notificaciones', component: NotificacionesComponent, children: Notificaciones_Routes},
          {path: 'oportunidadcomercial', component: OportunidadComercialComponent, children: Oportunidad_Routes},
          {path: 'ayuda', component: HelpComponent},
          {path: 'documentos-importantes', component: DocumentosImportantesComponent, children: DocumentosImportantes_Routes},
          {path: 'catalogo-productos', component: CatalogoProductosComponent, children: CatalogoProductos_Routes},
          {path: 'documentos-importantes', component: DocumentosImportantesComponent, children: DocumentosImportantes_Routes},
          {path: 'catalogo-productos', component: CatalogoProductosComponent, children: CatalogoProductos_Routes},
          {path: 'home', component: BienvenidaComponent},
          {path: 'historial-login', component: HistorialLoginComponent, data: {titulo: 'Historial de inicio de sesión'}},
          ]
        }
      ]
  ;
   
  export const Page_Routing = RouterModule.forChild(Page_Routes);