import { Documento } from "../interfaces/interfaces";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { Constantes } from "../constants/constantes.const";
import { ToastrService } from "ngx-toastr";


const mimeTypesMap: { [extension: string]: string } = {
    '.pdf': 'application/pdf',
    '.doc': 'application/msword',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.jpeg': 'image/jpeg',
    '.jpg': 'image/jpeg',
    '.png': 'image/png',
    '.gif': 'image/gif',
    '.bmp': 'image/bmp',
    '.tiff': 'image/tiff',
    '.tif': 'image/tiff',
    '.ico': 'image/x-icon',
    '.svg': 'image/svg+xml',
  }
export default class FileUtils {
    
    constructor(
        public toastSrv: ToastrService
    ){} 

    static fileToBase64(file: File) {
        return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                resolve(reader.result?.toString().split(',')[1] || '');
            } 
        })
    }

    /** Método para obtener el tamaño de un documento en kB
   * @param documento Documento a obtener su tamaño
   * @return tamaño del documento 
   */
    static getFileSize(documento: Documento) {
        const tamaño = (documento.tamaño ? (Math.round(((documento.tamaño*0.001) + Number.EPSILON) * 100) / 100).toString() : '0' )+' kB';
        return tamaño;
    }

    /** Método para exportar un objeto html 
     * @param data datos del objeto a exportar
     * @param title título a dar al objeto 
     * @param format formato a exportar
     */
    static export(data,title: string, format: "png"|"pdf") {
        const options ={ scale: 2};
        html2canvas(data, options).then(canvas => {
            if (format=="pdf") {
                const pdf = new jsPDF({orientation:'l',unit:'pt', format:'a4'}); 
                const img = canvas.toDataURL('image/PNG')
                const bufferX = 15;
                const bufferY = 15;
                const imgProps = pdf.getImageProperties(img);
                const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * bufferX;
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(img, 'PNG', bufferX, bufferY, pdfWidth , pdfHeight);
                pdf.save(`${title}.${format}`);
                } else {
                canvas.toBlob(function(blob) {
                    saveAs(blob, `${title}.${format}`);
                });
            }
        });
    }
    
    /** Método para corregir el nombre de un archivo a la cantidad de cartacteres permitido
     * @param fileName nombre del archivo (incluyendo extension)
     * @return array con los siguientes datos
     * <ul>
     *  <li>shorted: boolean si fue corregido o no</li>
     *  <li>shortName: nombre sin extensión</li>
     *  <li>extention: extensión del archivo</li>
     *  <li>full: nombre con extensión </li>
     * </ul>
     */
    static fixName(fileName: string) {
        let shortName = fileName.slice(0,fileName.lastIndexOf('.'));
        let shorted = false;
        if (shortName.length>Constantes.CARACTERES_NOMBRE_ADJUNTO) {
            shortName = shortName.slice(0,40);
            shorted = true;
        }
        
        const name = {
            short: shortName,
            extention: fileName.slice(fileName.lastIndexOf('.')+1),
            full: `${shortName}${fileName.slice(fileName.lastIndexOf('.'))}`,
            shorted: shorted
        }
        return name;
    }

    static stringToFile(data: string, name: string) {
        const mimeType = mimeTypesMap[`.${name.split('.').pop()}`]
        const blob = new Blob([data], { type: mimeType });
        return new File([blob], name, { type: mimeType });
    }

    static getMimeType(name: string) {
        const mimeType = mimeTypesMap[`.${name.split('.').pop()}`]
        return mimeType;
    }
}

