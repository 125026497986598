<div class="modal-body modal-background">
    <div class="row row-cols-lg-1 py-3 justify-content-center">
        <div class="col-12 text-center text-modal">
            <span>{{confirmationTitle}}</span>
        </div>
        <div class="col-10 text-center text-modal">
            <div class=" d-flex row text-center justify-content-center">
                <span class="span-modal">{{confirmationText}}</span>
            </div>
            <div class="d-flex row text-center justify-content-center">
                <span class="span-modal id-modal"></span>
            </div>
        </div>
    </div>

    <div class="row row-cols-4 row-cols-lg-4 mx-0  py-3 justify-content-center">
        <div class="col-lg-12">
            <div class="d-flex justify-content-center">
                <button type="button" (click)="confirm()" class="btn btn-primary-portal btn-primary btn-sm btn-modal" [ngClass]="forDeletion ? '' : 'btn-modal-accept'">
                    <span>{{confirmButtonText}}</span>
                </button>
            </div>
        </div>

    </div>
    <div class="row row-cols-4 row-cols-lg-4 mx-0  pb-3 justify-content-center">
        <div class="col-lg-12">
            <div class="d-flex  justify-content-center">
                <button type="button" (click)="cancel()" class="btn btn-primary-portal btn-primary btn-sm btn-modal btn-modal-cancel">
                    <span>{{cancelButtonText}}</span>
                </button>
            </div>
        </div>
    </div>
</div>
