import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { RequestService } from './request.service';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { MongoDBService } from './mongoDB.service';
import { EncryptionService } from './encryption.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private router: Router,
    private requestSrv: RequestService,
    private storageSrv: StorageService,
    private mongoSrv: MongoDBService,
    private encryptSrv: EncryptionService,
    private usersSrv: UsersService
  ) {}

  /** Método para iniciar sesión
   * @param data credenciales encriptadas
   * @returns datos de inicio de sesión
   */
  async login(data : string) {
    const body = new URLSearchParams();
    body.set('data', data);
    
    const $source = this.requestSrv.postRequestLogin(`/auth/login`,body);
    const res = await lastValueFrom($source);
    
    if (res instanceof HttpResponse) {
      return res.body;
    } else {
      return res.error;
    }
  }

  /** Método para cerrar sesión */
  async logout(redirect = true){
    const started = this.storageSrv.get("started");
    if (started=="true") {
      await this.mongoSrv.get(`/auth/logout`,[]);
    }
    
    this.storageSrv.clear(true)
    
    if(redirect) {
      this.router.navigate(['login'])
    }
  }

  requestRefreshToken(refreshToken: string) {
    const accessToken = this.storageSrv.get('token');
    return this.requestSrv.postRequestRefreshToken(`/auth/refresh_token`, {refreshToken, accessToken}, this.usersSrv.getActualUser().id.toString())
  }

  saveToken(res) {
    if (res instanceof HttpResponse) {
      // extrae llave de encriptado
      const r_key: string = res.body.data.keys.substring(0, 44);

      // inicializa el servicio de encriptado
      this.encryptSrv.init(r_key);

      // extrae otras llaves encriptadas
      const keys: string = res.body.data.keys.substring(44);

      // separa el vector de inicialiación
      const encryptedWithIV = this.encryptSrv.extractIV(keys, 32);

      // desencripta llaves
      const nKeys = this.encryptSrv.decrypt(
        encryptedWithIV.encrypted,
        encryptedWithIV.iv
      );
      if (nKeys) {
        // asigna llaves al servicio
        const keys = JSON.parse(nKeys);
        this.encryptSrv.setKeys(
          keys.pub,
          keys.priv,
          keys.pass,
          keys.api_pub_key
        );
      }
      
      this.storageSrv.save("token", res.body.data.token);
      this.storageSrv.save("refreshToken", res.body.data.refreshToken);
      
    }
  }
}