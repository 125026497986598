export const Constantes = {
    MAX_FILE_SIZE: 10485760,
    CARACTERES_NOMBRE_ADJUNTO: 40,
    CARACTERES_RAZON_SOCIAL: 30,
    VALID_EXTENTIONS:['gif','docx','pdf','jpg','txt','doc','xlsx','jpeg','png','xls','msg','htm','html','csv','pptx','zip'],
    GRUPOS_SIN_SLA:['ADM_1008','ADM_1003'],
    TICKETS_FIELDS:`ObjectID,ID,Name,ServiceIssueCategoryID,ProcessingTypeCode,IncidentServiceIssueCategoryID,ServiceIssueCategoryID,ServicePriorityCode,ServicePriorityCodeText,ServiceRequestUserLifeCycleStatusCodeText,ProcessingTypeCodeText,LastChangeDateTime,CreationDateTime,Z_CREADOPOR_KUT,Z_ENTIDAD_KUT,ServiceRequestUserLifeCycleStatusCode,Z_CabEntidadDueadelATM_KUT,Z_NUMERODEBOLETA_KUT`,
    OPPORTUNITIES_FIELDS:`ObjectID,ID,ProspectPartyName,ProcessingTypeCode,ProcessingTypeCodeText,PrimaryContactPartyName,OpportunityItem,ExpectedRevenueAmountCurrencyCode,ExternalUserStatusCode,ExternalUserStatusCodeText,ProbabilityPercent,SalesOrganisationName,CreationDateTime,Z_RUBRO_KUTText,Z_FECHAHORA_APROB_KUT,Z_OCCREADOPOR_KUT,ProcessStatusValidSinceDate`,
    ESTADOS_VALIDOS_ANULACION:['YF','YD','YE'],
    ESTADOS_CERRADOS_TICKET:['Y3','Z7','Z4','6','3','4','ZI'],
    ESTADOS_CERRADOS_OC:['Z07','Z09'],
    INVALID_STATUS_ANULACION_ENTIDAD:['Y3'],
    IGNORE_USER_ROLS:['ADMIN_BANCARD'],
    ROLS_FOR_TICKETS_ATM_VEREDICTS:['admin','user'],
    ROLS_FOR_TICKETS_CONTACTS:['admin','user'],
    ROLS_FOR_TICKETS_ANULATIONS:['admin','user'],
    VALID_TAGS:['<p>','</p>','<br>','<strong>','</strong>','<em>','</em>','<u>','</u>','<s>','</s>','<ol>','</ol>','<ul>','</ul>','<li>','</li>'],
    TIPOS_TICKET_OBVIADOS:['Z4','ZGE','ZAE','Z8'],
    TIPOS_TICKET_ANULACION:['Z4','ZAE'],
    CATEGORIAS_CONSULTA_ENTIDAD:['CA211','CA218'],
    CATEGORIAS_SIN_INCIDENTES:['CA513','CA514','CA515','CA516','CA517','CA_107','CA_120','CA_121','CA_900','CA_96','CA_97','CA_101'],
    INCIDENTES_PEDIDOS_CAC:['CA292','CA294'],
    TICKETS_ESCALADOS:['YA','Y1'],
    CATEGORIAS_UBICACION_ORIGEN:['CA515'],
    CATEGORIAS_UBICACION_DESTINO:['CA513','CA515'],
    CATEGORIAS_ATM:['CA513','CA515'],
    CATEGORIAS_TERMINAL:['CA514','CA516'],
    CON_NUMERO_BOLETA:['Z4','ZAE','ZVE'],
    ENTIDADESSINATM:['E007'],
    PRODUCTOS_ONBOARDING:['P7','P28','P43','P47','P50','P54'],
    MEDIOS_DE_PAGO_CON_REFERENCIA:["2"],
    ROL_ENTIDADES_REQUIEREN_MEDIO_DE_PAGO:['Entidad pagadora','Entidad Sponsor'],
    PATH_REINTENTABLES: ['comment/v2','grupos-tickets/tickets']
} 
