import { Pipe, PipeTransform } from '@angular/core';
import { Constantes } from '../constants/constantes.const';
import { Basico, CategoriaTicket, Departamento, IncidenciaTicket, OC, Producto, ReporteSLAData, Ticket, TicketsGroup, TipoTicket } from '../interfaces/interfaces';
import { DataService } from '../services/data.service';

@Pipe({
  name: 'TicketFilter'
})
export class TicketsFilterPipe implements PipeTransform {

  constructor(private dataSrv: DataService) {}

  transform(
    tickets: Ticket[], 
    filters: {statuses?:string[],priorities?: string[],types?: string[],categories?: string[],incidents?: string[],users?: string[],statusGroup?:'all'|'open'|'closed'}
  ): Ticket[] {
    this.dataSrv.setFiltering(true);
    let ntickets: Ticket[] = [];
    if(!filters) {
      ntickets = tickets;
    } else if (tickets) {
      ntickets = tickets.filter(ticket => {
        const tipo = ticket.tipo ? ticket.tipo.id.toString() : '';
        const categoria = ticket.tipo && ticket.tipo.categoria ? ticket.tipo.categoria.id.toString() : '';
        const incidencia = ticket.tipo && ticket.tipo.categoria && ticket.tipo.categoria.incidencia ? ticket.tipo.categoria.incidencia.id.toString() : '';
        return  ((filters.users && filters.users.length>0) ? filters.users.includes(ticket.creadoPor) : true) &&
                ((filters.statuses && filters.statuses.length>0) ? filters.statuses.includes(ticket.estado.id.toString()) : true) &&
                ((filters.priorities && filters.priorities.length>0) ? filters.priorities.includes(ticket.prioridad.id.toString()) : true) &&
                ((filters.incidents && filters.incidents.length>0) ?  filters.incidents.includes(incidencia) : true) &&
                ((filters.categories && filters.categories.length>0) ? filters.categories?.includes(categoria) : true) &&
                ((filters.types && filters.types.length>0) ? filters.types.includes(tipo) : true &&
                (filters.statusGroup && filters.statusGroup=='open') ? !Constantes.ESTADOS_CERRADOS_TICKET.includes(ticket.estado.id.toString()) : true &&
                (filters.statusGroup && filters.statusGroup=='closed') ? Constantes.ESTADOS_CERRADOS_TICKET.includes(ticket.estado.id.toString()) : true);
      });
    }
    this.dataSrv.setFiltering(false);
    return ntickets;
  }
}

@Pipe({
  name: 'TicketSearch'
})
export class TicketsSearchPipe implements PipeTransform {

  constructor(private dataSrv: DataService) {}

  transform(tickets: Ticket[], terms: string, collectionSizeFiltered?: {count:number}): Ticket[] {
    this.dataSrv.setFiltering(true);
    let ntickets: Ticket[] = [];
    if(!terms) {
      ntickets = tickets;
    } else if(tickets) {
      terms = terms.toLowerCase();
      ntickets = tickets.filter(ticket => {
        return  ticket.numero?.toString().includes(terms) || 
                ticket.boleta?.toString().includes(terms) || 
                ticket.descripcion?.toLowerCase().includes(terms) || 
                ticket.estado.descripcion?.toLowerCase().includes(terms) ||  
                ticket.prioridad.descripcion?.toLowerCase().includes(terms) || 
                ticket.tipo?.descripcion?.toLowerCase().includes(terms) || 
                ticket.tipo?.categoria?.descripcion?.toLowerCase().includes(terms) || 
                ticket.tipo?.categoria?.incidencia?.descripcion?.toLowerCase().includes(terms) || 
                ticket.creadoPor?.toLowerCase().includes(terms);
      });
    }
    if (collectionSizeFiltered) {
      collectionSizeFiltered.count=ntickets.length;
    }
    this.dataSrv.setFiltering(false);
    return ntickets;
  }
}

@Pipe({
  name: 'IncludeClossed'
})
export class IncludeClossedPipe implements PipeTransform {

  transform(statuses: Basico[], include: boolean): Basico[] {
    if(!statuses) { 
      return []
    }
    if (!include) {
      return statuses.filter(status => {return !Constantes.ESTADOS_CERRADOS_TICKET.includes(status.id.toString())});
    } else {
      return statuses;
    }
  }
}

@Pipe({
  name: 'TypeFilter'
})
export class TypeFilterPipe implements PipeTransform {

  transform(types: TipoTicket[], group: TicketsGroup): TipoTicket[] {
    if(!types) { 
      return []
    }
    switch (group) {
      case "deAnulacionEntidad":
        return types.filter(type => {return type.id.toString()=='ZAE'});
      case "deVeredictoATM":
        return types.filter(type => {return type.id.toString()=='ZVE'});
      case "deVeredictoContactos":
        return types.filter(type => {return type.id.toString()=='ZVE'});
      default:
        return types.filter(type => {return type.id.toString()!='ZAE' && type.id.toString()!='ZVE'});
    }
  }
}

@Pipe({
  name: 'CategoryFilter'
})
export class CategoryFilterPipe implements PipeTransform {

  transform(categories: CategoriaTicket[], types: string[], newTicket?:boolean): CategoriaTicket[] {
    if(!categories) { 
      return []
    }
    if (!types || types.length==0) {
      return categories;
    }
    return categories.filter(category => {
      return types.includes(category.idTipo) && 
       (types.includes('Z8') && category.idTipo=='Z8' ? Constantes.CATEGORIAS_CONSULTA_ENTIDAD.includes(category.id.toString()) : true)
    })
  }
}

@Pipe({
  name: 'IncidentFilter'
})
export class IncidentFilterPipe implements PipeTransform {

  transform(incidents: IncidenciaTicket[], categories: string[]): IncidenciaTicket[] {
    if (!incidents) { 
      return []
    }
    if (!categories || categories.length==0) {
      return incidents
    }
    return incidents.filter(incidencia => {
      return categories.includes(incidencia.idCategoria) && incidencia.visible
    })
  }
}

@Pipe({
  name: 'OCFilter'
})
export class OCFilterPipe implements PipeTransform {

  constructor(private dataSrv: DataService) {}

  transform(opportunities: OC[], filters: {statuses?:string[]}): OC[] {
    this.dataSrv.setFiltering(true);
    let nOpportunities: OC[] = [];
    if(!filters) {
      nOpportunities = opportunities;
    } else if (opportunities) {
      nOpportunities = opportunities.filter(oc => {
        return  (filters.statuses && filters.statuses.length>0) ? filters.statuses.includes(oc.estado.id.toString()) : true;
      });
    }
    this.dataSrv.setFiltering(false);
    return nOpportunities; 
  }
}


@Pipe({
  name: 'OCSearch'
})
export class OCSearchPipe implements PipeTransform {

  constructor(private dataSrv: DataService) {}

  transform(opportunities: OC[], terms: string, collectionSizeFiltered?: {count:number}): OC[] {
    this.dataSrv.setFiltering(true);
    let nOpportunities: OC[] = [];
    if(!terms) {
      nOpportunities = opportunities;
    } else if(opportunities) {
      terms = terms.toLowerCase();
      nOpportunities = opportunities.filter(oc => {
        return  oc.ID.toString().includes(terms) || 
                oc.razonSocial.toLowerCase().includes(terms) || 
                oc.ciclodeVentas.descripcion?.toLowerCase().includes(terms) ||  
                oc.contacto.toLowerCase().includes(terms) || 
                oc.productos.toLowerCase().includes(terms) || 
                oc.planUSD.toLowerCase().includes(terms) || 
                oc.estado.descripcion?.toLowerCase().includes(terms) || 
                oc.promocion.toLowerCase().includes(terms) || 
                oc.rubro.toLowerCase().includes(terms) || 
                oc.ciudad.toLowerCase().includes(terms) || 
                oc.creadoPor.toLowerCase().includes(terms);
      });
    }
    if (collectionSizeFiltered) {
      collectionSizeFiltered.count=nOpportunities.length;
    }
    this.dataSrv.setFiltering(false);
    return nOpportunities;
  }
}

@Pipe({
  name: 'StateFilter'
})
export class StateFilterPipe implements PipeTransform {

  transform(states: Departamento[], countryID: string): Departamento[] {
    if (!states) { 
      return []
    }
    if (!countryID) {
      return states
    }
    return states.filter(state => {return state.idPais==countryID})
  }
}

@Pipe({
  name: 'ProductSearch'
})
export class ProductSearchPipe implements PipeTransform {

  constructor(private dataSrv: DataService) {}

  transform(products: Producto[], searchText: string, collectionSizeFiltered?: {count:number}): Producto[] {
    this.dataSrv.setFiltering(true);
    let nProducts: Producto[] = [];
    if (!products) { 
      nProducts = [];
    } else if (!searchText) {
      nProducts = products;
    } else {
      searchText = searchText.toLowerCase();
      nProducts = products.filter(product => {
        return  product.id.toString().toLowerCase().includes(searchText) || 
                product.descripcion?.toLowerCase().includes(searchText) || 
                (product.onboarding ? 'sí' : 'no').includes(searchText)
      })
    }
    if (collectionSizeFiltered) {
      collectionSizeFiltered.count=nProducts.length;
    }
    this.dataSrv.setFiltering(false);
    return nProducts;
  }
}

@Pipe({
  name: 'ProductFilter'
})
export class ProductFilterPipe implements PipeTransform {

  constructor(private dataSrv: DataService) {}

  transform(products: Producto[], onlyOnboarding: boolean, collectionSizeFiltered?: {count:number}): Producto[] {
    this.dataSrv.setFiltering(true);
    let nProducts: Producto[] = [];
    if (!products) { 
      return []
    }
    if (!onlyOnboarding) {
      return products
    }
    nProducts = products.filter(product => {
      return  product.onboarding==true
    });
    if (collectionSizeFiltered) {
      collectionSizeFiltered.count=nProducts.length;
    }
    this.dataSrv.setFiltering(false);
    return nProducts; 
  }
}


@Pipe({
  name: 'ReportSLASearch'
})
export class ReportSLASearchPipe implements PipeTransform {

  transform(reports: ReporteSLAData[], terms: string): ReporteSLAData[] {
    let filtered: ReporteSLAData[] = [];
    
    if(!terms) {
      filtered = reports;
    } else if(reports) {
      terms = terms.toLowerCase();
      filtered = reports.filter(ticket => {
        return  ticket.ProcessingTypeCodeText?.toString().toLowerCase().includes(terms);
      });
    }
    
    return filtered;
  }
}
