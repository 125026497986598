import { Routes } from "@angular/router";
import { AuthGuardRol } from "src/app/services/auth-guardrol.component";
import { PermGuardService } from "../../services/perm-guard.service";
import { CrearOcComponent } from "./crear-oc/crear-oc.component";
import { DetallesOcComponent } from "./detalles-oc/detalles-oc.component";
import { ListadoOCComponent } from "./listado-oc/listado-oc.component";
import { DataOportunidad } from "./oportunidad.routes.data";


export const Oportunidad_Routes: Routes = [
    {path: 'listado', component: ListadoOCComponent, data: DataOportunidad.listado, canActivate:[PermGuardService]},
    {path: 'crearoc', component: CrearOcComponent,data: DataOportunidad.crear, canActivate:[PermGuardService]},
    {path: ':id/detalle', component: DetallesOcComponent,data:DataOportunidad.detalle, canActivate:[PermGuardService]},

    {path: '', pathMatch: 'full', redirectTo:'listado'},
    {path: '**', pathMatch: 'full', redirectTo: 'listado'}
  ]
  ;
   
