<div class="background-login login container-fluid">
    <div class="flex-row h-100">
        <div class="flex-column d-flex justify-content-center">

            <div class="row row-cols-7 pt-5 justify-content-center">
                <div class="d-flex flex-column justify-content-center pointer">
                    <img src="../../../assets/imgs/Bancard-Logo.svg"  class="logo" alt="Logo Bancard" (click)="home()">
                </div>
            </div>

            <div class="row row-cols-7 mt-3 justify-content-center">

                <div class="d-flex flex-column justify-content-center">
                    <span class="text-bancard">Portal de entidades</span>
                </div>
                
            </div>
        </div>        
    <div class="flex-column d-flex  h-75 justify-content-center">

        <form [formGroup]="formRecovery" (ngSubmit)="sendRecovery()">

                <div class="row pt-5 row-cols-4 justify-content-center ">
                
                    <div class="d-flex flex-column">
                        <input formControlName="email" class="input-login" type="email" placeholder="Email">
                    </div>
                
                </div>

                <div class="row py-4 row-cols-4 justify-content-center">
                
                    <div class="d-flex flex-column">
                        <button *ngIf="!enviandoRecovery" class="btn-portal" type="submit">
                            <span class="text-btn-portal">
                                Recuperar contraseña
                            </span>
                        </button>
                        <button *ngIf="enviandoRecovery" class="btn-portal" type="submit">
                            <span class="text-btn-portal">
                                Enviando <fa-icon [icon]="faSpinner" animation="spin"></fa-icon>
                            </span>
                        </button>

                    </div>
                </div>
        </form>
    </div>


    </div>
</div>