import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  Input,
} from "@angular/core";
import { UsersService } from "../services/users.service";

/**
 * @usageNotes
 * ### Directiva hasPermission
 * El hasPermission es una directiva que renderiza el component en caso de que el usuario tenga los privilegios.
 * Para utilizar este directiva es necesario para como argumento un array de string que indicar los permisos necesarios. Ej:
 * ```HTML
 * <div *hasPermission="['SEC_ENTIDADES_OPERACION']">
 *    SE VA A MOSTRAR SI TIENE EL PERMISO
 * </div>
 * ```
 */
@Directive({
  selector: "[hasPermission]",
})
export class HasPermissionDirective implements OnInit {
  /** @public hasPermission - Un array de permisos a ser chequeados, para utizar agregar `*hasPermission="['SEC_ENTIDADES_OPERACION'] */
  @Input("hasPermission") permisos: any[] = [];
  /** @public criterio - AND o OR para controlar los permisos del rol, para utizar agregar *hasPermission="['SEC_ENTIDADES_OPERACION'; criterio:'OR'] */
  @Input("hasPermissionCriterio") criterio: "OR" | "AND" = "AND";

  constructor(
    private viewContainerRef: ViewContainerRef,
    private template: TemplateRef<any>,
    private usersSrv: UsersService
  ) {}

  ngOnInit(): void {
    const hasPerm = this.usersSrv.actualUSerHasPerm(
      this.permisos,
      this.criterio
    );
    if (hasPerm) {
      this.viewContainerRef.createEmbeddedView(this.template);
    }
  }
}
