import { Routes } from "@angular/router";
import { CrearUsuarioComponent } from "./crear-usuario/crear-usuario.component";
import { EditarUsuariosComponent } from "./editar-usuarios/editar-usuarios.component";
import { UserListComponent } from "./listado-usuarios/listado-usuarios.component";
import { DataUsuarios } from "./usuarios.routes.data";
import { PermGuardService } from "../../services/perm-guard.service";


export const Usuario_Routes: Routes = [
    {path: 'userlist', component: UserListComponent, data: DataUsuarios.listado, canActivate:[PermGuardService]},
    {path: 'nuevousuario', component: CrearUsuarioComponent, canActivate:[PermGuardService], data: DataUsuarios.crear},
    {path: 'editarusuario', component: EditarUsuariosComponent, canActivate:[PermGuardService], data: DataUsuarios.editar},
    {path: '', pathMatch: 'full', redirectTo:'userlist'},
    {path: '**', pathMatch: 'full', redirectTo: 'userslist'}
  ]
  ;
   
