import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ATM, Barrio, Basico, CategoriaTicket, Ciudad, Cliente, CuentaFacturacion, Departamento, 
  Entidad, IncidenciaTicket, Pais, Producto, Sucursal, 
  Ticket, TipoEmpresa, TipoTicket, Zona, ParametroGeneral, Param, ParamCuentaFacturacion, ParamATM, ParamTipoEmpresa, ParamDepartamento, TipoAdjunto, MCC, GrupoTicket, LastChange } from '../interfaces/interfaces';
import { MongoDBService } from './mongoDB.service';
import { cloneDeep } from 'lodash';
import { AuditService } from './audit.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  //Se declaran las variables del servicio
  private lastChange = new BehaviorSubject<LastChange[]>([]);
  private tickets = new BehaviorSubject<Ticket[]>([]);
  private ticketsTypes = new BehaviorSubject<TipoTicket[]>([]);
  private ticketsTypesCategories = new BehaviorSubject<CategoriaTicket[]>([]);
  private ticketsTypesCategoriesIncidents = new BehaviorSubject<IncidenciaTicket[]>([]);
  private ticketsPriorities = new BehaviorSubject<Basico[]>([]);
  private ticketsStatuses = new BehaviorSubject<Basico[]>([]);
  private opportunitiesStatuses = new BehaviorSubject<Basico[]>([]);
  private clientTypes = new BehaviorSubject<Basico[]>([]);
  private preLoadStatus = new BehaviorSubject<boolean>(false);
  private loadingStatus = new BehaviorSubject<boolean>(false);
  private cargando = new BehaviorSubject<boolean>(false);
  private filtering = new BehaviorSubject<boolean>(false);
  private countries = new BehaviorSubject<Pais[]>([]);
  private states = new BehaviorSubject<Departamento[]>([]);
  private businessTypes = new BehaviorSubject<TipoEmpresa[]>([]);
  private businessLines = new BehaviorSubject<Basico[]>([]);
  private branches = new BehaviorSubject<Sucursal[]>([]);
  private entities = new BehaviorSubject<Entidad[]>([]);
  private atms = new BehaviorSubject<ATM[]>([]);
  private atmProviders = new BehaviorSubject<Basico[]>([]);
  private genders = new BehaviorSubject<Basico[]>([]);
  private products = new BehaviorSubject<Producto[]>([]);
  private accountTypes = new BehaviorSubject<Basico[]>([]);
  private clientDocumentTypes = new BehaviorSubject<Basico[]>([]);
  private facturationAccountTypes = new BehaviorSubject<CuentaFacturacion[]>([]);
  private filters : string[] = [];
  private ticketsClassifications = new BehaviorSubject<ParametroGeneral[]>([]);
  private tiposAdjuntos = new BehaviorSubject<TipoAdjunto[]>([]);
  private mccs = new BehaviorSubject<MCC[]>([]);
  private paymentMethods = new BehaviorSubject<Basico[]>([]);
  private gruposTickets = new BehaviorSubject<GrupoTicket[]>([]);
  private queriesOdata = new BehaviorSubject<any[]>([]);
  private perfilesComerios = new BehaviorSubject<Basico[]>([]);
  private perfilesDescargas = new BehaviorSubject<Basico[]>([]);
  private requiereConfirmacion = new BehaviorSubject<boolean>(false);

  constructor (
    private mongoSrv: MongoDBService,
    private auditSrv: AuditService,
    private storageSrv: StorageService
  ) {}

  /** Método para cargar todos los datos al servicio */
  async loadAllData() {
    this.cargando.next(true);
    await this.loadLastChange()
    this.cargando.next(false);
    this.preLoadStatus.next(true);
  }
  
  /** Método para cargar los filtros predefinidos */
  async loadFilters() {
    const datepipe: DatePipe = new DatePipe('en-US');
    const f1 = moment(datepipe.transform(new Date(), 'YYYY-MM-dd')).subtract(1,'month').toISOString();
    const f2 = moment(datepipe.transform(new Date(), 'YYYY-MM-dd')).toISOString();
    this.filters.push(`(CreationDateTime ge datetimeoffset'${f1}' and CreationDateTime le datetimeoffset'${f2}')`)
    this.filters.push(`ServiceRequestUserLifeCycleStatusCode ne 'Y3'`)
  }

  /** Método para actualizar los tipos del tickets */
  async loadTicketsTypes() {
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='TipoTicket');
    const localLastChangeValue = this.storageSrv.get('ticketsTypes_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const tt = this.storageSrv.get('ticketsTypes');
      const tc = this.storageSrv.get('ticketsCategories');
      const tci = this.storageSrv.get('ticketsIncidents');
      if (tt) {
        try{
          const ots = JSON.parse(tt);
          if(ots && ots.length>0) {
            requireGet = false
            this.ticketsTypes.next(ots);
          }
        } catch (error) {
          requireGet = true
        }
      } else {
        requireGet = true
      }
      if (tc) {
        try{
          const ots = JSON.parse(tc);
          if(ots && ots.length>0) {
            if(!requireGet) requireGet = false

            this.ticketsTypesCategories.next(ots);
          }
        } catch (error) {
          requireGet = true
        }
      } else {
        requireGet = true
      }
      if (tci) {
        try{
          const ots = JSON.parse(tci);
          if(ots && ots.length>0) {
            if(!requireGet) requireGet = false
            
            this.ticketsTypesCategoriesIncidents.next(ots);
          }
        } catch (error) {
          requireGet = true
        }
      } else {
        requireGet = true
      }
      return;  //Si no hay cambios, no hacemos nada. 
    } 
    if(requireGet) {
      const data = await this.mongoSrv.getAll('/tipo-tickets');
      if (data) {
        this.auditSrv.logAction({
          modulo: "dataService",
          accion: "Cargar tipo de tickets",
          detalle: `Tickets obtenidos ${data.length}`
        })
        const tipos : TipoTicket[] = [];
        data.forEach(element => {
          if (element.enabled==true) {
            const tipo: TipoTicket = {
              OID:element._id,
              id:element.id_ticket,
              descripcion:element.name
            };
            const categorias : CategoriaTicket[] = [];
            element.categoryServices.forEach(category => {
              const incidencias : IncidenciaTicket[] = [];
              category.categoryIncidences.forEach(incident => {
                const incidencia: IncidenciaTicket = {
                  OID:incident._id,
                  id:incident.id_category,
                  descripcion:incident.name,
                  idCategoria: category.id_category,
                  visible: incident.visible
                };
                incidencias.push(incidencia);
                this.addIncident(incidencia);
              });
              const categoria: CategoriaTicket = {
                OID :category._id,
                id : category.id_category,
                descripcion : category.name,
                incidencias : incidencias,
                idTipo: element.id_ticket
              };
              categorias.push(categoria);
              this.addCategory(categoria);            
            })
            tipo.categorias=categorias;
            tipo.estadosPosibles=element.estadosPosibles.map(estado => ({
              id: estado.codigo,
              descripcion: estado.descripcion
            }));
            tipos.push(tipo);
          }
        });
        this.storageSrv.save('ticketsTypes',JSON.stringify(tipos));
        this.storageSrv.save('ticketsCategories',JSON.stringify(this.ticketsTypesCategories.getValue()));
        this.storageSrv.save('ticketsIncidents',JSON.stringify(this.ticketsTypesCategoriesIncidents.getValue()));
        this.storageSrv.save('ticketsTypes_lastChange',lastChangeValue!.last_modified);
        this.ticketsTypes.next(tipos);
      }
    }    
  }

  /** Método para actualizar los estados de tickets */
  async loadTicketsStatuses() {
    let requireGet = true;
    const lastChangeValue = await this.lastChange.value.find(lc => lc._id=='EstadoTicket');
    const localLastChangeValue = this.storageSrv.get('ticketsStatuses_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('ticketsStatuses');
      if (ts) {
        try{
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false
            this.ticketsStatuses.next(ots);
            return;
          }
        } catch (error) {
          requireGet = true
        }
      } 
    } 
    if(requireGet) {
      let statuses: Basico[] = [];
      let nStatuses: ParametroGeneral[]=[];
      
      const data = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=ESTADOS-TICKET`);
      if (data) {
        nStatuses = data
        statuses = nStatuses.map((priority:ParametroGeneral)=>{
          return {id:priority.valor,descripcion:priority.descripcion}
        })
      }

      this.storageSrv.save('ticketsStatuses',JSON.stringify(statuses));
      this.storageSrv.save('ticketsStatuses_lastChange',lastChangeValue!.last_modified);
      this.ticketsStatuses.next(statuses)
    }
  }

  async loadTicketsPriorities(){
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='PrioridadTicket');
    const localLastChangeValue = this.storageSrv.get('ticketsPriorities_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('ticketsPriorities');
      if (ts) {
        try {
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false;
            this.ticketsPriorities.next(ots);
            return;
          } 
        } catch (error) {
          requireGet = true;
        }
      }
    }

    if (requireGet){
      let nPriorities: ParametroGeneral[]=[];
      let priorities: Basico[] = [];
      const data = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=PRIORIDAD-TICKET`);
      if (data) {
        nPriorities = data
        priorities = nPriorities.map((priority:ParametroGeneral)=>{
          return {id:priority.valor,descripcion:priority.descripcion}
        })
      }
      this.storageSrv.save('ticketsPriorities',JSON.stringify(priorities));
      this.storageSrv.save('ticketsPriorities_lastChange',lastChangeValue!.last_modified);
      this.ticketsPriorities.next(priorities);
    }
  }

  /** Método para actualizar los estados de oportunidades comerciales */
  async loadOpportunitiesStatuses() {
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='EstadosOC');
    const localLastChangeValue = this.storageSrv.get('opportunitiesStatuses_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('opportunitiesStatuses');
      if (ts) {
        try{
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false;
            this.opportunitiesStatuses.next(ots);
            return
          }
        } catch (error) {
          requireGet = true
        }
      } 
    } 
    if (requireGet) {
      let statuses: Basico[] = [];
      let nStatuses: ParametroGeneral[]=[];
      
      const data = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=ESTADOS-OPORTUNIDAD`);
      if (data) {
        nStatuses = data
        statuses = nStatuses.map((priority:ParametroGeneral)=>{
          return {id:priority.valor,descripcion:priority.descripcion}
        })
      }
      this.storageSrv.save('opportunitiesStatuses',JSON.stringify(statuses));
      this.storageSrv.save('opportunitiesStatuses_lastChange',lastChangeValue!.last_modified);
      this.opportunitiesStatuses.next(statuses)
    }
  }

  /** Método para obtener los tipos de negocio */
  async loadBusinessTypes() {
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='TipoEmpresa');
    const localLastChangeValue = this.storageSrv.get('businessTypes_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('businessTypes');
      if (ts) {
        try{
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false;
            this.businessTypes.next(ots);
            return;
          }
        } catch(error) {
          requireGet = true  
        }
      } 
    } 
    if(requireGet) {
      let types: TipoEmpresa[] = [];
      let nTypes: ParamTipoEmpresa[]=[]

      const data = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=BUSINESS-TYPES`);
      if (data) {
        nTypes = data
        types = nTypes.map((priority:ParamTipoEmpresa)=>{
          return {
            id:priority.valor,
            descripcion:priority.descripcion,
            C4C_ID:priority.C4C_ID
          }
        })
      }
      this.storageSrv.save('businessTypes',JSON.stringify(types));
      this.storageSrv.save('businessTypes_lastChange',lastChangeValue!.last_modified);
      this.businessTypes.next(types);
    }
  }

  /** Método para obtener los rubros de negocio */
  async loadBusinessLines() {
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='Rubros');
    const localLastChangeValue = this.storageSrv.get('businessLines_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('businessLines');
      if (ts) {
        try{
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false;
            this.businessLines.next(JSON.parse(ts));
            return;
          }
        } catch(error) {
          requireGet = true
        }
      }
    } 
    if (requireGet) {
      let lines: Basico[] = [];
      let nLines: ParametroGeneral[]=[];
      
      const data = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=BUSINESS-LINES`);
      if (data) {
        nLines = data
        lines = nLines.map((priority:ParametroGeneral)=>{
          return {id:priority.valor,descripcion:priority.descripcion}
        })
      }
      this.storageSrv.save('businessLines',JSON.stringify(lines));
      this.storageSrv.save('businessLines_lastChange',lastChangeValue!.last_modified);
      this.businessLines.next(lines);
    }
  }

  /** Método para cargar los tipos de cliente */
  async loadClientTypes() {
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='TipoCliente');
    const localLastChangeValue = this.storageSrv.get('clientTypes_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('clientTypes');
      if (ts) {
        try{ 
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false
            this.clientTypes.next(JSON.parse(ts));
            return;  //Si no hay cambios, no hacemos nada.
          }
        } catch (error) {
          requireGet = true
        }
      } 
    } 
    if (requireGet) {
      let types: Basico[] = [];
      let nTypes: ParametroGeneral[]=[];
      
      const data = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=TIPO-CLIENTE`);
      if (data) {
        nTypes = data
        types = nTypes.map((priority:ParametroGeneral)=>{
          return {id:priority.valor,descripcion:priority.descripcion}
        })
      }
      this.storageSrv.save('clientTypes',JSON.stringify(types));
      this.storageSrv.save('clientTypes_lastChange',lastChangeValue!.last_modified);
      this.clientTypes.next(types)
    }
  }

  /** Método para cargar departamentos, ciudades, barrios y zonas */
  async loadLocations() {
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='Ubicaciones');
    const localLastChangeValue = this.storageSrv.get('locations_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('locations');
      if (ts) {
        try{
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false;
            const deps = ots[0].departamentos;
            this.countries.next(ots);
            this.states.next(deps);
            return;
          }
        } catch (error) {
          requireGet = true
        }
      }
    } 
    if(requireGet) {
      let states: Departamento[] = [];
      let nStates: ParamDepartamento[]=[]

      const data = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=CIUDADES`);
      if (data) {
        nStates = data
        states = nStates.map((priority:ParamDepartamento)=>{
          return {
            id:priority.valor,
            descripcion:priority.descripcion,
            idPais:priority.idPais
          }
        })
      }

      const countries: Pais[] = [];
      countries.push({id:"PY",descripcion:"Paraguay",departamentos:states});

      this.storageSrv.save('locations',JSON.stringify(countries));
      this.storageSrv.save('locations_lastChange',lastChangeValue!.last_modified);

      this.countries.next(countries);
      this.states.next(states);
    }
  }

  /** Método para actualizar las sucursales */
  async loadBranches() {
    const branches = await this.mongoSrv.getAll('/sucursal');
    if (branches) {
      this.auditSrv.logAction({
        modulo: "dataService",
        accion: "Cargar sucursales",
        detalle: `Sucursales obtenidas ${branches.length}`
      })
      const nbranches: Sucursal[] = [];
      branches.forEach((result:any) => {
        const branch : Sucursal = {
          OID: result.c4c_ObjectID,
          C4C_ID: result.c4c_id,
          numero:result.numSuc,
          nombre:result.DenCom
        }
        nbranches.push(branch);
      });
      this.branches.next(nbranches);
    }
  }

  /** Método para actualizar las entidades */
  async loadEntities() {
    let requireGet = true;

    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='Entidades');
    const localLastChangeValue = this.storageSrv.get('entities_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('entities');
      if (ts) {
        try{ 
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false;
            this.entities.next(JSON.parse(ts));
            return;
          }
        } catch (error) {
          requireGet = true
        }
      } 
    } 
    if(requireGet) {
      const entities = await this.mongoSrv.getAll(`/entity/simple`);
      if (entities) {
        this.auditSrv.logAction({
          modulo: "dataService",
          accion: "Cargar entidades",
          detalle: `Entidades obtenidas ${entities.length}`
        })
        const nEntities: Entidad[] = [];
        entities.forEach((entity:any) => {
          const nEntity:Entidad = {
            id: entity._id,
            C4C_OID: entity.C4C_ObjectID,
            C4C_ID: entity.c4c_id,
            nombre: entity.name,
            codigoEntidad: entity.codigoEntidad,
            fuerzaDeVenta: entity.fuerzaDeVenta,
            activo: entity.enabled
          }
          nEntities.push(nEntity);
        });
        this.storageSrv.save('entities',JSON.stringify(nEntities));
        this.storageSrv.save('entities_lastChange',lastChangeValue!.last_modified);
        this.entities.next(nEntities);
      }
    }
  }

  /** Método para actualizar las ATMS */
  async loadATM() {
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='ATM');
    const localLastChangeValue = this.storageSrv.get('atms_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('atms');
      if (ts) {
        try{
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false;
            this.atms.next(ots);
            return;
          }
        }catch(error) {
          requireGet = true
        }
      } 
    } 
    if(requireGet) {
      let nATMS: ParamATM[]=[];
      let atms: ATM[] = [];
      const data = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=ATMS`);
      if (data) {
        nATMS = data
        atms = nATMS.map((atm:ParamATM)=>{
          return {
            id:atm.valor,
            descripcion:atm.descripcion,
            modelos: atm.modelos}
        })
      }
      this.storageSrv.save('atms',JSON.stringify(atms));
      this.storageSrv.save('atms_lastChange',lastChangeValue!.last_modified);
      this.atms.next(atms);
    }
  }

  /** Método para actualizar las ATMS */
  async loadATMProviders() {
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='ProveedorATM');
    const localLastChangeValue = this.storageSrv.get('atmProviders_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('atmProviders');
      if (ts) {
        try {
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false;
            this.atmProviders.next(ots);
            return;
          }
        } catch (error) {
         requireGet = true   
        }
      }
    }
    if(requireGet) {
      let providers: Basico[] = [];
      let nProviders: ParametroGeneral[]=[];
      
      const data = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=PROVIDERS`);
      if (data) {
        nProviders = data
        providers = nProviders.map((priority:ParametroGeneral)=>{
          return {id:priority.valor,descripcion:priority.descripcion}
        })
      }
      this.storageSrv.save('atmProviders',JSON.stringify(providers));
      this.storageSrv.save('atmProviders_lastChange',lastChangeValue!.last_modified);
      this.atmProviders.next(providers);
    }
  }

  /** Método para actualizar los géneros */
  async loadGenders() {
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='Generos');
    const localLastChangeValue = this.storageSrv.get('genders_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('genders');
      if (ts) {
        try{
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false;
            this.genders.next(ots);
            return;
          }
        } catch (error) {
          requireGet = true
        }
      } 
    } 
    if(requireGet) {
      let genders: Basico[] = [];
      let nGenders: ParametroGeneral[]=[];
      
      const data = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=GENDERS`);
      if (data) {
        nGenders = data
        genders = nGenders.map((priority:ParametroGeneral)=>{
          return {id:priority.valor,descripcion:priority.descripcion}
        })
      }
      this.storageSrv.save('genders',JSON.stringify(genders));
      this.storageSrv.save('genders_lastChange',lastChangeValue!.last_modified);
      this.genders.next(genders);
    }
  }

  async loadTicketsClassifications(){
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='ClasificacionTicket');
    const localLastChangeValue = this.storageSrv.get('ticketsClassifications_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('ticketsClassifications');
      if (ts) {
        try {
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false
            this.ticketsClassifications.next(ots);
            return;
          }
        } catch (error) {
          requireGet = true
        }
      } 
    }
    if(requireGet) {
      let nTicketsClassifications: ParametroGeneral[]=[];
      const clasifications = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=CLASIFICACION-TICKET`);
      if (clasifications) {
        this.auditSrv.logAction({
          modulo: "dataService",
          accion: "Cargar clasificacion de tickets",
          detalle: `Clasificaciones obtenidas ${clasifications.length}`
        })
        nTicketsClassifications = clasifications
      }
      this.storageSrv.save('ticketsClassifications',JSON.stringify(nTicketsClassifications));
      this.storageSrv.save('ticketsClassifications_lastChange',lastChangeValue!.last_modified);
      this.ticketsClassifications.next(nTicketsClassifications);
    }
  }

  async loadFacturationAccountsTypes() {
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='TipoCuentaFacturacion');
    const localLastChangeValue = this.storageSrv.get('facturationAccountTypes_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('facturationAccountTypes');
      if (ts) {
        try{
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false
            this.facturationAccountTypes.next(ots);
            return;
          }
        } catch(error){
          requireGet = true
        }
      } 
    }
    if(requireGet) {
      let nFacturationAccounts: ParamCuentaFacturacion[]=[];
      let facturationAccounts: CuentaFacturacion[] = [];
      const data = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=BILLINGACCOUNTTYPES`);
      if (data) {
        nFacturationAccounts = data
        facturationAccounts = nFacturationAccounts.map((priority:ParamCuentaFacturacion)=>{
          return {
            id:priority.valor,
            descripcion:priority.descripcion, 
            codigo: priority.codigo, 
            vigente: priority.vigente, 
            productos: priority.productos}
        })
      }
      this.storageSrv.save('facturationAccountTypes',JSON.stringify(facturationAccounts));
      this.storageSrv.save('facturationAccountTypes_lastChange',lastChangeValue!.last_modified);
      this.facturationAccountTypes.next(facturationAccounts);
    }
  }

  /** Método para cargar los tipos de cliente */
  async loadClientDocumentTypes() {
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='TipoDocumentoCliente');
    const localLastChangeValue = this.storageSrv.get('clientDocumentTypes_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('clientDocumentTypes');
      if (ts) {
        try{
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false
            this.clientDocumentTypes.next(ots);
            return;
          }
        } catch (error) {
          requireGet = true
        }
      } 
    } 
    if(requireGet) {
      let types: Basico[] = [];
      let nTypes: ParametroGeneral[]=[];
      
      const data = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=TIPO-DOCUMENTO-CLIENTE`);
      if (data) {
        nTypes = data
        types = nTypes.map((tipo:ParametroGeneral)=>{
          return {id:tipo.codigo,descripcion:tipo.valor}
        })
      }
      this.storageSrv.save('clientDocumentTypes',JSON.stringify(types));
      this.storageSrv.save('clientDocumentTypes_lastChange',lastChangeValue!.last_modified);
      this.clientDocumentTypes.next(types)
    }
  }

  /** Método para obtener los tipos de adjuntos */
  async loadTiposAdjuntos() {
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='TipoAdjunto');
    const localLastChangeValue = this.storageSrv.get('tiposAdjuntos_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('tiposAdjuntos');
      if (ts) {
        try{
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false
            this.tiposAdjuntos.next(ots);
            return
          }
        } catch(error) {
          requireGet = true
        }
      } 
    } 
    if(requireGet) {
      const tipos = await this.mongoSrv.getAll("/tipoAdjunto");
      if (tipos) {
        this.auditSrv.logAction({
          accion: 'Carga de tipos de adjuntos',
          modulo: "DataService",
          detalle: `Tipos de adjuntos obtenidos: ${tipos.length}`
        })
        this.storageSrv.save('tiposAdjuntos',JSON.stringify(tipos));
        this.storageSrv.save('tiposAdjuntos_lastChange',lastChangeValue!.last_modified);
        this.tiposAdjuntos.next(tipos);
      }
    }
  }

  /** Método para actualizar los métodos de pago */
  async loadPaymentMethods() {
    let paymentMethods: Basico[] = [];
    let nPaymentMethods: ParametroGeneral[]=[];

    const data = await this.mongoSrv.getAll(`/parametroGenerales/dominio?dominio=PAYMENT-METHODS`);
    if (data) {
      nPaymentMethods = data
      paymentMethods = nPaymentMethods.map((pm:ParametroGeneral)=>{
        return {id:pm.codigo,descripcion:pm.valor}
      })
    }

    this.paymentMethods.next(paymentMethods);
  }
  
  /** Método para obtener los grupos tickets */
  async loadGruposTickets() {
    let requireGet = true;  
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='GrupoTicket');
    const localLastChangeValue = this.storageSrv.get('GrupoTicket_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('GrupoTicket');
      if (ts) {
        try{
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false
            this.gruposTickets.next(ots);
            return;
          }
        } catch (error) {
          requireGet = true
        }
      } 
    } 
    if(requireGet) {
      let nGrupos: GrupoTicket[] = [];
      const data = await this.mongoSrv.getAll(`/grupos-tickets?orderBy=orden&orderDir=asc&size=-1`);
      if (data) {
        nGrupos = data.datos
      }
      if (nGrupos) {
        this.auditSrv.logAction({
          modulo: "dataService",
          accion: "Cargar grupos",
          detalle: `grupos tickets obtenidos ${nGrupos.length}`
        })
        
        this.storageSrv.save('GrupoTicket',JSON.stringify(nGrupos));
        this.storageSrv.save('GrupoTicket_lastChange',lastChangeValue!.last_modified);
        this.gruposTickets.next(nGrupos);
      }
    }
  }

  /** Método para obtener los grupos tickets */
  async loadQueriesOdata() {
    let requireGet = true;
    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='QueryOdata');
    const localLastChangeValue = this.storageSrv.get('QueryOdata_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('QueryOdata');
      if (ts) {
        try{
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false
            this.queriesOdata.next(ots);
            return;
          }
        } catch (error) {
          requireGet = true
        }
      } 
    } 
    if(requireGet) {
      let nQueries: any[] = [];
      const data = await this.mongoSrv.getAll(`/grupos-tickets/queries?size=-1`);
      if (data) {
        nQueries = data
      }
      if (nQueries) {
        this.auditSrv.logAction({
          modulo: "dataService",
          accion: "Cargar queries",
          detalle: `queries obtenidos ${nQueries.length}`
        })
        
        this.storageSrv.save('QueryOdata',JSON.stringify(nQueries));
        this.storageSrv.save('QueryOdata_lastChange',lastChangeValue!.last_modified);
        this.queriesOdata.next(nQueries);
      }
    }
  }

  /** Método para actualizar los tipos del tickets */
  async loadLastChange() {
    const data = await this.mongoSrv.getAll('/last-change');
    if (data) {
      const lastChangeData : LastChange[] = [];
      data.forEach(element => {
        const lc: LastChange = {
          _id:element._id,
          last_modified:element.last_modified
        };
        lastChangeData.push(lc);
      });
      this.lastChange.next(lastChangeData);
    }
  }

  /** Método para actualizar los mcc */
  async loadMCCs() {
    let requireGet = true;

    const lastChangeValue = this.lastChange.value.find(lc => lc._id=='MCC');
    const localLastChangeValue = this.storageSrv.get('mccs_lastChange');
    if (lastChangeValue && localLastChangeValue && lastChangeValue.last_modified==localLastChangeValue) {
      const ts = this.storageSrv.get('mccs');
      if (ts) {
        try{
          const ots = JSON.parse(ts);
          if(ots && ots.length>0) {
            requireGet = false
            this.mccs.next(ots);
            return;
          }
        } catch (error) {
          requireGet = true
        } 
      } 
    } 
    if(requireGet) {
      let mccs: MCC[] = [];
      
      const data = await this.mongoSrv.getAll(`/mcc`);
      if (data) {
        this.auditSrv.logAction({
          accion: 'Carga de mcc',
          modulo: "DataService",
          detalle: `MCCs obtenidos: ${data.length}`
        })
        mccs = data
      }
      this.storageSrv.save('mccs',JSON.stringify(mccs));
      this.storageSrv.save('mccs_lastChange',lastChangeValue!.last_modified);
      this.mccs.next(mccs);
    }
  }
  
  async loadProducts() {
    let nProducts : Producto[] = [];
    const data = await this.mongoSrv.getAll(`/productos-pe`);
    if (data) {
      nProducts = data.datos
    }
    this.products.next(nProducts);
  }
  /**
   * Método para cargar los perfiles de comercios
   */
  async loadPerfilesComerios() {
    let nPerfiles : Basico[] = [];
    const data = await this.mongoSrv.getAll(`/perfil-comercio`);
    if (data) {
      nPerfiles = data.datos.map((perfil)=>{
        return { OID: perfil.codigo, descripcion: perfil.descripcion };
      })
    }
    this.perfilesComerios.next(nPerfiles);
  }
  /**
   * Método para cargar los perfiles de descargas
   */
  async loadPerfilesDescargas() {
    let nPerfiles : Basico[] = [];
    const data = await this.mongoSrv.getAll(`/perfil-descarga`);

    if (data) {
      nPerfiles = data.datos.map((perfil)=>{
        return { OID: perfil.codigo, descripcion: perfil.descripcion };
      })
    }
    this.perfilesDescargas.next(nPerfiles);
  }

  async loadConfigConfirmacionCuentas() {
    const confirmacionCuentasConfig = await this.mongoSrv.getAll(`/configuracion-confirmacion-cuenta/user-entity`);
    if(confirmacionCuentasConfig && confirmacionCuentasConfig.requiereConfirmacion == 'SI') {
      this.requiereConfirmacion.next(true);
    } else {
      this.requiereConfirmacion.next(false);
    }
  }

  /** Método para obtener el estado de carga */
  getLoadingStatus() {
    return this.loadingStatus.asObservable();
  }

  /** Método para obtener el estado de carga principal */
  getPreLoadStatus() {
    return this.preLoadStatus.asObservable();
  }

  /** Método para obtener el estado de carga inicial */
  estaCargando() {
    return this.cargando.asObservable();
  }

  setCargando(estado: boolean) {
    this.cargando.next(estado);
  }

  /** Método para cargar los tipos de cuentas */
  async loadAccountTypes() {
    const types: Basico[] = [];
    types.push({id:"CC",descripcion:'Cuenta Corriente',OID:'2'});
    types.push({id:"TPP",descripcion:'Tarjeta prepaga',OID:''});
    types.push({id:"CA",descripcion:'Caja de ahorro',OID:'1'});
    this.accountTypes.next(types)
  }


  /** Método para obtener el estado de filtrado */
  getFilteringStatus() {
    return this.filtering.asObservable();
  }

   /** Método para obtener los tipos adjunto */
   getTiposAdjuntos(): Observable<TipoAdjunto[]> {
    return this.tiposAdjuntos.asObservable();
  }

  /** Método para obtener los tickets del servicio */
  getTickets(): Observable<Ticket[]> {
    return this.tickets.asObservable();
  }

  /** Método para obtener las clasificaciones de tickets */
  getTicketsClassifications(): Observable<ParametroGeneral[]> {
    return this.ticketsClassifications.asObservable();
  }

  /** Método para obtener los tipos de tickets */
  getTicketsTypes(): Observable<TipoTicket[]> {
    return this.ticketsTypes.asObservable();
  }

  /** Método para obtener los tipos de tickets */
  getTicketsTypesCategories(): Observable<CategoriaTicket[]> {
    return this.ticketsTypesCategories.asObservable();
  }

  /** Método para obtener los tipos de tickets */
  getTicketsTypesCategoriesIncidents(): Observable<IncidenciaTicket[]> {
    return this.ticketsTypesCategoriesIncidents.asObservable();
  }

  /** Método para obtener prioridad de tickets */
  getTicketsPriorities(): Observable<Basico[]> {
    return this.ticketsPriorities.asObservable();
  }

  /** Método para obtener estados de tickets */
  getTicketsStatuses(): Observable<Basico[]> {
    return this.ticketsStatuses.asObservable();
  }

  /** Método para obtener tipos de clientes */
  getClientTypes(): Observable<Basico[]> {
    return this.clientTypes.asObservable();
  }

  /** Método para obtener el tipo, categoria e incidencia de un ticket
   * @param idTipo id del tipo de ticket
   * @param idCategoria id de la categoria del ticket
   * @param idIncidencia id de la incidencia del ticket
   */
  getTicketType(idTipo:string, idCategoria: string,idIncidencia: string) : TipoTicket | undefined {
    // Hacer una copia profunda del objeto this.ticketsTypes para evitar alterar los datos originales
    const nTiquetsType = cloneDeep(this.ticketsTypes.getValue());

    // Buscar un ticket que coincida con los ID's proporcionados
    return nTiquetsType.find((tipo) => {
      if (tipo.id == idTipo) {
        // Si el ticket tiene categorías y estas no están vacías
        if (tipo.categorias && tipo.categorias.length > 0) {
          // Iterar a través de las categorías del ticket
          tipo.categorias.forEach(categoria => {
            if (categoria.id == idCategoria) {
              // Buscar una incidencia que coincida con el ID proporcionado, o asignar undefined si no se encuentra ninguna
              categoria.incidencia = categoria.incidencias != undefined ? categoria.incidencias.find(incidencia => {
                return incidencia.id == idIncidencia;
              }) : undefined;

              // Eliminar el campo incidencias de la categoría
              delete categoria.incidencias;

              // Asignar la categoría al campo categoria del ticket
              tipo.categoria = categoria;
            }
          });

          // Eliminar el campo categorias del ticket
          delete tipo.categorias;
        }
        
        // Si se encontró un ticket que coincide con los ID's proporcionados, devolverlo
        return tipo;
      } else {
        // Si no se encontró un ticket que coincida con los ID's proporcionados, devolver undefined
        return undefined;
      }
    });
  }


  /** Método para obtener filtros a aplicar directamente en el pedido de tickets al servicio */
  getFilters() {
    return this.filters;
  }

  /** Método para obtener estados de tickets */
  getOpportunitiesStatuses(): Observable<Basico[]> {
    return this.opportunitiesStatuses.asObservable();
  }

  /** Método para obtener un estado de oportunidad comercial
   * @param id id de la oportunidad comercial a buscar
   * @return si encuentra devuelve el estado de oportunidad comercial, sino devuelve undefined
   */
  getOpportunityStatus(id: string): Basico | undefined {
    return this.opportunitiesStatuses.value.find(status => {return status.id==id});
  }

  /** Método para obtener los tipos de negocio */
  getBusinessTypes() : Observable<TipoEmpresa[]> {
    return this.businessTypes.asObservable();
  }

  /** Método para obtener los tipos de negocio */
  getBusinessLines() : Observable<Basico[]> {
    return this.businessLines.asObservable();
  }

  /** Método para obtener los paises */
  getCountries() : Observable<Pais[]> {
    return this.countries.asObservable();
  }

  /** Método para obtener los tipos de negocio */
  getStates() : Observable<Departamento[]> {
    return this.states.asObservable();
  }

  /** Método para obtener tipos de clientes */
  getProducts(): Observable<Producto[]> {
    return this.products.asObservable();
  }
  
  /** Método para obtener perfiles de comercios */
  getPerfilesComercios(): Observable<Basico[]> {
    return this.perfilesComerios.asObservable();
  }


  /** Método para obtener perfiles de comercios */
  getPerfilesDescargas(): Observable<Basico[]> {
    return this.perfilesDescargas.asObservable();
  }

  /** Método para obtener tipos de clientes */
  getAccountTypes(): Observable<Basico[]> {
    return this.accountTypes.asObservable();
  }

  /** Método para obtener tipos de clientes */
  getClientDocumentTypes(): Observable<Basico[]> {
    return this.clientDocumentTypes.asObservable();
  }

  /** Método para obtener las ciudades de un departamento
   * @param stateID id del departamento del cual obtener las ciudades
   * @return array con las ciudades del departamento, si no encuentra o se produce un error devuelve el array vacio
   */
  async getCities(stateID: number) : Promise<Ciudad[]>{
    let cities: Ciudad[] = [];
    const params : Param[] = [{key: 'stateId',value: stateID}];
    const ncities = await this.mongoSrv.get('/cities/byStateId/',params) 
    if (ncities) {
      cities = ncities;
    }
    return cities;
  }

  /** Método para obtener los barrios de una ciudad
   * @param cityID id de la ciudad de cual obtener los barrios
   * @return array con los barrios de la ciudad, si no encuentra o se produce un error devuelve un array vacio
   */
  async getNeighborhoods(cityID: string) : Promise<Barrio[]>{
    const data = await this.mongoSrv.get(`/zonas/neighborhoods/${cityID}`, [])
    if(data ) {
      return data;
    } else {
      return [];
    }
    
  }
  
  /** Método para obtener las zonas de una ciudad
   * @cityID id de la ciudad de la cual obtener las zonas
   * @return array de zonas de la ciudad, si no encuentra o se produce un error devuelve un array vacío
   */
  async getZones(cityID: string) : Promise<Zona[]>{
    const data = await this.mongoSrv.get(`/zonas/zones/${cityID}`, [])
    if(data ) {
      return data;
    } else {
      return [];
    }
  }

  /** Método para obtener clientes asociados a un RUC */
  async getClientsByRuc(ruc:string) {

    const nClients: Cliente[] = [];
    const params : Param[] = [{key: 'Z_RUC_KUT',value: ruc}];
    const nclientes = await this.mongoSrv.get('/clientes/',params) 
    if(nclientes){
      return nclientes;
    }
    return undefined
  }

  /** Método para obtener datus de un cliente por su ID */
  async getClientByID(accountID:string) {
    const params : Param[] = [{key: 'AccountID',value: accountID}];
    const nclientes = await this.mongoSrv.get('/clientes/',params) 
    if(nclientes){
      return nclientes;
    }
    return undefined
  }

  /** Método para actualizar datos de una dirección
   * @param addressOID OID de la dirección
   * @param data datos de la dirección
   * @returns TRUE si se actualizó correctamente, sino FALSE
   */
  async updateClientAddress(addressOID:any,data:any)  {
    return await this.mongoSrv.update(`/clientes/direccion`,addressOID,data);
  }

  /** Método para obtener la dirección de un cliente
   * @param clientObjectId identificador del cliente
   */
  async getClientAddress(clientObjectId:string) : Promise<any> {
    const nclientes = await this.mongoSrv.getAll(`/clientes/direccion/${clientObjectId}`) 
    if(nclientes){
      return nclientes;
    }
    return undefined
  }
  
  /** Método para obtener las sucursales */
  getBranches(): Observable<Sucursal[]> {
    return this.branches.asObservable();
  }

  /** Método para obtener las entidades */
  getEntities(): Observable<Entidad[]> {
    return this.entities.asObservable();
  }

  /** Método para obtener las ATMS */
  getATMS(): Observable<ATM[]> {
    return this.atms.asObservable();
  }

  /** Método para obtener las empresas transportadoras */
  getATMSProviders(): Observable<Basico[]> {
    return this.atmProviders.asObservable();
  }

  /** Método para obtener las empresas transportadoras */
  getGenders(): Observable<Basico[]> {
    return this.genders.asObservable();
  }

  /** Método para obtener los métodos de pago */
  getPaymentMethods(): Observable<Basico[]> {
    return this.paymentMethods.asObservable();
  }

  getFacturationAccountTypes(): Observable<CuentaFacturacion[]> {
    return this.facturationAccountTypes.asObservable();
  }

  /** Método para obtener los grupos tickets */
  getGruposTickets(): Observable<GrupoTicket[]> {
    return this.gruposTickets.asObservable();
  }
  

  /** Método para obtener los queries */
  getQueriesOdata(): Observable<any[]> {
    return this.queriesOdata.asObservable();
  }
  /** Método para agregar un estado a la lista de estados de oportunidades comerciales 
   * @param id id de la oportunidad comercial a agregar
   * @param description descripción de la oportunidad comercial
  */
  addOpportunityStatus(id: string,description: string): void {
    this.opportunitiesStatuses.value.push({id:id,descripcion:description});
  }

  /** Método para asignar filtros al servicio */
  setFilters(filters: string[]) {
    this.filters=filters;
  }
  
  /** Método para agregar una categoría a la lista de categorías de tickets
   * @param category categoria a agregar
   */
  addCategory(category: CategoriaTicket) {
    if (!this.ticketsTypesCategories.getValue().includes(category)) {
      this.ticketsTypesCategories.getValue().push(category);
    }
  }

  /** Método para agregar un incident a la lista de incidentes de tickets
   * @param incident incidente a agregar
   */
  addIncident(incident: IncidenciaTicket) {
    const i = this.ticketsTypesCategoriesIncidents.value.find(inc => {return inc.OID==incident.OID})
    if (!i) {
      this.ticketsTypesCategoriesIncidents.value.push(incident);
    }
  }

  setFiltering(status: boolean) {
    this.filtering.next(status);
  }
  
  /** Método para obtener los feriados */
  async getFeriados() {
    const data = await this.mongoSrv.get(`/feriados/c4c`, [])
    if(data ) {
      return data;
    } else {
      return null;
    }
  }

  /** Método para obtener template de by Tipo */
  async getTemplateTicketByType(tipo, servicio, incidencia) {
    const params: Param[]=[];
    params.push( {key:'size', value: '-1'} )
    params.push( {key:'filtros', value: JSON.stringify({tipoTicket: tipo, servicio, incidencia})})
    params.push( {key:'orderBy', value: 'fechaCreacion'} )
    params.push( {key:'orderDir', value: 'desc'} )
    
    const data = await this.mongoSrv.get(`/plantilla-ticket/puntual`, params)
    if(data ) {
      return data;
    } else {
      return null;
    }
  }

  /** Método para obtener los mcc */
  getMCCs(): Observable<MCC[]> {
   return this.mccs.asObservable();
  }

  getRequiereConfirmacion(): Observable<boolean> {
    return this.requiereConfirmacion.asObservable();
  }

}
