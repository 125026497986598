<app-loadingSpinner [message]="loadingMessage" [show]="loading"></app-loadingSpinner>

<div class="row px-3">
    <div class="col-12 py-3">
        <app-title [acciones]="acciones" [title]="reportTitle" (OnSelectItem)="selectReport($event)"></app-title>
    </div>
    <div class="container-fluid" *ngIf="reportType == 'TIPO-TICKET'">
        <div class="d-flex my-3">
            <div class="d-flex flex-column">
                <span class="mb-2 table-cell-ticket">Seleccione el tipo de ticket para cargar los reportes</span>
                <div class="d-flex">
                    <ng-select [(ngModel)]="selectedType" class="ng-selec-portal select-m w-100" [clearable]="false"
                        [searchable]="true" placeholder="Tipo de ticket" (change)="updateTickets()">
                        <ng-option *ngFor="let tipo of ticketsTypes" value="{{ tipo.id }}">{{ tipo.descripcion
                            }}</ng-option>
                    </ng-select>
                    <div *ngIf="selectedType" class="ml-2">
                        <div class="d-flex align-items-center" ngbDropdown>
                            <button class="btn-btn btn-primary-portal btn-primary btn-sm dropdown-toggle py-2" type="button" ngbDropdownToggle
                                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Exportar
                            </button>
                            <ul class="dropdown-content text-left px-2" ngbDropdownMenu
                                aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a class="dropdown-item pointer pl-2" (click)="export('pdf',false)">Exportar vista -
                                        PDF</a>
                                </li>
                                <li>
                                    <a class="dropdown-item pointer pl-2" (click)="export('png',false)">Exportar vista -
                                        Imagen</a>
                                </li>
                                <li>
                                    <a class="dropdown-item pointer pl-2" (click)="export('png',true)">Exportar solo
                                        gráfico - Imagen</a>
                                </li>
                                <li *ngIf="statusGroupFilter">
                                    <a class="dropdown-item pointer pl-2" (click)="exportExcel()">Exportar tabla -
                                        Excel</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="exportable" class="d-flex row flex-wrap pt-3" *ngIf="tipoTicketSelected">
            <div class="col-3 mx-auto mb-3" id="pieChart">
                <app-pie-chart [ticketsAbiertos]="ticketAbiertos" [ticketsCerrados]="ticketCerrados"></app-pie-chart>
            </div>
            <div class="col-12">
                <div class="row mb-2">
                    <div class="col-4">
                        <div class="d-flex flex-column p-3 pointer"
                            [ngClass]="statusGroupFilter=='all' ? 'container-tickets' : 'container-tickets-secondary'"
                            (click)="statusGroupFilter='all'">
                            <span class="container-ticket-titulo">Tickets totales</span>
                            <span class="container-ticket-contenido">{{ ticketTotales }}</span>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="d-flex flex-column p-3 pointer"
                            [ngClass]="statusGroupFilter=='open' ? 'container-tickets' : 'container-tickets-secondary'"
                            (click)="statusGroupFilter='open'">
                            <span class="container-ticket-titulo">Tickets abiertos</span>
                            <span class="container-ticket-contenido">{{ ticketAbiertos }}</span>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="d-flex flex-column p-3 pointer"
                            [ngClass]="statusGroupFilter=='closed' ? 'container-tickets' : 'container-tickets-secondary'"
                            (click)="statusGroupFilter='closed'">
                            <span class="container-ticket-titulo">Tickets cerrados</span>
                            <span class="container-ticket-contenido">{{ ticketCerrados }}</span>
                        </div>
                    </div>
                </div>
                <div class="row mx-0" *ngIf="statusGroupFilter" @fade>
                    <table id="tabla" class="table table-style table-font-size">
                        <tr class="table-line align-items-center py-2">
                            <th class="table-cell-ticket ml-3" scope="col">
                                Nro. ticket
                            </th>
                            <th class="table-cell-ticket" scope="col">
                                Nro. boleta
                            </th>
                            <th class="table-cell-ticket table-cell-ticket-lg mx-2" scope="col">
                                Asunto
                            </th>
                            <th class="table-cell-ticket" scope="col">
                                Tipo ticket
                            </th>
                            <th class="table-cell-ticket">
                                Categoria nivel 1
                            </th>
                            <th class="table-cell-ticket">
                                Categoria nivel 2
                            </th>
                            <th class="table-cell-ticket" scope="col">
                                Prioridad
                            </th>
                            <th class="table-cell-ticket" scope="col">
                                Estado
                            </th>
                            <th class="table-cell-ticket" scope="col">
                                Fecha última actualizacion
                            </th>
                            <th class="table-cell-ticket" scope="col">
                                Fecha de Creacion
                            </th>
                            <th class="table-cell-ticket" scope="col">
                                Creado por
                            </th>
                        </tr>
                        <tbody *ngIf="tickets && tickets.length>0">
                            <tr class="py-2 align-items-center mt-2 puntero ticketRow" *ngFor="let ticket of tickets | 
                            TicketFilter: {statusGroup:statusGroupFilter}|
                            TicketSearch: searchText : collectionSizeFiltered |
                            slice:start:end" (click)="goToDetails(ticket.numero)">
                                <td class="table-cell-ticket ml-3">
                                    {{ ticket.numero }}
                                </td>
                                <td class="table-cell-ticket">
                                    {{ ticket.boleta }}
                                </td>
                                <td title="{{ticket.descripcion}}"
                                    class="table-cell-ticket table-cell-ticket-lg d-flex text-truncate">
                                    {{ ticket.descripcion }}
                                </td>
                                <td class="table-cell-ticket">
                                    {{ ticket.tipo?.descripcion }}
                                </td>
                                <td class="table-cell-ticket">
                                    {{ ticket.tipo?.categoria?.descripcion }}
                                </td>
                                <td class="table-cell-ticket">
                                    {{ ticket.tipo?.categoria?.incidencia?.descripcion }}
                                </td>
                                <td class="table-cell-ticket">
                                    <span class="value-data"
                                        [ngClass]="ticket.prioridad.id == '1' ? 'label-inmediata' : 
                                                                        ticket.prioridad.id == '2' ? 'label-urgente' : 
                                                                        ticket.prioridad.id == '3' ? 'label-normal' : 'label-baja'">
                                        {{ ticket.prioridad.descripcion }}
                                    </span>
                                </td>
                                <td class="table-cell-ticket">
                                    {{ ticket.estado.descripcion }}
                                </td>
                                <td class="table-cell-ticket">
                                    {{ ticket.fechaUltimoCambio | date:'dd/MM/YYYY HH:mm:ss'}}
                                </td>
                                <td class="table-cell-ticket">
                                    {{ ticket.fechaDeCreacion | date:'dd/MM/YYYY HH:mm:ss'}}
                                </td>
                                <td class="table-cell-ticket">
                                    {{ ticket.creadoPor }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody align="center" *ngIf="(!tickets || tickets.length == 0) && !loadingTickets">
                            <td colspan="20" class="span-empty">No existen tickets creados</td>
                        </tbody>
                        <tbody align="center" *ngIf="loadingTickets">
                            <td colspan="20" class="span-empty">
                                <fa-icon [icon]="faSpinner" animation="spin"></fa-icon>
                            </td>
                        </tbody>
                    </table>
                </div>
                <div #paginado class="d-flex justify-content-end py-3" *ngIf="statusGroupFilter" @fade>
                    <div class="d-flex align-items-center mr-auto w-75">
                        <fa-icon class="ml-2 table-search align-items-center" [icon]="faSearch"></fa-icon>
                        <input class="main-content-input-ticket filtro-ticket" [(ngModel)]="searchText" type="text"
                            placeholder="Búsqueda rápida">
                    </div>
                    <label class="label-entity pt-2 pr-3">Cantidad de tickets: <b>{{collectionSize}}</b></label>
                    <ngb-pagination *ngIf="collectionSize > 0" [(page)]="page" [maxSize]="3" [pageSize]="pageSize"
                        [collectionSize]="collectionSize" [boundaryLinks]="false" (pageChange)="updatePagination()">
                    </ngb-pagination>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid" *ngIf="reportType == 'SLAS'">

        <bpe-pre-content *ngIf="loading"></bpe-pre-content>

        <div class="d-flex my-3" *ngIf="!loading">
            <div class="d-flex flex-column">
                <span class="mb-2 table-cell-ticket">Seleccione el tipo de ticket para cargar los reportes</span>
                <div class="d-flex">
                    <ng-select [(ngModel)]="selectedType" class="ng-selec-portal select-m w-100" [clearable]="false"
                        [searchable]="true" placeholder="Tipo de ticket">
                        <ng-option *ngFor="let tipo of ticketsTypes" value="{{ tipo.id }}">{{ tipo.descripcion }}</ng-option>
                    </ng-select>
                    <div class="ml-2">
                        <div class="d-flex align-items-center">
                            <button class="btn btn-primary-portal btn-primary btn-sm dropdown-toggle py-2" 
                                (click)="obtenerReporteSLA()">
                                Mostrar reporte
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br />
        <div *ngFor="let item of ReporteSLADataArr">
            <h5>{{ item.ProcessingTypeCodeText }}</h5>
            <br />
            <div class="dashboard row">

                <div class="dashboard-item col-md-3 col-sm-12">
                    <fa-icon [icon]="faInbox" class="fa-lg circle-icon text-success"></fa-icon>
                    <div class="dashboard-label">Tickets recibidos</div>
                    <div class="text-success"> {{ getRecibidos(item) }}</div>
                </div>

                <div class="dashboard-item col-md-3 col-sm-12">
                    <fa-icon [icon]="faTimesCircle" class="fa-lg circle-icon text-danger"></fa-icon>
                    <div class="dashboard-label">Tickets cancelados</div>
                    <div class="text-danger">{{ item.cantidadTicketCancelados }}</div>
                </div>

                <div class="dashboard-item col-md-3 col-sm-12">
                    <fa-icon [icon]="faClipboardCheck" class="fa-lg circle-icon text-success"></fa-icon>
                    <div class="dashboard-label">Tickets resueltos</div>
                    <div class="text-success">{{ item.cantidadTicketResueltos }}</div>
                </div>

                <div class="dashboard-item col-md-3 col-sm-12">
                    <fa-icon [icon]="faExclamationTriangle" class="fa-lg circle-icon text-danger"></fa-icon>
                    <div class="dashboard-label">Tickets Pendientes</div>
                    <div class="text-danger"> {{ item.cantidadTicketPendientes }}</div>
                </div>               

                <div class="dashboard-item col-md-3 col-sm-12">
                    <fa-icon [icon]="faHourglassStart" class="fa-lg circle-icon text-warning"></fa-icon>
                    <div class="dashboard-label">Promedio de primera respuesta</div>
                    <div class="text-warning">{{ item.promedioPrimeraRespuesta }}</div>
                </div>

                <div class="dashboard-item col-md-3 col-sm-12">
                    <fa-icon [icon]="faExclamationTriangle" class="fa-lg circle-icon text-warning"></fa-icon>
                    <div class="dashboard-label">Tickets fuera de plazo de primera respuesta</div>
                    <div class="text-warning">{{ item.cantidadTicketPrimeraRespuestaFueraDePlazo }}</div>
                </div>

                
                <div class="dashboard-item col-md-3 col-sm-12">
                    <fa-icon [icon]="faCalendarCheck" class="fa-lg circle-icon text-success"></fa-icon>
                    <div class="dashboard-label">Promedio de resolución</div>
                    <div class="text-success">{{ item.promedioResoulicion }}</div>
                </div>                

                <div class="dashboard-item col-md-3 col-sm-12">
                    <fa-icon [icon]="faClipboardList" class="fa-lg circle-icon text-warning"></fa-icon>
                    <div class="dashboard-label">Tickets resueltos fuera de plazo</div>
                    <div class="text-warning">{{ item.cantidadTicketResueltosFueraDePlazo }}</div>
                </div>

            </div>
            <hr />
        </div>
    </div>

    <div class="container-fluid" *ngIf="reportType == 'EVALUACION'">
        <app-graficos-evaluacion></app-graficos-evaluacion>
    </div>
</div>