import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UsersService } from './users.service';
import { MongoDBService } from './mongoDB.service';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(
    private userSrv: UsersService,
    private mongoSrv: MongoDBService
  ) {}

  /** Método para guardar acciones realizadas desde el portal
   * @param action datos de la acción realizada
   */
  logAction(action: {modulo: string, accion: string, detalle?: string, recursoId?: string, coleccion?: string}) {
    const user = this.userSrv.getActualUser();
    const payload : any = {
      aplicacion: environment.app_name,
      modulo: action.modulo,
      accion: action.accion,
      'detalle': action.detalle ? action.detalle : '{}',
      usuario: user.email,
      entidadId: user.entidad?.id,
      entidad: user.entidad?.nombre,
      createdDate: new Date().toISOString()
    }
    if (action.detalle) {
      payload.detalle = action.detalle
    }
    if (action.recursoId) {
      payload.recursoId = action.recursoId
    }
    if (action.coleccion) {
      payload.coleccion = action.coleccion
    }
    this.mongoSrv.create('/auditoria/v2/',payload);
  }

}
