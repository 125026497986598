import { Injectable } from '@angular/core';
import { UsersService } from './users.service';
import { MongoDBService } from './mongoDB.service';
import { Param } from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class EvaluacionService {

  constructor(
    private userSrv: UsersService,
    private mongoSrv: MongoDBService
  ) {}

  /** Método para inicializar las encuestas a un usuario del portal
   * @param detalle
   */
  async generarEncuesta(detalle: { ticket: string }) {
    const user = this.userSrv.getActualUser();
    const payload:any = {
      ticket: detalle.ticket,
      usuario: user.id,
      entidad: user.entidad?.id,
      fechaCreacion: new Date().toISOString()
    }
    
    const data = await this.mongoSrv.create('/evaluaciones',payload);
    if(data.posted) {
      return data.body.data;
    }
    return null;
  }

  /** Método para obtener la encuesta de un ticket
   * @param detalle
   */
  async obtenerEncuesta(detalle: { ticket: string }) {
    const user = this.userSrv.getActualUser();
    const payload:any = {
      ticket: detalle.ticket,
    }

    const params: Param[]=[];
    params.push( {key:'filtros', value: JSON.stringify(payload)} )
    const data = await this.mongoSrv.get('/evaluaciones',params);
    if(data.datos && data.datos.length > 0) {
      return data.datos[0];
    }
    return null;
  }

}
