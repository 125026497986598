import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketService } from 'src/app/services/ticket.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { faSpinner, faTimes, faCheck, faPlus, faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { formNuevoParticipante } from './nuevo-participante.form';
import { validarParticipanteForm } from './validar-participante.form';
import { Mensajes } from 'src/app/constants/mensajes.const';
import { UsersService } from 'src/app/services/users.service';
import { ComentariosService } from 'src/app/services/comentarios.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Constantes } from 'src/app/constants/constantes.const';
import { CrmService } from 'src/app/services/crm.service';
import { Basico, EstadoTicket, GrupoTicket, Param, TipoTicket, Transaccion, Usuario } from 'src/app/interfaces/interfaces';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { AuditService } from 'src/app/services/audit.service';
import { EvaluacionService } from 'src/app/services/evaluacion.service';
import { MongoDBService } from 'src/app/services/mongoDB.service';
import { FormEncuestaComponent } from 'src/app/shared/form-encuesta/form-encuesta.component';
import { EncryptionService } from 'src/app/services/encryption.service';

@Component({
  selector: "app-detalle-ticket",
  templateUrl: "./detalle-ticket.component.html",
  styleUrls: ["./detalle-ticket.component.scss"],
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
      transition("* => void", [animate(200, style({ opacity: 0 }))]),
    ]),
  ],
})
export class DetalleTicketComponent implements OnInit {

  @Input() ticketNumber;

  //forms
  formNuevoParticipante: FormGroup;
  validarParticipanteForm: FormGroup;

  emailParticipante;
  emailParticipantePermanent;

  participantesList: any[] = [];
  participantestickets: any[] = [];
  participanteActual;
  usuariosList: Usuario[] = [];
  recipientsEmail: any = [];

  cambiarEstado?: boolean;
  loading = false;
  loadingMessage = new BehaviorSubject("Obteniendo detalles de ticket");
  loadingParticipantes = false;
  loadingModal = false;
  isDescriptionEmpty = true;
  loadingSla = false;
  showSLA = true;
  anulacionEditable = false;
  ticket;
  ticketAsociado: any = {};
  canUploadAttached = false;

  actualizando = false;

  idTicket?: number;

  estadoTicket = "";

  idCliente;

  ticketStatus;

  estados: EstadoTicket[] = [];

  aceptarAnulacion = false;

  Constantes = Constantes;

  idEncuesta;

  showInstalacion = false;
  ocId = "";
  creditCard = ""

  transaction: Transaccion | undefined;
  @ViewChild("transactionModal", { static: true }) transactionModal;
  @ViewChild("nuevoParticipante") nuevoParticipanteModal;
  @ViewChild("anular") anularModal;
  @ViewChild('cancelar') cancelarTicketModal;

  //icons
  faSpinner = faSpinner;
  faTimes = faTimes;
  faCheck = faCheck;
  faPlus = faPlus;

  //codigos anulacion
  aprobarAnulacion = "YF";
  rechazarAnulacion = "YE";
  aprobarAnulacion2 = "Z7";
  rechazarAnulacion2 = "Z4";
  mostrarModalAnulacion: boolean | undefined;
  mostrarBotones = false;
  editarMedioDePago = false;
  loadingTicketsHijos = false;
  ticketsHijosId: any = [];
  ticketHijo: any = [];
  faAngleDoubleRight = faAngleDoubleRight;
  faAngleDoubleLeft = faAngleDoubleLeft;
  descryptedCreditCard = false

  //pagination tabla de participantes asociados al ticket
  page = 1;
  collectionSize = 0;
  pageSize = 5;

  //paginacion tabla participantes existentes
  collectionSizeParticipantes = 0;
  pageParticipantes = 1;
  pageSizeParticipantes = 5;

  newComment =''
  refreshCommentAfterCancel = true;
  cantidadComentarios = 0;
  cantidadCambiosEstado = 0;

  estadosHabilitadosReabrir = ["Cancelado", "Resuelto", "Rechazado"];

  prioridadesTicket: any[] = [
    {
      value: 1,
      text: "Inmediata",
    },
    {
      value: 2,
      text: "Urgente",
    },
    {
      value: 3,
      text: "Normal",
    },
    {
      value: 7,
      text: "Baja",
    },
  ];

  feriadosPY: any = [];

  SLA: any = {};

  progressWidth = 0;
  progressWidth2 = 0;
  diasSlaObjetivo: any;
  diasSlaReal: any;
  subscriptions : Subscription[] = [];
  paymentMethods : Basico[] = [];

  subs: Subscription[] = [];
  grupoTickets: GrupoTicket[] = []
  tiposTickets: TipoTicket[] = []

  tipoTicket?: TipoTicket;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ticketsSrv: TicketService,
    private toastr: ToastrService,
    private title: Title,
    private modalSrv: NgbModal,
    private usersSrv: UsersService,
    private comentsSrv: ComentariosService,
    private notificationSrv: NotificationService,
    private crmSrv: CrmService,
    private dataSrv: DataService,
    private auditSrv: AuditService,
    private encuestaService: EvaluacionService,
    private mongoDBSrv: MongoDBService,
    private encryptService: EncryptionService
  ) {

    // obtiene el estado de carga de tickets
    this.subs.push(this.dataSrv.getPreLoadStatus().subscribe(cargado => {
      if (cargado) {
        this.loadData()
      }
    }));

    this.formNuevoParticipante = formNuevoParticipante;
    this.validarParticipanteForm = validarParticipanteForm;

    this.validarParticipanteForm.reset();
    this.formNuevoParticipante.reset();
  }

  ngOnInit(): void {
    this.recipientsEmail = [];
    if(this.ticketNumber){
      this.idTicket = this.ticketNumber
    } else {
      this.idTicket = +this.route.snapshot.paramMap.get("id")!;
    }
    this.title.setTitle(
      `${this.route.snapshot.data.titulo} - ${environment.app_name}`
    );
    this.getFeriados();
    this.getMetodosDePago();
    this.getTicket();
    this.getGruposTicket();
  }

  async loadData() {
    
    this.dataSrv.setCargando(true);
    await Promise.all([
      this.dataSrv.loadPaymentMethods(),
      this.dataSrv.loadTicketsTypes()
    ]);
    this.dataSrv.setCargando(false);
    await this.subs.push(this.dataSrv.getTicketsTypes().subscribe(ticket => {
      this.tiposTickets = ticket;
    }));
  }

  ngOnDestroy() {
    this.subs.forEach((subscription) => subscription.unsubscribe());
  }

  /** Método para obtener los feriados */
  async getFeriados() {
    const feriados = await this.dataSrv.getFeriados();
    if (feriados) {
      this.feriadosPY = feriados;
    } else {
      this.toastr.error("No se pudo obtener los feriados","",{positionClass: "toast-top-center", progressBar: false});
    }
  }

  getEstadosTicketsByTipo(tipo: string) {
    const estados =this.tiposTickets.find(tipoTicket => tipoTicket.id.toString() == tipo)?.estadosPosibles;
    if(estados){
      return estados
    }
    return []
  }
  
  /** Método para obtener lo metodos de pago */
  async getMetodosDePago() {
    this.subscriptions.push(this.dataSrv.getPaymentMethods().subscribe(methods => {
      this.paymentMethods = methods;
    }));
  }

  /** Método para obtener datos del ticket **/
  async getTicket() {
    this.loading = true;
    this.loadingMessage.next("Obteniendo datos del ticket")
    const ticket = await this.ticketsSrv.getTicketDetailsV2(this.idTicket!);
    if (ticket ) {
      await this.validTicketPermission(ticket);
      this.obtenerEncuesta();
      this.auditSrv.logAction({modulo:'Tickets',accion:'Ver detalle de ticket',detalle: `ID: ${this.idTicket}`})
      this.ticket = ticket;

      this.tipoTicket = this.dataSrv.getTicketType(
        this.ticket.zProcessingTypeCode, this.ticket.zServiceIssueCategoryID, this.ticket.zIncidentServiceIssueCategoryID)

      if (this.ticket.Z_ORIGEN_KUT != "18") {
        this.mostrarModalAnulacion = ['ZD','Y6'].includes(this.ticket.ServiceRequestUserLifeCycleStatusCode);
        this.mostrarBotones = this.ticket.ProcessingTypeCode == 'Z4' && this.ticket.ServiceRequestUserLifeCycleStatusCode =='YD' ||
        this.ticket.ProcessingTypeCode == 'ZAE' && this.mostrarModalAnulacion;
        if(this.ticketTieneDatosPago()){
          this.editarMedioDePago = false
        } else {
          if(this.tipoTicket){
            const rolEntidad = this.tipoTicket?.categoria?.incidencia?.descripcion == undefined ? 
                              '' : this.tipoTicket?.categoria?.incidencia?.descripcion;
            this.editarMedioDePago = this.tipoTicket.id == 'Z4' || (this.tipoTicket.id == 'ZAE' && Constantes.ROL_ENTIDADES_REQUIEREN_MEDIO_DE_PAGO.includes(rolEntidad))
          }
        }
        await this.getTicketHijos(ticket); 
        await this.getTicketDescription(ticket);
        await this.setTicketSLA(); //carga sla
        await this.getParticipantes(ticket);
        await this.getTicketAsociados(ticket);
        
        // comprueba el tipo de ticket
        switch (this.ticket.ProcessingTypeCode) {
          case "ZAE": //ANULACION ENTIDAD
            this.anulacionEditable = !['1','Y3'].includes(this.ticket.ServiceRequestUserLifeCycleStatusCode);
            this.cambiarEstado = false;
            break;
          case "Z4": //ANULACION
          case "Z5": //SOPORTE SAC
            this.creditCard = this.ticket.Z_Tarjeta_Enmascarada_KUT;
            this.descryptedCreditCard = false;
            break;
          case "Z6": //PEDIDOS AL CAC
          case "Z9": //EMBOZADO
          case "ZGE": //GESTION INTERNA
          case "Z8": // CONSULTA ENTIDAD
            this.cambiarEstado = false;
            break;
          default:
            this.cambiarEstado = !Constantes.ESTADOS_CERRADOS_TICKET.includes(
              this.ticket.ServiceRequestUserLifeCycleStatusCode
            );
            this.estados = this.getEstadosTicketsByTipo(this.ticket.ProcessingTypeCode);
            if (this.ticket.ProcessingTypeCode == "Z1") {
              
              this.getDetallInstalacion();
            }
            if (this.ticket.ProcessingTypeCode == "ZVE") {
              this.estados = this.estados.filter(
                (estado) =>
                  estado.descripcion == "Resuelto" ||
                  estado.descripcion == "Cancelado" ||
                  estado.descripcion ==
                    this.ticket.ServiceRequestUserLifeCycleStatusCodeText
              );
            } else {
              this.estados = this.estados.filter(
                (estado) =>
                  estado.descripcion == "Cancelado" ||
                  estado.descripcion ==
                    this.ticket.ServiceRequestUserLifeCycleStatusCodeText
              );
            }
            break;
        }
        this.canUploadAttached = !Constantes.ESTADOS_CERRADOS_TICKET.includes(
          this.ticket.ServiceRequestUserLifeCycleStatusCode
        );
        this.idCliente = ticket.BuyerPartyID;
        this.ticket.CreationDateTime = new Date(
          +this.ticket.CreationDateTime.replace(/\/Date\((\d+)\)\//, "$1")
        ).toLocaleDateString();
        this.ticket.RequestFinisheddatetimeContent =
          this.ticket.RequestFinisheddatetimeContent.length == 0
            ? "-"
            : moment(this.ticket.RequestFinisheddatetimeContent).format(
                "DD/MM/YYYY HH:mm"
              );
        if (this.ticket.Z_FECHADEBOLETA_KUT != null) {
          this.ticket.Z_FECHADEBOLETA_KUT = new Date(
            +this.ticket.Z_FECHADEBOLETA_KUT.replace(
              /\/Date\((\d+)\)\//,
              "$1"
            )
          ).toLocaleDateString();
        }
        if (this.ticket.Z_FECHATRANSFERENCIA_KUT) {
          this.ticket.Z_FECHATRANSFERENCIA_KUT = moment.utc(this.ticket.Z_FECHATRANSFERENCIA_KUT).format("YYYY-MM-DD");
        }
        this.ticketStatus = this.ticket.ServiceRequestUserLifeCycleStatusCodeText;
        this.loading=false;
        
      } else {
        this.loading=false;
        this.toastr.error("No tiene permisos para visualizar este ticket","",{ positionClass: "toast-top-center", progressBar: false });
        this.loading=false;
        this.router.navigate(["tickets", "list"]);
      }
    } else {
      this.loading=false;
      this.toastr.error("No se pudo obtener datos del ticket", "", { positionClass: "toast-top-center", progressBar: false });
    }
  }

  async getDetallInstalacion(){
    const instalacion = await this.mongoDBSrv.getAll(`/tickets/oc/${this.idTicket}`);
    if(instalacion) {
      this.showInstalacion=true;
      this.ocId= instalacion.ocId;
    }
  }

  addCurrentUserToRecipients() {
    this.recipientsEmail.push(this.usersSrv.getActualUser().email);
  }

  /** Método para obtener los participantes del ticket */
  async getParticipantes(ticket?) {
    this.recipientsEmail = [];
    this.addCurrentUserToRecipients();
    this.loadingParticipantes = true;
    let participants;
    if(ticket) {
      participants = ticket.ServiceRequestParty;
    } else {
      participants = await this.ticketsSrv.getParticipants(this.ticket.ObjectID);
    }
    //Limpio el array en cada get, sino pushea sobre lo que ya esta agregado
    this.participantestickets = [];
    this.collectionSizeParticipantes = 0;
    if (participants) {
      this.auditSrv.logAction({modulo:'Tickets',accion:'Obtener participantes',detalle: `ID: ${this.idTicket}`})
      if (participants.length>0) {
        //Itero para ver el valor del campo Z_CONTACTO_PORTAL_ENT_KUT
        participants.forEach(async (participante: any) => {
          if (['Z5','213','214','40'].includes(participante.RoleCode)) {
            const z = this.participantestickets.filter((element) => element.Email == participante.Email);
            if (z.length == 0) {
              if (participante.RoleCode!='40') {
                this.participantestickets.push(participante);
              }
              //se agrega la lista de participantes a la lista de mails
              this.recipientsEmail.push(participante.Email);
            }
          }
          //Si termino de iterar dejo de cargar y actualizo el paginado
          this.collectionSizeParticipantes = this.participantestickets.length;
        });
      }
    }
    this.loadingParticipantes = false;
  }

  formatSapDate(date: string) {
    return moment(date).format("DD/MM/YYYY HH:mm");
  }

  /** Método para obtener la descripción del ticket actual */
  async getTicketDescription(ticket) {
    this.isDescriptionEmpty=true;
    this.loadingMessage.next("Buscando descripción");
    const description = await this.ticketsSrv.getTicketDescriptionV2(ticket);
    if (description) {
      this.ticket.Description = description.FormattedText;
      this.isDescriptionEmpty = this.ticket.Description == "<p>&nbsp;</p>" ? true : false;
    } 
  }

  async setTicketSLA() {
    this.loadingMessage.next("Buscando SLA");
    if (Constantes.GRUPOS_SIN_SLA.includes(this.ticket.ServiceSupportTeamPartyID)) {
      this.showSLA = false;
    } else {
      const req = { IdTicket: this.ticket.ID };
      const posted = await this.ticketsSrv.postTicketSLA(req);
      if (posted) {
        this.getTicketSLA();
      }
    }
  }

  formatDateString(fecha: any) {
    let formatDate = moment(fecha).locale("es").format("LLLL");
    formatDate = formatDate[0].toUpperCase() + formatDate.slice(1);
    return formatDate;
  }

  /** Método para obtener el SLA del ticket actual */
  async getTicketSLA() {
    this.loadingSla = true;
    const sla = await this.ticketsSrv.getTicketSLA(this.ticket.ID);
    if (sla) {
      this.SLA=sla;
      const diaLaboral = 8;
      let [horaObjetivo, minutoObjetivo] = this.SLA.SLA1Objetivo.split(":");
      horaObjetivo = horaObjetivo.length == 1 ? 
        (horaObjetivo = "0" + horaObjetivo) : 
        horaObjetivo;
      minutoObjetivo = minutoObjetivo.length == 1 ? 
        (minutoObjetivo = "0" + minutoObjetivo) : 
        minutoObjetivo;
      let [horaReal, minutoReal] = this.SLA.SLA1Real.split(":");
      horaReal = horaReal.length == 1 ? (horaReal = "0" + horaReal) : horaReal;
      minutoReal = minutoReal.length == 1 ? (minutoReal = "0" + minutoReal) : minutoReal;
      let [horaObjetivo2, minutoObjetivo2] = this.SLA.SLA2Objetivo.split(":");
      horaObjetivo2 = horaObjetivo2.length == 1 ? (horaObjetivo2 = "0" + horaObjetivo2) : horaObjetivo2;
      minutoObjetivo2 = minutoObjetivo2.length == 1 ? (minutoObjetivo2 = "0" + minutoObjetivo2) : minutoObjetivo2;
      let [horaReal2, minutoReal2] = this.SLA.SLA2Real.split(":");
      horaReal2 = horaReal2.length == 1 ? (horaReal2 = "0" + horaReal2) : horaReal2;
      minutoReal2 = minutoReal2.length == 1 ? (minutoReal2 = "0" + minutoReal2) : minutoReal2;
      const formattedSLA1Objetivo = `${horaObjetivo}:${minutoObjetivo}`;
      this.SLA.SLA1Objetivo = formattedSLA1Objetivo;
      const calculoHoraReal = parseInt(horaReal) + parseFloat((minutoReal / 60).toFixed(2));
      const calculoHoraObjetivo = parseInt(horaObjetivo) + parseFloat((minutoObjetivo / 60).toFixed(2));
      const calculoHoraReal2 = parseInt(horaReal2) + parseFloat((minutoReal2 / 60).toFixed(2));
      this.progressWidth = (calculoHoraReal * 100) / calculoHoraObjetivo < 20 && (calculoHoraReal * 100) / calculoHoraObjetivo > 0 ? 
        25 : (calculoHoraReal * 100) / calculoHoraObjetivo;
      this.progressWidth2 = 
        calculoHoraReal2 >= 8 ? 
        ((this.diasSlaReal = Math.round(parseInt(this.SLA.SLA2Real) / diaLaboral)),(calculoHoraReal2 * 100) / parseInt(this.SLA.SLA2Objetivo)) : 
        calculoHoraReal2 > 0 && calculoHoraReal2 < 8 ? 
        ((this.diasSlaReal = 1), 20) : 0;
      this.SLA.SLA1Real = `${horaReal}:${minutoReal}`;
      this.diasSlaObjetivo = Math.round(parseInt(this.SLA.SLA2Objetivo) / diaLaboral);
    } 
    this.loadingSla = false;
  }

  /** Método para cambiar el estado del ticket actual
   * @param newStatusCode código del nuevo estado a asignar
   * @param updateAfterChangue usar TRUE para actualizar el ticket una vez que haya cambiado el estado
   * @param showToast usar TRUE para mostrar el toast de cambio de estado
   * @returns TRUE cuando el cambio se realiza correctamente, sino FALSE
   */
  async changeStatus(
    newStatusCode: any,
    updateAfterChangue: boolean,
    showToast: boolean
  ) {
    const oldStatusCode = this.ticket.ServiceRequestUserLifeCycleStatusCode;
    if (newStatusCode == oldStatusCode) {
      if (updateAfterChangue) {
        this.getTicket();
      }
      return true;
    } else {
     
      const oldStatusText = this.ticket.ServiceRequestUserLifeCycleStatusCodeText;
      const statusesFromTicket = this.getEstadosTicketsByTipo(this.ticket.ProcessingTypeCode);
      const newStatus = statusesFromTicket.find((status: any) => {
        return status.id == newStatusCode;
      });
      
      if(newStatusCode == 'Y3') {
        const cancelarModalRef = await this.modalSrv.open(this.cancelarTicketModal, { size: 'md', centered: true });
        let stopCancel = false;
        stopCancel = await cancelarModalRef.result.then(async (result) => {
          await this.cancelarTicket()
          
          return false;
        }, (reason) => {
          return true;
        });
        if(stopCancel) return false;
      }
      

      if (showToast) {
        this.loading = true;
        this.loadingMessage.next("Cambiando estado de ticket");
      }
      
      const payload = {"ServiceRequestUserLifeCycleStatusCode": newStatusCode};
      const res = await this.ticketsSrv.updateTicket(this.ticket.ObjectID,payload);
      if (res.updated) {
        this.auditSrv.logAction({modulo:'Tickets',accion:'Cambiar estado',detalle: `ID: ${this.idTicket}, Estado anterior: ${oldStatusCode}, Estado asignado: ${newStatusCode}`})
        const nComentary = {
          ServiceRequestID: this.ticket.ID,
          TypeCode: "10011",
          FormattedText:`${ this.usersSrv.getActualUser().nombreCompleto }:\nUsuario: ${ this.usersSrv.getActualUser().email }\nEstado anterior: ${ oldStatusText }\nEstado actual: ${ newStatus?.descripcion }\n`,
          ParentObjectID: this.ticket.ObjectID
        };
        
        const res = await this.comentsSrv.nuevoComentarioTK(nComentary);
        const mail={
          "recipients" : this.recipientsEmail,
          "previousStatus": oldStatusText,
          "newStatus": newStatus?.descripcion,
          "user": this.usersSrv.getActualUser().email,
          "userName":  this.usersSrv.getActualUser().nombreCompleto,
          "ticketId": this.ticket.ID,
          "ticketType": this.ticket.ProcessingTypeCodeText,
          "idTicketType": this.ticket.ProcessingTypeCode,
          "subject": this.ticket.Name,
          "entity": ['ZVE'].includes(this.ticket.ProcessingTypeCode) ?
              [this.usersSrv.getActualUser().entidad?.codigoEntidad, this.ticket.Z_CabEntidadDueadelATM_KUT ]: 
              this.usersSrv.getActualUser().entidad?.codigoEntidad

        }
        this.notificationSrv.sendMail(mail, 'status_change');
        if (updateAfterChangue) {
          this.getTicket();
        }
        this.loading=false;
        return true;
      } else {
        if (showToast) {
          this.toastr.error(Mensajes.ERROR_ACTUALIZAR_ESTADO,'',{positionClass:'toast-top-center', progressBar:false});
        } 
        this.loading=false;
        return false;
      }
    }
  }

  /** Método para aceptar o rechazar una anulación 
   * @param codigoAnulacion código de estado a asignar al ticket
  */
  async anulacion(codigoAnulacion: any) {
    this.modalSrv.dismissAll();
    this.loading = true;
    this.loadingMessage.next("Guardando datos");
    await this.guardarDatos();
    this.loadingMessage.next("Procesando anulación");
    const statusChanged = await this.changeStatus(codigoAnulacion, false, false);
    if (statusChanged) {
      this.loading = false;
      this.getTicket();
      const message = ["YF", "Z7"].includes(codigoAnulacion)
        ? Mensajes.ANULACION_APROBADA
        : Mensajes.ANULACION_RECHAZADA;
      this.toastr.success(message, "", {
        positionClass: "toast-top-center",
        progressBar: true,
      });
    } else {
      this.loading = false;
      this.toastr.error(Mensajes.ERROR_AL_PROCESAR_ANULACION, "", {
        positionClass: "toast-top-center",
        progressBar: true,
      });
    }
  }

  /** Método para obtener los tickets hijos del ticket actual */
  async getTicketHijos(ticket) {
    this.loadingMessage.next("Buscando tickets hijos");
    this.loadingTicketsHijos = true;
    // obtiene los tickets
    const data =ticket.ServiceRequestBusinessTransactionDocumentReference;
    if (data) {
      this.auditSrv.logAction({modulo:'Tickets',accion:'Obtener tickets hijos',detalle: `ID: ${this.idTicket}`})
      data.forEach((ticket: any) => {
        // verifica el tipo y rol del ticket
        if (ticket.TypeCode == 118 && ticket.RoleCode == 2) {
          this.ticketsHijosId.push(ticket.ID);
        }
      });
      // busca si existe algún ticket hijo de reapertura
      this.ticketHijo = data.find((tk) => {return tk.RoleCode == "13";});
    } 
    // informa que se el proceso culminó
    this.loadingTicketsHijos = false;
  }

  formatDate(date: string) {
    if (date != "") {
      date = date.replace("ART", "");
      const formattedDate = new Date(date);
      return moment(formattedDate).locale("es").format("L HH:mm");
    } else {
      return "-";
    }
  }

  async open(content: any, size: "md" | "lg" | "xl") {
    const nombreModal = content._declarationTContainer.localNames[0];
    if (nombreModal=='validacionParticipante') {
      this.loading=true
      this.loadingMessage.next(`Cargando usuarios de la entidad`)
      const users = await this.usersSrv.getUsers({onlyActive:true,ignoreActual: true});
      this.loading=false
      this.loadingModal = true;
      if (users) {
        this.usuariosList = users;
      } else {
        this.loadingModal = false;
        this.toastr.error(Mensajes.ERROR_AL_RECUPERAR_USUARIOS, "", {
          positionClass: "toast-top-center",
          progressBar: false,
        });
        this.modalSrv.dismissAll();
      }
      this.loadingModal = false;
      this.emailParticipante = "";
      this.validarParticipanteForm.reset();
      this.validarParticipanteForm.get("email")?.patchValue("");
      this.formNuevoParticipante.reset();
      this.formNuevoParticipante.get("email")?.patchValue(this.emailParticipantePermanent);
    }
    this.modalSrv.open(content, { size: size, centered: true, backdropClass: 'modal-backdrop' });
  }

  async crearNuevoParticipante() {
    this.loading = true;
    this.loadingMessage.next("Creando participante");
    if (this.formNuevoParticipante.invalid) {
      this.loading = false;
      this.validateAllFields(this.formNuevoParticipante);
      this.open(this.nuevoParticipanteModal,"xl")
      return;
    } else {
      const req = {
        FirstName: this.formNuevoParticipante.value.nombre,
        LastName: this.formNuevoParticipante.value.apellido,
        Phone: "",
        Email: this.formNuevoParticipante.value.email,
        Z_CONTACTO_PORTAL_ENT_KUT: "TRUE",
      };
      this.modalSrv.dismissAll();
      const nPartner = await this.ticketsSrv.createPartner(req);
      if (nPartner) {
        this.loading = false;
        this.selectParticipante(nPartner);
        this.auditSrv.logAction({modulo:'Tickets',accion:'Crear participante',detalle: `Email: ${nPartner.Email}`})
      } else {
        this.loading = false;
        this.toastr.error(Mensajes.ERROR_AL_CREAR_PARTICIPANTE, "", {
          positionClass: "toast-top-center",
          progressBar: false,
        });
      }
    }
  }

  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  async contactEmailValidate(participantesAsociados: any) {
    if (this.validarParticipanteForm.get("email")?.valid) {
      this.emailParticipantePermanent = this.emailParticipante;
      this.loading = true;
      this.loadingMessage.next("Validando participantes");
      this.participantesList = [];
      const participants = await this.ticketsSrv.getParticipanteByEmail(this.emailParticipante);
      if (participants) {
        this.auditSrv.logAction({modulo:'Tickets',accion:'Obtener participante por email',detalle: `Email: ${this.emailParticipante}`})
        this.participantesList = [...participants];
        this.collectionSize = this.participantesList.length;
        if (this.collectionSize==0) {
          const user = this.usuariosList.find(usuario => usuario.email == this.emailParticipante);
          this.formNuevoParticipante.get("nombre")?.patchValue(user?.nombre);
          this.formNuevoParticipante.get("apellido")?.patchValue(user?.apellido);
          this.formNuevoParticipante.get("email")?.patchValue(user?.email);
          this.loading = false;
          this.crearNuevoParticipante();
        } else if (this.collectionSize == 1) {
          this.loading = false;
          this.selectParticipante(this.participantesList[0]);
        } else {
          this.loading = false;
          this.open(participantesAsociados, "lg");
        }
      } else {
        this.loading = false;
        this.toastr.error(Mensajes.ERROR_AL_VALIDAR_MAIL, "", {
          positionClass: "toast-top-center",
          progressBar: false,
        });
      }
    }
  }

  /** Método para seleccionar un participante
   * @param participante datos del participante
   */
  async selectParticipante(participante: any) {
    this.loading = true;
    this.loadingMessage.next("Asociando participante");
    const encontrado = this.participantestickets.find(
      (element) => element.PartyID === participante.BusinessPartnerID
    );
    if (encontrado != undefined) {
      this.loading = false;
      this.toastr.error(Mensajes.PARTICIPANTE_ASOCIADO, "", {
        positionClass: "toast-top-center",
        progressBar: false,
      });
      return;
    } else {
      const req = {
        RoleCategoryCode: "46",
        RoleCode: "Z5",
        PartyID: participante.BusinessPartnerID,
      };
      const nAssociation = await this.ticketsSrv.associatePartner(this.ticket.ObjectID, req);
      if (nAssociation) {
        this.loading = false;
        this.auditSrv.logAction({modulo:'Tickets',accion:'Asociar participante',detalle: `Ticket: ${this.idTicket}, Participante: ${participante.Email}`})
        this.toastr.success(Mensajes.PARTICIPANTE_ASOCIADO_SUCCESS, "", {
          positionClass: "toast-top-center",
          progressBar: true,
        });
        await this.getParticipantes();
      } else {
        this.loading = false;
        this.toastr.error(Mensajes.ERROR_AL_ASOCIAR_PARTICIPANTE, "", {
          positionClass: "toast-top-center",
          progressBar: false,
        });
      }
    }
  }

  /** Método para eliminar un participante */
  async eliminarParticipante(participante: any) {
    this.loading = true;
    this.loadingMessage.next("Eliminando participante");
    this.collectionSizeParticipantes = 0;
    const deleted = await this.ticketsSrv.deleteParticipante(participante.ObjectID);
    if (deleted) {
      this.loading=false;
      this.auditSrv.logAction({modulo:'Tickets',accion:'Eliminar participante',detalle: `Ticket: ${this.idTicket}, Participante: ${participante.Email}`})
      this.loadingParticipantes = false;
      this.getParticipantes();
      this.toastr.success(Mensajes.PARTICIPANTE_ELIMINADO, "", {
        positionClass: "toast-top-center",
        progressBar: true,
      });
    } else {
      this.loading=false;
      this.collectionSizeParticipantes = this.participantestickets.length;
      this.loadingParticipantes = false;
      this.toastr.error(Mensajes.ERROR_AL_ELIMINAR_PARTICIPANTE, "", {
        positionClass: "toast-top-center",
        progressBar: false,
      });
    }        
  }

  reabrirCheck() {
    let valid = false;
    if (this.ticket && this.ticket.ResolvedOnDateTime) {
      const date = this.ticket.ResolvedOnDateTime.replace("ART", "");
      const fiveDate: Date = new Date(date);
      let i = 1;

      if (date) {
        while (i <= 5) {
          if (fiveDate.getDay() != 0 && fiveDate.getDay() != 6) {
            if (
              this.feriadosPY.includes(fiveDate.toISOString().split("T")[0])
            ) {
              const diaSet = fiveDate.getDate() + 1;
              fiveDate.setDate(diaSet);
            } else if (
              !this.feriadosPY.includes(fiveDate.toISOString().split("T")[0])
            ) {
              const diaSet = fiveDate.getDate() + 1;
              fiveDate.setDate(diaSet);
              i++;
            }
          } else if (fiveDate.getDay() == 0 || fiveDate.getDay() == 6) {
            const diaSet = fiveDate.getDate() + 1;
            fiveDate.setDate(diaSet);
          }
        }
        if (fiveDate.getDay() == 0) {
          const diaSet = fiveDate.getDate() + 1;
          fiveDate.setDate(diaSet);
          this.checkReturn(fiveDate);
        } else if (fiveDate.getDay() == 6) {
          const diaSet = fiveDate.getDate() + 2;
          fiveDate.setDate(diaSet);
          this.checkReturn(fiveDate);
        } else if (
          this.feriadosPY.includes(fiveDate.toISOString().split("T")[0])
        ) {
          const diaSet = fiveDate.getDate() + 1;
          fiveDate.setDate(diaSet);
          this.checkReturn(fiveDate);
        }

        const vencida = fiveDate < new Date();
        const availableStatus = this.estadosHabilitadosReabrir.some(
          (x) => x == this.ticket.ServiceRequestUserLifeCycleStatusCodeText
        );
        valid = !vencida && availableStatus;
      }
      return [valid, moment(fiveDate).locale("es").format("L HH:mm")];
    } else {
      return [valid, ""];
    }
  }

  checkReturn(fiveDate: any) {
    if (fiveDate.getDay() == 0) {
      const diaSet = fiveDate.getDate() + 1;
      fiveDate.setDate(diaSet);
      this.checkReturn(fiveDate);
    } else if (fiveDate.getDay() == 6) {
      const diaSet = fiveDate.getDate() + 2;
      fiveDate.setDate(diaSet);
      this.checkReturn(fiveDate);
    } else if (this.feriadosPY.includes(fiveDate.toISOString().split("T")[0])) {
      const diaSet = fiveDate.getDate() + 1;
      fiveDate.setDate(diaSet);
      this.checkReturn(fiveDate);
    }
  }

  /** Método para reabrir el ticket actual */
  async reOpen() {
    this.loadingMessage.next("Creando ticket de reapertura");
    this.loading = true;

    let sendReq = {};

    const BuyerPartyID =
      this.ticket.tipo == "Z6" || this.ticket.tipo == "Z9"
        ? this.ticket.sucursal
        : this.usersSrv.getActualUser().entidad?.C4C_ID;

    if (this.ticket.tipo == "ZSAT") {
      const reqSoporteAtm = {
        ProcessingTypeCode: this.ticket.ProcessingTypeCode,
        Name: this.ticket.Name,
        BuyerPartyID: BuyerPartyID,
        BuyerMainContactPartyID: this.ticket.BuyerMainContactPartyID,
        ServiceIssueCategoryID: this.ticket.ServiceIssueCategoryID,
        IncidentServiceIssueCategoryID:
          this.ticket.IncidentServiceIssueCategoryID,
        Z_ORIGEN_KUT: "12",
        Z_ENTIDAD_KUT: this.usersSrv.getActualUser().entidad?.id, 
        ServicePriorityCode: this.ticket.ServicePriorityCode,
        Z_DESCRIPCION_KUT: " ",
        Z_CREADOPOR_KUT: this.usersSrv.getActualUser().email,
        zDest_idDepartKUT_KUT: this.ticket.zDest_idDepartKUT_KUT,
        zDest_idCiudad_SDK: this.ticket.zDest_idCiudad_SDK,
        zDest_idBarrio_SDK: this.ticket.zDest_idBarrio_SDK,
        zDest_Direccion_SDK: this.ticket.zDest_Direccion_SDK,
        zOrig_idBarrio_SDK: this.ticket.zOrig_idBarrio_SDK,
        zOrig_Direccion_SDK: this.ticket.zOrig_Direccion_SDK,
        zOrig_idDepartKUT_KUT: this.ticket.zOrig_idDepartKUT_KUT,
        zOrig_idCiudad_SDK: this.ticket.zOrig_idCiudad_SDK,
        z_CabTickMarca_KUT: this.ticket.z_CabTickMarca_KUT,
        z_CabTIckModelo_KUT: this.ticket.z_CabTIckModelo_KUT,
        z_CabTickSuministro_KUT: this.ticket.z_CabTickSuministro_KUT,
        z_CanTickFechaHoraInstalacinATM_KUT:
          this.ticket.z_CanTickFechaHoraInstalacinATM_KUT,
        z_CabTickLongitud_KUT: this.ticket.z_CabTickLongitud_KUT,
        z_CabTickLatitud_KUT: this.ticket.z_CabTickLatitud_KUT,
        z_CabTickFechaBaja_KUT: this.ticket.z_CabTickFechaBaja_KUT, // Consultar
        Z_CabTickNroTermluno_KUT: this.ticket.Z_CabTickNroTermluno_KUT,
      };
      sendReq = reqSoporteAtm;
    } else {
      const req = {
        ProcessingTypeCode: this.ticket.ProcessingTypeCode,
        Name: this.ticket.Name,
        BuyerPartyID: BuyerPartyID,
        BuyerMainContactPartyID: this.ticket.BuyerMainContactPartyID,
        ServiceIssueCategoryID: this.ticket.ServiceIssueCategoryID,
        IncidentServiceIssueCategoryID:
          this.ticket.IncidentServiceIssueCategoryID,
        Z_ORIGEN_KUT: "12",
        Z_ENTIDAD_KUT: this.usersSrv.getActualUser().entidad?.id,
        ServicePriorityCode: this.ticket.ServicePriorityCode,
        zImporteReclamado_KUT: this.ticket?.zImporteReclamado_KUT,
        Z_CabEntidadDueadelATM_KUT: this.ticket?.Z_CabEntidadDueadelATM_KUT,
        Z_DESCRIPCION_KUT: " ",
        Z_CREADOPOR_KUT: this.usersSrv.getActualUser().email
      };
      sendReq = req;
    }

    const nTicket = await this.ticketsSrv.createTicket(sendReq);
    if (nTicket) {
      this.auditSrv.logAction({modulo:'Tickets',accion:'Reabir ticket',detalle: `Ticket: ${this.idTicket}, Nuevo Ticket: ${nTicket.ID}`})
      if (this.ticket.description != null) {
        const descriptionData = await this.ticketsSrv.getTicketDescriptionObject(nTicket.ObjectID)
        if (descriptionData) {
          const description = {FormattedText: this.ticket.FormattedText};
          await this.ticketsSrv.createTicketDescription(descriptionData.ObjectID,description)
        }
      }
      const associated = await this.ticketsSrv.asociarTicket(nTicket.ObjectID,this.ticket.ID);
      if (associated) {
        this.toastr.success(Mensajes.TICKET_CREADO, "", {
          positionClass: "toast-top-center",
          progressBar: true,
        });
        this.verDetalles(nTicket.ID);
        this.ngOnInit();
      }
      this.loading = false;
    }
  }

  verDetalles(ticket: any) {
    const url = `tickets/${ticket}/detalle`;
    window.open(url, "_blank");
  }

  /** Método para obtener los tickets asociados */
  async getTicketAsociados(ticket?) {
    if (!Constantes.TICKETS_ESCALADOS.includes(this.ticket.ServiceRequestUserLifeCycleStatusCode)) {
      let tk;
      if(ticket) {
        tk = ticket.ServiceRequestBusinessTransactionDocumentReference
      } else {
        tk = await this.ticketsSrv.getTicketAsociado(this.ticket.ObjectID);
      }

      if (tk) {
        this.auditSrv.logAction({modulo:'Tickets',accion:'Obtener tickets asociados',detalle: `Ticket: ${this.idTicket}`})
        this.ticketAsociado = tk.filter((t:any) => t.RoleCode == "14").pop();
        // comprueba si existe ticket padre
        if (this.ticketAsociado != undefined) {
          const nBoleta = await this.ticketsSrv.getNumeroBoleta(this.ticketAsociado.ID);
          if (nBoleta) {
            this.ticket.Z_NUMERODEBOLETA_KUT = nBoleta
          }
        }
      } 
      this.loadingParticipantes = false;
    }
  }

  /** Método para obtener detalles de la transacción del ticket actual */
  async getTransactionDetails() {
    this.loading = true;
    this.loadingMessage.next("Obteniendo detalles de transacción");
    const nBoleta = this.ticket.Z_NUMERODEBOLETA_KUT;
    const details = await this.crmSrv.getTransactionDetails(nBoleta);
    this.loading = false;
    this.auditSrv.logAction({modulo:'Tickets',accion:'Obtener detalles de transacción',detalle: `Ticket: ${this.idTicket}`})
    const errores = details.DatosOut.filter((err: any) => err.Retorno == "01");
    if (errores && errores.length > 0) {
      details.DatosOut.forEach((err) => {
        this.toastr.error(err.Mensaje, err.CodError, {
          positionClass: "toast-top-center",
          progressBar: true,
        });
      });
    } else {
      const t = details.Boleta.BoletaMA;
      const nTransaction: Transaccion = {
        transaccion: `${t.CodTransaccion}-${t.Transaccion}`,
        cliente: t.Cliente,
        boleta: t.NroBoleta,
        estado: t.EstadoMovimiento,
        respuesta: `${t.CodOperacion}-${t.Respuesta}`,
        fecha: t.FecHorTransaccion,
        servicio: `${t.CodServicio}-${t.Servicio}`,
        origen: t.VersionSoftware,
        importe: t.Importe,
        tarjeta: t.NroTarjeta,
        ATM: t.NroATM == "0" ? undefined : t.NroATM,
        marca: `${t.CodMarca}-${t.Marca}`,
        producto: `${t.CodProducto}-${t.Producto}`,
        emisora: `${t.EntidadEmisora}-${t.Emisor}`,
        operación: `${t.CodOperacion}-${t.Operacion}`,
      };
      this.transaction = nTransaction;
      this.open(this.transactionModal, "lg");
    }
  }

  /**
   * Al momento de cancelar un ticket se solicita un comentario antes de cambiar de estado,
   * este método se encarga de enviar el comentario antes de cancelar, debido a que la integración C4C
   * no admite un comentario en el servicio de cambio de estado
   */
  async cancelarTicket() {
    
    if(this.newComment != undefined){
      const comentario = {
        ServiceRequestID: this.ticket.ID,
        TypeCode: "10011",
        FormattedText: '<p>' + this.usersSrv.getActualUser().nombreCompleto +': </p>' + this.newComment,
        ParentObjectID: this.ticket.ObjectID
      };
      this.refreshCommentAfterCancel = false
      this.loading = true;
      this.loadingMessage.next("Agregando comentario al ticket")
      
      const res = await this.comentsSrv.nuevoComentarioTK(comentario);
      
      if (res.commented) {
        this.auditSrv.logAction({modulo:'Tickets',accion:'Nuevo comentario',detalle: `Ticket: ${this.idTicket}, Comentario: ${this.newComment}`})
        const body = {
          "recipients" : this.recipientsEmail,
          "subject": this.ticket.Name,
          "comment": this.newComment,
          "user": this.usersSrv.getActualUser().email,
          "userName":  this.usersSrv.getActualUser().nombreCompleto,
          "ticketId": this.ticket.ID,
          "ticketType": this.ticket.ProcessingTypeCodeText,
          "entity": ['ZVE'].includes(this.ticket.ProcessingTypeCode) ? 
              [this.usersSrv.getActualUser().entidad?.codigoEntidad, this.ticket.Z_CabEntidadDueadelATM_KUT ]: 
              this.usersSrv.getActualUser().entidad?.codigoEntidad
        }
        this.refreshCommentAfterCancel = true
        await this.notificationSrv.sendMail(body, 'comment');
      } else {
        this.toastr.error("Error al crear comentario",'',{positionClass:'toast-top-center', progressBar:false});
      }
      this.loading = false;
    }
    else{
      this.toastr.error(Mensajes.ERROR_COMENTARIO_VACIO,'',{positionClass:'toast-top-center', progressBar:false});
    }
  }

  /** Método para comprobar si el ticket puede ser cancelado */
  checkCancelable(cantidadEstados, cantidadComentarios) {
    this.cantidadCambiosEstado=cantidadEstados;
    this.cantidadComentarios=cantidadComentarios;
    if (this.cantidadCambiosEstado>0 || this.cantidadComentarios>0) {
      this.estados = this.estados.filter(estado => estado.codigo!='Y3');
    }
  }

  async obtenerEncuesta(){
    if(this.idTicket){
      const data: any = await this.encuestaService.obtenerEncuesta({ticket: this.idTicket.toString()})
      if(data) {
        this.idEncuesta = data._id;
        if(data.estado != 'COMPLETADA'){
          const modal = this.modalSrv.open(FormEncuestaComponent, { centered: true })
          modal.componentInstance.idEvaluacion = data._id;
          modal.result.then().catch(() => {
            data.estado = 'ENCUESTA NO RESPONDIDA';
            this.mongoDBSrv.update(`/evaluaciones/`, this.idEncuesta, data)
          });
        }
        
      }
    }
  }

  async validTicketPermission(ticket) { 
    
    const idEntidad = this.usersSrv.getActualUser().entidad?.id.toString();
    const codEntidad = this.usersSrv.getActualUser().entidad?.codigoEntidad;
    let ticketBelongsEntity = false
    if([idEntidad, codEntidad].includes(ticket.Z_ENTIDAD_KUT) || [idEntidad, codEntidad].includes(ticket.Z_CabEntidadDueadelATM_KUT)) {
      ticketBelongsEntity=true;
    } else {
      const participaEnTicket = await this.validarParticipacion()
      if(!participaEnTicket) {
        this.toastr.warning("No tiene permisos para visualizar este ticket","",{ positionClass: "toast-top-center", progressBar: false });
        this.router.navigate(["tickets", "list"]);
      } else {
        return;
      } 
    } 
    const queries = await this.mongoDBSrv.getAll(`/grupos-tickets/queries`);
    let queryFound: any = null
    for (const query of queries) {
      for (const filtro of query.filtros) {
        if (filtro.property == 'ProcessingTypeCode' && filtro.defaultOpertator == 'eq'
          && filtro.defaultValue && filtro.defaultValue == ticket.ProcessingTypeCode) {
          queryFound = query;
          break
        }
        if(filtro.property == 'ProcessingTypeCode' && filtro.defaultOpertator == 'in' && filtro.defaultValue) {
          const tipos = filtro.defaultValue.split(',')
          for(const tipo of tipos) {
            if(tipo == ticket.ProcessingTypeCode) {
              queryFound = query;
              break
            }
          }
        }
      }
    }

    if (queryFound && ticketBelongsEntity) {
      for (const grupo of this.grupoTickets) {
        if (grupo.codigoQuery == queryFound.codigo) {
          if (!this.usersSrv.actualUSerHasPerm([grupo.permisos])) {
            this.loading=false;
            this.toastr.warning("No tiene permisos para visualizar este ticket","",{ positionClass: "toast-top-center", progressBar: false });
            this.router.navigate(["tickets", "list"]);
          }
        }
      }
    } else {
      this.loading=false;
      this.toastr.warning("No tiene permisos para visualizar este ticket","",{ positionClass: "toast-top-center", progressBar: false });
      this.router.navigate(["tickets", "list"]);
    }
  }

  async getGruposTicket(){
    let nGrupos: GrupoTicket[]=[];
    this.loading = true;
    this.loadingMessage.next("Verificando datos");
    const data = await this.mongoDBSrv.getAll(`/grupos-tickets?orderBy=orden&orderDir=asc&size=-1`);
    if (data) {
      nGrupos = data.datos
    }
    this.grupoTickets = nGrupos;
  }

  /** Método para comprobar si el ticket puede ser anulado 
   * @param aprobar true para aprobar la anulación, false para rechazarla
   */
  checkAnulacion(aprobar: boolean) {
    let checked = false;
    if (aprobar) {
      if (this.editarMedioDePago && this.ticket.zCabMediodePago_KUT=="") {
        this.toastr.warning("Medio de pago es requerido",'',{positionClass:'toast-top-center', progressBar:false});  
      } else if (this.editarMedioDePago && Constantes.MEDIOS_DE_PAGO_CON_REFERENCIA.includes(this.ticket.zCabMediodePago_KUT) && this.ticket.zCabNumerodereferencia_KUT=="") {
        this.toastr.warning("Número de referencia es requerido",'',{positionClass:'toast-top-center', progressBar:false});  
      } else {
        checked = true;
      }
    } else {
      checked = true;
    }

    if (checked) {
      this.aceptarAnulacion = aprobar;
      this.open(this.anularModal,'lg')
    }
  }

  /** Método para guardar los datos de método de pago y número de referencia
   * 
  */
  async guardarDatos() {
    let componentesFecha;
    let fechaTransferencia;
    const body = {
      zCabMediodePago_KUT : this.ticket.zCabMediodePago_KUT,
      zCabNumerodereferencia_KUT : this.ticket.zCabNumerodereferencia_KUT,
    }

    if(this.ticket.Z_FECHATRANSFERENCIA_KUT) {
      componentesFecha = this.ticket.Z_FECHATRANSFERENCIA_KUT.split("-");
      fechaTransferencia = new Date(
        parseInt(componentesFecha[0]),
        parseInt(componentesFecha[1]) - 1,
        parseInt(componentesFecha[2])
      );
      body['Z_FECHATRANSFERENCIA_KUT'] = `/Date(${fechaTransferencia.getTime()})/`
    }

    await this.ticketsSrv.updateTicket(this.ticket.ObjectID,body)
  }

  ticketTieneDatosPago(): boolean {
    return this.ticket.zCabMediodePago_KUT != undefined && this.ticket.zCabMediodePago_KUT != '' && 
            this.ticket.Z_FECHATRANSFERENCIA_KUT != undefined && this.ticket.Z_FECHATRANSFERENCIA_KUT != '' && 
            this.ticket.zCabNumerodereferencia_KUT != undefined && this.ticket.zCabNumerodereferencia_KUT != '';
  }

  async validarParticipacion(){
    const params: Param[] = [
      {key: 'zRolCode', value: '213'},
      {key: 'zTipoTicket', value: 'ZVE'},
      {key: 'codigo', value: 'TICKETS-VEREDICTOS-CONTACTOS'},
      {key: 'ID', value: this.idTicket!},      
    ]
    const validacion = await this.mongoDBSrv.get(`/grupos-tickets/validate-participant`, params);
    return validacion.esValido
  }


   /** Método para desencriptar la tarjeta 
   * @param encryptedCreditCard número de tarjeta encriptado
  */
   async decriptCreditCard(encryptedCreditCard: string) {
    this.loading = true;
    this.loadingMessage.next("Desencriptando tarjeta");
    const encryptedWithIV = this.encryptService.extractIV(encryptedCreditCard,32);
    const data = this.encryptService.decrypt(encryptedWithIV.encrypted,encryptedWithIV.iv);
    this.auditSrv.logAction({modulo: "Ticket", accion: "Desencriptar tarjeta", detalle: `{Ticket ID: ${this.ticket.ID}, usuario: ${this.usersSrv.getActualUser().email}`})
    if (data) {
      this.creditCard=data;
      this.descryptedCreditCard = true;
    } else {
      this.toastr.error("Error al desencriptar la tarjeta","",{ positionClass: "toast-top-center", progressBar: false });
    }
    this.loading = false;
    this.loadingMessage.next("");
  }


  async toggleCreditCardInfo(){
    if(this.descryptedCreditCard) {
      this.creditCard = this.ticket.Z_Tarjeta_Enmascarada_KUT;
      this.descryptedCreditCard = false;
    } else {
      await this.decriptCreditCard(this.ticket.Z_Tarjeta_Encriptada_KUT);
    }
  }
}
