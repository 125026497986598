import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import momentTZ from 'moment-timezone';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-historial-login',
  templateUrl: './historial-login.component.html'
})
export class HistorialLoginComponent {
  
  historial: any[]=[]
  loading=false;
  loadingMessage = new BehaviorSubject("");

  tableConfig = {
    url: '/users/historial',
    columnas: [
      {
        field: 'fecha', header: 'Fecha/Hora',
        renderer: (data) => {
          const date = momentTZ(data.fecha).tz('America/Asuncion').format('DD/MM/YYYY HH:mm:ss')
          return `${data.usuario.email} inició sesión el ${date}`
        }
      }
    ]
  };

  constructor(private titleSrv: Title){
    this.titleSrv.setTitle(`Historial de inicio de sesión - ${environment.app_name}`);
  }

}
