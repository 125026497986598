<div class="row">
    <div class="d-flex flex-column" style="padding-left: 10px !important;">
      <div [ngClass]="toastPackage.toastType=='toast-error' ? 'icon-container icon-bg-error d-flex justify-content-center align-items-center':
                      toastPackage.toastType=='toast-warning' ? 'icon-container icon-bg-warning d-flex justify-content-center align-items-center':
                      toastPackage.toastType=='toast-info' ? 'icon-container icon-bg-info d-flex justify-content-center align-items-center':
                      'icon-container icons-bg-success d-flex justify-content-center align-items-center'">
                      <fa-icon [icon]="toastPackage.toastType=='toast-error' ? faTimes:
                      toastPackage.toastType=='toast-warning' ? faExclamationTriangle:
                      toastPackage.toastType=='toast-info' ? faInfo: faCheck"></fa-icon>
      </div>
    </div>
    <div class="d-flex align-items-center col-9">
      <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
        class="custom-message"
        [class]="options.messageClass" [innerHTML]="message">
      </div>
      <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
        class="custom-message"
        [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
      </div>
    </div>

  </div>
 