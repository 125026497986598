import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { RequestService } from './request.service';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RecoveryService {


  constructor(
    private requestService: RequestService
  ) {}

  /** Método para enviar correo de reseteo de contraseña
   * @param data objeto con el correo electrónico del usuario
   */
  async recovery(data : {email: string}) {
    const endpoint = `reset_password`;
    const $source = this.requestService.postRequestRecovery(endpoint,data);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      return res.body.data;
    } else {
      return undefined;
    }
  }

  /** Método para solicitar cambio de contraseña
   * @param token token de autorización
   * @param data objeto la nueva contraseña del usuario
   */
  async changePassword(token:any,data : {password: string}) {
    const endpoint = `reset_password/${token}`;
    const $source = this.requestService.postRequestRecovery(endpoint,data);
    const res = await lastValueFrom($source);
    
    if (res instanceof HttpResponse) {
      return res.body.data;
    } else {
      return res.error;
    }
  }

  /** Método par asignar la primera contraseña a un nuevo usuario
   * @param data objeto con nueva contraseña, contraseña actual (PWAC) y correo electrónico del usuario
   */
  async setFirstPassword(data : {
      "newPassword": string,
      "currentPassword": string,
      "email": string,
      }) {
    const endpoint = `change_password`;
    const $source = this.requestService.postRequestRecovery(endpoint,data);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      return res.body.data;
    } else {
      return undefined;
    }
  }

}