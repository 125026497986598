import { Component } from "@angular/core";
import { StorageService } from "./services/storage.service";
import { LoginService } from "./services/login.service";
import { EncryptionService } from "./services/encryption.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "portal-entidades";

  constructor(
    private storageSrv: StorageService,
    private encryptSrv: EncryptionService,
    private loginSrv: LoginService
  ) {
    // comprueba si existe llave de encriptado guardada
    const key = this.storageSrv.get("rKey", true);
    if (key) {
      // inicializa en servicio de enceriptado
      this.encryptSrv.init(key);

      // comprueba si existen otras llaves
      const keys = this.storageSrv.get("keys", true);
      if (keys) {
        // separa el vector de inicialiación
        const encryptedWithIV = this.encryptSrv.extractIV(keys, 32);

        // desencripta las llaves
        const nKeys = this.encryptSrv.decrypt(
          encryptedWithIV.encrypted,
          encryptedWithIV.iv
        );
        if (nKeys) {
          // asigna llaves al servicio
          const keys = JSON.parse(nKeys);
          this.encryptSrv.setKeys(
            keys.pub,
            keys.priv,
            keys.pass,
            keys.api_pub_key
          );
        }
      } else {
        this.loginSrv.logout(false);
      }
    } else {
      this.loginSrv.logout(false);
    }
  }
}
