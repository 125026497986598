<div class="container">
    <section class="jumbotron text-center">

        <h1>¿En qué podemos ayudarte?</h1>
        <p class="lead text-muted">Aquí podrás encontrar respuestas a las preguntas mas frecuentes.</p>
        <div class="d-flex justify-content-center" [formGroup]="filterForm" >
            <div class="d-flex align-items-center">
                <fa-icon class="ml-2 puntero pointer table-search" [icon]="faSearch"></fa-icon>
                <input type="text" class="form-control main-content-input" placeholder="Ingrese un tema" id="find"
                    formControlName="pregunta" (keyup.enter)="obtenerDatos()">
                <fa-icon class="ml-2 puntero pointer" [icon]="faTimes"
                    pTooltip="Limpiar consulta" tooltipPosition="bottom" 
                    *ngIf="!filterForm.pristine"
                    (click)="limpiar()"></fa-icon>
            </div>
        </div>
        <small id="findHelp" class="form-text text-muted" *ngIf="!filterForm.pristine">Presione Enter para buscar.</small>
    
    </section>
</div>
<div class="container">
    <div class="card ">

        <div class="card-body">
            <h5 class="card-title">Explore los temas de ayuda</h5>
            <p-accordion>
                <p-accordionTab [header]="ayuda.pregunta" *ngFor="let ayuda of ayudaList">
                    <div [innerHTML]="ayuda.comentario"></div>
                </p-accordionTab>
            </p-accordion>

            <small class="form-text text-muted" *ngIf="ayudaList.length===0">No se puede encontrar consulta.</small>
        </div>
    </div>
</div>