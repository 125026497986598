<div class="background-login login container-fluid">
    <div class="flex-row h-100">
        <div class="flex-column d-flex justify-content-center">

            <div class="row row-cols-7 pt-5 justify-content-center">
                <div class="d-flex flex-column justify-content-center pointer">
                    <img src="../../../assets/imgs/Bancard-Logo.svg"  class="logo" alt="Logo Bancard">
                </div>
            </div>

            <div class="row row-cols-7 mt-3 justify-content-center">

                <div class="d-flex flex-column justify-content-center">
                    <span class="text-bancard">Portal de Entidades</span>
                </div>
                
            </div>
        </div>        
    <div class="flex-column d-flex  h-75 justify-content-center">

        <form [formGroup]="formRecovery" (ngSubmit)="changePassword()">

                <div class="row row-cols-4 justify-content-center ">
                
                    <div class="d-flex flex-column  container-login-top border-bottom">
                        <input formControlName="newPass" class="input-login" type="password" readonly 
                        onfocus="this.removeAttribute('readonly');" autocomplete="off" placeholder="Nueva Contraseña">
                    </div>
                
                </div>
                <div class="row  row-cols-4 justify-content-center  ">
                
                    <div class="d-flex flex-column  container-login-bottom">
                        <input formControlName="confirmPass" class="input-login" type="password" placeholder="Confirmar Contraseña">
                    </div>
                
                </div>

                <div class="row py-4 row-cols-4 justify-content-center">
                
                    <div class="d-flex flex-column">
                        <button class="btn-portal" type="submit" [disabled]="procesando">
                            <span class="text-btn-portal">
                                {{ procesando ? 'Procesando...' : 'Cambiar contraseña'}}
                            </span>
                        </button>

                    </div>
                </div>
        </form>
    </div>


    </div>
</div>