import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-field-error',
  templateUrl: './field-error.component.html',
  styles: []
})
export class FieldErrorComponent {

  @Input() field: any;
  @Input() type: any;
}
