import moment from 'moment'; 
import { Constantes } from '../constants/constantes.const';
/**
 * El método permite borrar los datos de un objeto que son vacios o nulos
 * @param data 
 * @returns 
 */
export function deleteEmptyData(data:any){
    for(const key in data) {
        if(data[key] == '' || data[key] == null) {
            delete data[key];
        }
    }
    return data;
}


/**
 * El método permite convertir un texto del tipo X a dd/MM/yyyy
 * @param data 
 * @returns 
 */
export function dateConvertToGrid(data:any){
    if(data) {
        return moment(data).format("DD/MM/YYYY HH:mm:ss");
    }
    return ''
}

export function removeStyleAttributesFromHTML(html: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const elementsWithStyle = doc.querySelectorAll('[style]');
    elementsWithStyle.forEach((element) => {
        element.removeAttribute('style');
    });

    return doc.documentElement.innerHTML;
}

/**
 * Método para reemplazar los tags lt y gt por llaves
 * @param text texto a validar
 * @returns texto limpio
 */
export function replaceOtherTags(text){
    console.log('Antes:', JSON.stringify(text));
    // Reemplazar todas las instancias de &lt; por {
    text = text.replace(/&lt;/g, '{');
    // Reemplazar todas las instancias de &gt; por }
    text = text.replace(/&gt;/g, '}');
    console.log('Después:', text);
    return text;
}

/**
 * Método para eliminar las llaves <> de tags que tengan formato de correo electrónico
 * @param text texto a validar
 * @returns texto limpio
 */
export function removeEmailTags(text){
    console.log('antes', text)
    const regex = /&lt;([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})&gt;/g;
    const res = text.replace(regex, '$1');
    console.log('despues', res)
    return res;
}

export function replaceTags(text) {
    text = removeStyleAttributesFromHTML(text);
    text = removeEmailTags(text);
    text = replaceOtherTags(text);
    text = text.replaceAll(' class="ql-align-justify"', '');
    text = text.replace(/\n/g, '<br>');
    const invalidTags: string[] = [];
    const ntext = Array.from(text);
    let ntag = '';
    ntext.forEach(l => {
        switch (l) {
            case '<':
                ntag+=l;
                break;
            case '>':
                ntag+=l;
                if (!Constantes.VALID_TAGS.includes(ntag)) {
                    invalidTags.push(ntag);
                }
                ntag='';
                break;
            default:
                if (ntag.length>0) {
                    ntag+=l;
                }
                break;
        }
    });
    invalidTags.forEach(it => {
        if (it=='<h1>') {
            text=text.replaceAll(it,'<strong>');
        } else if (it=='</h1>') {
            text=text.replaceAll(it,'</strong>');
        } else {
            text=text.replaceAll(it,'');
        }
    })
    return text;       
}