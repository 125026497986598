<!-- Spinner de carga -->
<app-loadingSpinner [message]="loadingMessage" [show]="loading"></app-loadingSpinner>
<div class="pb-5" (dragover)="onDragOver($event)" (paste)="onPaste($event)">
    <div class="py-1 d-flex flex-column" *ngIf="showDropZone && canUpload">
        <label class="label-entity"><i>Suelte sus archivos aqui</i></label>
        <div id="dropZone" class="drop-zone">
            <input class="input-agregar fileInput" type="file" multiple (change)="onFileDropped($event,nuevoAdjunto)">
        </div>
    </div>
    <div class="py-2 px-0 col-12" *ngIf="DOCUMENTOS.length>0">
        <div class="d-flex align-items-center">
            <fa-icon class="ml-2 puntero pointer table-search align-items-center" [icon]="faSearch"></fa-icon>
            <input type="text" [(ngModel)]="nombreArchivo" placeholder="Buscar adjunto" (keyup)="onChange()" class="main-content-input-ticket filtro-ticket" style="width:100% !important">
        </div>
    </div>
    <div  class="table-responsive">
        <table id="excel-table" class="table table-style table-font-size">
            <tr class="table-line align-items-center py-2" >
                <th class="table-cell-ticket " scope="col">
                </th>
                <th class="table-cell-ticket" scope="col">
                    Nombre del Archivo
                </th>
                <th class="table-cell-ticket" scope="col">
                    Fecha de creación
                </th>
                <th class="table-cell-ticket" scope="col">
                    Usuario
                </th>
                <th class="table-cell-ticket" scope="col" *ngIf="tipo == 'TK'">
                    Documento seguro
                </th>
                <th class="table-cell-ticket" scope="col">
                    
                </th>
            </tr>
            <tbody *ngIf="!loadingData && documentos.length>0">
                <tr class="py-2 align-items-center mt-2" *ngFor="let documento of documentos">
                    <td class="table-cell-ticket">
                        <fa-icon [icon]="faDownload" class="puntero pointer"
                            (click)="getDocument(documento)">
                        </fa-icon>
                    </td>
                    <td class="table-cell-ticket ">
                        {{documento.titulo}}
                    </td>
                    <td class="table-cell-ticket">
                        {{documento.fechaCreacion | date:"dd/MM/YYYY HH:mm"}}
                    </td>
                    <td class="table-cell-ticket">
                        {{documento.usuario}}
                    </td>
                    <td class="table-cell-ticket" *ngIf="tipo == 'TK'">
                        <span class="badge" 
                            [ngClass]="documento.contieneInformacionSensible ? 'badge-warning' : 'badge-secondary'">
                            {{documento.contieneInformacionSensible ? 'SI' : 'NO'}}
                        </span>
                        
                    </td>
                    <td class="table-cell-ticket">
                        <fa-icon *ngIf="documento.borrable"
                            [icon]="faTrash" class="pointer" (click)="fileToDelete=documento; open(confirmar,'md')">
                        </fa-icon>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="!loadingData && documentos.length==0">
                <tr>
                    <td colspan="10" align="center">
                        No hay adjuntos asociados.
                    </td>
                </tr>                                  
            </tbody>
            <tbody *ngIf="loadingData">
                <td align="center" colspan="17">
                    <fa-icon [icon]="faSpinner" animation="spin"></fa-icon>
                </td>
            </tbody>
        </table>
    </div>
    <div class="d-flex flex-row py-3 " *ngIf="canUpload">
        <div class="d-flex flex-column">
            <div class="d-flex flex-row">
                <div class="d-flex flex-column justify-content-center pointer" (click)="open(nuevoAdjunto,'lg')">
                    <button  class="btn btn-primary-portal  btn-primary btn-sm" type="button">
                        <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>
                        <span class="">Nuevo Adjunto</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex col justify-content-end">
            <div class="d-flex flex-column align-items-center justify-content-end">
                <div class="d-flex flex-column align-items-center">
                    <ngb-pagination *ngIf="!filtrado && documentos.length != 0" [(page)]="page" [maxSize]="3" [pageSize]="pageSize" [collectionSize]="collectionSize" [boundaryLinks]="true" (pageChange)="refreshDocuments()"> </ngb-pagination>
                    <ngb-pagination *ngIf="filtrado && documentos.length != 0" [(page)]="page" [maxSize]="3" [pageSize]="pageSize" [collectionSize]="collectionSize" [boundaryLinks]="true" (pageChange)="refreshDocumentsFiltrados()"> </ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modales -->
<ng-template #nuevoAdjunto let-modal>
    <div class="modal-header modal-background">
        Nuevo Adjunto
    </div>
    <div class="modal-body modal-background">
        <app-nuevoadjunto 
            [idOc]="idOc" (refresh)="getAdjuntos()" 
            [idEvento]="idEvento" 
            [idCliente]="idCliente" 
            [evento]="evt">
        </app-nuevoadjunto>
    </div>
</ng-template>
<ng-template #confirmar let-modal>
    <div class="modal-header modal-background">
        ¿Desea eliminar el adjunto?
    </div>
    <div class="mx-auto d-flex my-2">
        <button type="button" class="btn btn-outline-secondary btn-sm" (click)="modal.dismiss()">Cancelar</button>
        <button type="button" class="btn btn-primary-portal  btn-primary btn-sm" (click)="deleteDocument(); modal.dismiss()">Eliminar</button>
    </div>
</ng-template>