<div class="row mx-0 px-0 mb-2" *ngIf="!loadingComments" @fade>
    <div class="d-flex flex-column w-100">
        <div class="box-comment" *ngFor="let comment of comments">
            <div class="row pb-3">
                <div class="col-10">
                    <div [innerHtml]="comment.Text | safeCommentsHtml" *ngIf="type == 'OC'"></div>
                    <div [innerHtml]="comment.FormattedText | safeCommentsHtml" *ngIf="type == 'TK'"></div>
                </div>
                <div class="col-2 text-right">
                    <span class="text-muted date-comment">
                        {{ moment(comment.CreatedOn).locale('es').format(' L , HH:mm') }}
                    </span>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <span class="iniciales justify-content-center align-items-center d-flex">{{comment.CreatedBy.slice(0,
                    1).toUpperCase()}}</span>
                <span class="username-comment ml-1">{{ comment.CreatedBy.toUpperCase() }}</span>
            </div>
        </div>
    </div>
</div>
<div class="d-flex flex-row ">
    <div class="d-flex col justify-content-end">
        <div class="d-flex flex-column align-items-center justify-content-end">
            <div class="d-flex flex-column align-items-center">
                <ngb-pagination *ngIf="comments.length != 0" [(page)]="page" [maxSize]="3" [pageSize]="pageSize"
                    [collectionSize]="collectionSize.getValue()" [boundaryLinks]="true"
                    (pageChange)="refreshComments()"> </ngb-pagination>
            </div>
        </div>
    </div>
</div>

<div class="item" *ngIf="loadingComments">
    <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
</div>

<ng-container *ngIf="allowNewComment">
    <editor [(ngModel)]="newComment" [init]="editorConfig"></editor>

    <button class="btn btn-primary-portal btn-primary btn-sm text-white mt-3" (click)="enviarComentarioTK()"
        *ngIf="type == 'TK' && !crearComentarios && !sendingComments">
        <span *ngIf="!sendingComments">Enviar comentario</span>
    </button>
    <button class="btn btn-primary-portal btn-primary btn-sm text-white mt-3" disabled *ngIf="type == 'TK' && !crearComentarios && sendingComments">
        <span *ngIf="sendingComments">Enviando comentario <fa-icon [icon]="faSpinner" animation="spin"></fa-icon></span>
    </button>

    <button class="btn btn-primary-portal btn-primarytext-white mt-3" (click)="enviarComentarioOC()" *ngIf="type == 'OC' && !sendingComments">
        <span *ngIf="!sendingComments">Enviar comentario</span>
    </button>
    <button class="bbtn btn-primary-portal btn-primary btn-sm text-white mt-3" disabled *ngIf="type == 'OC' && sendingComments">
        <span *ngIf="!sendingComments">Enviar comentario</span>
        <span *ngIf="sendingComments">Enviando comentario <fa-icon [icon]="faSpinner" animation="spin"></fa-icon></span>
    </button>
</ng-container>


<ng-template #confirmarcomentario let-modal>
    <div class="modal-body modal-background">
        <div class="row row-cols-lg-1 py-2  justify-content-center">
            <div class="col-lg-8 text-center text-modal" >
                <span>¡Atención! </span>
            </div>
        </div>

        
        <div class="row row-cols-lg-1 py-3 justify-content-center">
            <div class="col-lg-8 text-center">
                <div class="d-flex row text-center justify-content-center">
                <fa-icon class="mr-2 text-danger fa-3x" [icon]="faExclamationTriangle"></fa-icon>
                </div>
                <div class="d-flex row text-center justify-content-center">
                    <span>
                        Parece que tu comentario podría contener información confidencial, como un número de tarjeta. Antes de continuar, te recomendamos revisar y asegurarte de que no estés compartiendo datos sensibles. Por favor, confirma si deseas continuar
                    </span>
                </div>
            </div>
        </div>
        <div class="row row-cols-4 row-cols-lg-4 mx-0  py-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <button type="button" (click)="modal.close(true)" class="btn btn-primary-portal btn-primary btn-sm">
                        <span>Lo entiendo, enviar comentario</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row row-cols-4 row-cols-lg-4 mx-0  pb-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex  justify-content-center">
                    <button type="button" (click)="modal.dismiss()"  class="btn btn-secondary btn-sm">
                        <span>Cancelar y editar comentario</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>