import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/services/storage.service';
import { UsersService } from 'src/app/services/users.service';
import { faSpinner, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Mensajes } from 'src/app/constants/mensajes.const';
import { formEditUser } from './editar-usuarios.form';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { MongoDBService } from '../../../services/mongoDB.service';
import { Param, Privilege, Usuario } from 'src/app/interfaces/interfaces';
import { BehaviorSubject } from 'rxjs';
import { AuditService } from 'src/app/services/audit.service';

@Component({
  selector: 'app-editar-usuarios',
  templateUrl: './editar-usuarios.component.html',
  styleUrls: ['./editar-usuarios.component.scss']
})
export class EditarUsuariosComponent implements OnInit {

  faSpinner = faSpinner;
  faCircleExclamation=faCircleExclamation;

  userToEdit: Usuario | undefined;

  loading = false;
  loadingMessage = new BehaviorSubject("");

  formEditUser: FormGroup;
  formAssignRol: FormGroup;
  formAssignPerfil: FormGroup;

  perfilesPosibles:any = [];
  perfilesSelected:any = [];

  rolesPosibles: any = [];
  rolesSelected: any = [];
  active = 1;
  privilegiosAsignados: Privilege[] = [];

  constructor (
    private storageSrv: StorageService,
    private toastr: ToastrService,
    private router: Router,
    private usersSrv: UsersService,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private mongoSrv: MongoDBService,
    private auditSrv: AuditService
  ) {
    this.formEditUser = formEditUser;
    this.formAssignRol = new FormGroup({
      rol: new FormControl('', [Validators.required])
    })

    this.formAssignPerfil= new FormGroup({
      perfiles: new FormControl('',[])
    })
  }

  async ngOnInit() {
    this.loading = true;
    this.loadingMessage.next("Obteniendo datos");
    this.title.setTitle(`${this.activatedRoute.snapshot.data.titulo} - ${environment.app_name}`);
    const userToEdit: Usuario = JSON.parse(this.storageSrv.get('userToEdit', true, true)!);
    if (userToEdit) {
      this.userToEdit = userToEdit;
      this.formEditUser.patchValue({ 'username': userToEdit.email });
      this.formEditUser.patchValue({ 'name': userToEdit.nombre });
      this.formEditUser.patchValue({ 'email': userToEdit.email });
      this.formEditUser.patchValue({ 'rol': userToEdit.rol });
      this.formEditUser.patchValue({ 'surname': userToEdit.apellido });
      this.storageSrv.remove('userToEdit');


      // Evitar same request en pocos segundos
      if(userToEdit.usuarioCreacion?.trim() == userToEdit.usuarioActualizacion?.trim()){
        if (userToEdit.usuarioCreacion) {
          const u = await this.usersSrv.getUserById(userToEdit.usuarioCreacion);
          userToEdit.usuarioCreacion= u?.email;
          userToEdit.usuarioActualizacion = u?.email;
        }
      } else {
        if (userToEdit.usuarioCreacion) {
          const u = await this.usersSrv.getUserById(userToEdit.usuarioCreacion);
          userToEdit.usuarioCreacion= u?.email;
        }
        if (userToEdit.usuarioActualizacion) {
          const u = await this.usersSrv.getUserById(userToEdit.usuarioActualizacion);
          userToEdit.usuarioActualizacion = u?.email;
        }
      }
      await this.formAssignRol.valueChanges.subscribe(changes => this.changeRolesSelect(changes, this.rolesPosibles))
      await this.formAssignPerfil.valueChanges.subscribe( changes=> this.changePerfilesSelect(changes, this.perfilesPosibles) )

      this.getRoles();
      this.getPerfiles();
      
      this.loading = false;
    } else {
      this.loading = false;
      this.getBack();
    }
    
  }

  getBack() {
    this.router.navigate(['usuarios'])
  }

  /** Método para actualizar datos del usuario actual */
  async editUser() {
    this.loading = true;
    this.loadingMessage.next("Editando usuario")
    const payload = {
      username: this.formEditUser.value.username,
      email: this.formEditUser.value.username,
      rol: this.formEditUser.value.rol,
      entity: this.userToEdit?.entidad!.id,
      name: this.formEditUser.value.name,
      surname: this.formEditUser.value.surname,
      updated_by: this.usersSrv.getActualUser().id,
      updated_date: new Date().toISOString()
    }
    const updated = await this.usersSrv.updateUser(this.userToEdit!.id,payload);
    if (updated) {
      this.auditSrv.logAction({modulo:'Usuarios',accion: 'Editar usuario', detalle: `Usuario: ${payload.username}, Rol: ${payload.rol}, Nombre: ${payload.name}, Apellido: ${payload.surname}, Entidad: ${this.userToEdit?.entidad?.nombre}`})
      this.toastr.success(Mensajes.USUARIO_EDITADO, '', { positionClass: 'toast-top-center', progressBar: true });
      this.router.navigate(['usuarios']);
    } else {
      this.toastr.error(Mensajes.ERROR_AL_EDITAR_USUARIO, '', { positionClass: 'toast-top-center', progressBar: false })
    }
    this.loading = false;
  }

  formatDate(date: any) {
    return moment(date).format("DD/MM/YYYY HH:mm");
  }

  async guardarRoles() {
    this.loading = true;
    this.loadingMessage.next("Editando roles");
    const payload = this.getRolesSelected(this.formAssignRol.value.rol);
    const updated = await this.usersSrv.updateUserRoles(this.userToEdit!.id,payload);
    if (updated) {
      const codigoArray = payload.assign.map(item => item.codigo);
      const codigoString = codigoArray.join(', ');
      this.auditSrv.logAction({modulo:'Usuarios',accion: 'Editar roles de usuario', detalle: `Usuario: ${this.userToEdit?.email}, Roles: ${codigoString}`})
      this.toastr.success('Los roles del usuario fueron modificados correctamente','',{positionClass:'toast-top-center', progressBar:true})  
      this.router.navigate(['admin', 'usuarios']);
    } else {
      this.toastr.error('No se pudo modificar el rol','',{positionClass:'toast-top-center', progressBar:false});
    }
    this.loading = false;
  }
  /**
   * Método que obtiene los roles disponibles, el `size` es 99999 para obtener todos los roles
   * y obtener todos los roles en un sola página
   */
  async getRoles() {
    const roles = await this.mongoSrv.getAll(`/roles`);
    if (roles) {
      this.auditSrv.logAction({
        modulo: "Usuarios",
        accion: "Obtener de usuarios"
      })
      this.rolesPosibles = roles.datos
      this.setFormRolesValue()
    } else {
      this.toastr.error("No se puso obtener los roles", '', { positionClass: 'toast-top-center', progressBar: false })
    }
  }

  /**
   * Método que setea los roles en el formulario de roles.
   * Obtiene los datos del localstorage, recorre los roles seleccionados
   * y luego setea los ids al input del formulario
   */
  setFormRolesValue() {
    const roles = this.userToEdit?.roles
    if (roles) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const rolesEnComun: any[] = roles.filter((rol) =>
            this.rolesPosibles.some((posibleRol) => posibleRol._id == rol._id)
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const ids: any[] = []
      for (const rol of rolesEnComun) {
        ids.push(rol._id)
      }
      this.formAssignRol.patchValue({ 'rol': ids });
    }
  }

  /**
   * Método que agrega el objeto completo del rol seleccionado, ya que para que el formulario se visualice
   * correctamente el [value] dentro del componente ng-option debe ser el id del rol 
   * @param changes - Objeto de cambios realizados en el formulario. 
   * @param roles - listado completo de roles 
   */
  changeRolesSelect(changes: any, roles: any) {
    if (changes.rol) {
      const addRoles: any[] = []
      for (const rs of changes.rol) {
        for (const rp of roles) {
          if (rs == rp._id) {
            addRoles.push(rp);
          }
        }
      }
      
      this.rolesSelected = addRoles
      let privilegesTmp: Privilege[] = [];
      for(const r of this.rolesSelected){
        privilegesTmp = [...privilegesTmp, ...r.privileges]
      }
      const uniqueObjects = privilegesTmp.reduce((acc, obj) => {
        acc[obj.codigo] = obj;
        return acc;
      }, {});
      
      this.privilegiosAsignados = Object.values(uniqueObjects);
    }
  }

  /**
   * Método que retorna un objeto para asignar roles al usuario
   * @param roles - listado completo de roles
   * @returns un objecto que tiene arrays de roles a ser asigandos `assing` y desasignados `revoke`
   */
  getRolesSelected(roles: any) {
    const assign: any[] = []
    const revoke: any[] = []
    for (const rol of this.rolesPosibles) {
      if (roles.includes(rol._id)) {
        assign.push(rol)
      } else {
        revoke.push(rol);
      }
    }
    return { assign, revoke }
  }
  /**
   * Método que obtiene los perfiles disponibles, el `size` es 99999 para obtener todos los roles
   * y obtener todos los perfiles en un sola página
   */
  async getPerfiles(){
    const params: Param[]=[];
    params.push( {key:'size', value: '99999'} )
    params.push( {key:'page', value: '0'} )
    
    const res = await this.mongoSrv.get('/perfil/',params);
    if (res) {
      this.auditSrv.logAction({
        modulo: "Perfiles",
        accion: "Obtener de perfiles"
      })
      this.perfilesPosibles = res.datos;
      this.setFormPerfilesValue()
    } else {
      this.toastr.error("Error al obtener perfiles",'',{positionClass:'toast-top-center', progressBar:false})
    }
  }

  /**
   * Método que setea los perfiles en el formulario de perfiles.
   * Obtiene los datos del localstorage, recorre los roles seleccionados
   * y luego setea los ids al input del formulario
   */
  setFormPerfilesValue(){
    const perfiles = this.userToEdit?.perfiles
    if(perfiles){
      
      const ids: any[] = []
      for(const perfil of perfiles){
        ids.push(perfil._id)
      }
      this.formAssignPerfil.patchValue({'perfiles': ids});
    }
  }

  /**
   * Método que agrega el objeto completo del perfil seleccionado, ya que para que el formulario se visualice
   * correctamente el [value] dentro del componente ng-option debe ser el id del rol 
   * @param changes - Objeto de cambios realizados en el formulario. 
   * @param perfiles - listado completo de roles 
   */
  changePerfilesSelect(changes:any, perfiles:any) {
    if(changes.perfiles) {
      const addPerfil:any[] = []
      for(const rs of changes.perfiles){
        for(const rp of perfiles){
          if(rs==rp._id){
            addPerfil.push(rp);
          } 
        }
      }
      this.perfilesSelected=addPerfil
    }
  }

  /**
   * Método que retorna un objeto para asignar perfiles al usuario
   * @param perfiles - listado completo de perfiles
   * @returns un objecto que tiene arrays de perfiles a ser asigandos `assing` y desasignados `revoke`
   */
  getPerfilesSelected(perfiles:any){
    const assign:any[] = []
    const revoke:any[] = []
    for(const perfil of this.perfilesPosibles) {
      if(perfiles.includes(perfil._id)) {
        assign.push(perfil)
      } else {
        revoke.push(perfil);
      }
    }
    return {assign, revoke}
  }

  /** Método que guarda los perfiles seleccionados del usuario */
  async guardarPerfiles(){
    const data = this.getPerfilesSelected(this.formAssignPerfil.value.perfiles)
    const id = this.userToEdit?.id
    if(id){
      this.loading= true;
      const res = await this.mongoSrv.update(`/users/`, id , data ,`perfiles`);
      if (res.updated) {
        const codigoArray = data.assign.map(item => item.codigo);
        const codigoString = codigoArray.join(', ');
        this.auditSrv.logAction({modulo:'Usuarios',accion: 'Editar perfiles de usuario', detalle: `Usuario: ${this.userToEdit?.email}, Perfiles: ${codigoString}`})
        this.toastr.success('Los perfiles del usuario fueron modificados correctamente','',{positionClass:'toast-top-center', progressBar:true})    
        this.loading = false
        this.router.navigate(['admin', 'usuarios']);
      } else {
        this.loading = false
        this.toastr.error('No se pudo modificar el perfil','',{positionClass:'toast-top-center', progressBar:false});
      }
    }
  }
}
