import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { faDownload, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AdjuntosService } from '../../../services/adjuntos.service';
import { MongoDBService } from '../../../services/mongoDB.service';
import { dateConvertToGrid } from '../../../utils/appUtils';
import { Mensajes } from 'src/app/constants/mensajes.const';
import { UsersService } from 'src/app/services/users.service';
import { Basico, Contacto } from 'src/app/interfaces/interfaces';
import { ContactService } from 'src/app/services/contact.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuditService } from 'src/app/services/audit.service';
import { formContact } from '../../tickets/crear-ticket/contacto-principal.form';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OcService } from 'src/app/services/oc.service';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-formulario-catalogo-productos',
  templateUrl: './formulario-catalogo-productos.component.html'
})
export class FormularioCatalogoProductosComponent implements OnInit {

  id: any;
  data;
  loading = false;
  filter: any = {};
  contactoSelected:Contacto|undefined;

  faPlus = faPlus;
  faSpinner=faSpinner;
  procesando = false

  downloading = false;

  backUrl = ['/', 'catalogo-productos', 'listado']

  tableDocumentosConfig = {
    url:`/documentosAdjuntos/visutalizacion/productos-pe/`,
    hideIfEmpty: true,
    columnas : [
      { field: 'adjuntoDomus.D_Titulo', header: 'Nombre' },
      { field: 'modulo', header: 'Modulo' },
      { field: 'tipoDocumento.description', header: 'Tipo Adjunto' },
      { field:'createdBy', header:'Creado por'},
      {
        field: 'createdDate', header: 'Fecha de creación',
        renderer: (data) => {
          return dateConvertToGrid(data.createdDate)
        }
      },
      {
        field: 'enable', header: 'Activo', innerHTML: true,
        renderer: (data) => {
          if(data.enable) {
            return '<span class="badge badge-success">ACTIVO</span>'
          } else {
            return '<span class="badge badge-secondary">INACTIVO</span>'
          }
        }
      },
      {field:'', header:'Acciones', actions: true, 
        options:[
          {event:'download', tooltip:'Descargar documento', icon: faDownload},
        ] 
      }
    ]
  }
  
  loadingMessage = new BehaviorSubject('');

  contactForm: FormGroup = formContact;
  emailContacto: any;
  emailContactoPermanente: any;
  genders: Basico[] = [];
  subscriptions: Subscription[] = [];
  
  @ViewChild("nuevoContacto", { static: true }) nuevoContactoModal: any;
  mostrarProductosAsociados = false;

  constructor(
    private service: MongoDBService,
    private route: ActivatedRoute,
    private adjuntosSrv: AdjuntosService,
    private toastr: ToastrService,
    private usersSrv: UsersService,
    private contactSrv: ContactService,
    private auditSrv: AuditService,
    private modalService: NgbModal,
    private ocService: OcService,
    private dataSrv: DataService
  ) { 
    // obtiene el estado de carga de tickets
    this.subscriptions.push(
      this.dataSrv.getPreLoadStatus().subscribe(cargado => {
        if (cargado) {
          this.getDatos();
          this.resetForms();
        }
      })
    );
  }

  async ngOnInit() {
    await this.asociacionContacto();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      if (this.id && this.id != '') {
        this.tableDocumentosConfig.url = `/documentosAdjuntos/visutalizacion/productos-pe/${this.id}`
        this.mostrarProductosAsociados = true;
        this.obtenerDatos();
      }
    });
  }

  async obtenerDatos() {
    this.procesando = true
    this.loadingMessage.next("Obteniendo datos del producto");
    const data = await this.service.getAll(`/productos-digitales/${this.id}`);
    if (data) {
      this.auditSrv.logAction({
        modulo: 'Productos digitales',
        accion: 'Ver detalles de producto',
        detalle: `Producto: ${this.id}`
      })
      
      this.data = data;
      this.procesando = false;
    } else {
      this.toastr.error(`Error al obtener productos`,'',{positionClass:'toast-top-center', progressBar:false});
    }
  }

  getData(key){
    if(this.data && Object.prototype.hasOwnProperty.call(this.data, key)){
      return this.data[key].descripcion
    } 
    return ''
  }

  async clickInfo(e) {
    this.downloading = true;
    const req= {
      "templateId": e.data.adjuntoDomus.templateId,
      "PK":e.data.adjuntoDomus.D_PK,
    }
    switch(e.accion) {
      case 'download':
        this.downloading = true;
        this.adjuntosSrv.getContent(req).then(res => {
          if (res.retrieved) {
            this.adjuntosSrv.dowloadFile(res.body);
            this.auditSrv.logAction({
              modulo: 'Productos digitales',
              accion: 'Descargar adjunto',
              detalle: `Producto: ${this.id}, Adjunto: ${e.data.adjuntoDomus.D_Titulo}`
            })
            this.service.create('/documentosAdjuntos/check-download',e.data.adjuntoDomus);
          } else {
            this.toastr.error(Mensajes.ADJUNTOS_ERROR_OBTENERDATOS,'',{positionClass:'toast-top-center', progressBar:false});
          }
        })
        break;      
      default:
        break; 
    }

  }


  async solictarProducto(){
    this.procesando = true;
    this.loadingMessage.next("Solicitando producto");
    if(this.contactoSelected) {

      const res = await this.service.create(`/productos-digitales/solictud-producto`, {
        Name:`${this.data.nombre}`,
        BuyerPartyID: this.usersSrv.getActualUser().entidad?.C4C_ID,
        BuyerMainContactPartyID: this.contactoSelected!.idContacto,
        Email: this.contactoSelected!.email,
      });

      if (res.posted) {
        this.auditSrv.logAction({
          modulo: 'Productos digitales',
          accion: 'Solicitar producto',
          detalle: `Producto: ${this.id}`
        })
        this.toastr.success(`Se ha creado un ticket, solicitado el producto`,'',{positionClass:'toast-top-center', progressBar:true});
      } else {
        this.toastr.error(`No existe contato asociado para ${this.usersSrv.getActualUser().email}`,'',{positionClass:'toast-top-center', progressBar:false});
      }
      this.procesando = false;
    } else {
      this.toastr.error(`No existe contato asociado para ${this.usersSrv.getActualUser().email}, Favor crea el contacto para continuar`,'',{positionClass:'toast-top-center', progressBar:false});
      this.procesando = false;

      this.open(this.nuevoContactoModal, 'xl');
    }
  }

  /** Método para asignar el contacto del usuario actual al ticket */
  async asociacionContacto() {
    const contact = await this.contactSrv.getContactByEmail(this.usersSrv.getActualUser().email);
    if (contact && !(contact instanceof Array)) {
      this.auditSrv.logAction({
        modulo: 'Productos digitales',
        accion: 'Obtener contacto'
      })
      this.contactoSelected=contact;
    }
    
  }

  /** Método para abrir un modal
   * @param content modal a mostrar
   * @param size Tamaño del modal
   */
  open(content: any, size: "md" | "lg" | "xl") {
    this.modalService.open(content, { size: size, centered: true, backdropClass: 'modal-backdrop' });
    this.emailContacto = this.usersSrv.getActualUser().email;
    this.contactForm.reset();
    this.contactForm.get('email')?.patchValue(this.emailContacto);
  }


  /** Método para crear un nuevo contacto */
  async crearContacto() {
    if (this.contactForm.invalid) {
      this.validateAllFields(this.contactForm);
      this.toastr.error(Mensajes.DATOS_INCOMPLETOS, '', { positionClass: 'toast-top-center', progressBar: false });
      return
    } else {
      this.procesando = true;
      this.loadingMessage.next("Creando contacto");
      const req = {
        "FirstName": this.contactForm.value.nombre,
        "LastName": this.contactForm.value.apellido,
        "GenderCode": this.contactForm.value.genero,
        "Phone": this.contactForm.value.telefono,
        "Mobile": this.contactForm.value.telefonoMovil,
        "Email": this.contactForm.value.email,
        "AccountID": this.usersSrv.getActualUser().entidad?.C4C_ID
      }
      // llama al servicio para crear el contacto, espera el resultado
      const res = await this.ocService.post(`/http/contactoportal`, req);
      if (res.created) {
        const nContact = res.body! as Contacto
        
        this.auditSrv.logAction({ modulo: 'Productos digitales', accion: 'Crear contacto', detalle: `ID: ${nContact.idContacto}, Nombre: ${nContact.nombre}` });
        this.selectContacto(nContact);
        this.modalService.dismissAll();
        this.toastr.success(Mensajes.ALTA_CONTACTO, '', { positionClass: 'toast-top-center', progressBar: true });
        return true;
      } else {
        this.procesando = false;
        this.toastr.error(Mensajes.ERROR_AL_CREAR_CONTACTO, '', { positionClass: 'toast-top-center', progressBar: false });
        return false;
      }
    }
  }

  /** Método para validar campos de un formulario
   * @param formGroup formulario a validar
   */
  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  /** Método para seleccionar un contacto
   * @param contacto contacto seleccionado
   */
  async selectContacto(contacto: Contacto) {
    this.contactoSelected = contacto;
    await this.solictarProducto()
  }

  /** Método para obtener datos del servicio */
  async getDatos() {

    this.dataSrv.setCargando(true);
    await Promise.all([
      this.dataSrv.loadGenders()
    ]);
    this.dataSrv.setCargando(false);
    // obtiene los géneros
    this.subscriptions.push(this.dataSrv.getGenders().subscribe(genders => {
      this.genders = genders;
    }));
  }

  /** Método para resetear los formularios del componente */
  resetForms() {
    this.contactForm.reset();
  }

}
