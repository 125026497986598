import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Regex } from "src/app/constants/regex.const";

export const formNewTicket = new FormGroup({
  clasificacion: new FormControl("", [Validators.required]),
  asunto: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  tipo: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  sucursal: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  contactoPrincipal: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  categoryServices: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  categoryIncidents: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  description: new FormControl("", [
    Validators.minLength(1),
    Validators.required,
  ]),
  prioridadTicket: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  serie: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),

  paisOrigen: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  departamentoOrigen: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  ciudadOrigen: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  barrioOrigen: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  zonaOrigen: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  calleOrigen: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  numeroCalleOrigen: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  paisDestino: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  departamentoDestino: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  ciudadDestino: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  barrioDestino: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  zonaDestino: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  calleDestino: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  numeroCalleDestino: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  marca: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  modelo: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  suministro: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  latitud: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  longitud: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  fechaHoraInstalacion: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  fechaHoraBaja: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  horaBaja: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  terminal: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
    Validators.pattern(Regex.TERMINAL),
  ]),
  importeReclamado: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  atmEntity: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  numeroBoleta: new FormControl("", [
    Validators.minLength(1),
    Validators.maxLength(240),
  ]),
  nroTarjeta: new FormControl("", [
    Validators.minLength(13),
    Validators.maxLength(19),
    Validators.pattern(Regex.TARJETA)
  ]),
});
