import { Component, OnInit } from '@angular/core';
import { Param } from '../../../interfaces/interfaces';
import { MongoDBService } from '../../../services/mongoDB.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuditService } from 'src/app/services/audit.service';

@Component({
  selector: 'app-listado-catalogo-productos',
  templateUrl: './listado-catalogo-productos.component.html',
  styleUrls: ['./listado-catalogo-productos.component.scss']
})
export class ListadoCatalogoProductosComponent implements OnInit {

  productosAgrupados: any[] = []
  gruposPDlist: any[] = [];
  loadingMessage = new BehaviorSubject("");
  cargando = false;

  constructor(
    private service: MongoDBService,
    private router: Router,
    private auditSrv: AuditService
  ) {
  }

  ngOnInit(): void {

    this.obtenerDatos()
  }

  async obtenerDatos() {
    this.cargando = true
    this.loadingMessage.next('Cargando catálogo de productos')
    const params: Param[] = [];
    params.push({ key: 'size', value: '-1' })
    params.push({ key: 'page', value: '0' })
    params.push({ key: 'orderBy', value: 'order' })

    const grupos = await this.service.get(`/grupos-productos-digitales/`, params);
    if (grupos) {
      this.auditSrv.logAction({
        modulo: "Productos",
        accion: "Obtener grupo de productos"
      })
      this.gruposPDlist = grupos.datos
      for (const g of this.gruposPDlist) {
        this.productosAgrupados.push({
          nombre: g.nombre,
          grupoId: g._id,
          productos: []
        })
      }
      
      const { datos } = await this.service.get(`/productos-digitales/visualizacion/`, params);

      if (datos) {
        this.auditSrv.logAction({
          modulo: "Productos",
          accion: "Obtener productos"
        })
        for (const gp of this.productosAgrupados)
          for (const p of datos) {
            if (p.grupo == gp.grupoId) {
              gp.productos.push(p)
            }
          }
      }
    }
    this.cargando = false
  }


  ver(data) {
    this.router.navigate(['catalogo-productos', data._id, 'detalle',])
  }

}
