import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-title',
  templateUrl: './titulo.component.html',
})
export class TituloComponent implements OnInit {

  @Input('acciones') acciones: any = {}
  
  @Output('OnSelectItem') onSelectItem: EventEmitter<object>

  @Input('title') title = ''
  
  faCaretDown = faCaretDown;
  constructor(private activatedRoute: ActivatedRoute,
    private usersSrv: UsersService) {
    this.onSelectItem = new EventEmitter();
   }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data =>{
      if(this.title == '') {
        this.title=data.titulo;
      }  
    })
  }

  emitSelected(option) {
    this.onSelectItem.emit( { option })
  }

  showOption(option) {
    return true
    if(option.permisos) {
      return this.usersSrv.actualUSerHasPerm(option.permisos, 'AND');
    } else {
      return true;
    }
  }
}
