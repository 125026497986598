            
<div *ngIf="uploading" class="d-flex flex-column align-items-center justify-content-center w-100 h-100 spinner-background" style="position: fixed;z-index: 99999999;">
    <fa-icon [icon]="faSpinner" class="fa-2x mt-5 d-flex justify-content-center " style="color: #fff;" animation="spin"></fa-icon>
    <span class="label-entity" style="color:#fff">Cargando adjunto</span>
</div>

<app-loadingSpinner [message]="loadingMessage" [show]="loading"></app-loadingSpinner>

<div class="d-flex flex-row py-1 align-items-center">
    <div class="d-flex flex-column">
        <label class="label-entity">Archivo</label>
        <div class="d-flex flex-row">
            <div class="d-flex flex-column">
                <label class="input-file-domus">
                    Seleccionar Archivo
                    <input  id="fileInput" type="file" [(ngModel)]="archivo" (change)="selectFile($event)" class="input-file-hidden" style="height: auto;">
                </label>
            </div>
        </div>
        <span class="custom-control-inline warning-feedback"> </span>
    </div>
    <div class="d-flex flex-column mx-4 input-domus-mobile" style="width:30rem" *ngIf="selectedFile">
        <label class="label-entity">Nombre del Archivo</label>
        <input type="text" class="input-modal w-100" [(ngModel)]="selectedFile.tituloSolo" placeholder="selectedFile.tituloSolo" (keyup)="checkLength($event)">
        <span class="custom-control-inline warning-feedback"> {{getNameSizeLeft()}} caracteres restantes. </span>
    </div>
</div>
<div class="d-flex flex-row py-1 align-items-center">
    <div class="d-flex flex-column" *ngIf="selectedFile">
        <label class="label-entity">Tipo de Documento (*)</label>
        <ng-select placeholder="Tipo" [ngModel]="tipoDocValue" [clearable]="false" (change)="onChange($event)" [searchable]="false">
            <ng-option *ngFor="let tipo of tipoAdjuntos" value="{{tipo.value}}">
            <span >{{tipo.value}} - {{tipo.description}}</span> 
            </ng-option>
        </ng-select> 
    </div>
    <div class="d-flex flex-column" *ngIf="selectedFile && tipo == 'TK'" >
            <label class="mr-auto my-auto" style="text-indent: 7px; color: var(--blue);">Documento seguro?</label>
            <label class="switch ml-auto my-auto">
                <input type="checkbox" [(ngModel)]="selectedFile.contieneInformacionSensible" 
                    name="isSensitive">
                <span class="slider round"></span>
            </label>
    </div>
</div>
<div class="d-flex flex-row py-1 align-items-center" *ngIf="tipo == 'TK'">
    <div class="alert alert-warning-gold d-flex align-items-center" role="alert">
        <fa-icon class="mr-2 text-danger fa-2x" [icon]="faExclamationTriangle"></fa-icon>
        Por favor, ten en cuenta que si algún documento contiene información de tarjeta, es crucial asegurarse de que el botón de dato sensible esté activado antes de proceder.
    </div>
</div>
<div *ngIf="tipoDoc?.id ==1 || tipoDoc?.id==2 || tipoDoc?.id==3 || tipoDoc?.id==4 || tipoDoc?.id==6 || tipoDoc?.id==7 || tipoDoc?.id==114 || tipoDoc?.id==115">
    <div class="d-flex flex-row input-modal-domus py-3 " >
        <div class="d-flex flex-column ">
            <label class="label-entity">Atributo</label>   
            <ng-select (change)="onChangeAtributo()" placeholder="Tipo" [(ngModel)]="atributo" [clearable]="false" [searchable]="false">
                <ng-option value="">
                    Seleccionar atributo
                    </ng-option>
                    <ng-option *ngFor="let atributo of tipoDoc?.atributes" value="{{atributo.cod}}">
                    <span >{{atributo.desc}}</span> 
                    </ng-option>
            </ng-select>                      
        </div>
        <div  class="d-flex flex-column mx-4">
            <label class="label-entity">Valor</label>
            <input type="text" class="input-modal" [(ngModel)]="valorAtributo">
        </div>

        <div class="d-flex flex-column align-items-start justify-content-end">
            <button class="btn-domus-agregar" type="button" (click)="agregarAtributo()"><fa-icon [icon]="faPlus"></fa-icon></button>
        </div>
    </div>
    <div class="table-responsive table-contact-modal" >
        <table class="table table-style m-0 table-font-size" >
            <thead>
                <th class="table-cell-ticket " scope="col">COD. ATRIBUTO</th>
                <th class="table-cell-ticket " scope="col">VALOR</th>
                <th class="table-cell-ticket " scope="col">MODO</th>
            </thead>
            <tbody *ngIf="atributos.length!=0">
                <tr *ngFor="let atributo of atributos">
                    <td>{{ atributo.cod }}</td>
                    <td>{{ atributo.value }}</td>
                    <td></td>
                </tr>
            </tbody>
            <tbody *ngIf="atributos.length==0">
                <tr>
                    <td colspan="3" align="center">
                        No hay atributos agregados.
                    </td>
                </tr>                                  
            </tbody>
        </table>
    </div>
</div>
<div  class="d-flex flex-row py-3 ml-auto">
    <div>
        <button class="btn btn-primary-portal  btn-primary btn-sm" style="text-indent: 0px;" (click)="agregarDocumento()" *ngIf="this.selectedFile">Guardar</button>
    </div>
</div>