<app-loadingSpinner [message]="loadingMessage" [show]="!showData || exporting"></app-loadingSpinner>


<div class="dashboard" *ngIf="!showSummary">
    <bpe-pre-content></bpe-pre-content>
</div>
<div class="dashboard" *ngIf="showSummary">
    <div class="dashboard-item">
        <fa-icon [icon]="faSquarePollVertical" class="fa-lg circle-icon text-warning"></fa-icon>
        <div class="dashboard-label">Encuestas Generadas</div>
        <div class="text-warning">
             
             {{ cantidadEvalauciones > 0 ? cantidadEvalauciones : ''}}  
        </div>
    </div>

    <div class="dashboard-item">

        <fa-icon [icon]="faExclamationTriangle" class="fa-lg circle-icon text-danger"></fa-icon>

        <div class="dashboard-label">Encuestas no respondidas Pendientes</div>
        <div class="text-danger"> 
            
            {{ cantidadEvaluacionesPendientes > 0 ? cantidadEvaluacionesPendientes : '' }}
        </div>
    </div>

    <div class="dashboard-item">
        <fa-icon [icon]="faFileCircleCheck" class="fa-lg circle-icon text-success"></fa-icon>
        
        <div class="dashboard-label">Encuestas Respondidas</div>
        <div class="text-success"> 
             
            {{ cantidadEvaluacionesRespondidas > 0 ? cantidadEvaluacionesRespondidas : '' }}
        </div>
    </div>
</div>
<br />

<div class="d-flex justify-content-end py-3">
    <button class="btn-btn btn-primary-portal btn-primary btn-sm mx-2" [disabled]="!showData" (click)="exportExcel()">
        <fa-icon [icon]="faDownload"></fa-icon>
        <span class="text-btn-main"> Exportar </span>
    </button>

</div>


<br />
<p-table [value]="evaluaciones" [tableStyle]="{'min-width': '50rem'}">
    <ng-template pTemplate="header">
        <tr>
            <th>Usuario</th>
            <th>Entidad</th>
            <th>Estado</th>
            <th>Promedio</th>
            <th>Fecha de ticket</th>
            <th>Fue escalado?</th>
            <th>Servicio</th>
            <th>Incidente</th>
            <th>Cumplemiento SLA</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
        <tr>
            <td> {{ data.usuario }}</td>
            <td> {{ data.entidad }}</td>
            <td> {{ data.estado }}</td>
            <td> {{ data.promedio }}</td>
            <td> {{ getFormatDate(data.fechaCreacionTicket) }}</td>
            <td> {{ data.EscalationStatusCodeText }}</td>
            <td> {{ data.IncidentServiceIssueCategoryID }}</td>
            <td> {{ data.ServiceIssueCategoryID }}</td>
            <td>
                <span *ngIf="data.SLA1Cumplido != '-'" class="badge" [ngClass]="{'badge-danger': data.SLA1Cumplido == 'NO', 'badge-success':data.SLA1Cumplido == 'SI'}">SLA1: {{ data.SLA1Cumplido }}</span> 
                <span *ngIf="data.SLA2Cumplido != '-'" class="badge" [ngClass]="{'badge-danger': data.SLA2Cumplido == 'NO', 'badge-success':data.SLA2Cumplido == 'SI'}">SLA2: {{ data.SLA2Cumplido }}</span>
            </td>
        </tr>
    </ng-template>
</p-table>
<div class="d-flex justify-content-end py-3 pr-5">
    <ngb-pagination *ngIf="evaluaciones.length != 0" [(page)]="page" [pageSize]="pageSize" [maxSize]="maxSize"
        [collectionSize]="collectionSize" (pageChange)="obtenerDatos()"> </ngb-pagination>
</div>