import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { lastValueFrom } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { UsersService } from './users.service';
import { Param } from '../interfaces/interfaces';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root'
})
export class MongoDBService {

  constructor(
    private requestSrv: RequestService,
    private usersSrv: UsersService,
    private encryptSrv: EncryptionService
  ) { }

  /** Método GET para obtener TODOS los datos de un endpoint en la API del portal de entidades
   * @param url dirección de la api
   * @param encrypted use TRUE para encriptar los datos con la clave publica
   * @return lista de objetos, si se produce algún error devuelve undefined
  */
  async getAll(endpoint: string) {

    // instancia objeto para guardar encabezados de peticion html
    const customHeaders: Param[] = [];
    // obtiene la clave publica
    const pub = this.encryptSrv.getPublicKey();
    // agrega la clave pública encriptada a los encabezados de la petición
    customHeaders.push({key:'pkey',value:pub});
    customHeaders.push({key:'x-user',value:this.usersSrv.getActualUser().id.toString()});

    // realiza petición
    const $source = this.requestSrv.getRequest('API',endpoint, customHeaders, null, true);
    const res = await lastValueFrom($source);

    // comprueba la respuesta
    if (res instanceof HttpResponse) {
      if ([200].includes(res.body.code)) {
        const encryptedData = res.body.data;
        // desencripta el dato obtenido
        const data = this.encryptSrv.decryptResultData(encryptedData);

        // devuelve el dato desencriptado
        return data;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  /** Método GET para obtener datos de la API del portal de entidades
   * @param url dirección de la api
   * @param paramsUrl parametros a enviar
   * @return lista de objetos que coincidan con los parámetros
  */
  async get(endpoint: string, paramsUrl: Param[], notEncrypted?: boolean) {
    const customHeaders: Param[] = [];
    // obtiene la clave publica
    const pub = this.encryptSrv.getPublicKey();
    // agrega la clave pública encriptada a los encabezados de la petición
    if(!notEncrypted) {
      customHeaders.push({key:'pkey',value:pub});
    }
    customHeaders.push({key:'Content-type',value:'application/x-www-form-urlencoded'});
    customHeaders.push({key:'x-user',value:this.usersSrv.getActualUser().id.toString()});
    const $source = this.requestSrv.getRequest('API',endpoint, customHeaders, paramsUrl, true);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      if ([200].includes(res.body.code)) {
        if(notEncrypted) {
          return res.body.data; 
        }
        const encryptedData = res.body.data;
        // desencripta el dato obtenido
        const data = this.encryptSrv.decryptResultData(encryptedData);
        // devuelve el dato desencriptado
        return data;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  /** Método POST para subir nuevos datos a traves de la API del portal del entidades
   * @param endpoint dirección de la api
   * @param payload datos a enviar
  */
  async create(endpoint: string, payload: any, notEncrypted?: boolean) {
    // instancia objeto para guardar encabezados de peticion html
    const customHeaders: Param[] = [];
    customHeaders.push({key:'x-user',value:this.usersSrv.getActualUser().id.toString()});
    let encryptedPayload = "";
    if(!notEncrypted){
      encryptedPayload = this.encryptSrv.encryptPayloadData(JSON.stringify(payload));
    }

    const $source = this.requestSrv.postRequest('API',endpoint, notEncrypted ? payload : {encrypted: encryptedPayload},customHeaders);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      return {posted: [201, 200].includes(res.status), body: res.body}
    } else {
      return {posted: false, body: undefined}
    }
  }

  /** Método PUT para actualizar datos a traves de la API del portal de entidades
   * @param endpoint dirección de la api
   * @param oid OID en MongoDB del objeto a actualizar
   * @param payload datos a enviar
   * @param propiedad de la colección a actualizar, enviar string vacío para actualizar toda la colección
  */
  async update(endpoint:string,  oid: string,payload: any,property?: string) {
    const customHeaders: Param[] = [];
    customHeaders.push({key:'x-user',value:this.usersSrv.getActualUser().id.toString()});
    endpoint = `${endpoint}/${oid}/${property ? property : ''}`;
    const encryptedPayload = this.encryptSrv.encryptPayloadData(JSON.stringify(payload));
    const $source = this.requestSrv.putRequest('API',endpoint, {encrypted: encryptedPayload},customHeaders);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      return {updated: [200].includes(res.status), body: res.body}
    } else {
      return {updated: false, body: undefined}
    }
  }

  /** Método DELETE para eliminar datos a traves de la API del portal de entidades
   * @param endpoint dirección de la api
   * @param paramsUrl parametros a enviar
  */
  async delete(endpoint: string, paramsUrl: Param[]) {
    const customHeaders: Param[] = [];
    // obtiene la clave publica
    const pub = this.encryptSrv.getPublicKey();
    // agrega la clave pública encriptada a los encabezados de la petición
    customHeaders.push({key:'pkey',value:pub});
    customHeaders.push({key:'Content-type',value:'application/x-www-form-urlencoded'});
    customHeaders.push({key:'x-user',value:this.usersSrv.getActualUser().id.toString()});
    const $source = this.requestSrv.deleteRequest('API',endpoint, customHeaders, paramsUrl, true);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      return res.body.code==200;
    } else {
      return false;
    }
  }


  async logAction(data: any){

    // instancia objeto para guardar encabezados de peticion html
    const customHeaders: Param[] = [];
    customHeaders.push({key:'x-user',value:this.usersSrv.getActualUser().id.toString()});
    const encryptedPayload = this.encryptSrv.encryptPayloadData(JSON.stringify(data));

    const $source = this.requestSrv.postRequest('API', `/auditoria/v2`, {encrypted: encryptedPayload},customHeaders);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      return {posted: [201].includes(res.status), body: res.body}
    } else {
      return {posted: false, body: undefined}
    }
  }


  /** Método DELETE para eliminar datos a traves de la API del portal de entidades
   * @param endpoint dirección de la api
   * @param paramsUrl parametros a enviar
  */
  async download(endpoint: string, paramsUrl: Param[]) {
    return undefined
  }

}
