export const DataUsuarios = {
    listado:{
        titulo:'Usuarios',
        roles: ['SEC_USUARIOS_GET']
    },
    crear: {
        titulo:'Nuevo Usuario',
        roles: ['SEC_USUARIOS_OPERACION']
    },
    editar: {
        titulo:'Editar Usuario',
        roles: ['SEC_USUARIOS_OPERACION']
    }
}
