import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Regex } from "src/app/constants/regex.const";

export const formulario = new FormGroup({
  email: new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(240),
    Validators.pattern(Regex.EMAIL),
  ]),
});
