import { Component, OnInit } from '@angular/core';
import { MongoDBService } from 'src/app/services/mongoDB.service';
import { faSpinner, faSquarePollVertical, faFileCircleCheck, faExclamationTriangle, faEllipsis, faChevronLeft, faChevronRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ReporteEvaluacion } from 'src/app/interfaces/interfaces';
import { WorkBook, WorkSheet, utils, writeFile } from 'xlsx';
import { AuditService } from 'src/app/services/audit.service';
import { LogService } from 'src/app/services/log.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { dateConvertToGrid } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-graficos-evaluacion',
  templateUrl: './graficos-evaluacion.component.html',
  styleUrls: ['./graficos-evaluacion.component.scss'],
})
export class GraficosEvaluacionComponent implements OnInit {

  showData = false;
  showSummary = false;
  exporting = false;
  loadingMessage = new BehaviorSubject("");

  faSquarePollVertical = faSquarePollVertical;
  faSpinner = faSpinner;
  faFileCircleCheck = faFileCircleCheck;
  faExclamationTriangle = faExclamationTriangle;
  faEllipsis = faEllipsis;
  faChevronLeft=faChevronLeft;
  faChevronRight=faChevronRight;
  faDownload=faDownload;


  evaluaciones: ReporteEvaluacion[] = [];

  cantidadEvalauciones = 0;
  cantidadEvaluacionesRespondidas = 0;
  cantidadEvaluacionesPendientes = 0;

  page=1;
  pageSize=10;
  maxSize=10;
  collectionSize=0

  constructor(private mongoSrv: MongoDBService,
    private auditSrv: AuditService,
    private logSrv: LogService) { }

  ngOnInit(): void {
    this.obtenerResumen()
    this.obtenerDatos();

  }

  async obtenerResumen() {
    this.showSummary = false;
    const resumen = await this.mongoSrv.getAll(`/evaluaciones/resumen-reportes`)
    if(resumen) {
      this.cantidadEvalauciones = resumen.generadas
      this.cantidadEvaluacionesPendientes = resumen.sinResponder
      this.cantidadEvaluacionesRespondidas = resumen.respondidas
    }
    this.showSummary = true;
  }
  async obtenerDatos() {
    this.loadingMessage.next("Obteniendo datos del reporte");
    this.showData = false;
    const response = await this.mongoSrv.getAll(`/evaluaciones/reportes?page=${this.page}&size=${this.pageSize}`)
    this.evaluaciones = response.dataToResponse;
    this.collectionSize = response.total;

    for (const evaluacion of this.evaluaciones) {
      this.formatSLA(evaluacion);
    }
    this.showData = true;
  
  }

  formatSLA(data) {
    if (data.SLA1Objetivo && data.SLA1Real && data.SLA2Objetivo && data.SLA2Real) {
      // Objetivo SLA 1
      let [horaObjetivo, minutoObjetivo] = data.SLA1Objetivo.split(":");
      horaObjetivo = horaObjetivo.length == 1 ?
        (horaObjetivo = "0" + horaObjetivo) :
        horaObjetivo;
      minutoObjetivo = minutoObjetivo.length == 1 ?
        (minutoObjetivo = "0" + minutoObjetivo) :
        minutoObjetivo;

      // Real SLA 1
      let [horaReal, minutoReal] = data.SLA1Real.split(":");
      horaReal = horaReal.length == 1 ? (horaReal = "0" + horaReal) : horaReal;
      minutoReal = minutoReal.length == 1 ? (minutoReal = "0" + minutoReal) : minutoReal;

      // Objetivo SLA 2
      let [horaObjetivo2, minutoObjetivo2] = data.SLA2Objetivo.split(":");
      horaObjetivo2 = horaObjetivo2.length == 1 ? (horaObjetivo2 = "0" + horaObjetivo2) : horaObjetivo2;
      minutoObjetivo2 = minutoObjetivo2.length == 1 ? (minutoObjetivo2 = "0" + minutoObjetivo2) : minutoObjetivo2;
      // Real SLA 2
      let [horaReal2, minutoReal2] = data.SLA2Real.split(":");
      horaReal2 = horaReal2.length == 1 ? (horaReal2 = "0" + horaReal2) : horaReal2;
      minutoReal2 = minutoReal2.length == 1 ? (minutoReal2 = "0" + minutoReal2) : minutoReal2;

      const calculoHoraReal = parseInt(horaReal) + parseFloat((minutoReal / 60).toFixed(2));
      const calculoHoraObjetivo = parseInt(horaObjetivo) + parseFloat((minutoObjetivo / 60).toFixed(2));

      if (calculoHoraObjetivo >= calculoHoraReal) {
        data.SLA1Cumplido = 'SI'
      } else {
        data.SLA1Cumplido = 'NO'
      }

      const calculoHoraReal2 = parseInt(horaReal2) + parseFloat((minutoReal2 / 60).toFixed(2));
      const calculoHoraObjetivo2 = parseInt(horaObjetivo2) + parseFloat((minutoObjetivo2 / 60).toFixed(2));

      if (calculoHoraObjetivo2 >= calculoHoraReal2) {
        data.SLA2Cumplido = 'SI'
      } else {
        data.SLA2Cumplido = 'NO'
      }
    } else {
      data.SLA1Cumplido = '-'
      data.SLA2Cumplido = '-'
    }
  }

  async exportExcel(){
    this.loadingMessage.next("Elaborando reporte de evaluaciones, esto puede tardar unos segundos.");
    this.exporting=true;
    
    this.showData = false;
    const filtered = await this.mongoSrv.getAll(`/evaluaciones/reportes-excel`)
    for (const evaluacion of filtered) {
      this.formatSLA(evaluacion);
    }

    const toExport: any = [];
    filtered.forEach(f => {
      const row = {
        "Usuario": f.usuario,
        "Entidad": f.entidad,
        "Estado": f.estado,
        "Promedio": f.promedio,
        "Fecha de ticket": this.getFormatDate(f.fechaCreacionTicket),
        "Fue escalado?": f.EscalationStatusCodeText,
        "Servicio": f.IncidentServiceIssueCategoryID,
        "Incidente": f.ServiceIssueCategoryID,
        "Cumplemiento SLA 1": f.SLA1Cumplido,
        "Cumplemiento SLA 2": f.SLA2Cumplido,
      }
      toExport.push(row);
    })
    setTimeout(() => {   
      try {
        // instancia el libro 
        const wb: WorkBook = utils.book_new();   
        const ws: WorkSheet = utils.json_to_sheet(toExport); 
        // // crea variables con tamaño de las columnas
        const wscols = [
          {wch:30}, //ID
          {wch:30}, //Razón Social
          {wch:16}, //Ciclo de Ventas
          {wch:16}, //Contacto
          {wch:16}, //Productos
          {wch:10}, //Plan USD
          {wch:10}, //Estado
          {wch:10}, //Promocion
          {wch:10},  //Rubro
          
        ];
        // asigna variable a la tabla
        ws['!cols'] = wscols;
        // genera el libro y agrega la hoja
        utils.book_append_sheet(wb, ws, 'Hoja1');
        // guarda el archivo
        writeFile(wb, 'reporte-evaluaciones.xlsx');
        
        this.auditSrv.logAction({modulo:'evaluaciones',accion:'Exportar datos de evaluaciones',detalle: `Cantidad de Evaluaciones: ${this.cantidadEvalauciones}, Pendientes: ${this.cantidadEvaluacionesPendientes}, Respondidas: ${this.cantidadEvaluacionesRespondidas}`});
      } catch (e:any) {
        const env = environment.production ? 'Producción' :'QA';
        this.logSrv.sendErrorNotification(env,e.toString(),'','','');
      }
      this.exporting=false;
      this.showData = true;
    },50)

  }

  getFormatDate(data) {
    return dateConvertToGrid(data)
  }
}
