import { Component } from "@angular/core";
import { Routes } from "@angular/router";
import { RouterModule } from "@angular/router";
import { ListadoReportesComponent } from "./listado-reportes/listado-reportes.component";
import { DataReportes } from "./reportes.routes.data";
import { PermGuardService } from "../../services/perm-guard.service";

export const Reportes_Routes: Routes = [
    {path: 'reportlist', component: ListadoReportesComponent, data: DataReportes.listado, canActivate:[PermGuardService]},
    {path: '', pathMatch: 'full', redirectTo:'reportlist'},
    {path: '**', pathMatch: 'full', redirectTo: 'reportlist'}
  ]
  ;
   
