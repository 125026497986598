export const DataOportunidad = {
    listado:{
        titulo:'Oportunidad Comercial',
        roles: ['SEC_OPORTUNIDAD_COMERCIAL_GET']
    },
    crear: {
        titulo:'Nueva oportunidad comercial',
        roles: ['SEC_OPORTUNIDAD_COMERCIAL_OPERACION']
    },
    detalle: {
        titulo:'Detalles oportunidad comercial',
        roles: ['SEC_OPORTUNIDAD_COMERCIAL_GET']
    }
}
