    
<div class="d-flex flex-row justify-content-center pt-3 mt-3">
    <span class="main-title">Cuentas bancarias</span>
</div>

 
 <!-- Tabla de cuentas bancarias -->
<div class="col-lg-12 col-sm-12" style="font-size: small;">

    <div class=" d-flex flex-row flex-wrap py-3">

        <table class="table-style table table-responsive">
            <tr class="py-2">
                <th scope="col"></th>
                <th scope="col">Transaccional</th>
                <th scope="col">Tipo de cuenta</th>
                <th scope="col">Nro de cuenta</th>
                <th scope="col">Entidad</th>
                <th scope="col">Titular</th>
                <th scope="col" *ngIf="requiereConfirmacion">Confirmado</th>
                

            </tr>

            <tbody style="border:none;"*ngIf="cuentasBancariasList.length!=0">
                <tr class="py-2 mt-2" *ngFor="let cuenta of cuentasBancariasList | slice: (page-1) * pageSize : page * pageSize">
                    <td></td>
                    <td>{{cuenta.DectipoCF_SDK}}</td>
                    <td>{{cuenta.CodTipCue_SDK}}</td>
                    <td>{{cuenta.NroCtaBancaria_SDK}}</td>
                    <td>{{cuenta.DescEnt_SDK}}</td>
                    <td>{{cuenta.TitularCta_SDK}}</td>
                    <td *ngIf="requiereConfirmacion"> {{ cuentaConfirmada ? 'Confirmada': 'No confirmada'}}</td>

                   
                </tr>
            </tbody>
            <tbody align="center" *ngIf="loading">
                <td colspan="5">
                    <span><fa-icon [icon]="faSpinner" animation="spin"></fa-icon></span>
                </td>
            </tbody>
            <tbody align="center" *ngIf="cuentasBancariasList.length==0 && !loading">
                <td colspan="5"> 
                    <span class="span-empty">No hay cuentas asociadas</span> 
                </td>
            </tbody>
            
        </table>
        <ngb-pagination *ngIf="cuentasBancariasList.length!=0"  [(page)]="page" [maxSize]="3" [pageSize]="pageSize" [collectionSize]="collectionSize" [boundaryLinks]="true"> </ngb-pagination>

    </div>
</div>
 <!-- Tabla de cuentas bancarias -->