<bpe-pre-content *ngIf="loading"></bpe-pre-content>

<label class="label-entity mt-5" *ngIf="show">Oportunidad Comercial</label>
<form *ngIf="show">
    <div class="d-flex flex-row flex-wrap" style="margin-left: -1rem;">

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col-12 pt-3">
                <label class="label-entity">ID</label>
            </div>
            <div class="col-12">
                <input readonly class="cursor-default ticket-resumen-input input-agregar" type="text"
                    value="{{this.ocId}}">
            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col pt-3">
                <label class="label-entity">Cliente</label>
            </div>
            <div class="col">
                <div class="d-flex flex-row ticket-resumen-input">
                    <input class="cursor-default ticket-resumen-input input-agregar" readonly type="text"
                        value="{{cliente.denominacion}}">
                </div>

            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">

            <div class="col pt-3">
                <label class="label-entity">Razon Social</label>
            </div>
            <div class="col">
                <div class="d-flex flex-row ticket-resumen-input">
                    <input readonly class="cursor-default ticket-resumen-input input-agregar " type=""
                        value="{{cliente.razonSocial}}">
                </div>

            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col-12 pt-3">
                <label class="label-entity">RUC</label>
            </div>
            <div class="col-12">
                <input readonly class="cursor-default ticket-resumen-input input-agregar" type="text"
                    value="{{cliente.ruc}}">
            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 justify-content-center">
            <div class="col pt-3">
                <label class="label-entity">Nombre</label>
            </div>
            <div class="col">
                <input readonly class="ticket-resumen-input input-agregar cursor-default" type="text"
                    placeholder="Nombre" value="{{oc.Name}}">
            </div>
        </div>


    </div>

    <app-tabla-productos-asociados-detalle [ocObjectId]="ocObjectId"
        [editable]="false"></app-tabla-productos-asociados-detalle>

</form>