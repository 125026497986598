<div class="row pt-4" *ngIf="!hideComponent">
<div class="col-12">
    <p-table [value]="dataList" [loading]="loading">
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="configuration.columnas.length">
                    <div class="d-flex justify-content-center py-2">
                        <span *ngIf="!loading" class="span-empty">
                            {{filtrado ? 'No se han encontrado datos.' : 'No existen datos.'}}
                        </span>
                        <fa-icon *ngIf="loading" [icon]="faSpinner" animation="spin"></fa-icon>

                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="header">
            <tr style="font-size: small;">
                <th *ngFor="let d of configuration.columnas">{{d.header}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rol>
            <tr style="font-size: small;">
                <td *ngFor="let d of configuration.columnas">
                    <div *ngIf="!d.actions && d.innerHTML" [innerHTML]="getData(rol, d)" class="preview-cell"></div>
                    <p *ngIf="!d.actions && !d.innerHTML">{{ getData(rol, d) }}</p>
                    <div *ngIf="d.actions && !d.innerHTML">
                        <ng-container *ngFor="let op of d.options">
                            <button pButton class="p-button-text p-button-plain" *ngIf="op.show ? op.show(rol) : true"
                                [pTooltip]="op.tooltip" tooltipPosition="top" (click)="emitAction(op.event, rol)">
                                <fa-icon [icon]="op.icon"></fa-icon>
                            </button>
                        </ng-container>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="d-flex justify-content-end py-3 pr-5">
        <ngb-pagination *ngIf="dataList.length != 0" [(page)]="page" [pageSize]="pageSize" [maxSize]="maxSize"
            [collectionSize]="collectionSize" (pageChange)="obtenerDatos()"> </ngb-pagination>
    </div>
</div>