import { Injectable } from '@angular/core';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor (
    private encryptSrv: EncryptionService
  ) {}

  /** Método para obtener un dato del almacenamiento local
   * @param itemName nombre del item a buscar
   * @returns valor del item, si no se encuentra devuelve undefined
   */
  get(itemName: string, sessionOnly?: boolean, encrypted?: boolean) {
    if (sessionOnly) {
      if (encrypted) {
        const encrypted = sessionStorage.getItem(itemName);
        if (encrypted) {
          try {
            // extrae el vector de inicializacion
            const encryptedWithIV = this.encryptSrv.extractIV(encrypted,32);
    
            // desencripta y devuelve el resultado
            return this.encryptSrv.decrypt(encryptedWithIV.encrypted,encryptedWithIV.iv);
          } catch {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return sessionStorage.getItem(itemName);
      }
    } else {
      const encrypted = localStorage.getItem(itemName);
      if (encrypted) {
        try {
          // extrae el vector de inicializacion
          const encryptedWithIV = this.encryptSrv.extractIV(encrypted,32);
          // desencripta y devuelve el resultado
          return this.encryptSrv.decrypt(encryptedWithIV.encrypted,encryptedWithIV.iv);
        } catch {
          return null;
        }
      } else {
        return null;
      }
    }
  }

  /** Método para guardar un dato en el almacenamiento local
   * @param itemName nombre del item a guardar
   * @param value valor del item a aguardar
   */
  save(itemName: string, value: string, sessionOnly?: boolean, encrypted?: boolean) {
    if (sessionOnly) {
      const nvalue = encrypted ? this.encryptSrv.encrypt(value) : value;
      sessionStorage.setItem(itemName, nvalue);
    } else {
      const encrypted = this.encryptSrv.encrypt(value);
      if (encrypted) {
        localStorage.setItem(itemName, encrypted);
      }
    }
  }

  /** Método para eliminar un dato del almacenamiento local
   * @param itemName nombre del item a eliminar
   */
  remove(itemName: string, session?: boolean) {
    if (session) {
      sessionStorage.removeItem(itemName);
    } else {
      localStorage.removeItem(itemName);    
    }
  }

  /** Método para vaciar el almacenamiento local */
  clear(session?: boolean) {
    if (session) {
      sessionStorage.clear();
    } else {
      localStorage.clear();
    }
  }
}
