<div class="background-login login container-fluid">
    <div class="flex-row h-100">
        <div class="flex-column d-flex justify-content-center">

            <div class="row row-cols-7 pt-5 justify-content-center">
                <div class="d-flex flex-column justify-content-center pointer">
                    <img src="../../../assets/imgs/Bancard-Logo.svg"  class="logo" alt="Logo Bancard" (click)="home()">
                </div>
            </div>

            <div class="row row-cols-7 mt-3 justify-content-center">

                <div class="d-flex flex-column justify-content-center">
                    <span class="text-bancard">Portal de entidades</span>
                </div>
                
            </div>

        </div> 
      
    <div class="flex-column d-flex  h-75 justify-content-center">
        <form  [formGroup]="formPassword" (ngSubmit)="createPassword()">

            <div class="row row-cols-4 justify-content-center" *ngIf="showInputPassActual">
                <div class="d-flex flex-column border-bottom">
                        
                    <div class="container-login-top">
                        <div class="d-flex flex-column">
                            <div class="d-flex flex-row">
                                <div class="d-flex column" style="width: 91%;">
                                    <input class="input-login" placeholder="Contraseña actual" autocomplete="off" required formControlName="password" style="width: 100%;" [type]="showPassActual ? 'text' : 'password'" readonly 
                                    onfocus="this.removeAttribute('readonly');">
                                    <!-- <app-field-error [field]="formPassword.controls['password']"></app-field-error> --> 
                                </div>
                                <div class="flex-column justify-content-center align-self-center" style="width: 50px;">
                                    <span><fa-icon class="puntero pointer" [icon]="showPassActual ? faEyePasswordActual : faEyeSlashPasswordActual" (click)="showActualPassword()" ></fa-icon></span>
                                </div>
                            </div>
                        </div>
                    </div>             
                </div> 
            </div>
                <div class="row row-cols-4 justify-content-center ">
                
                    <div class="d-flex flex-column border-bottom">
                        
                            <div class="container-login-middle" [ngClass]="{'input-login-top-radius': !showInputPassActual}">
                                <div class="d-flex flex-column">
                                    <div class="d-flex flex-row">
                                        <div class="d-flex column" style="width: 91%;">
                                            <input class="input-login" placeholder="Contraseña nueva" autocomplete="off" required formControlName="newPass" style="width: 100%;" [type]="showPassNueva ? 'text' : 'password'" readonly 
                                            onfocus="this.removeAttribute('readonly');">
                                            <!-- <app-field-error [field]="formPassword.controls['newPass']"></app-field-error> --> 
                                        </div>
                                        <div class="flex-column justify-content-center align-self-center" style="width: 50px;">
                                            <span><fa-icon class="puntero pointer" [icon]="showPassNueva ? faEyePasswordNueva : faEyeSlashPasswordNueva" (click)="showNewPassword()" ></fa-icon></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       
                        
                    </div>
                
                </div>
                <div class="row  row-cols-4 justify-content-center ">
                    <div class="container-login-bottom">
                        <div class="d-flex flex-column">
                            <div class="d-flex flex-row">
                                <div class="d-flex column" style="width: 91%;">
                                    <input class="input-login" placeholder="Confirmar contraseña nueva" autocomplete="off" required formControlName="confirmPass" style="width: 100%;" [type]="showPassNuevaRep ? 'text' : 'password'" readonly 
                                    onfocus="this.removeAttribute('readonly');">
                                    <!-- <app-field-error [field]="formPassword.controls['confirmPass']"></app-field-error> --> 
                                </div>
                                <div class="flex-column justify-content-center align-self-center" style="width: 50px;">
                                    <span><fa-icon class="puntero pointer" [icon]="showPassNuevaRep ? faEyePasswordNuevaRep : faEyeSlashPasswordNuevaRep" (click)="showConfirmPassword()" ></fa-icon></span>
                                </div>
                            </div>
                        </div>
                    </div>
            
                </div>

                <div class="row py-4 row-cols-4 justify-content-center">
                    <div class="d-flex flex-column">
                        <button class="btn-portal" type="submit">
                            <span class="text-btn-portal">
                                Cambiar contraseña
                            </span>
                        </button>

                    </div>

                </div>

        </form>
    </div>


    </div>
</div>