import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

  @Input() ticketsTotales=0;
  @Input() ticketsAbiertos=0;
  @Input() ticketsCerrados=0;

  documentStyle = getComputedStyle(document.documentElement);
  data; 
  options;

  ngOnInit(): void {
    this.data = {
      labels: ['Abiertos', 'Cerrados'],
      datasets: [
        {
          data: [this.ticketsAbiertos, this.ticketsCerrados],
          backgroundColor: [this.documentStyle.getPropertyValue('--blue'), this.documentStyle.getPropertyValue('--gris')],
          hoverBackgroundColor: [this.documentStyle.getPropertyValue('--hover-option'), this.documentStyle.getPropertyValue('--gris-span')]
        }
      ]    
    }
    this.options = {
      cutout: '60%'
    }
  }

}
