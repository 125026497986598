import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { FormGroup } from "@angular/forms";
import { formulario } from "./change-password.form";
import { Mensajes } from "src/app/constants/mensajes.const";
import { RecoveryService } from "../services/recovery.service";
import { StorageService } from "../services/storage.service";
import { environment } from "src/environments/environment";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-changepassword",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  formRecovery: FormGroup;
  procesando = false;

  constructor(
    private storageSrv: StorageService,
    private recoverySrv: RecoveryService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private title: Title
  ) {
    this.formRecovery = formulario;
    this.formRecovery.reset();
  }

  ngOnInit() {
    this.title.setTitle(
      `${this.activatedRoute.snapshot.data.titulo} - ${environment.app_name}`
    );
  }

  /** Método para cambiar la contraseña */
  async changePassword() {
    this.procesando = true;
    let token;
    this.activatedRoute.queryParams.subscribe((params) => {
      token = params["token"];
    });
    if (!token) {
      this.toastr.error(Mensajes.TOKEN_INVALIDO, "", {
        positionClass: "toast-top-center",
        progressBar: false,
      });
      this.procesando = false;
      return;
    } else if (
      this.formRecovery.value.newPass != this.formRecovery.value.confirmPass
    ) {
      this.toastr.error(Mensajes.CONTRASEÑAS_IGUALES, "", {
        positionClass: "toast-top-center",
        progressBar: false,
      });
      this.procesando = false;
      return;
    } else if (this.formRecovery.invalid) {
      this.toastr.error(Mensajes.RESTRICCIONES_CONTRASEÑA, "", {
        positionClass: "toast-top-center",
        progressBar: false,
      });
      this.procesando = false;
      return;
    } else {
      const req = {
        password: this.formRecovery.value.newPass,
      };
      const res = await this.recoverySrv.changePassword(token, req);
      
      if (res.code == 0) {
        this.toastr.success(Mensajes.CONTRASEÑA_CAMBIADA_CON_EXITO, "", {
          positionClass: "toast-top-center",
          progressBar: true,
        });
        this.procesando = false;
        this.router.navigate(["login"]);
      } else {
        const data = res.data;
        switch (data.code) {
          case 1:
            this.toastr.error(Mensajes.TOKEN_INVALIDO, "", {
              positionClass: "toast-top-center",
              progressBar: false,
            });
            break;
          case 2:
            this.toastr.error(Mensajes.TOKEN_INACTIVO, "", {
              positionClass: "toast-top-center",
              progressBar: false,
            });
            break;
          case 3: 
            this.toastr.error(Mensajes.TOKEN_EXPIRADO, "", {
              positionClass: "toast-top-center",
              progressBar: false,
            });
            break;
          default:
            this.toastr.error(Mensajes.ERROR_CAMBIAR_CONTRASEÑA, "", {
              positionClass: "toast-top-center",
              progressBar: false,
            });
            break;
        }
      }
      this.procesando = false;
      this.storageSrv.clear(true);
    }
  }
}
