import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { OcService } from 'src/app/services/oc.service';

@Component({
  selector: 'app-resumen-oc',
  templateUrl: './resumen-oc.component.html'
})
export class ResumenOcComponent implements OnInit {
  
  @Input("ocId") ocId: any;

  show = false;
  oc;
  ocObjectId;
  idCliente;
  cliente: any = {};
  loading = false;
  constructor(
    
    private ocService : OcService,
    private dataSrv: DataService,
  ) {}
  
  ngOnInit(): void {   
    this.getOc();
  }
  

  /** Método para obtener datos de la oc */
  async getOc(){

    if(this.ocId) {
      this.show = false;
      this.loading = true
      const oc = await this.ocService.getOportunityDetails(this.ocId);
      if (oc) {
        this.oc = oc;
        this.idCliente =this.oc.ProspectPartyID;
        this.ocObjectId = this.oc.ObjectID;
        await this.getClient(this.idCliente);
        this.show = true;
        
      }
      this.loading = false;
    }
  }

  /** Método para obtener datos de un cliente
   * @param clientID identificador del cliente
   */
  async getClient(clientID: string) {
    const client = await this.dataSrv.getClientByID(clientID);
    if (client) {
      this.cliente = client;    
    } 
  }
}
