<app-loadingSpinner [message]="loadingMessage" [show]="procesando"></app-loadingSpinner>
<div class="row px-3">
    <div class="col-12">
        <span class="page-route">Detalle de Producto</span>
    </div>
    <div class="col-12 py-3">
        <app-title></app-title>
    </div>
    <div class="col-lg-12 col-sm-12">
        <div class="card">
            <div class="card-body">



                <div class="row">
                    <div class="col-md-3 text-center" *ngIf="data?.logo">
                        <img [src]="data?.logo" class="thumbnail-img " alt="Foto de perfil">
                    </div>
                    <div [ngClass]="data?.logo ? 'col-md-9' : 'col-md-12'">
                        <!-- Aquí va la descripción -->
                        <h2>{{ data?.nombre }}</h2>
                        <h6 class="card-subtitle mb-2 text-muted">{{data?.subtitulo}}</h6>
                        <div [innerHTML]="data?.descripcion"></div>
                        
                    
                    </div>
                </div>

                <div class="d-flex justify-content-end py-3">
                      
                    <button class="btn btn-outline-sencondary" [routerLink]="backUrl">Volver atras</button>
                    
                    <button type="submit" class="btn btn-primary-portal btn-primary btn-sm mx-2" (click)="solictarProducto()">
                        <span class="text-btn-main">{{ procesando ? 'Procesando' : 'Solicitalo aquí'}}</span>
                        <fa-icon *ngIf="procesando" [icon]="faSpinner" animation="spin"></fa-icon>
                    </button>
                </div>
                <br />

                <bpe-grid *ngIf="mostrarProductosAsociados"
                    [configuration]="tableDocumentosConfig" [find]="filter" (actions)="clickInfo( $event )">
                </bpe-grid>
                

            </div>
        </div>
    </div>
</div>


<!----------------------------------------FORMULARIO DE NUEVO CONTACTO------------------------------------------->
<ng-template #nuevoContacto let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal ">Nuevo Contacto</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
            <form [formGroup]="contactForm" class="d-flex flex-row flex-wrap">
                <div class="col-3 justify-content-center">
                    <div class="col pt-3">
                        <label class="label-entity">Nombre (*)</label>
                    </div>
                    <div class="col">
                        <input class="input-modal input-agregar" formControlName="nombre" type="text" placeholder="" style="text-transform: uppercase;">
                        <app-field-error [field]="contactForm.controls['nombre']"></app-field-error>
                    </div>
                </div>
                <div class="col-3 justify-content-center">
                    <div class="col pt-3">
                        <label class="label-entity">Apellido (*)</label>
                    </div>
                    <div class="col">
                        <input class="input-modal input-agregar" formControlName="apellido" type="text" placeholder="" style="text-transform: uppercase;">
                        <app-field-error [field]="contactForm.controls['apellido']"></app-field-error>
                    </div>
                </div>
                <div class="col-3 justify-content-center">
                    <div class="col pt-3">
                        <label class="label-entity">Sexo</label>
                    </div>
                    <div class="col-12">
                        <ng-select placeholder="Seleccione" formControlName="genero"
                            [clearable]="false" [searchable]="true" class="select-modal">
                            <ng-option *ngFor="let genero of genders" value="{{genero.id}}">
                                <span>{{genero.descripcion}}</span>
                            </ng-option>
                        </ng-select> 
                        <app-field-error [field]="contactForm.controls['genero']"></app-field-error>
                    </div>
                </div>
                <div class="col-3 justify-content-center">
                    <div class="col pt-3">
                        <label class="label-entity">Tel&eacute;fono 1</label>
                    </div>
                    <div class="col">
                        <input class="input-modal input-agregar" formControlName="telefono" type="text" placeholder="">
                        <app-field-error [field]="contactForm.controls['telefono']"></app-field-error>
                    </div>
                </div>
                <div class="col-3 justify-content-center">
                    <div class="col pt-3">
                        <label class="label-entity">Tel&eacute;fono 2</label>
                    </div>
                    <div class="col">
                        <input class="input-modal input-agregar" formControlName="telefonoMovil" type="text" placeholder="">
                        <app-field-error [field]="contactForm.controls['telefonoMovil']"></app-field-error>
                    </div>
                </div>
                <div class="col-3 justify-content-center">
                    <div class="col pt-3">
                        <label class="label-entity">Correo electr&oacute;nico</label>
                    </div>
                    <div class="col">
                        <input class="input-modal input-agregar" readonly formControlName="email" type="text" placeholder="{{this.emailContactoPermanente}}">
                        <app-field-error [field]="contactForm.controls['email']"></app-field-error>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer" style="border: none;">
            <button (click)="crearContacto();" 
                class="btn btn-primary-portal btn-primary btn-sm">
                Crear y solicitar producto
            </button>
        </div>
</ng-template>
