import { Injectable } from '@angular/core';
import { Contacto, CuentaContacto, Param } from '../interfaces/interfaces';
import { RequestService } from './request.service';
import { MongoDBService } from './mongoDB.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private requestService: RequestService,
    private mongoDBSrv: MongoDBService
  ) { }

  async checkContact(accountID: string,contactID: string) {
    let asoc: CuentaContacto | undefined = undefined;
    const params: Param[]=[];
    params.push( {key:'ContactID', value: contactID} )
    params.push( {key:'AccountID', value: accountID} )
    const data = await this.mongoDBSrv.get(`/contactos/check`,params) 

    if(data ){
      const existe = data.length > 0
      if(existe){
        const c = data[0];
        asoc = {
          OID:c.ObjectID,
          idCuenta:c.AccountID,
          idContacto:c.ContactID,
          email:c.Email,
          rol:c.Z_ROLDECONTACTO_KUT
        }
      }
      return {asociacion:asoc,error:false};
    } else {
      return {asociacion:asoc,error:true}
    }
    
  }

  /** Método para asociar un contacto a una cuenta
   * @param accountID identificador de la cuenta
   * @param contactID identificador del contacto
   * @param contactEmail correo electrónico del contacto
   * @param rol rol del contacto en la asociación
   * @return TRUE si el asociación se realizó correctamente, sino FALSE
   */
  async associateContact(accountID: string,contactID: string,contactEmail: string,rol?: string) {
    const res = await this.mongoDBSrv.create(`/contactos/asociar`,{accountID, contactID, contactEmail, rol})
    if (res && res.posted && res.body.data) {
      return true;
    } else {
      return false
    }
  }
    
  /** Método para asignar rol al contacto en una asociación
   * @param associationID identificador de la asociación
   * @param rol rol a asignar
   * @return TRUE si el cambio se realizó correctamente, sino FALSE
   */
  async asignateRol(associationID: string,rol: string) {
    const res = await this.mongoDBSrv.create(`/contactos/asignar-rol`,{associationID, rol})
    if (res && res.posted && res.body.data) {
      return true;
    } else {
      return false
    }
  }

  /** Método para obtener datos de un contacto
   * @param contactID identificador del contacto
   * @return objeto con datos del contacto, si no se escuentra devuelve undefined
   */
  async getContactByID(idContacto:any) {

    const params: Param[]=[];
    params.push( {key:'id', value: idContacto} )
    const data = await this.mongoDBSrv.get(`/contactos/`,params) 

    if(data && data.length > 0 ){
      return data[0]
    }
    return undefined;
  }

  /** Método para obtener datos de contacto por email 
   * @param contactEmail correo electrónico del contacto
   * @param allAsociated TRUE para devolver todos los contactos asociados al correo electrónico, sino, devuelve solo el primero
   * @returns objeto con datos de contacto asociados al correo electrónico, si no encuentra devuelve vacío o undefined
  */
  async getContactByEmail(contactEmail:any, allAsociated?:boolean) {
    contactEmail = contactEmail.replaceAll('+','%2B');
   
    const params: Param[]=[];
    params.push( {key:'email', value: contactEmail} )
    params.push( {key:'status', value: 'activo'} )
    const data = await this.mongoDBSrv.get(`/contactos/`,params) 
    
    if(data && data.length > 0 ){
      if (allAsociated) {
        const contacts: Contacto[] = [];
        const results = data;
        results.forEach((result:any) => {
          const contacto : Contacto = {
            OID: result.ObjectID,
            id:result.ExternalID,
            idContacto: result.ContactID,
            nombre: result.Name,
            cliente: result.AccountFormattedName,
            cedula: result.Z_CEDULA_KUT,
            email: result.Email,
            telefono: result.Phone,
            celular: result.Mobile,
            estado:{id: result.StatusCode, descripcion: result.StatusCodeText}
          }
          contacts.push(contacto);
        });
        return contacts;
      } else {
        const results = data
        const result = results[0];
          const contacto : Contacto = {
            OID: result.ObjectID,
            id:result.ExternalID,
            idContacto: result.ContactID,
            nombre: result.Name,
            cliente: result.AccountFormattedName,
            cedula: result.Z_CEDULA_KUT,
            email: result.Email,
            telefono: result.Phone,
            celular: result.Mobile,
            estado:{id: result.StatusCode, descripcion: result.StatusCodeText}
          }
          return contacto;
      }
    } else {
      return []
    }
  }
  
}

