<div class="row px-3 new-entity ">
 
    <div class="col-12" >
        <a class="page-route puntero" (click)="usuarios()">Usuarios&nbsp;</a>
        <span class="page-route" >/&nbsp;</span>
        <span class="page-route" >Nuevo Usuario</span>

    </div>
 
    <div class="col-12 py-3">
        <app-title></app-title>
    </div>


     <form [formGroup]="formNewUser" (ngSubmit)="createUser()">

    <div class="col-12 pt-4"> 
        <label class="label-entity">Nombre</label> 
    </div> 
    <div class="col-12 ">  
        <input class="main-content-input input-agregar" formControlName="name" type="text" placeholder="Nombre"> 
        <app-field-error [field]="formNewUser.controls['name']"></app-field-error> 
    </div> 
 
    <div class="col-12 pt-4"> 
        <label class="label-entity">Apellido</label> 
    </div> 

    <div class="col-12 ">  
        <input class="main-content-input input-agregar" formControlName="surname" type="text" placeholder="Apellido"> 
        <app-field-error [field]="formNewUser.controls['surname']"></app-field-error> 
    </div> 


    <div class="col-12 pt-4">
        <label class="label-entity" >Email</label>
    </div>

    <div class="col-12 "> 
        <input class="main-content-input input-agregar" formControlName="email" type="text" readonly 
        onfocus="this.removeAttribute('readonly');" placeholder="Email">
        <app-field-error [field]="formNewUser.controls['email']"></app-field-error>
    </div>

    <div class="col-12 pt-4">
        <label class="label-entity" >Rol</label>
    </div>
    <div class="col-12 "> 
        <ng-select placeholder="Rol" [clearable]="false" [searchable]="false"
        formControlName="rol">
            <ng-option  value="">Rol</ng-option>
            <ng-option [value]="rol._id"
                *ngFor='let rol of rolesPosibles'>{{rol.codigo}}</ng-option>

        </ng-select>
        <app-field-error [field]="formNewUser.controls['rol']" ></app-field-error>
    </div>

    <div class="col-12 pt-4">
        <label class="label-entity" >Perfil/Área</label>
    </div>
    <div class="col-12 "> 
        <ng-select placeholder="Seleccione un Perfil" [clearable]="false" [searchable]="false"
        formControlName="perfil">
            <ng-option  value="">Seleccione un Perfil</ng-option>
            <ng-option [value]="perfil._id"
                *ngFor='let perfil of perfilesPosibles'>{{perfil.nombre}}</ng-option>

        </ng-select>
        <app-field-error [field]="formNewUser.controls['perfil']" ></app-field-error>
    </div>


    <div class=" col-12 py-4">
        <button type="submit" class="btn btn-primary-portal  btn-primary btn-sm" *hasPermission="['SEC_USUARIOS_OPERACION']">
            <span *ngIf="!creatingUser" class="text-btn-main">Crear</span>
            <span *ngIf="creatingUser" class="text-btn-main">Creando...</span>
            <fa-icon *ngIf="creatingUser" [icon]="faSpinner" animation="spin"></fa-icon>        </button>
    </div>
</form>

</div>