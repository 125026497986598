<!-- Spinner de carga -->
<app-loadingSpinner [message]="loadingMessage" [show]="loading"></app-loadingSpinner>

<!-- Formulario principal-->
<div class="row px-3" style="overflow-x: hidden;">
    <div class="col-12">
        <a class="page-route puntero" (click)="listadoOcs()">Oportunidad comercial&nbsp;</a>
        <span class="page-route">/&nbsp;</span>
        <span class="page-route">Nueva oportunidad comercial</span>
    </div>
    <div class="col-12 py-3 d-flex">
        <app-title></app-title>
    </div>
    <form class="col-12" [formGroup]="formNewOc" (ngSubmit)="validateOC()">
        <div class="d-flex flex-row flex-wrap">  
            <!-- Cliente -->
            <div class="col-12 px-0 justify-content-center"
                [ngClass]="this.formNewOc.value.cliente ? 'col-md-6 col-xl-5' : 'col-md-6'">
                <div class="col pt-3">
                    <label class="label-entity">Cliente  <b *ngIf="clientToCreate">(nuevo)</b></label>
                </div>
                <div class="col">
                    <div class="d-flex flex-row ticket-resumen-input">
                        <input class="cursor-default ticket-resumen-input input-agregar" 
                            readonly formControlName="cliente" type="text" placeholder="{{this.formNewOc.value.cliente}}">
                        <fa-icon class="align-self-center justify-content-center mx-2 pointer" 
                            [icon]="this.formNewOc.value.cliente ? faTimes : faExternalLinkAlt" 
                            (click)="this.formNewOc.value.cliente ? limpiarCliente() : open(cliente,'xl')">
                        </fa-icon>
                    </div>
                    <app-field-error [field]="formNewOc.controls['cliente']"></app-field-error>
                </div>
            </div>
            <!-- Razón social -->
            <div class="col-12 col-md-6 col-xl-5 p-0 justify-content-center" *ngIf="this.formNewOc.value.cliente">
                <div class="col pt-3">
                    <label class="label-entity">Razón Social</label>
                </div>
                <div class="col">
                    <div class="d-flex flex-row ticket-resumen-input">
                        <input readonly class="cursor-default ticket-resumen-input input-agregar " formControlName="razonSocial" type="text">
                    </div>
                </div>
            </div>
            <!-- RUC -->
            <div class="col-12 col-md-6 col-xl-2 p-0 justify-content-center" *ngIf="this.formNewOc.value.cliente">
                <div class="col-12 pt-3">
                    <label class="label-entity">RUC</label>
                </div>
                <div class="col-12">
                    <input readonly class="cursor-default ticket-resumen-input input-agregar" formControlName="ruc" type="text">
                </div>
            </div>
            <!-- Tipo empresa -->
            <div class="col-12 col-md-6 p-0 justify-content-center" 
                *ngIf="this.formNewOc.value.cliente"
                [ngClass]="this.formNewOc.value.contactoPrincipal ? 'col-xl-2' : 'col-xl-3'">
                <div class="col-12 pt-3">
                    <label class="label-entity">Tipo de empresa</label>
                </div>
                <div class="col-12">
                    <input readonly class="cursor-default ticket-resumen-input input-agregar" formControlName="tipoEmpresa" type="text">
                </div>
                <app-field-error [field]="formNewOc.controls['tipoEmpresa']"></app-field-error>
            </div>
            <!-- Contacto principal -->
            <div class="col-12 px-0 justify-content-center"
                *ngIf="this.formNewOc.value.cliente"
                [ngClass]="this.formNewOc.value.contactoPrincipal ? 'col-md-9 col-lg-5 col-xl-4' : 'col-md-6 col-lg-6 col-xl-4'">
                <div class="col pt-3">
                    <label class="label-entity">Contacto principal  <b *ngIf="contactToCreate">(nuevo)</b></label>
                </div>
                <div class="col">
                    <div class="d-flex flex-row ticket-resumen-input">
                        <input class="cursor-default ticket-resumen-input input-agregar" 
                            readonly formControlName="contactoPrincipal" type="text" placeholder="{{formNewOc.value.contactoPrincipal}}">
                        <fa-icon
                            class="align-self-center justify-content-center mx-2 pointer" 
                            [icon]="formNewOc.value.contactoPrincipal ? faTimes : faExternalLinkAlt" 
                            (click)="formNewOc.value.contactoPrincipal ? limpiarContacto() : open(contacto,'xl'); creatingPEPContact=false">
                        </fa-icon>
                    </div>
                    <app-field-error [field]="formNewOc.controls['contactoPrincipal']"></app-field-error>
                </div>
            </div>
            <!-- PEP -->
            <div class="col-12 px-0 justify-content-center" 
                    *ngIf="this.formNewOc.value.contactoPrincipal"
                    [ngClass]="this.formNewOc.value.contactoPrincipal ? 'col-md-3 col-lg-2 col-xl-2' : 'col-md-6 col-lg-2 col-xl-2'">
                <div class="col-12 pt-3">
                    <label class="label-entity">¿Tiene contacto PEP?</label>
                    <label class="label-entity">
                        <fa-icon 
                            class="align-self-center justify-content-center mx-2 pointer" 
                            [icon]="faQuestionCircle" (click)="open(infoPEP,'lg')">
                        </fa-icon>
                    </label>
                </div>
                <div class="col-12">
                    <div>
                        <ng-select [clearable]="false" [searchable]="false"
                            formControlName="pep" (change)="hasPEPContact($event)">
                            <ng-option value=false>
                                <span>No</span> 
                            </ng-option>
                            <ng-option value=true>
                                <span>Sí</span> 
                            </ng-option>
                        </ng-select>
                    </div>
                    <app-field-error [field]="formNewOc.controls['pep']"></app-field-error>
                </div>
            </div>
            <!-- Contacto pep -->
            <div class="col-12 col-md-6 col-lg-5 col-xl-4 px-0 justify-content-center"
                *ngIf="this.formNewOc.value.contactoPrincipal && formNewOc.controls['pep'].value=='true'">
                <div class="col pt-3">
                    <label class="label-entity">Contacto PEP</label>
                </div>
                <div class="col">
                    <div class="d-flex flex-row ticket-resumen-input">
                        <input class="cursor-default ticket-resumen-input input-agregar" readonly formControlName="contactoPEP" type="text">
                        <fa-icon 
                            class="align-self-center justify-content-center mx-2 pointer" 
                            [icon]="this.selectedPEPContact ? faTimes : faPlus" 
                            (click)="this.selectedPEPContact ? clearPEPContact() : creatingPEPContact=true; open(formularioContactoModal,'xl')">
                        </fa-icon>
                    </div>
                    <app-field-error [field]="formNewOc.controls['contactoPEP']"></app-field-error>
                </div>
            </div>
            <!-- Nombre -->
            <div class="col-12 p-0 justify-content-center" 
                [ngClass]="this.formNewOc.value.cliente ? this.formNewOc.value.contactoPrincipal ? formNewOc.controls['pep'].value=='true' ? 'col-md-6 col-lg-12 col-xl-6' : 'col-md-12 col-lg-5 col-xl-4' : 'col-md-5' : 'col-md-6'">
                <div class="col pt-3">
                    <label class="label-entity">Nombre</label>
                </div>
                <div class="col">
                    <input class="ticket-resumen-input input-agregar" formControlName="nombre" type="text">
                    <app-field-error [field]="formNewOc.controls['nombre']"></app-field-error>
                </div>
            </div>
        </div>   
        <div class="d-flex flex-row flex-wrap">  
            <app-tabla-productos-asociados 
                class="col-12 col-xl-6 px-0" 
                style="font-size:small" 
                (productList)="setProductList($event)"
                (productsForOnboarding)="setProductsForOnboarding($event)"
                (equipoList)="setEquiposPOS($event)">
            </app-tabla-productos-asociados>
            <div class="col-12 col-xl-6 px-0">
                <div class="col-12 pt-4">
                    <div class="d-flex align-items-center">
                        <label class="label-entity"><b>Cuentas bancarias</b></label>
                        <div class="d-flex align-items-center pointer ml-auto">
                            <button (click) = "openNewAccount('TD')" class="btn btn-primary-portal btn-primary btn-sm ml-auto" type="button">
                                <span class="text-btn-main" style="font-size: small;">{{bankAccountTD ? 'Modificar cuenta' : 'Asociar cuenta'}} TD</span>
                            </button>
                            <button *ngIf="!isPersona" (click) = "openNewAccount('TC')" class="btn btn-primary-portal btn-primary btn-sm ml-2" type="button">
                                <span class="text-btn-main" style="font-size: small;">{{bankAccountTC ? 'Modificar cuenta' : 'Asociar cuenta'}} TC</span>
                            </button>
                            <button *ngIf="tieneProductosSPI" (click) = "openNewAccount('SPI')" class="btn btn-primary-portal btn-primary btn-sm ml-2" type="button">
                                <span class="text-btn-main" style="font-size: small;">{{bankAccountSPI ? 'Modificar cuenta' : 'Asociar cuenta'}} SPI</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class=" d-flex flex-row flex-wrap py-3" style="font-size: small">
                        <table class="table-style table">
                            <tr class="table-line py-2">
                                <th scope="col"></th>
                                <th scope="col">Cuenta</th>
                                <th scope="col">Tipo de cuenta</th>
                                <th scope="col">Nro de cuenta</th>
                                <th scope="col">Entidad</th>
                                <th scope="col">Titular</th>
                                <th scope="col" *ngIf="!requiereConfirmacion">Validado <label class="mb-0">
                                    <fa-icon 
                                        class="align-self-center justify-content-center mx-2 pointer" 
                                        [icon]="faQuestionCircle" (click)="open(infoValidado,'lg')">
                                    </fa-icon>
                                </label></th>
                            </tr>
                            <tr class="py-2 mt-2" *ngIf="bankAccountTD">
                                <td><fa-icon class="pointer"  [icon]="faTimes" (click)="bankAccountTD=undefined"></fa-icon></td>
                                <td>TD</td>
                                <td>{{bankAccountTD.tipo.descripcion}}</td>
                                <td>{{bankAccountTD.numero}}</td>
                                <td>{{bankAccountTD.entidad.nombre}}</td>
                                <td>{{bankAccountTD.titular}}</td>
                                <td *ngIf="!requiereConfirmacion">{{bankAccountTD.validado ? 'Sí': 'No'}}</td>
                            </tr>
                            <tr class="py-2 mt-2" *ngIf="bankAccountTC">
                                <td><fa-icon class="pointer"  [icon]="faTimes" (click)="bankAccountTC=undefined"></fa-icon></td>
                                <td>TC</td>
                                <td>{{bankAccountTC.tipo.descripcion}}</td>
                                <td>{{bankAccountTC.numero}}</td>
                                <td>{{bankAccountTC.entidad.nombre}}</td>
                                <td>{{bankAccountTC.titular}}</td>
                                <td *ngIf="!requiereConfirmacion">{{bankAccountTC.validado ? 'Sí': 'No'}}</td>
                            </tr>
                            <tr class="py-2 mt-2" *ngIf="bankAccountSPI">
                                <td><fa-icon class="pointer"  [icon]="faTimes" (click)="bankAccountSPI=undefined"></fa-icon></td>
                                <td>SPI</td>
                                <td>{{bankAccountSPI.tipo.descripcion}}</td>
                                <td>{{bankAccountSPI.numero}}</td>
                                <td>{{bankAccountSPI.entidad.nombre}}</td>
                                <td>{{bankAccountSPI.titular}}</td>
                                <td *ngIf="!requiereConfirmacion">{{bankAccountSPI.validado ? 'Sí': 'No'}}</td>
                            </tr>
                            <tr align="center" *ngIf="!bankAccountTC && !bankAccountTD && !bankAccountSPI">
                                <td [attr.colspan]=" requiereConfirmacion ? 6 : 7"> 
                                    <span class="span-empty">No hay cuentas asociadas</span> 
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>  


        <!-------------------------------------------------ADJUNTOS-------------------------------------------------------------------------->
        <div class="d-flex flex-row flex-wrap mb-5">
            <div class="col-12 col-xl-6 px-0 mb-2" *ngIf="isUnipersonal">
                <label class="label-entity ml-3"><b>Adjuntos obligatorios</b></label>
                <div class="ml-3 flex-row flex-wrap align-items-center">
                    <div class="w100 d-flex align-items-center my-2">
                        <label class="my-auto" style="font-size: small; width: 250px">Cédula de identidad - Frente</label>
                        <label class="input-file-domus d-flex align-items-center justify-content-center" style="font-size: small;height: 2em; width: 150px">
                            Agregar adjunto
                            <input type="file" class="input-file-hidden" [(ngModel)]="file_ci_f" (change)="fileSelected($event,'ci_frente')" [ngModelOptions]="{standalone: true}">
                        </label>
                        <label class="my-auto ml-2" style="font-size: small; width: 250px">{{checkFileName(file_ci_f)}}</label>
                    </div>
                    <div class="w100 d-flex align-items-center mb-2">
                        <label class="my-auto" style="font-size: small; width: 250px">Cédula de identidad - Dorso</label>
                        <label class="input-file-domus d-flex align-items-center justify-content-center" style="font-size: small;height: 2em; width: 150px">
                            Agregar adjunto
                            <input type="file" class="input-file-hidden" [(ngModel)]="file_ci_d" (change)="fileSelected($event,'ci_dorso')" [ngModelOptions]="{standalone: true}">
                        </label>
                        <label class="my-auto ml-2" style="font-size: small; width: 250px">{{checkFileName(file_ci_d)}}</label>
                    </div>
                    <div class="w100 d-flex align-items-center mb-2">
                        <label class="my-auto" style="font-size: small; width: 250px">R.U.C</label>
                        <label class="input-file-domus d-flex align-items-center justify-content-center" style="font-size: small;height: 2em; width: 150px">
                            Agregar adjunto
                            <input type="file" class="input-file-hidden" [(ngModel)]="file_ruc" (change)="fileSelected($event,'ruc')" [ngModelOptions]="{standalone: true}">
                        </label>
                        <label class="my-auto ml-2" style="font-size: small; width: 250px">{{checkFileName(file_ruc)}}</label>
                    </div>
                    <div class="w100 d-flex align-items-center mb-2" style="font-size: small; width: 500px">
                        <div class="d-flex align-items-start filtro-ticket" > 
                            <label class="switch my-auto">
                                <input type="checkbox" [(ngModel)]="tieneContrato" [ngModelOptions]="{standalone: true}">
                                <span class="slider round"></span>
                            </label>
                            <label class="mr-auto my-auto" style="text-indent: 7px; color: var(--blue);font-size: small;">¿Tiene contrato?</label>
                        </div>
                    </div>
                    <div class="w100 d-flex align-items-center mb-2" *ngIf="tieneContrato">
                        <label class="my-auto" style="font-size: small; width: 250px">Contrato <a [href]=contract_url target="_blank">(descargar modelo)</a></label>
                        <label class="input-file-domus d-flex align-items-center justify-content-center" style="font-size: small;height: 2em; width: 150px">
                            Agregar adjunto
                            <input type="file" class="input-file-hidden" [(ngModel)]="file_contrato" (change)="fileSelected($event,'contrato')" [ngModelOptions]="{standalone: true}">
                        </label>
                        <label class="my-auto ml-2" style="font-size: small; width: 250px">{{checkFileName(file_contrato)}}</label>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 pt-4">
                <div class="d-flex align-items-center">
                    <label class="label-entity"><b>Otros Adjuntos</b></label>
                    <div class="d-flex align-items-center pointer ml-auto" *hasPermission="['SEC_PARAMETRICOS_GET']">
                        <button
                            class="btn btn-primary-portal btn-primary btn-sm mr-2" type="button" (click)="open(newAttachment,'xl'); getFileTypes()">
                            <span class="text-btn-main" style="font-size: small;"><fa-icon [icon]="faPlus"></fa-icon> Asociar adjunto</span>
                        </button>
                    </div>
                </div>
                <table class="table table-style my-3" style="font-size:small" >
                    <tr class="table-line py-2">
                        <th style="text-align:center" scope="col">Tipo</th>
                        <th style="text-align:center" scope="col">Nombre del Archivo</th>
                        <th style="text-align:center" scope="col">Fecha</th>
                        <th style="text-align:center" scope="col">Tamaño</th>
                        <th style="text-align:center" scope="col"></th>
                    </tr>
                    <tr class="py-2 align-items-center mt-2" *ngFor="let documento of otherFiles">
                        <td style="text-align:center">{{documento.tipo!.descripcion}}</td>
                        <td style="text-align:center">{{documento.titulo}}</td>
                        <td style="text-align:center">{{documento.fechaCreacion | date:'dd/MM/YYYY'}}</td>
                        <td style="text-align:center">{{getFileSize(documento)}}</td>
                        <td style="text-align:center">
                            <button class="btn btn-primary-portal  btn-primary btn-sm" (click)="removeFile(documento)" style="min-width: 0px">
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>
                        </td>
                    </tr>
                    <tr align="center" *ngIf="!loading && otherFiles.length==0">
                        <td colspan="10" align="center">
                            <span class="span-empty">No hay adjuntos asociados.</span>
                        </td>
                    </tr> 
                </table>
            </div>
        </div >
        <div class="col-12 d-flex mt-5" *hasPermission="['SEC_ONBOARDING_OPERACION']">
            <span class="page-route puntero mx-auto my.auto" >
                <label (click)="open(infoOnboarding,'lg')" class="requirements">
                    Onboarding automático {{onboardingCheckListChecked}}/{{onboardingCheckList.length}}
                </label> 
                <span *hasPermission="['SEC_ALTAAGIL_OPERACION']" style="margin: 0 10px;">|</span>
                <label *hasPermission="['SEC_ALTAAGIL_OPERACION']" (click)="open(infoAltaAgil,'lg')">
                    <label  class="requirements">
                        Alta Ágil {{altaAgilCheckListChecked}}/{{altaAgilCheckList.length}}
                    </label>
                </label>
            </span>
        </div>   
        <div class="col-12 d-flex mt-2">
            <button class="btn btn-primary-portal btn-primary btn-sm mx-auto my.auto" 
                type="submit" 
                *hasPermission="['SEC_OPORTUNIDAD_COMERCIAL_OPERACION']">
                Confirmar
            </button>
        </div>
    </form>
</div>  

<!-- Modales --> 
<ng-template #cliente let-modal>
    <div class="modal-header" style="border: none;">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
        <div class="row row-cols-lg-1 py-2  justify-content-center">
            <div class="col-lg-4 text-center text-modal d-flex flex-column justify-content-center">
                <form [formGroup]="rucForm" (ngSubmit)="searchClients(clientesAsociados,sinClientesAsociados); modal.dismiss()">
                    <div class="d-flex flex-column justify-content-center text-center">
                        <span>Ingrese el número de RUC o cédula</span>
                        <input formControlName="ruc" class="mt-2 input-modal input-agregar text-center" type="text" placeholder="">
                    </div>
                </form>
            </div>
        </div>
        <div class="row row-cols-lg-1 py-3 justify-content-center">
            <div class="col-lg-8 text-center text-modal">
                <div class=" d-flex row text-center justify-content-center">
                    <span class="span-modal"></span>
                </div>
                <div class="d-flex row text-center justify-content-center">
                    <span class="span-modal id-modal"></span>
                </div>
            </div>
        </div>
        <div class="row row-cols-4 row-cols-lg-4 mx-0  py-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <button [disabled]="rucForm.invalid" (click)="searchClients(clientesAsociados,sinClientesAsociados); modal.dismiss()" type="button" class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept">
                        <span>Consultar</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #clientesAsociados let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal ">Clientes asociados al RUC: "{{rucPermanente}}"</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="ml-3 d-flex">
        <button *ngIf="!disableSucursalButton" type="button" class="btn btn-primary-portal  btn-primary btn-sm" (click)="open(formularioClienteModal,'xl','nuevaSucursal'); modal.dismiss()">Nueva Sucursal</button>
    </div> 
    <div class="modal-body modal-background"> 
        <table class="d-flex flex-column table-style-modal table-style">
            <thead class="d-flex table-line align-items-center py-2">
                <th class="table-cell-s table-cell text-center"></th>
                <th class="table-cell">ID</th>
                <th class="table-cell">Tipo cliente</th>
                <th class="table-cell">RUC</th>
                <th class="table-cell-lg table-cell">Denominacion comercial</th>
                <th class="table-cell">Razon Social</th>
                <th class="table-cell">Rubro</th>
                <th class="table-cell">Código</th>
                <th class="table-cell">Rol</th>
                <th class="table-cell">Estado procesadora</th>
                <th class="table-cell">Estado Switch</th>
            </thead>
            <tbody>
                <tr class="py-2 d-flex mt-2" *ngFor="let cliente of clients | slice: (page-1) * pageSize : page * pageSize">
                    <td class="table-cell-s table-cell text-center">
                        <fa-icon class="pointer" (click)="selectClient(cliente); modal.dismiss()" [icon]="faCheck"></fa-icon>
                    </td>
                    <td class="table-cell">{{cliente.id}}</td>
                    <td class="table-cell">{{cliente.tipoCliente.descripcion}}</td>
                    <td class="table-cell">{{cliente.ruc}}</td>
                    <td class="table-cell table-cell-lg">{{cliente.denominacion}}</td>
                    <td class="table-cell">{{cliente.razonSocial}}</td>
                    <td class="table-cell">{{cliente.rubro.descripcion}}</td>
                    <td class="table-cell">{{cliente.idCliente}}</td>
                    <td class="table-cell">{{cliente.rol.descripcion}}</td>
                    <td class="table-cell">{{cliente.estadoProcesadora}}</td>
                    <td class="table-cell">{{cliente.estadoSwitch}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer" style="border: none;">
        <ngb-pagination [(page)]="page" [maxSize]="3" [pageSize]="pageSize" [collectionSize]="collectionSize" [boundaryLinks]="true"> </ngb-pagination>
    </div>
</ng-template>
<ng-template #sinClientesAsociados let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal ">Clientes asociados al RUC: "{{rucPermanente}}"</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <label class="label-entity mx-auto text-secondary">No se encontraron clientes asociados a este número de RUC</label>
    <div class="mx-auto d-flex">
        <button type="button" class="btn btn-primary-portal btn-primary mr-2" (click)="open(formularioClienteModal,'xl','nuevoClientePersona'); modal.dismiss()">Nuevo cliente Persona</button>
        <button type="button" class="btn btn-primary-portal btn-primary btn-sm mr-2" (click)="open(formularioClienteModal,'xl','nuevoClienteComercio'); modal.dismiss()">Nuevo cliente Comercio</button>
    </div> 
    <div class="modal-footer" style="border: none;"></div>
</ng-template>
<ng-template #formularioClienteModal let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal">{{this.newClientForm.get('casaMatriz')!.value=='N' ? 'Nueva Sucursal' : 'Nuevo Cliente'}}</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
        <form [formGroup]="newClientForm" class="d-flex flex-row flex-wrap">
            <!-- Razón Social / Nombre-->
            <div class="col-12 col-lg-6 col-xl-5 px-0 justify-content-center"> 
                <div class="col px-2 pt-3">
                    <label class="label-entity">Nombre (Razón Social)</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" 
                        formControlName="razonSocial" 
                        type="text" placeholder="" 
                        style="text-transform: uppercase;" 
                        maxlength="30" (ngModelChange)="checkCaracteres('razonSocial')">
                    <span class="custom-control-inline warning-feedback" 
                        *ngIf="caracteresRestantesRazonSocial < 5 && newClientForm.controls['razonSocial'].dirty"> 
                        {{caracteresRestantesRazonSocial}} caracteres restantes.
                    </span>
                    <app-field-error [field]="newClientForm.controls['razonSocial']"></app-field-error>
                </div>
            </div>
            <!-- Denominación-->
            <div class="col-12 col-lg-6 col-xl-5 px-0 justify-content-center">
                <div class="col px-2 pt-3">
                    <label class="label-entity">Denominación Comercial</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" 
                        formControlName="denominacionComercial" 
                        type="text" placeholder="" 
                        style="text-transform: uppercase;" 
                        maxlength="40" (ngModelChange)="checkCaracteres('denominacionComercial')">
                    <span class="custom-control-inline warning-feedback " 
                        *ngIf="caracteresRestantesDenominacionComercial < 5 && newClientForm.controls['denominacionComercial'].dirty"> 
                        {{caracteresRestantesDenominacionComercial}} caracteres restantes. 
                    </span>
                    <app-field-error [field]="newClientForm.controls['denominacionComercial']"></app-field-error>
                </div>
            </div>
            <!-- RUC -->
            <div class="col-12 col-lg-6 col-xl-2 px-0 justify-content-center">
                <div class="col px-2 pt-3">
                    <label class="label-entity">RUC</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" readonly formControlName="ruc" type="text" placeholder="{{rucPermanente}}">
                </div>
            </div>
            <!-- Tipo de cliente-->
            <div class="col-12 col-lg-6 col-xl-2 px-0 justify-content-center"> 
                <div class="col px-2 pt-3">
                    <label class="label-entity">Tipo cliente</label>
                </div>
                <div class="col px-1">
                    <ng-select placeholder="Tipo de cliente" [clearable]="false" [searchable]="false"
                        formControlName="tipoCliente" class="select-modal" [readonly]="disableClientTypeSelection">
                        <ng-option value="{{tipo.id}}" *ngFor="let tipo of clientTypes">
                            <span>{{tipo.descripcion}}</span> 
                        </ng-option>
                    </ng-select> 
                </div>
            </div>
            <!-- Tipo de empresa-->
            <div class="col-12 col-lg-6 col-xl-2 px-0 justify-content-center">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">Tipo de empresa</label>
                </div>
                <div class="col-12 px-1">
                    <ng-select placeholder="Tipo de empresa" [clearable]="false" [searchable]="true"
                        formControlName="tipoEmpresa" class="select-modal" [readonly]="newClientForm.controls['tipoCliente'].value=='ZPER'">
                        <ng-option value="{{tipo.id}}" *ngFor="let tipo of businessTypes">
                            <span>{{tipo.descripcion}}</span> 
                        </ng-option>
                    </ng-select> 
                    <app-field-error [field]="newClientForm.controls['tipoEmpresa']"></app-field-error>
                </div>
            </div>
            <!-- Rubro -->
            <div class="col-12 col-lg-6 col-xl-4 px-0 justify-content-center">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">Rubro</label>
                </div>
                <div class="col-12 px-1">
                    <ng-select placeholder="Rubro" [clearable]="false" [searchable]="true"
                        formControlName="rubro" class="select-modal" (change)="updateMCCs($event)">
                        <ng-option value="{{rubro.id}}" *ngFor="let rubro of businessLines">
                            <span>{{rubro.descripcion}}</span> 
                        </ng-option>
                    </ng-select>    
                    <app-field-error [field]="newClientForm.controls['rubro']"></app-field-error>
                </div>
            </div>
            <!-- MCC -->
            <div class="col-12 col-lg-6 col-xl-4 px-0 justify-content-center">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">Área</label>
                </div>
                <div class="col-12 px-1">
                    <ng-select placeholder="Área" [clearable]="false" [searchable]="true"
                        formControlName="mcc" class="select-modal">
                        <ng-option value="{{mcc.id}}" *ngFor="let mcc of mccs">
                            <span>{{mcc.descripcion}}</span> 
                        </ng-option>
                    </ng-select>    
                    <app-field-error [field]="newClientForm.controls['mcc']"></app-field-error>
                </div>
            </div>
            <!-- Calle -->
            <div class="col-12 col-lg-7 px-0 justify-content-center">
                <div class="col px-2 pt-3">
                    <label class="label-entity">Calle</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" 
                        formControlName="calle" 
                        type="text" 
                        maxlength="40" (ngModelChange)="checkCaracteres('calle')" 
                        style="text-transform: uppercase;">
                    <span class="custom-control-inline warning-feedback" 
                        *ngIf="caracteresRestantesAddress < 15 && newClientForm.controls['calle'].dirty"> 
                        {{caracteresRestantesAddress}} caracteres restantes.
                    </span>
                    <app-field-error [field]="newClientForm.controls['calle']"></app-field-error>
                </div>
            </div>
            <!-- Número -->
            <div class="col-12 col-lg-1 px-0 justify-content-center">
                <div class="col px-2 pt-3">
                    <label class="label-entity">Número</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" formControlName="numeroCalle" type="text" placeholder="">
                    <app-field-error [field]="newClientForm.controls['numeroCalle']"></app-field-error>
                </div>
            </div>
            <!-- Código postal -->
            <div class="col-12 col-lg-2 px-0 justify-content-center">
                <div class="col px-2 pt-3">
                    <label class="label-entity">Código Postal</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" formControlName="codigoPostal" type="text" placeholder="">
                    <app-field-error [field]="newClientForm.controls['codigoPostal']"></app-field-error>
                </div>
            </div>
            <!-- Pais -->
            <div class="col-12 col-lg-6 col-xl-2 px-0 justify-content-center">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">País</label>
                </div>
                <div class="col-12 px-1">
                    <ng-select #paisInput placeholder="País" [clearable]="false" [searchable]="false"
                        formControlName="pais" class="select-modal">
                        <ng-option value="{{pais.id}}" *ngFor="let pais of countries"> 
                            <span>{{pais.descripcion}}</span> 
                        </ng-option>
                    </ng-select> 
                    <app-field-error [field]="newClientForm.controls['pais']"></app-field-error>
                </div>
            </div>
            <!-- Departamento -->
            <div class="col-12 col-lg-6 col-xl-3 px-0 justify-content-center">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">Departamento</label>
                </div>
                <div class="col-12 px-1">
                    <ng-select #departamento placeholder="Departamento" [clearable]="false" [searchable]="true"
                        formControlName="departamento" class="select-modal" (change)="getCities($event)">
                        <ng-option value="{{departamento.id}}" *ngFor="let departamento of states | sort:'asc':'descripcion' | StateFilter: newClientForm.controls['pais'].value">
                            <span>{{departamento.descripcion}}</span> 
                        </ng-option>
                    </ng-select> 
                    <app-field-error [field]="newClientForm.controls['departamento']"></app-field-error>
                </div>
            </div>
            <!-- Ciudad -->
            <div class="col-12 col-lg-6 col-xl-3 px-0 justify-content-center">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">Ciudad</label>
                </div>
                <div class="col-12 px-1">
                    <ng-select placeholder="Ciudad" [clearable]="false" [searchable]="true" 
                        formControlName="ciudad" class="select-modal" (change)="getNeighborhoods($event); getZones($event)">
                        <ng-option *ngFor="let ciudad of cities | sort:'asc':'descripcion'" value="{{ciudad.id}}"> 
                            <span>{{ciudad.descripcion}}</span> 
                        </ng-option>
                    </ng-select> 
                    <app-field-error [field]="newClientForm.controls['ciudad']"></app-field-error>
                </div>
            </div>
            <!-- Barrio -->
            <div #barrio class="col-12 col-lg-6 col-xl-3 px-0 justify-content-center">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">Barrio</label>
                </div>
                <div class="col-12 px-1">
                    <ng-select placeholder="Barrio" [clearable]="false" [searchable]="true"
                        formControlName="barrio" class="select-modal">
                        <ng-option *ngFor="let barrio of neighborhoods | sort:'asc':'descripcion'" value="{{barrio.id}}"> 
                            <span>{{barrio.descripcion}}</span> 
                        </ng-option>
                    </ng-select> 
                    <app-field-error [field]="newClientForm.controls['barrio']"></app-field-error>
                </div>
            </div>
            <!-- Zona -->
            <div class="col-12 col-lg-6 col-xl-3 px-0 justify-content-center">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">Zona</label>
                </div>
                <div class="col-12 px-1">
                    <ng-select placeholder="Zona" [clearable]="false" [searchable]="true"
                        formControlName="zona" class="select-modal">
                        <ng-option *ngFor="let zona of zones | sort:'asc':'descripcion'" value="{{zona.id}}"> 
                            <span>{{zona.descripcion}}</span> 
                        </ng-option>
                    </ng-select> 
                    <app-field-error [field]="newClientForm.controls['zona']"></app-field-error>
                </div>
            </div>
            <!-- Mapa -->
            <div class="col-12 col-lg-6 px-0 justify-content-center" *ngIf="!newClientForm.value.latitud">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">Ubicación del cliente</label>
                </div>
                <div class="col px-1">
                    <button type="button" class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept" style="width: 120px;" (click)="open(mapa,'lg')">
                        Abrir mapa
                    </button>
                </div>
            </div>
            <!-- Latitud -->
            <div class="col-12 col-lg-6 col-xl-3 px-0 justify-content-center" *ngIf="newClientForm.value.latitud">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">Latitud</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" formControlName="latitud" type="text" placeholder=""
                        (keypress)="checkNumber($event)"
                        (keyup)="transformNumber($event,'2.7-7',true,true)">
                    Modificar <fa-icon class="align-self-center justify-content-center mx-2 pointer" 
                        [icon]="faExternalLinkAlt" 
                        (click)="open(mapa,'lg')">
                    </fa-icon>
                    Limpiar <fa-icon class="align-self-center justify-content-center mx-2 pointer" 
                        [icon]="faTimes" 
                        (click)="newClientForm.get('latitud')?.patchValue(undefined)">
                    </fa-icon>
                    <app-field-error [field]="newClientForm.controls['latitud']"></app-field-error>
                </div>
            </div>
            <!-- Longitud -->
            <div class="col-12 col-lg-6 col-xl-3 px-0 justify-content-center" *ngIf="newClientForm.value.latitud">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">Longitud</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" formControlName="longitud" type="text" placeholder=""
                        (keypress)="checkNumber($event)"
                        (keyup)="transformNumber($event,'2.7-7',true,true)">
                    <app-field-error [field]="newClientForm.controls['longitud']"></app-field-error>
                </div>
            </div>
            <!-- Teléfono -->
            <div class="col-12 col-lg-6 col-xl-3 px-0 justify-content-center">
                <div class="col px-2 pt-3">
                    <label class="label-entity">Teléfono</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" formControlName="telefono" type="text" placeholder="">
                    <app-field-error [field]="newClientForm.controls['telefono']"></app-field-error>
                </div>
            </div>
            <!-- Celular-->
            <div class="col-12 col-lg-6 col-xl-3 px-0 justify-content-center"> 
                <div class="col px-2 pt-3">
                    <label class="label-entity">Celular</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" formControlName="telefonoMovil" type="text" placeholder="">
                    <app-field-error [field]="newClientForm.controls['telefonoMovil']"></app-field-error>
                </div>
            </div>
            <!-- Correo electrónico-->
            <div class="col-12 col-lg-6 col-xl-3 px-0 justify-content-center">
                <div class="col px-2 pt-3">
                    <label class="label-entity">Correo electrónico</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" formControlName="mail" type="text" placeholder="" style="text-transform: lowercase;">
                    <app-field-error [field]="newClientForm.controls['mail']"></app-field-error>
                </div>
            </div>
            <!-- Sitio web-->
            <div class="col-12 col-lg-6 col-xl-3 px-0 justify-content-center">
                <div class="col px-2 pt-3">
                    <label class="label-entity">Sitio Web</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" formControlName="web" type="text" placeholder="">
                    <app-field-error [field]="newClientForm.controls['web']"></app-field-error>
                </div>
            </div>
            <!-- Promedio tickets -->
            <div class="col-12 col-lg-6 col-xl-3 px-0 justify-content-center">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">Cantidad de ventas promedio mensual</label>
                </div>
                <div class="col-12 px-1">
                    <input class="input-modal input-agregar" 
                        formControlName="promedioTickets" type="text" 
                        (keyup)="transformNumber($event,'1.0-0')" 
                        placeholder="">
                    <app-field-error [field]="newClientForm.controls['promedioTickets']"></app-field-error>
                </div>
            </div>
            <!-- Promedio monto -->
            <div class="col-12 col-lg-6 col-xl-3 px-0 justify-content-center">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">Importe de ventas Gs. promedio mensual</label>
                </div>
                <div class="col-12 px-1">
                    <input class="input-modal input-agregar" 
                        formControlName="montoPromedio" type="text" 
                        (keyup)="transformNumber($event,'1.0-0')" 
                        placeholder="">
                    <app-field-error [field]="newClientForm.controls['montoPromedio']"></app-field-error>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="border: none;">
        <button (click)="confirmNewCliente();" 
            class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept" style="width: 70px;">
            {{editClient ? 'Guardar' : 'Crear'}}
        </button>
    </div>
</ng-template>
<ng-template #contacto let-modal>
    <div class="modal-header" style="border: none;">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
        <div class="row py-2 justify-content-center">
            <div class="text-center text-modal d-flex flex-column justify-content-center">
                <form [formGroup]="contactForm" (ngSubmit)="searchContacts()">
                    <div class="d-flex flex-column justify-content-center text-center">
                        <span>Ingrese el email del contacto</span>
                        <input formControlName="email" class="mt-2 input-modal input-agregar text-center" type="text" placeholder="" style="text-transform: lowercase;">
                    </div>
                    <app-field-error [field]="contactForm.controls['email']" style="font-size:14px"></app-field-error>
                    <div class="row row-cols-lg-1 py-3 justify-content-center">
                        <div class="col-lg-8 text-center text-modal">
                            <div class=" d-flex row text-center justify-content-center">
                                <span class="span-modal"></span>
                            </div>
                            <div class="d-flex row text-center justify-content-center">
                                <span class="span-modal id-modal"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 py-3 justify-content-center">
                        <div>
                            <button type="submit" class="btn btn-primary-portal btn-primary btn-sm py-1">
                                <span style="font-size:18px">Consultar</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #contactosAsociados let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal ">Contactos con el email: "{{emailContactoPermanente}}"</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
        <div class="table-responsive table-contact-modal">
            <table class="table-style table">
                <thead class="py-2 ">
                    <th></th>
                    <th>ID de contacto</th>
                    <th>Nombre</th>
                    <th>Cliente</th>
                    <th>C.I. N°</th>
                    <th>Correo electrónico</th>
                    <th>Teléfono</th>
                    <th>Celular</th>
                    <th>Estado</th>
                </thead>
                <tbody>
                    <tr class="py-2 mt-2" *ngFor="let contacto of contactoPrincipalList | slice: (page-1) * pageSize : page * pageSize">
                        <td>
                            <fa-icon class="pointer" (click)="selectContact(contacto); modal.dismiss()" [icon]="faCheck"></fa-icon>
                        </td>
                        <td>{{contacto.idContacto}}</td>
                        <td>{{contacto.nombre}}</td>
                        <td>{{contacto.cliente}}</td>
                        <td>{{contacto.cedula}}</td>
                        <td>{{contacto.email}}</td>
                        <td>{{contacto.telefono}}</td>
                        <td>{{contacto.celular}}</td>
                        <td>{{contacto.estado.id}}-{{contacto.estado.descripcion}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer" style="border: none;">
        <ngb-pagination  [(page)]="page" [maxSize]="3" [pageSize]="pageSize" [collectionSize]="contactoPrincipalList.length"[boundaryLinks]="true" [boundaryLinks]="true"> </ngb-pagination>
    </div>
</ng-template>
<ng-template #formularioContactoModal let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal ">Nuevo Contacto {{this.creatingPEPContact ? 'PEP' : ''}}</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
        <form [formGroup]="newContactForm" class="d-flex flex-row flex-wrap">
            <!-- Nombre -->
            <div class="col-12 col-lg-6 col-xl-5 px-0 justify-content-center">
                <div class="col pt-3">
                    <label class="label-entity">Nombre</label>
                </div>
                <div class="col">
                    <input class="input-modal input-agregar" formControlName="nombre" type="text" placeholder="" style="text-transform: uppercase;">
                    <app-field-error [field]="newContactForm.controls['nombre']"></app-field-error>
                </div>
            </div>
            <!-- Apellido -->
            <div class="col-12 col-lg-6 col-xl-5 px-0 justify-content-center">
                <div class="col pt-3">
                    <label class="label-entity">Apellido</label>
                </div>
                <div class="col">
                    <input class="input-modal input-agregar" formControlName="apellido" type="text" placeholder="" style="text-transform: uppercase;">
                    <app-field-error [field]="newContactForm.controls['apellido']"></app-field-error>
                </div>
            </div>
            <!-- Nacimiento -->
            <div class="col-12 col-lg-3 col-xl-2 px-0 justify-content-center">
                <div class="col-12 pt-3">
                    <label class="label-entity">Fecha nacimiento</label>
                </div>
                <div class="col-12">
                    <input 
                        class="input-modal date-input"
                        formControlName="nacimiento"
                        type="date">
                    <app-field-error [field]="newContactForm.controls['nacimiento']"></app-field-error>
                </div>
            </div>
            <!-- Sexo -->
            <div class="col-12 col-lg-3 col-xl-2 px-0 justify-content-center">
                <div class="col-12 pt-3">
                    <label class="label-entity">Sexo</label>
                </div>
                <div class="col-12">
                    <ng-select placeholder="Seleccione" [clearable]="false" [searchable]="false"
                        formControlName="sexo" class="select-modal">
                        <ng-option value="9">
                            <span>No especificado</span> 
                        </ng-option>
                        <ng-option value="1">
                            <span>Hombre</span> 
                        </ng-option>
                        <ng-option value="2">
                            <span>Mujer</span> 
                        </ng-option>
                    </ng-select> 
                    <app-field-error [field]="newContactForm.controls['sexo']"></app-field-error>
                </div>
            </div>
            <!-- Teléfono-->
            <div class="col-12 col-lg-3 col-xl-2 px-0 justify-content-center">
                <div class="col pt-3">
                    <label class="label-entity">Teléfono</label>
                </div>
                <div class="col">
                    <input class="input-modal input-agregar" formControlName="telefono" type="text" placeholder="">
                    <app-field-error [field]="newContactForm.controls['telefono']"></app-field-error>
                </div>
            </div>
            <!-- Celular -->
            <div class="col-12 col-lg-3 col-xl-2 px-0 justify-content-center">
                <div class="col pt-3">
                    <label class="label-entity">Celular</label>
                </div>
                <div class="col">
                    <input class="input-modal input-agregar" formControlName="telefonoMovil" type="text" placeholder="">
                    <app-field-error [field]="newContactForm.controls['telefonoMovil']"></app-field-error>
                </div>
            </div>
            <!-- Correo electrónico-->
            <div class="col-12 col-lg-6 px-0 justify-content-center">
                <div class="col pt-3">
                    <label class="label-entity">Correo electrónico</label>
                </div>
                <div class="col">
                    <input class="input-modal input-agregar" [readonly]="!creatingPEPContact" formControlName="email" type="text" placeholder="{{newContactForm.value.email}}">
                    <app-field-error [field]="newContactForm.controls['email']"></app-field-error>
                </div>
            </div>
            <!-- Cédula-->
            <div class="col-12 col-lg-3 px-0 justify-content-center">
                <div class="col pt-3">
                    <label class="label-entity">Nro de cédula</label>
                </div>
                <div class="col">
                    <input class="input-modal input-agregar" formControlName="cedula" placeholder="">
                    <app-field-error [field]="newContactForm.controls['cedula']"></app-field-error>
                </div>
            </div>
            <!-- PEP -->
            <div class="col-12 col-lg-3 px-0 justify-content-center">
                <div class="col pt-3">
                    <label class="label-entity">Es PEP</label>
                    <label class="label-entity">
                        <fa-icon 
                            class="align-self-center justify-content-center mx-2 pointer" 
                            [icon]="faQuestionCircle" (click)="open(infoPEP,'lg')">
                        </fa-icon>
                    </label>
                </div>
                <div class="col">
                    <ng-select [clearable]="false" [searchable]="false"
                        formControlName="esPEP" (change)="isPEPChangued($event)">
                        <ng-option value='N'>
                            <span>No</span> 
                        </ng-option>
                        <ng-option value='S'>
                            <span>Sí</span> 
                        </ng-option>
                    </ng-select>
                </div>
                <app-field-error [field]="newContactForm.controls['esPEP']"></app-field-error>
            </div>
            <!-- Cargo -->
            <div class="col-12 col-lg-3 px-0 justify-content-center" *ngIf="newContactForm.controls['esPEP'].value=='S'">
                <div class="col pt-3">
                    <label class="label-entity">Cargo</label>
                </div>
                <div class="col">
                    <input class="input-modal input-agregar" formControlName="cargo" type="text" placeholder="">
                    <app-field-error [field]="newContactForm.controls['cargo']"></app-field-error>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="border: none;">
        <button (click)="confirmNewContact(creatingPEPContact);" 
            class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept" style="width: 70px;">
            Crear
        </button>
    </div>
</ng-template>
<ng-template #infoPEP let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal">PEP (Persona políticamente expuesta)</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
        <p>En cumplimiento a políticas de prevención de lavado de dinero, 
            favor seleccionar la opción <b>Sí</b> y complete los datos de contacto PEP en caso que el contacto, su cónyuge o familiares 
            hasta el segundo grado de consanguinidad o afinidad, 
            desempeñan o han desempeñado funciones públicas destacadas en los últimos dos años.</p>
    </div>
    <div class="modal-footer" style="border: none;">
        <button (click)="modal.dismiss()" class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept" style="width: 70px;">
            Aceptar
        </button>
    </div>
</ng-template>
<ng-template #infoOnboarding let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal">Onboarding digital automático</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
        <p>
            Requisitos para utilizar el onboarding digital automático, si no se cumplen se crea una oportunidad comercial normal.
        </p>
        <ul class="fa-ul" style="--fa-li-margin: 1em;">
            <ng-container *ngFor="let check of onboardingCheckList">
                <li>
                    <fa-icon 
                        [icon]="check.warning==true ? faExclamationTriangle : check.value==true ? faCheck : faTimes"
                        [fixedWidth]="true"
                        class="py-0"
                        [ngClass]="check.warning==true ? 'text-warning' : check.value==true ? 'text-success' : 'text-danger'" >
                    </fa-icon>
                    {{check.description}}
                </li>
            </ng-container>
        </ul>
        <p *ngIf="requiereConfirmacion">
            Es necesario que la Cuenta TD se encuentre confirmada, si la cuenta no se encuentra confirmada se creará una oportunidad comercial normal, esta confirmación se realiza durante la creación de la OC.
        </p>
    </div>
    <div class="modal-footer" style="border: none;">
        <button (click)="modal.dismiss()" class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept" style="width: 70px;">
            Aceptar
        </button>
    </div>
</ng-template>

<ng-template #infoAltaAgil let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal">Alta Ágil</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
        <p>
            Requisitos para utilizar el servicio de alta ágil para activación automática de equipos POS.
        </p>
        <ul class="fa-ul" style="--fa-li-margin: 1em;">
            <li *ngFor="let check of altaAgilCheckList">
                <fa-icon 
                    [icon]="check.value==true ? faCheck : faTimes"
                    [fixedWidth]="true"
                    class="py-0"
                    [ngClass]="check.value==true ? 'text-success' : 'text-danger'" >
                </fa-icon>
                {{check.description}}
            </li>
        </ul>
    </div>
    <div class="modal-footer" style="border: none;">
        <button (click)="modal.dismiss()" class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept" style="width: 70px;">
            Aceptar
        </button>
    </div>
</ng-template>
<ng-template #confirmNOOnboardingModal let-modal>
    <app-confirmation 
        [confirmationTitle]="'Onboarding Digital'"
        [confirmationText]="'No se cumplen los requisitos para el onboarding digital. Se creará la oc sin integración automática'"
        [confirmButtonText]="'Crear OC'"
        [cancelButtonText]="'Volver'"
        (result)="confirmNOOnboarding($event)">
    </app-confirmation>
</ng-template>
<ng-template #confirmNOAltaAgilModal let-modal>
    <app-confirmation 
        [confirmationTitle]="'Alta Ágil'"
        [confirmationText]="'No se cumplen los requisitos para alta ágil. El equipo seleccionado será desvinculado y se creará una OC tradicional'"
        [confirmButtonText]="'Crear OC'"
        [cancelButtonText]="'Volver'"
        (result)="confirmNOAltaAgil($event)">
    </app-confirmation>
</ng-template>
<ng-template #mapa let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal">Ubicación del cliente</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div id="mapa" style="width: 100%; height:250px"></div>
    <div class="modal-footer" style="border: none;">
        <span class="label-entity mr-auto" *ngIf="proximidad!=''"><b>Proximidad:</b> {{proximidad}}</span>
        <button (click)="confirmLocation();modal.dismiss()" class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept" style="width: 70px;">
            Aceptar
        </button>
    </div>
</ng-template>
<ng-template #newAttachment let-modal>
    <div class="modal-header modal-background">
        Nuevo Adjunto
    </div>
    <div class="modal-body modal-background">
        <div class="d-flex flex-row py-1 align-items-center">
            <div class="d-flex flex-column col-2">
                <label class="label-entity">Archivo</label>
                <div class="d-flex flex-row">
                    <div class="d-flex flex-column">
                        <label class="input-file-domus">
                            Seleccionar Archivo
                            <input type="file" [(ngModel)]="selectedFiles" (change)="handleFileSelect($event)" class="input-file-hidden" style="height: auto;">
                        </label>
                    </div>
                </div>
                <span class="custom-control-inline warning-feedback"> </span>
            </div>
            <div class="d-flex flex-column col-4 input-domus-mobile">
                <label class="label-entity">Nombre del Archivo</label>
                <input type="text" class="input-modal w-100" [(ngModel)]="selectedFileName" value="nombreArchivo" (keyup)="checkLength($event)">
            </div>
            <div class="d-flex flex-column col-6">
                <label class="label-entity">Tipo de Documento (*)</label>
                <ng-select 
                    [items]="tipoAdjuntos" bindLabel="description" placeholder="Tipo" 
                    [(ngModel)]="selectedFileType" [clearable]="false" [searchable]="false">
                </ng-select> 
            </div>
        </div>
        <div  class="d-flex flex-row py-3 mx-2">
            <div class="d-flex ml-auto mr-2">
                <button class="btn btn-primary-portal  btn-primary btn-sm" style="text-indent: 0px;" (click)="addFile(selectedFile,selectedFileName,selectedFileType)">Guardar</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #newBankAccountModal let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal">Asociar cuenta bancaria {{nAccountType}} ₲</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
        <form [formGroup]="newBankAccountForm" (ngSubmit)="associateAccount()" class="d-flex flex-row flex-wrap">
            <div class="col-12 col-lg-4 px-0 justify-content-center">
                <div class="col-12 px-2 pt-3">
                    <label class="label-entity">Tipo de cuenta</label>
                </div>
                <div class="col px-1">
                    <ng-select placeholder="" [clearable]="false" [searchable]="false"
                        formControlName="tipoCuenta" class="select-modal">
                        <ng-option *ngFor="let tipo of accountTypes" value="{{tipo.id}}">
                            <span>{{tipo.descripcion}}</span> 
                        </ng-option>
                    </ng-select> 
                    <app-field-error [field]="newBankAccountForm.controls['tipoCuenta']"></app-field-error>
                </div>
            </div>
            <div class="col-12 col-lg-4 px-0 justify-content-center">
                <div class="col px-2 pt-3">
                    <label class="label-entity">Número de cuenta</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" formControlName="numeroCuenta" type="text" placeholder="" maxlength="11">
                    <app-field-error [field]="newBankAccountForm.controls['numeroCuenta']"></app-field-error>
                </div>
            </div>
            <div class="col-12 col-lg-4 px-0 justify-content-center">
                <div class="col px-2 pt-3">
                    <label class="label-entity">Titular</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" formControlName="titular" type="text" placeholder="">
                    <app-field-error [field]="newBankAccountForm.controls['titular']"></app-field-error>
                </div>
            </div>
            <div class="col-12 col-lg-4 px-0 justify-content-center" *ngIf="nAccountType==='SPI' || requiereConfirmacion">
                <div class="col px-2 pt-3">
                    <label class="label-entity">Tipo Doc.</label>
                </div>
                <div class="col px-1">
                    <ng-select placeholder="" [clearable]="false" [searchable]="false"
                        formControlName="tipoDocumento" class="select-modal">
                        <ng-option *ngFor="let tipo of clientDocumentTypes" value="{{tipo.id}}">
                            <span>{{tipo.descripcion}}</span> 
                        </ng-option>
                    </ng-select> 
                    <app-field-error [field]="newBankAccountForm.controls['tipoDocumento']"></app-field-error>
                </div>
            </div>
            <div class="col-12 col-lg-4 px-0 justify-content-center" *ngIf="nAccountType==='SPI' || requiereConfirmacion">
                <div class="col px-2 pt-3">
                    <label class="label-entity">Número Doc.</label>
                </div>
                <div class="col px-1">
                    <input class="input-modal input-agregar" formControlName="numeroDocumento" type="text" placeholder="">
                    <app-field-error [field]="newBankAccountForm.controls['numeroDocumento']"></app-field-error>
                </div>
            </div>
        </form>
    </div>
    <div class="d-flex flex-column py-3 ml-auto mx-5">
        <div class="d-flex align-items-center pointer ml-auto">
            <div class="d-flex align-items-center pointer">    
                <button class="btn btn-primary-portal btn-primary btn-sm btn-agregar-ticket" type="submit" (click)="associateAccount();">
                    Agregar
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #infoValidado let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal">¿Qué implica la validación de cuenta?</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
        Al agregar una cuenta, el sistema verifica dentro de la lista de cuentas validadas por la entidad.
        <ul>
            <li><b>Validado</b>: La cuenta existe en la lista y es de tipo Cta. Cte. o Caja de ahorro</li>
            <li><b>No validado</b>: La cuenta no existe en la lista, se debe agregar manualmente</li>
        </ul>
        Es necesario que la cuenta se encuentra validada si se desea utilizar el servicio de automatización
    </div>
    <div class="modal-footer" style="border: none;">
        <button (click)="modal.dismiss()" class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept"
            style="width: 70px;">
            Aceptar
        </button>
    </div>
</ng-template>