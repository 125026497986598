import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import CryptoJS from 'crypto-js';
import {util,pki} from 'node-forge';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  private KEY = "";
  public PUB = "";
  private PRIV = "";
  public PASS = "";
  public API_PUB_KEY = "";

  /** Método para inicializar el servicio de encriptado 
   * @param r_key llave de encriptado
  */
  init(r_key: string) {
    const keyBase = r_key.split("").reverse().join("");
    const key = util.decode64(keyBase);
    this.KEY = key;
  }

  /** Método para asignar las demás claves de encriptado
   * @param pub clave pública del front
   * @param priv clave privada del front
   * @param pass llave de la clave privada
   * @param api_pub_key clave pública de la API
   */
  setKeys(pub: string, priv: string, pass: string, api_pub_key: string) {
    this.PUB = pub;
    this.PRIV = priv;
    this.PASS = pass;
    this.API_PUB_KEY = api_pub_key;
  }

  /** Método para encriptar datos
   * Utiliza AES-256 y un vector de inicialización aleatorio
   * @param data Datos a encriptar
   * @param _key clave con la cual encriptar, si se ignora se utiliza
   * la clave del servicio
   * @return IV de 32 caracteres junto con los datos encriptados en formato HEX
   */
   encrypt(data: string, _key?: string) {

    // crea un vector de inicialización
    const _iv = this.randomString(16);

    // Se convierte la clave y el iv a UTF-8
    const key  = CryptoJS.enc.Utf8.parse(_key ? _key : this.KEY);
    const iv  = CryptoJS.enc.Utf8.parse(_iv);

    // Se encripta con AES y se guarda el resultado en una variable
    const encriptado = CryptoJS.AES.encrypt(data, key, {
        iv: iv, 
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
      });

    // Devuelve el iv y el resultado en formato HEX
    return _iv+encriptado.ciphertext.toString();
  }

  /** Método para desencriptar datos
   * Utiliza AES-256
   * @param encrypted Datos a desencriptar
   * @param _iv vector de inicialización
   * @param _key clave para desencriptar, si se ignora se utiliza la clave del servicio
   * @return dato desencriptado en formato UTF-8
   */
  decrypt(encrypted: string, _iv: string, _key?: string) {
    // Se convierte la clave y la vector de inicialización a UTF-8
    const key  = CryptoJS.enc.Utf8.parse(_key ? _key : this.KEY);
    const iv  = CryptoJS.enc.Utf8.parse(_iv);

    // transforma el dato encriptado a base64
    const base64 = this.transform(encrypted,'hex','base64')

    // Se desencripta y se guarda el resultado en una variable
    const desencriptado = CryptoJS.AES.decrypt(base64, key, {
        iv: iv, 
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
      });

    // Devuelve el resultado en formato UTF-8
    return desencriptado.toString(CryptoJS.enc.Utf8);
  }

  /** Método para encriptar datos con una clave pública
   * Utiliza RSA-OAEP
   * @param data Datos a encriptar
   * @param pubKey clave pública a utilizar
   * @return IV de 32 caracteres junto con los datos encriptados en formato HEX
   */
  encryptRSA(data: string, pubKey: string) {
    // Crear clave simetrica
    const symkey = this.randomString(32);

    // Encripta los datos con la clave simetrica
    const encryptedData = this.encrypt(data, symkey);

    // Crea una instancia de RSA public key utilizando la clave pública
    const publicKeyObj = pki.publicKeyFromPem(pubKey);

    // Encripta la clave simetrica creada utilizando RSA y la clave pública
    const encryptedSymKey = publicKeyObj.encrypt(symkey,'RSA-OAEP');

    // Convierte el resultado en una cadena legible para su transmisión
    const encryptedSymKeyData = this.transform(encryptedSymKey,'binary','base64');

    // resultado
    const res = JSON.stringify({key: encryptedSymKeyData,data: encryptedData});

    // encripta el resultado
    const encryptedResult = this.encrypt(res);

    // Devuelve el resultado
    return encryptedResult;
  }

  /** Método para desencriptar datos encriptados con la clave pública
   * se utiliza la clave privada del servicio
   * @param data datos encriptados en formato base64
   * @return datos desencriptados en formato UTF-8
   */
  decryptRSA(data: string) {
    // construye clave privada
    let privateKey;
    try {
      privateKey = pki.decryptRsaPrivateKey(this.PRIV, this.PASS);
    } catch(error) {
      console.log('error generando llave',error)
      return;
    }

    // decodifica el dato encriptado
    const encryptedBinary = util.decode64(data);

    // desencripta usando la clave privada
    let decryptedData;
    try {
      decryptedData = privateKey.decrypt(encryptedBinary, 'RSA-OAEP');
    } catch (error) {
      console.log('error al desencriptar',error)
    }

    // devuelve los datos desencriptados
    return decryptedData.toString('utf8');

  }

  /** Crea hash utilizando la metodología SHA-256
   *  @param data String a transformar
   *  @returns string en formato Base64
   */
  SHA256(data: string) {
    const salt = this.KEY.slice(0,16);
    // Se convierte la llave a UTF-8
    const key  = CryptoJS.enc.Utf8.parse(salt);
    // Hash y devuelve el resultado en formato Base64
    return CryptoJS.SHA256(data,key).toString(CryptoJS.enc.Base64);
  }

  /** Crea hash utilizando la metodología Hmac SHA-256
   *  @param data String a transformar
   *  @param key String a utilizar como llave
   *  @returns string en formato Base64
   */
  HmacSHA256(data: string,key: string) {
    // Se convierte la llave a UTF-8
    const skey  = CryptoJS.enc.Utf8.parse(key);
    // Hash y devuelve el resultado en formato Base64
    return CryptoJS.HmacSHA256(data,skey).toString(CryptoJS.enc.Base64);
  }

  /** Crea un string con caracteres aleatorios en formato HEX }
   * @param length tamaño del string a crear
   * @returns string aleatorio
  */
  randomString(length:number) {
    const randomBytes = CryptoJS.lib.WordArray.random(length);
    const randomString = CryptoJS.enc.Hex.stringify(randomBytes);
    return randomString;
  }

  /** Método para tranformar datos 
   * @param data dato a transformar
   * @param from tipo del formato inicial
   * @param to tipo del formato final
   * @returns string transformado
  */
  transform(data: string, from: 'hex'|'base64'|'binary', to: 'hex'|'base64'|'binary') {
    return Buffer.from(data, from).toString(to)
  }

  /** Método para separar el vector de inicialización del dato encriptado
   * @param ivWithEncrypted dato a separar
   * @param length tamaño en caracteres del vector
   * @return objeto con la iv y el encriptado
  */
  extractIV(ivWithEncrypted: string, length: number) {
    // extrae el vector de inicialización
    const _iv = ivWithEncrypted.substring(0,length);
    // extrae los datos encriptados
    const encrypted = ivWithEncrypted.substring(length);
    // devuelve los datos
    return {iv: _iv, encrypted: encrypted}
  }

  /** Método para desencritar datos obtenidos de la api 
   * @param encryptedData datos encriptados
   * @returns datos desencriptados
  */
  decryptResultData(encryptedData: string) {

    // separa la clave de inicialización del dato obtenido
    const EIV1 = this.extractIV(encryptedData,32);

    // desencripta el dato
    const result = JSON.parse(this.decrypt(EIV1.encrypted,EIV1.iv));

    // extrae la clave simétrica encriptada
    const encryptedKeyData = result.key;

    // desencripta la clave simétrica
    const symKey = this.decryptRSA(encryptedKeyData);

    // separa la clave de inicialización 
    const EIV2 = this.extractIV(result.data,32);

    // desencripta los datos
    const data = this.decrypt(EIV2.encrypted, EIV2.iv,symKey);
    
    // devuelve el resultado
    return JSON.parse(data);
  }

  /** Método para obtener la clave pública del portal encriptada con la clave privada de la API */
  getPublicKey() {
    // obtiene la clave pública de la API
    const api_pub_key = this.API_PUB_KEY;

    // encripta la clave pública del fromt
    const pub = this.encryptRSA(this.PUB,api_pub_key);

    // devuelve el resultado
    return pub;
  }

  /** Método para encriptar datos a enviar a la api 
   * @param payload datos a encriptar
  */
  encryptPayloadData(payload: string) {
    // obtiene la clave pública de la API
    const api_pub_key = this.API_PUB_KEY;

    return this.encryptRSA(payload,api_pub_key)
  }

}
