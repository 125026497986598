import { Component, Input, OnInit } from '@angular/core';
import { OcService } from 'src/app/services/oc.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Mensajes } from 'src/app/constants/mensajes.const';

@Component({
  selector: 'app-tabla-cuentas-bancarias-detalle',
  templateUrl: './tabla-cuentas-bancarias-detalle.component.html',
  styleUrls: ['./tabla-cuentas-bancarias-detalle.component.scss']
})
export class TablaCuentasBancariasDetalleComponent implements OnInit {

  //inputs y outputs
  @Input() editable;
  @Input() ocObjectId;
  @Input() requiereConfirmacion;
  @Input() cuentaConfirmada;

  //icons
  faSpinner=faSpinner;

  //arrays
  cuentasBancariasList:any[] = [];

  //booleans
  loading = false;

  //paginado
  page=1;
  pageSize=5;
  collectionSize=0;
  constructor(private ocService : OcService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getBankAccounts();
  }

  /** Método para obtener las cuentas bancarias asociadas ala oc actual */
  async getBankAccounts(){
    this.loading=true;
    const bankAccounts = await this.ocService.getBankAccounts(this.ocObjectId);
    if (bankAccounts) {
      this.cuentasBancariasList = bankAccounts;
      this.collectionSize=this.cuentasBancariasList.length;
    }
    this.loading=false;
  }

}
