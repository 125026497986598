<app-loadingSpinner [message]="loadingMessage" [show]="loading"></app-loadingSpinner>
<div class="row px-3" id="createTicketPage" (dragover)="onDragOver($event)" (paste)="onPaste($event)">
    <div class="col-12">
        <a class="page-route puntero" [routerLink]="['tickets','tickets']">Tickets&nbsp;</a>
        <span class="page-route">/&nbsp;</span>
        <span class="page-route">Nuevo ticket</span>
    </div>
    <div class="col-12 py-3">
        <app-title></app-title>
    </div>
    <form class="w-100" [formGroup]="formNewTicket" (ngSubmit)="crearTicket()">
        <div class="row row-cols-1 row-cols-md-3 g-4 mx-0" id="rowFormCrearTicket">
            
            <!-- Tipo de ticket -->
            <div class="col">
                <div class="d-flex flex-column">
                    <label class="label-entity">Clasificación de Ticket</label>
                    <ng-select 
                        placeholder="Clasificación" formControlName="clasificacion"
                        [clearable]="false" [searchable]="true">
                        <ng-option *ngFor="let clasificacion of ticketsClassifications" value="{{clasificacion.codigo}}">
                            <span>{{clasificacion.valor}}</span>
                        </ng-option>
                    </ng-select>
                    <app-field-error [field]="formNewTicket.controls['clasificacion']"></app-field-error>
                </div>
            </div>
            
            <!-- Tipo de ticket -->
            <div class="col">
                <div class="d-flex flex-column">
                    <label class="label-entity">Tipo de Ticket</label>
                    <ng-select 
                        placeholder="Tipo" formControlName="tipo"
                        [clearable]="false" [searchable]="true"
                        (change)="onChange($event)">
                        <ng-option *ngFor="let tipo of ticketsTypes | sort: 'asc' : 'descripcion'" value="{{tipo.id}}">
                            <span>{{tipo.descripcion}}</span>
                        </ng-option>
                    </ng-select>
                    <app-field-error [field]="formNewTicket.controls['tipo']"></app-field-error>
                </div>
            </div>
            <!-- Asunto -->
            <div class="col" *ngIf="formNewTicket.value.tipo != 'ZVE'">
                <div class="d-flex flex-column">
                    <label class="label-entity">Asunto</label>
                    <input class="ticket-resumen-input input-agregar" formControlName="asunto" type="text" placeholder="Asunto">
                    <app-field-error [field]="formNewTicket.controls['asunto']"></app-field-error>
                </div>
            </div>
            <!-- En el caso de que el tipo de ticket requiera Sucursal, se debera mostrar esto y asociarlo como cliente -->
            <div class="col" *ngIf="formNewTicket.value.tipo=='Z6' || formNewTicket.value.tipo=='Z9'">
                <div class="d-flex flex-column">
                    <label class="label-entity">Sucursal</label>
                    <ng-select placeholder="Sucursal" [clearable]="false" [searchable]="false"
                    formControlName="sucursal" (change)="onChangeSucursal($event)">
                        <ng-option *ngFor="let sucursal of branches" value="{{sucursal.C4C_ID}}">
                            <span>{{sucursal.numero}} - {{sucursal.nombre}}</span>
                        </ng-option>
                    </ng-select>
                    <app-field-error [field]="formNewTicket.controls['sucursal']"></app-field-error>
                </div>
            </div>
            <!-- Contacto entidad-->
            <div class="col">
                <div class="d-flex flex-column">
                    <label class="label-entity">Contacto Entidad</label>
                        <div class="d-flex flex-row ticket-resumen-input">
                            <input class="cursor-default ticket-resumen-input input-agregar" readonly formControlName="contactoPrincipal" type="text">
                            <fa-icon class="align-self-center justify-content-center mx-2 pointer" [icon]="faTimes" *ngIf="this.contactoSelected != undefined" (click)="limpiarContacto()"></fa-icon>
                            <fa-icon class="align-self-center justify-content-center mx-2 pointer" [icon]="faExternalLinkAlt" *ngIf="this.contactoSelected == undefined" (click)="open(validacionContacto,'xl')"></fa-icon>
                       </div>
                       <app-field-error [field]="formNewTicket.controls['contactoPrincipal']"></app-field-error>
                </div>
            </div>
            <!-- Serie equipo -->
            <div class="col" *ngIf="formNewTicket.value.tipo=='Z6'">
                <div class="d-flex flex-column">
                    <label class="label-entity">Serie (Equipo Instalado)</label>
                    <input class="ticket-resumen-input input-agregar"  type="text" placeholder="Serie">
                    <app-field-error [field]="formNewTicket.controls['serie']"></app-field-error>
                </div>
            </div>
            <!-- Categoría -->
            <div class="col" *ngIf="!isTkVeredicto">
                <div class="d-flex flex-column">
                    <label class="label-entity">Categoria de Servicio (Nivel 1)</label>
                    <ng-select 
                        placeholder="Categoria de Servicio" formControlName="categoryServices"
                        [clearable]="false" [searchable]="true" 
                        (change)="onChangeCategory()">
                        <ng-option 
                            *ngFor="let categoria of ticketsTypesCategories | 
                            CategoryFilter:[formNewTicket.value.tipo]:true | 
                            sort: 'asc' : 'descripcion'" 
                            value="{{categoria.id}}">
                            <span>{{categoria.descripcion}}</span>
                        </ng-option>
                    </ng-select>
                    <app-field-error [field]="formNewTicket.controls['categoryServices']"></app-field-error>
                </div>
            </div>
            <!-- Incidente -->
            <div class="col" *ngIf="showCategoryIncident">
                <div class="d-flex flex-column">
                    <label class="label-entity">Categoria de Incidente (Nivel 2)</label>
                    <ng-select 
                        class="select-m filtro-ticket" placeholder="Incidente" formControlName="categoryIncidents"
                        [clearable]="false" [searchable]="true">
                        <ng-option 
                            *ngFor="let incidente of ticketsTypesCategoriesIncidents | 
                                IncidentFilter:[formNewTicket.value.categoryServices] | 
                                sort: 'asc' : 'descripcion'"
                            value="{{incidente.id}}" >
                            <span>{{incidente.descripcion}}</span> 
                        </ng-option>
                    </ng-select> 
                    <app-field-error [field]="formNewTicket.controls['categoryIncidents']"></app-field-error>
                </div>
            </div>
            <!-- Prioridad -->
            <div class="col">
                <div class="d-flex flex-column">
                    <label class="label-entity">Prioridad</label>
                    <ng-select placeholder="Prioridad" [clearable]="false" [searchable]="true" formControlName="prioridadTicket">
                        <ng-option *ngFor="let prioridad of ticketsPriorities | sort: 'asc' : 'descripcion'" value="{{prioridad.id}}">
                            <span>{{prioridad.descripcion}}</span>
                        </ng-option>
                    </ng-select>
                    <app-field-error [field]="formNewTicket.controls['prioridadTicket']"></app-field-error>
                </div>
            </div>
            <!-- Fecha/Hora baja -->
            <div *ngIf="this.formNewTicket.value.categoryServices == 'CA514'" class="col"> 
                <div class="d-flex flex-column">
                    <label class="label-entity">Fecha/Hora Baja</label>
                    <input class="ticket-resumen-input input-agregar" formControlName="fechaHoraBaja" type="datetime-local" placeholder="Fecha Baja">
                    <app-field-error [field]="formNewTicket.controls['fechaHoraBaja']"></app-field-error>
                </div>
            </div>
            <!-- Entidad dueña ATM -->
            <div *ngIf="this.formNewTicket.value.categoryServices == 'CA_900' && this.formNewTicket.value.tipo == 'ZVE'" class="col">
                <div class="d-flex flex-column">
                    <label class="label-entity">Entidad dueña del ATM</label>
                    <ng-select placeholder="" [clearable]="false" [searchable]="true" formControlName="atmEntity">
                        <ng-option *ngFor="let entidad of entities" value="{{ entidad.codigoEntidad }}">
                            <span>{{ entidad.nombre }}</span>
                        </ng-option>
                    </ng-select>
                    <app-field-error [field]="formNewTicket.controls['atmEntity']"></app-field-error>
                </div>
            </div>
            <!-- Número de boleta-->
            <div *ngIf="this.formNewTicket.value.tipo == 'ZPIK' || (this.formNewTicket.value.categoryServices == 'CA_900' && this.formNewTicket.value.tipo == 'ZVE')" class="col">
                <div class="d-flex flex-column">
                    <label class="label-entity">N&uacute;mero de Boleta</label>
                    <input onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'" class="ticket-resumen-input input-agregar" formControlName="numeroBoleta" type="text" placeholder="">
                    <app-field-error [field]="formNewTicket.controls['numeroBoleta']"></app-field-error>
                </div>
            </div>
            <!-- Importe-->
            <div *ngIf="this.formNewTicket.value.tipo == 'ZPIK' || (this.formNewTicket.value.categoryServices == 'CA_900' && this.formNewTicket.value.tipo == 'ZVE')" class="col">
                <div class="d-flex flex-column">
                    <label class="label-entity">Importe Reclamado</label>
                    <input class="ticket-resumen-input input-agregar" formControlName="importeReclamado" type="number" placeholder="" (keydown)="preventInvalidChars($event)">
                    <app-field-error [field]="formNewTicket.controls['importeReclamado']"></app-field-error>
                </div>
            </div>
            <!-- Terminal-->
            <div *ngIf="Constantes.CATEGORIAS_TERMINAL.includes(this.formNewTicket.value.categoryServices)" class="col">
                <div class="d-flex flex-column">
                    <label class="label-entity">Terminal</label>
                    <input class="ticket-resumen-input input-agregar" formControlName="terminal" type="text" placeholder="Terminal" maxlength="10" minlength="9">
                    <app-field-error [field]="formNewTicket.controls['terminal']"></app-field-error>
                </div>
            </div>
            <!-- ATM marca-->
            <div *ngIf="Constantes.CATEGORIAS_ATM.includes(this.formNewTicket.value.categoryServices)" class="col">
                <div class="d-flex flex-column">
                    <label class="label-entity">Marca</label>
                    <ng-select placeholder="Marca" [clearable]="false" [searchable]="true" formControlName="marca" (change)="changeModelo()">
                        <ng-option value="">
                            <span>Marca</span>
                        </ng-option>
                        <ng-option *ngFor="let atm of atms" value="{{atm.id}}">
                            <span>{{atm.descripcion}}</span>
                        </ng-option>
                    </ng-select>
                    <app-field-error [field]="formNewTicket.controls['marca']"></app-field-error>
                </div>
            </div>
            <!-- Model de ATM -->
            <div *ngIf="Constantes.CATEGORIAS_ATM.includes(this.formNewTicket.value.categoryServices)" class="col">
                <div class="d-flex flex-column">
                    <label class="label-entity">Modelo</label>
                    <ng-select placeholder="Modelo" [clearable]="false" [searchable]="true" formControlName="modelo">
                        <ng-option value="">
                            <span>Modelo</span>
                        </ng-option>
                        <ng-option *ngFor="let modelo of atmModels" value="{{modelo.id}}">
                            <span>{{modelo.descripcion}}</span>
                        </ng-option>
                    </ng-select>
                    <app-field-error [field]="formNewTicket.controls['modelo']"></app-field-error>
                </div>
            </div>
            <!-- Suministro - Trasportadoras -->
            <div *ngIf="Constantes.CATEGORIAS_ATM.includes(this.formNewTicket.value.categoryServices)" class="col">
                <div class="d-flex flex-column">
                    <label class="label-entity">Suministro</label>
                    <ng-select placeholder="Suministro" [clearable]="false" [searchable]="true" formControlName="suministro">
                        <ng-option value="">
                            <span>Suministro</span>
                        </ng-option>
                        <ng-option *ngFor="let suministro of atmProviders" value="{{suministro.id}}">
                            <span>{{suministro.descripcion}}</span>
                        </ng-option>
                    </ng-select>
                    <app-field-error [field]="formNewTicket.controls['suministro']"></app-field-error>
                </div>
            </div>
            <!-- Fecha instalación-->
            <div *ngIf="Constantes.CATEGORIAS_ATM.includes(this.formNewTicket.value.categoryServices)" class="col">
                <div class="d-flex flex-column">
                    <label class="label-entity">Fecha/Hora de instalacion</label>
                    <input class="ticket-resumen-input input-agregar pr-2" type="datetime-local" formControlName="fechaHoraInstalacion">
                    <app-field-error [field]="formNewTicket.controls['fechaHoraInstalacion']"></app-field-error>
                </div>
            </div>
            <!-- Ubicación origen -->
            <ng-container *ngIf="Constantes.CATEGORIAS_UBICACION_ORIGEN.includes(this.formNewTicket.value.categoryServices)">
                <div class="col-lg-12 col-md-12 col-sm-12 mt-3 mb-0">
                    <label class="label-entity" style="font-size: 1.2rem!important"><i>Dirección de orígen</i></label>
                </div>
                <!-- Pais origen -->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">País</label>
                        <ng-select
                            placeholder="Pais/Region" formControlName="paisOrigen"
                            [clearable]="false" [searchable]="false">
                            <ng-option value="{{pais.id}}" *ngFor="let pais of countriesFrom">
                                <span>{{pais.descripcion}}</span>
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <!-- Departamento origen-->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Departamento</label>
                        <ng-select
                            placeholder="Departamento" formControlName="departamentoOrigen"
                            [clearable]="false" [searchable]="true"
                            (change)="getCities($event,'from')">
                            <ng-option value="{{departamento.id}}" *ngFor="let departamento of statesFrom | StateFilter: formNewTicket.controls['paisOrigen'].value">
                                <span >{{departamento.descripcion}}</span>
                            </ng-option>
                        </ng-select>
                        <app-field-error [field]="formNewTicket.controls['departamentoOrigen']"></app-field-error>
                    </div>
                </div>
                <!-- Ciudad origen -->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Ciudad</label>
                        <ng-select
                            placeholder="Ciudad" formControlName="ciudadOrigen"
                            [clearable]="false" [searchable]="true"
                            (change)="getNeighborhoods($event,'from')">
                            <ng-option *ngFor="let ciudad of citiesFrom" value="{{ciudad.id}}">
                                <span >{{ciudad.descripcion}}</span>
                            </ng-option>
                        </ng-select>
                        <app-field-error [field]="formNewTicket.controls['ciudadOrigen']"></app-field-error>
                    </div>
                </div>
                <!-- Barrio origen-->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Barrio</label>
                        <ng-select
                            placeholder="Barrio" formControlName="barrioOrigen"
                            [clearable]="false" [searchable]="true">
                            <ng-option *ngFor="let barrio of neighborhoodsFrom" value="{{barrio.id}}">
                                <span >{{barrio.descripcion}}</span>
                            </ng-option>
                        </ng-select>
                        <app-field-error [field]="formNewTicket.controls['barrioOrigen']"></app-field-error>
                    </div>
                </div>
                <!-- Calle origen -->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Calle</label>
                        <input class="ticket-resumen-input input-agregar" formControlName="calleOrigen" type="text" placeholder="Calle">
                        <app-field-error [field]="formNewTicket.controls['calleOrigen']"></app-field-error>
                    </div>
                </div>
                <!-- Número  de calle origen-->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">N&uacute;mero</label>
                        <input class="ticket-resumen-input input-agregar" formControlName="numeroCalleOrigen" type="text" placeholder="Numero">
                        <app-field-error [field]="formNewTicket.controls['numeroCalleOrigen']"></app-field-error>
                    </div>
                </div>
            </ng-container>
            <!-- Ubicación destino -->
            <ng-container *ngIf="Constantes.CATEGORIAS_UBICACION_DESTINO.includes(this.formNewTicket.value.categoryServices)">
                <div class="col-lg-12 col-md-12 col-sm-12 mt-3 mb-0">
                    <label class="label-entity" style="font-size: 1.2rem!important"><i>Dirección de destino</i></label>
                </div>
                <!-- Pais destino-->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">País</label>
                        <ng-select
                            placeholder="Pais/Region" formControlName="paisDestino"
                            [clearable]="false" [searchable]="false">
                            <ng-option value="{{pais.id}}" *ngFor="let pais of countriesTo">
                                <span>{{pais.descripcion}}</span>
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <!-- Departamento destino -->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Departamento</label>
                        <ng-select
                            placeholder="Departamento" formControlName="departamentoDestino"
                            [clearable]="false" [searchable]="true"
                            (change)="getCities($event,'to')">
                            <ng-option value="{{departamento.id}}" *ngFor="let departamento of statesTo | StateFilter: formNewTicket.controls['paisDestino'].value">
                                <span >{{departamento.descripcion}}</span>
                            </ng-option>
                        </ng-select>
                        <app-field-error [field]="formNewTicket.controls['departamentoDestino']"></app-field-error>
                    </div>
                </div>
                <!-- Ciudad destino -->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Ciudad</label>
                        <ng-select placeholder="Ciudad" formControlName="ciudadDestino"
                            [clearable]="false" [searchable]="true"
                            (change)="getNeighborhoods($event,'to')">
                            <ng-option *ngFor="let ciudad of citiesTo" value="{{ciudad.id}}">
                                <span >{{ciudad.descripcion}}</span>
                            </ng-option>
                        </ng-select>
                        <app-field-error [field]="formNewTicket.controls['ciudadDestino']"></app-field-error>
                    </div>
                </div>
                <!-- Barrio destino-->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Barrio</label>
                        <ng-select placeholder="Barrio" formControlName="barrioDestino"
                            [clearable]="false" [searchable]="true">
                            <ng-option *ngFor="let barrio of neighborhoodsTo" value="{{barrio.id}}">
                                <span>{{barrio.descripcion}}</span>
                            </ng-option>
                        </ng-select> 
                        <app-field-error [field]="formNewTicket.controls['barrioDestino']"></app-field-error>
                    </div>
                </div>
                <!-- calle destino-->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Calle</label>
                        <input class="ticket-resumen-input input-agregar" formControlName="calleDestino" type="text" placeholder="Calle">
                        <app-field-error [field]="formNewTicket.controls['calleDestino']"></app-field-error>
                    </div>
                </div>
                <!-- Numero de calle destino-->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">N&uacute;mero</label>
                        <input class="ticket-resumen-input input-agregar" formControlName="numeroCalleDestino" type="text" placeholder="Numero">
                        <app-field-error [field]="formNewTicket.controls['numeroCalleDestino']"></app-field-error>
                    </div>
                </div>
                <!-- Latitud-->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Latitud</label>
                        <input class="ticket-resumen-input input-agregar" formControlName="latitud" type="text" placeholder="Latitud">
                        <app-field-error [field]="formNewTicket.controls['latitud']"></app-field-error>
                    </div>
                </div>
                <!-- Longitud-->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Logitud</label>
                        <input class="ticket-resumen-input input-agregar" formControlName="longitud" type="text" placeholder="Logitud">
                        <app-field-error [field]="formNewTicket.controls['longitud']"></app-field-error>
                    </div>
                </div>
            </ng-container>
            <!-- Nro. de tarjeta -->
            <div class="col" *ngIf="formNewTicket.value.tipo == 'Z5'">
                <div class="d-flex flex-column">
                    <label class="label-entity">Nro. de tarjeta</label>
                    <input class="ticket-resumen-input input-agregar" formControlName="nroTarjeta" type="text" placeholder="Número de tarjeta" appMaskCardNumber>
                    <app-field-error [field]="formNewTicket.controls['nroTarjeta']"></app-field-error>
                </div>
            </div>
        </div>
        <!-- Descripcion-->
        <div class="col">
            <div class="d-flex flex-column my-3">
                <label class="label-entity">Descripcion</label>
                <editor formControlName="description" [init]="editorConfig"></editor>
                <app-field-error [field]="formNewTicket.controls['description']"></app-field-error>
            </div>
        </div>
<!-------------------------------------------------ADJUNTOS-------------------------------------------------------------------------->
        <div class="py-1 d-flex flex-column mx-3" *ngIf="showDropZone">
            <label class="label-entity">Suelte sus archivos aqui</label>
            <div id="dropZone" class="drop-zone">
                <input class="input-agregar fileInput" type="file" multiple (change)="onFileDropped($event)">
            </div>
        </div>
        <label class="label-entity ml-3">Adjuntos</label>
        <div class="col">
            <div class="table-responsive">
                <table class="table table-style table-font-size">
                    <tr class="table-line align-items-center py-2">
                        <th class="table-cell-ticket" scope="col">
                            Nombre del Archivo
                        </th>
                        <th class="table-cell-ticket" scope="col">
                            Fecha
                        </th>
                        <th class="table-cell-ticket" scope="col">
                            Tamaño
                        </th>
                        <th class="table-cell-ticket" scope="col">
                            Documento seguro
                        </th>
                        <th class="table-cell-ticket " style="width:10px; text-align:center" scope="col">
                        </th>
                    </tr>
                    <tbody *ngIf="!loading && documentos.length!=0">
                        <tr class="py-2 align-items-center mt-2" *ngFor="let documento of documentos;  let i = index">
                            <td class="table-cell-ticket ">
                                {{documento.titulo}}
                            </td>
                            <td class="table-cell-ticket">
                                {{documento.fechaCreacion | date:'dd/MM/YYYY'}}
                            </td>
                            <td class="table-cell-ticket">
                                {{getFileSize(documento)}}
                            </td>
                            <td class="table-cell-ticket">
                                <div class="d-flex align-items-center"> 
                                    <label class="switch ml-auto my-auto">
                                        <input type="checkbox" [(ngModel)]="documento.contieneInformacionSensible" 
                                            [name]="'isSensitive' + i"
                                            [ngModelOptions]="{standalone: true}">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </td>
                            <td class="table-cell-ticket" style="text-align:center">
                                <button class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept" style="width:100px !important;" (click)="deleteFile(documento)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                    Borrar
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!loading && documentos.length==0">
                        <tr>
                            <td colspan="10" align="center">
                                No hay adjuntos asociados.
                            </td>
                        </tr>                                  
                    </tbody>
                </table>
            </div>
            <div class="d-flex flex-row py-3">
                <div class="d-flex flex-column mr-auto">
                    <div class="d-flex flex-row">
                        <div class="d-flex flex-row mx-auto justify-content-center align-items-center">
                            <label class="input-file-domus">
                                Agregar adjunto
                                <input id="fileSelection" type="file" multiple [(ngModel)]="selectedFiles" (change)="selectFile($event)" class="input-file-hidden" style="height: auto;" [ngModelOptions]="{standalone: true}">
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col" *ngIf="documentos.length!=0">
            <div class="alert alert-warning-gold d-flex align-items-center" role="alert">
                <fa-icon class="mr-2 text-danger fa-2x" [icon]="faExclamationTriangle"></fa-icon>
                Por favor, ten en cuenta que si algún documento contiene información de tarjeta, es crucial asegurarse de que el botón de dato sensible esté activado antes de proceder.
            </div>
        </div>
<!----------------------------------------BOTON DE CREACION----------------------------------------------->
        <div class="col-12 mt-5">
            <button type="submit" class="mx-auto btn btn-primary-portal  btn-primary btn-sm" *hasPermission="['SEC_TICKETS_OPERACION']">
                <span class="text-btn-main">Crear ticket</span>
            </button>
        </div>
    </form>
</div>
<!----------------------------------------FORMULARIO DE CONTACTOS------------------------------------------->
<ng-template #contactosAsociados let-modal>
    <div class="modal-header" style="border: none;">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
        <div class="table-responsive table-contact-modal">
            <table class="table-style table">
                <thead class="py-2 ">
                    <th></th>
                    <th>ID de contacto</th>
                    <th>Nombre</th>
                    <th>Correo electronico</th>
                    <th>Telefono</th>
                    <th>Telefono Movil</th>
                </thead>
                <tbody>
                    <tr class="py-2 mt-2" *ngFor="let contacto of contactoPrincipalList | slice: (page-1) * pageSize : page * pageSize">
                        <td>
                            <fa-icon class="pointer" (click)="selectContacto(contacto); modal.dismiss()" [icon]="faCheck"></fa-icon>
                        </td>
                        <td>{{contacto.idContacto}}</td>
                        <td>{{contacto.nombre}}</td>
                        <td>{{contacto.email}}</td>
                        <td>{{contacto.telefono}}</td>
                        <td>{{contacto.celular}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal-footer" style="border: none;">
        <ngb-pagination  [(page)]="page" [maxSize]="3" [pageSize]="pageSize" [collectionSize]="collectionSizeContacto" [boundaryLinks]="true"> </ngb-pagination>
    </div>

</ng-template>
<!----------------------------------------FORMULARIO DE VALIDACION CONTACTOS------------------------------------>
<ng-template #validacionContacto let-modal>
    <div class="modal-header" style="border: none;">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class="modal-body modal-background">
        <div class="row row-cols-lg-1 py-2  justify-content-center">
            <div class="col-lg-4 text-center text-modal d-flex flex-column justify-content-center">
                <form [formGroup]="contactForm" (ngSubmit)="contactEmailValidate(); modal.dismiss()">
                    <div class="d-flex flex-column justify-content-center text-center">
                        <span>Ingrese el email del contacto</span>
                        <input formControlName="email" [(ngModel)]="emailContacto" class="mt-2 input-modal input-agregar text-center" type="text" placeholder="">
                    </div>
                </form>
            </div>
        </div>
        <div class="row row-cols-lg-1 py-3 justify-content-center">
            <div class="col-lg-8 text-center text-modal">
                <div class=" d-flex row text-center justify-content-center">
                    <span class="span-modal"></span>
                </div>
                <div class="d-flex row text-center justify-content-center">
                    <span class="span-modal id-modal"></span>
                </div>
            </div>
        </div>
        <div  class="row row-cols-4 row-cols-lg-4 mx-0  py-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <button (click)="contactEmailValidate(); modal.dismiss()" type="button" class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept">
                        <span>Consultar</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!----------------------------------------FORMULARIO DE NUEVO CONTACTO------------------------------------------->
<ng-template #nuevoContacto let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal ">Nuevo Contacto</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
            <form [formGroup]="contactForm" class="d-flex flex-row flex-wrap">
                <div class="col-3 justify-content-center">
                    <div class="col pt-3">
                        <label class="label-entity">Nombre (*)</label>
                    </div>
                    <div class="col">
                        <input class="input-modal input-agregar" formControlName="nombre" type="text" placeholder="" style="text-transform: uppercase;">
                        <app-field-error [field]="contactForm.controls['nombre']"></app-field-error>
                    </div>
                </div>
                <div class="col-3 justify-content-center">
                    <div class="col pt-3">
                        <label class="label-entity">Apellido (*)</label>
                    </div>
                    <div class="col">
                        <input class="input-modal input-agregar" formControlName="apellido" type="text" placeholder="" style="text-transform: uppercase;">
                        <app-field-error [field]="contactForm.controls['apellido']"></app-field-error>
                    </div>
                </div>
                <div class="col-3 justify-content-center">
                    <div class="col pt-3">
                        <label class="label-entity">Sexo</label>
                    </div>
                    <div class="col-12">
                        <ng-select placeholder="Seleccione" formControlName="genero"
                            [clearable]="false" [searchable]="true" class="select-modal">
                            <ng-option *ngFor="let genero of genders" value="{{genero.id}}">
                                <span>{{genero.descripcion}}</span>
                            </ng-option>
                        </ng-select> 
                        <app-field-error [field]="contactForm.controls['genero']"></app-field-error>
                    </div>
                </div>
                <div class="col-3 justify-content-center">
                    <div class="col pt-3">
                        <label class="label-entity">Tel&eacute;fono 1</label>
                    </div>
                    <div class="col">
                        <input class="input-modal input-agregar" formControlName="telefono" type="text" placeholder="">
                        <app-field-error [field]="contactForm.controls['telefono']"></app-field-error>
                    </div>
                </div>
                <div class="col-3 justify-content-center">
                    <div class="col pt-3">
                        <label class="label-entity">Tel&eacute;fono 2</label>
                    </div>
                    <div class="col">
                        <input class="input-modal input-agregar" formControlName="telefonoMovil" type="text" placeholder="">
                        <app-field-error [field]="contactForm.controls['telefonoMovil']"></app-field-error>
                    </div>
                </div>
                <div class="col-3 justify-content-center">
                    <div class="col pt-3">
                        <label class="label-entity">Correo electr&oacute;nico</label>
                    </div>
                    <div class="col">
                        <input class="input-modal input-agregar" readonly formControlName="email" type="text" placeholder="{{this.emailContactoPermanente}}">
                        <app-field-error [field]="contactForm.controls['email']"></app-field-error>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer" style="border: none;">
            <button (click)="crearContacto();" 
                class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept" style="width: 70px;">
                Crear
            </button>
        </div>
</ng-template>


<!-- MODAL DE ADVERTENCIA DE CONTENIDO CONFIDENCIAL-->
<ng-template #confirmarcomentario let-modal>
    <div class="modal-body modal-background">
        <div class="row row-cols-lg-1 py-2  justify-content-center">
            <div class="col-lg-8 text-center text-modal" >
                <span>¡Atención! </span>
            </div>
        </div>

        
        <div class="row row-cols-lg-1 py-3 justify-content-center">
            <div class="col-lg-8 text-center">
                <div class="d-flex row text-center justify-content-center">
                <fa-icon class="mr-2 text-danger fa-3x" [icon]="faExclamationTriangle"></fa-icon>
                </div>
                <div class="d-flex row text-center justify-content-center">
                    <span>
                        Parece que la descripción del ticket podría contener información confidencial, como un número de tarjeta. Antes de continuar, te recomendamos revisar y asegurarte de que no estés compartiendo datos sensibles. Por favor, confirma si deseas continuar
                    </span>
                </div>
            </div>
        </div>
        <div class="row row-cols-4 row-cols-lg-4 mx-0  py-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <button type="button" (click)="modal.close(true)" class="btn btn-primary-portal btn-primary btn-sm">
                        <span>Lo entiendo, crear ticket</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row row-cols-4 row-cols-lg-4 mx-0  pb-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex  justify-content-center">
                    <button type="button" (click)="modal.dismiss()"  class="btn btn-secondary btn-sm">
                        <span>Cancelar y editar descripción</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>