<div *ngIf="loading" class="d-flex flex-column align-items-center justify-content-center w-100 h-100 spinner-background">
    <fa-icon [icon]="faSpinner" class="fa-2x mt-5 d-flex justify-content-center " style="color: #fff;" animation="spin"></fa-icon>
    <span class="label-entity mt-2" style="color:#fff">{{loadingMessage.value}}</span>
</div>
<ng-template #captcha let-modal>
    <div class="modal-body modal-background">
        <div class="row row-cols-lg-1 py-2  justify-content-center">
            <div class="col-lg-8 text-center text-modal" >
                    <span>¿No eres un robot?</span>
            </div>
        </div>
        <div class="row row-cols-lg-1 py-3 justify-content-center">
            <div class="col-lg-8 text-center text-modal" >
                <div class="d-flex row text-center justify-content-center">
                    <h1>Captcha</h1>
                </div>
                <div class="d-flex row text-center justify-content-center">
                    <re-captcha (resolved)="captchaResolved()" (errored)="captchaError($event)" errorMode="handled"></re-captcha>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<div class="background-login login container-fluid">
    <div class="flex-row h-100">
        <div class="flex-column d-flex justify-content-center">

            <div class="row row-cols-7 pt-5 justify-content-center">
                <div class="d-flex flex-column justify-content-center">
                    <img src="../../assets/imgs/Bancard-Logo.svg"  class="logo puntero pointer" alt="Logo Bancard">
                </div>
            </div>
            <div class="row row-cols-7 mt-3 justify-content-center">
                <div class="d-flex flex-column justify-content-center">
                    <span class="text-bancard">Portal de entidades</span>
                </div>
            </div>
            <div class="row justify-content-center">
                <span class="text-btn-portal mt-2" style="font-size: small">Versión {{ver}}</span>
            </div>
        </div>
        <form [formGroup]="formLogin" (ngSubmit)="login(false)" class="flex-column d-flex  h-75 justify-content-center" *ngIf="!loading">
                    <div class="row row-cols-4 justify-content-center">
                        <div class="container login-container">
                            <div class="d-flex flex-column">
                                    <div class="row row-cols-1 justify-content-center border-login-bottom">
                                        <div class="container-login-top">
                                            <div class="d-flex flex-column">
                                                <input class="input-login" required formControlName="username" placeholder="Usuario" type="text">
                                            </div>
                                        </div>
                                    </div>

                                <div class="row row-cols-1 justify-content-center">
                                    <div class="container-login-bottom">
                                        <div class="d-flex flex-column">
                                            <div class="d-flex flex-row">
                                                <div class="d-flex column" style="width: 91%;">
                                                    <input class="input-login" placeholder="Contraseña" autocomplete="off" required formControlName="password" style="width: 100%;" [type]="showPass ? 'text' : 'password'">
                                                </div>
                                                <div class="flex-column justify-content-center align-self-center" style="width: 50px;">
                                                    <span><fa-icon class="puntero pointer" [icon]="showPass ? faEye : faEyeSlash" (click)="showPassword()"></fa-icon></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-cols-4 py-4 justify-content-center">
                        <div class="d-flex flex-column">
                                <button class="btn-portal" type="submit">
                                    <span class="text-btn-portal"> Iniciar Sesi&oacute;n </span>
                                </button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center text-center pt-5">
                        <img
                            src="../../assets/imgs/pci-dss-white.png" 
                            class="logo puntero pointer small-image" alt="Logo PCI">
                            
                    </div>
        </form>

         
    <div class="flex-column d-flex  h-15 justify-content-center" *ngIf="!loading">

        <div class="row row-cols-7 justify-content-center">
            <div class="d-flex flex-column">
                    <span class="text-btn-portal reset-pw puntero pointer" (click)="forgottenPassword()">  ¿Olvid&oacute; su contraseña?   </span>

            </div>
        </div>
    </div>
</div>
</div>
