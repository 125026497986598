import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { StorageService } from './storage.service';
import { RequestService } from './request.service';
import { Entidad, Param, Usuario } from '../interfaces/interfaces';
import { Constantes } from '../constants/constantes.const';
import { HttpResponse } from '@angular/common/http';
import moment from 'moment';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private requestSrv: RequestService,
    private storageSrv: StorageService,
    private encryptSrv: EncryptionService
  ) { }

  /** Método para obtener los usuarios
   * @param filters objeto con 
   * - entityID código de entidad
   * - onlyActive mostrar solo activos
   * - ignoreActual ignora el usuario actual
   * @returns lista de usuarios, si se produce algún error devuelve undefined
   */
  async getUsers(filters?: { id?: string, email?: string, entityID?: string, onlyActive?: boolean, ignoreActual?: boolean }) {
    const nUsers: Usuario[] = [];
    const actualUser = this.getActualUser();

    // instancia objeto para guardar encabezados de peticion html
    const customHeaders: Param[] = [];

    // obtiene la clave pública de la API
    const api_pub_key = this.encryptSrv.API_PUB_KEY;

    // encripta la clave pública del fromt
    const pub = this.encryptSrv.encryptRSA(this.encryptSrv.PUB, api_pub_key);

    // agrega la clave pública encriptada a los encabezados de la petición
    customHeaders.push({ key: 'pkey', value: pub });

    customHeaders.push({ key: 'x-user', value: actualUser.id.toString() });

    const $source = this.requestSrv.getRequest('API', '/users', customHeaders, null, true);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      if ([200].includes(res.body.code)) {

        const encryptedData = res.body.data;

        // desencripta el dato obtenido
        let users = this.encryptSrv.decryptResultData(encryptedData);

        if (filters) {
          users = users.filter((u: any) => {
            return (filters.id ? u._id == filters.id : true) &&
              (filters.email ? u.email == filters.email : true) &&
              (filters.entityID ? u.entity._id == filters.entityID : true) &&
              (filters.onlyActive ? filters.onlyActive == u.enabled : true) &&
              (filters.ignoreActual ? u._id != actualUser.id : true);
          })
        }
        users.forEach((u: any) => {
          let i = true;
          u.roles?.forEach(rol => {
            if (rol && Constantes.IGNORE_USER_ROLS.includes(rol.codigo)) {
              i = false;
            }
          })
          if (i) {
            const entidad: Entidad = {
              id: u.entity._id,
              C4C_OID: u.entity.c4c_ObjectID,
              C4C_ID: u.entity.c4c_id,
              nombre: u.entity.name,
              codigoEntidad: u.entity.codigoEntidad,
              fuerzaDeVenta: u.entity.fuerzaDeVenta,
              activo: u.entity.enabled
            }
            const user: Usuario = {
              id: u._id,
              email: u.email,
              nombre: u.name,
              apellido: u.surname,
              nombreCompleto: `${u.name} ${u.surname}`,
              rol: u.rol,
              entidad: entidad,
              fechaCreacion: u.created_date? moment(new Date(u.created_date)).format("DD/MM/YYYY HH:mm:ss"):'',
              fechaActualizacion: u.updated_date? moment(new Date(u.updated_date)).format("DD/MM/YYYY HH:mm:ss"):'',
              usuarioCreacion: u.created_by,
              usuarioActualizacion: u.updated_by,
              roles: u.roles,
              perfiles: u.perfiles,
              activo: u.enabled
            }
            nUsers.push(user);
          }
        });
        return nUsers;
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }

  /** Método para obtener un usuario por su id
   * @param id id del usuario
   * @returns objeto con datos del usuario, si se produce algun error devuelve undefined
   */
  async getUserById(id: string) {
    const actualUser = this.getActualUser();

    // instancia objeto para guardar encabezados de peticion html
    const customHeaders: Param[] = [];

    // obtiene la clave pública de la API
    const api_pub_key = this.encryptSrv.API_PUB_KEY;

    // encripta la clave pública del fromt
    const pub = this.encryptSrv.encryptRSA(this.encryptSrv.PUB, api_pub_key);

    // agrega la clave pública encriptada a los encabezados de la petición
    customHeaders.push({ key: 'pkey', value: pub });

    customHeaders.push({ key: 'x-user', value: actualUser.id.toString() });

    const $source = this.requestSrv.getRequest('API', `/users/${id}`, customHeaders, null, true);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      if ([200].includes(res.body.code)) {

        const encryptedData = res.body.data;

        // desencripta el dato obtenido
        const u = this.encryptSrv.decryptResultData(encryptedData);

        const entidad: Entidad = {
          id: u.entity._id,
          C4C_OID: u.entity.c4c_ObjectID,
          C4C_ID: u.entity.c4c_id,
          nombre: u.entity.name,
          codigoEntidad: u.entity.codigoEntidad,
          fuerzaDeVenta: u.entity.fuerzaDeVenta,
          activo: u.entity.enabled
        }
        const user: Usuario = {
          id: u._id,
          email: u.email,
          nombre: u.name,
          apellido: u.surname,
          nombreCompleto: `${u.name} ${u.surname}`,
          rol: u.rol,
          entidad: entidad,
          fechaCreacion: moment(new Date(u.created_date)).format("DD/MM/YYYY HH:mm:ss"),
          fechaActualizacion: moment(new Date(u.updated_date)).format("DD/MM/YYYY HH:mm:ss"),
          usuarioCreacion: u.created_by,
          usuarioActualizacion: u.updated_by,
          roles: u.roles,
          perfiles: u.perfiles,
          activo: u.enabled
        }

        return user;
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }

  /** Método para obtener un usuario por su email
   * @param email correo electrónico del usuario
   * @returns objeto con datos del usuario, si se produce algun error devuelve undefined
   */
  async getUserByEmail(email: string) {
    const users = await this.getUsers({ email: email });
    if (users) {
      return users[0]
    } else {
      return undefined
    }
  }

  /** Método para crear un nuevo usuario
   * @param datos del usuario
   * @returns TRUE cuando se crea correctamente, sino FALSE
   */
  async createUser(data: any): Promise<{
    created: boolean;
    status?;
    message?;
  } | {
    created: boolean;
    status: number;
    message: string;
  }> {
    const $source = this.requestSrv.postRequest('API', `/users`, data);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      return { created: true }
    } else {
      return { created: false, status: res.status, message: res.message }
    }
  }

  /** Método para actualizar datos de un usuario
   * @param id OID del usuario
   * @param payload objeto con datos a modificar
   * @returns TRUE cuando se actualiza correctamente, sino FALSE
   */
  async updateUser(id: string, payload: any) {
    const endpoint = `/users/${id}`;
    const $source = this.requestSrv.putRequest('API', endpoint, payload);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      return true
    } else {
      return false
    }
  }

  /** Método para actualizar los roles de un usuario
   * @param id id del usuario a actualizar
   * @param paylad objeto con datos de roles a asignar o desasignar
   * @returns TRUE cuando se actualiza correctamente, sino FALSE
   */
  async updateUserRoles(id: string, payload: any) {
    const endpoint = `/users/${id}/roles`;
    const $source = this.requestSrv.putRequest('API', endpoint, payload);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      return true
    } else {
      return false
    }
  }

  /** Método para actualizar los roles de un usuario
   * @param id id del usuario a actualizar
   * @param paylad objeto con datos de roles a asignar o desasignar
   * @returns TRUE cuando se actualiza correctamente, sino FALSE
   */
  async updateUserPerfiles(id: string, payload: any) {
    const endpoint = `/users/${id}/perfiles`;
    const $source = this.requestSrv.putRequest('API', endpoint, payload);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      return true
    } else {
      return false
    }
  }

  /** Método para asignar el usuario actual
 * @param userData Datos de usuario en json-ODATA
 */
  setActualUser(userData: any) {
    const entidad: Entidad = {
      id: userData.entity._id,
      C4C_OID: userData.entity.c4c_ObjectID,
      C4C_ID: userData.entity.c4c_id,
      nombre: userData.entity.name,
      codigoEntidad: userData.entity.codigoEntidad,
      fuerzaDeVenta: userData.entity.fuerzaDeVenta,
      activo: userData.entity.enabled,
      ejecutivo: userData.entity.ejecutivo
    }
    const usuario = {
      OID: userData.C4C_ObjectID,
      id: userData._id,
      email: userData.email,
      nombre: userData.name,
      apellido: userData.surname,
      nombreCompleto: `${userData.name} ${userData.surname}`,
      rol: userData.rol,
      entidad: entidad,
      roles: userData.roles,
      fechaCreacion: userData.created_date,
      fechaActualizacion: userData.updated_date,
      usuarioCreacion: userData.created_by,
      usuarioActualizacion: userData.updated_by,
      lastLogin: userData.last_login
    }
    this.storageSrv.save('user', JSON.stringify(usuario), true, true);
  }

  /** Método para obtener el usuario actual */
  getActualUser() {
    let usuario;
    const user = this.storageSrv.get('user', true, true)
    if (user) {
      usuario = JSON.parse(user) as Usuario;
    }
    return usuario;
  }

  /** Método para eliminar datos del usuario actual */
  removerActualUser() {
    this.storageSrv.remove('user');
  }

  /**
   * Método que chequea si un usuario tiene o no los permisos enviados
   * @param permisos - Un array de permisos a ser chequeados
   * @param criterio - Por defecto el tomado es `AND`, Significa que todos los permisos que se encuentran 
   * en el array permisos deben estar asignados al Rol. 
   * Si se envia `OR` es suficiente con que al menos un permiso este asignado a los roles del usuario
   * @returns true en caso de de que tenga el permiso, false en caso de que no 
   */
  actualUSerHasPerm(permisos: any[], criterio?: string) {
    const currentUser = this.getActualUser()
    if (currentUser.roles) {
      if (criterio == 'OR') {
        for (const rol of currentUser.roles) {
          for (const privilegio of rol.privileges) {
            if (permisos.includes(privilegio.codigo)) {
              return true;
            }
          }
        }
        return false;
      } else {
        const allPrivileges: string[] = []
        for (const rol of currentUser.roles) {
          for (const privilegio of rol.privileges) {
            if (!allPrivileges.includes(privilegio.codigo)) {
              allPrivileges.push(privilegio.codigo)
            }
          }
        }

        let countPermisosFound = 0;
        for (const permiso of permisos) {
          if (allPrivileges.includes(permiso)) {
            countPermisosFound++;
          }
        }
        if (countPermisosFound == permisos.length) { return true } else { return false }
      }

    }
    return false
  }

  /** Método para obtener los usuarios
   * @param ignoreActual ignora el usuario actual
   * @returns lista de usuarios, si se produce algún error devuelve undefined
   */
  async getUsersV2(params: Param[], ignoreActual?: boolean) {
    const nUsers : Usuario[] = [];
    const actualUser = this.getActualUser();

    // instancia objeto para guardar encabezados de peticion html
    const customHeaders: Param[] = [];

    // obtiene la clave pública de la API
    const api_pub_key = this.encryptSrv.API_PUB_KEY;

    // encripta la clave pública del fromt
    const pub = this.encryptSrv.encryptRSA(this.encryptSrv.PUB,api_pub_key);

    // agrega la clave pública encriptada a los encabezados de la petición
    customHeaders.push({key:'pkey',value:pub});

    customHeaders.push({key:'x-user',value: actualUser.id.toString()});

    const $source = this.requestSrv.getRequest('API','/users/v2', customHeaders, params, true);
    const res = await lastValueFrom($source);
    if (res instanceof HttpResponse) {
      if ([200].includes(res.body.code)) {

        const encryptedData = res.body.data;

        // desencripta el dato obtenido
        const data = this.encryptSrv.decryptResultData(encryptedData);

        const users = data.datos;

        users.forEach((u: any) => {
          if (ignoreActual ? u._id!=actualUser.id : true) {
            const entidad: Entidad = {
              id: u.entity._id,
              C4C_OID: u.entity.c4c_ObjectID,
              C4C_ID: u.entity.c4c_id,
              nombre: u.entity.name,
              codigoEntidad: u.entity.codigoEntidad,
              fuerzaDeVenta: u.entity.fuerzaDeVenta,
              activo: u.entity.enabled
            }
            const user: Usuario = {
              id: u._id,
              email: u.email,
              nombre: u.name,
              apellido: u.surname,
              nombreCompleto: `${u.name} ${u.surname}`,
              rol: u.rol,
              entidad: entidad,
              fechaCreacion: moment(new Date(u.created_date)).format("DD/MM/YYYY HH:mm:ss"),
              fechaActualizacion: moment(new Date(u.updated_date)).format("DD/MM/YYYY HH:mm:ss"),
              usuarioCreacion: u.created_by,
              usuarioActualizacion: u.updated_by,
              roles: u.roles,
              perfiles: u.perfiles,
              activo: u.enabled
            }
            nUsers.push(user);
          }
        });
        return {users: nUsers, total: data.total};
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

}
