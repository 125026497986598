import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { ToastrService } from "ngx-toastr";
import { LoginService } from "../services/login.service";
import { StorageService } from "../services/storage.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";
import { formLogin } from "./login.form"; //Importa el nuevo grupo de formulario
import { Title } from "@angular/platform-browser";
import { Mensajes } from "../constants/mensajes.const";
import { UsersService } from "../services/users.service";
import { BehaviorSubject } from "rxjs";
import { SocketService } from '../services/socket.service';
import { RecaptchaService } from '../services/recaptcha.service';
import { EncryptionService } from "../services/encryption.service";
import packageInfo from '../../../package.json';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @ViewChild('captcha') captcha;
  formLogin: FormGroup;

  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faSpinner = faSpinner;

  showPass = false;
  ver = packageInfo.version;
  
  loadingMessage = new BehaviorSubject("");
  loading = false;
  captchaToken = "";
  lastVisibilityChange: Date | null = null;
  timeout;

  constructor(
    private storageSrv: StorageService,
    private loginSrv: LoginService,
    private modalSrv: NgbModal,
    private toastr: ToastrService,
    private usersSrv: UsersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private socketService: SocketService,
    private catpchaSrv: RecaptchaService,
    private encryptSrv: EncryptionService
  ) {
    this.formLogin = formLogin;
    this.generarTokenCaptcha();
  }

  async ngOnInit() {
    this.modalSrv.dismissAll();
    this.title.setTitle(
      `${this.activatedRoute.snapshot.data.titulo} - ${environment.app_name}`
    );
    this.storageSrv.clear(true);
    this.storageSrv.remove('token');
    this.activatedRoute.queryParams.subscribe(async (params) => {
      if (params["email"] != null && params["pass"] != null) {
        await this.login(true, { username: params["email"], pass: params["pass"] });
      }
    });
    // obtiene nuevo token
    document.addEventListener('visibilitychange', this.onVisibilityChange);
  }

  ngOnDestroy() {
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  onVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      // App is paused or hidden, record the current time
      this.lastVisibilityChange = new Date();
    } else {
      // App is visible again, calculate elapsed time
      const now = new Date();
      if (this.lastVisibilityChange) {
        const elapsed = (now.getTime() - this.lastVisibilityChange.getTime()) / 1000; // Convert milliseconds to seconds
        if (elapsed > 120) { // 120 seconds = 2 minutes
          window.location.reload(); // Reload the page
        }
      }
    }
  };

  showPassword() {
    this.showPass = !this.showPass;
  }

    /** Método para verificar el captcha 
     * @returns boolean - true si es válido, false si no lo es
    */
    async verifyToken(token) {
      const valid = await this.catpchaSrv.verify(token);
      switch (valid) {
        case 0:
          return true;
        case 1:
          this.toastr.success("Complete el captcha antes de continuar");
          this.modalSrv.open(this.captcha, {backdrop: 'static', keyboard: false, modalDialogClass: 'modal-body'});
          return false;
        case 2:
          this.toastr.clear();
          this.toastr.warning("El captcha ha expirado, por favor vuelva a intentarlo");
          setTimeout(() => {
            window.location.reload();
          },2000);
          return false;
        default:
          this.toastr.warning("Error al comprobar usuario");
          return false;
      }
    }

  /** Método para iniciar sesión */
  async login(captchaVerified: boolean, data?: { username: string; pass: string }) {
    this.modalSrv.dismissAll();
    this.loading = true;
    this.loadingMessage.next("Comprobando");
    let verified = captchaVerified;
    if (!captchaVerified) {
      verified = await this.verifyToken(this.captchaToken);
    } 
    if (verified) {
      if (!data) {
        data = { username: this.formLogin.value.username.trim(), pass:  this.formLogin.value.password }
      }
      const k = this.encryptSrv.randomString(16);
      const encryptedData = this.encryptSrv.encrypt(JSON.stringify(data),k);
      const res = await this.loginSrv.login(encryptedData+k);
      if (res) {
        this.loading = false;
        switch (res.code) {
          case 200:
            if (res.data.user.firstLogin) {
              this.usersSrv.setActualUser(res.data.user);
              this.storageSrv.save("pwac", data.pass, true, true);
              this.formLogin.get("password")?.reset();
              this.router.navigate(["login", "createpassword"]);
              return;
            } else {
              // extrae llave de encriptado
              const r_key: string = res.data.keys.substring(0, 44);

              // inicializa el servicio de encriptado
              this.encryptSrv.init(r_key);

              // extrae otras llaves encriptadas
              const keys: string = res.data.keys.substring(44);

              // separa el vector de inicialiación
              const encryptedWithIV = this.encryptSrv.extractIV(keys, 32);

              // desencripta llaves
              const nKeys = this.encryptSrv.decrypt(
                encryptedWithIV.encrypted,
                encryptedWithIV.iv
              );
              if (nKeys) {
                // asigna llaves al servicio
                const keys = JSON.parse(nKeys);
                this.encryptSrv.setKeys(
                  keys.pub,
                  keys.priv,
                  keys.pass,
                  keys.api_pub_key
                );
              }

            // guarda las llaves y otros datos
            this.storageSrv.save("rKey", r_key, true);
            this.storageSrv.save("keys", keys, true);
            this.storageSrv.save("token", res.data.token);
            this.storageSrv.save("refreshToken", res.data.refreshToken);
            this.storageSrv.save("started", "true");
            this.storageSrv.save("exp", res.data.exp.toString());
            this.usersSrv.setActualUser(res.data.user);
            this.formLogin.reset();
            this.router.navigate(["", "tickets", "list"]);
            this.socketService.connect()
          }
          break;
        case 401:
          switch (res.data.code) {
            case 1:
              // user not found
              this.toastr.error(Mensajes.ERROR_AL_INICIAR_SESION, "", {
                positionClass: "toast-top-center",
                progressBar: false,
              });
              return;
            case 2:
              // credenciales incorrectas
              this.toastr.error(Mensajes.CREDENCIALES_INCORRECTAS, "", {
                positionClass: "toast-top-center",
                progressBar: false,
              });
              this.captchaToken = await this.catpchaSrv.createToken('login');
              return;
            case 3:
              // contraseña expirada
              this.storageSrv.save("pwac", this.formLogin.value.password, true , true);
              this.router.navigate(["login", "passwordexpired"]);
              return;
            case 4:
              // usuario bloqueado
              this.toastr.error(
                Mensajes.USUARIO_BLOQUEADO + " " + res.data.msg.split(":")[1] + " minutos.",
                "",
                { positionClass: "toast-top-center", progressBar: false }
              );
              return;
            case 5:
              // usuario inactivo
              this.toastr.error(
                Mensajes.USUARIO_INACTIVO,"",{ positionClass: "toast-top-center", progressBar: false }
              );
              return;
            case 6:
              // usuario sin permisos
              this.toastr.error(Mensajes.USUARIO_SIN_PERMISOS, "", {
                positionClass: "toast-top-center",
                progressBar: false,
              });
              return;
            default:
              // error desconocido
              this.toastr.error(Mensajes.ERROR_AL_INICIAR_SESION, "", {
                positionClass: "toast-top-center",
                progressBar: false,
              });
              return;
          }
        }
      } else {
        this.toastr.error(Mensajes.ERROR_AL_INICIAR_SESION, "", {
          positionClass: "toast-top-center",
          progressBar: false,
        });
      }
    } 
    this.loading=false;
  }

  forgottenPassword(){
    this.router.navigate(['login', 'recovery']);
  }

  captchaResolved() {
    setTimeout(() => {
      this.login(true)
    },500)
  }

  captchaError(error) {
    console.log(error);
  }

  async generarTokenCaptcha() {
    this.captchaToken = await this.catpchaSrv.createToken('login');
    this.timeout = setTimeout(async() => {
      this.generarTokenCaptcha();
    },120000)
  }

}
