<div class="d-flex flex-row justify-content-center pt-3 mt-3">
    <span class="main-title">Productos asociados</span>
</div>

<div class="col-lg-12 col-sm-12" style="font-size: small;">

    <div  class="table-responsive">
        <table class="table table-style table-font-size">
            <tr class="py-2">
               
                <th scope="col">
                    Producto
                </th>
                <th scope="col" >
                    Descripcion
                </th>
                <th scope="col" >
                    Categoria
                </th>
                <th scope="col" >
                    Dispositivo
                </th>
                <th scope="col" >
                    Cantidad
                </th>
                <th scope="col">
                    Costo
                </th>
                <th scope="col">
                    Descuento
                </th>
                <th scope="col">
                    Descuento Hasta
                </th>
                <th scope="col">
                    Serie
                </th>
            </tr>

            <tbody *ngFor="let producto of productListLocal | slice: (page-1) * pageSize : page * pageSize" style="border:none;" >
                <tr class="py-2 mt-2" *ngIf="productListLocal.length!=0" >
                    
                    <td>
                        {{producto.ProductID}}
                    </td>
                    <td>
                        {{producto.ProductIDDescription}}
                    </td>
                    <td>
                        {{producto.ProductCategoryDescription}}
                    </td>
                    <td>
                        {{producto.Z_Dispositivo_KUTText}}
                    </td>
                    <td>
                        {{formatNumber(producto.Quantity)}}

                    </td>
                    <td>
                        {{producto.NetAmountCurrencyCode == '' ? producto.Z_MONEDAPOSICION_KUT : producto.NetAmountCurrencyCode }} {{producto.Z_Costo_KUTText=='' ? producto.Z_Costo_KUT : producto.Z_Costo_KUTText}}
                    </td>
                    <td>
                        {{producto.Z_IMPORTEDESCUENTOcurrencyCode_KUT}} {{formatNumber(producto.Z_IMPORTEDESCUENTOContent_KUT)| number}}
                    </td>
                    <td>
                        {{producto.Z_FECHAHASTADESCUENTO_KUT ? (producto.Z_FECHAHASTADESCUENTO_KUT | date:'dd/MM/YYYY'):''}}
                    </td>
                    <td>
                        {{ producto.Z_SERIE_KUT  }}
                    </td>
                </tr>
            </tbody>
            <tbody align="center" *ngIf="loading">
                <td colspan="8">
                    <span><fa-icon [icon]="faSpinner" animation="spin"></fa-icon></span>
                </td>
            </tbody>
            <tbody align="center" *ngIf="productListLocal.length==0 && !loading" >
                <td colspan="8"> 
                    <span class="span-empty">No hay productos asociados</span> 
                </td>
            </tbody>
            
        </table>
        <ngb-pagination *ngIf="productListLocal.length!=0"  [(page)]="page" [maxSize]="3" [pageSize]="pageSize" [collectionSize]="collectionSize" [boundaryLinks]="true"> </ngb-pagination>

    </div>
 <!-- Tabla de productos -->


