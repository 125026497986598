import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { RequestService } from './request.service';
import { Param } from '../interfaces/interfaces';
import { EncryptionService } from './encryption.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService{


  constructor(
    private requestSrv: RequestService,
    private encryptSrv: EncryptionService,
    private usersSrv: UsersService
  ) {}

  /** Método para enviar correo electrónico
   * @param body cuerpo a enviar
   * @param type tipo de email o plantilla
   * @return TRUE cuando se envió correctamente, sino FALSE
   */
  async sendMail(body: any, type:string, encrypted?: boolean) : Promise<boolean>{
    // instancia objeto para guardar encabezados de peticion html
    const customHeaders: Param[] = [];

    // comprueba se pide encriptado
    if (encrypted) {

      // obtiene la clave pública de la API
      const api_pub_key = this.encryptSrv.API_PUB_KEY;

      // encripta la clave pública del fromt
      const pub = this.encryptSrv.encryptRSA(this.encryptSrv.PUB,api_pub_key);

      // agrega la clave pública encriptada a los encabezados de la petición
      customHeaders.push({key:'pkey',value:pub});
    }

    customHeaders.push({key:'x-user',value:this.usersSrv.getActualUser().id.toString()});
    const $source = this.requestSrv.postRequest('API',`/notification/?type=${type}`,body,customHeaders)
    const res = await lastValueFrom($source);
    return [201].includes(res.status);
  }

}
