<app-loadingSpinner [message]="loadingMessage" [show]="loading"></app-loadingSpinner>
<div class="row px-3">
    <div class="col-12">
        <span class="page-route">Auditoria</span>
    </div>
    <div class="col-12 py-3">
        <app-title></app-title>
    </div>
    <div class="col-12 d-flex align-content-start flex-wrap">
        <div class="d-flex mr-2 mt-2 align-items-center">
            <ng-select [(ngModel)]="selectedUser" placeholder="Usuario" [clearable]="false" style="min-width: 300px;">
                <ng-option  value="">
                    <span><b>Usuario</b></span> 
                 </ng-option>
                <ng-option *ngFor='let user of filteredUsers' [value]="user.email">
                   <span>{{user.email}}</span> 
                </ng-option>
            </ng-select> 
        </div>
        <div class="d-flex mr-3 mt-2 flex-row align-items-center">
            <label class="label-entity" style="width:50px;">Fecha desde</label>  
            <input class="main-content-input-ticket date-input" type="date" [(ngModel)]="dateSince">
        </div>
        <div class="d-flex mr-3 mt-2 flex-row align-items-center">
            <label class="label-entity" style="width:50px;">Fecha hasta</label>   
            <input class="main-content-input-ticket date-input"  type="date" [(ngModel)]="dateUntil">
        </div>
        <div class="d-flex mr-2 mt-2 align-items-center pointer">
            <button class="btn btn-primary-portal btn-primary btn-sm mr-2" type="button" (click)="filter()">
                <fa-icon [icon]="faFilter" class="mr-1"></fa-icon>Filtrar
            </button>
        </div>
        <div class="d-flex mr-2 mt-2 align-items-center pointer">
            <button class="btn btn-primary-portal  btn-primary btn-sm" type="button" (click)="exportExcel()">
                <fa-icon [icon]="faFileExcel" class="mr-1"></fa-icon>
                Exportar
            </button>
        </div>
    </div>  
    <bpe-grid *ngIf="!loading" class="w-100" [configuration]="tableConfig" [find]="tableFilter">
    </bpe-grid>
</div>
