<!-- Spinner de carga -->
<app-loadingSpinner [message]="loadingMessage" [show]="loading"></app-loadingSpinner>
<!-- Página -->
<div *ngIf="ticket" @fade> 
    <!-- Cabecera -->
    <div class="row px-3 new-entity">
        <div class="col-12" >
            <a class="page-route puntero" [routerLink]="['tickets','tickets']">Tickets&nbsp;</a>
            <span class="page-route">/&nbsp;</span>
            <span class="page-route font-weight-bold">{{ idTicket }}</span>
        </div>
        <div class="d-flex col-12 py-3">
            <span title="{{ticket.Name}}" class="main-title font-weight-bold overflow-text">{{ ticket.Name }}</span>
        </div>
    </div>
    <div class="row px-3">
        <!-- Panel izquierdo -->
        <div class="col-lg-8 col-xl-8 col-md-12 cold-sm-12" *ngIf="(ticket.ProcessingTypeCode!='ZAE' || anulacionEditable)">
            
            <!-- Soporte ATM | CA513: Alta, CA514: Baja, CA515: Reubicación, CA516: Soporte -->
            <div class="w-100" *ngIf="ticket.ProcessingTypeCode == 'ZSAT'">
                <div class="row row-cols-1 row-cols-md-4">
                    <div class="col py-2" *ngIf="['CA513','CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Marca</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.z_CabTickMarca_KUTText}}">
                        </div>
                    </div>
                    <div class="col py-2" *ngIf="['CA513','CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Modelo</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.z_CabTIckModelo_KUTText}}">
                        </div>
                    </div>
                    <div class="col py-2" *ngIf="['CA513','CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Suministro</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.z_CabTickSuministro_KUTText}}">
                        </div>
                    </div>
                    <div class="col py-2" *ngIf="['CA513','CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Fecha/Hora creaci&oacute;n</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{formatSapDate(ticket.z_CanTickFechaHoraInstalacinATM_KUT)}}">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 pt-2"
                    *ngIf="['CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <label class="label-entity" style="font-size: 1.2rem!important"><i>Direcci&oacute;n de origen</i></label>
                    </div>
                    <div class="col py-2" *ngIf="['CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Departamento</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.zOrig_idDepartKUT_KUTText}}">
                        </div>
                    </div>
                    <div class="col py-2" *ngIf="['CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Ciudad</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.zOrig_DescCiudad_SDK.split(' - ')[1]}}">
                        </div>
                    </div>
                    <div class="col py-2" *ngIf="['CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Barrio</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.zOrig_DescBarrio_SDK.split(' - ')[1]}}">
                        </div>
                    </div>
                    <div class="col py-2" *ngIf="['CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Direcci&oacute;n</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.zOrig_Direccion_SDK}}">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 pt-2"
                    *ngIf="['CA513','CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <label class="label-entity" style="font-size: 1.2rem!important"><i>Direcci&oacute;n de destino</i></label>
                    </div>
                    <div class="col py-2" *ngIf="['CA513','CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Departamento</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.zDest_idDepartKUT_KUTText}}">
                        </div>
                    </div>
                    <div class="col py-2" *ngIf="['CA513','CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Ciudad</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.zDest_DescCiudad_SDK.split(' - ')[1]}}">
                        </div>
                    </div>
                    <div class="col py-2" *ngIf="['CA513','CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Barrio</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.zDest_DescBarrio_SDK.split(' - ')[1]}}">
                        </div>
                    </div>
                    <div class="col py-2" *ngIf="['CA513','CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Direcci&oacute;n</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.zDest_Direccion_SDK}}">
                        </div>
                    </div>
                    <div class="col py-2" *ngIf="['CA513','CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Latitud</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.z_CabTickLatitud_KUT}}">
                        </div>
                    </div>
                    <div class="col py-2" *ngIf="['CA513','CA515'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Longitud</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.z_CabTickLongitud_KUT}}">
                        </div>
                    </div>
                    <div class="col py-2" *ngIf="['CA514'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Fecha/Hora de baja</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{formatSapDate(ticket.z_CabTickFechaBaja_KUT)}}">
                        </div>
                    </div>
                    <div class="col py-2" *ngIf="['CA514','CA516'].includes(ticket.ServiceIssueCategoryID)">
                        <div class="d-flex flex-column">
                            <label class="label-entity">Terminal</label>
                            <input readonly type="text" class="ticket-resumen-input input-agregar" value="{{ticket.Z_CabTickNroTermluno_KUT}}">
                        </div>
                    </div>
                </div>
            </div>
            <!-- Veredicto -->
            <div class="row my-2" *ngIf="ticket.ProcessingTypeCode == 'ZVE' || ticket.ProcessingTypeCode == 'ZPIK'">
                <div class="col-2 mr-4" *ngIf="ticket.ProcessingTypeCode == 'ZVE' || ticket.ProcessingTypeCode == 'ZPIK'">
                    <label class="label-entity font-weight-bold">N° de boleta:</label>
                    <div>
                        <span class="value-data">{{ ticket.Z_NUMERODEBOLETA_KUT == '' ? '-'  : ticket.Z_NUMERODEBOLETA_KUT}}</span>
                    </div>
                </div>
                <div class="col-2 mx-4" *ngIf="ticket.ProcessingTypeCode == 'ZVE' || ticket.ProcessingTypeCode == 'ZPIK'">
                    <label class="label-entity font-weight-bold">Importe reclamado:</label>
                    <div>
                        <span class="value-data">{{ ticket.zImporteReclamado_KUT == '' ? '-' : ticket.zImporteReclamado_KUT }}</span>
                    </div>
                </div>
                <div class="col-3 mx-4" *ngIf="ticket.ProcessingTypeCode == 'ZVE'">
                    <label class="label-entity font-weight-bold">Entidad dueña del ATM:</label>
                    <div>
                        <span class="value-data">{{ ticket.Z_CabEntidadDueadelATM_KUTText }}</span>
                    </div>
                </div>
            </div>
            <div class="row my-2 py-3">
                <div class="col-12 col-sm-12">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Descripci&oacute;n</label>
                        <div class="box-descripcion-ticket" [innerHtml]="ticket.Description | safeHtml" *ngIf="!isDescriptionEmpty"></div>
                        <div class="box-descripcion-ticket" *ngIf="isDescriptionEmpty">
                            <p class="font-italic text-muted">Sin descripción</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-2 py-3">
                <div class="col-2 mr-4" *ngIf="ticket.ProcessingTypeCode == 'Z5'">
                    <label class="label-entity font-weight-bold">N° de Tarjeta:</label>
                    <div>
                        <span class="value-data">{{ creditCard == '' ? '-'  : creditCard}}</span>
                    </div>
                    <button type="button" (click)="toggleCreditCardInfo()" class="btn btn-primary-portal  btn-primary btn-sm" *ngIf="creditCard != ''">
                        <span>{{descryptedCreditCard ? "Ocultar" : "Visualizar" }}</span>
                    </button>
                </div>
            </div>
            <!-- Z4: Anulación - Reversa, ZAE: Anulacion Entidad | YD: A la espera del facturador/ entidad-->
            <div class="row mt-2 pt-3" 
                *ngIf=" this.ticket.ProcessingTypeCode == 'Z4' || this.ticket.ProcessingTypeCode == 'ZAE' ">
                <div class="col-lg-12 col-md-12 col-sm-12 mt-3 mb-0">
                    <label class="label-entity" style="font-size: 1.2rem!important"><i>Datos de Pago</i></label>
                </div>
                <!-- Medio de Pago -->
                <div class="col">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Medio de pago</label>
                        <ng-select [disabled]="!mostrarBotones || !editarMedioDePago"
                            placeholder="Medio de pago" [(ngModel)]="ticket.zCabMediodePago_KUT"
                            [clearable]="false" [searchable]="false">
                            <ng-option value="{{pm.id}}" *ngFor="let pm of paymentMethods">
                                <span>{{pm.descripcion}}</span>
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <!-- Fecha de transferencia -->
                <div class="col" *ngIf="Constantes.MEDIOS_DE_PAGO_CON_REFERENCIA.includes(ticket.zCabMediodePago_KUT)">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Fecha de transferencia</label>
                        <input [disabled]="!mostrarBotones || !editarMedioDePago"
                            id="fechaTransferencia" class="main-content-input-ticket date-input filtro-ticket" 
                            [(ngModel)]="ticket.Z_FECHATRANSFERENCIA_KUT"
                            name="fechaTransferencia"
                            type="date">
                    </div>
                </div>
            </div>

            <div class="row mt-2 pt-3" 
                *ngIf=" this.ticket.ProcessingTypeCode == 'Z4' || this.ticket.ProcessingTypeCode == 'ZAE' ">
                <!-- Número  de referencia -->
                <div class="col" *ngIf="Constantes.MEDIOS_DE_PAGO_CON_REFERENCIA.includes(ticket.zCabMediodePago_KUT)">
                    <div class="d-flex flex-column">
                        <label class="label-entity">Nro. de referencia</label>
                        <input [disabled]="!mostrarBotones || !editarMedioDePago" class="ticket-resumen-input input-agregar" [(ngModel)]="ticket.zCabNumerodereferencia_KUT" type="text" placeholder="Nro. de referencia">
                    </div>
                </div>
            </div>

            <div class="row mt-2 pt-3" 
                *ngIf="mostrarBotones">
                <div class="col-12 col-sm-12">
                    <div class="d-flex flex-column">
                        <label class="label-entity">{{this.ticket.ProcessingTypeCode=='Z4' ? 'Reversa' : 'Anulación'}}</label>
                    </div>
                </div>
                <div class="col-12 col-sm-12 d-flex mb-2 py-3">
                    <button class="btn-anulacion-aprobar" (click)="checkAnulacion(true)">
                        Aprobar
                    </button>
                    <button class="btn-anulacion-rechazar mx-2" (click)="checkAnulacion(false)">
                        Rechazar
                    </button>
                </div>
            </div>
           
            <app-resumen-oc  *ngIf="showInstalacion" [ocId]="ocId"></app-resumen-oc>
            
            <!-- Comentarios -->
            <label class="label-entity mt-5" *ngIf="!showInstalacion" >Comentarios</label>
            <app-comentarios *ngIf="refreshCommentAfterCancel"
                (NewStatus)="changeStatus($event,true,false)" 
                (collectionSize)="checkCancelable(cantidadCambiosEstado,$event)"
                [ticket]="ticket" 
                [recipientsEmail]="recipientsEmail" 
                [allowNewComment]="!showInstalacion"
                [type]="'TK'">
            </app-comentarios>
            <br/>
            <!-- Adjuntos -->
            <label class="label-entity mt-5" *ngIf="!showInstalacion">Adjuntos</label>
            <app-adjuntos 
                [ticket]="ticket" 
                [idEvento]="idTicket" 
                [idCliente]="idCliente" 
                [canUpload]="canUploadAttached"
                *ngIf="!showInstalacion"></app-adjuntos>
        </div>
        <!-- Panel derecho -->
        <div [ngClass]="(ticket.ProcessingTypeCode!='ZAE' || anulacionEditable) ? 'col-lg-4 col-xl-4 col-md-12 cold-sm-12' : 'col-12'">
            <label class="label-entity mb-2">N&uacute;mero de ticket:</label>
            <div class="row mb-4">
                <div class="col-auto" *ngIf="!ticketAsociado?.ID">
                <div class="d-flex align-items-center">
                    <b class="value-data btn-portal" style="font-size: 1.4rem; text-align: center;">{{ ticket.ID }}</b>
                </div>
                </div>
                <div class="col-auto" *ngIf="ticketAsociado?.ID">
                    <div class="d-flex align-items-center">
                        <div class="info">
                            <b class="value-data btn-portal" style="font-size: 1.4rem;">{{ ticket.ID }}</b>
                            <span class="infotext text-center" style="width: 90px;">
                            <strong>Actual</strong>
                            </span>
                        </div>
                    </div>
                </div>
                <fa-icon *ngIf="ticketAsociado?.ID" [icon]="faAngleDoubleLeft" class="fa-2x d-flex justify-content-center " style="color: #001a33;align-items: center;" ></fa-icon>
                <div class="col-auto" *ngIf="ticketAsociado?.ID">
                    <div class="d-flex align-items-center" (click)="verDetalles(ticketAsociado?.ID)">
                        <div class="info">
                            <b class="value-data btn-portal" style="font-size: 0.9rem; margin-top: 5.7px; background: linear-gradient(90deg, rgba(29,70,115,1) 0%, rgba(0,212,255,1) 100%);">{{ ticketAsociado?.ID }}</b>
                            <span class="infotext text-center" style="width: 90px;">
                            <strong>Anterior</strong>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-1 my-3" *ngIf="loadingSla && showSLA && (ticket.ProcessingTypeCode!='ZAE' || anulacionEditable)">
                <fa-icon [icon]="faSpinner" animation="spin" class="fa-2x d-flex justify-content-center col"></fa-icon>
            </div>
            <div *ngIf="!loadingSla && showSLA && (ticket.ProcessingTypeCode!='ZAE' || anulacionEditable)" class="row row-cols-1 row-cols-md-2 my-3">
                <span  class="label-entity col mb-2">SLA primera respuesta: <strong> {{SLA.SLA1Objetivo}} horas h&aacute;biles</strong> </span>
                <div class="info">
                    <div class="w-100 progress mb-2">
                        <div class="progress-bar progress-bar-striped" [ngClass]="{'bg-fail': progressWidth > 100, 'bg-success':progressWidth <= 100}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ 'width': progressWidth + '%' }">
                        <span>{{SLA.SLA1Real}} hs</span></div>
                        <span class="infotext text-center">
                            <p>Vencimiento - Primera respuesta:</p>
                            <strong>{{formatDateString(this.ticket.FirstReactionDuedatetimeContent)}} </strong>
                        </span>
                    </div>
                </div>
                <span class="label-entity col">SLA resoluci&oacute;n: <strong>{{diasSlaObjetivo}} d&iacute;as h&aacute;biles</strong> </span>
                <div class="info">
                    <div class="w-100 progress">
                        <div class="progress-bar progress-bar-striped" [ngClass]="{'bg-fail': progressWidth2 > 100, 'bg-success':progressWidth2 <= 100}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ 'width': progressWidth2 + '%'}">
                        <span>{{diasSlaReal}} d</span></div>
                        <span class="infotext text-center">
                            <p>Vencimiento - Resoluci&oacute;n:</p>
                            <strong>{{formatDateString(this.ticket.CompleteDuedatetimeContent)}} </strong>
                        </span>
                    </div>
                </div>
            </div>
            <label class="label-entity mb-2">Estado Actual</label>
            <div class="row mb-12">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <span class="value-data" style="font-size: 1.3rem!important"><strong>{{ ticket.ServiceRequestUserLifeCycleStatusCodeText }}</strong></span>
                    </div>
                </div>
                <div class="col-12">
                    <div *ngIf="loadingTicketsHijos">
                    <fa-icon [icon]="faSpinner"animation="spin"></fa-icon>
                    </div>
                    <div class="d-flex align-items-center" *ngIf="!loadingTicketsHijos && reabrirCheck()[0]">
                        <button [disabled]="ticketHijo" type="button" class="btn btn-primary-portal btn-primary btn-sm ml-2" (click)="reOpen()">Reabrir</button>
                        <label class="label-entity col mb-2 ng-tns-c154-0" [ngClass]="ticketHijo ? 'color-reabierto' : ''"> 
                            <strong *ngIf='!ticketHijo'>
                                Este ticket podrá ser abierto hasta {{reabrirCheck()[1]}}
                            </strong>
                            <strong *ngIf='ticketHijo'>
                                Este ticket ya fue reabierto: <span style="cursor: pointer; text-decoration: underline;" (click)="verDetalles(ticketHijo.ID)">{{ticketHijo.ID}}</span>
                            </strong>
                        </label>
                    </div>
                </div>
            </div>
            <label *ngIf="cambiarEstado && this.ticket.ProcessingTypeCode != 'Z4'" class="label-entity mb-2">Cambio de estado</label>
            <div *ngIf="cambiarEstado && this.ticket.ProcessingTypeCode != 'Z4'" class="row mb-4">
                <div class="col-auto">
                    <div class="d-flex align-items-center">
                        <ng-select class="select-m w-100" [clearable]="false" [searchable]="false" [(ngModel)]="ticketStatus">
                            <ng-option *ngFor="let estado of estados" value="{{estado.id}}">
                                {{estado.descripcion}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="d-flex align-items-center" *ngIf="!showInstalacion">
                        <button 
                            [disabled]="ticket.ServiceRequestUserLifeCycleStatusCode == ticketStatus || ticket.ServiceRequestUserLifeCycleStatusCodeText == ticketStatus" 
                            (click)="changeStatus(ticketStatus,true, true)" type="button" class="btn btn-primary-portal  btn-primary btn-sm" 
                            *hasPermission="['SEC_TICKETS_OPERACION']">
                            Actualizar
                        </button>
                    </div>
                </div>
            </div>
            <label class="label-entity my-3">Detalles</label>
            <div class="row my-2" *ngIf="ticket.Z_SOLUCION_KUT!=''">
                <div class="col-4">
                    <label class="label-entity font-weight-bold">Solución</label>
                </div>
                <div class="col-8">
                    <span class="value-data">{{ ticket.Z_SOLUCION_KUT }}</span>
                </div>
            </div>
            <div class="row my-2" *ngIf="ticket.Z_MOTIVOTICKET_KUT">
                <div class="col-lg-4 col-xl-4 col-md-12 cold-sm-12">
                    <label class="label-entity font-weight-bold">Motivo</label>
                </div>
                <div class="col-lg-8 col-xl-8 col-md-12 cold-sm-12">
                    <span class="value-data">{{ ticket.Z_MOTIVOTICKET_KUTText }}</span>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-lg-4 col-xl-4 col-md-12 cold-sm-12">
                    <label class="label-entity font-weight-bold">Fecha de creaci&oacute;n</label>
                </div>
                <div class="col-lg-8 col-xl-8 col-md-12 cold-sm-12">
                    <span class="value-data">{{ formatDate(ticket.RequestInitialReceiptdatetimecontent) }}</span>
                </div>
            </div>
            <div  class="row my-2">
                <div class="col-lg-4 col-xl-4 col-md-12 cold-sm-12">
                    <label class="label-entity font-weight-bold">Fecha de resoluci&oacute;n</label>
                </div>
                <div class="col-lg-8 col-xl-8 col-md-12 cold-sm-12">
                    <span class="value-data">{{ formatDate(ticket.ResolvedOnDateTime) }}</span>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-lg-4 col-xl-4 col-md-12 cold-sm-12">
                    <label class="label-entity font-weight-bold">Cliente</label>
                </div>
                <div class="col-lg-8 col-xl-8 col-md-12 cold-sm-12">
                    <span class="value-data">{{ ticket.BuyerPartyName }}</span>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-lg-4 col-xl-4 col-md-12 cold-sm-12">
                    <label class="label-entity font-weight-bold">Contacto principal</label>
                </div>
                <div class="col-lg-8 col-xl-8 col-md-12 cold-sm-12">
                    <span class="value-data">{{ ticket.BuyerMainContactPartyName }}</span>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-lg-4 col-xl-4 col-md-12 cold-sm-12">
                    <label class="label-entity font-weight-bold">Tipo de Ticket</label>
                </div>
                <div class="col-lg-8 col-xl-8 col-md-12 cold-sm-12">
                    <span class="value-data">{{ ticket.ProcessingTypeCodeText }}</span>
                </div>
            </div>
            <div class="row my-2" *ngIf="Constantes.CON_NUMERO_BOLETA.includes(ticket.ProcessingTypeCode)">
                <div class="col-lg-4 col-xl-4 col-md-12 cold-sm-12">
                    <label class="label-entity font-weight-bold">N&uacute;mero de Boleta</label>
                </div>
                <div class="col-lg-8 col-xl-8 col-md-12 cold-sm-12">
                    <span class="value-data label-entity pointer" (click)="getTransactionDetails()">{{ ticket.Z_NUMERODEBOLETA_KUT }}</span>
                </div>
            </div>
            <div class="row my-2" *ngIf="Constantes.CON_NUMERO_BOLETA.includes(ticket.ProcessingTypeCode)">
                <div class="col-lg-4 col-xl-4 col-md-12 cold-sm-12">
                    <label class="label-entity font-weight-bold">Fecha de Boleta</label>
                </div>
                <div class="col-lg-8 col-xl-8 col-md-12 cold-sm-12">
                    <span class="value-data">{{ ticket.Z_FECHADEBOLETA_KUT }}</span>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-lg-4 col-xl-4 col-md-12 cold-sm-12">
                    <label class="label-entity font-weight-bold">Prioridad</label>
                </div>
                <div class="col-lg-8 col-xl-8 col-md-12 cold-sm-12">
                    <span class="value-data label-inmediata" *ngIf="ticket.ServicePriorityCode == 1">{{ ticket.ServicePriorityCodeText }}</span>
                    <span class="value-data label-urgente" *ngIf="ticket.ServicePriorityCode == 2">{{ ticket.ServicePriorityCodeText }}</span>
                    <span class="value-data label-normal" *ngIf="ticket.ServicePriorityCode == 3">{{ ticket.ServicePriorityCodeText }}</span>
                    <span class="value-data label-baja" *ngIf="ticket.ServicePriorityCode == 7">{{ ticket.ServicePriorityCodeText }}</span>
                </div>
            </div>
            <div class="row my-2" *ngIf="ticket.ProcessingTypeCode == 'ZVE' && ticket.zAnalisisVeredicto_KUTText">
                <div class="col-lg-4 col-xl-4 col-md-12 cold-sm-12">
                    <label class="label-entity font-weight-bold">Analisis veredicto</label>
                </div>
                <div class="col-lg-8 col-xl-8 col-md-12 cold-sm-12">

                    <span class="value-data label-normal">{{ ticket.zAnalisisVeredicto_KUTText }}</span>
                </div>
            </div>
            <div class="row my-2" *ngIf="ticket.ProcessingTypeCode == 'ZVE' && ticket.zResolucion_KUTText">
                <div class="col-lg-4 col-xl-4 col-md-12 cold-sm-12">
                    <label class="label-entity font-weight-bold">Resoluci&oacute;n</label>
                </div>
                <div class="col-lg-8 col-xl-8 col-md-12 cold-sm-12">
                    <span class="value-data label-normal" *ngIf="ticket.ServicePriorityCode == 3">{{ ticket.zResolucion_KUTText }}</span>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-12 d-flex align-items-center">
                    <label class="label-entity font-weight-bold">Participantes</label>
                    <div class="ml-auto">
                        <ngb-pagination *ngIf="collectionSizeParticipantes > 0 && !loadingParticipantes" [(page)]="pageParticipantes" [maxSize]="3" [pageSize]="pageSizeParticipantes" [collectionSize]="collectionSizeParticipantes"  [boundaryLinks]="true"> </ngb-pagination>
                    </div>
                </div>
                <div class="col-12 box-descripcion-ticket p-0">
                    <div  class="table-responsive">
                        <table class="table">
                            <thead>
                            <tr class="align-items-center">
                                <th *ngIf="(this.ticketStatus != 'Resuelto' && this.ticketStatus != 'Rechazado' && this.ticketStatus != 'Cancelado') && !loadingParticipantes && (ticket.ProcessingTypeCode!='ZAE' || anulacionEditable)" scope="col"></th>
                                <th scope="col">Nombre y apellido</th>
                                <th scope="col">Email</th>
                            </tr>
                            </thead>
                            <tbody align="center" *ngIf="participantestickets.length == 0 && !loadingParticipantes" >
                                <td colspan="3" class="span-empty">No hay participantes asociados al ticket.</td>
                            </tbody>
                            <tbody align="center" *ngIf="loadingParticipantes" >
                                <td colspan="3"> <fa-icon [icon]="faSpinner" animation="spin"></fa-icon> </td>
                            </tbody>

                            <tbody *ngIf="participantestickets.length != 0 && !loadingParticipantes">
                            <tr *ngFor="let participante of participantestickets | slice: (pageParticipantes-1) * pageSizeParticipantes : pageParticipantes * pageSizeParticipantes">
                                <th *ngIf="(this.ticketStatus != 'Resuelto' && this.ticketStatus != 'Rechazado' && this.ticketStatus != 'Cancelado') && !loadingParticipantes && (ticket.ProcessingTypeCode!='ZAE' || anulacionEditable)" scope="row" class="pointer" (click)="open(eliminar,'xl'); this.participanteActual = participante"><fa-icon [icon]="faTimes"></fa-icon> </th>
                                <td>{{participante.PartyName}} </td>
                                <td>{{participante.Email}} </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *hasPermission="['SEC_TICKETS_OPERACION']">
                    <div *ngIf="(this.ticketStatus != 'Resuelto' && this.ticketStatus != 'Rechazado' && this.ticketStatus != 'Cancelado') && !loadingParticipantes && (ticket.ProcessingTypeCode!='ZAE' || anulacionEditable) && !showInstalacion"  class="mr-auto my-3" (click)="open(validacionParticipante,'xl')">
                        <span class="value-data btn-portal pointer">Añadir participantes</span>
                    </div>
                </div>
            </div>
            <div class="row my-2" *ngIf="this.ticket.ProcessingTypeCode == 'Z4'">
                <div class="col-12 d-flex align-items-center">
                    <label class="label-entity font-weight-bold">Ticket hijos</label>
                </div>
                <div class="col-12 box-descripcion-ticket p-0">
                    <div  class="table-responsive">
                        <table class="table table-style table-font-size table-align">
                            <thead align="center">
                            <tr class="align-items-center">
                                <th scope="col">ID ticket</th>
                            </tr>
                            </thead>
                            <tbody align="center" *ngIf="ticketsHijosId.length == 0 && loadingTicketsHijos">
                                <td colspan="3" class="span-empty">No hay tickets asociados.</td>
                            </tbody>
                            <tbody align="center" *ngIf="!loadingTicketsHijos">
                                <td colspan="3"> <fa-icon [icon]="faSpinner" animation="spin"></fa-icon> </td>
                            </tbody>
                            <tbody align="left" *ngIf="loadingTicketsHijos">
                            <tr *ngFor="let id of ticketsHijosId">
                                <td>{{ id }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <app-estados [ticket]="ticket" (collectionSize)="checkCancelable($event,cantidadComentarios)"></app-estados>
        </div>
    </div>
</div>
<!--Modales-->
<ng-template #participantesAsociados let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal ">Participantes asociados al email: "{{emailParticipantePermanent}}"</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background">
        <div class="table-responsive table-contact-modal">
            <table class="table-style table">
                <thead class="py-2 ">
                    <th></th>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Correo electronico</th>
                </thead>
                <tbody>
                    <tr class="py-2 mt-2" *ngFor="let participante of participantesList | slice: (page-1) * pageSize : page * pageSize">
                        <td class="">
                            <fa-icon class="pointer" (click)="selectParticipante(participante); modal.dismiss()" [icon]="faCheck"></fa-icon>
                        </td>
                        <td class="">
                            {{participante.FirstName}}
                        </td>
                        <td class="">
                            {{participante.LastName}}
                        </td>
                        <td class="">
                            {{participante.Email}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer" style="border: none;">
        <ngb-pagination *ngIf="collectionSize > 0"  [(page)]="page" [maxSize]="3" [pageSize]="pageSize" [collectionSize]="collectionSize" [boundaryLinks]="true" > </ngb-pagination>
    </div>
</ng-template>
<ng-template #validacionParticipante let-modal>
    <div class="modal-header" style="border: none;">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background" >
        <div class="row row-cols-lg py-2  justify-content-center">
            <span class="col-lg-12 text-center text-modal d-flex flex-column justify-content-center" style="font-size: 1.3rem!important;">Seleccione un usuario de la entidad</span>
            <div *ngIf="!loadingModal" class="col-lg-4 text-center d-flex flex-column justify-content-center mt-2" >
                <form [formGroup]="validarParticipanteForm">
                    <ng-select placeholder="Usuario" [clearable]="false" [searchable]="true"
                    formControlName="email" [(ngModel)]="emailParticipante" class="select-modal">
                        <ng-option value="">Seleccione un usuario</ng-option>
                        <ng-option *ngFor="let usuario of usuariosList" value="{{usuario.email}}">
                            <span title="{{usuario.nombreCompleto}} - {{usuario.email}}">{{usuario!.nombreCompleto}} - {{usuario.email}} </span>
                        </ng-option>
                    </ng-select>
                </form>
            </div>
            <div *ngIf="loadingModal" class="col-lg-4 text-center d-flex flex-column justify-content-center" >
                <fa-icon class="fa-2x" [icon]="faSpinner" animation="spin"></fa-icon>
            </div>
        </div>
        <div  class="row row-cols-4 row-cols-lg-4 mx-0  py-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <button (click)="contactEmailValidate(participantesAsociados); modal.dismiss()" type="button" class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept">
                        <span>Asociar</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #nuevoParticipante let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal ">Nuevo Participante</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background" >
        <form [formGroup]="formNuevoParticipante" class="d-flex flex-row flex-wrap">
            <div class="col-4 justify-content-center">
                <div class="col pt-3">
                    <label class="label-entity">Nombre</label>
                </div>
                <div class="col">
                    <input class="input-modal input-agregar" formControlName="nombre" type="text" placeholder="" style="text-transform: uppercase;">
                    <app-field-error [field]="formNuevoParticipante.controls['nombre']"></app-field-error>
                </div>
            </div>
            <div class="col-4 justify-content-center">
                <div class="col pt-3">
                    <label class="label-entity">Apellido</label>
                </div>
                <div class="col">
                    <input class="input-modal input-agregar" formControlName="apellido" type="text" placeholder="" style="text-transform: uppercase;">
                    <app-field-error [field]="formNuevoParticipante.controls['apellido']"></app-field-error>
                </div>
            </div>
            <div class="col-4 justify-content-center">
                <div class="col pt-3">
                    <label class="label-entity">Correo electr&oacute;nico</label>
                </div>
                <div class="col">
                    <input class="input-modal input-agregar" formControlName="email" readonly type="text" value="{{emailParticipantePermanent}}" placeholder="{{emailParticipantePermanent}}">
                    <app-field-error [field]="formNuevoParticipante.controls['email']"></app-field-error>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="border: none;">
        <div  class="row row-cols-4 row-cols-lg-4 mx-0  py-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <button (click)="crearNuevoParticipante();" type="button" class="btn-portal btn-modal-accept">
                        <span>Crear</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #eliminar let-modal>
    <div class="modal-body modal-background">
        <div class="row row-cols-lg-1 py-2  justify-content-center">
            <div class="col-lg-8 text-center text-modal" >
                <span>¿Desea eliminar el participante de ticket?</span>
            </div>
        </div>
        <div class="row row-cols-lg-1 py-3 justify-content-center">
            <div class="col-lg-8 text-center text-modal" >
                <div class=" d-flex row text-center justify-content-center">
                    <span class="span-modal">{{this.participanteActual.PartyName}}</span>
                </div>
                <div class="d-flex row text-center justify-content-center">
                    <span class="span-modal id-modal"></span>
                </div>
            </div>
        </div>
        <div class="row row-cols-4 row-cols-lg-4 mx-0  py-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <button type="button" (click)="modal.dismiss()" (click)="eliminarParticipante(this.participanteActual)" class="btn btn-primary-portal btn-primary btn-sm btn-modal">
                        <span>Eliminar</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row row-cols-4 row-cols-lg-4 mx-0  pb-3 justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex  justify-content-center">
                    <button type="button" (click)="modal.dismiss()"  class="btn-modal-cancel">
                        <span>Cancelar</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #anular let-modal>
    <div class="modal-body modal-background">
        <div class="row row-cols-lg-1 py-2  justify-content-center">
            <div class="col-lg-12 text-center text-modal">
                <h5>¿Desea {{aceptarAnulacion ? 'aceptar' : 'rechazar'}} realmente la anulaci&oacute;n?'</h5>
            </div>
        </div>
        <div class="row my-3 justify-content-center">
            <button class=" col-auto btn-anulacion-aprobar mx-2" 
                (click)="this.ticket.ProcessingTypeCode == 'Z4' ? aceptarAnulacion ?  anulacion(aprobarAnulacion) : anulacion(rechazarAnulacion) :
                aceptarAnulacion ? anulacion(aprobarAnulacion2) : anulacion(rechazarAnulacion2)">
                {{aceptarAnulacion ? 'Aprobar' : 'Rechazar'}}
            </button>
            <button class="col-auto btn-anulacion-cancelar" (click)="modal.dismiss()">
                Cancelar
            </button>
        </div>
    </div>
</ng-template>
<ng-template #transactionModal let-modal>
    <div class="modal-header" style="border: none;">
        <div class="d-flex flex-row py-2">
            <span class="text-modal">Detalles de transacción</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-background row row-cols-2" *ngIf="transaction">
        <div class="col d-flex px-1" *ngIf="transaction.boleta">
            <div class="col-4">
                <label class="label-entity font-weight-bold">Boleta</label>
            </div>
            <div class="col-8">
                <span class="value-data" style="font-size: small;">{{ transaction.boleta }}</span>
            </div>
        </div>
        <div class="col d-flex px-1" *ngIf="transaction.importe">
            <div class="col-4">
                <label class="label-entity font-weight-bold">Importe</label>
            </div>
            <div class="col-8">
                <span class="value-data" style="font-size: small;">{{ transaction.importe }}</span>
            </div>
        </div>
        <div class="col d-flex px-1" *ngIf="transaction.fecha">
            <div class="col-4">
                <label class="label-entity font-weight-bold">Fecha y hora</label>
            </div>
            <div class="col-8">
                <span class="value-data" style="font-size: small;">{{ transaction.fecha }}</span>
            </div>
        </div>
        <div class="col d-flex px-1" *ngIf="transaction.transaccion">
            <div class="col-4">
                <label class="label-entity font-weight-bold">Transacción</label>
            </div>
            <div class="col-8">
                <span class="value-data" style="font-size: small;">{{ transaction.transaccion }}</span>
            </div>
        </div>
        <div class="col d-flex px-1" *ngIf="transaction.servicio">
            <div class="col-4">
                <label class="label-entity font-weight-bold">Servicio</label>
            </div>
            <div class="col-8">
                <span class="value-data" style="font-size: small;">{{ transaction.servicio }}</span>
            </div>
        </div>
        <div class="col d-flex px-1" *ngIf="transaction.origen">
            <div class="col-4">
                <label class="label-entity font-weight-bold">Origen</label>
            </div>
            <div class="col-8">
                <span class="value-data" style="font-size: small;">{{ transaction.origen }}</span>
            </div>
        </div>
        <div class="col d-flex px-1" *ngIf="transaction.tarjeta">
            <div class="col-4">
                <label class="label-entity font-weight-bold">Tarjeta</label>
            </div>
            <div class="col-8">
                <span class="value-data" style="font-size: small;">{{ transaction.tarjeta }}</span>
            </div>
        </div>
        <div class="col d-flex px-1" *ngIf="transaction.marca">
            <div class="col-4">
                <label class="label-entity font-weight-bold">Marca</label>
            </div>
            <div class="col-8">
                <span class="value-data" style="font-size: small;">{{ transaction.marca }}</span>
            </div>
        </div>
        <div class="col d-flex px-1" *ngIf="transaction.producto">
            <div class="col-4">
                <label class="label-entity font-weight-bold">Producto</label>
            </div>
            <div class="col-8">
                <span class="value-data" style="font-size: small;">{{ transaction.producto }}</span>
            </div>
        </div>
        <div class="col d-flex px-1" *ngIf="transaction.emisora">
            <div class="col-4">
                <label class="label-entity font-weight-bold">Emisora</label>
            </div>
            <div class="col-8">
                <span class="value-data" style="font-size: small;">{{ transaction.emisora }}</span>
            </div>
        </div>
        <div class="col d-flex px-1" *ngIf="transaction.ATM">
            <div class="col-4">
                <label class="label-entity font-weight-bold">ATM</label>
            </div>
            <div class="col-8">
                <span class="value-data" style="font-size: small;">{{ transaction.ATM }}</span>
            </div>
        </div>
    </div>
    <div class="modal-footer" style="border: none;">
        <button (click)="modal.dismiss()" class="btn btn-primary-portal btn-primary btn-sm btn-modal-accept mx-auto" style="width: 70px;">
            Aceptar
        </button>
    </div>
</ng-template>



<ng-template #cancelar let-modal>
    <div class="modal-body modal-background">
        <div class="row row-cols-lg-1 py-2  justify-content-center">
            <div class="col-lg-12 text-center text-modal">
                <h5>¿Desea Cancelar realmente el ticket?'</h5>
            </div>
        </div>

        <div class="form-group">
            <label for="comentarioTextArea">Para cancelar, favor indicar brevemente el motivo</label>
            <textarea class="form-control" id="comentarioTextArea" rows="3" [(ngModel)]="newComment"></textarea>

            
          </div>

        <div class="row my-3 justify-content-center">
            <button class="col-auto btn-anulacion-aprobar mx-2" 
                (click)="modal.close()" [disabled]="newComment == '' ">
                Si, deseo Cancelar
            </button>
            <button class="col-auto btn-anulacion-rechazar" (click)="modal.dismiss()">
                Salir sin cancelar
            </button>
        </div>
    </div>
</ng-template>