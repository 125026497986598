import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Regex } from "src/app/constants/regex.const";

export const formNewOc  = new FormGroup({
    tipoOC: new FormControl('',[
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(240)]),
    cliente: new FormControl('',[
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(240),]),
    prioridad: new FormControl('',[
        Validators.minLength(1),
        Validators.maxLength(240)]),
    ruc: new FormControl('',[
        Validators.minLength(1),
        Validators.maxLength(240),
        Validators.pattern(Regex.RUC)]),
    tipoEmpresa: new FormControl('',[
        Validators.minLength(1),
        Validators.maxLength(240)]),
    contactoPrincipal: new FormControl('',[
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(240),]),
    pep: new FormControl('',[
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(240),]),
    contactoPEP: new FormControl('',[
        Validators.minLength(1),
        Validators.maxLength(240),]),
    razonSocial: new FormControl('',[
        Validators.minLength(1),
        Validators.maxLength(120),]),
    fuerzaVenta: new FormControl('',[
        Validators.minLength(1),
        Validators.maxLength(240),]),
    nombre: new FormControl('',[
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(40),]),
    estado: new FormControl('',[
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(240),]),
    categoria: new FormControl('',[
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(240),]),
    horaAprobacion: new FormControl('',[
        Validators.minLength(1),
        Validators.maxLength(240),]),
    origen: new FormControl('',[
        Validators.minLength(1),
        Validators.maxLength(240),]),
    ciudad: new FormControl('',[
        Validators.minLength(1),
        Validators.maxLength(240),]),
    barrio: new FormControl('',[
        Validators.minLength(1),
        Validators.maxLength(240),]), 
    zona: new FormControl('',[
        Validators.minLength(1),
        Validators.maxLength(240),])                                                                                                                                                                                              
})
