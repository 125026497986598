<!-- Header horizontal -->
<nav class="d-flex navbar header fixed-top nav-fixed-top">
    <div class="env" *ngIf="showEnv" >Ambiente de pruebas - {{env}}</div>
    <div class="container-fluid">
        <div class="d-flex flex-row">
            <div class="flex-row font-weight-bold d-none d-lg-inline">
                <span class="panel-admin mx-1" >{{ user.nombreCompleto }}</span>
            </div>
            <span class="text-white mx-2 d-none d-lg-inline">|</span>
            <div class="flex-row d-none d-lg-inline">
                <span class="panel-admin color-light-blue" >{{ user.entidad.nombre }}</span>
            </div>    
        </div>

       
        
        <div class="d-flex flex-row">

            <fa-icon [icon]="faQuestionCircle" style="color: #fff !important;"  size="2x"  class="pointer"
                 [routerLink]="['/','ayuda']"></fa-icon>
            <span class="text-white mx-2">&nbsp;&nbsp;</span>
            <bpe-notificacions-panel id="bell"></bpe-notificacions-panel>
            <span class="text-white mx-2">&nbsp;&nbsp;</span>
            <img class="pointer header-width logo" (click)="home()" src="../../assets/imgs/Bancard-Logo.svg" alt="Bancard-Logo" >
        </div>        

    </div>
    <div class="last-login">
        Ultimo inicio de sesión: {{user.lastLogin}} <span class="text-white mx-1"></span> <fa-icon class="pointer" [icon]="faHistory" [routerLink]="['/','historial-login']"></fa-icon> 
    </div>
</nav>